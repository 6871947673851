define("adept-iq/adapters/avlm-vehicle-radius", ["exports", "adept-iq/adapters/-playbackService"], function (_exports, _playbackService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _playbackService.default.extend({
    findRecord()
    /* store, type, id, snapshot */
    {
      throw new Error('adapter method unsupported');
    },

    buildURL(modelName
    /*, id, snapshot, requestType, query*/
    ) {
      return this._buildUrlForPath(modelName);
    },

    _buildUrlForPath() {
      const host = this.get('host');
      return `${host}/vehicle-radius`;
    }

  });

  _exports.default = _default;
});