define("adept-iq/pods/components/side-drawer/perform-stop/pick/container/component", ["exports", "ember-concurrency", "moment", "adept-iq/config/mapped-permIds", "adept-iq/utils/unwrapProxy", "adept-iq/utils/vehicleCapacity", "adept-iq/models/stop-point"], function (_exports, _emberConcurrency, _moment, _mappedPermIds, _unwrapProxy, _vehicleCapacity, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TravelNeedItem = Ember.Object.extend({
    type: null,
    count: null
  }); // "service animal" is the one client travel need not shown in dropdowns

  const dropdownExemptedTravelNeedTypeNames = ['serviceAnimal'];
  const paymentTypeOptions = ['Cash', 'Credit Card'];
  const minimumTravelNeedCount = 0;
  const maximumTravelNeedCount = 20;

  var _default = Ember.Component.extend({
    classNames: ['perform-stop-drawer'],
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    // passed in by side-drawer-container component
    stopPoint: null,
    onShrinkClick: null,
    onCloseClick: null,
    currentState: null,
    arrive: Ember.computed.equal('stopPoint.status', 'Arrived'),
    depart: false,
    travelNeedOptions: null,
    travelNeedItems: null,
    shouldArrive: true,
    minCompanionCount: 0,
    maxCompanionCount: 20,
    paymentTypeOptions: null,
    selectedPaymentType: null,
    amount: null,
    _travelNeedTypesQuery: null,
    trip: Ember.computed.readOnly('stopPoint.trip'),
    route: Ember.computed.readOnly('stopPoint.dispatchRoute.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    routeVehicleDriver: Ember.computed.readOnly('route.dispatchRoute.latestRouteVehicleDriver'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    actualArriveTime: Ember.computed('stopPoint.actualArriveTime', function () {
      const arriveTime = this.get('stopPoint.actualArriveTime');
      const curDate = new Date();

      if (Ember.isNone(arriveTime)) {
        return curDate.toISOString();
      }

      return arriveTime;
    }),
    actualDepartTime: Ember.computed('stopPoint.{actualDepartTime,actualArriveTime}', 'actualArriveTime', function () {
      const departTime = this.get('stopPoint.actualDepartTime');
      const curDate = this.get('actualArriveTime');

      if (Ember.isNone(departTime)) {
        return curDate;
      }

      return departTime;
    }),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    isArrived: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.ARRIVE_STOP_STATUS),

    init() {
      this._super(...arguments); // only need to do this query once


      const travelNeedTypesQuery = this.get('store').findAll('travel-need-type');
      this.set('_travelNeedTypesQuery', travelNeedTypesQuery);
      this.set('editAPI', Ember.Object.create({}));
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('refreshTask').perform();
      const setStopContainerReference = this.get('setStopContainerReference');

      if (setStopContainerReference) {
        setStopContainerReference(this);
      }
    },

    unusedTravelNeedOptions: Ember.computed('travelNeedOptions', 'travelNeedItems.@each.type', function () {
      const travelNeedOptions = this.get('travelNeedOptions') || [];
      const travelNeedItems = this.get('travelNeedItems') || [];
      const usedTravelNeedTypes = travelNeedItems.mapBy('type');
      return travelNeedOptions.reject(({
        type
      }) => {
        return usedTravelNeedTypes.includes(type);
      });
    }),

    setMinMaxTravelNeedCount(travelNeedItem) {
      // create min count for each travel need
      travelNeedItem.set('minCount', minimumTravelNeedCount); // create max count for each travel need

      travelNeedItem.set('maxCount', maximumTravelNeedCount);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const overrideTripCapacityLimitsPerm = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.overrideTripCapacityLimits, null);
      const travelNeedTypes = yield this.get('_travelNeedTypesQuery') || [];
      const stopPoint = this.get('stopPoint');
      const actualArriveTime = stopPoint.get('actualArriveTime');
      const actualDepartTime = stopPoint.get('actualDepartTime');
      const curDate = (0, _moment.default)().startOf('minute').toDate();
      const refreshTravelNeedTask = this.get('editAPI.refreshTravelNeedTask');
      stopPoint.rollback();

      if (Ember.isEmpty(actualArriveTime)) {
        stopPoint.set('actualArriveTime', curDate);
      }

      if (Ember.isEmpty(actualDepartTime)) {
        stopPoint.set('actualDepartTime', curDate);
      }

      const isArrived = this.get('isArrived');
      stopPoint.set('odometer'); // "Pick Stop" panel

      this.set('arrive', isArrived);
      this.set('depart', false); // "Travel Needs" panel

      const travelNeedOptions = travelNeedTypes.reduce((arr, travelNeedType) => {
        const name = travelNeedType.get('name');
        const displayName = travelNeedType.get('displayName'); // e.g. don't include "serviceAnimal" since it has its own UI

        if (dropdownExemptedTravelNeedTypeNames.includes(name)) return arr;
        arr.push({
          type: name,
          label: displayName || name
        });
        return arr;
      }, []);
      this.set('travelNeedOptions', travelNeedOptions); // dropdown travel needs

      const travelNeeds = stopPoint.get('trip.segment.leg.legTravelNeeds') || [];
      const equipmentTravelNeeds = travelNeeds.filter(travelNeed => {
        return !travelNeed.get('basicTravelNeedType');
      });
      const ambulatoryTravelNeeds = travelNeeds.filterBy('isAmbulatory', true);
      const wheelchairTravelNeeds = travelNeeds.filterBy('isWheelchair', true);
      const travelNeedItems = equipmentTravelNeeds.reduce((arr, travelNeed) => {
        const count = travelNeed ? travelNeed.get('count') : 0;
        const type = travelNeed.get('travelNeedTypeName');
        if (Ember.isNone(count) || count === 0) return arr;
        const travelNeedItem = TravelNeedItem.create({
          type,
          count
        }) || [];
        arr.push(travelNeedItem);
        return arr;
      }, []); // add and combine all ambulatory count

      if (ambulatoryTravelNeeds.length > 0) {
        const count = ambulatoryTravelNeeds.reduce((accCount, travelNeed) => {
          const currCount = Ember.isNone(travelNeed.get('count')) ? 0 : travelNeed.get('count');
          return accCount + currCount;
        }, 0);
        const type = 'ambulatory';
        travelNeedItems.push(TravelNeedItem.create({
          type,
          count
        }));
      } // add and combine all wheelchair count


      if (wheelchairTravelNeeds.length > 0) {
        const count = wheelchairTravelNeeds.reduce((accCount, travelNeed) => {
          const currCount = Ember.isNone(travelNeed.get('count')) ? 0 : travelNeed.get('count');
          return accCount + currCount;
        }, 0);
        const type = 'wheelchair';
        travelNeedItems.push(TravelNeedItem.create({
          type,
          count
        }));
      }

      travelNeedItems.forEach(travelNeed => {
        if (Ember.isNone(travelNeed.get('oldCount'))) {
          travelNeed.set('oldCount', travelNeed.get('count'));
        } else {
          travelNeed.set('count', travelNeed.get('oldCount'));
        }

        this.setMinMaxTravelNeedCount(travelNeed);
      });
      this.set('travelNeedItems', travelNeedItems); // additional travel needs

      const serviceAnimalTravelNeed = travelNeeds.findBy('isServiceAnimal', true);
      const pcaTravelNeed = travelNeeds.findBy('isPca', true);
      const companionTravelNeed = travelNeeds.findBy('isCompanion', true);
      const companionCount = companionTravelNeed ? companionTravelNeed.get('count') : 0;
      const serviceAnimalSwitch = this.$('#service-animal-switch');
      const pcaSwitch = this.$('#pca-switch');
      const amountToReset = 0;
      if (serviceAnimalSwitch[0]) serviceAnimalSwitch[0].checked = serviceAnimalTravelNeed ? serviceAnimalTravelNeed.get('count') : 0;
      if (pcaSwitch[0]) pcaSwitch[0].checked = pcaTravelNeed ? pcaTravelNeed.get('count') : 0;
      const pcaCount = pcaTravelNeed ? pcaTravelNeed.get('count') : 0;
      this.set('serviceAnimalCount', serviceAnimalSwitch);
      this.set('pcaCount', pcaCount);
      this.set('companionCount', companionCount);
      this.set('minCompanionCount', companionCount - 1 > 0 ? companionCount - 1 : 0);

      if (overrideTripCapacityLimitsPerm) {
        this.set('maxCompanionCount', 5); // replace value 5 with system configuration limit when it exist nyaar-11162
      } else {
        this.set('maxCompanionCount', companionCount + 1);
      } // Refreshing Amount and CompanionCount


      if (refreshTravelNeedTask) {
        refreshTravelNeedTask.perform(companionCount, amountToReset);
      } // "Fare" panel


      this.set('paymentTypeOptions', paymentTypeOptions);
      this.set('selectedPaymentType', paymentTypeOptions[0]);
      this.set('amount', stopPoint.get('trip.segment.fare'));

      if (!Ember.isEmpty(this.currentState)) {
        this.loadCurrentState();
      }
    }).keepLatest(),
    travelNeedComputation: (0, _emberConcurrency.task)(function* () {
      const travelNeedTypes = yield this.get('_travelNeedTypesQuery') || [];
      const stopPoint = this.get('stopPoint'); // "Travel Needs" panel

      const travelNeedOptions = travelNeedTypes.reduce((arr, travelNeedType) => {
        const name = travelNeedType.get('name');
        const displayName = travelNeedType.get('displayName'); // e.g. don't include "serviceAnimal" since it has its own UI

        if (dropdownExemptedTravelNeedTypeNames.includes(name)) return arr;
        arr.push({
          type: name,
          label: displayName || name
        });
        return arr;
      }, []); // dropdown travel needs

      const travelNeeds = stopPoint.get('trip.segment.leg.legTravelNeeds') || [];
      const equipmentTravelNeeds = travelNeeds.filter(travelNeed => {
        return !travelNeed.get('basicTravelNeedType');
      });
      const ambulatoryTravelNeeds = travelNeeds.filterBy('isAmbulatory', true);
      const wheelchairTravelNeeds = travelNeeds.filterBy('isWheelchair', true);
      const travelNeedItems = equipmentTravelNeeds.reduce((arr, travelNeed) => {
        const count = travelNeed ? travelNeed.get('count') : 0;
        const type = travelNeed.get('travelNeedTypeName');
        if (Ember.isNone(count) || count === 0) return arr;
        const travelNeedItem = TravelNeedItem.create({
          type,
          count
        }) || [];
        arr.push(travelNeedItem);
        return arr;
      }, []); // add and combine all ambulatory count

      if (ambulatoryTravelNeeds.length > 0) {
        const count = ambulatoryTravelNeeds.reduce((accCount, travelNeed) => {
          const currCount = Ember.isNone(travelNeed.get('count')) ? 0 : travelNeed.get('count');
          return accCount + currCount;
        }, 0);
        const type = 'ambulatory';
        travelNeedItems.push(TravelNeedItem.create({
          type,
          count
        }));
      } // add and combine all wheelchair count


      if (wheelchairTravelNeeds.length > 0) {
        const count = wheelchairTravelNeeds.reduce((accCount, travelNeed) => {
          const currCount = Ember.isNone(travelNeed.get('count')) ? 0 : travelNeed.get('count');
          return accCount + currCount;
        }, 0);
        const type = 'wheelchair';
        travelNeedItems.push(TravelNeedItem.create({
          type,
          count
        }));
      }

      travelNeedItems.forEach(travelNeed => {
        if (Ember.isNone(travelNeed.get('oldCount'))) {
          travelNeed.set('oldCount', travelNeed.get('count'));
        } else {
          travelNeed.set('count', travelNeed.get('oldCount'));
        }

        this.setMinMaxTravelNeedCount(travelNeed);
      });
      return {
        travelNeedItems,
        travelNeedOptions
      };
    }),

    loadCurrentState() {
      const currentState = this.get('currentState');
      const stopPoint = this.get('stopPoint');

      if (currentState) {
        const {
          odometerInMiles,
          driverBreak,
          arrive,
          depart,
          isArrived,
          selectedPaymentType,
          amount,
          pcaCount,
          serviceAnimalCount,
          companionCount
        } = currentState;
        stopPoint.set('odometerInMiles', odometerInMiles);
        let travelNeedOptions = currentState.travelNeedOptions;
        let travelNeedItems = currentState.travelNeedItems;

        if (Ember.isEmpty(travelNeedOptions) || Ember.isEmpty(travelNeedItems)) {
          const travelNeedComputation = this.get('refreshTask').perform();
          travelNeedOptions = travelNeedComputation.travelNeedOptions;
          travelNeedItems = travelNeedComputation.travelNeedItems;
        }

        this.setProperties({
          driverBreak,
          travelNeedOptions,
          travelNeedItems,
          arrive,
          depart,
          isArrived,
          selectedPaymentType,
          amount,
          pcaCount,
          serviceAnimalCount,
          companionCount
        });
      }
    },

    async processTripCapacityChange(travelNeedItems, stopPoint) {
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      const session = this.get('session');
      const route = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('route'));
      const trip = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('trip'));
      const vehicle = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute.assignedVehicle'));
      const driver = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute.assignedDriver'));
      const dispatchSchedule = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute.dispatchSchedule'));
      const providerId = route.get('provider.id');
      let attributeChanges = false;
      travelNeedItems.forEach(item => {
        if (item.count !== item.oldCount) {
          attributeChanges = true; // let's not remove this message for now. It will be needed later.
          // const sign = item.count > item.oldCount ? '+' : '-';
          // if (attributeChanges.length > 0) {
          //   attributeChanges += ` and ${sign}${Math.abs(item.count - item.oldCount)} ${item.type[0].toUpperCase() + item.type.slice(1)} seat`;
          // }
          // else {
          //   attributeChanges = `${sign}${Math.abs(item.count - item.oldCount)} ${item.type[0].toUpperCase() + item.type.slice(1)} seat`;
          // }
        }
      });

      if (attributeChanges) {
        const details = `Capacity for Trip ${trip.tripId} was modified`;
        const eventLog = {
          type: 'event',
          attributes: {
            routeId: parseInt(route.get('id'), 10),
            tripId: parseInt(trip.get('id'), 10),
            stopPointId: stopPoint.get('id'),
            driverId: parseInt(driver.get('id'), 10),
            vehicleId: parseInt(vehicle.get('id'), 10),
            scheduleId: parseInt(dispatchSchedule.get('id'), 10),
            source: 'webApp',
            providerName: providerId,
            priority: 'low',
            eventType: 'TEE',
            actionType: 'capacityChange',
            userId: session.data.authenticated.userId,
            eventLog: {
              capacityChange: [{
                activity: 'Trip Capacity Change',
                loggingType: 'route',
                details: details
              }, {
                activity: 'Trip Capacity Change',
                loggingType: 'trip',
                details: details
              }]
            }
          }
        };
        await activityLogAdapter.createEventActivity(eventLog);
      }
    },

    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripPaymentAdapter = store.adapterFor('trip-payment');
      const stopPoint = this.get('stopPoint');
      const trip = stopPoint.get('trip');
      const isArrived = this.get('isArrived');
      const serviceAnimalCount = this.get('serviceAnimalCount');
      const pcaCount = this.get('pcaCount');
      const companionCount = this.get('companionCount');
      const travelNeedItems = this.get('travelNeedItems');
      stopPoint.set('odometer', null);

      if (this.get('arrive') && !isArrived) {
        yield routeExecEventAdapter.createStopArriveEvent(stopPoint);
      }

      if (this.get('depart')) {
        // Handle Travel Needs
        stopPoint.set('serviceAnimalCount', serviceAnimalCount);
        stopPoint.set('pcaCount', pcaCount);
        stopPoint.set('companionCount', companionCount);
        stopPoint.set('travelNeeds', travelNeedItems.map(travelNeedItem => ({
          type: travelNeedItem.get('type'),
          cnt: travelNeedItem.get('count')
        }))); // added this activity log here because it was difficult to calculate
        // in avlm

        yield this.processTripCapacityChange(travelNeedItems, stopPoint);
        yield routeExecEventAdapter.createStopDepartEvent(stopPoint);
      }

      if (this.get('amount')) {
        trip.set('fare', parseFloat(this.get('amount')));
        trip.set('fareName', this.get('selectedPaymentType'));
        yield tripPaymentAdapter.createTripPaymentEvent(stopPoint.get('trip'));
      }
    }).drop(),
    actions: {
      onRefreshButtonClick() {
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        this.onCloseClick();
      },

      onPerformButtonClick() {
        const tooltip = this.get('tooltip');
        const route = this.get('route');
        const stopPoint = this.get('stopPoint');
        const arrive = this.get('arrive');
        const depart = this.get('depart');
        const routeEndTime = route.get('dispatchRoute.plannedEndTime');
        const actualStartTime = route.get('dispatchRoute.computedStartTime');
        const arriveTime = (0, _moment.default)(this.get('actualArriveTime'));
        const selectedPaymentType = this.get('selectedPaymentType');
        const amount = this.get('amount');
        const departTime = (0, _moment.default)(this.get('actualDepartTime'));
        const routeOrdinal = stopPoint.get('routeOrdinal');
        const routeStops = route.get('dispatchRoute.orderedStopsWithGaragesAndBreaks');
        const lastPerformedStop = routeStops.filter(stop => stop.isPerformed).get('lastObject');
        const currentDateTime = (0, _moment.default)();
        const travelNeedItems = this.get('travelNeedItems');
        const dispatchRoute = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('dispatchRoute'));
        const vehicle = (0, _unwrapProxy.unwrapProxy)(dispatchRoute.get('latestRouteVehicleDriver.vehicle'));
        const tripsInDanger = this.get('depart') ? (0, _vehicleCapacity.notEnoughSeatsTripInRoute)(travelNeedItems, stopPoint, dispatchRoute, vehicle) : [];
        let title = 'Perform Stop';
        let messageText = '';
        let tooltipText = ''; // Reset error text

        this.set('errorText');

        if ((0, _moment.default)(departTime).isBefore(arriveTime, 'minutes')) {
          this.set('actualDepartTime', this.get('actualArriveTime'));
        }

        if (arrive && depart) {
          messageText = 'Performed pick stop';
          tooltipText = 'Perform pick stop?';
        } else if (arrive) {
          messageText = 'Arrived pick stop';
          tooltipText = 'Arrive pick stop?';
        } else {
          this.set('errorText', 'Please select at least one option of arrive or pick up to perform the stop.');
          return false;
        }

        if (arrive) {
          if (Ember.isEmpty(arriveTime)) {
            this.set('errorText', 'Please fill in a time for arrive time');
            return false;
          }
        }

        if (depart) {
          if (Ember.isEmpty(departTime)) {
            this.set('errorText', 'Please fill in a time for pick up time');
            return false;
          }
        }

        if (selectedPaymentType) {
          if (Ember.isEmpty(amount)) {
            this.set('errorText', 'Fare amount cannot be empty.');
            return false;
          }
        }

        if (arriveTime.isBefore(actualStartTime) || departTime.isBefore(actualStartTime)) {
          this.set('errorText', "Performed Pick Stop Failed. The stop cannot be performed before the route's start time");
          return false;
        }

        if (arriveTime.isAfter(routeEndTime) || departTime.isAfter(routeEndTime)) {
          this.set('errorText', "Performed Pick Stop Failed. The stop cannot be performed after the route's end time.");
          return false;
        }

        if (arriveTime.isAfter(currentDateTime) || departTime.isAfter(currentDateTime)) {
          this.set('errorText', 'Performed Pick Stop Failed. The stop cannot be performed after the current time.');
          return false;
        }

        if (routeOrdinal > 0 && lastPerformedStop && !lastPerformedStop.get('isNoShow')) {
          if (arriveTime.isBefore(lastPerformedStop.get('actualDepartTime')) || departTime.isBefore(lastPerformedStop.get('actualDepartTime'))) {
            this.set('errorText', 'Performed Pick Stop Failed. The stop cannot be performed before the previous Actual drop time.');
            return false;
          }
        }

        if (tripsInDanger.length > 0) {
          tooltipText = `Trips cannot be serviced: ${tripsInDanger.join()}`;
          title = 'Capacity Exceeded';
        }

        tooltip.pushConfirmation({
          tip: tooltipText,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('saveTask').perform().then(() => {
              this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
              this.get('notifications').success(`${messageText} successful`);
            }).catch(response => {
              // clear odometer in stop point model
              stopPoint.set('odometer');
              const payloadMessage = Ember.get(response, 'payload.message') ? JSON.parse(Ember.get(response, 'payload.message')) : null;
              messageText = messageText + ' failed';

              if (response.status === 400) {
                if (payloadMessage.errorDetail.includes('cannot be in state arrived unless all previous stops are performed') || payloadMessage.errorDetail.includes('with status arrived cannot come after')) {
                  messageText = 'Performed Pick Stop Failed. You cannot perform this stop until previous stops are performed';
                }
              }

              this.get('notifications').warning(`${messageText}`);
            }).finally(() => {
              tooltip.reset();
              return this.onCloseClick();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      }

    }
  });

  _exports.default = _default;
});