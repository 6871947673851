define("adept-iq/pods/components/iq-widgets/history-routes-widget/cells/radio-button/component", ["exports", "adept-iq/pods/components/iq-widgets/history-routes-widget/cells/base-cell/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['table-radio-button-cell', 'data-test-table-radio-button-cell'],
    disabled: Ember.computed.readOnly('row.disabled'),
    actions: {
      onRadioButtonClick()
      /* event */
      {
        // prevents `click` event from bubbling to row component
        // event.stopPropagation();
        const row = this.get('row');
        const rows = this.get('table.rows');
        const selectedRow = rows.findBy('selected', true);
        const buttonClickAction = this.get('tableActions.radioButtonClick');
        if (row === selectedRow) return;

        if (selectedRow) {
          selectedRow.set('selected', false);
        }

        if (buttonClickAction) {
          buttonClickAction(row);
        }

        row.set('selected', true);
      }

    }
  });

  _exports.default = _default;
});