define("adept-iq/pods/components/iq-widgets/new-booking-form/travel-needs/component", ["exports", "adept-iq/utils/unwrapProxy", "lodash"], function (_exports, _unwrapProxy, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const travelNeedsToFilter = ['SERVICEANIMAL', 'PCA', 'AMBULATORY', 'COMPANION'];
  const MAX_LOAD_TIME = 15;

  var _default = Ember.Component.extend({
    classNames: ['travel-needs-form'],
    booking: Ember.inject.service(),
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    configItems: null,
    travelNeedTypes: null,
    passengerTypes: null,
    ambulatoryCount: 1,
    countPath: 'count',
    pcaTravelNeed: null,
    travelNeedTypePath: 'travelNeedType',
    previousSelectedCompanionCount: 0,
    travelNeedTypeOptions: null,
    // need to populate this,
    totalRidersCount: 0,
    bigSeats: null,

    init() {
      this._super(...arguments);

      const configItems = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-travel_need_types';
      });
      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const passengerTypes = this.store.peekAll('passenger-type');
      const bigSeatsTN = configItems.toArray().filter(tr => parseInt(tr.get('value.Vehicle Capacity Count'), 10) === 2);
      this.set('booking.configTravelNeedItems', configItems);
      this.setProperties({
        companionOptions: [0, 1, 2, 3, 4, 5, 6],
        configItems,
        bigSeats: bigSeatsTN.map(tr => tr.get('value.Name').toUpperCase()),
        passengerTypes,
        travelNeedTypes,
        travelNeedTypeOptions: travelNeedTypes,
        totalRidersCount: this.get('booking.selectedRiders').length
      });
      this.addCompanionAmbulatoryIfAbsent();
      this.getAndSetCompanionTravelNeed();
      this.getAndSetWheelChairSeatCount();
      this.ambulatorySeatCountChange();
      this.countDisabledObserver();
      this.validateLoadAndUnLoadTimeToBeNumber();
      this.validateLoadAndUnLoadTime();
    },

    selectedCompanionCount: Ember.computed.alias('booking.selectedCompanionCount'),
    countDisabledObserver: Ember.observer('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      this.get('legTravelNeeds').forEach(legTravelNeed => {
        const typeName = legTravelNeed.get('travelNeedTypeNameUppercase');

        if (typeName && (typeName === 'COMPANION' || typeName === 'AMBULATORY')) {
          return;
        }

        if (typeName) {
          let isCountDisabled = true;
          const capacity = parseInt(this.get('configItems').find(tr => tr.get('value.Name').toUpperCase() === typeName).get('value.Vehicle Capacity Count'), 10);

          if (typeName === 'WHEELCHAIR') {
            isCountDisabled = false;
          }

          legTravelNeed.set('count', capacity);
          legTravelNeed.set('isCountDisabled', isCountDisabled);
        }
      });
    }),
    ambulatorySeatCountChange: Ember.observer('ambulatorySeatCount', function () {
      const ambulatorySeatCount = this.get('ambulatorySeatCount');
      const ambulatoryTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'AMBULATORY';
      });

      if (ambulatoryTravelNeed) {
        ambulatoryTravelNeed.set('count', ambulatorySeatCount);
      }
    }),

    addCompanionAmbulatoryIfAbsent() {
      const companion = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'COMPANION';
      });
      const ambulatory = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'AMBULATORY';
      });

      if (!companion) {
        const companionTravelNeedType = this.store.peekAll('travel-need-type').find(tntype => {
          return tntype.get('name').toUpperCase() === 'COMPANION';
        });

        if (companionTravelNeedType) {
          const companionTravelNeedRecord = this.store.createRecord('leg-travel-need', {
            count: this.get('selectedCompanionCount'),
            travelNeedType: companionTravelNeedType,
            passengerType: this.store.peekRecord('passenger-type', 'client')
          });
          this.get('legTravelNeeds').pushObject(companionTravelNeedRecord);
        }
      }

      if (!ambulatory) {
        const ambulatoryTravelNeedType = this.store.peekAll('travel-need-type').find(tntype => {
          return tntype.get('name').toUpperCase() === 'AMBULATORY';
        });

        if (ambulatoryTravelNeedType) {
          const ambulatoryTravelNeedRecord = this.store.createRecord('leg-travel-need', {
            count: 0,
            travelNeedType: ambulatoryTravelNeedType,
            passengerType: this.store.peekRecord('passenger-type', 'client')
          });
          this.get('legTravelNeeds').pushObject(ambulatoryTravelNeedRecord);
        }
      }
    },

    // get wheelchair count for single and multiple riders NYAAR-11938
    getAndSetWheelChairSeatCount() {
      let wheelChairCount = 0;
      this.get('booking.selectedRiders').forEach(rider => {
        const riderTravelNeeds = rider.get('travelNeeds');
        riderTravelNeeds.forEach(riderTravelNeed => {
          if (riderTravelNeed.type === 'WHEELCHAIR') {
            wheelChairCount += riderTravelNeed.cnt;
          }
        });
      });
      const wheelChairTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'WHEELCHAIR';
      });

      if (wheelChairTravelNeed) {
        wheelChairTravelNeed.set('count', wheelChairCount);
      }
    },

    getAndSetCompanionTravelNeed() {
      const companionTravelNeed = this.getCompanionTravelNeed();

      if (companionTravelNeed) {
        const companionCount = companionTravelNeed.get('count');
        this.get('booking').setCompanionCount(companionCount);
      }
    },

    // eslint-disable-next-line ember/use-brace-expansion
    ambulatorySeatCount: Ember.computed('selectedCompanionCount', 'legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.@each.{count}', 'legTravelNeeds.[]', function () {
      const totalRidersCount = this.get('totalRidersCount') + this.get('selectedCompanionCount');
      let totalCount = totalRidersCount;
      const pcaRiders = [];
      const wheelchairTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'WHEELCHAIR';
      });
      const bigSeatTravelneeds = this.get('legTravelNeeds').filter(travelNeed => {
        return this.get('bigSeats').includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      const wcCount = wheelchairTravelNeed ? parseInt(wheelchairTravelNeed.count, 10) : 0;
      const bigSeatsCount = bigSeatTravelneeds.length;
      const totalWCCount = wcCount + bigSeatsCount;

      if (totalWCCount >= totalRidersCount) {
        totalCount = 0;
      } else {
        totalCount = totalCount - totalWCCount;
      }

      const pcaTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'PCA';
      });
      this.get('booking.selectedRiders').forEach(rider => {
        const riderTravelNeeds = rider.get('travelNeeds');
        riderTravelNeeds.forEach(riderTravelNeed => {
          if (riderTravelNeed.type === 'PCA') {
            pcaRiders.push(rider);
          }
        });
      });

      if (pcaTravelNeed) {
        totalCount = totalCount + pcaRiders.length;
      }

      return totalCount;
    }),
    ambulatoryTravelNeed: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const ambulatoryTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'AMBULATORY';
      });
      return ambulatoryTravelNeed;
    }),
    configLoadUnloadTimes: Ember.computed('legTravelNeeds.[]', 'legTravelNeeds.@each.{travelNeedType}', function () {
      const selectedTRs = this.get('legTravelNeeds').map(tr => tr.get('travelNeedTypeNameUppercase'));
      const selectedConfigTRs = this.get('configItems').filter(ctr => selectedTRs.includes(ctr.get('value.Name').toUpperCase()));
      return {
        loadTimes: selectedConfigTRs.map(item => parseInt(item.get('value.Load Time'), 10)),
        unloadTimes: selectedConfigTRs.map(item => parseInt(item.get('value.Unload Time'), 10))
      };
    }),
    loadTime: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', 'riderToBook.loadTime', function () {
      let pickDwellTime;
      this.get('legs').forEach(leg => {
        const segments = leg.get('segments');
        segments.forEach(segment => {
          if (segment.get('id')) {
            pickDwellTime = segment.get('pick.dwell');
          }
        });
      });

      if (pickDwellTime) {
        return pickDwellTime;
      }

      let allTravelNeedLoadTimes = this.get('legTravelNeeds').map(travelNeed => {
        const travelNeedTypePresent = !(0, _lodash.isEmpty)((0, _unwrapProxy.unwrapProxy)(travelNeed.get('travelNeedType')));

        if (travelNeedTypePresent) {
          const loadTime = travelNeed.get('travelNeedType.loadTime');
          return (0, _lodash.isNumber)(loadTime) ? loadTime : 0;
        }

        return 0;
      });
      const riderLoadTime = this.get('booking.selectedRiders').map(r => r.get('loadTime') ? r.get('loadTime') : 0);
      const configLoadTimes = this.get('configLoadUnloadTimes.loadTimes'); // check if this coming from server

      if (riderLoadTime) {
        allTravelNeedLoadTimes = [...allTravelNeedLoadTimes, ...riderLoadTime, ...configLoadTimes];
      }

      const maxLoadTime = Math.max(...allTravelNeedLoadTimes);
      return maxLoadTime;
    }),
    unloadTime: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', 'riderToBook.unloadTime', function () {
      let dropDwellTime;
      this.get('legs').forEach(leg => {
        const segments = leg.get('segments');
        segments.forEach(segment => {
          if (segment.get('id')) {
            dropDwellTime = segment.get('drop.dwell');
          }
        });
      });

      if (dropDwellTime) {
        return dropDwellTime;
      }

      let allTravelNeedUnLoadTimes = this.get('legTravelNeeds').map(travelNeed => {
        const travelNeedTypePresent = !(0, _lodash.isEmpty)((0, _unwrapProxy.unwrapProxy)(travelNeed.get('travelNeedType')));

        if (travelNeedTypePresent) {
          const unloadTime = travelNeed.get('travelNeedType.unloadTime');
          return (0, _lodash.isNumber)(unloadTime) ? unloadTime : 0;
        }

        return 0;
      });
      const riderUnLoadTime = this.get('booking.selectedRiders').map(r => r.get('unloadTime') ? r.get('unloadTime') : 0);
      const configUnLoadTimes = this.get('configLoadUnloadTimes.unloadTimes'); // check if this coming from server

      if (riderUnLoadTime) {
        allTravelNeedUnLoadTimes = [...allTravelNeedUnLoadTimes, ...riderUnLoadTime, ...configUnLoadTimes];
      }

      const maxUnLoadTime = Math.max(...allTravelNeedUnLoadTimes);
      return maxUnLoadTime;
    }),
    pcaEligible: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const pcaRequired = this.get('riderToBook.eligibility.pcaRequired'); // if pcaRequired then disable the checkbox

      if (pcaRequired) {
        return false;
      }

      const totalPcaRider = this.getNoOfPcaRiders();
      const pcaTravelNeed = this.getPcaTravelNeed();

      if (totalPcaRider > 0 || pcaTravelNeed) {
        return true;
      }

      return false;
    }),
    pcaStateChecked: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const pcaRequired = this.get('riderToBook.eligibility.pcaRequired');

      if (pcaRequired) {
        return true;
      }

      const pcaTravelNeed = this.getPcaTravelNeed();

      if (pcaTravelNeed) {
        return true;
      }

      return false;
    }),
    serviceAnimalChecked: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const serviceAnimalTravelNeed = this.getServiceAnimalTravelNeed();

      if (serviceAnimalTravelNeed) {
        return true;
      }

      return false;
    }),
    availableTravelNeedTypes: Ember.computed('legTravelNeeds.@each.{travelNeedType}', 'legTravelNeeds.[]', function () {
      const allTravelTypes = this.store.peekAll('travel-need-type');
      const existingTravelNeedsTypes = this.get('legTravelNeeds').map(travelNeed => travelNeed.get('travelNeedTypeNameUppercase'));
      const travelNeedTypesOptionsThatShouldNotBeInOptions = existingTravelNeedsTypes.filter(tr => tr).concat(travelNeedsToFilter);
      const filteredArray = allTravelTypes.filter(travelType => {
        return travelType.name && !travelNeedTypesOptionsThatShouldNotBeInOptions.includes(travelType.name.toUpperCase()) || travelType.name && this.get('bigSeats').includes(travelType.name.toUpperCase());
      });
      return filteredArray;
    }),
    nonCompanionTravelNeeds: Ember.computed('legTravelNeeds.[]', function () {
      const filteredTravelNeeds = this.get('legTravelNeeds').filter(legtravelNeed => {
        return !travelNeedsToFilter.includes(legtravelNeed.get('travelNeedTypeNameUppercase'));
      });
      return filteredTravelNeeds;
    }),
    validateLoadAndUnLoadTime: Ember.observer('loadTime', 'unloadTime', function () {
      const loadTime = this.get('loadTime');
      const unLoadTime = this.get('unloadTime');

      if (loadTime > MAX_LOAD_TIME || unLoadTime > MAX_LOAD_TIME) {
        this.get('setLoadAndUnLoadTimeFlag')(false);
        this.get('booking').showErrorMessage('Load/Unload time value must be 15 min or less.');
      } else {
        this.get('setLoadAndUnLoadTimeFlag')(true);
      }
    }),
    validateLoadAndUnLoadTimeToBeNumber: Ember.observer('loadTime', 'unloadTime', function () {
      const loadTime = this.get('loadTime');
      const unLoadTime = this.get('unloadTime');
      const isLoadUnloadTimesNumber = this.get('booking').isNumber(loadTime) && this.get('booking').isNumber(unLoadTime);

      if (isLoadUnloadTimesNumber) {
        this.get('setIsLoadAndUnLoadTimeNumberFlag')(true);
      } else {
        this.get('setIsLoadAndUnLoadTimeNumberFlag')(false);
        this.get('booking').showErrorMessage('Please enter valid numbers for load/unload time.');
      }
    }),
    //NYAAR-12930  verify if pca and serviceAnimal are present in the config item
    isPcaPresentInConfig: Ember.computed('legTravelNeeds.[]', function () {
      return this.get('booking').isCheckPca();
    }),
    //NYAAR-12930 verify if pca and serviceAnimal are present in the config item
    isServiceAnimalPresentInConfig: Ember.computed('legTravelNeeds.[]', function () {
      return this.get('booking').isCheckServiceAnimal();
    }),

    getPcaTravelNeed() {
      return this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedType.upperCaseName') === 'PCA';
      });
    },

    getServiceAnimalTravelNeed() {
      return this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedType.name') === 'serviceAnimal';
      });
    },

    getNoOfPcaRiders() {
      const pcaRiders = [];
      this.get('booking.selectedRiders').forEach(rider => {
        const riderTravelNeeds = rider.get('travelNeeds');
        riderTravelNeeds.forEach(riderTravelNeed => {
          if (riderTravelNeed.type === 'PCA') {
            pcaRiders.push(rider);
          }
        });
      });
      return pcaRiders.length;
    },

    getCompanionTravelNeed() {
      return this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'COMPANION';
      });
    },

    actions: {
      onTravelNeedCountChange(travelNeed, value) {
        travelNeed.set('count', value);
      },

      onLoadTimeChange(value) {
        this.get('legs').forEach(leg => {
          const segments = leg.get('segments');
          segments.forEach(segment => {
            segment.set('pick.dwell', value);
          });
        });
      },

      onUnLoadTimeChange(value) {
        this.get('legs').forEach(leg => {
          const segments = leg.get('segments');
          segments.forEach(segment => {
            segment.set('drop.dwell', value);
          });
        });
      },

      onPCAChange(event) {
        if (event.target.checked) {
          const allTravelTypes = this.store.peekAll('travel-need-type');
          const pcaType = allTravelTypes.find(travelType => {
            return travelType.name.toUpperCase() === 'PCA';
          });
          const newPcaTravelNeed = this.store.createRecord('leg-travel-need', {
            count: 1,
            travelNeedType: this.store.peekRecord('travel-need-type', pcaType.get('id')),
            passengerType: this.store.peekRecord('passenger-type', 'pca')
          });
          this.get('legTravelNeeds').pushObject(newPcaTravelNeed);
        } else {
          const pcaTravelNeed = this.getPcaTravelNeed();
          this.get('legTravelNeeds').removeObject(pcaTravelNeed);
        }
      },

      onServiceAnimalChange(event) {
        if (event.target.checked) {
          const allTravelTypes = this.store.peekAll('travel-need-type');
          const serviceAnimalType = allTravelTypes.find(travelType => {
            return travelType.name.toUpperCase() === 'SERVICEANIMAL';
          });
          const newServiceAnimalTravelNeed = this.store.createRecord('leg-travel-need', {
            count: 1,
            travelNeedType: this.store.peekRecord('travel-need-type', serviceAnimalType.get('id')),
            passengerType: this.store.peekRecord('passenger-type', 'client')
          });
          this.get('legTravelNeeds').pushObject(newServiceAnimalTravelNeed);
        } else {
          const sAnimalTravelNeed = this.get('legTravelNeeds').find(travelNeed => travelNeed.get('travelNeedType.name') === 'serviceAnimal');
          this.get('legTravelNeeds').removeObject(sAnimalTravelNeed);
        }
      },

      onAddTravelNeed() {
        const store = this.get('store');
        const newLegTravelNeed = store.createRecord('leg-travel-need', {
          count: 0,
          travelNeedType: null,
          passengerType: store.peekRecord('passenger-type', 'client')
        });
        this.get('legTravelNeeds').pushObject(newLegTravelNeed);
      },

      onRemoveTravelNeed(legTravelNeed) {
        this.get('legTravelNeeds').removeObject(legTravelNeed);
      },

      onCompanionChange(companionCount) {
        const companionTravelNeed = this.getCompanionTravelNeed();

        if (companionTravelNeed) {
          companionTravelNeed.set('count', companionCount);
        }

        this.get('booking').setCompanionCount(companionCount);
        this.get('updateFare')();
      }

    }
  });

  _exports.default = _default;
});