define("adept-iq/classes/data-joins/map/base", ["exports", "adept-iq/classes/data-joins/base", "adept-iq/config/environment", "adept-iq/classes/work-queue"], function (_exports, _base, _environment, _workQueue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    map: null,
    // tomtom `map` object
    mapService: null,
    work: null,
    // `work` service
    workspace: null,
    // `workspace` service
    queue: null,
    // `WorkQueue` instance
    vehicleCluster: null,
    queueName: null,

    // set in subclass!
    init() {
      this._super(...arguments);

      const queue = this.createWorkQueue();
      this.get('work').registerQueue(queue);
      this.set('queue', queue);
    },

    destroy() {
      // remove all observers
      this.get('nodes').forEach(node => {
        const dynamicProperties = node.get('dynamicProperties');
        Ember.makeArray(dynamicProperties).forEach(propertyName => {
          node.removeObserver(propertyName, this, '_nodePropertyChanged');
        });
      }); // eliminate queue

      const work = this.get('work');
      const queue = this.get('queue');
      work.unregisterQueue(queue);
      queue.destroy();

      this._super(...arguments);
    },

    createWorkQueue() {
      const name = this.get('queueName');

      if (Ember.isBlank(name)) {
        throw new Error('queueName cannot be blank');
      }

      const envOptions = _environment.default.work[name];

      if (!envOptions) {
        throw new Error(`no environment options for queue '${name}'`);
      }

      const options = Object.assign({}, envOptions, {
        name,
        workspace: this.get('workspace'),
        perform: jobs => {
          // returns # of non-trivial refreshes performed
          return jobs.reduce((count, node) => {
            return this.refresh(node) ? count + 1 : count;
          }, 0);
        }
      });
      return _workQueue.default.extend({
        isDisabled: Ember.computed.readOnly('workspace.isRoadSupervisorModeEnabled')
      }).create(options);
    },

    join() {
      this._super(...arguments);

      this.get('work').start();
    },

    enter(node) {
      this._super(...arguments);

      delete node.markerMaximized;
      delete node.polylineMaximized;
      delete node.shapeMaximized;
      delete node.decoratorMaximized;
      const dynamicProperties = node.get('dynamicProperties');
      Ember.makeArray(dynamicProperties).forEach(propertyName => {
        node.addObserver(propertyName, this, '_nodePropertyChanged');
      });
      node.set('isJoined', true);
      this.get('queue').addJob(node);
    },

    update(node) {
      this._super(...arguments);

      this.get('queue').addJob(node);
    },

    exit(node) {
      const dynamicProperties = node.get('dynamicProperties');
      Ember.makeArray(dynamicProperties).forEach(propertyName => {
        node.removeObserver(propertyName, this, '_nodePropertyChanged');
      });
      node.set('isJoined', false);
      this.refresh(node);

      this._super(...arguments);
    },

    refresh()
    /* node */
    {
      throw new Error('not implemented');
    },

    _nodePropertyChanged(node
    /*, propertyName */
    ) {
      this.update(node);
      this.get('work').start();
    }

  });

  _exports.default = _default;
});