define("adept-iq/classes/data-joins/map/marker", ["exports", "adept-iq/classes/data-joins/map/base", "lodash", "tomtom", "adept-iq/pods/components/iq-widgets/map-widget/config/marker", "adept-iq/pods/components/iq-widgets/map-widget/config/marker-highlight"], function (_exports, _base, _lodash, _tomtom, _marker, _markerHighlight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MARKER_UPDATE_THROTTLE = 10000; // do not update marker more than once per N ms

  var _default = _base.default.extend({
    onContextMenu: null,
    onDblClick: null,
    queueName: 'map-marker',

    refresh(node) {
      const map = this.map;
      const isMaximized = map.options.isMaximized;
      const isJoined = node.get('isJoined');
      const isHidden = node.get('isHidden');
      const marker = isMaximized ? node.get('markerMaximized') : node.get('marker');
      const lat = node.get('lat');
      const lng = node.get('lng');

      if (!isJoined || isHidden || Ember.isNone(lat) || Ember.isNone(lng)) {
        if (marker) {
          return this.removeMarker(node, isMaximized);
        }

        return false;
      }

      if (node.get('layer.isHighLighted')) node.set('isHighLighted', node.get('layer.isHighLighted'));

      if (!marker) {
        return this.createMarker(node, isMaximized);
      }

      return this.updateMarker(node, isMaximized);
    },

    createMarker(node, isMaximized) {
      const vehicleCluster = this.get('vehicleCluster');
      const lat = node.get('lat');
      const lng = node.get('lng');

      const options = this._computeMarkerOptions(node);

      const marker = _tomtom.default.L.marker([lat, lng], options);

      if (isMaximized) {
        node.set('markerMaximized', marker);
      } else {
        node.set('marker', marker);
      }

      if (vehicleCluster && node.get('isGrouped')) {
        vehicleCluster.addLayer(marker);
        node.set('inCluster', true);
      } else {
        marker.addTo(this.get('map'));
        node.set('inCluster', false);
      }

      if (node.get('isHighLighted') && marker._icon) {
        _tomtom.default.L.DomUtil.addClass(marker._icon, 'high-lighted');
      } else if (marker._icon) {
        _tomtom.default.L.DomUtil.removeClass(marker._icon, 'high-lighted');
      } // append iq info for active context callback


      const record = node.get('record');
      marker.iq = {
        isActive: node.get('isActive'),
        modelName: record ? record.constructor.modelName : null,
        modelId: record ? record.get('id') : null
      }; // init z-index

      const zIndexOffset = node.get('zIndexOffset');

      if (!Ember.isNone(zIndexOffset)) {
        marker.setZIndexOffset(zIndexOffset);
      } // init rotation angle


      const rotationAngle = node.get('rotationAngle');

      if (!Ember.isNone(rotationAngle)) {
        marker.setRotationAngle(rotationAngle / 2);
      } // bind popup & tooltip


      const label = node.get('label');

      if (label) {
        // popup label if display label set to 'always'
        if (node.get('layer.isDisplayMapIconLabel')) {
          marker.bindPopup(label, {
            closeButton: false,
            autoClose: false,
            closeOnClick: false,
            autoPan: false
          }).openPopup();
        } else {
          marker.bindPopup(label, {
            closeButton: false
          });
        }

        marker.bindTooltip(label);
      } // bind click handlers


      marker.on('contextmenu', e => {
        node.trigger('contextmenu', e);

        if (this.onContextMenu) {
          Ember.run(() => this.onContextMenu(e, node));
        }
      });
      marker.on('dblclick', e => {
        node.trigger('dblclick', e);

        if (this.onDblClick) {
          _tomtom.default.L.DomEvent.stop(e);

          Ember.run(() => this.onDblClick(e, node));
        }
      });
      node.cache = {
        lat,
        lng,
        label,
        rotationAngle,
        zIndexOffset
      };
      return true;
    },

    updateMarker(node, isMaximized) {
      const vehicleCluster = this.get('vehicleCluster');
      const marker = isMaximized ? node.get('markerMaximized') : node.get('marker');
      const cache = node.cache;
      const needUpdateNow = !node.get('isGrouped') || node.get('isGrouped') && !node.get('inCluster'); // Update not Groupded vehicle everytime

      let isDirty = false; // Check marker last update time

      const lastUpdatedAt = node.get('lastUpdatedAt');
      const t = new Date().getTime();

      if (!needUpdateNow && lastUpdatedAt && t - lastUpdatedAt < MARKER_UPDATE_THROTTLE) {
        // skip marker update
        return;
      } // update position


      const lat = node.get('lat');
      const lng = node.get('lng');

      if (lat !== cache.lat || lng !== cache.lng) {
        marker.setLatLng([lat, lng]);
        cache.lat = lat;
        cache.lng = lng;
        isDirty = true;
      } // update marker icon


      const {
        icon
      } = this._computeMarkerOptions(node);

      const iconDigest = JSON.stringify(icon.options);

      if (iconDigest !== cache.iconDigest) {
        marker.setIcon(icon);
        cache.iconDigest = iconDigest;
        isDirty = true;
      }

      if (!node.get('isGrouped') && node.get('inCluster')) {
        vehicleCluster.removeLayer(marker);
        marker.addTo(this.get('map'));
        node.set('inCluster', false);
        isDirty = true;
      } else if (node.get('isGrouped') && !node.get('inCluster')) {
        vehicleCluster.addLayer(marker);
        this.get('map').removeLayer(marker);
        node.set('inCluster', true);
        isDirty = true;
      }

      if (node.get('isHighLighted') && marker._icon) {
        _tomtom.default.L.DomUtil.addClass(marker._icon, 'high-lighted');
      } else if (marker._icon) {
        _tomtom.default.L.DomUtil.removeClass(marker._icon, 'high-lighted');
      } // update front-to-back z-index, e.g. render vehicles on top of stops


      const zIndexOffset = node.get('zIndexOffset');

      if (!Ember.isNone(zIndexOffset) && zIndexOffset !== cache.zIndexOffset) {
        marker.setZIndexOffset(zIndexOffset);
        cache.zIndex = zIndexOffset;
        isDirty = true;
      } // update icon rotation, e.g. vehicle heading


      const rotationAngle = node.get('rotationAngle');

      if (!Ember.isNone(rotationAngle) && rotationAngle !== cache.rotationAngle) {
        marker.setRotationAngle(rotationAngle / 2);
        cache.rotationAngle = rotationAngle;
        isDirty = true;
      } // once isDirty property is true for any different icon change, rotation angle, lat-lng, or zindex
      // update the vehicle cluster


      if (isDirty) {
        vehicleCluster.refreshClusters(marker);
      } // update opacity; emergency/highlighted vehicles are always full opacity


      const isEmergencyVehicle = node.get('record.isGrouped');
      const opacity = isEmergencyVehicle ? 1.0 : node.get('opacity');

      if (opacity !== cache.opacity) {
        marker.setOpacity(opacity);
        cache.opacity = opacity;
        isDirty = true;
      } // update popup & tooltip


      const label = node.get('label');

      if (label !== cache.label) {
        if (!label || label === '') {
          marker.closePopup();
          marker.unbindPopup();
          marker.unbindTooltip();
        } else if (!cache.label) {
          marker.bindPopup(label, {
            closeButton: false
          });
          marker.bindTooltip(label);
        } else {
          marker.setPopupContent(label);
          marker.setTooltipContent(label);
        }

        cache.label = label;
        isDirty = true;
      }

      node.set('lastUpdatedAt', t);
      return isDirty;
    },

    removeMarker(node, isMaximized) {
      const vehicleCluster = this.get('vehicleCluster');
      const marker = isMaximized ? node.get('markerMaximized') : node.get('marker');
      marker.iq = null;
      marker.off('contextmenu');
      marker.off('dblclick');

      if (vehicleCluster && node.get('inCluster')) {
        vehicleCluster.removeLayer(marker);
      }

      marker.remove();
      node.cache = null;
      node.set('marker', null);
      return true;
    },

    _computeMarkerOptions(node) {
      const optionSets = [];
      const opacity = node.get('opacity');

      if (!Ember.isNone(opacity)) {
        optionSets.push({
          opacity
        });
      }

      const nodeOptions = node.get('options');

      if (nodeOptions) {
        optionSets.push(nodeOptions);
      }

      const style = node.get('style');
      const layerType = node.get('layerType');
      const isHighlight = node.get('isHighLighted');

      if (style) {
        const markerOptions = isHighlight ? _markerHighlight.default[style] : _marker.default[style];

        if (!markerOptions) {
          throw new Error(`no marker style found with key '${style}'`);
        }

        optionSets.push(markerOptions);
      } else if (layerType && layerType.style) {
        const layerTypeOptions = _marker.default[layerType.style];

        if (!layerTypeOptions) {
          throw new Error(`no marker style found with key ${layerType.style}`);
        }

        optionSets.push(layerTypeOptions);
      }

      return _lodash.default.defaultsDeep({}, ...optionSets);
    }

  });

  _exports.default = _default;
});