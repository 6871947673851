define("adept-iq/pods/components/iq-widgets/new-booking-form/booking-details/component", ["exports", "moment", "lodash"], function (_exports, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['booking-details'],
    editModal: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    booking: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tripDetails: null,
    returnTripDetails: null,
    returnTripDetailsVisible: false,
    returnTripIndex: null,
    isITPEligible: false,

    async init() {
      this._super(...arguments);

      const isITPEligible = this.get('riderSelectedTravelModes').includes('itp');
      this.set('isITPEligible', isITPEligible);

      if (isITPEligible) {
        //create itp-paratransit segments
        const itpRequestResults = this.get('booking.itpInfo.itpRequestResults');
        const bookingId = this.get('booking').get('tempBookingInfo.tempBookingId');
        const booking = await this.get('store').queryRecord('booking', bookingId);
        const legs = booking.get('legs').toArray();
        let segments = [];
        const segmentsForITP = legs.map(async leg => {
          const legOrdinal = leg.get('segments.firstObject.legOrdinal');
          const itpRequest = (0, _lodash.find)(itpRequestResults, itpResult => itpResult.legIndex === legOrdinal - 1);
          const dbSegments = await this.get('booking').createSegmentsForItpInDB(leg, legOrdinal, itpRequest);
          segments = [...segments, ...dbSegments];
          return dbSegments;
        });
        await Promise.all(segmentsForITP); // fetch promisetime for the above segments

        const promiseTimeData = await this.get('booking').getPromiseTimeForITPSegments(segments, booking);

        if (!Ember.isEmpty(promiseTimeData)) {
          this.set('booking.itpSegmentPromiseTimeResults', promiseTimeData);
        }
      }

      this.buildTripAndReturnTripDetails();

      if (!Ember.isEmpty(this.get('returnTripBooking'))) {
        this.set('returnTripDetailsVisible', true);
      }

      this.get('workspace').set('isGlobalSpinnerVisible', false);
      this.onSelectedTravelModeChange = this.onSelectedTravelModeChange.bind(this);
    },

    willDestroyElement() {
      this.set('booking.tempBookingInfo', {});

      this._super(...arguments);
    },

    async saveBooking(status) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        const tempBookingId = this.get('booking.tempBookingInfo.tempBookingId');

        if (status === 'failed') {
          const tripCancelJobs = await this.get('booking').deleteBooking(tempBookingId, 'refuse');
          const asynOperationInfoArray = tripCancelJobs ? tripCancelJobs.tripCancelJobs : [];

          for (const tripCancelInfo of asynOperationInfoArray) {
            if (tripCancelInfo) {
              await this.get('booking').callScheduleAsyncOperation(tripCancelInfo);
            }
          }
        } // if edit booking delete original booking trips


        if (this.get('isEditMode')) {
          const bookingID = this.get('booking.activeBooking.id');
          const tripCancelJobs = await this.get('booking').deleteBooking(bookingID, 'refuse');
          const asynOperationInfoArray = tripCancelJobs ? tripCancelJobs.tripCancelJobs : [];

          for (const tripCancelInfo of asynOperationInfoArray) {
            if (tripCancelInfo) {
              await this.get('booking').callScheduleAsyncOperation(tripCancelInfo);
            }
          }

          await this.get('booking').deleteTrips(bookingID);
          await this.get('booking').saveBooking(this.get('isEditMode'));
        } else {
          await this.get('booking').saveBookingStatus(tempBookingId);
        }

        await this.loadBookingsForSelectedExternalRiders();
        this.get('onClose')();
        this.editModal.close();
        let message = this.get('isEditMode') ? 'Booking updated Successfully' : 'New Booking successfully saved!';

        if (status === 'failed') {
          message = 'Booking was saved but it will not be scheduled.';
          this.get('notifications').warning(message);
        } else {
          this.get('notifications').success(message);
          this.set('booking.activeBooking.status', 'requested');
        }
      } catch (err) {
        const bookingFailedMessage = this.get('isEditMode') ? 'Failed to update Booking' : 'Failed to create Booking';
        const errorMsg = err.isLoadTripError ? err.message : bookingFailedMessage;
        this.get('notifications').warning(errorMsg);
        this.get('onClose')();
        this.editModal.close();
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    /**
     * update temp booking with  status 'scheduled' and  update  legs and segments..
     * then call schedule service to create trips
     * @returns {Promise<void>}
     */
    async acceptBooking() {
      try {
        const {
          promiseTimeAsyncOperationInfoArray
        } = await this.get('booking').acceptBooking(this.get('isEditMode'));
        this.set('booking.activeBooking.status', 'scheduled'); // There is a little delay in creating trips in scheduling service.. So we need to call schedule service to wait until trips are created..
        // After this call, we should  call loadBookingsForSelectedExternalRiders api to get trips for newly created scheduled  booking

        if (promiseTimeAsyncOperationInfoArray) {
          for (const promiseTimeAsyncOperationInfo of promiseTimeAsyncOperationInfoArray) {
            await this.get('booking').callScheduleAsyncOperation(promiseTimeAsyncOperationInfo);
          }
        }

        await this.loadBookingsForSelectedExternalRiders();
        this.editModal.close();
        this.get('onClose')();
        const message = this.get('isEditMode') ? 'Booking updated Successfully' : 'New Booking successfully saved!';
        this.get('notifications').success(message);
      } catch (err) {
        const bookingFailedMessage = this.get('isEditMode') ? `Failed to update Booking ${err.message}` : `Failed to create Booking  ${err.message}`;
        const errorMsg = err.isLoadTripError ? err.message : bookingFailedMessage;
        this.get('notifications').warning(errorMsg);
        this.get('onClose')();
        this.editModal.close();
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    checkAndSetStatus(bookingStatus) {
      if (bookingStatus) {
        this.set('booking.activeBooking.status', bookingStatus);
      } else {
        this.set('booking.activeBooking.status', 'requested');
      }
    },

    buildTripAndReturnTripDetails() {
      const TIME_FORMAT = 'hh:mm A';
      const DATE_TIME_FORMAT = 'ddd,MM/DD/YYYY hh:mm A';
      const DATE_FORMAT = 'ddd,MM/DD/YYYY';
      const tripRequestDetails = this.get('tripLegs').map((tb, index) => {
        const {
          result: itpRequestResult
        } = (0, _lodash.find)(this.get('itpRequestResults'), itpResult => itpResult.legIndex === index) || {};
        const itinerarySegments = itpRequestResult && itpRequestResult.segments || [];
        const reqTime = tb.get('requestTime');
        return {
          tripDate: (0, _moment.default)(reqTime).format(DATE_FORMAT),
          requestTime: reqTime,
          formattedRequestTime: (0, _moment.default)(reqTime).format(TIME_FORMAT),
          itinerarySegments,
          segments: tb.get('segments').map(segment => {
            return {
              pickAddress: segment.get('pick.place.address.fullAddress'),
              dropAddress: segment.get('drop.place.address.fullAddress'),
              promiseTime: (0, _moment.default)(segment.get('formattedPromiseTime')).format(DATE_TIME_FORMAT),
              startTime: (0, _moment.default)(segment.get('tripStartTime')).format(TIME_FORMAT),
              arriveTime: (0, _moment.default)(segment.get('tripEndTime')).format(TIME_FORMAT)
            };
          })
        };
      });
      const returnTripDetails = this.get('returnTripBooking').map((rtb, index) => {
        const {
          result: itpRequestResult
        } = (0, _lodash.find)(this.get('itpRequestResults'), itpResult => itpResult.legIndex === index) || {};
        const itinerarySegments = itpRequestResult && itpRequestResult.segments || [];
        return {
          tripDate: (0, _moment.default)(rtb.get('requestTime')).format(DATE_FORMAT),
          requestTime: this.get('booking').getPromiseTime(this.get('returnTripIndex'), rtb),
          formattedRequestTime: (0, _moment.default)(rtb.get('requestTime')).format(TIME_FORMAT),
          itinerarySegments,
          segments: rtb.get('segments').map(segment => {
            return {
              pickAddress: segment.get('pick.place.address.fullAddress'),
              dropAddress: segment.get('drop.place.address.fullAddress'),
              promiseTime: (0, _moment.default)(segment.get('formattedPromiseTime')).format(DATE_TIME_FORMAT),
              startTime: (0, _moment.default)(segment.get('tripStartTime')).format(TIME_FORMAT),
              arriveTime: (0, _moment.default)(segment.get('tripEndTime')).format(TIME_FORMAT)
            };
          })
        };
      });
      this.set('tripDetails', tripRequestDetails);
      this.set('returnTripDetails', returnTripDetails);
      this.set('returnTripIndex', tripRequestDetails.length);
    },

    onSelectedTravelModeChange(legIndex, travelModeConfig) {
      const travelModeName = travelModeConfig.get('name').toLowerCase();
      this.get('booking').setSelectedTravelMode(legIndex, travelModeName);
      this.get('booking').setSelectedTravelModeInBooking(legIndex, travelModeName);
    },

    async loadBookingsForSelectedExternalRiders() {
      try {
        const [bookings, trips] = await this.get('apiContext').loadBookingsForSelectedExternalRiders();
        this.set('booking.currentBookings', bookings);
        this.set('booking.currentTrips', trips);
      } catch (e) {
        const message = 'Booking is saved. But failed to load bookings and trips'; // eslint-disable-next-line no-throw-literal

        throw {
          message: message,
          isLoadTripError: true
        };
      }
    },

    actions: {
      onClickOverlay() {//this.get('onClose')();
      },

      async closeDialog() {
        const bookingService = this.get('booking');
        const bookingId = bookingService.get('tempBookingInfo.tempBookingId');

        if (bookingService.get('tempBookingInfo.tempBookingId')) {
          try {
            const asynOperationInfoArray = await bookingService.deleteTemporaryBooking('x');
            this.get('workspace').set('isGlobalSpinnerVisible', true);

            for (const tripCancelInfo of asynOperationInfoArray) {
              if (tripCancelInfo) {
                await this.get('booking').callScheduleAsyncOperation(tripCancelInfo);
              }
            }

            await bookingService.deleteTrips(bookingId);
          } catch (err) {
            const errorMsg = err.isLoadTripError ? err.message : 'Failed to Delete Booking/Trips';
            this.get('notifications').warning(errorMsg);
          } finally {
            this.get('onClose')();
            this.get('workspace').set('isGlobalSpinnerVisible', false);
          }
        }
      },

      async onRefuseClick() {
        this.checkAndSetStatus('failed');
        this.saveBooking('failed');
      },

      async onAcceptClick() {
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        const bookingService = this.get('booking');
        const bookingId = bookingService.get('tempBookingInfo.tempBookingId'); // if taxi mode is selected, cancel trip in 'booking' schedule

        if (bookingService.isTaxiModeSelected()) {
          try {
            const tripCancelJobs = await bookingService.deleteBooking(bookingId, 'taxi');
            const asynOperationInfoArray = tripCancelJobs ? tripCancelJobs.tripCancelJobs : [];

            if (asynOperationInfoArray) {
              for (const tripCancelInfo of asynOperationInfoArray) {
                if (tripCancelInfo) {
                  await this.get('booking').callScheduleAsyncOperation(tripCancelInfo);
                }
              }
            }
          } catch (err) {
            const errorMsg = err.isLoadTripError ? err.message : 'Failed to Cancel Trips';
            this.get('workspace').set('isGlobalSpinnerVisible', false);
            this.get('notifications').warning(errorMsg);
          }
        } // if edit booking delete original booking trips


        if (this.get('isEditMode')) {
          const bookingID = this.get('booking.activeBooking.id');
          const tripCancelJobs = await this.get('booking').deleteBooking(bookingID, 'edit');
          const asynOperationInfoArray = tripCancelJobs ? tripCancelJobs.tripCancelJobs : [];

          for (const tripCancelInfo of asynOperationInfoArray) {
            if (tripCancelInfo) {
              await this.get('booking').callScheduleAsyncOperation(tripCancelInfo);
            }
          }

          await bookingService.deleteTrips(bookingID);
        } // call booking with promise time api if booking has promise time and hasParaTransitOrItpFeederModeSelected
        // In case of  Service Mode - Paratransit and  ITP  iternary type - 2 means This is a Feeder  itinerary, we should  call bookingWithPromiseTime .


        if (bookingService.get('enableNewPromiseTimeApi')) {
          this.checkAndSetStatus('scheduled');
          this.acceptBooking();
        }
      }

    }
  });

  _exports.default = _default;
});