define("adept-iq/pods/components/iq-widgets/trips-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/trips-widget/config", "adept-iq/pods/components/iq-widgets/trips-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/utils/find-row-record", "adept-iq/utils/unwrapProxy", "adept-iq/config/api-urls", "ember-concurrency", "moment", "lodash", "adept-iq/config/mapped-permIds", "adept-iq/mixins/async-schedule-operation", "adept-iq/mixins/async-booking-operation", "adept-iq/utils/rql-generators", "adept-iq/models/trip", "adept-iq/models/stop-point", "adept-iq/models/travel-need-type", "adept-iq/models/passenger-type"], function (_exports, _component, _config, _avlmConfig, _environment, _findRowRecord, _unwrapProxy, _apiUrls, _emberConcurrency, _moment, _lodash, _mappedPermIds, _asyncScheduleOperation, _asyncBookingOperation, _rqlGenerators, _trip, _stopPoint, _travelNeedType, _passengerType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = ['rider', 'cluster.route', 'stops', 'stops.place', 'rider.travelNeeds', 'rider.travelNeeds.travelNeedType', 'cluster', 'cluster.route,stops.stopEvents', 'fareType', 'serviceWindow'];

  var _default = _component.default.extend(_asyncScheduleOperation.default, _asyncBookingOperation.default, {
    dragCoordinator: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['trips-widget', 'data-test-trips-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    booking: Ember.inject.service(),
    notifications: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,
    noShowComponent: 'iq-widgets/noshow-event-stop-form-widget',
    cancelTripComponent: 'iq-widgets/cancel-type-form-widget',
    isScheduleDashboard: Ember.computed.readOnly('workspace.isScheduleDashboard'),
    isReconcileDashboard: Ember.computed.readOnly('workspace.isReconcileDashboard'),
    isDispatchDashboard: Ember.computed.readOnly('workspace.isDispatchDashboard'),
    routeexecevent: Ember.inject.service(),

    init() {
      const isDispatchDashboard = this.get('isDispatchDashboard');
      const isScheduleDashboard = this.get('isScheduleDashboard');
      const implicitConditions = _config.default.implicit.conditions;

      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      if (isDispatchDashboard) {
        const implicitConditionsForDispatch = [{
          type: 'stringEq',
          field: 'scheduleStatus',
          value: ['exported']
        }];
        _config.default.implicit.conditions = implicitConditions.concat(implicitConditionsForDispatch);
        this.set('config', _config.default);
      }

      if (isScheduleDashboard) {
        const restrictConditionForSchedule = ['scheduleStatus'];
        _config.default.implicit.conditions = implicitConditions.filter(condition => {
          if (restrictConditionForSchedule.includes(condition.field)) {
            return false;
          }

          return true;
        });
        this.set('config', _config.default);
      }

      this._super(...arguments);
    },

    saveNoShowRecordFunction(records) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripNoShowAdapter = store.adapterFor('trip-noshow');
      const notifications = this.get('notifications');
      return records.reduce((acu, record) => {
        const dsStop = (0, _unwrapProxy.unwrapProxy)(record);
        const noshow = (0, _unwrapProxy.unwrapProxy)(dsStop.get('noShow'));
        const noshowReason = (0, _unwrapProxy.unwrapProxy)(noshow.get('noShowReasonCode'));
        const trip = (0, _unwrapProxy.unwrapProxy)(dsStop.get('trip'));
        trip.set('noshowDetails', noshow.get('notes'));
        return acu.then(() => routeExecEventAdapter.createStopNoShowEvent(record)).then(() => tripNoShowAdapter.createTripNoShow(trip, noshowReason)).then(() => {
          notifications.success(`Successfully No Showed Trip ${dsStop.get('trip.tripId')}`);
        }).catch(e => {
          notifications.warning(`Failed to No Show Trip ${dsStop.get('trip.tripId')}`);
          throw new Error(e);
        });
      }, Promise.resolve());
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('tripTransferAction', {
        id: 'tripTransfer',
        permId: _mappedPermIds.default.transferTrip,
        name: 'Transfer Trip',
        action: trip => {
          if (this.get('isScheduleDashboard')) {
            this.get('workspace').pushState('schedulingTripTransfer', {
              trip
            });
            return;
          }

          this.get('workspace').pushState('tripTransfer', {
            trip
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('bulkTripTransferAction', [{
        id: 'bulkTripTransfer',
        permId: _mappedPermIds.default.transferTrip,
        name: 'Bulk Trip Transfer',
        action: trips => {
          if (this.get('isScheduleDashboard')) {
            this.get('workspace').pushState('schedulingBulkTripTransfer', {
              trips
            });
            return;
          }

          this.get('workspace').pushState('bulkTripTransfer', {
            trips
          });
        }
      }]);
      this.set('noShowAction', {
        id: 'noShow',
        permId: _mappedPermIds.default.noShowTrip,
        name: 'No Show',
        action: model => {
          const store = this.get('store');
          const pickStopPoint = model.get('pickStopPoint');
          const noShowReasonCodes = this.get('store').peekAll('no-show-reason-category'); // sort all no show reason code by the promise time start/end

          const sortedNoShowReasonCode = noShowReasonCodes.toArray().sort(function (a, b) {
            const aMinStartTime = Ember.isPresent(a.get('minutesFromPromiseTimeStart')) ? parseInt(a.get('minutesFromPromiseTimeStart'), 10) : 999;
            const aMinEndTime = Ember.isPresent(a.get('minutesFromPromiseTimeEnd')) ? parseInt(a.get('minutesFromPromiseTimeEnd'), 10) : 999;
            const bMinStartTime = Ember.isPresent(b.get('minutesFromPromiseTimeStart')) ? parseInt(b.get('minutesFromPromiseTimeStart'), 10) : 999;
            const bMinEndTime = Ember.isPresent(b.get('minutesFromPromiseTimeEnd')) ? parseInt(b.get('minutesFromPromiseTimeEnd'), 10) : 999;

            if (aMinStartTime > bMinStartTime && aMinEndTime > bMinEndTime) {
              return 1;
            } else if (aMinStartTime < bMinStartTime && aMinEndTime < bMinEndTime) {
              return -1;
            }

            return 0;
          }); // calculate the duration for current time and stop's ETA

          const actualArriveTime = pickStopPoint.get('actualArriveTime') ? (0, _moment.default)(pickStopPoint.get('actualArriveTime')) : (0, _moment.default)();
          const promisedTime = (0, _moment.default)(pickStopPoint.get('promisedTime'));

          const minDurations = _moment.default.duration(actualArriveTime.diff(promisedTime)).asMinutes();

          let calculatedNoShowReason = sortedNoShowReasonCode.find(code => {
            const aMinStartTime = Ember.isPresent(code.get('minutesFromPromiseTimeStart')) ? parseInt(code.get('minutesFromPromiseTimeStart'), 10) : 999;
            const aMinEndTime = Ember.isPresent(code.get('minutesFromPromiseTimeEnd')) ? parseInt(code.get('minutesFromPromiseTimeEnd'), 10) : 999;

            if (minDurations >= aMinStartTime && minDurations <= aMinEndTime) {
              return true;
            }

            return false;
          });

          if (!Ember.isPresent(calculatedNoShowReason)) {
            // if it's really early then pick one with the biggest range
            if (minDurations > 0) {
              calculatedNoShowReason = sortedNoShowReasonCode.lastObject;
            } else {
              calculatedNoShowReason = sortedNoShowReasonCode.firstObject;
            }
          }

          pickStopPoint.set('noShow', store.createRecord('no-show', {
            timestamp: new Date(),
            noShowReasonCode: calculatedNoShowReason
          }));
          pickStopPoint.set('minPerformedTime', new Date());

          if (!Ember.isPresent(calculatedNoShowReason.get('minutesFromPromiseTimeEnd'))) {
            pickStopPoint.set('noShowDropDownReadonly', false);
          } else {
            pickStopPoint.set('noShowDropDownReadonly', true);
          }

          const noShowComponent = this.get('noShowComponent'); // this.get('editModal').open(noShowComponent, [pickStopPoint], this.get('saveNoShowRecordFunction').bind(this), null, { title: 'Perform No Show' });

          this.get('editModal').open(noShowComponent, [pickStopPoint], this.get('saveNoShowRecordFunction').bind(this), null, null, {
            title: 'No Show Trip'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('unPerformNoShowAction', {
        id: 'unperform',
        permId: _mappedPermIds.default.unperformStop,
        name: 'Unperform No Show',
        action: model => {
          const workspace = this.get('workspace');
          const pickStopPoint = model.get('pickStopPoint');
          const title = 'Unperform No Show';
          workspace.pushState('unPerformNoShow', {
            stopPoint: pickStopPoint,
            title
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      });
      this.set('approveNoShowRequestAction', {
        id: 'approveNoShowRequest',
        permId: _mappedPermIds.default.approveNoShowRequest,
        name: 'Approve NoShow Request',
        action: trip => {
          this.get('workspace').pushState('noShowForm', {
            displayName: 'No Show Approval Form',
            trip,
            sectionId: 'approve'
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: true
      });
      this.set('denyNoShowRequestAction', {
        id: 'denyNoShowRequest',
        permId: _mappedPermIds.default.denyNoShowRequest,
        name: 'Deny NoShow Request',
        action: trip => {
          this.get('workspace').pushState('noShowForm', {
            displayName: 'No Show Denial Form',
            trip,
            sectionId: 'deny'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('waitlistAction', {
        id: 'waitlist',
        permId: _mappedPermIds.default.waitlist,
        name: 'Waitlist',
        action: model => {
          this.waitlistTrip(model);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('cancelAction', {
        id: 'cancelTrip',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: model => {
          const trip = model;
          const tripId = _environment.default.APP.avlmLite ? model.get('externalId') : model.get('tripId');
          const booking = (0, _unwrapProxy.unwrapProxy)(model.get('booking.content'));
          const segment = (0, _unwrapProxy.unwrapProxy)(model.get('segment'));
          booking.set('timestamp', new Date());
          this.get('workspace').pushState('cancelTripForm', {
            displayName: 'Cancel Trip',
            trip,
            booking,
            segment,
            tripId
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editAction', {
        id: 'edit',
        permIds: [_mappedPermIds.default.editFuture, _mappedPermIds.default.editPast, _mappedPermIds.default.editSameDay],
        name: 'Edit',
        action: trip => {
          this.get('workspace').pushState('editTripWidget', {
            trip
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('activityLogAction', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLog,
        name: 'View Activity Log',
        action: trip => {
          const id = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
          this.get('workspace').pushState('tripActivityLog', {
            displayName: `Trip ${id} Activity Log`,
            trip
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      this.set('autoRescheduleAction', {
        id: 'autoReschedule',
        permId: _mappedPermIds.default.autoReschedule,
        name: 'Auto Reschedule',
        action: trip => {
          this.autoRescheduleTrip(trip);
        },
        disableOnLocked: false,
        disableOnLockedRoute: true
      });
    },

    rowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    oneDayTimeline: Ember.computed('workspace.{startDate,endDate}', function () {
      const startDate = (0, _moment.default)(this.get('workspace.startDate')).format('YYYY-MMM-D');
      const endDate = (0, _moment.default)(this.get('workspace.endDate')).format('YYYY-MMM-D');
      return startDate === endDate;
    }),

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto widget's other elements.

      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).addClass('drag-target');
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
    },

    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      const targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('rowGroup'));
      this.assignCluster(targetRowRecord, sourceRowRecord);
    },

    assignCluster(tripRow, routeRow) {
      const tripRecord = tripRow.get('record');
      const routeRecord = routeRow.get('record');

      if (routeRecord.constructor.modelName !== 'route') {
        return;
      }

      let cluster = routeRecord.get('clusters').firstObject;

      if (Ember.isBlank(cluster)) {
        cluster = this.get('store').createRecord('cluster', {
          route: routeRecord
        });
        tripRecord.set('cluster', cluster);
        cluster.save();
      } else {
        tripRecord.set('cluster', cluster);
        tripRecord.save();
      }
    },

    performDispatchWaitlist(tripId) {
      const session = this.get('session');
      return this.get('ajax').delete(_apiUrls.API.dispatchService.host + '/trip/' + tripId + '/waitlist', {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    performScheduleWaitlist: (0, _emberConcurrency.task)(function* (trip) {
      const schedule = trip.get('route.schedule');
      const tripId = parseInt(trip.get('id'), 10);
      let result;

      try {
        const operationData = {
          tripId
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripWaitlist', options);

        if (!result) {
          throw new Error('Unable to place trip on waitlist.');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),

    performWaitlist(model) {
      if (this.get('isScheduleDashboard')) {
        return this.performScheduleWaitlist.perform(model);
      }

      return this.performDispatchWaitlist(model.get('id'));
    },

    waitlistTrip(model) {
      const notifications = this.get('notifications');
      const title = 'Waitlist Trip';
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        tip: `Are you sure you want to waitlist trip ${model.get('tripId')} ?`,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.performWaitlist(model).then(() => {
            notifications.success(`Successfully Waitlisted Trip ${model.get('tripId')}`);
            this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
          }).catch(() => {
            notifications.warning(`Failed to Waitlist Trip ${model.get('tripId')}`);
          }).finally(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    performAutoReschedule: (0, _emberConcurrency.task)(function* (model) {
      const schedule = model.get('isWaitlisted') ? model.get('schedule') : model.get('route.schedule');
      const tripId = parseInt(model.get('id'), 10);
      let asyncResult;
      const currentRouteId = parseInt(model.get('route.id'), 10);

      try {
        const operationData = {
          tripId,
          routes: [currentRouteId],
          routeUsage: 1
        };
        const options = {
          operationData,
          outputResult: true
        };
        const {
          isJobSuccess,
          results
        } = yield this.createScheduleAsyncOperation.perform(schedule, 'tripReschedule', options);
        asyncResult = isJobSuccess;

        if (!isJobSuccess) {
          const errMsg = 'Unable to reschedule trip in SES';

          if (results.includes(errMsg)) {
            throw new Error(errMsg);
          }

          throw new Error(`Trip ${model.get('tripId')} failed to reschedule`);
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(asyncResult);
    }),

    autoRescheduleTrip(model) {
      const title = 'Auto Reschedule';
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      const failureMsg = `Trip ${model.get('tripId')} failed to reschedule`;
      const isWaitlistedTripScheduled = model.get('isWaitlisted');
      const originalRouteId = model.get('route.id');
      const originalRouteName = model.get('route.name');
      tooltip.pushConfirmation({
        tip: `Are you sure you want to automatically reschedule trip  ${model.get('tripId')} ?`,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('performAutoReschedule').perform(model).then(() => {
            this.get('workspace').pushState('schedulingAutoReschedule', {
              trip: model,
              isWaitlistedTripScheduled: isWaitlistedTripScheduled,
              status: 'success',
              originalRouteId: originalRouteId,
              originalRouteName: originalRouteName
            });
          }).catch(err => {
            const errMsg = 'Unable to reschedule trip in SES';

            if (err.message === errMsg) {
              this.get('workspace').pushState('schedulingAutoReschedule', {
                trip: model,
                status: 'failure'
              });
              return;
            }

            notifications.warning(failureMsg);
          }).finally(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => tooltip.reset(),
        hasOverlay: true
      });
    },

    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,computedStatus}', 'selectedWidgetRecord', function () {
      const tripTransferAction = this.get('tripTransferAction');
      const noShowAction = this.get('noShowAction');
      const waitlistAction = this.get('waitlistAction');
      const activityLogAction = this.get('activityLogAction');
      const approveNoShowRequestAction = this.get('approveNoShowRequestAction');
      const denyNoShowRequestAction = this.get('denyNoShowRequestAction');
      const unPerformNoShowAction = this.get('unPerformNoShowAction');
      const autoRescheduleAction = this.get('autoRescheduleAction');
      const editTrip = this.get('editAction');
      const cancelTrip = this.get('cancelAction');
      const row = this.getSelectedCoreModel();
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      if (!row) return [];
      const tripStatus = selectedWidgetRecord.tripStatus;
      const isBrokerProviderType = row.get('provider.isBrokerProviderType');
      const pickStopStatus = row.get('pickStopPoint.computedStatus');
      const lastPerformedStopID = row.get('dispatchRoute.lastPerformedStop.trip.tripId');
      const currentRowTripID = this.get('lastRowClicked.tripId');

      switch (tripStatus) {
        case _trip.TRIP_SCHEDULED_STATUS:
          return [activityLogAction, tripTransferAction, editTrip, waitlistAction, autoRescheduleAction, cancelTrip];

        case _trip.TRIP_DISPATCH_STATUS:
          return [activityLogAction, tripTransferAction, editTrip, waitlistAction];

        case _trip.TRIP_ENROUTE_STATUS:
          if (pickStopStatus === _stopPoint.ARRIVE_STOP_STATUS) return [activityLogAction, noShowAction];
          return [activityLogAction, noShowAction, waitlistAction, tripTransferAction];

        case _trip.TRIP_ACTIVE_STATUS:
          return [activityLogAction, editTrip];

        case _trip.TRIP_WAITLIST_STATUS:
          if (isBrokerProviderType) {
            return [activityLogAction, editTrip];
          }

          return [activityLogAction, editTrip, autoRescheduleAction, tripTransferAction, cancelTrip];

        case _trip.TRIP_ILLEGAL_STATUS:
          return [activityLogAction, editTrip, cancelTrip];

        case _trip.TRIP_NOSHOW_STATUS:
          if (lastPerformedStopID === currentRowTripID) {
            return [activityLogAction, unPerformNoShowAction];
          }

          return [activityLogAction];

        case _trip.TRIP_NOSHOWREQUESTED_STATUS:
          return [approveNoShowRequestAction, denyNoShowRequestAction, activityLogAction, editTrip];

        case _trip.TRIP_COMPLETED_STATUS:
          return [activityLogAction];

        case _trip.TRIP_CANCELED_STATUS:
          return [activityLogAction, editTrip];

        default:
          return [activityLogAction];
      }
    }),

    // Hiding WaitList action for the broker trips
    addActions(actions, isBrokerProviderType, waitlistAction, autoRescheduleAction) {
      if (isBrokerProviderType) {
        return actions;
      }

      actions.unshift(waitlistAction);
      actions.unshift(autoRescheduleAction);
      return actions;
    },

    dynamicBulkActions: Ember.computed('checkedItems.[]', function () {
      const bulkTripTransferAction = this.get('bulkTripTransferAction');
      const isDispatchDashboard = this.get('isDispatchDashboard');
      const checkedRows = this.get('checkedItems') || [];

      if (!checkedRows.length) {
        return [];
      }

      const isScheduledRows = checkedRows.every(row => row.get('status') === 'scheduled');
      const isStopPerformed = isDispatchDashboard && checkedRows.some(selectedTrip => selectedTrip.get('isStopPointPerformed'));
      const isBrokerProviderType = checkedRows.every(row => row.get('provider.isBrokerProviderType')); //User must select only Scheduled trips for bulk TripTransfer

      if (!isScheduledRows || isStopPerformed || isBrokerProviderType) {
        return [];
      }

      return bulkTripTransferAction;
    }),

    calculateTimeQuery() {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const dateRangeFilterQuery = (0, _rqlGenerators.dateBetweenRQLUsingFormat)('promiseTime', [startTime, endTime], 'YYYY-MM-DDTHH:mm:ss.SSS');
      return dateRangeFilterQuery;
    },

    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) {
      let rowStyleHandler = '';

      if (currRowData.isLocked) {
        rowStyleHandler += ' is-locked';
      }

      if (columnIndex === 0) {
        if (currRowData.otpStatus === 'Late') {
          rowStyleHandler += ' otp-late-first-column';
        }

        if (currRowData.otpStatus === 'On Time') {
          rowStyleHandler += ' otp-on-time-first-column';
        }

        if (currRowData.otpStatus === 'In Danger') {
          rowStyleHandler += ' otp-in-danger-first-column';
        }
      }

      return rowStyleHandler;
    },

    canFurtherSelectionAllowed() {
      const gridConfig = this.get('config');
      const maxLimitByRouteSelection = gridConfig.maxLimitByRouteSelection;
      const maxLimitWithoutRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;
      const selectedTripRecords = this.get('widgetService').getSelectedWidgetRecordsByModel('tripWidget');
      let tripAssociatedRouteIds = selectedTripRecords.filter(data => !!data.routeId).map(data => data.routeId);
      tripAssociatedRouteIds = _lodash.default.uniq(tripAssociatedRouteIds);

      if (tripAssociatedRouteIds.length >= maxLimitByRouteSelection) {
        return false;
      }

      if (selectedTripRecords.length >= maxLimitWithoutRouteAssociation) {
        return false;
      }

      return true;
    },

    applyNoShowReasonTransformation(widgetRecord) {
      const noShowReason = widgetRecord.noShowReasonCode;

      if (Ember.isPresent(noShowReason)) {
        const noShowReasonCategories = this.get('store').peekAll('no-show-reason-category');
        const noShowCategory = noShowReasonCategories.findBy('name', noShowReason);

        if (Ember.isPresent(noShowCategory)) {
          widgetRecord.noShowReasonDisplay = noShowCategory.get('displayName');
        } else {
          // If not found then set noShowReason directly
          widgetRecord.noShowReasonDisplay = noShowReason;
        }
      }
    },

    applyTravelNeedsTransformation(widgetRecord) {
      const travelNeeds = widgetRecord.travelNeeds || [];
      /* eslint-disable newline-after-var */

      let ambSeats = 0;
      let wcSeats = 0;
      let companions = 0;
      let pca = 0;
      let passengers = 0;
      /* eslint-enable newline-after-var */

      travelNeeds.forEach(travelNeed => {
        const passngerType = travelNeed.passengerTypeName;
        const travelNeedType = travelNeed.travelNeedTypeName;
        let count = 0;

        if (Ember.isPresent(travelNeed.count)) {
          count = parseInt(travelNeed.count, 10);
        }

        if (passngerType === _passengerType.CLIENT_PASSENGER_TYPE) {
          if (travelNeedType === _travelNeedType.AMBULATORY_TRAVELNEED_TYPE) ambSeats += count;
          if (travelNeedType === _travelNeedType.WHEELCHAIR_TRAVELNEED_TYPE) wcSeats += count;
        }

        if (passngerType === _passengerType.COMPANION_PASSENGER_TYPE) {
          companions += count;
        }

        if (passngerType === _passengerType.PCA_PASSENGER_TYPE) {
          pca += count;
        }
      });
      passengers = ambSeats + wcSeats + companions + pca;
      widgetRecord.ambSeats = ambSeats;
      widgetRecord.wcSeats = wcSeats;
      widgetRecord.companions = companions;
      widgetRecord.pca = pca;
      widgetRecord.passengers = passengers;
    },

    applyTransformationData(widgetRecords) {
      widgetRecords.forEach(widgetRecord => {
        if (!widgetRecord._transformed) {
          this.applyOtpStatusTransformation(widgetRecord);
          this.applyTravelNeedsTransformation(widgetRecord);
          this.applyNoShowReasonTransformation(widgetRecord);

          if (widgetRecord.hasOwnProperty('legNotes')) {
            widgetRecord.driverNotes = widgetRecord.legNotes;
          }

          if (this.get('isScheduleDashboard')) {
            this.applyRouteTransformation(widgetRecord);
          }

          widgetRecord._transformed = true;
        }
      });
      return widgetRecords;
    },

    /**
     * do not show Trip in TripWidget whoes Route ID contains - (SUP-)
     * @param widgetRecords
     * @param header
     * @param data
     * @returns {string|void|*}
     */
    determineWidgetRecordAction(widgetRecords, header, data) {
      if (data.isSupRoute) {
        return 'DELETE';
      }

      if (data.status === 'Cancelled') {
        return 'DELETE';
      }

      if (data.status === 'Removed') {
        return 'DELETE';
      }

      return this._super(...arguments);
    },

    applyRouteTransformation(widgetRecord) {
      if (widgetRecord.originalRouteId && widgetRecord.tripStatus === _trip.TRIP_SCHEDULED_STATUS) {
        widgetRecord.routeId = widgetRecord.originalRouteId;
        widgetRecord.routeName = widgetRecord.originalRouteName;
      }

      if (!widgetRecord.routeName && widgetRecord.routeId) {
        const route = this.get('store').peekRecord('route', widgetRecord.routeId);

        if (route) {
          widgetRecord.routeName = route.get('name');
        }
      }
    }

  });

  _exports.default = _default;
});