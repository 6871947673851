define("adept-iq/pods/components/side-drawer/trip-transfer/component", ["exports", "ember-light-table", "adept-iq/utils/unwrapProxy", "ember-concurrency", "adept-iq/pods/components/side-drawer/trip-transfer/config", "moment", "adept-iq/classes/map-contexts/side-drawers/trip-transfer", "adept-iq/classes/impact-stop", "lodash"], function (_exports, _emberLightTable, _unwrapProxy, _emberConcurrency, _config, _moment, _tripTransfer, _impactStop, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    map: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    coreEntityPopulator: Ember.inject.service(),
    // passed by the side drawer container component
    onShrinkClick: null,
    onCloseClick: null,
    currentState: null,
    // component properties
    solutionRoutesTable: null,
    stopsTable: null,
    routeOverrideOptions: null,
    overrideSelectedRoute: null,
    overrideSelectedProvider: null,
    previousSelectedProvider: null,
    overrideSelectedPickOrder: null,
    overrideSelectedDropOrder: null,
    isBrokerProviderType: false,
    errorText: null,
    solutions: null,
    impact: null,
    selectedSolutionId: null,
    selectedOverrideSolutionId: null,
    selectedImpactPeriod: 'after',
    activeTabRouteId: null,
    currentSolutionDispatchRoute: null,
    override: false,
    title: 'Transfer Trip',
    componentName: 'tripTransfer',
    canShrink: Ember.computed.not('isSolutionsTaskRunning'),
    isShrunken: Ember.computed.alias('workspace.isDrawerShrunken'),
    //passed  by the workspace service
    trip: Ember.computed.readOnly('stackFrame.options.trip'),
    //Trips could be an array sometimes
    currentDispatchRoute: Ember.computed.readOnly('currentRoute.dispatchRoute'),
    currentSolutionRoute: Ember.computed.readOnly('currentSolutionDispatchRoute.route'),
    overrideSelectedDispatchRoute: Ember.computed.readOnly('overrideSelectedRoute.dispatchRoute'),
    previousSelectedTripProvider: Ember.computed.readOnly('trip.provider'),
    isNotOverride: Ember.computed('override', function () {
      return !this.get('override');
    }),
    canDisableOverrideSectionFields: Ember.computed('isNotOverride', 'overrideSelectedProvider', function () {
      const isNotOverride = this.get('isNotOverride');
      const overrideSelectedProvider = this.get('overrideSelectedProvider');

      if (isNotOverride || overrideSelectedProvider && overrideSelectedProvider.get('isBrokerProviderType')) {
        return true;
      }

      return false;
    }),
    canSave: Ember.computed('selectedSolutionId', 'overrideSelectedProvider', 'overrideSelectedPickOrder', 'overrideSelectedDropOrder', function () {
      const selectedSolutionId = this.get('selectedSolutionId');
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
      const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');

      if (!Ember.isEmpty(selectedSolutionId)) {
        return true;
      }

      if (overrideSelectedProvider && overrideSelectedProvider.get('isBrokerProviderType') || overrideSelectedPickOrder && overrideSelectedDropOrder) {
        return true;
      }

      return false;
    }),
    isSolutionsTaskRunning: Ember.computed('fetchSolutionsTask.isRunning', 'fetchManualSolutionsTask.isRunning', function () {
      return this.get('fetchSolutionsTask.isRunning') || this.get('fetchManualSolutionsTask.isRunning');
    }),
    isSolutionsTaskCanceled: Ember.computed('fetchSolutionsTask.lastCanceled', 'fetchManualSolutionsTask.lastCanceled', function () {
      return Ember.isPresent(this.get('fetchSolutionsTask.lastCanceled')) || Ember.isPresent(this.get('fetchManualSolutionsTask.lastCanceled'));
    }),
    currentRoute: Ember.computed('trip.pickStopPoint.dispatchRoute.route', function () {
      const route = this.get('trip.pickStopPoint.dispatchRoute.route');

      if (!route) {
        return this.get('noRoute');
      }

      return route;
    }).readOnly(),

    init() {
      this._super(...arguments);

      const solutionRoutesTable = new _emberLightTable.default(_config.solutionColumns);
      const stopsTable = new _emberLightTable.default(_config.stopColumns);
      this.set('solutionRoutesTable', solutionRoutesTable); // eslint-disable-next-line new-cap

      this.set('solutions', Ember.A([]));
      this.set('stopsTable', stopsTable); // run-time bindings; this avoids having a ton of observers

      const mapContext = _tripTransfer.default.extend({
        trip: Ember.computed.readOnly('_component.trip'),
        destinationIqRoute: Ember.computed.readOnly('_component.selectedSolutionDispatchRoute'),
        currentIqRoute: Ember.computed.readOnly('_component.currentDispatchRoute'),
        impactPayload: Ember.computed.readOnly('_component.impact'),
        inDanger: Ember.computed.readOnly('_component.inDanger'),
        selectedImpactPeriod: Ember.computed.readOnly('_component.selectedImpactPeriod'),
        // 'before' or 'after',
        selectedImpactView: Ember.computed.readOnly('_component.selectedImpactView') // 'current' or 'destination'

      }).create({
        _component: this
      });

      this.set('mapContext', mapContext);
      this.setOverrideProviderOptions();
    },

    setOverrideProviderOptions() {
      const selectedProvider = this.get('trip.provider');
      const overrideProviderOptions = this.get('store').peekAll('provider').filter(provider => {
        let canAllowProvider = provider.get('userCanAccessProvider');

        if (canAllowProvider && selectedProvider.get('isBrokerProviderType')) {
          canAllowProvider = selectedProvider.get('id') !== provider.get('id');
        }

        return canAllowProvider;
      });
      this.set('overrideProviderOptions', overrideProviderOptions);
    },

    didInsertElement() {
      this._super(...arguments);

      const mapContext = this.get('mapContext'); // side panel width just for trip transfer

      Ember.$('.side-drawer-modal').width('600px');
      this.get('map').pushContext(mapContext);
      this.loadCurrentState(); // skip auto trip transfer if the depart time is earlier than now.

      if ((0, _moment.default)(this.get('trip.pickStopPoint.departTime')).isAfter((0, _moment.default)())) {
        this.setupSolutions();
      }
    },

    willDestroyElement() {
      const mapContext = this.get('mapContext');
      this.get('map').removeContext(mapContext);

      this._super(...arguments);
    },

    destroy() {
      this.get('mapContext').set('_component', null);

      this._super(...arguments);
    },

    isFoundSolution(route) {
      const solutions = this.get('solutions');
      const isFound = solutions.findBy('id', route.get('dispatchRoute.id'));
      return Ember.isPresent(isFound);
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('override', currentState.override);
        this.set('overrideSelectedProvider', currentState.overrideSelectedProvider);
        this.set('overrideSelectedRoute', currentState.overrideSelectedRoute);
        this.set('overrideSelectedPickOrder', currentState.overrideSelectedPickOrder);
        this.set('overrideSelectedDropOrder', currentState.overrideSelectedDropOrder);

        if (currentState.currentRouteSolution) {
          this.set('currentRouteSolution', currentState.currentRouteSolution);
        }

        if (currentState.overrideSelectedRoute) {
          this.selectOverrideSolution();
        }
      }
    },

    // creates impact after payload
    createManualSolution(route, trip = null) {
      const dsTrip = this.get('trip');
      let dsRouteId = null;
      let stops = [];

      if (Ember.isPresent(route)) {
        dsRouteId = route.get('dispatchRoute.id');
        stops = route.get('dispatchRoute.orderedStopsWithBreaks');
      } // current route impact after remove transferrable trip


      if (dsTrip.get('pickStopPoint.dispatchRoute.id') === dsRouteId) {
        stops = stops.filter(stop => stop.get('trip.id') !== dsTrip.get('id'));
      }

      const newSolution = {
        id: dsRouteId,
        gain: 'unknown',
        otp: {
          delta: 'unknown',
          originalValue: 'unknown',
          newValue: 'unknown'
        },
        stops: [],
        recipe: null
      };

      if (!Ember.isEmpty(stops)) {
        stops.forEach(stop => {
          newSolution.stops.push({
            eta: {
              originalValue: null,
              newValue: null
            },
            otp: {
              originalValue: null,
              newValue: null,
              delta: null
            },
            promiseTime: null,
            id: stop.get('id'),
            polyline: null
          });
        });
      }

      if (trip) {
        const pick = {
          eta: {
            originalValue: null,
            newValue: null
          },
          otp: {
            originalValue: null,
            newValue: null,
            delta: null
          },
          promiseTime: null,
          id: trip.get('pickStopPoint.id'),
          polyline: null
        };
        const drop = {
          eta: {
            originalValue: null,
            newValue: null
          },
          otp: {
            originalValue: null,
            newValue: null,
            delta: null
          },
          promiseTime: null,
          id: trip.get('dropStopPoint.id'),
          polyline: null
        };
        newSolution.stops.push(pick);
        newSolution.stops.push(drop);
        newSolution.recipe = this.createManualRecipe([pick, drop], route, trip);
      }

      return newSolution;
    },

    createManualRecipe(stops, route, trip) {
      // last stop ordinal needs to be at least greater than last actual stop ordinal, it doesn't matter as longer as its bigger
      // cos will be able to normalize it and put it at the end
      const lastStopOrdinal = route.get('dispatchRoute.orderedStopsWithGaragesAndBreaks.length') + 100;
      const destinationId = route.get('dispatchRoute.id');
      let currentSourceId = this.get('currentDispatchRoute.id');

      if (trip.get('isCanceled')) {
        currentSourceId = 'cancel';
      } else if (trip.get('isWaitlisted')) {
        currentSourceId = 'waitlist';
      }

      const recipe = {
        instructions: stops.map((stop, index) => {
          return {
            id: stop.id,
            type: 'stop',
            source: currentSourceId,
            destination: destinationId,
            ordinal: lastStopOrdinal + index
          };
        })
      };
      return recipe;
    },

    noRoute: Ember.computed('trip.{isCanceled,isWaitlisted}', function () {
      const trip = this.get('trip');
      const pickStopPoint = trip.get('pickStopPoint');
      const dropStopPoint = trip.get('dropStopPoint');
      const schedule = this.get('store').peekAll('schedule').find(sch => Ember.isPresent((0, _unwrapProxy.unwrapProxy)(sch.get('dispatchSchedule'))));

      if (trip.get('isCanceled') || trip.get('isWaitlisted')) {
        return Ember.Object.create({
          id: null,
          name: 'No Route',
          schedule,
          dispatchRoute: {
            orderedStopsWithBreaks: [pickStopPoint, dropStopPoint],
            iqRoute: this
          }
        });
      }

      return null;
    }),
    overridePickOrderOptions: Ember.computed('overrideSelectedRoute', function () {
      const dispatchRoute = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.dispatchRoute'));

      if (!Ember.isEmpty(dispatchRoute)) {
        const unperformedStops = dispatchRoute.getUnArrivedStopsInSequence(0) || [];
        const lastPerformedStop = dispatchRoute.get('lastPerformedStop');
        const routeOrdinals = [];
        unperformedStops.forEach(stop => {
          routeOrdinals.push(stop.get('routeOrdinal'));
        });

        if (unperformedStops.length === 0 && Ember.isNone(lastPerformedStop)) {
          routeOrdinals.push(1);
        } else if (unperformedStops.length === 0 && Ember.isPresent(lastPerformedStop)) {
          routeOrdinals.push(lastPerformedStop.get('routeOrdinal') + 1);
        } else if (unperformedStops.length > 0) {
          routeOrdinals.push(Math.max(...routeOrdinals) + 1);
        }

        return routeOrdinals;
      }

      return [];
    }),
    overrideDropOrderOptions: Ember.computed('overrideSelectedPickOrder', function () {
      const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
      const dispatchRoute = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.dispatchRoute'));

      if (!Ember.isEmpty(overrideSelectedPickOrder) && !Ember.isEmpty(dispatchRoute)) {
        const unperformedStops = dispatchRoute.getUnArrivedStopsInSequence(overrideSelectedPickOrder) || [];
        const routeOrdinals = [];
        unperformedStops.forEach(stop => {
          routeOrdinals.push(stop.get('routeOrdinal'));
        });

        if (unperformedStops.length > 0) {
          routeOrdinals.push(Math.max(...routeOrdinals) + 1);
        }

        if (routeOrdinals.length > 0) {
          return routeOrdinals;
        }

        return [overrideSelectedPickOrder + 1];
      }

      return [];
    }),

    async populateRoutesList(scheduleId) {
      const scheduleRecord = await this.get('store').findRecord('schedule', scheduleId, {
        include: 'routes'
      });
      const routes = scheduleRecord.get('routes').toArray();
      this.set('routesList', routes);
    },

    overrideRouteOptions: Ember.computed('currentRoute', 'overrideSelectedProvider', async function () {
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const sourceRouteId = this.get('currentRoute.id');
      const sourceTripScheduleId = this.get('trip.schedule.id');
      await this.populateRoutesList(sourceTripScheduleId);
      const routes = this.get('routesList');

      if (Ember.isNone(routes)) {
        return [];
      }

      return _lodash.default.sortBy(routes.filter(route => {
        const routeScheduleId = route.get('schedule.id');
        const routeId = route.get('id');
        const sameSchedule = routeScheduleId === sourceTripScheduleId;
        let sameProvider = false;

        if (!Ember.isEmpty(overrideSelectedProvider)) {
          sameProvider = overrideSelectedProvider.id === route.get('provider.id');
        }

        const differentId = routeId !== sourceRouteId;
        return sameSchedule && differentId && sameProvider;
      }), route => route.name);
    }),
    selectedGain: Ember.computed('selectedSolutionId', 'solutions.[]', function () {
      const solutions = this.get('solutions');
      if (!solutions) return 0;
      const solution = solutions.findBy('id', this.get('selectedSolutionId')); // TODO: update to use delta otp value

      if (solution) {
        return solution.otp.deltaValue;
      }

      return 0;
    }),
    selectedGainType: Ember.computed('impactScore', function () {
      let gainType = 'spending';

      if (this.get('impactScore') > 0) {
        gainType = 'gaining';
      }

      return gainType;
    }),
    impactScore: Ember.computed('selectedSolutionId', 'selectedImpactPeriod', 'impact', function () {
      const impact = this.get('impact');
      const period = this.get('selectedImpactPeriod');
      if (!impact) return null;
      let score = impact.otpDelta ? impact.otpDelta : null;
      if (period === 'before') score = -score; // transform after back to negative value

      return score > 0 ? -score : Math.abs(score); // transform score based on positive being late and negative being positive
    }),
    impactScoreType: Ember.computed('impactScore', function () {
      let impactType = 'negative';

      if (this.get('impactScore') > 0) {
        impactType = 'positive';
      }

      return impactType;
    }),
    currentRouteImpactScoreType: Ember.computed('impactScore', function () {
      let impactType = 'spending';

      if (this.get('impactScore') > 0) {
        impactType = 'saving';
      }

      return impactType;
    }),
    selectedSolutionDispatchRoute: Ember.computed('solutions', 'selectedSolutionId', function () {
      const solutions = this.get('solutions');
      const selectedSolutionId = this.get('selectedSolutionId');
      if (Ember.isEmpty(solutions) || Ember.isEmpty(selectedSolutionId)) return null;
      const route = this.get('store').peekRecord('route', selectedSolutionId);
      const dsRoute = this.get('store').peekRecord('dispatch-route', route.get('dispatchRoute.id'));
      return dsRoute;
    }),
    inDanger: Ember.computed('solutions', 'selectedSolutionId', function () {
      const solutions = this.get('solutions');
      if (Ember.isEmpty(solutions)) return false; // TODO: connect to real data here

      const selectedSolutionId = this.get('selectedSolutionId');
      const index = solutions.mapBy('id').indexOf(selectedSolutionId);
      return index % 2 === 1;
    }),

    setupSolutions() {
      this.set('errorText');
      const trip = this.get('trip');
      const store = this.get('store');
      const currentState = this.get('currentState');
      let currentSolutions = null;
      let currentSolutionRouteId = null;

      if (currentState) {
        currentSolutions = currentState.solutions ? this.currentState.solutions : null;
        currentSolutionRouteId = currentState.currentSolutionRoute ? currentState.currentSolutionRoute.get('id') : null;
      }

      this.cursorWait();
      this.get('fetchSolutionsTask').perform(trip).catch(err => {
        // if the side drawer have been close while doing fetch
        if (this.get('isSolutionsTaskCanceled')) return null; // Try to be as robust as possible.  Log an error to console for discovery,
        // but just use an empty solution set to unblock manually transferring

        this.set('errorText', 'The system encountered an error, please try again. If the problem persists contact administrator.');
        console.warn(`Unable to fetch trip transfer solutions: ${JSON.stringify(err)}`); // eslint-disable-line no-console

        return null;
      }).then(solutions => {
        let solutionsTableRows = []; // eslint-disable-next-line no-param-reassign

        solutions = currentState ? currentSolutions : solutions; // reset stops table data

        this.set('stopsTable.rows', []); // give it a bit of time for everything to get loaded to ember store

        Ember.run.later(() => {
          if (!Ember.isEmpty(solutions)) {
            solutionsTableRows = solutions.map(({
              id,
              otp
            }) => {
              const selectedRoute = store.peekRecord('route', id);
              return {
                id,
                otp,
                dispatchRoute: selectedRoute.get('dispatchRoute')
              };
            });
            this.get('solutions').pushObjects(solutions);
          }

          this.get('solutionRoutesTable').setRows(solutionsTableRows);

          if (!Ember.isEmpty(solutions)) {
            const firstSolutionId = currentSolutionRouteId ? solutions.find(solution => solution.id === currentSolutionRouteId).id : solutions[0].id;
            this.selectTableSolutionRow(firstSolutionId);
            this.selectSolution(firstSolutionId);
          }
        }, 500);
        this.cursorDefault();
      }).catch(() => {
        this.get('notifications').info(`Trip ${trip.get('tripId')} cannot be transferred`);
        this.cursorDefault();
        return this.onCloseClick();
      });
      this.fetchRouteOverrideOptions();
    },

    fetchRouteOverrideOptions() {
      const sourceRouteIdValue = this.get('currentRoute.id');
      const sourceRouteScheduleIdValue = this.get('currentRoute.schedule.id');
      if (!sourceRouteIdValue) return null;
      const store = this.get('store');
      const routes = store.peekAll('route').filter(route => {
        const routeScheduleIdValue = route.get('schedule.id');
        const routeIdValue = route.get('id');
        const sameSchedule = routeScheduleIdValue === sourceRouteScheduleIdValue;
        const differentId = routeIdValue !== sourceRouteIdValue;
        return sameSchedule && differentId;
      });
      this.set('routeOptions', routes);
      return routes;
    },

    fetchSolutionsTask: (0, _emberConcurrency.task)(function* (trip, routeId) {
      const tripTransferAdapter = yield this.get('store').adapterFor('trip-transfer');
      const dispatchScheduleId = trip.get('schedule.dispatchSchedule.id') ? parseInt(trip.get('schedule.dispatchSchedule.id'), 10) : null;
      const tripId = trip.get('id') ? parseInt(trip.get('id'), 10) : null;
      const result = yield tripTransferAdapter.postTransfer([tripId], dispatchScheduleId, routeId ? parseInt(routeId, 10) : routeId);

      if (!Ember.isEmpty(result.data)) {
        const currentRouteSolution = result.data.attributes.sourceRoute;
        const solutions = result.data.attributes.destinationRoutes; // get core entity records from solution result

        return this.getRouteResults(solutions).then(() => {
          this.set('currentRouteSolution', currentRouteSolution);
          return solutions;
        });
      }

      return;
    }).keepLatest(),

    async getRouteResults(solutions) {
      const coreEntityPopulator = this.get('coreEntityPopulator');
      const solutionPromises = [];
      solutions.forEach(solution => {
        const routeId = solution.id;
        solutionPromises.push(coreEntityPopulator.populateRtsRoute(routeId, {}));
      });
      await Promise.all(solutionPromises);
    },

    fetchManualSolutionsTask: (0, _emberConcurrency.task)(function* (trip, pickOrdinal, dropOrdinal, routeId) {
      const tripTransferAdapter = yield this.get('store').adapterFor('trip-transfer');
      const payloadData = [];
      const dispatchScheduleId = trip.get('schedule.dispatchSchedule.id') ? parseInt(trip.get('schedule.dispatchSchedule.id'), 10) : null;
      const id = trip.get('id') ? parseInt(trip.get('id'), 10) : null;
      payloadData.push({
        id,
        pickOrdinal,
        dropOrdinal
      });
      const result = yield tripTransferAdapter.postManualTransfer(payloadData, dispatchScheduleId, routeId ? parseInt(routeId, 10) : routeId);

      if (!Ember.isEmpty(result.data)) {
        const currentRouteSolution = result.data.attributes.sourceRoute;
        const solutions = result.data.attributes.destinationRoutes; // get core entity records from solution result

        return this.getRouteResults(solutions).then(() => {
          this.set('currentRouteSolution', currentRouteSolution);
          return solutions;
        });
      }

      return;
    }).keepLatest(),
    currentRouteImpact: Ember.computed('currentRouteSolution', function () {
      const store = this.get('store');
      const currentSolution = this.get('currentRouteSolution') || {};
      const currentStops = this.get('currentDispatchRoute.orderedStopsWithBreaks') || []; // @TODO we should refactor to make it more flexible for map context

      const impact = Ember.Object.create({
        routeOtp: currentSolution.otp,
        otpDelta: currentSolution.otp.deltaValue,
        before: {
          otp: currentSolution.otp.originalValue,
          stops: currentStops.map(stop => {
            const stopPoint = store.peekRecord('stop-point', stop.id);
            return _impactStop.default.create({
              id: stop.id,
              eta: stopPoint.get('eta'),
              otp: stop.get('otp'),
              promiseTime: stop.get('promisedTime'),
              otpValue: stop.get('otpValue'),
              polyline: null,
              mrt: null,
              routeOrdinal: stopPoint.get('routeOrdinal'),
              stopPoint: stopPoint
            });
          })
        },
        after: {
          otp: currentSolution.otp.newStatus,
          stops: currentSolution.stops.map(stop => _impactStop.default.create({
            id: stop.id,
            eta: stop.eta.newValue,
            otp: stop.otp.newStatus,
            otpValue: stop.otp,
            mrt: stop.otp.delta,
            promiseTime: stop.promiseTime,
            polyline: stop.polyline,
            routeOrdinal: stop.ordinal,
            stopPoint: store.peekRecord('stop-point', stop.id)
          }))
        }
      });
      return impact;
    }),
    selectedRouteImpact: Ember.computed('currentSolutionDispatchRoute', function () {
      const store = this.get('store');
      const selectedRouteId = this.get('currentSolutionDispatchRoute.route.id');
      const solutions = this.get('solutions') || [];
      const selectedSolution = solutions.findBy('id', selectedRouteId) || {};
      const currentStops = this.get('currentSolutionDispatchRoute.orderedStopsWithBreaks') || []; // @TODO we should refactor to make it more flexible for map context

      const impact = Ember.Object.create({
        routeOtp: selectedSolution.otp,
        otpDelta: selectedSolution.otp.deltaValue,
        before: {
          otp: selectedSolution.otp.originalValue,
          stops: currentStops.map(stop => {
            const stopPoint = store.peekRecord('stop-point', stop.id);
            return _impactStop.default.create({
              id: stop.id,
              eta: stopPoint.get('eta'),
              otp: stop.get('otp'),
              promiseTime: stop.get('promisedTime'),
              otpValue: stop.get('otpValue'),
              polyline: null,
              mrt: null,
              routeOrdinal: stopPoint.get('routeOrdinal'),
              stopPoint: stopPoint
            });
          })
        },
        after: {
          otp: selectedSolution.otp.newValue,
          stops: selectedSolution.stops.map(stop => {
            let stopPoint = null;

            if (stop.id.includes('B')) {
              const stopId = stop.id.replace('B', '');
              stopPoint = store.peekRecord('route-break', stopId).stopPoint;
            } else {
              stopPoint = store.peekRecord('stop-point', stop.id);
            }

            return _impactStop.default.create({
              id: stop.id,
              eta: stop.eta.newValue,
              otp: stop.otp.newStatus,
              otpValue: stop.otp,
              mrt: stop.otp.delta,
              promiseTime: stop.promiseTime,
              polyline: stop.polyline,
              routeOrdinal: stop.ordinal,
              stopPoint: stopPoint
            });
          })
        }
      });
      return impact;
    }),

    chooseImpactByRoute(routeId) {
      const solutions = this.get('solutions');
      const period = this.get('selectedImpactPeriod');
      const currentRoute = this.get('currentRoute');
      const currentRouteSolution = this.get('currentRouteSolution');
      const currentTripId = this.trip.tripId;
      let selectedSolution = null;
      let impact = null; // Handle choosing the current route

      if (!Ember.isNone(currentRoute) && routeId === currentRoute.get('dispatchRoute.route.id')) {
        selectedSolution = currentRouteSolution;

        if (Ember.isEmpty(selectedSolution)) {
          if (this.get('trip.status') === 'waitlisted' && period === 'before') {
            this.get('stopsTable').setRows(this.get('trip.stops'));
            return;
          }

          this.get('stopsTable').setRows([]);
          return;
        }

        impact = this.get('currentRouteImpact');
      } else {
        selectedSolution = solutions.findBy('id', routeId);
        if (Ember.isEmpty(selectedSolution)) return;
        impact = this.get('selectedRouteImpact');
      }

      this.set('impact', impact);
      const rows = this.get('stopsTable').setRows(impact[period].stops);
      rows.map(stop => {
        if (!Ember.isNone(stop.get('stopPoint')) && stop.get('stopPoint.trip.tripId') === currentTripId) {
          return stop.set('selected', true);
        }
      });
      return impact;
    },

    saveTask: (0, _emberConcurrency.task)(function* () {
      const solutions = this.get('solutions');
      const selectedSolutionId = this.get('currentSolutionDispatchRoute.route.id');
      const recipe = solutions.findBy('id', selectedSolutionId).recipe;
      const bulkOperationAdapter = this.get('store').adapterFor('bulk-operation');
      return yield bulkOperationAdapter.createBulkAsyncOperation.perform(recipe).then(success => {
        if (success) {
          return Promise.resolve();
        }

        return Promise.reject('Transferring trip to new route failed');
      }).catch(function (error) {
        return Promise.reject(error);
      });
    }),

    selectSolution(routeId) {
      const selectedRoute = this.get('store').peekRecord('route', routeId);
      const selectedDispatchRoute = this.get('store').peekRecord('dispatch-route', selectedRoute.get('dispatchRoute.id'));
      this.setProperties({
        selectedSolutionId: routeId,
        selectedImpactPeriod: 'after',
        activeTabRouteId: routeId,
        currentSolutionDispatchRoute: selectedDispatchRoute
      });
      this.chooseImpactByRoute(routeId);
    },

    observeSelectImpactPeriod: Ember.observer('selectedImpactPeriod', function () {
      const period = this.get('selectedImpactPeriod');
      const selectedRouteId = this.get('activeTabRouteId'); // TODO: Can the table rows be updated without an observer?

      this.get('stopsTable').setRows(this.get(`impact.${period}.stops`)); // revaluate impact

      this.chooseImpactByRoute(selectedRouteId);
    }),

    selectTableSolutionRow(routeId) {
      const rows = this.get('solutionRoutesTable.rows');
      const currentSelectedRow = rows.findBy('selected');
      const rowToSelect = rows.findBy('id', routeId);
      if (rowToSelect === currentSelectedRow) return;

      if (currentSelectedRow) {
        currentSelectedRow.set('selected', false);
      }

      rowToSelect.set('selected', true);
      this.selectSolution(routeId);
    },

    cursorWait() {
      Ember.$('html,body').css('cursor', 'wait');
    },

    cursorDefault() {
      Ember.$('html,body').css('cursor', 'default');
    },

    selectOverrideSolution() {
      const route = this.get('overrideSelectedRoute');
      const previousSelectedTripProvider = this.get('previousSelectedTripProvider');
      const routeId = route.get('id');
      const routeName = route.get('name');
      const trip = this.get('trip');
      const currentSelectedRow = this.get('solutionRoutesTable.rows').findBy('selected');
      const store = this.get('store');
      const solutions = this.get('solutions');
      const pickOrdinal = this.get('overrideSelectedPickOrder');
      const dropOrdinal = this.get('overrideSelectedDropOrder'); // clear error message

      this.set('errorText');

      if (currentSelectedRow) {
        currentSelectedRow.set('selected', false);
        this.set('selectedSolutionId');
      }

      if (previousSelectedTripProvider.get('isBrokerProviderType')) {
        const selectedRoute = store.peekRecord('route', routeId);
        this.set('currentSolutionDispatchRoute', selectedRoute.get('dispatchRoute'));
        return;
      }

      this.cursorWait();
      this.get('fetchManualSolutionsTask').perform(trip, pickOrdinal, dropOrdinal, routeId).catch(err => {
        // if the side drawer have been close while doing fetch
        if (this.get('isSolutionsTaskCanceled')) return null; // Try to be as robust as possible.  Log an error to console for discovery,
        // but just use an empty solution set to unblock manually transferring

        console.warn(`Unable to fetch trip transfer solutions: ${JSON.stringify(err)}`); // eslint-disable-line no-console

        if (!Ember.isEmpty(err) && !Ember.isEmpty(err.payload)) {
          if (err.payload.message) {
            if (err.payload.message === `Route ${routeId} does not exist`) {
              this.set('errorText', 'The selected route is not available, please try again in a few minutes. If the problem persist contact an administrator.');
            } else {
              this.set('errorText', `* ${err.payload.message}`);
            }
          } else if (err.payload.detail) {
            this.set('errorText', `* ${err.payload.detail}`);
          } else {
            this.set('errorText', '* Error getting hypo trip transfer.');
          }
        }

        return null;
      }).then(solution => {
        if (solution) {
          const destinationRoutes = solution || [];
          const foundSolution = solutions.findBy('id', destinationRoutes[0].id);

          if (!foundSolution) {
            this.get('solutions').pushObject(destinationRoutes[0]);
            const solutionsTableRows = destinationRoutes.map(({
              id,
              otp
            }) => {
              const selectedRoute = store.peekRecord('route', id);
              return {
                id,
                otp,
                dispatchRoute: selectedRoute.get('dispatchRoute')
              };
            }); // this.get('solutionRoutesTable').push(solutionsTableRows[0]);

            this.get('solutionRoutesTable').addRows(solutionsTableRows);
          } else {
            const solutionRoutesTableRow = this.get('solutionRoutesTable.rows').toArray();
            const solutionIndex = solutions.findIndex(currSolution => currSolution.id === destinationRoutes[0].id);
            const solutionRoutesTableRowIindex = solutionRoutesTableRow.findIndex(row => row.get('id') === destinationRoutes[0].id); // replace the solution with new data

            solutions.splice(solutionIndex, 0, destinationRoutes[0]);
            const solutionsTableRows = destinationRoutes.map(({
              id,
              otp
            }) => {
              const selectedRoute = store.peekRecord('route', id);
              return {
                id,
                otp,
                dispatchRoute: selectedRoute.get('dispatchRoute')
              };
            }); // replace the routes table rows with new solution rows

            solutionRoutesTableRow.splice(solutionRoutesTableRowIindex, 0, solutionsTableRows[0]); // refresh stop table with new results

            const selectedRoute = store.peekRecord('route', destinationRoutes[0].id);
            this.set('currentSolutionDispatchRoute', selectedRoute.get('dispatchRoute'));
          }

          this.selectSolution(destinationRoutes[0].id);
          this.selectTableSolutionRow(routeId);
        }

        this.cursorDefault();
      }).catch(e => {
        if (e.status) {
          this.get('notifications').warning(`Trip ${trip.get('tripId')} cannot be transferred to route ${routeName}`);
        }

        this.cursorDefault();
      });
    },

    onCancelBookingPatchTask() {
      const trip = this.get('trip');
      const booking = (0, _unwrapProxy.unwrapProxy)(trip.get('booking'));
      booking.set('status', 'canceled');
      return booking.save();
    },

    async waitListTripInDispatch() {
      const dispatchAdapter = this.get('store').adapterFor('dispatch-schedule');
      const tripId = parseInt(this.get('trip.id'), 10);
      const response = await dispatchAdapter.waitListTrip(tripId);
      return response;
    },

    onManualTripTransfer() {
      const currentSolutionDispatchRoute = this.get('currentSolutionDispatchRoute');
      const selectedDispatchRouteId = parseInt(this.get('overrideSelectedRoute.dispatchRoute.id'), 10);
      const pickOrdinal = this.get('overrideSelectedPickOrder');
      const dropOrdinal = this.get('overrideSelectedDropOrder');
      const currentRouteId = this.get('currentRoute.name');
      const title = 'Transfer Trip';
      const tripId = this.get('trip.tripId');
      const tooltip = this.get('tooltip');
      const currentRouteIdTip = this.get('trip.isWaitlisted') ? '' : `from route ${currentRouteId}`;
      const tip = `Transfer trip ${tripId} ${currentRouteIdTip} to route ${currentSolutionDispatchRoute.get('route.name')}`;
      const successMsg = `Trip transfer ${tripId} was successful`;
      const errorMsg = `Trip transfer ${tripId} failed`;
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          await this.onCancelBookingPatchTask();
          this.get('manualTripTransferForDispatch').perform(selectedDispatchRouteId, pickOrdinal, dropOrdinal, false).then(() => {
            this.get('notifications').success(successMsg);
            tooltip.reset();
            this.get('workspace').popState('tripTransfer');
          }).catch(async () => {
            this.get('notifications').warning(errorMsg);
            tooltip.reset();
            await this.waitListTripInDispatch();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        },
        hasOverlay: true
      });
    },

    onAutoGeneratedSolutions() {
      const tripId = this.get('trip.tripId');
      const currentSolutionDispatchRoute = this.get('currentSolutionDispatchRoute');
      const currentRouteId = this.get('currentRoute.name');
      const title = 'Transfer Trip';
      const currentRouteIdTip = this.get('trip.isWaitlisted') ? '' : `from route ${currentRouteId}`;
      const tip = `Transfer trip ${tripId} ${currentRouteIdTip} to route ${currentSolutionDispatchRoute.get('route.name')}`;
      this.get('tooltip').pushConfirmation({
        tip: tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveTask').perform().then(() => {
            this.get('notifications').success(`Trip transfer ${tripId} was successful`);
            this.get('tooltip').reset();
            return this.onCloseClick();
          }).catch(() => {
            this.get('notifications').warning(`Trip transfer ${tripId} failed`);
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          this.get('tooltip').reset();
        },
        hasOverlay: true
      });
    },

    async onPostBooking() {
      const bookingAdapter = this.get('store').adapterFor('booking');
      const rider = (0, _unwrapProxy.unwrapProxy)(this.get('trip.rider'));
      const pick = (0, _unwrapProxy.unwrapProxy)(this.get('trip.pick'));
      const drop = (0, _unwrapProxy.unwrapProxy)(this.get('trip.drop'));
      const segment = (0, _unwrapProxy.unwrapProxy)(this.get('trip.segment'));
      const leg = (0, _unwrapProxy.unwrapProxy)(segment.get('leg'));
      const legTravelNeed = (0, _unwrapProxy.unwrapProxy)(leg.get('legTravelNeeds.firstObject'));
      const stopPointsJson = this.buildStopPointsJson(pick, drop);
      const tripId = this.get('trip.tripId');
      const json = {
        data: {
          type: 'booking',
          attributes: {
            trackingId: tripId,
            externalBookingId: '',
            requestedProvider: {
              type: 'dedicated',
              id: '',
              name: 'Verifone'
            },
            source: {
              type: 'callAgent',
              id: '1'
            },
            status: 'active',
            passengers: [{
              id: rider.get('id'),
              name: rider.get('fullName'),
              lastName: rider.get('lastName'),
              firstName: rider.get('firstName'),
              middleName: rider.get('middleName'),
              email: `${rider.get('firstName')}@emaple.com`,
              phone1: {
                number: rider.get('riderPhoneNumbers').length ? rider.get('riderPhoneNumbers')[0].get('phoneNumber') : ''
              },
              bookingNote: rider.get('notes') ? rider.get('notes') : '',
              requiredAttributes: [{
                type: legTravelNeed.get('travelNeedTypeName'),
                cnt: legTravelNeed.get('count')
              }]
            }],
            stopPoints: stopPointsJson,
            price: {
              payerIdentifier: '',
              currencyCode: 'USD',
              estimate: segment.get('fare'),
              paymengTypeName: segment.get('fareTypeName'),
              tip: {
                percent: 1,
                minValue: 0
              }
            }
          }
        }
      };
      const bookingResponse = await bookingAdapter.postBooking(json);
      return bookingResponse;
    },

    buildStopPointsJson(pick, drop) {
      const stopPointsJson = [];
      const pickAddress = (0, _unwrapProxy.unwrapProxy)(pick.get('segmentStop.place.address'));
      const dropAddress = (0, _unwrapProxy.unwrapProxy)(drop.get('segmentStop.place.address'));
      const rider = (0, _unwrapProxy.unwrapProxy)(this.get('trip.rider'));
      const segment = (0, _unwrapProxy.unwrapProxy)(this.get('trip.segment'));
      const pickJson = {
        index: pick.get('plannedRouteOrdinal'),
        'address': {
          'formattedAddress': pickAddress.get('simpleAddress'),
          'streetName': pickAddress.get('streetAddress'),
          'streetNr': pickAddress.get('streetNumber'),
          'region': pickAddress.get('region'),
          'postCode': pickAddress.get('postalCode'),
          'city': pickAddress.get('locality'),
          'state': pickAddress.get('region'),
          'coord': {
            'lat': pick.get('lat'),
            'lng': pick.get('lng')
          }
        },
        'passengerEvents': [{
          'type': 'pickup',
          'passengerId': rider.get('riderId'),
          'time': (0, _moment.default)(segment.get('promiseTime')).format(),
          'anchor': true,
          'driverNote': rider.get('notes') ? rider.get('notes') : ''
        }]
      };
      const dropJson = {
        index: drop.get('plannedRouteOrdinal'),
        'address': {
          'formattedAddress': dropAddress.get('simpleAddress'),
          'streetName': dropAddress.get('streetAddress'),
          'streetNr': dropAddress.get('streetNumber'),
          'region': dropAddress.get('region'),
          'postCode': dropAddress.get('postalCode'),
          'city': dropAddress.get('locality'),
          'state': dropAddress.get('region'),
          'coord': {
            'lat': drop.get('lat'),
            'lng': drop.get('lng')
          }
        },
        'passengerEvents': [{
          'type': 'dropoff',
          'passengerId': rider.get('riderId'),
          'time': (0, _moment.default)(segment.get('promiseTime')).format(),
          'anchor': false,
          'driverNote': rider.get('notes') ? rider.get('notes') : ''
        }]
      };
      stopPointsJson.push(pickJson, dropJson);
      return stopPointsJson;
    },

    onValidateBrokerProvider() {
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const previousSelectedProvider = this.get('previousSelectedProvider');
      const isAnotherBrokerProviderSelected = previousSelectedProvider && previousSelectedProvider.get('isBrokerProviderType') && overrideSelectedProvider.get('isBrokerProviderType');

      if (isAnotherBrokerProviderSelected || overrideSelectedProvider.get('isBrokerProviderType')) {
        this.set('isBrokerProviderType', true);
      }
    },

    manualTripTransferForDispatch: (0, _emberConcurrency.task)(function* (routeId, pickOrdinal, dropOrdinal, ignoreCapacityViolation) {
      const dispatchRouteAdapter = this.get('store').adapterFor('dispatch-route');
      const tripId = parseInt(this.get('trip.id'), 10);
      yield dispatchRouteAdapter.manualTripTransferForDispatch(routeId, tripId, pickOrdinal, dropOrdinal, ignoreCapacityViolation);
    }),
    actions: {
      onSolutionRowClick(row
      /*, event */
      ) {
        const routeId = row.get('id'); // Reset the override

        this.set('selectedOverrideSolutionId');
        this.selectTableSolutionRow(routeId);
      },

      onRefreshClick() {
        this.setProperties({
          override: null,
          overrideSelectedProvider: null,
          overrideSelectedRoute: null,
          overrideSelectedPickOrder: null,
          overrideSelectedDropOrder: null
        });
        this.setupSolutions();
      },

      async onSaveClick() {
        const currentSolutionDispatchRoute = this.get('currentSolutionDispatchRoute');

        if (!Ember.isNone(currentSolutionDispatchRoute)) {
          this.onAutoGeneratedSolutions();
        }
      },

      onShrinkClick() {
        let currentState = this.get('currentState');
        const currentSolutionRoute = this.get('currentSolutionRoute');
        const solutions = this.get('solutions');
        const currentRouteSolution = this.get('currentRouteSolution');
        const overrideSelectedProvider = this.get('overrideSelectedProvider');
        const overrideSelectedRoute = this.get('overrideSelectedRoute');
        const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
        const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
        const override = this.get('override'); // save current manual settings

        currentState = {
          currentSolutionRoute: (0, _unwrapProxy.unwrapProxy)(currentSolutionRoute),
          solutions,
          currentRouteSolution,
          overrideSelectedProvider,
          overrideSelectedRoute,
          overrideSelectedPickOrder,
          overrideSelectedDropOrder,
          override
        };
        this.set('currentState', currentState);
        this.set('overrideSelectedDropOrder');
        this.onShrinkClick();
      },

      onChangeProvider(provider) {
        // clear selected route, pick order and drop order
        this.set('overrideSelectedRoute', null);
        this.set('overrideSelectedPickOrder', null);
        this.set('overrideSelectedDropOrder', null);
        this.set('previousSelectedProvider', this.get('overrideSelectedProvider'));
        this.set('overrideSelectedProvider', provider);
        this.onValidateBrokerProvider();
      },

      async onChangeRoute(route) {
        // clear selected pick order and drop order
        this.set('overrideSelectedPickOrder', null);
        this.set('overrideSelectedDropOrder', null);
        await this.get('coreEntityPopulator').populateRtsRoute(route.id, {}); // give it a bit of time for everything to get loaded to ember store

        Ember.run.later(() => {
          this.set('overrideSelectedRoute', route);
        }, 500);
      },

      onChangePickOrder(order) {
        // clear selected drop order
        this.set('overrideSelectedDropOrder', null);
        this.set('overrideSelectedPickOrder', order);
      },

      onChangeDropOrder(order) {
        this.set('overrideSelectedDropOrder', order); // this.selectOverrideSolution();

        this.selectOverrideSolution(this.get('overrideSelectedRoute'));
      },

      selectSolutionRouteTab() {
        const selectedRouteId = this.get('selectedSolutionId');
        this.set('selectedImpactView', 'destination');
        this.set('activeTabRouteId', selectedRouteId);
        this.chooseImpactByRoute(selectedRouteId);
      },

      selectCurrentRouteTab() {
        const selectedRouteId = this.get('currentDispatchRoute.route.id');
        this.set('selectedImpactView', 'current');
        this.set('activeTabRouteId', selectedRouteId);
        this.chooseImpactByRoute(selectedRouteId);
      }

    }
  });

  _exports.default = _default;
});