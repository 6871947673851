define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/log/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/config/placeholders", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/log/validation"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _placeholders, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['driver-log-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    disableForm: true,
    selectedDriverLog: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    providerOptions: Ember.computed('', function () {
      return this.get('store').peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider');
      });
    }),
    driverLogChangeSet: Ember.computed('selectedDriverLog', function () {
      const selectedDriverLog = (0, _unwrapProxy.unwrapProxy)(this.get('selectedDriverLog'));
      return this.setDefaultProperties(selectedDriverLog);
    }),

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onDeleteRecord = this.get('onDeleteRecord');
      const onSaveRecord = this.get('onSaveRecord'); // normal functions

      const onUndoRecord = this.get('onUndoRecord').bind(this);
      const onNewRecord = this.get('onNewRecord').bind(this);
      const onEditRecord = this.get('onEditRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRecord,
          newAction: onNewRecord,
          deleteAction: onDeleteRecord,
          editAction: onEditRecord,
          undoAction: onUndoRecord,
          onValidateAction: null
        });
      });
      editAPI.buttonSettings();
    },

    setDefaultProperties(record = null) {
      const store = this.get('store');
      const editAPI = this.get('editAPI');
      let pendingDriverLogRecord = this.get('pendingDriverLogRecord');

      if (record) {
        pendingDriverLogRecord = record;
      } else if (!pendingDriverLogRecord) {
        pendingDriverLogRecord = store.createRecord('driver-log');
        this.set('pendingDriverLogRecord', pendingDriverLogRecord);
      }

      if (pendingDriverLogRecord.get('isNew')) {
        pendingDriverLogRecord.setProperties({
          description: '',
          provider: '',
          driver: editAPI.selectedDriver,
          createDate: null
        });
      }

      return new _emberChangeset.default(pendingDriverLogRecord, (0, _emberChangesetValidations.default)(_validation.DRIVER_LOG_VALIDATION), _validation.DRIVER_LOG_VALIDATION);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedDriverLog = this.get('selectedDriverLog');
      const tableRef = this.get('tableRef');

      if (selectedDriverLog) {
        if (!selectedDriverLog.get('isNew') && !selectedDriverLog.get('isDeleted')) {
          yield selectedDriverLog.reload();
        }
      }

      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    validateDriverLog: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverLogChangeSet = this.get('driverLogChangeSet');
      yield driverLogChangeSet.validate();
      const errors = driverLogChangeSet.errors;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      return true;
    }),
    onSaveRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const driverLogChangeSet = this.get('driverLogChangeSet');
      const editAPI = this.get('editAPI'); // validation

      const isValidDriverLog = yield this.validateDriverLog.perform();

      if (isValidDriverLog) {
        try {
          yield driverLogChangeSet.save();
          notifications.success('Successfully record saved');
          editAPI.buttonSettings();
          this.set('disableForm', true);
        } catch (e) {
          notifications.warning('Failed to Save the records');
          console.warn(e); // eslint-disable-line
        }

        this.get('refreshTask').perform();
      }
    }),
    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedDriverLog = this.get('selectedDriverLog');
      const notifications = this.get('notifications');
      const tooltip = this.get('tooltip');
      const refreshTask = this.get('refreshTask');
      if (!selectedDriverLog) return;
      yield tooltip.pushConfirmation({
        title: 'Manage Driver',
        tip: 'Are you sure you want to delete this record?',
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',

        async primaryAction() {
          try {
            await selectedDriverLog.destroyRecord();
            notifications.success('Record successfully deleted');
            refreshTask.perform();
          } catch (e) {
            notifications.warning('Record failed to delete');
          }

          return tooltip.reset();
        }

      });
    }),

    onUndoRecord() {
      const driverLogChangeSet = this.get('driverLogChangeSet');

      if (driverLogChangeSet) {
        driverLogChangeSet.rollback();
      }
    },

    onNewRecord() {
      const selectedRow = this.get('selectedRow');
      const editAPI = this.get('editAPI');
      if (selectedRow) selectedRow.set('selected', false);
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onEditRecord() {
      const selectedRow = this.get('selectedRow');
      const editAPI = this.get('editAPI');
      if (Ember.isEmpty(selectedRow)) return;
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    }

  });

  _exports.default = _default;
});