define("adept-iq/pods/components/iq-widgets/ember-react-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/ember-react-widget/component", "adept-iq/config/column-types", "adept-iq/utils/rql", "lodash", "adept-iq/utils/filters", "adept-iq/utils/datetime-filters"], function (_exports, _component, _columnTypes, _rql, _lodash, _filters, _datetimeFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const otpStatusMapper = {
    'O': 'On Time',
    'D': 'In Danger',
    'L': 'Late',
    'E': 'Early'
  };
  const BUILD_FILTER_FUNC_MAP = {
    'date': _datetimeFilters.buildDateFilter,
    'datetime': _datetimeFilters.buildDateFilter,
    'time': _datetimeFilters.buildTimeFilter
  };
  const logger = {
    log: () => null,
    warn: console.warn // eslint-disable-line no-console

  };

  var _default = _component.default.extend({
    activeContext: Ember.inject.service(),
    classNames: ['iqux-column-widget'],
    layoutName: 'components/generic-widgets/ember-react-widget',
    defaultIncludes: null,
    filterParamNode: null,

    didInsertElement() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    // resetDataQuery() {
    //   const params = {
    //     sorts: []
    //   };
    //   try {
    //   // this only applies for server-side pagination
    //     if (this.get('paginationEnabled')) {
    //       params.page = {
    //         offset: this.get('records.length'),
    //         limit: this.get('limit')
    //       };
    //     }
    //     const sort = this.buildSortParam();
    //     if (sort) {
    //       params.sorts.push(sort);
    //     }
    //     const includes = this.get('defaultIncludes');
    //     if (!isEmpty(includes)) {
    //       params.includes = includes;
    //     }
    //     params.filter = null;
    //     const modelNames = makeArray(this.get('config.modelName'));
    //     return this.get('activeContext.topActiveContext').query(modelNames, params);
    //   } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.log('Workspace view is not refreshed on selecting or deselecting' + error);
    //   }
    // },
    fetchDataQuery() {
      try {
        const params = this.buildQuery();
        const modelName = this.get('config.modelName');
        const isServerDataDependency = this.get('isServerDataDependency');
        return this.get('activeContext.topActiveContext').query(modelName, params, isServerDataDependency);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    buildQuery() {
      const params = {
        sorts: []
      };

      try {
        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = [this.buildColumnFilter()];
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters); // this.set('dataOffset', -1);

        const extendedParams = _lodash.default.merge({}, params, {
          filter: params.filter
        });

        let queryParams = (0, _rql.buildQueryParams)(extendedParams);
        queryParams = _lodash.default.merge({}, queryParams);
        this.set('filterQueryString', queryParams.filter);
        this.set('filterParamNode', params.filter);
        return queryParams;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    buildSortParam() {
      const config = this.get('config');
      const state = this.get('state');
      let columnConfig; // user-specified sort order always takes precedence

      if (Ember.isPresent(state.sortId)) {
        columnConfig = this.getSortColumn(state.sortId); // config.columns.findBy('id', state.sortId);

        if (!columnConfig) {
          // this can occur naturally if the user sorts by a column, saves, and
          // that column is later removed from the widget's configuration
          logger.warn(`workspace configuration specifies that ${config.title} widget should be sorted by column with id '${state.sortId}', but no such column exists`);
        }
      } // fall back to default sort order; this is optional in widget config


      if (!columnConfig && Ember.isPresent(config.defaultSortId)) {
        columnConfig = this.getSortColumn(config.defaultSortId); //config.columns.findBy('id', config.defaultSortId);

        if (!columnConfig) {
          logger.warn(`${config.title} widget is configured with default sort ID '${config.defaultSortId}', but has no column with matching ID`);
        }
      } // collection will be unsorted; this is expected when there is no specified
      // workspace sort or widget default sort


      if (!columnConfig) {
        return null;
      }

      const columnType = _columnTypes.default.findBy('id', columnConfig.type);

      if (!columnType) {
        logger.warn(`tried to sort by column with ID '${columnConfig.id}', but its type '${columnConfig.type}' is not a valid column type`);
        return null;
      }

      if (!columnType.compare) {
        logger.warn(`column type '${columnType.id}' does not have a compare function configured`);
        return null;
      }

      let sortAsc = true;

      if (Ember.isPresent(state.sortAsc)) {
        sortAsc = state.sortAsc;
      } else if (Ember.isPresent(config.defaultSortAsc)) {
        sortAsc = config.defaultSortAsc;
      }

      let sortValuePath = 'id';

      if (columnConfig) {
        sortValuePath = columnConfig.customSortPath ? columnConfig.customSortPath : columnConfig.valuePath;
      }

      return {
        path: sortValuePath,
        asc: sortAsc,
        compare: columnType.compare
      };
    },

    getSortColumn(sortId) {
      const config = this.get('config');
      let columnConfig = config.columns.findBy('id', sortId);

      if (!columnConfig) {
        columnConfig = config.columns.findBy('valuePath', sortId);
      }

      return columnConfig;
    },

    buildSearchFilterByParameter() {
      const searchText = this.get('searchText');
      const res = searchText.split('=');
      const attr = res[0].toLowerCase().trim();
      const value = res[1].trim();
      const columns = this.get('table.columns') || [];

      function configFilter(config) {
        return config.label.toLowerCase().trim() === attr;
      }

      if (Ember.isEmpty(searchText)) {
        return null;
      }

      const searchableConfigs = columns.rejectBy('hidden').mapBy('config').compact().filter(configFilter);
      const args = searchableConfigs.map(config => {
        const columnType = _columnTypes.default.findBy('id', config.type) || {};
        const filterType = columnType.searchFilterType;
        const valuePath = config.alternateValuePath ? config.alternateValuePath : config.valuePath;
        return (0, _filters.buildValueFilterNode)(filterType, valuePath, [value], config.format);
      });
      return (0, _filters.buildCompoundFilterNode)('or', args);
    },

    buildSearchFilter() {
      const searchText = this.get('searchText');
      const columns = this.get('table.columns') || [];

      if (Ember.isEmpty(searchText)) {
        return null;
      }

      const searchableConfigs = columns.rejectBy('hidden').mapBy('config').compact(); // .filterBy('searchable'); // We allow search on all columns - Ashraf , Ankur discussed

      const args = searchableConfigs.map(config => {
        const columnType = _columnTypes.default.findBy('id', config.type) || {};
        const filterType = columnType.searchFilterType;
        const valuePath = config.alternateValuePath ? config.alternateValuePath : config.valuePath;
        return (0, _filters.buildValueFilterNode)(filterType, valuePath, [searchText], config.format);
      });
      return (0, _filters.buildCompoundFilterNode)('or', args);
    },

    buildColumnFilter() {
      const columns = this.get('table.columns') || [];
      const args = columns.map(column => {
        const columnType = column.config ? column.config.type : null;
        const filternode = column.filterNode;
        const buildFilterFunc = columnType ? BUILD_FILTER_FUNC_MAP[columnType] : null;

        if (filternode && buildFilterFunc) {
          buildFilterFunc(filternode);
        }

        return column.get('filterNode');
      });
      this.applyFilterQueryConversion(args);
      return (0, _filters.buildCompoundFilterNode)('and', args);
    },

    calculateTimeQuery() {
      const dateRangeFilterQuery = '';
      return dateRangeFilterQuery;
    },

    calculateProviderQuery() {
      const selectedProviders = this.get('workspaceContext._selectedProviders') || [];

      if (_lodash.default.isEmpty(selectedProviders)) {
        return '';
      }

      const providerNames = selectedProviders.mapBy('name');
      const providerFilterQuery = this.get('widgetService').generateRQL('provider', providerNames);
      return providerFilterQuery;
    },

    canFurtherSelectionAllowed() {
      const gridConfig = this.get('config');
      const selectedRowLimit = gridConfig.selectedRowLimit; // Only 3 widgets (stop, trip and routes) are having selectedRowLimit in their config,
      // so rest of the widgets shouldn't restrict the selections

      if (!selectedRowLimit) {
        return true;
      }

      const checkedItems = this.get('checkedRowIds');

      if (checkedItems.length < selectedRowLimit) {
        return true;
      }

      return false;
    },

    /** Used to style table rows, each component can override and return the approriate
     *  css classes based on the provided data, rowIndex and columnIndex **/
    // eslint-disable-next-line no-unused-vars
    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currentData) {
      return '';
    },

    applyOtpStatusTransformation(widgetRecord) {
      if (widgetRecord.otpStatus) {
        widgetRecord.otpCode = widgetRecord.otpStatus;
        widgetRecord.otpStatus = otpStatusMapper[widgetRecord.otpStatus];
      }

      if (widgetRecord.otp) {
        widgetRecord.otpWithSign = (widgetRecord.otp < 0 ? '' : '+') + widgetRecord.otp;
      } // show zero value with no sign


      if (widgetRecord.otp === 0) widgetRecord.otpWithSign = 0;
    },

    applyDriverIdTransformation(widgetRecord) {
      // This means it is a road supervisor record. can use routeName with 'SUP' because some widgetRecord do not have routeName
      if (widgetRecord.driverId && widgetRecord.vehicleId && widgetRecord.driverId === widgetRecord.vehicleId) {
        widgetRecord.driverId = '';
      }
    },

    applyFilterQueryConversion(filters) {
      filters.forEach(filter => {
        this.applyOtpStatusFilterConversion(filter);
      });
    },

    applyOtpStatusFilterConversion(filter) {
      if (!filter || filter.local.path !== 'otpStatus') {
        return;
      }

      const optStatusValue = filter.local.values[0];

      const optStatusKey = _lodash.default.findKey(otpStatusMapper, value => value === optStatusValue.trim());

      filter.local.values[0] = optStatusKey;
    }

  });

  _exports.default = _default;
});