define("adept-iq/serializers/cs-config-permission", ["exports", "adept-iq/serializers/cs-config-model"], function (_exports, _csConfigModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigModel.default.extend({
    modelName: 'cs-config-permission',
    session: Ember.inject.service(),
    user: Ember.inject.service(),

    normalizeArrayResponse(store, primaryModelClass, payload
    /*, id, requestType */
    ) {
      const configPermissions = []; // unlike other config-models, cs-config-permissions keep their properties
      // wrapped up in a `value` JSON property; we don't call `this._super(...)`

      payload.data = payload.data || [];
      payload.data.map(configItemPayload => {
        configItemPayload.attributes = configItemPayload.attributes || {};
        const attributes = {
          name: configItemPayload.attributes.displayName
        };

        if (configItemPayload.attributes) {
          try {
            attributes.value = JSON.parse(configItemPayload.attributes.value);
          } catch (e) {
            attributes.value = null;
          }

          if (attributes.value.stops) {
            attributes.value.stops.singleActions.push('undoEndRoute');
          }

          if (attributes.value.routes) {
            attributes.value.routes.singleActions.push('undoStartRoute');
          } //TODO FIXME - hack to allow todays demo


          attributes.value.stops.singleActions.push('navigateTo');
          attributes.value.vehicles.singleActions.push('navigateTo');
          attributes.value.map.addressSearchAllowed = true;
          attributes.value.map.navigateToAllowed = true;
          configPermissions.push({
            id: configItemPayload.id,
            type: 'cs-config-permission',
            attributes
          });
        }
      });
      return {
        data: configPermissions
      };
    }

  });

  _exports.default = _default;
});