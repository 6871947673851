define("adept-iq/serializers/vehicle-dot-inspection", ["exports", "adept-iq/serializers/-vehicle", "lodash"], function (_exports, _vehicle, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const type = 'vehicle-dot-inspection';

  var _default = _vehicle.default.extend({
    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      json.data.type = 'vehicleDotInspection'; // incorrect camel case should be 'vehicleDotInspection'

      json.data.relationships = json.data.relationships || {};
      const {
        inspectionCategory,
        provider
      } = json.data.relationships;

      if (provider) {
        json.data.relationships.providerName = provider;
        json.data.relationships.providerName.data.type = 'providerName';
        delete json.data.relationships.provider;
      }

      if (inspectionCategory) {
        json.data.relationships.inspectionCategoryName = inspectionCategory;
        json.data.relationships.inspectionCategoryName.data.type = 'inspectionCategoryName';
        delete json.data.relationships.inspectionCategory;
      }

      return json;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      payload.data.type = type;
      return payload;
    },

    normalize(modelClass, resourceHash) {
      resourceHash.relationships = resourceHash.relationships || {};
      resourceHash.attributes = resourceHash.attributes || {};

      if (resourceHash.attributes.inspectionCategoryName) {
        resourceHash.relationships.inspectionCategory = {
          data: {
            id: resourceHash.attributes.inspectionCategoryName,
            type: 'inspection-category'
          }
        };
        delete resourceHash.attributes.inspectionCategoryName;
      }

      if (resourceHash.attributes.providerName) {
        resourceHash.relationships.provider = {
          data: {
            id: resourceHash.attributes.providerName,
            type: 'provider'
          }
        };
        delete resourceHash.attributes.providerName;
      }

      if (resourceHash.relationships.providerName) {
        resourceHash.relationships.providerName.data.type = 'provider';
        resourceHash.relationships.provider = (0, _lodash.cloneDeep)(resourceHash.relationships.providerName);
        delete resourceHash.relationships.providerName;
      }

      if (resourceHash.relationships.inspectionCategoryName) {
        resourceHash.relationships.inspectionCategoryName.data.type = 'inspection-category';
        resourceHash.relationships.inspectionCategory = (0, _lodash.cloneDeep)(resourceHash.relationships.inspectionCategoryName);
        delete resourceHash.relationships.inspectionCategoryName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});