define("adept-iq/serializers/leg", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    serialize()
    /*snapshot, options*/
    {
      const json = this._super(...arguments);

      const serviceWindowRelationship = json.data.relationships.serviceWindow;
      const booking = json.data.relationships.booking;

      if (!Ember.isNone(serviceWindowRelationship) && !Ember.isNone(serviceWindowRelationship.data)) {
        serviceWindowRelationship.data.type = 'serviceWindowName';
        json.data.relationships.serviceWindowName = json.data.relationships.serviceWindow;
        delete json.data.relationships.serviceWindow;
      }

      if (!Ember.isNone(booking)) {
        delete json.data.relationships.booking;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships)) {
        if (!Ember.isNone(resourceHash.relationships.serviceWindowName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.serviceWindow = {
            data: {
              type: 'service-window',
              id: resourceHash.relationships.serviceWindowName.data.id
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.serviceWindowName;
        }

        if (!Ember.isNone(resourceHash.relationships.travelNeeds)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.legTravelNeeds = resourceHash.relationships.travelNeeds;
          resourceHash.relationships = relationships;
          delete resourceHash.relationships.travelNeeds;
        }
      }

      if (!Ember.isNone(resourceHash.attributes.serviceWindowName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.serviceWindow = {
          data: {
            type: 'service-window',
            id: resourceHash.attributes.serviceWindowName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.serviceWindowName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});