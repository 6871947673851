define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/category/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e5t8rYXq",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[27,\"iq-widgets/vehicle-driver-manager-widget/tabs/lookup/category/table\",null,[[\"tableRef\"],[[23,[\"tableRef\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"form-panel-wrapper\"],[9],[0,\"\\n\"],[4,\"generic-components/panel\",null,[[\"class\",\"title\",\"isCollapsible\"],[\"route-information-panel\",\"Category\",false]],{\"statements\":[[0,\"    \"],[7,\"form\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n          \"],[7,\"div\"],[12,\"class\",[28,[\"input-group \",[27,\"if\",[[27,\"and\",[[23,[\"vehicleCategoryRecord\",\"error\",\"description\"]],[23,[\"showErrorMsg\"]]],null],\"error\"],null]]]],[9],[0,\"\\n            \"],[7,\"label\"],[9],[0,\" Description\"],[10],[0,\"\\n            \"],[7,\"div\"],[9],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"class\",\"placeholder\",\"disabled\",\"type\",\"value\"],[\"description-textbox\",[23,[\"DESCRIPTION_PLACEHOLDER\"]],[23,[\"disableForm\"]],\"text\",[23,[\"vehicleCategoryRecord\",\"description\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/category/template.hbs"
    }
  });

  _exports.default = _default;
});