define("adept-iq/pods/components/iq-widgets/new-booking-form/component", ["exports", "moment", "adept-iq/config/mapped-permIds", "adept-iq/pods/components/generic-widgets/base-widget/component", "lodash", "adept-iq/utils/unwrapProxy", "adept-iq/classes/rules/eligibility-rules/eligibility-rule-builder", "adept-iq/classes/rules/eligibility-rules/eligibility-rule-context", "adept-iq/classes/rules/itinerary-type"], function (_exports, _moment, _mappedPermIds, _component, _lodash, _unwrapProxy, _eligibilityRuleBuilder, _eligibilityRuleContext, _itineraryType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PICK = 'pick';
  let CUT_OFF_TIME_FOR_SAMEDAY = 0;
  let FUTURE_BOOKING_TIMELINE = 0;

  const onEligibilityWarningYes = (component, rulecontext, resolve) => {
    rulecontext.overrideCondition = true;
    component.set('showEligibilityRestrictions', false);
    component.get('workspace').set('isGlobalSpinnerVisible', true); // rules with async call  are executed

    resolve();
  };

  const onEligibilityWarningNo = (component, rulecontext, resolve) => {
    rulecontext.overrideCondition = false;
    component.set('showEligibilityRestrictions', false);
    component.get('editModal').close();
    resolve();
  };

  var _default = _component.default.extend({
    classNames: ['new-booking-form-side-drawer'],
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    booking: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    isProgressShown: false,
    riderToBook: null,
    serviceNeeds: null,
    isLoadAndUnLoadTimeValid: true,
    isLoadAndUnLoadTimeNumber: false,
    // Checking the leg date eligibility
    selectedLegDate: null,
    isEligiblitiyCheck: false,
    showBookingSummary: false,
    onConditionCancelClick: null,
    onConditionConfirmClick: null,
    returnTripCollapseState: 'collapsed',
    travelNeedCollapseState: 'expanded',
    passengerCollapseState: 'collapsed',
    serviceNeedCollapseState: 'collapsed',
    // conditional Eligibility
    showEligibilityRestrictions: false,
    showOnlyEligibilityOkButton: true,
    ridersWithConditionalEligibility: null,
    riderIdConditionsMap: null,
    isOpenErrorModal: Ember.computed.alias('booking.isOpenErrorModal'),
    errorMessage: Ember.computed.alias('booking.errorMessage'),
    canSave: Ember.computed.alias('booking.canSave'),
    isEditMode: false,
    passengerInfo: null,
    legTravelNeeds: null,
    dateFormat: 'MM/DD/YYYY',
    legsInBooking: Ember.computed.alias('activeLegsInBooking'),
    defaultFare: 0,
    companionFare: 0,
    purpose: null,
    temporaryBookingResults: null,
    pickAddressText: null,
    dropAddressText: null,
    selectedRiders: null,
    // eslint-disable-next-line ember/use-brace-expansion
    maxLegOrdinal: Ember.computed('booking.activeBooking.legs.[]', 'booking.activeBooking.legs', function () {
      const legOrdinals = this.get('booking.activeBooking.legs').map(leg => {
        return leg.get('segments.firstObject.legOrdinal');
      });
      return Math.max(...legOrdinals);
    }),
    riderFullNames: Ember.computed('booking.selectedRiders.[]', function () {
      return this.get('booking.selectedRiders').map(rider => rider.get('fullName')).join(',');
    }),
    noOfActiveRiders: Ember.computed('booking.selectedRiders.[]', function () {
      return this.get('booking.selectedRiders').length;
    }),
    allRiderAllowedServiceModes: Ember.computed('booking.selectedRiders.[]', function () {
      if (this.get('booking.selectedRiders').length === 0) {
        return [];
      }

      if (this.get('booking.selectedRiders').length === 1) {
        const riderRecord = this.get('booking.selectedRiders')[0];
        return riderRecord.get('eligibility.allowedServiceModes');
      }

      const commonServiceModes = this.get('booking.selectedRiders').reduce((result, rider) => {
        if (!result.length) {
          return rider.get('eligibility.allowedServiceModes');
        }

        return (0, _lodash.intersection)(result, rider.get('eligibility.allowedServiceModes'));
      }, []);
      return commonServiceModes;
    }),
    passengerListTitle: Ember.computed('noOfActiveRiders', function () {
      return `Passenger List (${this.get('noOfActiveRiders')})`;
    }),
    bookingPanelTitle: Ember.computed('isEditMode', function () {
      return this.get('isEditMode') ? `Edit Booking ${this.get('booking.activeBooking.id')}` : 'Create Booking';
    }),
    activeLegsInBooking: Ember.computed('booking.activeBooking.legs.[]', function () {
      return this.get('booking.activeBooking.legs');
    }),
    activeLegsInBookingForMapObserver: Ember.observer('booking.activeBooking.legs.[]', function () {
      this.booking.setActiveLegsInBooking(this.get('activeLegsInBooking'));
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    tripLegsInBooking: Ember.computed('booking.activeBooking.legs.[]', 'booking.activeBooking.legs', function () {
      const tripLegs = this.get('booking.activeBooking.legs').filterBy('purpose', 'trip');
      return tripLegs.sortBy('legOrdinal');
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    returnTripLegsInBooking: Ember.computed('booking.activeBooking.legs.[]', 'booking.activeBooking.legs', function () {
      const returnTripLegs = this.get('booking.activeBooking.legs').filterBy('purpose', 'return');
      return returnTripLegs.sortBy('legOrdinal');
    }),
    addressesOfRiderToBook: Ember.computed('riderToBook', function () {
      return this.get('riderToBook.places').map(place => {
        if (Ember.typeOf(place) === 'object') {
          return this.store.createRecord('address', {
            freeformAddress: '',
            // we give this empty because nyct does give this field,full address in address model checks for this
            streetNumber: place.address.streetNumber,
            streetAddress: place.address.streetAddress,
            notes: place.address.notes,
            premise: place.address.premise,
            region: place.address.region,
            locality: place.address.locality,
            subLocality: place.address.locality,
            postalCode: place.address.postalCode,
            lat: place.address.lat,
            lng: place.address.lng,
            zName: place.address.zName
          });
        }

        return this.store.createRecord('address', {
          freeformAddress: place.get('address.freeformAddress') || '',
          streetNumber: place.get('address.streetNumber'),
          streetAddress: place.get('address.streetAddress'),
          notes: place.get('address.notes'),
          premise: place.get('address.premise'),
          region: place.get('address.region'),
          locality: place.get('address.locality'),
          subLocality: place.get('address.subLocality'),
          postalCode: place.get('address.postalCode'),
          lat: place.get('location.lat'),
          lng: place.get('location.lng'),
          zName: place.get('address.zName')
        });
      });
    }),

    init() {
      this._super(...arguments);

      this.configureAddTripPermission();
      const records = this.get('editableRecords');
      this.set('isEditMode', records[0]);
      this.activeLegsInBookingForMapObserver();

      if (this.get('isEditMode')) {
        this.initEditMode();
      } else {
        this.initAddMode();
      }

      this.onAddNewTripLegClick = this.onAddNewTripLegClick.bind(this);
      this.onAddNewReturnLegClick = this.onAddNewReturnLegClick.bind(this);
      this.onLegReturnRemoveClick = this.onLegReturnRemoveClick.bind(this);
      this.onLegTripRemoveClick = this.onLegTripRemoveClick.bind(this);
      this.onReturnTripPanelHeaderClick = this.onReturnTripPanelHeaderClick.bind(this);
      this.syncPickAndDrop = this.syncPickAndDrop.bind(this);
      this.onFirstLegDateChange = this.onFirstLegDateChange.bind(this);
      this.updateFare = this.updateFare.bind(this);
      const cutOffTime4SameDay = this.store.peekRecord('cs-config-item', 'config-Book_Trips/cutOffTime4SameDay');
      const futureBookingTimeline = this.store.peekRecord('cs-config-item', 'config-Book_Trips/Future_Booking_Timeline'); // converting minutes to hour
      // eslint-disable-next-line radix

      CUT_OFF_TIME_FOR_SAMEDAY = parseInt(cutOffTime4SameDay.get('value')) / 60; // eslint-disable-next-line radix

      FUTURE_BOOKING_TIMELINE = parseInt(futureBookingTimeline.get('value'));
      this.combineServiceNeedsForGroupBooking();
      this.set('companionFare', this.get('booking').getSysConfigCompanionFare());
      this.setDefaultFare();
      this.set('legTravelModeMap', this.get('booking').getActiveBookingTravelModeMap());
      this.set('selectedRiders', this.get('booking.selectedRiders'));
      this.setZoneAddress();
      this.setDefaultBookingData();
    },

    setDefaultBookingData() {
      const records = this.get('editableRecords');
      const externalRider = this.get('booking.selectedRiders.firstObject');
      const riderTravelNeeds = externalRider.get('travelNeeds');
      const legTravelNeeds = this.get('booking').createLegTravelNeeds(riderTravelNeeds);
      const bookingRecord = (0, _lodash.clone)(records[2]);
      const loadTime = records[1].loadTime;
      const unLoadTime = records[1].unloadTime;
      const notes = records[1].notes;
      const areaCode = records[1].notificationPhoneNumbers[0].areaCode;
      const phoneNumber = records[1].notificationPhoneNumbers[0].phoneNumber;
      const formattedPhoneNumber = `${areaCode}-${phoneNumber}`;
      const rider = this.get('selectedRiders');
      const record = Ember.Object.create({
        legs: [].concat(bookingRecord.get('legs').toArray()),
        booking: bookingRecord,
        legTravelNeeds: legTravelNeeds.toArray(),
        loadTime: loadTime,
        unLoadTime: unLoadTime,
        notes: notes,
        formattedPhoneNumber: formattedPhoneNumber,
        rider: rider
      });
      record.set('legSegments', this.createClonedLegSegments(record.get('legs')));
      record.set('travelNeeds', this.createClonedTravelNeeds(record.get('legTravelNeeds')));
      this.set('defaultBookingData', record);
    },

    createPlaceRecord(place) {
      const store = this.get('store');
      const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      const addressAttr = address.toJSON();
      const placeAttr = place.toJSON();
      const clonedAddress = store.createRecord('address', addressAttr);
      const clonePlace = store.createRecord('place', placeAttr);
      clonePlace.set('address', clonedAddress); // if clone the location it will update the location in map

      clonePlace.set('location', place.get('location'));
      return clonePlace;
    },

    createClonedLegSegments(legs) {
      const legSegments = legs.map(leg => {
        const pickPlace = this.createPlaceRecord((0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.pick.place')));
        const dropPlace = this.createPlaceRecord((0, _unwrapProxy.unwrapProxy)(leg.get('segments.lastObject.drop.place')));
        const fare = leg.get('segments.firstObject.fare');
        const pickNotes = leg.get('segments.firstObject.pick.notes');
        const dropNotes = leg.get('segments.lastObject.drop.notes');
        return Ember.Object.create({
          pickPlace: pickPlace,
          dropPlace: dropPlace,
          fare: fare,
          pickNotes: pickNotes,
          dropNotes: dropNotes,
          serviceWindow: leg.get('serviceWindow'),
          noSharing: leg.get('segment.noSharing'),
          anchor: leg.get('anchor'),
          requestTime: leg.get('requestTime')
        });
      });
      return legSegments;
    },

    createClonedTravelNeeds(legTravelNeed) {
      const legTravelNeeds = legTravelNeed.map(travelNeed => {
        return Ember.Object.create({
          count: travelNeed.get('count'),
          travelNeedType: travelNeed.get('travelNeedType'),
          passengerType: travelNeed.get('passengerType')
        });
      });
      return legTravelNeeds;
    },

    willDestroyElement() {
      this.set('booking.selectedCompanionCount', 0);
      this.set('booking.isOpenErrorModal', false);

      this._super(...arguments);
    },

    selectedCompanionCount: Ember.computed.alias('booking.selectedCompanionCount'),

    initEditMode() {
      const record = this.get('editableRecords');
      const legTravelNeeds = this.duplicateWideSeats(record[2].legs.firstObject.legTravelNeeds);
      const hasReturnLeg = record[2].legs.toArray().find(ltn => ltn.get('purpose') === 'return');

      if (hasReturnLeg) {
        this.set('returnTripCollapseState', 'expanded');
      }

      this.set('riderToBook', record[1]);
      this.set('legTravelNeeds', legTravelNeeds);
      const selectedRidersWithConditionalEligibility = this.get('booking.selectedRidersWithConditionalEligibility');
      this.set('riderIdConditionsMap', this.get('booking').getRiderIdToConditionsMap({}));
      this.set('ridersWithConditionalEligibility', selectedRidersWithConditionalEligibility);
    },

    initAddMode() {
      const record = this.get('editableRecords');
      this.set('riderToBook', record[1]);
      this.set('legTravelNeeds', record[2].legs.firstObject.legTravelNeeds);
      const selectedRidersWithConditionalEligibility = this.get('booking.selectedRidersWithConditionalEligibility');
      this.set('riderIdConditionsMap', this.get('booking').getRiderIdToConditionsMap({}));
      this.set('ridersWithConditionalEligibility', selectedRidersWithConditionalEligibility);
    },

    duplicateWideSeats(legTravelNeeds) {
      const configItems = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-travel_need_types';
      });
      const bigSeatsTN = configItems.toArray().filter(tr => parseInt(tr.get('value.Vehicle Capacity Count'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('value.Name').toUpperCase());
      const bigSeatTravelneeds = legTravelNeeds.filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      bigSeatTravelneeds.forEach(tr => {
        const wideTravelNeed = bigSeatsTN.find(travelNeed => {
          return travelNeed.get('travelNeedTypeNameUppercase') === tr.get('value.Name');
        });
        const configCount = parseInt(wideTravelNeed.get('value.Vehicle Capacity Count'), 10);

        if (tr.count > configCount) {
          const count = tr.count / configCount;

          for (let i = 1; i < count; i++) {
            const newLegTravelNeed = this.store.createRecord('leg-travel-need', {
              count: configCount,
              travelNeedType: tr.get('travelNeedType'),
              passengerType: tr.get('passengerType')
            });
            legTravelNeeds.pushObject(newLegTravelNeed);
          }
        }
      });
      return legTravelNeeds;
    },

    onNoteChange(value) {
      this.set('riderToBook.notes', value);
    },

    checkIfFullAddressPresent(address) {
      return !Ember.isEmpty(address.get('fullAddress'));
    },

    /**
     * Method to set return trip dropoff address as first leg pic address
     */
    syncReturnDropWithTripPick() {
      const firstTripLeg = this.get('tripLegsInBooking.firstObject');
      const firstTripPickPlace = firstTripLeg.get('segments.firstObject.pick.place');
      const lastReturnLeg = this.get('returnTripLegsInBooking.lastObject');
      const lastLegDrop = lastReturnLeg.get('segments.lastObject.drop');
      const dropAddress = this.store.createRecord('address', {
        freeformAddress: firstTripPickPlace.get('address.freeformAddress') || '',
        streetNumber: firstTripPickPlace.get('address.streetNumber'),
        streetAddress: firstTripPickPlace.get('address.streetAddress'),
        notes: firstTripPickPlace.get('address.notes'),
        premise: firstTripPickPlace.get('address.premise'),
        region: firstTripPickPlace.get('address.region'),
        locality: firstTripPickPlace.get('address.locality'),
        subLocality: firstTripPickPlace.get('address.subLocality'),
        postalCode: firstTripPickPlace.get('address.postalCode'),
        zName: firstTripPickPlace.get('address.zName')
      });
      const dropLocation = this.store.createRecord('location', {
        lat: firstTripPickPlace.get('location.lat'),
        lng: firstTripPickPlace.get('location.lng'),
        geoNode: firstTripPickPlace.get('location.geoNode')
      });
      const dropPlace = this.store.createRecord('place', {
        address: dropAddress,
        location: dropLocation
      });
      lastLegDrop.set('place', dropPlace);
      const activelegsInBooking = this.get('activeLegsInBooking');
      this.booking.setActiveLegsInBooking(activelegsInBooking);
    },

    syncPickAndDrop() {
      const legsInBooking = this.get('tripLegsInBooking').toArray(); // last trip leg's drop should first return trip leg's pick

      if (this.get('returnTripLegsInBooking.length')) {
        const lastTripLeg = this.get('tripLegsInBooking.lastObject');
        const firstReturnLeg = this.get('returnTripLegsInBooking.firstObject');
        const prevDrop = lastTripLeg.get('segments.firstObject.drop.place');
        const currPick = firstReturnLeg.get('segments.firstObject.pick');
        const previousDrop = this.addressRecordCreation(prevDrop);
        currPick.set('place', previousDrop);
      } else {
        this.syncLegsInBooking(legsInBooking);
      }

      const activelegsInBooking = this.get('activeLegsInBooking');
      this.booking.setActiveLegsInBooking(activelegsInBooking);
    },

    syncLegsInBooking(legsInBooking) {
      if (legsInBooking.length <= 1) {
        return;
      }

      for (let i = 1; i < legsInBooking.length; i++) {
        const previousLeg = legsInBooking[i - 1];
        const currentLeg = legsInBooking[i];
        const prevDrop = previousLeg.get('segments.firstObject.drop.place');
        const currPick = currentLeg.get('segments.firstObject.pick');
        const pickPlace = this.addressRecordCreation(prevDrop);
        currPick.set('place', pickPlace);
      }
    },

    /**
     * address record creation
     */
    addressRecordCreation(prevDrop) {
      const pickAddress = this.store.createRecord('address', {
        freeformAddress: prevDrop.get('address.freeformAddress') || '',
        streetNumber: prevDrop.get('address.streetNumber'),
        streetAddress: prevDrop.get('address.streetAddress'),
        notes: prevDrop.get('address.notes'),
        premise: prevDrop.get('address.premise'),
        region: prevDrop.get('address.region'),
        locality: prevDrop.get('address.locality'),
        subLocality: prevDrop.get('address.subLocality'),
        postalCode: prevDrop.get('address.postalCode'),
        zName: prevDrop.get('address.zName')
      });
      const pickLocation = this.store.createRecord('location', {
        lat: prevDrop.get('location.lat'),
        lng: prevDrop.get('location.lng'),
        geoNode: prevDrop.get('location.geoNode')
      });
      const pickPlace = this.store.createRecord('place', {
        address: pickAddress,
        location: pickLocation
      });
      return pickPlace;
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      Ember.$('.edit-widget-modal').width('400');
      Ember.$('.booking-map-container').css({
        'width': 'calc(95% - ' + Ember.$('.edit-widget-modal').width() + 'px)'
      }).css({
        'height': 'calc(100% - 20%)',
        'left': Ember.$('.edit-widget-modal').width() + 'px)'
      });
      this.set('travelNeedCollapseState', 'collapsed');
    },

    getNewLegDate(prevLeg) {
      const bufferMinutes = 2;
      const rTime = prevLeg.get('requestTime');
      const currentDateAndTime = (0, _moment.default)(rTime);
      const newMomentDate = (0, _moment.default)();
      const currentYear = currentDateAndTime.year();
      const currentMonth = currentDateAndTime.month();
      const currentDay = currentDateAndTime.date();
      const currentHour = currentDateAndTime.hour();
      const currentMinutes = currentDateAndTime.minutes();
      const currentSeconds = currentDateAndTime.seconds();
      newMomentDate.year(currentYear);
      newMomentDate.month(currentMonth);
      newMomentDate.date(currentDay);
      newMomentDate.hour(currentHour);
      newMomentDate.minutes(currentMinutes + bufferMinutes);
      newMomentDate.seconds(currentSeconds);
      const selectedDateObjectWithTime = newMomentDate.toDate();
      return selectedDateObjectWithTime;
    },

    configureAddTripPermission() {
      const record = this.get('editableRecords')[1];
      const sameDayTripPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createSameDay, null);
      const FutureDayTripPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createFuture, null);
      let maxDate = null;
      let minDate = null;

      if (sameDayTripPermGranted && !FutureDayTripPermGranted) {
        maxDate = (0, _moment.default)().endOf('day');
      }

      if (FutureDayTripPermGranted && !sameDayTripPermGranted) {
        minDate = (0, _moment.default)().startOf('day').add(1, 'day').toDate();
      }

      record.set('tripMaxDate', maxDate);
      record.set('tripMinDate', minDate);
    },

    createLegSegmentRecord(isReturn) {
      const store = this.get('store');
      const serviceWindow = store.peekRecord('service-window', 'GENERAL');
      const fareType = store.peekAll('fare-type').firstObject;
      let date;

      if (!isReturn || !this.get('returnTripLegsInBooking.lastObject.requestTime')) {
        const legToUse = this.get('tripLegsInBooking.lastObject');
        date = this.getNewLegDate(legToUse);
      } else {
        const legToUse = this.get('returnTripLegsInBooking.lastObject');
        date = this.getNewLegDate(legToUse);
      }

      const pickAddress = store.createRecord('address');
      const pickLocation = store.createRecord('location');
      const pickPlace = store.createRecord('place', {
        address: pickAddress,
        location: pickLocation
      });
      const dropAddress = store.createRecord('address');
      const dropLocation = store.createRecord('location');
      const dropPlace = store.createRecord('place', {
        address: dropAddress,
        location: dropLocation
      });
      const leg = store.createRecord('leg', {
        requestTime: date,
        anchor: 'pick',
        purpose: isReturn ? 'return' : 'trip',
        notes: '',
        serviceWindow
      });
      const legOrdinalForNewLeg = this.get('maxLegOrdinal') + 1;
      const segment = store.createRecord('segment', {
        promiseTime: date,
        anchor: 'Pick',
        fare: this.get('booking.activeBooking.legs.firstObject.segments.firstObject.fare'),
        travelMode: 'paratransit',
        segmentType: 2,
        noSharing: 0,
        legOrdinal: legOrdinalForNewLeg,
        pick: store.createRecord('segment-stop', {
          type: 'pick',
          notes: '',
          plannedTime: date,
          onboardCount: 1,
          place: pickPlace
        }),
        drop: store.createRecord('segment-stop', {
          type: 'drop',
          notes: '',
          plannedTime: date,
          onboardCount: 1,
          place: dropPlace
        }),
        fareType: fareType,
        leg: leg
      });
      leg.set('segments', [segment]);
      return leg;
    },

    onReturnTripPanelHeaderClick() {
      const bookingFieldType = this.getLegTypeField(true);

      if (this.get(`${bookingFieldType}.length`) === 0) {
        const newLeg = this.createLegSegmentRecord(true);
        this.get('booking.activeBooking.legs').pushObject(newLeg);
        this.syncPickAndDrop(); //setting last return trip drop

        this.syncReturnDropWithTripPick();
      }
    },

    getLegTypeField(isReturn) {
      return isReturn ? 'returnTripLegsInBooking' : 'tripLegsInBooking';
    },

    onAddNewTripLegClick() {
      this._onAddNewLegClick(false);
    },

    onAddNewReturnLegClick() {
      this._onAddNewLegClick(true); //setting last return trip drop


      if (this.get('returnTripLegsInBooking.length')) {
        this.syncReturnDropWithTripPick();
      }
    },

    _onAddNewLegClick(isReturn) {
      const newLeg = this.createLegSegmentRecord(isReturn);
      this.get('booking.activeBooking.legs').pushObject(newLeg);
      this.syncPickAndDrop();
    },

    _onRemoveLeg(leg) {
      this.get('booking.activeBooking.legs').removeObject(leg);
      this.set('returnTripCollapseState', 'collapsed');
    },

    onLegReturnRemoveClick(leg) {
      this._onRemoveLeg(leg);
    },

    onLegTripRemoveClick(leg) {
      this._onRemoveLeg(leg);

      this.syncPickAndDrop();
    },

    // we are just checking using postalCode because both nyct and tomtom returns that consistently
    validateIfPickAndDropArePresentInAllLegs() {
      let allLegsHavePickAndDrop = true;
      this.get('legsInBooking').forEach(leg => {
        if (!leg.get('segments.firstObject.pick.place.address.postalCode') && !leg.get('segments.lastObject.drop.place.address.postalCode')) {
          allLegsHavePickAndDrop = false;
        }
      });
      return allLegsHavePickAndDrop;
    },

    validateIfPickPresentInAllLegs() {
      let allLegsHavePick = true;

      if (this.get('legsInBooking').length === 1) {
        return true;
      }

      this.get('legsInBooking').forEach((leg, index) => {
        if (!leg.get('segments.firstObject.pick.place.address.postalCode') && index > 0) {
          this.set('purpose', leg.get('purpose'));
          allLegsHavePick = false;
        }
      });
      return allLegsHavePick;
    },

    validateIfDropPresentInAllLegs() {
      let allLegsHaveDrop = true;

      if (this.get('legsInBooking').length === 1) {
        return true;
      }

      this.get('legsInBooking').forEach((leg, index) => {
        if (!leg.get('segments.lastObject.drop.place.address.postalCode') && index > 0) {
          this.set('purpose', leg.get('purpose'));
          allLegsHaveDrop = false;
        }
      });
      return allLegsHaveDrop;
    },

    validateCutOffInAllSubsequentLegs() {
      const legsInBooking = this.get('activeLegsInBooking');

      for (let i = 0; i < legsInBooking.length; i++) {
        const leg = this.get('legsInBooking').objectAt(i);

        if (!this.validateCutOff(leg)) {
          return true;
        }
      }
    },

    validateSeatAndPassengerCount() {
      const ccount = this.get('selectedCompanionCount') ? this.get('selectedCompanionCount') : 0;
      const pcaTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'PCA';
      });
      const pcaRiders = [];
      this.get('booking.selectedRiders').forEach(rider => {
        const riderTravelNeeds = rider.get('travelNeeds');
        riderTravelNeeds.forEach(riderTravelNeed => {
          if (riderTravelNeed.type === 'PCA') {
            pcaRiders.push(rider);
          }
        });
      });
      const pcaCount = pcaTravelNeed ? pcaRiders.length : 0;
      const noOfRiders = this.get('booking').noOfSelectedRiders;
      const noOfPassengers = noOfRiders + pcaCount + parseInt(ccount, 10);
      const configItems = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-travel_need_types';
      });
      const bigSeatsTN = configItems.toArray().filter(tr => parseInt(tr.get('value.Vehicle Capacity Count'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('value.Name').toUpperCase());
      const bigSeatTravelneeds = this.get('legTravelNeeds').filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      const wheelchairTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'WHEELCHAIR';
      });
      const ambulatoryTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'AMBULATORY';
      });
      const wheelchaircount = wheelchairTravelNeed ? parseInt(wheelchairTravelNeed.count, 10) : 0;
      const ambulatoryCount = ambulatoryTravelNeed ? parseInt(ambulatoryTravelNeed.count, 10) : 0;
      const noOfSeats = ambulatoryCount + wheelchaircount + bigSeatTravelneeds.length;

      if (noOfPassengers !== noOfSeats) {
        return false;
      }

      return true;
    },

    validateEmptyTravelNeeds() {
      return this.get('legTravelNeeds').toArray().some(tr => !tr.get('travelNeedTypeNameUppercase'));
    },

    validateRecords() {
      let valid = true;
      this.set('editModal.errors', []);
      const firstLeg = this.get('legsInBooking.firstObject');
      const pickAddress = firstLeg.get('segments.firstObject.pick.place.address.postalCode');
      const dropAddress = firstLeg.get('segments.lastObject.drop.place.address.postalCode');
      const requestTime = firstLeg.get('requestTime');

      if (!this.get('isLoadAndUnLoadTimeValid')) {
        this.get('booking').showErrorMessage('Load/Unload time value must be 15 min or less.');
        valid = false;
      }

      if (!this.get('isLoadAndUnLoadTimeNumber')) {
        this.get('booking').showErrorMessage('Please enter valid numbers for load/unload time.');
        valid = false;
      }

      if (Ember.isEmpty(requestTime) || Ember.isNone(requestTime)) {
        this.get('booking').showErrorMessage('Request time is missing. Please enter a valid time.');
        valid = false;
      }

      if (Ember.isEmpty(pickAddress) || Ember.isNone(pickAddress)) {
        this.get('booking').showErrorMessage('Unknown Address. Please enter a valid pickup address.');
        valid = false;
      }

      if (Ember.isEmpty(dropAddress) || Ember.isNone(dropAddress)) {
        this.get('booking').showErrorMessage('Unknown Address. Please enter a valid dropoff address.');
        valid = false;
      }

      if (!this.validateIfPickPresentInAllLegs()) {
        const message = this.get('purpose') === 'trip' ? 'leg' : 'return';
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid pickup ${message} address.`);
        valid = false;
      }

      if (!this.validateIfDropPresentInAllLegs()) {
        const message = this.get('purpose') === 'trip' ? 'leg' : 'return';
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid dropoff ${message} address.`);
        valid = false;
      }

      if (!this.validateLegRequestTimeAfterPrevLegRequestTime()) {
        this.get('booking').showErrorMessage('The leg trip time must come after the previous trip request time.');
        valid = false;
      }

      if (this.validateCutOffInAllSubsequentLegs()) {
        this.get('booking').showErrorMessage(`Same day booking must be at least ${CUT_OFF_TIME_FOR_SAMEDAY * 60} mins in advance. Please select a different request time.`);
        valid = false;
      }

      if (!this.validateEligibility(firstLeg)) {
        this.set('selectedLegDate', (0, _moment.default)(firstLeg.get('requestTime')).format('M.D.Y'));
        this.get('booking').showErrorMessage(`${this.get('riderToBook.firstName')} ${this.get('riderToBook.lastName')}
      has no client eligibility records for the date \n  ${this.get('selectedLegDate')} . Please select another date.`);
        valid = false;
      }

      if (!this.validateSeatAndPassengerCount()) {
        this.get('booking').showErrorMessage('The number of seats used for the booking must match the number of passengers.');
        valid = false;
      }

      if (this.validateEmptyTravelNeeds()) {
        this.get('booking').showErrorMessage('Please remove empty Travel needs to proceed.');
        valid = false;
      }

      if (!this.validateFutureBookingTimeline()) {
        this.get('booking').showErrorMessage('Trip date must be within the allowed booking timeframe.');
        valid = false;
      }

      return valid;
    },

    // when the date of the first leg changes . all the date should change
    onFirstLegDateChange() {
      const rTime = this.get('legsInBooking.firstObject.requestTime');
      const firstLegDateAndTime = (0, _moment.default)(rTime);
      const firstLegYear = firstLegDateAndTime.year();
      const firstLegMonth = firstLegDateAndTime.month();
      const firstLegDay = firstLegDateAndTime.date();

      for (let i = 1; i < this.get('legsInBooking').length; i++) {
        const leg = this.get('legsInBooking').objectAt(i);
        const legDateAndTime = (0, _moment.default)(leg.get('requestTime'));
        legDateAndTime.year(firstLegYear);
        legDateAndTime.month(firstLegMonth);
        legDateAndTime.date(firstLegDay);
        const legDateObjectWithTime = legDateAndTime.toDate();
        leg.set('requestTime', legDateObjectWithTime);
      }
    },

    validateFutureBookingTimeline() {
      const legsInBooking = this.get('activeLegsInBooking');
      const currentDate = new Date();
      const maxDate = new Date();
      maxDate.setHours(24, 0, 0);
      const newDate = (0, _moment.default)(currentDate);
      const futureMaxDate = (0, _moment.default)(maxDate);

      for (let i = 0; i < legsInBooking.length; i++) {
        const previousLeg = legsInBooking.objectAt(i);
        const previousLegRequestTime = (0, _moment.default)(previousLeg.get('requestTime'));
        const futureBookingTimeline = futureMaxDate.clone().add(FUTURE_BOOKING_TIMELINE, 'days');
        const nextLeg = legsInBooking.objectAt(i + 1);

        if (!previousLegRequestTime.isBetween(newDate, futureBookingTimeline)) {
          return false;
        }

        if (nextLeg) {
          const nextLegRequestTime = (0, _moment.default)(nextLeg.get('requestTime'));

          if (!nextLegRequestTime.isBetween(newDate, futureBookingTimeline)) {
            return false;
          }
        }
      }

      return true;
    },

    validateEligibility(leg) {
      const requestTimeOfLeg = leg.get('requestTime');
      const momentedRequestTime = (0, _moment.default)(requestTimeOfLeg);
      const eligibleEndDate = (0, _moment.default)(this.get('riderToBook').get('eligibilityEnd'), 'DD/MM/YYYY').add(1, 'days');
      const eligibleStartDate = (0, _moment.default)(this.get('riderToBook').get('eligibilityStart'), 'DD/MM/YYYY'); // Checking the rider eligibility

      if (!(momentedRequestTime.isSameOrAfter(eligibleStartDate) && momentedRequestTime.isSameOrBefore(eligibleEndDate))) {
        return false;
      }

      return true;
    },

    validateCutOff(leg) {
      const requestTimeOfLeg = leg.get('requestTime');
      const momentedRequestTime = (0, _moment.default)(requestTimeOfLeg);
      const anchor = leg.get('anchor').toLowerCase();

      if (momentedRequestTime.isSame((0, _moment.default)(), 'day') && anchor === PICK) {
        const today = new Date();
        const todayWithOneHourExtended = new Date(today);
        todayWithOneHourExtended.setHours(todayWithOneHourExtended.getHours() + CUT_OFF_TIME_FOR_SAMEDAY);

        if (momentedRequestTime.isBefore(todayWithOneHourExtended) && CUT_OFF_TIME_FOR_SAMEDAY !== 0) {
          return false;
        }
      }

      return true;
    },

    validateLegRequestTimeAfterPrevLegRequestTime() {
      const legsInBooking = [...this.get('tripLegsInBooking').toArray(), ...this.get('returnTripLegsInBooking').toArray()];

      if (legsInBooking.length <= 1) {
        return true;
      }

      for (let i = 1; i < legsInBooking.length; i++) {
        const prevLeg = legsInBooking[i - 1];
        const prevlegRequestime = (0, _moment.default)(prevLeg.get('requestTime')).seconds(0).milliseconds(0);
        const curLeg = legsInBooking[i];
        const curLegRequestTime = (0, _moment.default)(curLeg.get('requestTime')).seconds(0).milliseconds(0); //Compares each leg requestTime with previous leg requestTime

        if (curLegRequestTime.isSameOrBefore(prevlegRequestime)) {
          return false;
        }
      }

      return true;
    },

    checkIfLegIsFirstLeg(leg) {
      const legsInBooking = this.get('activeLegsInBooking');
      const indexOfLeg = legsInBooking.indexOf(leg);

      if (indexOfLeg > 0) {
        return false;
      }

      return true;
    },

    combineServiceNeedsForGroupBooking() {
      const serviceNeeds = this.get('booking.selectedRiders').map(rider => rider.serviceNeeds);
      const uniqServiceNeeds = (0, _lodash.uniq)((0, _lodash.flattenDeep)(serviceNeeds));
      this.set('serviceNeeds', uniqServiceNeeds);
    },

    /**
     * when there is  no trip, this method will be called and close the spinner
     * @param ruleContext
     */
    noTripActionHandler(ruleContext) {
      console.log('noTripActionHandler : rule outcome map: ', ruleContext.outComeMap);
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    /**
     * when itp server api is failed, this method will be called and showed the warning message
     * @param ruleContext
     */
    itpRequestFailureHandler(ruleContext) {
      console.log('itpRequestFailureHandler : rule outcome map: ', ruleContext.outComeMap);
      this.get('notifications').warning('Failed to request ITP server');
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    eligibilityErrorHandler(ruleContext) {
      console.log('eligibilityErrorHandler : rule outcome map: ', ruleContext.outComeMap);
      const {
        errorInfo
      } = ruleContext;
      this.get('notifications').warning(errorInfo.message);
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    overridePermissionHandler(ruleContext) {
      console.log('overridePermissionHandler : rule outcome map: ', ruleContext.outComeMap);
      this.set('showOnlyEligibilityOkButton', true);
      this.set('riderIdConditionsMap', this.get('booking').getRiderIdToConditionsMap(ruleContext.riderConditionsResultMap, true));
      this.get('workspace').set('isGlobalSpinnerVisible', false);
      this.set('showEligibilityRestrictions', true);
    },

    /**
     * if user have  permission to override the service mode, show eligibility modal with 'yes' and 'no' button
     * @param ruleContext
     * @returns {Promise}
     */
    overrideConditionPreActionHandler(ruleContext) {
      return new Promise(resolve => {
        this.set('riderIdConditionsMap', this.get('booking').getRiderIdToConditionsMap(ruleContext.riderConditionsResultMap, true));
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.set('showOnlyEligibilityOkButton', false);
        this.set('showEligibilityRestrictions', true);
        this.set('onEligibilityWarningYes', onEligibilityWarningYes.bind(this, this, ruleContext, resolve));
        this.set('onEligibilityWarningNo', onEligibilityWarningNo.bind(this, this, ruleContext, resolve));
      });
    },

    /**
     * if taxi allowed rule is passed , this method will be called
     * @param ruleContext
     */
    taxiRequestHandler(ruleContext) {
      this.setItpInfo(ruleContext);
      this.get('workspace').set('isGlobalSpinnerVisible', false);
      const travelModes = ['taxi']; // if the workflow resolves  itp  itinerary segments, show itp as default in service mode drop down

      if (this.hasValidItpResult(ruleContext)) {
        travelModes.unshift('itp');
      }

      console.log('taxiRequestHandler : rule outcome map: ', ruleContext.outComeMap);
      this.set('travelModes', travelModes);
      this.fetchPromiseTime();
    },

    /**
     * if taxi not allowed ,  the method will be called
     * @param ruleContext
     */
    paraTransitHandler(ruleContext) {
      this.setItpInfo(ruleContext);
      this.get('workspace').set('isGlobalSpinnerVisible', false);
      const travelModes = ['paratransit', 'broker']; // if the workflow resolves  itp  itinerary segments, show itp as default in service mode drop down

      if (this.hasValidItpResult(ruleContext)) {
        travelModes.unshift('itp');
      }

      this.set('travelModes', travelModes);
      this.fetchPromiseTime();
    },

    async itpRequestHandler(ruleContext) {
      this.setItpInfo(ruleContext);
      this.get('workspace').set('isGlobalSpinnerVisible', false);
      const travelModes = ['itp'];
      this.set('travelModes', travelModes);
      this.fetchPromiseTime();
    },

    hasValidItpResult(ruleContext) {
      const {
        itpRequestResults,
        itpResultType
      } = ruleContext;
      return itpRequestResults.length && itpResultType !== _itineraryType.NO_SOLUTION;
    },

    updateFare() {
      const defaultFare = this.get('defaultFare');
      const companionFare = this.get('companionFare');
      const companionCount = this.get('booking').get('selectedCompanionCount'); //Rider is consider as one and if we add  one companion it becames 2

      const totalFare = companionCount !== 0 ? companionCount * companionFare + defaultFare : defaultFare;
      this.get('booking.activeBooking.legs').forEach(leg => {
        const segments = leg.get('segments');
        segments.forEach(segment => {
          segment.set('fare', totalFare);
        });
      });
    },

    executeEligibilityRules() {
      // clear itp info and selected travel mode in booking service, before executing rule
      this.get('booking').setItpInfo();
      this.get('booking').setSelectedTravelMode(); // create new   rule context

      const eligibilityRuleContext = new _eligibilityRuleContext.default(this.get('store'), this.get('booking'), this.get('tooltip'), this.get('systemConfig'), this.get('permissionLayer')); // build rule graph

      const eligibilityRuleBuilder = new _eligibilityRuleBuilder.default();
      const rootRule = eligibilityRuleBuilder.buildRuleGraph(this.noTripActionHandler.bind(this), this.itpRequestFailureHandler.bind(this), this.taxiRequestHandler.bind(this), this.paraTransitHandler.bind(this), this.overrideConditionPreActionHandler.bind(this), this.overridePermissionHandler.bind(this), this.itpRequestHandler.bind(this), this.eligibilityErrorHandler.bind(this)); // execute the rule, after execute the rule  one of the action handlers will be called by one of the rule

      this.get('workspace').set('isGlobalSpinnerVisible', true); // rules with async call  are executed

      eligibilityRuleBuilder.execute(rootRule, eligibilityRuleContext);
    },

    /**
     * create temp booking and fetch promise time after that, show booking details  screen
     * @returns {Promise<void>}
     */
    async fetchPromiseTime() {
      try {
        if (this.get('booking.enableNewPromiseTimeApi')) {
          // TODO  to remove later after fixing backend api
          this.get('workspace').set('isGlobalSpinnerVisible', true);
          this.set('booking.activeBooking.status', 'Requested'); // clear temp booking info

          this.get('booking').setTemporaryBookingInfo(); // create temp booking and call postAddBookingTripOperation  api to  get promise time for each legs

          const temporaryBookingResult = await this.get('booking').saveBookingWithPromiseTime();
          await this.get('booking').processPromiseTimeAsyncOperation(temporaryBookingResult);
        }
      } catch (err) {
        const message = `Unable to calculate Promise time  ${err.message}`;
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        console.warn(message);
      } finally {
        this.toggleProperty('showBookingSummary');
      }
    },

    setItpInfo(ruleContext) {
      const {
        itpRequestResults,
        itpResultType,
        hasOverrideITP
      } = ruleContext;
      this.get('booking').setItpInfo(itpRequestResults, itpResultType, hasOverrideITP);
    },

    resetFieldValues() {
      this.get('booking.activeBooking.legs').forEach(leg => {
        if (!leg) {
          return;
        }

        (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.pick')).rollbackAttributes();
        (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.drop')).rollbackAttributes();
        const pickAddress = (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.pick.place.address'));
        const dropAddress = (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.drop.place.address'));
        const pickLocation = (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.pick.place.location'));
        const dropLocation = (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject.drop.place.location'));

        if (pickAddress) {
          pickAddress.rollbackAttributes();
          pickLocation.rollbackAttributes();
        }

        if (dropAddress) {
          dropAddress.rollbackAttributes();
          dropLocation.rollbackAttributes();
        }

        (0, _unwrapProxy.unwrapProxy)(leg.get('segments.firstObject')).rollbackAttributes();
        (0, _unwrapProxy.unwrapProxy)(leg).rollbackAttributes();
      });
    },

    // NYAAR-13244 Booking - Upon Editing Fare field is not calculated correctly upon selecting the companions
    setDefaultFare() {
      const fare = this.get('legsInBooking.firstObject.segments.firstObject.fare');
      const regularFare = this.get('booking').getSysConfigRegularFare();
      const guestFare = this.get('booking').getSysConfigCompanionFare();
      const activeRiders = this.get('noOfActiveRiders'); // when REG or Guest fare is 0 return original fare

      if (regularFare === 0 || guestFare === 0) {
        if (activeRiders > 1) {
          this.set('defaultFare', regularFare * activeRiders);
          return;
        }

        this.set('defaultFare', regularFare);
        return;
      }

      const companion = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'COMPANION';
      });
      const companionCount = companion ? companion.get('count') : 0;
      const defaultFare = fare / (activeRiders + companionCount);

      if (activeRiders > 1) {
        this.set('defaultFare', defaultFare * activeRiders);
      } else {
        this.set('defaultFare', defaultFare);
      }
    },

    setZoneAddress() {
      const firstLeg = this.get('legsInBooking.firstObject');
      const pickZone = firstLeg.get('segments.firstObject.pick.place.address.zName');
      if (pickZone) firstLeg.set('segments.firstObject.pick.place.address.zName', pickZone.zoneName);
    },

    actions: {
      onUndoClick() {
        const defaultBookingData = this.get('defaultBookingData');
        const legTravelNeeds = defaultBookingData.get('legTravelNeeds').toArray();
        const legs = defaultBookingData.get('legs').toArray();
        const legSegments = defaultBookingData.get('legSegments').toArray();
        const hasReturnLeg = legs.toArray().find(ltn => ltn.get('purpose') === 'return');
        const loadTime = defaultBookingData.get('loadTime');
        const unLoadTime = defaultBookingData.get('unLoadTime');
        const newLegTravelNeed = defaultBookingData.get('travelNeeds');
        const travelNeed = legs.firstObject.legTravelNeeds;
        const notes = defaultBookingData.get('notes');
        const formattedPhoneNumber = defaultBookingData.get('formattedPhoneNumber');
        const rider = defaultBookingData.get('rider');
        rider.forEach(riders => {
          riders.set('notes', notes);
          riders.set('formattedPhoneNumber', formattedPhoneNumber);
        });
        legs.forEach((leg, index) => {
          const legSegment = legSegments[index];
          leg.set('segments.firstObject.pick.place', legSegment.get('pickPlace'));
          leg.set('segments.lastObject.drop.place', legSegment.get('dropPlace'));
          leg.set('segments.lastObject.drop.notes', legSegment.get('dropNotes'));
          leg.set('segments.firstObject.pick.notes', legSegment.get('pickNotes'));
          leg.set('segments.firstObject.fare', legSegment.get('fare'));
          leg.set('serviceWindow', legSegment.get('serviceWindow'));
          leg.set('segment.noSharing', legSegment.get('noSharing'));
          leg.set('anchor', legSegment.get('anchor'));
          leg.set('requestTime', legSegment.get('requestTime'));
          leg.set('segments.firstObject.pick.dwell', loadTime);
          leg.set('segments.firstObject.drop.dwell', unLoadTime);
        });
        this.set('booking.activeBooking.legs', legs);
        this.set('pickAddressText', '');
        this.set('dropAddressText', '');

        if (!hasReturnLeg) {
          this.set('returnTripCollapseState', 'collapsed');
        } else {
          this.set('returnTripCollapseState', 'expanded');
        }

        legTravelNeeds.forEach(travelNeedsType => {
          travelNeed.find(travelNeedType => {
            if (travelNeedType) {
              if (travelNeedType.get('travelNeedType.name') !== travelNeedsType.get('travelNeedType.name')) {
                travelNeed.removeObject(travelNeedType);
              }

              if (travelNeedType.get('travelNeedType.name') === travelNeedsType.get('travelNeedType.name')) {
                travelNeed.removeObject(travelNeedType);
              }
            }
          });
        });

        if (travelNeed.length === 0) {
          legTravelNeeds.forEach((newtravelNeed, index) => {
            const travelNeeds = newLegTravelNeed[index];
            newtravelNeed.set('count', travelNeeds.get('count'));
            newtravelNeed.set('travelNeedType', travelNeeds.get('travelNeedType'));
            newtravelNeed.set('passengerType', travelNeeds.get('passengerType'));
            travelNeed.pushObject(newtravelNeed);
          });
        }

        const companion = newLegTravelNeed.find(companionTravelNeed => {
          return companionTravelNeed.get('travelNeedTypeNameUppercase') === 'COMPANION';
        });
        const companionCount = companion ? this.get('selectedCompanionCount') : 0;
        this.get('booking').setCompanionCount(companionCount);
        if (this.get('travelNeedCollapseState') === 'expanded') this.set('travelNeedCollapseState', 'collapsed');
        if (this.get('passengerCollapseState') === 'expanded') this.set('passengerCollapseState', 'collapsed');
        if (this.get('serviceNeedCollapseState') === 'expanded') this.set('serviceNeedCollapseState', 'collapsed');
      },

      onSaveClick() {
        this.set('canSave', true);

        if (this.validateRecords()) {
          this.executeEligibilityRules();
        }
      },

      onXButtonClick() {
        this.toggleProperty('isProgressShown');
      },

      onProgressNoClick() {
        this.resetFieldValues();
        this.toggleProperty('isProgressShown');
        this.get('editModal').close();
      },

      onCloseClick() {
        this.toggleProperty('isProgressShown');
      },

      onShrinkClick() {
        this.onShrinkClick();
      },

      onApplyClick() {
        if (this.validateRecords()) {
          Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
          this.get('geocode').activateGeocode(false);
          this.set('addressRecord', null);
          this.get('service').apply();
        }
      },

      validateRequestDateTime(leg) {
        this.set('canSave', true);
        this.set('errorMessage', '');

        if (!this.validateEligibility(leg)) {
          this.set('selectedLegDate', (0, _moment.default)(leg.get('requestTime')).format('M.D.Y'));
          this.get('booking').showErrorMessage(`${this.get('riderToBook.firstName')} ${this.get('riderToBook.lastName')}
        has no client eligibility records for the date \n  ${this.get('selectedLegDate')} . Please select another date.`);
          return;
        }

        if (!this.validateLegRequestTimeAfterPrevLegRequestTime()) {
          this.get('booking').showErrorMessage('The leg trip time must come after the previous trip request time.');
          return;
        }

        if (!this.validateCutOff(leg)) {
          this.get('booking').showErrorMessage(`Same day booking must be at least ${CUT_OFF_TIME_FOR_SAMEDAY * 60} mins in advance. Please select a different request time.`);
          return;
        }

        if (!this.validateFutureBookingTimeline()) {
          this.get('booking').showErrorMessage('Trip date must be within the allowed booking timeframe.');
          return;
        }

        this.set('booking.isOpenErrorModal', false);
      },

      async onYesButtonClick() {
        if (this.validateRecords()) {
          this.get('workspace').set('isGlobalSpinnerVisible', true);
          this.set('booking.activeBooking.status', 'willCall');
          this.set('booking.activeBooking.legs', this.get('legsInBooking'));

          try {
            await this.get('booking').saveBooking(this.get('isEditMode'));
            const [bookings, trips] = await this.get('apiContext').loadBookingsForSelectedExternalRiders();
            this.set('booking.currentBookings', bookings);
            this.set('booking.currentTrips', trips);
            this.get('notifications').warning('Booking was saved but it will not be scheduled');
          } catch (err) {
            this.get('notifications').warning('Failed to create Booking');
          } finally {
            this.toggleProperty('isProgressShown');
            this.get('editModal').close();
            this.get('workspace').set('isGlobalSpinnerVisible', false);
          }
        }
      },

      async onClickClose() {
        this.toggleProperty('showBookingSummary');
      },

      onWarningModalClose() {
        this.set('canSave', true);
        this.set('isOpenErrorModal', false);
      },

      setLoadAndUnLoadTimeFlag(isLoadAndUnLoadTimeValid) {
        this.set('isLoadAndUnLoadTimeValid', isLoadAndUnLoadTimeValid);
      },

      setIsLoadAndUnLoadTimeNumberFlag(isLoadAndUnLoadTimeNumber) {
        this.set('isLoadAndUnLoadTimeNumber', isLoadAndUnLoadTimeNumber);
      },

      onEligibleWarningClose() {
        this.set('showEligibilityRestrictions', false);
      },

      onEligibleWarningOK() {
        this.set('showEligibilityRestrictions', false);
        this.get('editModal').close();
      }

    }
  });

  _exports.default = _default;
});