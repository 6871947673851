define("adept-iq/pods/components/side-drawer/add-break/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fields = _exports.stopColumns = void 0;
  const MIN_COLUMN_WIDTH = 50;
  const stopColumns = [{
    label: 'Trip ID',
    valuePath: 'stopPoint.trip.tripId',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true,
    width: '80px'
  }, {
    label: 'Last Name',
    valuePath: 'stopPoint.trip.rider.lastName',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true
  }, {
    label: 'Address',
    valuePath: 'stopPoint.place.address.simpleAddress',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true
  }, {
    label: 'City',
    valuePath: 'stopPoint.place.address.locality',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true
  }, {
    label: 'Stop',
    valuePath: 'stopPoint.stopType',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true
  }, {
    label: 'ETA',
    valuePath: 'eta',
    cellComponent: 'table/cells/trip-eta',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true
  }, {
    label: 'Promise Time',
    valuePath: 'stopPoint.computedPromiseTime',
    cellComponent: 'table/cells/date-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true,
    format: 'HH:mm'
  }, {
    label: 'Appt Time',
    valuePath: 'stopPoint.computedApptTime',
    cellComponent: 'table/cells/date-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true,
    format: 'HH:mm'
  }, {
    label: 'Stop Break Ord',
    valuePath: 'routeOrdinal',
    cellComponent: 'table/cells/base-cell',
    minResizeWidth: MIN_COLUMN_WIDTH,
    resizable: true
  }];
  _exports.stopColumns = stopColumns;
  const fields = {
    id: 'location',
    type: 'addresses',
    label: '',
    valuePath: 'place',
    modelName: 'place',
    addressType: 'originlocations',
    isMultiType: true,
    extra: {
      aliasPath: 'address.content.alias',
      notesPath: 'address.content.notes',
      displayPath: 'address.content.address',
      premisePath: 'address.content.premise',
      streetNumberPath: 'address.content.streetNumber',
      streetNamePath: 'address.content.streetName',
      localityPath: 'address.content.locality',
      regionPath: 'address.content.region',
      subRegionPath: 'address.content.county',
      postalCodePath: 'address.content.postalCode',
      countryPath: 'address.content.country',
      freeformAddressPath: 'address.content.freeformAddress',
      tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
      useOptionRecord: true,
      streetAddressPath: 'address.content.streetAddress',
      locationsPath: 'address.locations.content.firstObject.latlng'
    }
  };
  _exports.fields = fields;
});