define("adept-iq/pods/components/iq-widgets/map-widget/config/polyline", ["exports", "adept-iq/pods/components/iq-widgets/map-widget/config/colors"], function (_exports, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // don't use opacity in these presets; it may cause polyline opacity to be
  // reset if the active style changes
  var _default = {
    // these generic styles should go away
    solidPlanned: {
      color: _colors.PLANNED_COLOR
    },
    solidNavigation: {
      color: _colors.PENDING_COLOR
    },
    solidPerformed: {
      color: _colors.PERFORMED_COLOR
    },
    solid: {
      dashArray: null,
      weight: 1,
      color: _colors.PLANNED_COLOR
    },
    solidLate: {
      dashArray: null,
      color: _colors.OTP_LATE_COLOR
    },
    solidDanger: {
      dashArray: null,
      color: _colors.OTP_DANGER_COLOR
    },
    dotted: {
      dashArray: '0 8',
      color: _colors.PLANNED_COLOR
    },
    dottedLate: {
      dashArray: '0 8',
      color: _colors.OTP_LATE_COLOR
    },
    dottedDanger: {
      dashArray: '0 8',
      color: _colors.OTP_DANGER_COLOR
    },
    dottedDistance: {
      dashArray: '7 12',
      color: _colors.DISTANCE_COLOR,
      weight: 5,
      className: 'removeCursor'
    },
    // better to use descriptive styles like these below:
    trip: {
      dashArray: '0 5',
      color: _colors.PLANNED_DARK_COLOR
    },
    tripDanger: {
      dashArray: '0 8',
      color: _colors.OTP_LATE_COLOR
    },
    tripLate: {
      dashArray: '0 8',
      color: _colors.OTP_DANGER_COLOR
    },
    tripSolid: {
      dashArray: null,
      color: _colors.PLANNED_DARK_COLOR
    },
    tripSolidDanger: {
      dashArray: null,
      color: _colors.OTP_LATE_COLOR
    },
    tripSolidLate: {
      dashArray: null,
      color: _colors.OTP_DANGER_COLOR
    },
    tripPerformed: {
      dashArray: null,
      color: _colors.PERFORMED_COLOR
    },
    // otp doesn't affect stop polylines
    stopPlanned: {
      color: _colors.PLANNED_COLOR
    },
    stopPlannedDanger: {
      color: _colors.PLANNED_COLOR
    },
    stopPlannedLate: {
      color: _colors.PLANNED_COLOR
    },
    stopPending: {
      color: _colors.PENDING_COLOR
    },
    stopPendingDanger: {
      color: _colors.PENDING_COLOR
    },
    stopPendingLate: {
      color: _colors.PENDING_COLOR
    },
    stopPerformed: {
      color: _colors.PERFORMED_COLOR
    },
    // otp does not affect route polylines
    // Pane used to control the ordering of layers on the map
    // FYI: You can access panes with map.getPane or map.getPanes methods.
    route: {
      color: _colors.PLANNED_COLOR,
      weight: 3
    },
    routePlanned: {
      color: _colors.PLANNED_COLOR,
      weight: 6,
      pane: 'tilePane'
    },
    routeNavigation: {
      color: _colors.PENDING_COLOR,
      weight: 3,
      pane: 'shadowPane'
    },
    routePerformed: {
      color: _colors.PERFORMED_COLOR,
      weight: 3
    },
    routeScheduled: {
      color: _colors.SCHEDULE_COLOR,
      weight: 3,
      pane: 'tilePane'
    },
    routeFloatingBreak: {
      color: _colors.FLOATING_BREAK_COLOR,
      weight: 3,
      dashArray: '3 5',
      pane: 'tilePane'
    }
  };
  _exports.default = _default;
});