define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/cdl-classification/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['cdl-classification'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tableRef: null,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    description: null,
    isNewCdl: false,
    editAPI: null,
    disableForm: true,
    refreshRecord: true,

    //To refresh on undoRecord and newRecord
    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveCdlClassificationRecord = this.get('onSaveCdlClassificationRecord');
      const onNewCdlClassificationRecord = this.get('onNewCdlClassificationRecord').bind(this);
      const onEditCdlClassificationRecord = this.get('onEditCdlClassificationRecord').bind(this);
      const onDeleteCdlClassificationRecord = this.get('onDeleteCdlClassificationRecord');
      const onUndoCdlClassificationRecord = this.get('onUndoCdlClassificationRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveCdlClassificationRecord,
          newAction: onNewCdlClassificationRecord,
          deleteAction: onDeleteCdlClassificationRecord,
          editAction: onEditCdlClassificationRecord,
          undoAction: onUndoCdlClassificationRecord
        });
        this.buttonSettings();
      });
    },

    buttonSettings(deleteBtn = false, editBtn = false, saveBtn = true, undoBtn = true, newBtn = false) {
      const editAPI = this.get('editAPI');
      editAPI.setProperties({
        deleteBtn,
        editBtn,
        saveBtn,
        undoBtn,
        newBtn
      });
    },

    cdlChangeSet: Ember.computed('selectedRecord', 'refreshRecord', function () {
      const selectedRecord = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));

      if (selectedRecord) {
        this.onSelectTableRow(selectedRecord);
      }

      return this.buildChangeSet(selectedRecord);
    }),

    buildChangeSet(record = null) {
      const displayName = record ? record.get('displayName') : '';
      return new _emberChangeset.default(Ember.Object.create({
        displayName: displayName
      }));
    },

    onSaveCdlClassificationRecord: (0, _emberConcurrency.task)(function* () {
      const name = this.get('cdlChangeSet.displayName');
      const value = {
        displayName: name
      };
      const isNewCdl = this.get('isNewCdl');
      const store = this.get('store');
      const notifications = this.get('notifications');
      let record = null;

      if (isNewCdl) {
        const data = {
          category: 'config-System_Configuration-CDL_classifications',
          type: 'object',
          name,
          displayName: name,
          value
        };
        record = this.get('store').createRecord('cs-config-item', data);
      } else {
        const id = `config-System_Configuration-CDL_classifications/${this.get('selectedRecord.id')}`;
        record = store.peekRecord('cs-config-item', id);
        record.set('value', value);
        record.set('name', name);
        record.set('displayName', name);
      }

      try {
        yield record.save();
        notifications.success('Lookup record successfully saved.');
        this.set('isNewCdl', false);
        this.set('disableForm', true);
        this.get('tableRef').refreshData(); //to refresh table data

        this.buttonSettings();
      } catch (e) {
        notifications.warning('Lookup record failed to save.');
        console.warn(e); // eslint-disable-line
      }
    }).drop(),
    onDeleteTask: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');

      try {
        const id = `config-System_Configuration-CDL_classifications/${this.get('selectedRecord.id')}`;
        const record = this.store.peekRecord('cs-config-item', id);
        yield record.destroyRecord();
        notifications.success('Record successfully deleted.');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (error) {
        console.error(error); // eslint-disable-line

        notifications.warning('Record failed to delete.');
      }
    }).drop(),

    onNewCdlClassificationRecord() {
      this.set('isNewCdl', true);
      this.get('tableRef').onUncheckAll();
      this.set('disableForm', false);
      this.toggleProperty('refreshRecord');
      this.buttonSettings(true, true, false, false, true);
    },

    onEditCdlClassificationRecord() {
      this.set('isNewCdl', false);
      this.set('disableForm', false);
      this.buttonSettings(true, true, false, false, true);
    },

    onDeleteCdlClassificationRecord: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      yield tooltip.pushConfirmation({
        title: 'Look UP',
        hasoverlay: true,
        tip: 'Are you sure you want to delete this record?',
        primaryActionText: 'Confirm',
        primaryAction: () => {
          const store = this.get('store');
          const displayName = this.get('cdlChangeSet.displayName') || '';

          if (displayName) {
            const driver = store.peekAll('driver').filter(record => record.get('active') && record.get('cdlClassificationName.displayName') === displayName);

            if (driver.length) {
              tooltip.pushConfirmation({
                title: 'Look UP',
                hasoverlay: true,
                tip: `There are ${driver.length} active driver(s) with this Driver CDL Classification description. You must either change the Driver CDL Classification description on these driver(s) or delete these driver(s) prior to changing the Driver CDL Classification description.`,
                primaryActionText: 'OK',

                primaryAction() {
                  tooltip.reset();
                }

              });
            }
          }

          return this.get('onDeleteTask').perform().then(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        }

      });
    }).drop(),

    onUndoCdlClassificationRecord() {
      this.toggleProperty('refreshRecord');
    },

    actions: {
      onTextChange(record, path, event) {
        const value = event.target.value;
        record.set(path, value);
        this.onSelectedRecordDirty(record.get('isDirty'), record);
      }

    }
  });

  _exports.default = _default;
});