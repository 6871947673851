define("adept-iq/serializers/driver-19-a-review", ["exports", "adept-iq/serializers/-driver"], function (_exports, _driver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _driver.default.extend({
    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      json.data.type = 'driver19AReview';

      if (json.data && json.data.relationships) {
        const providerRelationship = json.data.relationships.provider;
        const examTypeNameRelationship = json.data.relationships.examTypeName;

        if (!Ember.isNone(providerRelationship)) {
          providerRelationship.data.type = 'providerName';
          json.data.relationships.providerName = json.data.relationships.provider;
          delete json.data.relationships.provider;
        }

        if (!Ember.isNone(examTypeNameRelationship)) {
          examTypeNameRelationship.data.type = 'examTypeName';
        }
      }

      return json;
    },

    modelNameFromPayloadKey(key) {
      if (key === 'driver19AReview') {
        return 'driver-19-a-review';
      }

      return this._super(key);
    },

    normalize(modelClass, resourceHash) {
      const relationships = resourceHash.relationships ? resourceHash.relationships : {};
      relationships.provider = {
        data: {
          type: 'provider',
          id: relationships.providerName.data.id
        }
      };
      delete relationships.providerName;
      resourceHash.relationships = relationships;

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});