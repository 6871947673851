define("adept-iq/classes/polylines/trips/base", ["exports", "adept-iq/classes/polylines/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isLineSolid = {
    active: true,
    completed: true,
    noshow: true
  };

  var _default = _base.default.extend({
    // `BasePolyline` overrides
    style: Ember.computed.readOnly('tripStyle'),
    pattern: Ember.computed.readOnly('tripPattern'),
    options: Ember.computed.readOnly('tripOptions'),
    // local properties
    stopsIsVisible: null,
    otp: null,
    tripStyle: Ember.computed('otp', 'lineStyle', function () {
      const lineStyle = this.get('lineStyle');
      let style = `trip${lineStyle}`;
      const otp = this.get('otp');
      style += this._getOTPstyleSuffix(otp);
      return style;
    }),
    lineStyle: Ember.computed('record.computedStatus', function () {
      const status = this.get('record.computedStatus');
      if (!status) return '';

      if (isLineSolid[status.toLowerCase()]) {
        return 'Solid';
      }

      return '';
    }),
    tripPattern: Ember.computed('tripStyle', 'stopsIsVisible', function () {
      const style = this.get('tripStyle');
      const stopsIsVisible = this.get('stopsIsVisible');

      if (stopsIsVisible) {
        // if stop markers are visible, move arrows back a little
        return `${style}Offset`;
      }

      return style;
    }),
    tripOptions: Ember.computed('', function () {
      return {
        weight: 4
      };
    }),

    _getOTPstyleSuffix(otp) {
      let suffix = '';

      if (otp === 'L') {
        suffix += 'Late';
      } else if (otp === 'D') {
        suffix += 'Danger';
      }

      return suffix;
    }

  });

  _exports.default = _default;
});