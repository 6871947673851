define("adept-iq/classes/rules/eligibility-rules/itp-request-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type"], function (_exports, _baseRule, _ruleResultType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ItpRequestRule = void 0;

  class ItpRequestRule extends _baseRule.default {
    async doEvaluate(ruleContext) {
      const {
        bookingService
      } = ruleContext;
      const legs = bookingService.get('activeBooking.legs');
      const allPromises = legs.map((leg, legIndex) => {
        return bookingService.postScheduleDemandTripForLeg(leg, legIndex);
      });

      try {
        ruleContext.itpRequestResults = await Promise.all(allPromises);
        return _ruleResultType.RESULT_TYPE_YES;
      } catch (error) {
        console.error(error); //eslint-disable-line no-console

        return _ruleResultType.RESULT_TYPE_NO;
      }
    }

  }

  _exports.ItpRequestRule = ItpRequestRule;
  ItpRequestRule.RULE_NAME = 'ItpRequestRule';
});