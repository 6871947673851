define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/physical-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TzTjZQeQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"table-container\"],[9],[0,\"\\n  \"],[1,[27,\"iq-widgets/vehicle-driver-manager-widget/tabs/lookup/physical-type/table\",null,[[\"tableRef\"],[[23,[\"tableRef\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"form-panel-wrapper\"],[9],[0,\"\\n\"],[4,\"generic-components/panel\",null,[[\"class\",\"title\",\"isCollapsible\"],[\"route-information-panel\",\"CDL Classification\",false]],{\"statements\":[[0,\"    \"],[7,\"form\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"container row offset-md-1\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"col-4\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n            \"],[7,\"label\"],[11,\"class\",\"label\"],[9],[0,\"Physical Type  \"],[10],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"class\",\"placeholder\",\"disabled\",\"type\",\"input\",\"change\",\"value\"],[\"description-textbox\",\"Enter Name\",[23,[\"disableForm\"]],\"text\",[27,\"action\",[[22,0,[]],\"onTextChange\",[23,[\"physicalTypeChangeSet\"]],\"displayName\"],null],[27,\"action\",[[22,0,[]],\"onTextChange\",[23,[\"physicalTypeChangeSet\"]],\"displayName\"],null],[23,[\"physicalTypeChangeSet\",\"displayName\"]]]]],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/physical-type/template.hbs"
    }
  });

  _exports.default = _default;
});