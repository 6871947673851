define("adept-iq/pods/components/iq-widgets/vehicles-breakdown-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/vehicles-breakdown-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    classNames: ['vehicle-breakdown-form-widget', 'data-test-vehicle-breakdown-form-widget'],

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const breakdownPlace = record.get('breakdownPlace');
        const breakdownDate = (0, _moment.default)(record.get('breakdownTime'));
        const breakdownType = record.get('breakdownType');
        const currentDate = (0, _moment.default)();

        if (Ember.isNone(breakdownPlace.get('location.lat')) || Ember.isNone(breakdownPlace.get('location.lng'))) {
          this.get('editModal.errors').pushObject('Location cannot be empty');
          valid = false;
        }

        if (breakdownDate.isAfter(currentDate)) {
          this.get('editModal.errors').pushObject('Breakdown time cannot be set in the future');
          valid = false;
        }

        if (Ember.isNone(breakdownType)) {
          this.get('editModal.errors').pushObject('Must have a breakdown reason');
          valid = false;
        }
      });
      return valid;
    },

    undoForBreakDown() {
      const lastUndoState = this.get('editModal.undoHistory').popObject();
      lastUndoState.forEach(({
        record,
        properties
      }) => {
        if (properties.hasOwnProperty('country')) {
          record.set('address', null);
        }

        record.setProperties(properties);
      });
      this.set('isLastUndoStateCommitted', true);
    },

    undoAllForBreakDown() {
      const undoHistory = this.get('editModal.undoHistory');
      if (Ember.isPresent(undoHistory)) while (undoHistory.length > 0) this.undoForBreakDown();
    },

    actions: {
      onApplyClick() {
        if (this.validateRecords()) {
          const title = this.get('title');
          this.get('service').apply(title);
        }
      },

      onUndoClick() {
        this.undoAllForBreakDown();
      }

    }
  });

  _exports.default = _default;
});