define("adept-iq/pods/components/dropdown-provider/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown-provider'],
    store: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    user: Ember.inject.service(),
    widgetService: Ember.inject.service('widget'),
    isEnable: Ember.computed('isEnabled', 'user', function () {
      const isRoadSupEnable = this.get('user').isRoadSupEnable();
      return !isRoadSupEnable;
    }),
    isEnabled: Ember.computed.oneWay('isEnable'),
    title: 'All Providers',
    isOpen: false,
    isOpened: Ember.computed.oneWay('isOpen'),
    providersList: Ember.computed.alias('workspaceContext.providersList'),
    disabledProvider: null,
    allProviders: Ember.computed.alias('workspaceContext._allProviders'),
    // disable all provider checkboxes
    hasOneProvider: Ember.computed.lte('providersList.length', 1),

    resetDisableProvider() {
      const disabledProvider = this.get('disabledProvider');

      if (disabledProvider) {
        disabledProvider.set('isDisabled', false);
        this.set('disabledProvider', null);
      }
    },

    updateTitle: Ember.observer('providersList', function () {
      const results = this.get('providersList').filterBy('isChecked', true);
      const workspaceContext = this.get('workspaceContext');

      if (results.length === 1) {
        this.set('title', results[0].providerName);
        Ember.set(results[0], 'isDisabled', true);
        this.set('disabledProvider', results[0]);
        workspaceContext.set('_allProviders', false);
      } else if (results.length < this.providersList.length && results.length > 1) {
        this.set('title', 'Multiple');
        workspaceContext.set('_allProviders', false);
        this.resetDisableProvider();
      } else if (results.length === this.providersList.length) {
        this.set('title', 'All Providers');
        workspaceContext.set('_allProviders', true);
        this.resetDisableProvider();
      }
    }),

    async setupProviderList() {
      const providers = this.get('store').peekAll('provider').filter(provider => {
        return !Ember.isEmpty(provider.get('name')) && !Ember.isEmpty(provider.get('id')) && provider.get('userCanAccessProvider');
      });
      providers.forEach(provider => provider.set('isChecked', true));
      this.set('providersList', providers);
    },

    init() {
      this._super(...arguments);

      const workspaceContext = this.get('workspaceContext');
      workspaceContext.set('_allProviders', true);
      this.get('store').findAll('provider').then(() => {
        this.setupProviderList();
        this.updateTitle();
      }).then(() => {
        const providersList = this.get('providersList');
        providersList.forEach(provider => {
          Ember.set(provider, 'isChecked', true);
        });
      });
    },

    toggleSelect() {
      this.toggleProperty('isOpen');
    },

    actions: {
      onToggleSelect() {
        this.toggleSelect();
      },

      onOpen() {
        this.toggleSelect();
      },

      onClose() {
        this.toggleSelect();
      },

      async onClickCheckAll(event) {
        const providersList = this.get('providersList');
        const topActiveContext = this.get('activeContext.topActiveContext');
        const activeContextNodes = topActiveContext.get('nodes') || [];

        if (event.target.checked) {
          providersList.forEach(provider => {
            Ember.set(provider, 'isChecked', event.target.checked);
          });
        } else {
          providersList.forEach((provider, index) => {
            if (index === 0) {
              // keep first provider checked.
              Ember.set(provider, 'isChecked', !event.target.checked);
            } else {
              Ember.set(provider, 'isChecked', event.target.checked);
            }
          });
        }

        this.updateTitle();
        this.get('workspaceContext').setSelectedProviders();
        this.get('widgetService').globalFilterChanged();
        topActiveContext.clearCheckedItems();
        activeContextNodes.reduce((obj, node) => {
          this.get('workspaceContext').trigger('change', [node.modelName]);
        });
      },

      async onClickCheckBox(provider, event) {
        const selectedProvider = provider;
        const topActiveContext = this.get('activeContext.topActiveContext');
        const activeContextNodes = topActiveContext.get('nodes') || [];
        Ember.set(selectedProvider, 'isChecked', event.target.checked);
        this.updateTitle();
        this.get('workspaceContext').setSelectedProviders();
        this.get('widgetService').globalFilterChanged();
        topActiveContext.clearCheckedItems();
        activeContextNodes.reduce((obj, node) => {
          this.get('workspaceContext').trigger('change', [node.modelName]);
        });
      }

    }
  });

  _exports.default = _default;
});