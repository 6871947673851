define("adept-iq/pods/components/side-drawer/system-config/providers/component", ["exports", "ember-concurrency", "moment", "adept-iq/utils/regex-functions"], function (_exports, _emberConcurrency, _moment, _regexFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NUMBER_FORMAT = /^\d*\.*\d*$/;
  const REMOVE_TEXT_FORMAT = /[^0-9.]+/;
  const daysOfService = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Holidays', null];
  const costLimit = {
    min: 1,
    isFloat: true,
    digitPrecision: 2
  };

  var _default = Ember.Component.extend({
    classNames: ['providers'],
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    workspace: Ember.inject.service(),
    daysOfService,
    dayOfWeeks: null,
    value: null,
    selected: null,
    status: null,
    disableBtns: null,
    minTripCountDate: null,
    maxTripCountDate: null,
    tripCountWeekDays: null,
    zipCodes: null,
    zipCodesSelected: null,
    zoneNames: null,
    zones: null,
    selectedZone: null,
    selectedCostZoneOne: null,
    selectedCostZoneTwo: null,
    newZoneName: null,
    firstZoneName: null,
    secondZoneName: null,
    zoneCost: null,
    cost: null,
    providersList: null,
    isNewProvider: false,
    disableForm: true,
    providerChanges: false,
    inValidEmail: false,
    disableTripCount: true,
    addZoneName: false,
    addCostName: false,
    inValidZone: false,
    inValidCostZone: false,
    isNewZoneCost: true,
    isNewBroker: false,
    mandatoryError: false,
    duplicateError: false,
    disableBrokerForm: true,
    disableZoneRemoveAction: true,
    disableZoneSaveAction: true,
    disableCostRemoveAction: true,
    disableCostSaveAction: true,
    disableCostForm: true,
    configType: null,
    costLimit,
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    showBrokerPanel: Ember.computed('provider.providerTypeName', function () {
      const type = this.get('provider.providerTypeName');
      return type === 'broker';
    }),
    providerFormChanges: Ember.computed('providerChanges', 'provider.{id,name,status,providerTypeName,contactName,contactPhone,contactEmail}', function () {
      const providerChanges = this.get('providerChanges');

      if (providerChanges) {
        return true;
      }

      return false;
    }),
    disableSavebtn: Ember.computed('disableForm', 'provider.{id,name,status,providerTypeName,contactName,contactPhone,contactEmail}', function () {
      const disableForm = this.get('disableForm');

      if (disableForm) {
        return true;
      }

      const hasValues = Object.values(this.get('provider')).some(input => !Ember.isEmpty(input));
      return !hasValues;
    }),
    disableSelectedZone: Ember.computed('disableForm', 'addZoneName', function () {
      const disableForm = this.get('disableForm');
      const addZoneName = this.get('addZoneName');
      if (addZoneName) return true;
      return disableForm;
    }),
    // @TODO: transform into computed property similar to route master
    selectedRowChange: Ember.observer('selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      this.clearPerviousRecordPropertites();
      this.setDefaultProperties(selectedRow);
      this.buttonSettings();

      if (!selectedRow) {
        this.buttonSettings(false, true, true);
      }
    }),

    init() {
      this._super(...arguments);

      this.set('status', ['Active', 'Inactive']);
      this.setupProviderList();
      this.setDefaultProperties();
      this.setupZipCodes();
      this.buttonSettings();
      const today = new Date().toDateString();
      const minDate = (0, _moment.default)(today).add(0, 'days').toDate();
      const maxDate = (0, _moment.default)(today).add(2, 'days').toDate(); // The following line added to initialize the service reference

      this.get('providerChanges');
      this.set('maxTripCountDate', maxDate);
      this.set('minTripCountDate', minDate);
    },

    clearPerviousRecordPropertites() {
      this.setProperties({
        zipCodesSelected: null,
        selectedZone: null,
        modifiedZoneName: null,
        selectedCostZoneOne: null,
        selectedCostZoneTwo: null,
        cost: null
      });
    },

    setDefaultProperties(record = []) {
      const fields = {
        id: '',
        name: '',
        status: '',
        providerTypeName: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        costs: [],
        serviceZones: []
      };
      const tripCountWeekDays = [];

      if (Ember.isPresent(record)) {
        Object.entries(fields).forEach(key => {
          fields[key[0]] = record.get(key[0]);
        });
        const serviceZones = record.get('serviceZones') || [];
        const zipCodes = serviceZones.length ? serviceZones.firstObject.zipCodes : [];
        this.set('zipCodesSelected', zipCodes);

        if (record.get('providerTypeName') === 'broker') {
          const tripCount = record.get('tripCount');
          const costs = record.get('costs') || [];
          this.set('tripCountWeekDays', tripCount);
          this.set('zones', serviceZones);
          this.set('zoneCost', costs);

          if (Ember.isPresent(serviceZones)) {
            this.set('selectedZone', serviceZones.firstObject);
            this.set('modifiedZoneName', serviceZones.firstObject.name);
          }

          if (Ember.isPresent(costs)) {
            const firstServiceZone = serviceZones.firstObject;

            if (Ember.isPresent(firstServiceZone)) {
              const firstGroupCost = costs.find(cost => cost.group.includes(firstServiceZone.name));
              const zones = Ember.isPresent(firstGroupCost) ? firstGroupCost.group.split('-') : null;

              if (zones) {
                const zoneOne = serviceZones.filter(zone => zone.name === zones.firstObject);
                const zoneTwo = serviceZones.filter(zone => zone.name === zones.lastObject);

                if (zoneOne.firstObject || zoneTwo.firstObject) {
                  this.set('selectedCostZoneOne', zoneOne.firstObject);
                  this.set('selectedCostZoneTwo', zoneTwo.firstObject);
                  this.set('cost', firstGroupCost.cost);
                }
              }
            }
          }
        }

        this.set('disableForm', true);
        this.set('providerChanges', false);
      } else {
        this.get('daysOfService').forEach(day => {
          const item = Ember.Object.create({
            day,
            values: {
              targetCount: 0,
              underage: 0,
              overage: 0
            }
          });
          tripCountWeekDays.push(item);
        });
        this.set('tripCountWeekDays', tripCountWeekDays);
        this.set('zones', null);
        this.set('zoneCost', null);
        this.set('providerChanges', false);
        this.set('configType', null);
      }

      this.setProperties({
        'provider': Ember.Object.create(fields),
        'inValidEmail': false,
        'mandatoryError': false,
        'duplicateID': false,
        'duplicateName': false,
        'disableTripCount': true,
        'newZoneName': '',
        'addZoneName': false,
        'addCostName': true,
        'disableZoneAdd': true,
        'disableCostForm': true,
        'disableZoneRemoveAction': true,
        'disableCostRemoveAction': true
      });
    },

    setupZipCodes() {
      const store = this.get('store');
      const configZipCodeList = store.peekAll('zip-code');
      const zipcodes = configZipCodeList.firstObject.get('zipCodes') || [];
      this.set('zipCodes', zipcodes.map(String).sort());
    },

    buttonSettings(newbtn = false, editbtn = false, deletebtn = false) {
      const disableBtns = {
        new: newbtn,
        edit: editbtn,
        delete: deletebtn
      };
      this.set('disableBtns', disableBtns);
    },

    validateEmail() {
      const email = this.get('provider.contactEmail');
      const error = 'Invalid Email';
      if (Ember.isEmpty(email)) return false;

      const isValid = _regexFunctions.REGEX_EMAIL.test(email);

      if (isValid) {
        this.set('inValidEmail', false);
        return false;
      }

      this.set('inValidEmail', true);
      return error;
    },

    validateId() {
      const id = this.get('provider.id');
      const error = 'Provider ID should be text only';
      if (Ember.isEmpty(id)) return false;

      const isValid = _regexFunctions.REGEX_ONLY_TEXT_FORMAT.test(id);

      if (isValid) {
        this.set('inValidId', false);
        return false;
      }

      this.set('inValidId', true);
      return error;
    },

    duplicateCheck() {
      const store = this.get('store');
      const providers = store.peekAll('provider');
      const id = this.get('provider.id').toLowerCase();
      const name = this.get('provider.name').toLowerCase();
      const isNewProvider = this.get('isNewProvider');
      let duplicateIds = [];
      let duplicateNames = [];

      if (isNewProvider) {
        duplicateIds = providers.filter(record => record.get('id').toLowerCase() === id);
        duplicateNames = providers.filter(record => {
          const providerName = record.get('name') || '';
          return providerName.toLowerCase() === name;
        });
      } else {
        duplicateNames = providers.filter(record => {
          const providerName = record.get('name') || '';
          return record.get('id').toLowerCase() !== id && providerName.toLowerCase() === name;
        });
      }

      this.set('duplicateID', duplicateIds.length);
      this.set('duplicateName', duplicateNames.length);

      if (Ember.isEmpty(duplicateIds) && Ember.isEmpty(duplicateNames)) {
        return false;
      }

      let tip = 'Provider ID cannot duplicate an existing provider ID';

      if (!Ember.isEmpty(duplicateIds) && !Ember.isEmpty(duplicateNames)) {
        tip = 'Provider ID and Name cannot duplicate an existing provider ID and Name';
      } else if (Ember.isEmpty(duplicateIds)) {
        tip = 'Provider name cannot duplicate an existing provider name';
      }

      return tip;
    },

    validateServiceZone(newZoneName) {
      let zones = this.get('zones') || []; //remove newZone

      zones = zones.slice(0, -1);
      const availableZone = zones.filter(zone => zone.name === newZoneName) || [];
      if (Ember.isEmpty(availableZone)) return true;
      const existingZipCodes = availableZone.firstObject.zipCodes || [];

      if (!existingZipCodes.length) {
        return true;
      }

      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Providers-Broker Zone',
        hasoverlay: true,
        tip: `${newZoneName} already exist`,
        primaryActionText: 'OK',
        primaryAction: () => {
          this.set('inValidZone', true);
          tooltip.reset();
        }
      });
      return false;
    },

    mandatoryValidation() {
      const name = this.get('provider.name');
      const id = this.get('provider.id');
      const contactPhone = this.get('provider.contactPhone');
      let isValid = !Ember.isEmpty(name) && !Ember.isEmpty(contactPhone);
      const errors = [];
      const providerTypeName = this.get('provider.providerTypeName');
      const providerStatus = this.get('provider.status');
      if (Ember.isEmpty(id) || Ember.isEmpty(name) || Ember.isEmpty(providerTypeName) || Ember.isEmpty(providerStatus)) errors.pushObject({
        'validation': 'Missing Provider Information'
      });
      if (Ember.isEmpty(contactPhone)) errors.pushObject({
        'validation': 'Missing Contact Information'
      });
      const isDuplicate = this.duplicateCheck();
      if (isDuplicate) errors.pushObject({
        'validation': isDuplicate
      });
      const isInValidEmail = this.validateEmail();
      if (isInValidEmail) errors.pushObject({
        'validation': isInValidEmail
      });
      const isInValidId = this.validateId();
      if (isInValidId) errors.pushObject({
        'validation': isInValidId
      });

      if (providerTypeName === 'broker') {
        const newZoneName = this.get('newZoneName');
        const zones = this.get('zones') || [];
        isValid = Ember.isEmpty(zones.filter(zone => Ember.isEmpty(zone.zipCodes)));

        if (!Ember.isEmpty(newZoneName) && isValid) {
          const selectedZipcodes = this.get('zipCodesSelected') || [];
          isValid = !Ember.isEmpty(selectedZipcodes);
        }

        if (!isValid) errors.pushObject({
          'validation': 'zipcode cannot be empty'
        });
        const serviceZones = this.setupPayloadForZone();
        if (Ember.isEmpty(serviceZones)) errors.pushObject({
          'validation': 'Enter at least one zone in order to save'
        });
      }

      const tooltip = this.get('tooltip');

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          errors,
          tip: '',
          primaryActionText: 'OK',
          primaryAction: () => {
            this.set('mandatoryError', true);
            tooltip.reset();
          }
        });
        return false;
      }

      return true;
    },

    fetchZoneCost() {
      const zoneCost = this.get('zoneCost');
      const zone1 = this.get('selectedCostZoneOne') || '';
      const zone2 = this.get('selectedCostZoneTwo') || '';
      this.set('cost', null);
      this.set('isNewZoneCost', true);
      if (Ember.isEmpty(zone1) || Ember.isEmpty(zone2)) return;
      const zoneCombination1 = `${zone1.name}-${zone2.name}`;
      const zoneCombination2 = `${zone2.name}-${zone1.name}`;
      const selectedCost = zoneCost.filter(item => item.group === zoneCombination1 || item.group === zoneCombination2);

      if (!Ember.isEmpty(selectedCost)) {
        const value = selectedCost.firstObject.cost;
        this.set('cost', value);
        this.set('isNewZoneCost', false);
      }
    },

    setupProviderList() {
      const providerTypes = this.get('store').peekAll('provider-type');
      const list = [];
      providerTypes.forEach(item => list.push(item.id));
      this.set('providersList', list);
    },

    setupPayloadForZone() {
      const zones = this.get('zones') || [];
      const selectedZone = this.get('selectedZone.name') || '';
      const name = this.get('newZoneName');
      const modifiedZoneName = this.get('modifiedZoneName');
      const zipCodes = this.get('zipCodesSelected') || [];
      const newZone = {
        name,
        zipCodes
      };

      if (!Ember.isEmpty(selectedZone)) {
        const newZones = [];
        zones.forEach(zone => {
          if (zone.name === selectedZone) {
            newZones.push({
              name: modifiedZoneName,
              zipCodes
            });
          } else {
            newZones.push(zone);
          }
        });
        return newZones;
      } else if (!Ember.isEmpty(name) && !this.isExistingZone(zones, newZone)) {
        zones.push(newZone);
      }

      return zones;
    },

    isExistingZone(zones, newZone) {
      return zones.some(zone => JSON.stringify(zone) === JSON.stringify(newZone));
    },

    setupPayloadForCost() {
      const zoneCost = this.get('zoneCost');
      const zone1 = this.get('selectedCostZoneOne') || '';
      const zone2 = this.get('selectedCostZoneTwo') || '';
      const cost = this.get('cost');
      if (Ember.isEmpty(zone1) || Ember.isEmpty(zone2) || Ember.isEmpty(cost)) return zoneCost;
      const group = `${zone1.name}-${zone2.name}`;
      const group1 = `${zone2.name}-${zone1.name}`;
      let costUpdated = false;
      zoneCost.forEach(item => {
        if (item.group === group || item.group === group1) {
          item.cost = parseFloat(cost);
          costUpdated = true;
          return;
        }
      });

      if (!costUpdated) {
        zoneCost.push({
          group,
          cost
        });
      }

      return zoneCost;
    },

    save: (0, _emberConcurrency.task)(function* () {
      const record = this.buildProviderData();
      return yield record.save();
    }).drop(),

    buildProviderData() {
      let record = null;
      const store = this.get('store');
      const value = this.get('provider');
      const displayName = value.get('name');
      const name = value.get('id');
      const providerType = this.get('provider.providerTypeName');
      const isNewProvider = this.get('isNewProvider');

      if (providerType === 'broker') {
        value.tripCount = this.get('tripCountWeekDays');
        value.costs = this.setupPayloadForCost();
        value.serviceZones = this.setupPayloadForZone();
      }

      if (isNewProvider) {
        const data = {
          category: 'config-System_Configuration-providers',
          type: 'object',
          displayName,
          name,
          value
        };
        record = store.createRecord('cs-config-item', data);
      } else {
        const id = `config-System_Configuration-providers/${name}`;
        record = store.peekRecord('cs-config-item', id);
        record.set('value', value);
      }

      return record;
    },

    delete: (0, _emberConcurrency.task)(function* () {
      const record = this.get('selectedRow');
      const notifications = this.get('notifications');

      try {
        yield record.destroyRecord();
        this.get('tableRef').onUncheckAll();
        this.get('tableRef').refreshData(); //to refresh table data

        notifications.success('Record successfully deleted.');
      } catch (error) {
        console.error(error); // eslint-disable-line

        notifications.warning('Record failed to delete.');
      }
    }).drop(),
    deleteServiceZone: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const selectedRow = this.get('selectedRow');
      const id = `config-System_Configuration-providers/${selectedRow.get('id')}`;
      const record = store.peekRecord('cs-config-item', id);
      const value = this.get('provider');
      const selectedZone = this.get('selectedZone');
      const serviceZones = selectedRow.get('serviceZones');
      value.tripCount = selectedRow.get('tripCount');
      value.serviceZones = serviceZones.filter(zone => zone.name !== selectedZone.name);
      const zoneCost = selectedRow.get('costs');
      value.costs = zoneCost.filter(item => !item.group.includes(selectedZone.name));
      record.set('value', value);
      return yield record.save();
    }).drop(),
    deleteServiceCostZone: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const selectedRow = this.get('selectedRow');
      const id = `config-System_Configuration-providers/${selectedRow.get('id')}`;
      const record = store.peekRecord('cs-config-item', id);
      const value = this.get('provider');
      const zoneCost = selectedRow.get('costs');
      const zone1 = this.get('selectedCostZoneOne') || '';
      const zone2 = this.get('selectedCostZoneTwo') || '';
      const zoneCombination1 = `${zone1.name}-${zone2.name}`;
      const zoneCombination2 = `${zone2.name}-${zone1.name}`;
      value.costs = zoneCost.filter(item => item.group !== zoneCombination1 && item.group !== zoneCombination2);
      record.set('value', value);
      return yield record.save();
    }).drop(),

    async refreshProviderDOM(provider) {
      await provider.reload();
      this.get('tableRef').onUncheckAll();
      this.get('tableRef').refreshData();
      const serviceZones = provider.get('value.serviceZones') || [];
      const costs = provider.get('value.costs') || [];
      Ember.run.next(() => {
        // power select requires all selected items to remove to update collection options
        this.set('zones', serviceZones); // update zone options

        this.set('selectedCostZoneOne', '');
        this.set('selectedCostZoneTwo', '');
        this.set('cost', null);
        this.set('zoneCost', costs);
        this.set('selectedZone', null);
        this.set('modifiedZoneName', '');
        this.set('zipCodesSelected', []);
        this.set('providerChanges', false);
        this.set('configType', null);
      });
    },

    async refreshBrokerData(provider) {
      let serviceZones = provider.get('value.serviceZones') || [];
      const costs = provider.get('value.costs') || [];
      serviceZones = serviceZones.map(({
        name,
        zipCodes
      }) => Ember.Object.create({
        name,
        zipCodes
      }));
      Ember.run.next(() => {
        // power select requires all selected items to remove to update collection options
        this.set('zones', serviceZones); // update zone options

        this.set('selectedCostZoneOne', '');
        this.set('selectedCostZoneTwo', '');
        this.set('cost', null);
        this.set('zoneCost', costs);
        this.set('selectedZone', null);
        this.set('modifiedZoneName', '');
        this.set('zipCodesSelected', []);
      });
    },

    //first, validate the mandatory fields then  validate providerType and validate the service zone of the provider
    async validateProvider() {
      let valid = this.mandatoryValidation();
      const providerType = this.get('provider.providerTypeName');

      if (valid) {
        valid = await this.validateProviderType();
      }

      if (valid) {
        valid = this.validateServiceZone(this.get('newZoneName'));
      }

      if (valid && providerType === 'broker') {
        valid = this.validateCostZone();
      }

      return valid;
    },

    async validateProviderType() {
      const selectedRow = this.get('selectedRow');
      const provider = this.get('provider');
      const store = this.get('store');
      const isNewProvider = this.get('isNewProvider');
      const routes = await store.findAll('route');
      const scheduledRoutes = routes.filter(route => route.get('provider.id') === provider.get('id') && route.get('isScheduled'));
      if (isNewProvider) return true;
      if (Ember.isEmpty(scheduledRoutes)) return true;

      if (selectedRow && provider.get('providerTypeName') === selectedRow.get('providerTypeName')) {
        return true;
      }

      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'System Configuration',
        hasoverlay: true,
        tip: 'Unable to save because a schedule exists that contains routes assigned to the provider',
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        }
      });
      return false;
    },

    onSaveProviders() {
      this.validateProvider().then(isValid => {
        if (isValid) {
          const tooltip = this.get('tooltip');
          const notifications = this.get('notifications');
          const tableRef = this.get('tableRef');
          tooltip.pushConfirmation({
            title: 'System Configuration',
            hasOverlay: true,
            tip: 'Are you sure you want to save these changes?',
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('save').perform().then(provider => {
                tableRef.onUncheckAll();
                notifications.success('Record successfully saved');
                return this.refreshProviderDOM(provider);
              }).catch(() => {
                notifications.warning('Record failed to save');
              }).finally(() => {
                tooltip.reset();
              });
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              return tooltip.reset();
            }

          });
        }
      });
    },

    validateCostZone() {
      const zone1 = this.get('selectedCostZoneOne');
      const zone2 = this.get('selectedCostZoneTwo');
      const cost = this.get('cost');
      const errors = [];
      if (Ember.isEmpty(zone1) && Ember.isEmpty(zone2) && Ember.isEmpty(cost)) errors.pushObject({
        'validation': 'User must enter at least one cost in order to save'
      });else {
        if (Ember.isEmpty(zone1) || Ember.isEmpty(zone2)) errors.pushObject({
          'validation': 'Zone names must be selected'
        });
        if (Ember.isEmpty(cost)) errors.pushObject({
          'validation': 'Cost is required'
        });
      }
      const tooltip = this.get('tooltip');

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          errors,
          tip: '',
          primaryActionText: 'OK',
          primaryAction: () => {
            this.set('mandatoryError', true);
            tooltip.reset();
          }
        });
        return false;
      }

      return true;
    },

    actions: {
      newProviders() {
        this.setDefaultProperties();
        this.setProperties({
          isNewProvider: true,
          disableForm: false,
          providerChanges: false,
          addZoneName: false,
          addCostName: false,
          disableZoneRemoveAction: true,
          disableZoneSaveAction: true,
          disableCostRemoveAction: true,
          disableCostSaveAction: true,
          disableCostForm: true,
          zones: null,
          selectedCostZoneOne: null,
          selectedCostZoneTwo: null,
          configType: null
        });
        this.get('tableRef').onUncheckAll();
        this.send('onAddServiceZone');
        this.send('onAddCostZone');
        this.buttonSettings(true, true, true);
        this.set('disableZoneAdd', false);
        this.setupZipCodes();
      },

      editProviders() {
        const selectedRow = this.get('selectedRow');
        this.setDefaultProperties(selectedRow);
        this.setProperties({
          isNewProvider: false,
          disableForm: false,
          providerChanges: false,
          addZoneName: false,
          addCostName: false,
          disableCostForm: false,
          disableCostRemoveAction: false,
          disableCostSaveAction: true,
          disableZoneAdd: false,
          disableZoneRemoveAction: false,
          disableZoneSaveAction: true,
          configType: null
        });
        this.buttonSettings(true, true, true);
      },

      deleteProviders() {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          tip: 'Are you sure you want to Delete these changes?',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('delete').perform().then(() => {
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',

          secondaryAction() {
            return tooltip.reset();
          }

        });
      },

      async saveProviders() {
        this.onSaveProviders();
      },

      onStatusChange(val) {
        this.set('provider.status', val);
        this.set('providerChanges', true);
      },

      onProviderTypeChange(val) {
        this.set('provider.providerTypeName', val);
        this.set('providerChanges', true);

        if (val === 'broker') {
          const serviceZones = this.get('zones') || [];
          if (Ember.isEmpty(serviceZones)) this.setProperties({
            zipCodesSelected: null,
            selectedZone: null,
            addZoneName: true,
            disableBrokerForm: true,
            disableCostForm: true
          });
        }
      },

      onUpdateTripCountDate(day) {
        const tripDays = this.get('tripCountWeekDays');
        this.set('tripCountWeekDays', []);
        tripDays.pop();
        tripDays.push({
          day,
          values: {
            targetCount: 0,
            underage: 0,
            overage: 0
          }
        });
        this.set('tripCountWeekDays', tripDays);
        this.set('disableTripCount', false);
      },

      onAddServiceZone() {
        this.setProperties({
          zipCodesSelected: null,
          selectedZone: null,
          addZoneName: true,
          disableZoneAdd: true,
          disableZoneRemoveAction: false,
          disableZoneSaveAction: false,
          disableBrokerForm: false
        });
      },

      onRemoveServiceZone() {
        const tooltip = this.get('tooltip');
        const selectedZone = this.get('selectedZone');
        const notifications = this.get('notifications');
        const addZoneName = this.get('addZoneName');

        if (!selectedZone && addZoneName) {
          this.set('newZoneName', '');
          this.set('zipCodesSelected', '');
        } else {
          tooltip.pushConfirmation({
            title: 'Providers-Broker Zone',
            hasoverlay: true,
            tip: `Do you want to delete ${selectedZone.name}?`,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('deleteServiceZone').perform().then(provider => {
                provider.reload();
                this.refreshBrokerData(provider);
                notifications.success(`${selectedZone.name} deleted successfully`);
                this.set('providerChanges', true);
                this.set('disableZoneSaveAction', true);
                this.set('disableZoneAdd', false);
                this.set('disableZoneRemoveAction', false);
              }).catch(() => {
                notifications.warning(`Failed to delete ${selectedZone.name}`);
              }).finally(() => {
                tooltip.reset();
              });
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              return tooltip.reset();
            }

          });
        }
      },

      onSaveServiceZone() {
        let isValid = this.mandatoryValidation();

        if (isValid) {
          const tooltip = this.get('tooltip');
          const addZoneName = this.get('addZoneName');
          let zoneName = '';

          if (addZoneName) {
            zoneName = this.get('newZoneName');
          } else {
            zoneName = this.get('selectedZone.name') !== this.get('modifiedZoneName') ? this.get('modifiedZoneName') : '';
          }

          const isNewProvider = this.get('isNewProvider');
          let tip = 'Are you sure you want to save these changes?';
          const notifications = this.get('notifications');

          if (!Ember.isEmpty(zoneName) && isValid) {
            isValid = this.validateServiceZone(zoneName);

            if (!isNewProvider) {
              tip = `Do you want to save ${zoneName}?`;
            }
          }

          if (isValid) {
            tooltip.pushConfirmation({
              title: 'Providers-Broker Zone',
              hasoverlay: true,
              tip,
              primaryActionText: 'Confirm',
              primaryAction: () => {
                this.set('addZoneName', false);
                const provider = this.buildProviderData();

                if (provider) {
                  const zonesExist = provider.get('value').serviceZones;
                  this.set('newZoneName', '');
                  this.set('zipCodesSelected', []);

                  if (zonesExist && zonesExist.length >= 1) {
                    this.setProperties({
                      'disableZoneAdd': false,
                      'disableZoneRemoveAction': false,
                      'disableZoneSaveAction': true,
                      'addCostName': false,
                      'disableCostRemoveAction': true,
                      'disableCostSaveAction': true
                    });
                  } else {
                    this.set('disableZoneAdd', false);
                    this.set('disableZoneRemoveAction', true);
                    this.set('disableZoneSaveAction', true);
                  }

                  this.set('disableBrokerForm', true);
                  this.set('providerChanges', true);
                  this.refreshBrokerData(provider);
                  notifications.success(`${zoneName} saved successfully`);
                }

                tooltip.reset();
              },
              secondaryActionText: 'Cancel',

              secondaryAction() {
                return tooltip.reset();
              }

            });
          }
        }
      },

      removeSelectedZipCode(zipCode) {
        const selectedZipcodes = this.get('zipCodesSelected') || [];
        selectedZipcodes.removeObject(zipCode);
        this.set('zipCodesSelected', selectedZipcodes);
        this.set('disableZoneSaveAction', false);
        this.set('providerChanges', true);
      },

      onZoneChange(selectedZone) {
        const selectedZipCodes = selectedZone.zipCodes || [];
        this.set('selectedZone', selectedZone);
        this.set('modifiedZoneName', selectedZone.name);
        this.set('zipCodesSelected', selectedZipCodes);
      },

      onZipCodeChange(selectedZipCode) {
        if (!selectedZipCode.lastObject) {
          return;
        }

        const serviceZones = this.get('zones') || [];
        const zipCode = parseInt(selectedZipCode.lastObject, 10);
        const assignedZipCodes = serviceZones.filter(zone => zone.zipCodes.includes(zipCode));

        if (!Ember.isEmpty(assignedZipCodes)) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'Providers-Broker Zone',
            hasoverlay: true,
            tip: `${zipCode} already assigned to ${assignedZipCodes.firstObject.name}`,
            primaryActionText: 'OK',
            primaryAction: () => {
              tooltip.reset();
            }
          });
        } else {
          const zipCodesSelected = this.get('zipCodesSelected') || [];

          if (!zipCodesSelected.includes(zipCode)) {
            this.set('zipCodesSelected', null);
            zipCodesSelected.push(zipCode);
          }

          this.set('zipCodesSelected', zipCodesSelected);
          this.set('disableZoneSaveAction', false);
          this.set('providerChanges', true);
        }
      },

      onZoneOneChange(selectedZone) {
        this.set('selectedCostZoneOne', selectedZone);
        this.fetchZoneCost();
        this.set('providerChanges', true);
        this.set('disableCostSaveAction', false);
      },

      onZoneTwoChange(selectedZone) {
        this.set('selectedCostZoneTwo', selectedZone);
        this.fetchZoneCost();
        this.set('providerChanges', true);
        this.set('disableCostSaveAction', false);
      },

      onZoneCostChange(path, value) {
        this.set(path, value);
        const zone1 = this.get('selectedCostZoneOne');
        const zone2 = this.get('selectedCostZoneTwo');

        if (zone1 && zone2) {
          this.set('providerChanges', true);
          this.set('disableCostSaveAction', false);
        }
      },

      findZipCode(newZoneName) {
        let zoneName = newZoneName.value; // Automatic removal of characters other than numbers

        if (!NUMBER_FORMAT.test(zoneName)) {
          zoneName = zoneName.replace(REMOVE_TEXT_FORMAT, '');
          this.set('newZoneName', zoneName ? parseInt(zoneName, 10) : '');
          return this.notifyPropertyChange('newZoneName');
        } // Replacing '0' and undefined value


        if (!zoneName || zoneName === '0') {
          this.set('newZoneName', zoneName = '');
          return this.notifyPropertyChange('newZoneName');
        }

        this.set('newZoneName', parseInt(zoneName, 10));
        this.set('inValidZone', false);
        this.set('zipCodesSelected', []);
      },

      onRemoveCostZone() {
        const tooltip = this.get('tooltip');
        const zone1 = this.get('selectedCostZoneOne');
        const zone2 = this.get('selectedCostZoneTwo');
        const notifications = this.get('notifications');
        const addCostName = this.get('addCostName');

        if (addCostName) {
          this.set('selectedCostZoneOne', '');
          this.set('selectedCostZoneTwo', '');
          this.set('cost', '');
        } else {
          tooltip.pushConfirmation({
            title: 'Providers-Cost',
            hasoverlay: true,
            tip: `Do you want to delete cost for ${zone1.name} to ${zone2.name}?`,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('deleteServiceCostZone').perform().then(provider => {
                this.refreshBrokerData(provider);
                notifications.success(`Cost for ${zone1.name} to ${zone2.name} deleted successfully`);
              }).catch(() => {
                notifications.warning(`Failed to delete cost for ${zone1.name} to ${zone2.name}`);
              }).finally(() => {
                tooltip.reset();
              });
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              return tooltip.reset();
            }

          });
        }
      },

      onAddCostZone() {
        this.setProperties({
          selectedCostZoneOne: null,
          selectedCostZoneTwo: null,
          cost: null,
          addCostName: true,
          disableCostRemoveAction: false,
          disableCostSaveAction: false,
          disableCostForm: false
        });
      },

      onSaveCostZone() {
        let isValid = this.mandatoryValidation();

        if (isValid) {
          isValid = this.validateCostZone();

          if (isValid) {
            const tooltip = this.get('tooltip');
            const zone1 = this.get('selectedCostZoneOne');
            const zone2 = this.get('selectedCostZoneTwo');
            const tip = `Do you want to modify cost for ${zone1.name} to ${zone2.name}?`;
            const notifications = this.get('notifications');
            tooltip.pushConfirmation({
              title: 'Providers-Cost',
              hasoverlay: true,
              tip,
              primaryActionText: 'Confirm',
              primaryAction: () => {
                const provider = this.buildProviderData();

                if (provider) {
                  if (zone1 || zone2) {
                    this.set('addCostName', false);
                    this.set('disableCostRemoveAction', false);
                    this.set('disableCostSaveAction', true);
                    this.set('disableCostForm', false);
                  } else {
                    this.set('addCostName', false);
                    this.set('disableCostRemoveAction', true);
                    this.set('disableCostSaveAction', true);
                    this.set('disableCostForm', true);
                  }

                  notifications.success(`Cost for ${zone1.name} to ${zone2.name} saved successfully`);
                  tooltip.reset();
                }
              },
              secondaryActionText: 'Cancel',

              secondaryAction() {
                return tooltip.reset();
              }

            });
          }
        }
      },

      onTripCountChange(record, valuePath, val) {
        const value = parseInt(val, 10) || 0;
        Ember.set(record, valuePath, value);
        this.set('providerChanges', true);
      },

      onProviderFormChanges() {
        this.set('providerChanges', true);
      }

    }
  });

  _exports.default = _default;
});