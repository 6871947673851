define("adept-iq/pods/components/side-drawer/system-config/route-master/component", ["exports", "ember-changeset-cp-validations", "ember-changeset", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/side-drawer/system-config/route-master/vehicle-search-config", "lodash", "adept-iq/models/route-template", "adept-iq/models/zone", "moment"], function (_exports, _emberChangesetCpValidations, _emberChangeset, _emberConcurrency, _unwrapProxy, _vehicleSearchConfig, _lodash, _routeTemplate, _zone, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_DATE = (0, _moment.default)().add(1000, 'years');
  const MAX_TRIP_LIMIT = 9;
  const MAX_DISTANCE_LIMIT = 99;
  const daysOfService = {
    '0': 'monday',
    '1': 'tuesday',
    '2': 'wednesday',
    '3': 'thursday',
    '4': 'friday',
    '5': 'saturday',
    '6': 'sunday',
    '7': 'holiday'
  };
  const daysOfWeek = [{
    day: 'monday',
    display: 'Mon',
    disabled: false
  }, {
    day: 'tuesday',
    display: 'Tues',
    disabled: false
  }, {
    day: 'wednesday',
    display: 'Wed',
    disabled: false
  }, {
    day: 'thursday',
    display: 'Thurs',
    disabled: false
  }, {
    day: 'friday',
    display: 'Fri',
    disabled: false
  }, {
    day: 'saturday',
    display: 'Sat',
    disabled: false
  }, {
    day: 'sunday',
    display: 'Sun',
    disabled: false
  }, {
    day: 'holiday',
    display: 'Holiday',
    disabled: false
  }];
  const extraTripLimitOptions = {
    min: 1,
    max: 1,
    isFloat: true
  };
  const extraDistanceLimitOptions = {
    min: 1,
    max: 2,
    isFloat: true
  };

  var _default = Ember.Component.extend({
    classNames: ['route-master-view'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    daysOfService,
    daysOfWeek,
    tableRef: null,
    zoneTableRef: null,
    isDriverBreakModalEnabled: false,
    driverBreakAvailability: null,
    disableEditing: true,
    disableBtns: null,
    showValidations: false,
    breakTypes: null,
    zones: null,
    selectedRouteTemplateAvailabilities: null,
    extraDistanceLimitOptions,
    extraTripLimitOptions,
    routeTemplate: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    error: Ember.computed.readOnly('routeTemplateChangeSet.error'),
    disableUndobtn: Ember.computed.readOnly('disableSaveBtn'),
    hasZoneSelected: Ember.computed.readOnly('zoneTableRef.hasZoneSelected'),
    shouldDisplayValidations: Ember.computed.or('showValidations').readOnly(),
    hasRouteTemplateChanged: Ember.computed('routeTemplateChangeSet.{isDirty,changes}', 'sortedAvailabilities.@each.{isDirty,changes}', 'routeTemplateAvailabilities.[]', function () {
      const routeTemplateChangeSet = this.get('routeTemplateChangeSet');
      const oldAvailabilitiesLength = routeTemplateChangeSet.get('availabilities').length;
      const avalabilityLengthChanged = oldAvailabilitiesLength !== this.get('routeTemplateAvailabilities').length;
      if (!routeTemplateChangeSet) return false;
      const routeTemplateChanged = routeTemplateChangeSet.get('isDirty') || routeTemplateChangeSet.get('changes').length;
      const availabilitiesChanged = this.get('routeTemplateAvailabilities').some(availability => {
        return availability.get('isDirty') || availability.get('changes.length');
      });
      const zeroShiftLengthAvailabilities = this.get('routeTemplateAvailabilities').filter(availability => {
        if (!availability.get('routeLength')) return 1;
        const avail = availability.get('routeLength').replace(/_/img, '0');
        const shiftLengthDuration = avail.split(':');
        const hours = shiftLengthDuration[0];
        const minutes = shiftLengthDuration.length > 1 ? shiftLengthDuration[1] : 0;
        return parseInt(minutes, 10) === 0 && parseInt(hours, 10) === 0 || avail === '';
      });
      const isShiftLengthZero = zeroShiftLengthAvailabilities.length ? 0 : 1;

      if (!this.get('routeTemplateAvailabilities').length) {
        return false;
      }

      return (routeTemplateChanged || availabilitiesChanged || avalabilityLengthChanged) && isShiftLengthZero;
    }),
    disableSaveBtn: Ember.computed('disableEditing', 'hasRouteTemplateChanged', function () {
      const disableEditing = this.get('disableEditing');
      const hasRouteTemplateChanged = this.get('hasRouteTemplateChanged');
      return disableEditing || !hasRouteTemplateChanged;
    }),
    routeTemplateChangeSet: Ember.computed('routeTemplate', 'selectedRow.selected', function () {
      const routeTemplate = (0, _unwrapProxy.unwrapProxy)(this.get('routeTemplate'));
      return this.setDefaultProperties(routeTemplate);
    }),
    routeTemplateAvailabilities: Ember.computed('sortedAvailabilities.@each.isDeleted', function () {
      const sortedAvailabilities = this.get('sortedAvailabilities') || [];
      return sortedAvailabilities.filter(avail => !avail.get('isDeleted')).sortBy('DOWSelected.length').reverse();
    }),

    async init() {
      this._super(...arguments);

      const store = this.get('store');
      const breakTypes = store.peekAll('break-type');
      const zones = store.peekAll('zone');
      this.set('breakTypes', breakTypes);
      this.set('availableZones', zones);
      this.set('affinityTypes', [_zone.AFFINITY_PREFERRED, _zone.AFFINITY_NORMAL, _zone.AFFINITY_LAST_RESORT, _zone.AFFINITY_NO_SERVICE]);
      this.refreshModelsTask.perform();
    },

    didInsertElement() {
      this._super(...arguments);

      const routeMasterSaveFunction = this.get('onSaveRouteMaster').bind(this);
      const routeMasterEditFunction = this.get('enableEditRouteMaster').bind(this);
      this.set('routeMasterEditFunction', routeMasterEditFunction);
      this.set('routeMasterSaveFunction', routeMasterSaveFunction);
    },

    async willDestroy() {
      const store = this.get('store');
      const routeTemplates = store.peekAll('route-template');
      await routeTemplates.reduce((acu, template) => {
        return acu.then(() => {
          if (template.get('isNew')) {
            template.rollbackAttributes(); // delete any created records
          }
        });
      }, Promise.resolve());

      this._super(...arguments);
    },

    setDefaultProperties(record = null
    /* disableForms = true */
    ) {
      const store = this.get('store');
      let pendingRecord = this.get('pendingRecord');
      this.set('selectedAffinityType', null);

      if (record) {
        pendingRecord = record;
      } else if (!pendingRecord) {
        pendingRecord = store.createRecord('route-template');
        this.set('pendingRecord', pendingRecord);
      }

      if (pendingRecord.get('isNew')) {
        pendingRecord.setProperties({
          name: '',
          tripLimit: '',
          distanceLimit: '',
          shiftBreaks: [],
          vehicle: null
        });
      }

      if (record) {
        const disableBtns = {
          new: false,
          edit: false,
          delete: false
        };
        Ember.run.scheduleOnce('afterRender', this, () => this.set('disableBtns', disableBtns));
        this.set('disableEditing', true);
      }

      const zoneTableRef = this.get('zoneTableRef');
      const {
        validateFn,
        validationMap
      } = (0, _emberChangesetCpValidations.buildChangeset)(pendingRecord);
      if (zoneTableRef) Ember.run.scheduleOnce('afterRender', zoneTableRef, 'refreshData');
      this.set('showValidations', false);

      if (pendingRecord) {
        const availabilities = pendingRecord.get('availabilities') || [];
        this.configureRouteTemplateAvailabilities(availabilities);
      }

      return new _emberChangeset.default(pendingRecord, validateFn, validationMap, {
        skipValidate: true
      });
    },

    configureOptimizationFlags(checked, bitFlag) {
      const routeTemplate = this.get('routeTemplateChangeSet');
      const optimizationFlags = routeTemplate.get('optimizationFlags');

      if (checked) {
        routeTemplate.set('optimizationFlags', optimizationFlags | bitFlag); // turn bit on
      } else {
        routeTemplate.set('optimizationFlags', optimizationFlags & ~bitFlag); // turn bit off
      }
    },

    configureRouteTemplateAvailabilities(availabilities) {
      availabilities.forEach(availability => {
        this.resetRouteTemplateAvailability(availability);
      });
      const changesets = availabilities.map(availability => {
        const {
          validateFn,
          validationMap
        } = (0, _emberChangesetCpValidations.buildChangeset)(availability);
        return new _emberChangeset.default(availability, validateFn, validationMap);
      });
      this.set('sortedAvailabilities', changesets);
    },

    resetRouteTemplateAvailability(availability) {
      const DOWSelected = [];
      const dayOptions = this.get('daysOfWeek');
      dayOptions.forEach(option => {
        if (availability.get(option.day)) {
          Ember.set(option, 'disabled', true);
          DOWSelected.pushObject(option);
        } else {
          Ember.set(option, 'disabled', false);
        }
      });
      availability.setProperties({
        DOWSelected
      });
    },

    deleteRecords(records) {
      return records.reduce((acu, record) => {
        return acu.then(() => {
          if (record.get('isNew')) return record.rollbackAttributes();
          return record.destroyRecord();
        });
      }, Promise.resolve());
    },

    async savePlaceRecord(place) {
      if (place) {
        const zoneType = (0, _unwrapProxy.unwrapProxy)(this.get('store').peekAll('zone-type').get('firstObject'));
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));

        if (address) {
          // TODO: We need to deal with how zone name is populated
          if (Ember.isNone(address.get('zoneName.id'))) {
            address.set('zoneName', zoneType);
          }

          await address.save();
        }

        if (location) await location.save();
        return place.save();
      }
    },

    rollbackPlaceRecord(place) {
      if (place) {
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        if (address) address.rollbackAttributes();
        if (location) location.rollbackAttributes();
        place.rollbackAttributes();
      }
    },

    saveShiftBreakRecords(records) {
      return records.reduce((acu, record) => {
        return acu.then(async () => {
          const place = (0, _unwrapProxy.unwrapProxy)(record.get('place'));

          if (!record.get('isFloatingBreak')) {
            await this.savePlaceRecord(place);
          } else {
            this.rollbackPlaceRecord(place);
          }

          return record.save();
        });
      }, Promise.resolve());
    },

    async deletePlaceRecord(place) {
      if (place && !place.get('isNew')) {
        const address = place.get('address');
        const location = place.get('location');
        if (address) await address.destroyRecord();
        if (location) await location.get('location').destroyRecord();
        return place.destroyRecord();
      }

      return this.rollbackPlaceRecord(place);
    },

    deleteShiftBreakRecords(records) {
      return records.reduce((acu, record) => {
        return acu.then(async () => {
          await this.deletePlaceRecord((0, _unwrapProxy.unwrapProxy)(record.get('place')));
          if (record.get('isNew')) return record.rollbackAttributes();
          return record.destroyRecord();
        });
      }, Promise.resolve());
    },

    onRouteMasterSaveTask: (0, _emberConcurrency.task)(function* () {
      const routeTemplate = this.get('routeTemplateChangeSet');
      const availabilities = this.get('routeTemplateAvailabilities');
      const deletedAvailabilities = this.get('sortedAvailabilities').filterBy('isDeleted');
      const notifications = this.get('notifications');
      this.validateTripAndDistanceLimit(routeTemplate);
      availabilities.forEach(avail => {
        const shiftStart = avail.get('shiftStart');
        const calcEndDate = avail.get('calcEndDate');
        avail.setProperties({
          startTime: shiftStart,
          shiftEnd: calcEndDate,
          endTime: MAX_DATE.toDate()
        });
        if (avail.get('isNew')) avail.set('routeTemplate', routeTemplate.data);
      });

      try {
        // save route template record
        yield routeTemplate.save(); // save all shift breaks per availability
        // including all place, address, and location records

        yield availabilities.reduce((acu, avail) => {
          const shiftBreaks = avail.get('shiftBreaks') || [];
          const deletedShiftBreaks = avail.get('deletedShiftBreaks') || [];
          shiftBreaks.pushObjects(deletedShiftBreaks);
          return avail.save().then(() => this.saveShiftBreakRecords(shiftBreaks));
        }, Promise.resolve());
        yield deletedAvailabilities.reduce((acu, avail) => {
          const shiftBreaks = avail.get('shiftBreaks') || [];
          const deletedShiftBreaks = avail.get('deletedShiftBreaks') || [];
          shiftBreaks.pushObjects(deletedShiftBreaks);
          return acu.then(() => avail.data.destroyRecord());
        }, Promise.resolve());
        this.get('sortedAvailabilities').removeObject(...deletedAvailabilities);
        notifications.success(`Successfully Saved Route ${routeTemplate.get('name')}`);
        this.set('pendingRecord', null); // newly record has been saved or been edited

        this.resetForm();
        this.refreshModelsTask.perform();
      } catch (e) {
        console.warn(e); //eslint-disable-line

        notifications.warning(`Failed to Save Route ${routeTemplate.get('name')}`);
      }
    }),

    resetForm() {
      const disableBtns = {
        new: false,
        edit: false,
        delete: false
      };
      Ember.run.scheduleOnce('afterRender', this, () => this.set('disableBtns', disableBtns));
      this.set('disableEditing', true);
    },

    onDeleteTask: (0, _emberConcurrency.task)(function* () {
      const routeTemplate = this.get('routeTemplate'); // selected record to delete

      const availabilities = this.get('routeTemplate.availabilities');
      const notifications = this.get('notifications');

      try {
        // delete all shift breaks per availability
        // including all place, address, and location records
        yield availabilities.reduce((acu, avail) => {
          const shiftBreaks = avail.get('shiftBreaks') || [];
          const deletedShiftBreaks = avail.get('deletedShiftBreaks') || [];
          shiftBreaks.pushObjects(deletedShiftBreaks);
          return acu.then(() => this.deleteShiftBreakRecords(shiftBreaks));
        }, Promise.resolve()); // delete all availabilities

        yield this.deleteRecords(availabilities); // delete route template record

        yield routeTemplate.destroyRecord();
        notifications.success('Route Master record successfully deleted');
      } catch (e) {
        console.warn(e); //eslint-disable-line

        notifications.warn(`Failed to Delete Route ${routeTemplate.get('name')}`);
      }

      this.set('pendingRecord', null); // newly record has been saved or been edited

      this.refreshModelsTask.perform();
    }),
    onRouteMasterDeleteTask: (0, _emberConcurrency.task)(function* () {
      const routes = this.get('routeTemplate.routes');
      const tooltip = this.get('tooltip');
      const onDeleteTask = this.get('onDeleteTask');
      const sumTrips = routes.reduce((acu, route) => {
        return route.get('trips.length') + acu;
      }, 0);

      if (routes) {
        yield tooltip.pushConfirmation({
          title: 'System Configuration',
          tip: `There are ${routes.length} future route(s) and ${sumTrips} future trip(s) associated with this route. Are you sure you want to delete this Route Master record?`,
          warning: true,
          secondaryActionText: 'Cancel',

          secondaryAction() {
            return tooltip.reset();
          },

          primaryActionText: 'Confirm',

          primaryAction() {
            onDeleteTask.perform().then(() => {
              tooltip.reset();
            });
          }

        });
        return;
      }
    }),
    refreshModelsTask: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      const routeTemplate = this.get('routeTemplate');
      if (routeTemplate && routeTemplate.isDeleted) Ember.run.scheduleOnce('afterRender', tableRef, 'onUncheckAll'); // empty tableRef checkedrows

      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData'); // refresh data

      this.get('store').findAll('route-template-availability'); // find a better place for api

      this.get('store').findAll('shift-break'); // find a better place for api

      yield;
    }).drop(),

    async validateRecord() {
      const routeTemplateChangeSet = this.get('routeTemplateChangeSet');
      const sortedAvailabilities = this.get('routeTemplateAvailabilities');
      const tooltip = this.get('tooltip');
      let errors = [];
      let validate = false;
      validate = await routeTemplateChangeSet.validate();
      errors = routeTemplateChangeSet.errors;
      await sortedAvailabilities.reduce((acu, availability) => {
        return acu.then(async () => {
          //Rolls back all invalid unsaved changes and resets all errors. Valid changes will be kept on the changeset.
          availability.rollbackInvalid(); // to clear previous error
          // validate  saved record

          if (!availability.get('isNew')) {
            await availability.validate();
            return;
          } // validate new record if its selected


          if (availability.get('isNew')) {
            await availability.validate();

            if (!(0, _unwrapProxy.unwrapProxy)(availability.get('vehicle'))) {
              availability.addError('vehicle', {
                value: null,
                validation: 'Vehicle cannot be blank'
              });
            }
          }
        });
      }, Promise.resolve());
      sortedAvailabilities.forEach(availability => {
        if (availability.errors.length > 0) {
          errors.pushObjects(availability.errors);
        }
      });
      errors = (0, _lodash.uniqBy)(errors, error => error.key);
      validate = sortedAvailabilities.every(availability => availability.isValid);

      if (!validate || !routeTemplateChangeSet.isValid) {
        tooltip.pushConfirmation({
          title: 'System Configuration',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
      }

      return routeTemplateChangeSet.isValid && validate;
    },

    async onVehicleChange(record, vehicle, path = 'vehicle') {
      record.set(path, vehicle);
      record.execute();

      if (!record.get('vehicle.provider.isBrokerProviderType')) {
        record.set('routeLimit', 0);
      }
    },

    async onDateChange(record, path, dateStr) {
      record.set(path, dateStr);
      const calcEndDate = this.get('calcEndDate')(record, record.get('routeLength'));
      record.set('calcEndDate', calcEndDate);
      record.execute(); // changeset to execute underlying changes to update computed route length property
    },

    async onSaveRouteMaster() {
      const valid = await this.validateRecord();

      if (valid) {
        this.set('showValidations', false);
        this.onRouteMasterSaveTask.perform();
        return true;
      }

      this.set('showValidations', true);
      return false;
    },

    validateTripAndDistanceLimit(routeTemplate) {
      // default values
      if (Ember.isEmpty(routeTemplate.get('tripLimit'))) {
        routeTemplate.set('tripLimit', MAX_TRIP_LIMIT);
      }

      if (Ember.isEmpty(routeTemplate.get('distanceLimit'))) {
        routeTemplate.set('distanceLimit', MAX_DISTANCE_LIMIT);
      }
    },

    enableEditRouteMaster() {
      const routeTemplate = this.get('routeTemplate');
      if (!routeTemplate) return;
      const disableBtns = {
        new: true,
        edit: true,
        delete: true
      };
      Ember.run.scheduleOnce('afterRender', this, () => {
        this.set('disableBtns', disableBtns);
        this.set('disableEditing', false);
      });
    },

    calcEndDate(availability, routeLength) {
      const start = availability.get('shiftStart');
      if (!start || !routeLength) return null;
      const clone = (0, _moment.default)(start);
      const duration = routeLength.split(':');
      const hours = duration[0];
      const minutes = duration.length > 1 ? duration[1] : 0;
      clone.add(hours, 'hours');
      clone.add(minutes, 'minutes');
      return clone.toDate();
    },

    actions: {
      newRouteMaster() {
        const selectedRow = this.get('selectedRow');
        if (selectedRow) selectedRow.set('selected', false);
        const disableBtns = {
          new: true,
          edit: true,
          delete: true
        };
        const dayOptions = this.get('daysOfWeek');
        dayOptions.forEach(option => {
          Ember.set(option, 'disabled', false);
        });
        this.set('disableBtns', disableBtns);
        this.set('disableEditing', false);
      },

      editRouteMaster() {
        this.enableEditRouteMaster();
        const routeTemplate = this.get('routeTemplate');
        if (!routeTemplate) return;
        const disableBtns = {
          new: false,
          edit: true,
          delete: true
        };
        this.set('disableBtns', disableBtns);
        this.set('disableEditing', false);
      },

      undoRouteMaster() {
        const routeTemplateChangeSet = this.get('routeTemplateChangeSet');
        const tableRef = this.get('tableRef'); // On rollback, all changes are dropped and the underlying Object is left untouched for all changeset objects
        // ember data related records will be rolled back and if its new deleted

        routeTemplateChangeSet.rollback();
        const availabilities = routeTemplateChangeSet.get('availabilities') || [];
        availabilities.forEach(availability => {
          const shBrks = availability.get('shiftBreaks');
          const previousSelectedDOW = availability.get('DOWSelected');
          shBrks.forEach(shBrk => {
            if (shBrk.get('isNew')) return shBrk.destroyRecord();
            return shBrk.rollbackAttributes();
          });
          availability.rollbackAttributes();
          availability.set('DOWSelected', previousSelectedDOW);
        });
        this.configureRouteTemplateAvailabilities(availabilities);
        if (tableRef.get('isSearchEnabled')) this.resetForm();
      },

      async saveRouteMaster() {
        this.onSaveRouteMaster();
      },

      deleteRouteMaster() {
        this.onRouteMasterDeleteTask.perform();
      },

      openSearchModal(availability) {
        const onVehicleChange = this.get('onVehicleChange').bind(this);
        const searchVehicleObj = Ember.Object.create({
          title: 'Find Vehicles',
          hasOverlay: true,
          config: _vehicleSearchConfig.default,
          primaryActionText: 'Select',
          className: 'vehicle-search',
          primaryAction: record => {
            onVehicleChange(availability, record);
          },
          queryParams: {
            useVehicleService: true
          }
        });
        this.set('modalAPI', searchVehicleObj);
      },

      openBreakModal(availability, day) {
        availability.set('day', day);
        this.set('isDriverBreakModalEnabled', true);
        this.set('driverBreakAvailability', availability);
      },

      onDateChange(record, path, value) {
        this.onDateChange(record, path, value);
      },

      onTextChange(record, path, value) {
        record.set(path, value);
      },

      onRouteLengthChange: async function (availability, maskObj) {
        const routeLength = maskObj.masked;
        const oldVal = availability.get('routeLength'); // This method gets called multiple times (even while hitting a ENTER key). So If oldValue is equal to newValue then no need to validate

        if (routeLength === oldVal) {
          return;
        }

        let value = maskObj.masked.replace(/_/img, '0');
        if (!value) value = '00:00';
        availability.set('routeLength', routeLength);
        const calcEndDate = this.get('calcEndDate')(availability, value);
        availability.set('calcEndDate', calcEndDate);
      },

      onAffinitySelect(option) {
        const availableZones = this.get('availableZones');
        const pendingRecord = this.get('routeTemplateChangeSet');
        const zones = this.get('zoneTableRef.checkedRows');
        const zoneIds = availableZones.map(zone => parseInt(zone.get('id'), 10));
        const affinities = pendingRecord.get('affinities') || [];
        const maxZoneId = Math.max(...zoneIds, affinities.length);
        const newSize = Math.max(maxZoneId, affinities.length);
        const newAffinities = Array(newSize).fill(0);
        this.set('selectedAffinityType', option);
        zones.setEach('affinityValue', option.val); // configured affinities to contain all imaginable zone changes

        affinities.forEach((aff, index) => {
          newAffinities[index] = aff;
        });
        zones.forEach(zone => {
          const zoneId = parseInt(zone.get('id'), 10);
          newAffinities[zoneId] = option.val;
        });
        pendingRecord.set('affinities', newAffinities);
      },

      onAdjustDriverBreakCheckBoxClick(event) {
        const checked = event.target.checked;
        this.configureOptimizationFlags(checked, _routeTemplate.ADJUST_BREAK_FLAG);
      },

      onConsolidateCheckBoxClick(event) {
        const checked = event.target.checked;
        this.configureOptimizationFlags(checked, _routeTemplate.CONSOLIDATE_ROUTE_FLAG);
      },

      onSchedulingModeClick(event) {
        const checked = event.target.checked;
        const routeTemplate = this.get('routeTemplateChangeSet');

        if (checked) {
          routeTemplate.set('schedulingMode', _routeTemplate.MANUAL_SCHEDULING_MODE);
        } else {
          routeTemplate.set('schedulingMode', _routeTemplate.AUTOMATIC_SCHEDULING_MODE);
        }
      },

      onKeeperTypeClick(event) {
        const checked = event.target.checked;
        const routeTemplate = this.get('routeTemplateChangeSet');

        if (checked) {
          routeTemplate.set('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_KEEPER);
        } else {
          routeTemplate.set('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_REGULAR);
        }
      },

      async onRouteLimitChange(availability, value) {
        let routeLimitValue = parseInt(value, 10);

        if (isNaN(routeLimitValue)) {
          routeLimitValue = 0;
        }

        availability.set('routeLimit', routeLimitValue);
      },

      async onDOWChange(availability, options) {
        const sortedAvailabilities = this.get('sortedAvailabilities').filter(avail => avail !== availability);
        const dayOptions = this.get('daysOfWeek');
        const DOWSelected = [];
        dayOptions.forEach(day => {
          availability.set(day.day, false);
        });
        let deselectedDayOptions = (0, _lodash.difference)(dayOptions, options);
        options.forEach(option => {
          availability.set(option.day, true);
          const findDay = dayOptions.find(dayOption => dayOption.day === option.day);
          Ember.set(findDay, 'disabled', true);
          DOWSelected.pushObject(findDay);
        });
        sortedAvailabilities.forEach(avail => {
          deselectedDayOptions = (0, _lodash.difference)(deselectedDayOptions, avail.get('DOWSelected'));
        });
        deselectedDayOptions.forEach(option => {
          Ember.set(option, 'disabled', false);
        }); // no available options remain

        if (!deselectedDayOptions.length) {
          this.set('disableAddAvailabilityBtn', true);
        } else {
          this.set('disableAddAvailabilityBtn', false);
        }

        availability.set('DOWSelected', DOWSelected);
      },

      addNewAvailability() {
        const store = this.get('store');
        const routeTemplateAvailability = store.createRecord('route-template-availability', {
          shiftStart: new Date()
        });
        const {
          validateFn,
          validationMap
        } = (0, _emberChangesetCpValidations.buildChangeset)(routeTemplateAvailability);
        const changeset = new _emberChangeset.default(routeTemplateAvailability, validateFn, validationMap);
        this.get('sortedAvailabilities').pushObject(changeset);
      },

      onRemoveAvailability(availability) {
        const dayOptions = this.get('daysOfWeek');
        const DOWSelected = availability.get('DOWSelected');
        const deselectedDayOptions = (0, _lodash.intersection)(dayOptions, DOWSelected);
        deselectedDayOptions.forEach(option => {
          Ember.set(option, 'disabled', false);
        });
        availability.set('isDeleted', true); // no available options remain

        if (deselectedDayOptions.length || Ember.isEmpty(deselectedDayOptions)) {
          this.set('disableAddAvailabilityBtn', false);
        } else {
          this.set('disableAddAvailabilityBtn', true);
        }
      }

    }
  });

  _exports.default = _default;
});