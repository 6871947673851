define("adept-iq/pods/components/side-drawer/reconcile-trip-transfer/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addressModel = _exports.passengerModel = _exports.stopModel = _exports.tripModel = _exports.routeIdModel = _exports.routeModel = _exports.stopColumns = void 0;
  const cellComponent = 'table/cells/base-cell';
  const routeIdModel = 'reconcile-route-id';
  _exports.routeIdModel = routeIdModel;
  const routeModel = 'reconcile-route';
  _exports.routeModel = routeModel;
  const tripModel = 'reconcile-trip';
  _exports.tripModel = tripModel;
  const stopModel = 'reconcile-stop';
  _exports.stopModel = stopModel;
  const passengerModel = 'reconcile-passenger';
  _exports.passengerModel = passengerModel;
  const addressModel = 'reconcile-address';
  _exports.addressModel = addressModel;
  const stopColumns = [{
    label: 'Trip ID',
    valuePath: 'tripId',
    cellComponent,
    resizable: true,
    minResizeWidth: 50 // width: '80px'

  }, {
    label: 'Last Name',
    valuePath: 'passenger.lastName',
    cellComponent,
    resizable: true,
    minResizeWidth: 50 // width: '80px'

  }, {
    label: 'Address',
    valuePath: 'address.formattedAddress',
    cellComponent,
    minResizeWidth: 50,
    resizable: true // width: '80px'

  }, {
    label: 'City',
    valuePath: 'address.formattedCity',
    cellComponent,
    minResizeWidth: 50,
    resizable: true,
    width: '60px'
  }, {
    label: 'Stop',
    valuePath: 'type',
    cellComponent,
    minResizeWidth: 50,
    resizable: true // width: '60px'

  }, {
    label: 'ETA',
    valuePath: 'updatedEtaAsTime',
    cellComponent,
    minResizeWidth: 50,
    resizable: true // width: '60px'

  }, {
    label: 'Promise Time',
    valuePath: 'formattedPromiseTimeAsTime',
    cellComponent,
    minResizeWidth: 50,
    resizable: true // width: '80px'

  }, {
    label: 'Appt Time',
    valuePath: 'formattedRequestTimeAsTime',
    cellComponent,
    resizable: true,
    minResizeWidth: 50 // width: '80px'

  }, {
    label: 'Stop Break Ord',
    valuePath: 'ordinal',
    cellComponent,
    minResizeWidth: 60,
    resizable: true // width: '80px'

  }];
  _exports.stopColumns = stopColumns;
});