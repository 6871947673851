define("adept-iq/pods/components/iq-widgets/alert-widget/avlmConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/alert-widget',
    rowComponent: 'iq-widgets/alert-widget/alerts-row',
    modelName: 'avlm-alert',
    title: 'Alerts',
    defaultSortId: 'priority',
    defaultSortAsc: true,
    widgetName: 'alertWidget',
    selectedRowLimit: 1,
    columns: [{
      id: 'timestamp',
      index: 0,
      type: 'time',
      label: 'Time',
      valuePath: 'time',
      format: 'hh:mm A',
      defaultWidth: 80,
      highlightable: true,
      searchable: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'priorityId',
      index: 1,
      type: 'uuid',
      label: 'Priority ID',
      valuePath: 'priorityId',
      hidden: true,
      defaultWidth: 70
    }, {
      id: 'priority',
      index: 2,
      type: 'text',
      label: 'Priority',
      valuePath: 'priority',
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'routeId',
      index: 3,
      type: 'text',
      label: 'Route ID',
      valuePath: 'routeName',
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'vehicleId',
      index: 4,
      type: 'text',
      label: 'Vehicle ID',
      valuePath: 'vehicleId',
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'body',
      index: 5,
      type: 'text',
      label: 'Alert Description',
      valuePath: 'alertDescription',
      defaultWidth: 100,
      searchable: true,
      highlightable: true
    }, {
      id: 'tripId',
      index: 6,
      type: 'text',
      label: 'Trip ID',
      valuePath: 'tripId',
      hidden: true,
      unAvailable: true,
      defaultWidth: 60
    }, {
      id: 'stopId',
      index: 7,
      type: 'text',
      label: 'Stop ID',
      valuePath: 'stopId',
      hidden: true,
      unAvailable: true,
      defaultWidth: 60
    }, {
      id: 'driverId',
      index: 8,
      type: 'text',
      label: 'Driver ID',
      valuePath: 'driverId',
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'category',
      index: 9,
      type: 'text',
      label: 'Category',
      valuePath: 'category',
      hidden: true,
      defaultWidth: 70
    }, {
      id: 'state',
      index: 10,
      type: 'text',
      label: 'State',
      valuePath: 'state',
      hidden: true,
      defaultWidth: 70
    }, {
      id: 'provider',
      index: 11,
      type: 'text',
      label: 'Provider',
      valuePath: 'provider',
      hidden: true,
      defaultWidth: 70
    }],
    traversal: {
      stopWidget: {
        links: [{
          type: 'eq',
          field: 'stopId',
          valuePath: 'id'
        }]
      },
      routeWidget: {
        links: [{
          type: 'stringEq',
          field: 'routeName',
          valuePath: 'name'
        }]
      },
      tripWidget: {
        links: [{
          type: 'eq',
          field: 'tripPk',
          valuePath: 'id'
        }]
      },
      vehicleWidget: {
        links: [{
          type: 'eq',
          field: 'vehicleId',
          valuePath: 'id'
        }]
      },
      messageWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'alertId'
        }, {
          type: 'eq',
          field: 'driverId',
          valuePath: 'driverId'
        }, {
          type: 'eq',
          field: 'vehicleId',
          valuePath: 'vehicleId'
        }, {
          type: 'eq',
          field: 'tripPk',
          valuePath: 'tripId'
        }, {
          type: 'stringEq',
          field: 'routeName',
          valuePath: 'routeName'
        }]
      }
    },
    implicit: {
      conditions: [{
        type: 'stringNeRQL',
        field: 'state',
        value: ['completed']
      }]
    }
  };
  _exports.default = _default;
});