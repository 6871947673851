define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/config", "adept-iq/utils/unwrapProxy"], function (_exports, _config, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['vehicle-driver-manager-container'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    // its possible to have a singular table ref
    // but let's keep it simple for now and singularly have a reference per each tab
    vehicleTableRef: null,
    driverTableRef: null,
    zoneTableRef: null,
    disableEditing: true,
    disableBtns: null,
    showValidations: false,
    isEditing: false,
    editAPI: null,
    lookupComponentRef: null,
    isLookupTabActive: Ember.computed.notEmpty('lookupComponentRef'),
    selectedMenuTitle: Ember.computed('editAPI.selected', function () {
      const selectedMenu = this.get('editAPI.selected');
      return selectedMenu ? selectedMenu.toLowerCase() : '';
    }),

    async init() {
      this._super(...arguments);

      this.set('editAPI', Ember.Object.create({
        buttonSettings: function (deleteBtn = false, newBtn = false, editBtn = false, undoBtn = true, saveBtn = true) {
          this.setProperties({
            deleteBtn,
            editBtn,
            saveBtn,
            undoBtn,
            newBtn
          });
        }
      }));
      this.set('disableBtns', {
        new: false,
        edit: false,
        delete: false,
        save: true,
        undo: true
      });
    },

    didInsertElement() {
      this._super(...arguments);
    },

    willDestroy() {
      this._super(...arguments);
    },

    newVehicleRecord() {
      const title = 'New Vehicle';
      const editAPI = this.get('editAPI');
      let pendingVehicleRecord = this.get('pendingVehicleRecord'); // pending record is no longer new or doesn't exist

      if (!pendingVehicleRecord || pendingVehicleRecord.get('id')) {
        pendingVehicleRecord = this.get('store').createRecord('vehicle');
        this.set('pendingVehicleRecord', pendingVehicleRecord);
      } else {// reset vehicle record properties
      }

      this.toggleProperty('isEditing');
      editAPI.setProperties({
        options: _config.vehicleEditOptions,
        selected: _config.vehicleEditOptions.firstObject,
        selectedVehicle: pendingVehicleRecord,
        searchEnabled: false,
        title: title
      });
    },

    editVehicleRecord() {
      // checked rows will be only one because it a radio type
      const checkedRow = this.get('vehicleTableRef.checkedRows.firstObject');
      if (!checkedRow) return;
      const editAPI = this.get('editAPI');
      this.toggleProperty('isEditing');
      let title = '';
      let searchEnabled = false;
      title = `Edit Vehicle ${checkedRow.get('name')}`;
      searchEnabled = checkedRow.get('isNew');
      editAPI.setProperties({
        options: _config.vehicleEditOptions,
        selected: _config.vehicleEditOptions.firstObject,
        selectedVehicle: checkedRow,
        searchEnabled: searchEnabled,
        title: title
      });
    },

    newDriverRecord() {
      const store = this.get('store');
      const editAPI = this.get('editAPI');
      const title = 'New Driver';
      const selectedDriverRow = this.get('driverTableRef.checkedRows.firstObject');
      let pendingDriverRecord = this.get('pendingDriverRecord'); // pending record is no longer new or doesn't exist

      if (!pendingDriverRecord || pendingDriverRecord.get('id')) {
        pendingDriverRecord = store.createRecord('driver');
        this.set('pendingDriverRecordRecord', pendingDriverRecord);
      }

      if (selectedDriverRow) selectedDriverRow.set('selected', false);
      this.createDriverAssociations(pendingDriverRecord);
      editAPI.setProperties({
        options: _config.driverEditOptions,
        selected: _config.driverEditOptions.firstObject,
        selectedDriver: pendingDriverRecord,
        searchEnabled: false,
        title: title
      });
      this.toggleProperty('isEditing');
    },

    editDriverRecord() {
      // checked rows will be only one because it a radio type
      const checkedRow = this.get('driverTableRef.checkedRows.firstObject');
      const editAPI = this.get('editAPI');
      if (!checkedRow) return;
      this.createDriverAssociations(checkedRow);
      const title = `Edit Driver ${checkedRow.get('driverId')} - ${checkedRow.get('fullName')}`;
      const searchEnabled = false;
      editAPI.setProperties({
        options: _config.driverEditOptions,
        selected: _config.driverEditOptions.firstObject,
        selectedDriver: checkedRow,
        searchEnabled: searchEnabled,
        title: title
      });
      this.toggleProperty('isEditing');
    },

    createDriverAssociations(driverRecord) {
      const store = this.get('store');
      const driverAddress = (0, _unwrapProxy.unwrapProxy)(driverRecord.get('address'));
      const driverPhones = (0, _unwrapProxy.unwrapProxy)(driverRecord.get('phoneNumbers'));
      const emergencyContacts = (0, _unwrapProxy.unwrapProxy)(driverRecord.get('emergencyContacts'));
      const driverLicense = (0, _unwrapProxy.unwrapProxy)(driverRecord.get('license'));

      if (Ember.isEmpty(driverAddress)) {
        driverRecord.set('address', store.createRecord('address'));
      }

      if (Ember.isEmpty(driverLicense)) {
        driverRecord.set('license', store.createRecord('driver-license'));
      }

      if (Ember.isEmpty(driverPhones) || Ember.isEmpty(driverPhones.length)) {
        driverRecord.set('phoneNumbers', [store.createRecord('driver-phone-number')]);
      }

      if (Ember.isEmpty(emergencyContacts) || Ember.isEmpty(emergencyContacts.length)) {
        driverRecord.set('emergencyContacts', [store.createRecord('driver-emergency-contact')]);
      }
    },

    actions: {
      deleteRecord() {
        if (this.get('lookupComponentRef')) {
          this.get('lookupComponentRef').onDeleteRecordClick();
        }
      },

      undoRecord() {
        if (this.get('lookupComponentRef')) {
          return this.get('lookupComponentRef').onUndoRecordClick();
        }
      },

      newRecord(selectedTab) {
        if (selectedTab === _config.DRIVER_TAB) {
          this.newDriverRecord();
        }

        if (selectedTab === _config.VEHICLE_TAB) {
          this.newVehicleRecord();
        }

        if (this.get('lookupComponentRef')) {
          return this.get('lookupComponentRef').onNewRecordClick();
        }
      },

      saveRecord() {
        this.set('disableBtns', {
          new: false,
          edit: false,
          delete: false,
          save: true,
          undo: true
        });

        if (this.get('lookupComponentRef')) {
          return this.get('lookupComponentRef').onSaveRecordClick();
        }
      },

      editRecord(selectedTab) {
        if (selectedTab === _config.VEHICLE_TAB) {
          this.editVehicleRecord();
        } else {
          this.editDriverRecord();
        }

        this.set('disableBtns', {
          new: true,
          edit: true,
          delete: true,
          save: false,
          undo: false
        });
        this.set('disableEditing', false);

        if (this.get('lookupComponentRef')) {
          return this.get('lookupComponentRef').onEditRecordClick();
        }
      },

      async backTo() {
        // return to tab navigation menu
        const editAPI = this.get('editAPI');
        const onValidateAction = editAPI.onValidateAction;
        let isValid = true;

        if (onValidateAction) {
          isValid = await onValidateAction.perform();
        }

        if (isValid) {
          this.set('isEditing', false);
          editAPI.setProperties({
            vehicleEditOptions: null,
            driverEditOptions: null,
            editAction: null,
            newAction: null,
            deleteAction: null,
            undoAction: null,
            saveAction: null,
            saveBtn: false,
            deleteBtn: false,
            editBtn: false,
            undoBtn: false,
            newBtn: false
          });
          this.set('disableBtns', {
            save: false,
            undo: false,
            delete: false,
            new: false,
            edit: false
          });
        }
      },

      onSelectTableRow() {
        const disableBtns = {
          new: false,
          edit: false,
          delete: false,
          save: true,
          undo: true
        };
        Ember.run.scheduleOnce('afterRender', this, () => {
          this.set('disableBtns', disableBtns);
          this.set('disableEditing', true);
        });
      },

      onSelectedRecordDirty(isDirty) {
        const disableSaveBtn = !isDirty;
        Ember.run.scheduleOnce('afterRender', this, () => {
          this.set('disableBtns.save', disableSaveBtn);
        });
      },

      async onNavigationChange(option) {
        const editAPI = this.get('editAPI');
        const onValidateAction = editAPI.onValidateAction;
        let isValid = true;

        if (onValidateAction) {
          isValid = await onValidateAction.perform();
        }

        if (isValid) {
          editAPI.set('selected', option);
        }
      },

      onLookupTabClick() {
        const editAPI = this.get('editAPI');
        editAPI.setProperties({
          options: _config.lookupOptions,
          selected: _config.lookupOptions[0].options[0]
        });
      },

      setLookupComponent(childCompRef) {
        this.set('lookupComponentRef', childCompRef);
      }

    }
  });

  _exports.default = _default;
});