define("adept-iq/pods/components/side-drawer/reconcile-rescue-route/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rowComponent = _exports.tripColumns = void 0;
  const rowComponent = 'side-drawer/reconcile-rescue-route/table/row';
  _exports.rowComponent = rowComponent;
  const cellComponent = 'side-drawer/reconcile-rescue-route/table/cells/base-cell';
  const tripColumns = [{
    id: 'checkbox',
    valuePath: 'checked',
    cellComponent: 'side-drawer/reconcile-rescue-route/table/cells/check-box',
    sortable: false,
    width: '30px',
    minResizeWidth: 50
  }, {
    label: 'OTP Status',
    valuePath: 'otpCaption',
    resizable: true,
    sortable: false,
    highlightable: true,
    minResizeWidth: 50,
    cellComponent
  }, {
    label: 'Trip ID',
    valuePath: 'tripId',
    resizable: true,
    sortable: false,
    minResizeWidth: 100
  }, {
    label: 'Last Name',
    valuePath: 'lastName',
    resizable: true,
    sortable: false,
    minResizeWidth: 100
  }, {
    label: 'ETA',
    valuePath: 'eta',
    resizable: true,
    sortable: false,
    highlightable: true,
    minResizeWidth: 75,
    cellComponent
  }];
  _exports.tripColumns = tripColumns;
});