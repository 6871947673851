define("adept-iq/serializers/driver-license", ["exports", "adept-iq/serializers/-driver"], function (_exports, _driver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _driver.default.extend({
    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      if (json.data && json.data.relationships) {
        const classificationName = json.data.relationships.classificationName;

        if (Ember.isPresent(classificationName)) {
          classificationName.data.type = 'classificationName';
        }
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (Ember.isPresent(resourceHash.attributes) && Ember.isPresent(resourceHash.attributes.classificationName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.classificationName = {
          data: {
            type: 'classificationName',
            id: resourceHash.attributes.classificationName
          }
        };
        resourceHash.relationships = relationships;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});