define("adept-iq/pods/components/iq-widgets/history-routes-widget/cells/editable/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const WC_SEAT_CHANGED = 1;
  const PCA_CHANGED = 2;
  const CMP_CHANGED = 3;

  var _default = Ember.Component.extend({
    classNames: ['editable-cell', 'no-spinner'],
    notifications: Ember.inject.service(),
    editModal: Ember.inject.service(),
    isEditing: false,
    buffer: null,
    isNumberType: false,
    isLimitNumberType: false,
    isDateType: false,
    isTextType: false,
    isListType: false,
    // canFocus: computed.not('isEditing'),
    isEditClick: Ember.computed.and('row.isEditing', 'column.isEditing'),
    onIsEditingChange: Ember.observer('isEditClick', function () {
      if (this.get('isEditClick')) {
        this.startEditing();
      } else {
        this.discardChanges();
      }
    }),

    init() {
      this._super(...arguments);

      this.resetProperties();
      Ember.set(this, 'timeRange', {
        today: (0, _moment.default)().hours(0).minutes(0).format('YYYY-MM-DD'),
        date: null,
        starttime: null,
        endtime: null,
        startforendtime: (0, _moment.default)().startOf('day').toISOString(),
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(7, 'years').format('YYYY-MM-DD')
      });
    },

    startEditing() {
      if (this.get('isEditing')) return;
      this.set('isEditing', true);
      const row = this.get('row');
      const column = this.get('column');
      const valuePath = column.get('valuePath');
      this.setValuesTypeFlags(valuePath);
      const value = row.get(valuePath);
      const buffer = value ? value.toString() : '';
      this.set('buffer', buffer); // column.set('oldValue', buffer);

      column.set('newValue', buffer);

      if (valuePath === 'actualTime') {
        Ember.set(this, 'timeRange.starttime', buffer);
      }
    },

    resetProperties() {
      this.setProperties({
        isNumberType: false,
        isLimitNumberType: false,
        isFloatType: false,
        isDateType: false,
        isSpecificNumberType: false
      });
    },

    setColumnValue(value) {
      const column = this.get('column');

      if (column) {
        column.set('newValue', value);
        column.set('editingStart', true);
        this.manageCalculativeFields(column);
      }
    },

    setValuesTypeFlags(valuePath) {
      switch (valuePath) {
        case 'actualTime':
          Ember.set(this, 'isDateType', true);
          break;

        case 'stopTime':
        case 'mileage':
          Ember.set(this, 'isNumberType', true);
          break;

        case 'cmp':
        case 'pca':
          Ember.set(this, 'isLimitNumberType', true);
          break;

        case 'fare':
        case 'ambSeats':
          Ember.set(this, 'isDisableType', true);
          break;

        case 'wcSeats':
          // case 'pca':
          Ember.set(this, 'isBoolNumberType', true);
          break;

        case 'otpStatus':
          Ember.set(this, 'isSpecificNumberType', true);
          break;

        default:
          break;
      }
    },

    setCalculativeFileds(changedType, column) {
      const value = column.get('newValue') ? parseInt(column.get('newValue'), 10) : 0;
      const row = Ember.get(this, 'row');
      const columns = Ember.get(row, 'columns');
      const travelneddsObj = {
        ambSeats: null,
        ambCol: null,
        wcSeats: null,
        pca: null,
        cmp: null,
        fare: null,
        fareCol: null
      };
      columns.map(col => {
        switch (col.get('valuePath')) {
          case 'ambSeats':
            travelneddsObj.ambCol = col;
            break;

          case 'wcSeats':
            // eslint-disable-next-line no-nested-ternary
            travelneddsObj.wcSeats = changedType === WC_SEAT_CHANGED ? value : col.get('newValue') ? parseInt(col.get('newValue'), 10) : 0;
            break;

          case 'pca':
            // eslint-disable-next-line no-nested-ternary
            travelneddsObj.pca = changedType === PCA_CHANGED ? value : col.get('newValue') ? parseInt(col.get('newValue'), 10) : 0;
            break;

          case 'cmp':
            // eslint-disable-next-line no-nested-ternary
            travelneddsObj.cmp = changedType === CMP_CHANGED ? value : col.get('newValue') ? parseInt(col.get('newValue'), 10) : 0;
            break;

          case 'fare':
            travelneddsObj.fareCol = col;
            break;

          default:
            break;
        }
      }); // calculate ambSeats

      travelneddsObj.ambSeats = travelneddsObj.wcSeats === 1 ? travelneddsObj.pca + travelneddsObj.cmp : 1 + travelneddsObj.pca + travelneddsObj.cmp;
      travelneddsObj.ambCol.set('newValue', travelneddsObj.ambSeats);
      row.set('ambSeats', travelneddsObj.ambSeats); // calculate fare

      travelneddsObj.fare = travelneddsObj.ambSeats * 2.75 + travelneddsObj.wcSeats * 2.75;
      travelneddsObj.fareCol.set('newValue', travelneddsObj.fare > 0 ? parseFloat(travelneddsObj.fare).toFixed(2) : 0);
      row.set('fare', travelneddsObj.fare > 0 ? parseFloat(travelneddsObj.fare).toFixed(2) : 0);
    },

    manageCalculativeFields(column) {
      if (column) {
        switch (column.get('valuePath')) {
          case 'wcSeats':
            this.setCalculativeFileds(WC_SEAT_CHANGED, column);
            break;

          case 'pca':
            this.setCalculativeFileds(PCA_CHANGED, column);
            break;

          case 'cmp':
            this.setCalculativeFileds(CMP_CHANGED, column);
            break;

          default:
            break;
        }
      }
    },

    discardChanges() {
      if (!this.get('isEditing')) return;
      this.set('isEditing', false);
      this.set('row.isEditing', false);
      this.set('column.isEditing', false);
      this.set('column.newValue', null); // this.set('column.oldValue', null);

      this.set('column.editingStart', null);
    },

    actions: {
      onInput(text) {
        this.set('buffer', text);
        this.setColumnValue(text);
      },

      onNumberKeydown(event) {
        if (event.keyCode === 189 || event.keyCode === 109 || event.keyCode === 69 || event.keyCode === 107 || event.shiftKey) {
          return false;
        }

        return true;
      },

      onBlockKeydown() {
        return false;
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
        this.setColumnValue((0, _moment.default)(this.timeRange.starttime).format('hh:mm A'));
      }

    }
  });

  _exports.default = _default;
});