define("adept-iq/classes/polylines/routes/floatingBreak/iq", ["exports", "adept-iq/classes/polylines/routes/floatingBreak/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // `BasePolyline` overrides
    isActive: true,
    points: Ember.computed.readOnly('iqRoute.breakPolylinePoints'),
    // local properties
    record: null,
    iqRoute: Ember.computed.alias('record')
  });

  _exports.default = _default;
});