define("adept-iq/pods/components/side-drawer/reconcile-cancel-trip/component", ["exports", "moment", "ember-concurrency", "adept-iq/utils/format-function"], function (_exports, _moment, _emberConcurrency, _formatFunction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    passengerTrips: null,
    cancelTripNote: '',
    isSelectAllChecked: false,
    selectedReason: null,
    serviceModeTaxiType: 'Taxi',
    selectedTrip: Ember.computed.readOnly('workspace.topOptions.trip'),
    callBack: Ember.computed.readOnly('workspace.topOptions.action'),
    canUndo: Ember.computed.or('selectedReason', 'cancelTripNote', 'selectedTrips'),
    canSave: Ember.computed.alias('selectedReason'),
    tripDetails: Ember.computed.readOnly('reconcile.reconcileTrip'),
    passengerDetails: Ember.computed.readOnly('reconcile.reconcilePassenger'),
    serviceMode: Ember.computed.alias('reconcile.reconcileMode'),
    promiseTime: Ember.computed('tripDetails.promiseTime', function () {
      const promiseTime = this.get('tripDetails.promiseTime');
      return promiseTime ? (0, _moment.default)(promiseTime).format('hh:mm A') : '';
    }),
    selectedTrips: Ember.computed('passengerTrips.@each.isChecked', function () {
      const trips = Ember.get(this, 'passengerTrips');

      if (Ember.isEmpty(trips)) {
        return null;
      }

      return trips.filterBy('isChecked', true);
    }),
    reasonOptions: Ember.computed('selectedReason', function () {
      const canelReasons = this.store.peekAll('cs-config-item').filter(message => {
        return message.category === 'config-System_Configuration-cancel_types';
      });
      return canelReasons.map(reason => reason.displayName);
    }),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      Ember.$('.side-drawer-modal').width('470px');
      this.get('systemConfig').set('showRightPanel', true);

      if (this.passengerDetails) {
        Ember.get(this, 'getPassengerTripsTask').perform();
      }
    },

    getPassengerTripsTask: (0, _emberConcurrency.task)(function* () {
      const trips = [];

      if (!this.tripDetails.promiseTime) {
        Ember.set(this, 'passengerTrips', []);
        return;
      }

      const passengerId = this.serviceMode === this.serviceModeTaxiType ? this.passengerDetails.passengerId : this.passengerDetails.get('passengerId');
      const dateRange = (0, _formatFunction.getDateRangeForSearch)(this.tripDetails.promiseTime);
      const queryOptions = {
        passengerId: passengerId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        tripId: this.tripDetails.get('tripId')
      };
      const passengerTrips = yield Ember.get(this, 'store').query('reconcile-trip', queryOptions);

      if (!Ember.isEmpty(passengerTrips)) {
        Ember.set(this, 'passengerTrips', []);
      }

      passengerTrips.map(passengerTrip => {
        Ember.set(passengerTrip, 'isChecked', false);
        trips.push(passengerTrip);
      });
      Ember.set(this, 'passengerTrips', trips);
    }),
    saveCancelTripTask: (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const reconcileTripAdapter = store.adapterFor('reconcile-trip');
        const canelTrips = [];
        canelTrips.push(this.tripDetails.get('tripId'));

        if (!Ember.isEmpty(this.passengerTrips)) {
          this.passengerTrips.map(passengerTrip => {
            if (passengerTrip.isChecked && !canelTrips.includes(passengerTrip.tripId)) {
              canelTrips.push(passengerTrip.tripId);
            }
          });
        }

        const payload = {
          tripId: this.tripDetails.get('tripId'),
          reason: this.get('selectedReason'),
          notes: this.cancelTripNote,
          date: (0, _moment.default)(new Date()).toISOString(),
          isDeleted: true,
          trips: canelTrips,
          routeId: this.tripDetails.get('routeId'),
          userId: this.session.data.authenticated.userId
        };
        yield reconcileTripAdapter.patchTrip(payload, 'cancel trip');
        let successMsg = `TRIP ${this.tripDetails.tripId} WAS SUCCESSFULLY CANCELLED`;

        if (!Ember.isEmpty(canelTrips) && canelTrips.length > 1) {
          successMsg = `${canelTrips.length} TRIPS WERE SUCCESSFULLY CANCELLED`;
        }

        this.get('notifications').success(successMsg);
      } catch (error) {
        throw error;
      }
    }),

    saveCancelTrip() {
      const tooltip = this.get('tooltip');
      const title = 'Cancel Trip';
      const tip = `Cancel trip ${this.tripDetails.tripId}?`;
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          return Ember.get(this, 'saveCancelTripTask').perform().then(() => {
            this.send('cancel');
            this.get('workspace').popState('reconcileCancelTrip');
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in cancel trip, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    clearCheckboxes() {
      Ember.set(this, 'isSelectAllChecked', false);
      this.passengerTrips.map(passengerTrip => {
        passengerTrip.set('isChecked', false);
      });
    },

    unloadSelectedTrips() {
      let record = this.store.peekRecord('reconcile-trip', this.tripDetails.get('tripId'));
      this.store.unloadRecord(record);

      if (!Ember.isEmpty(this.selectedTrips)) {
        this.selectedTrips.map(trip => {
          record = this.store.peekRecord('reconcile-trip', trip.tripId);
          this.store.unloadRecord(record);
        });
      }
    },

    actions: {
      onSaveClick() {
        if (!Ember.get(this, 'selectedReason')) {
          Ember.set(this, 'errorMessage', 'Please select reason');
          return;
        }

        Ember.set(this, 'errorMessage', '');
        this.saveCancelTrip();
      },

      onChangeReason(option) {
        this.set('selectedReason', option);
      },

      onUndoClick() {
        this.set('selectedReason', null);
        this.set('cancelTripNote', '');
        this.clearCheckboxes();
      },

      onSelectAllCheckboxClick() {
        this.toggleProperty('isSelectAllChecked');
        this.passengerTrips.map(passengerTrip => {
          passengerTrip.set('isChecked', this.isSelectAllChecked);
        });
      },

      onTripCheckboxClick(passengerTrip) {
        let isChecked = passengerTrip.get('isChecked');
        isChecked = !isChecked;
        passengerTrip.set('isChecked', isChecked);
      },

      cancel() {
        this.unloadSelectedTrips();

        if (this.callBack) {
          this.callBack(true);
        }
      }

    }
  });

  _exports.default = _default;
});