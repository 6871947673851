define("adept-iq/pods/components/side-drawer/activity-log/base-activity-log/component", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line no-unused-vars
  let mockedUserEntries = null;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    events: null,
    eventsChanged: false,
    componentName: 'activityLogs',
    onFilteredEventsChange: Ember.observer('filteredEvents', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateTableRows');
    }),

    init() {
      this._super(...arguments);

      const currColumns = this.get('columns');
      const table = new _emberLightTable.default(currColumns);
      this.set('table', table);
      mockedUserEntries = [];
      this.set('events', []); // initialize key observer

      this.onFilteredEventsChange();
      this.get('fetchEventsTask').perform();
    },

    filteredSections: Ember.computed('editableSections', function () {
      const sections = Ember.copy(this.get('editableSections'), true) || [];
      return sections.map(section => {
        return section;
      }).filter(section => section.fields.length > 0);
    }),
    filteredEvents: Ember.computed('eventsChanged', function () {
      let events = this.get('events') || []; // sort reverse-chronologically while we're at it

      events = events.sortBy('date');
      events.reverse();
      return events;
    }),

    updateTableRows() {
      const table = this.get('table');
      const filteredEvents = this.get('filteredEvents');
      table.setRows(filteredEvents);
    },

    async buildUserInfo(eventInfo) {
      const driverId = eventInfo.driver ? eventInfo.driver.get('driverId') : null;
      const vehicleId = eventInfo.vehicle ? eventInfo.vehicle.get('callSign') : null;
      let driverInfo = '';

      if (driverId) {
        driverInfo = `Driver ${driverId}`;
      } else if (vehicleId) {
        driverInfo = `Vehicle ${vehicleId}`;
      }

      if (!Ember.isEmpty(eventInfo.userId) && eventInfo.userId !== '0') {
        let userModel = await this.store.peekRecord('sso-user', eventInfo.userId);

        if (Ember.isEmpty(userModel)) {
          userModel = await this.store.findRecord('sso-user', eventInfo.userId).catch(() => {
            return '';
          });
        }

        if (!Ember.isEmpty(userModel)) {
          const firstName = userModel.get('firstName');

          if (firstName === 'Driver') {
            return driverInfo;
          }

          return `${userModel.get('fullName')}`;
        }
      }

      return driverInfo;
    },

    processActivityLogs(activityLogs, loggingType) {
      const events = this.get('events');
      activityLogs.data.map(async activityLog => {
        const eventTime = activityLog.attributes.createdAt;
        const userInfo = await this.buildUserInfo(activityLog.attributes);
        const eventLog = activityLog.attributes.eventLog;

        for (const key in eventLog) {
          if (eventLog.hasOwnProperty(key)) {
            const eventLogType = eventLog[key];
            eventLogType.map(event => {
              if (event.loggingType === loggingType) {
                event.date = eventTime;
                event.user = userInfo;
                events.push(event);
              }
            });
          }
        }

        this.toggleProperty('eventsChanged');
      });
    },

    validateRecords() {
      let valid = true;
      const addActivityLog = this.get('addActivityLog');
      const activity = this.get('userActivitySelected');
      const details = this.get('details');
      this.set('errors', []);

      if (!addActivityLog) {
        this.get('errors').pushObject('Please Open Activity Entry Form through Plus Icon before Approving');
        return false;
      }

      if (Ember.isEmpty(activity)) {
        valid = false;
        this.get('errors').pushObject('Must Select an Activity');
      }

      if (Ember.isEmpty(details)) {
        valid = false;
        this.get('errors').pushObject('Must Enter Details');
      }

      return valid;
    },

    actions: {
      onValueChange(valuePath, options) {
        let value = options;

        if (typeof options === 'object') {
          value = options.get('name');
        }

        this.set(valuePath, value);
      },

      onAddClick() {
        this.set('errors', null);
        this.toggleProperty('addActivityLog');
      }

    }
  });

  _exports.default = _default;
});