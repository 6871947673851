define("adept-iq/pods/components/reoptimize-modal/operation-tables/remove-driver-break-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    modelName: 'route',
    title: 'Remove Driver Breaks',
    defaultSortId: 'provider',
    defaultSortAsc: true,
    queryModelData: true,
    // hideWidgetControls: true,
    columns: [{
      id: 'provider',
      index: 0,
      type: 'text',
      label: 'Provider',
      valuePath: 'provider.id',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'name',
      index: 1,
      type: 'uuid',
      // adept 4 is no longer part of the system, route's will include characters
      label: 'Name',
      valuePath: 'name',
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'trimStart',
      index: 2,
      type: 'date',
      label: 'Trim Start',
      valuePath: 'adjustedStartTime',
      format: 'HH:mm',
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'trimEnd',
      index: 3,
      type: 'date',
      label: 'Trim End',
      valuePath: 'adjustedEndTime',
      format: 'HH:mm',
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'routeMinutes',
      index: 4,
      type: 'text',
      label: 'Route Minutes',
      valuePath: 'routeMinutes',
      format: 'HH:mm',
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'result',
      index: 6,
      type: 'text',
      label: 'Result',
      valuePath: 'result',
      defaultWidth: 100,
      searchable: true,
      highlightable: true
    }]
  };
  _exports.default = _default;
});