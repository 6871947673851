define("adept-iq/config/cell-item-types", ["exports", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/otp-cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/otp-value-cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/date-cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/date-local-cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/time-cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/time-local-cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/availability-cell", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/read-time-cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/travel-needs-cell"], function (_exports, _cellItem, _otpCellItem, _otpValueCellItem, _dateCellItem, _dateLocalCellItem, _timeCellItem, _timeLocalCellItem, _availabilityCell, _readTimeCellItem, _travelNeedsCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CellItemTypes = {
    'CellItem': _cellItem.default,
    'OTPCellItem': _otpCellItem.default,
    'OTPValueCellItem': _otpValueCellItem.default,
    'TimeCellItem': _timeCellItem.default,
    'TimeLocalCellItem': _timeLocalCellItem.default,
    'DateCellItem': _dateCellItem.default,
    'DateLocalCellItem': _dateLocalCellItem.default,
    'AvailabilityCell': _availabilityCell.default,
    'TravelNeedsCell': _travelNeedsCell.default,
    'ReadTimeCellItem': _readTimeCellItem.default
  };
  var _default = CellItemTypes;
  _exports.default = _default;
});