define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/dot-inspection/dot-inspection-validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATIONS = void 0;
  const VALIDATIONS = {
    inspectionDate: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Inspection Date',
      message: '{description} is required'
    })],
    nextInspectionDate: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Next Inspection Date',
      message: '{description} is required'
    })],
    oosDate: [(0, _validators.validatePresence)({
      presence: true,
      description: 'OOS Date',
      message: '{description} is required'
    })],
    failedDot: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Failed Dot',
      message: '{description} is required'
    })]
  };
  _exports.VALIDATIONS = VALIDATIONS;
});