define("adept-iq/pods/components/iq-widgets/new-booking-form/trip-details-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BhtpHuDt",
    "block": "{\"symbols\":[\"leg\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"g-side-drawer-panel-selector\"],[11,\"style\",\"display: block;\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"noOfLegs\"]],0],null]],null,{\"statements\":[[4,\"each\",[[23,[\"legsInBooking\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row mt-2\"],[11,\"style\",\"display: block;\"],[9],[0,\"\\n        \"],[1,[27,\"iq-widgets/new-booking-form/leg-form\",null,[[\"legIndex\",\"addressesOfRiderToBook\",\"syncPickAndDrop\",\"leg\",\"disableRequestDate\",\"isReturnTrip\",\"onFirstLegDateChange\",\"onLegRemoveClick\",\"validateRequestDateTime\",\"showErrorMessage\",\"updateFare\",\"pickAddressText\",\"dropAddressText\"],[[22,2,[]],[23,[\"addressesOfRiderToBook\"]],[23,[\"syncPickAndDrop\"]],[22,1,[]],[23,[\"disableRequestDate\"]],[23,[\"isReturnTrip\"]],[23,[\"onFirstLegDateChange\"]],[23,[\"onLegRemoveClick\"]],[23,[\"validateRequestDateTime\"]],[23,[\"showErrorMessage\"]],[23,[\"updateFare\"]],[23,[\"pickAddressText\"]],[23,[\"dropAddressText\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"isReturnTrip\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"col-md-10 offset-md-2 mr-1 remove-col-padding\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"trip-locationButton float-right mr-1 btn-font-size\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onAddNewLegClick\"],null]],[9],[0,\"\\n              \"],[7,\"i\"],[11,\"class\",\"fa fa-plus\"],[11,\"aria-hidden\",\"true\"],[9],[0,\" \"],[10],[0,\"\\n              Add New Legs\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/new-booking-form/trip-details-form/template.hbs"
    }
  });

  _exports.default = _default;
});