define("adept-iq/pods/components/form-components/addresses/component", ["exports", "tomtom", "adept-iq/config/environment", "ember-concurrency", "adept-iq/utils/zone-validation"], function (_exports, _tomtom, _environment, _emberConcurrency, _zoneValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function cleanFieldString(text) {
    return typeof text === 'undefined' ? '' : text;
  }

  var _default = Ember.Component.extend({
    // Rename classNames to address and then separate that class into the actual trigger, dropdown content, and pin
    // want to give people a better css ruleset to override wherever this component is embedded
    classNames: ['locations-input'],
    classNameBindings: ['geocode.activeGeo'],
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    notifications: Ember.inject.service(),
    value: null,
    disabled: false,
    addressOptions: null,
    notesReadOnly: false,
    addressType: null,
    markerType: 'pick',
    address: null,
    showPinButton: true,
    renderInPlace: false,
    selectedAddress: null,
    addressRecord: null,
    disableAddress: false,
    locationsPath: 'latlng',
    activeGeo: Ember.computed.readOnly('geocode.activeGeo'),
    aliasPath: Ember.computed.readOnly('extra.aliasPath'),
    streetNumberPath: Ember.computed.readOnly('extra.streetNumberPath'),
    streetAddressPath: Ember.computed.readOnly('extra.streetAddressPath'),
    premisePath: Ember.computed.readOnly('extra.premisePath'),
    streetNamePath: Ember.computed.readOnly('extra.streetNamePath'),
    localityPath: Ember.computed.readOnly('extra.localityPath'),
    regionPath: Ember.computed.readOnly('extra.regionPath'),
    subRegionPath: Ember.computed.readOnly('extra.subRegionPath'),
    postalCodePath: Ember.computed.readOnly('extra.postalCodePath'),
    countryPath: Ember.computed.readOnly('extra.countryPath'),
    notesPath: Ember.computed.readOnly('extra.notesPath'),
    freeformAddressPath: Ember.computed.readOnly('extra.freeformAddressPath'),
    defaultOptionsPath: Ember.computed.readOnly('extra.defaultOptionsPath'),
    useOptionRecord: Ember.computed.readOnly('extra.useOptionRecord'),
    useRecordWithId: Ember.computed.readOnly('extra.useRecordWithId'),
    addressTypeOption: Ember.computed.readOnly('extra.addressType'),
    selectedAddressRecord: Ember.computed('record.{place,address}', function () {
      const valuePath = this.get('valuePath');
      return this.get(`record.${valuePath}`) || this.get('record');
    }),
    publicApi: null,

    init() {
      this._super(...arguments);

      this.setAddress();
      this.publicApi = {
        onValueChange: value => {
          this.send('setValue', value);
        }
      };
    },

    didInsertElement() {
      this._super(...arguments);

      const action = this.get('registerPublicApi');

      if (action) {
        action(this.publicApi);
      }
    },

    willDestroyElement() {
      this.get('geocode').deactivateGeocode();
      const action = this.get('registerPublicApi');

      if (action) {
        action(null);
      }

      this._super(...arguments);
    },

    geocodeAddressChange() {
      const record = this.get('addressRecord');
      const tomtomAddress = this.get('geocode.tomtomAddress');
      const markerType = this.get('markerType');

      if (!Ember.isEmpty(tomtomAddress)) {
        this.convertTTAddressToModel(record, tomtomAddress).then(() => {
          this.disableAddressSearchOnMap(record, markerType);
        });
      }
    },

    saveReverseGeocodeAddress() {
      this.geocodeAddressChange();
      this.removeObserver('geocode.tomtomAddress', this.saveReverseGeocodeAddress);
    },

    validateAddresses() {
      const record = this.get('address');
      this.set('editModal.errors', []);

      if (record.get('location.content.lat') === 0 || record.get('location.content.lng') === 0) {
        this.get('editModal.errors').pushObject('Address does not have lat/lng. Please select position on the map.');
      }
    },

    setAddress() {
      const place = this.get('value'); // component expects a place model
      // then onchange method to mutate in parent
      // we should refactor this component where it can take any value and returns the address object
      // then the parent should decide what to do with that object would be better, in my opinion to be more inline with functional programming paradigm

      this.set('address', place);
      this.set('addressOptions', []);
      const selectedAddress = place.get('address.address');

      if (!Ember.isNone(selectedAddress)) {
        place.set('address.oldAddress', selectedAddress);
      }

      if (!Ember.isEmpty(place)) {
        this.get('addressOptions').push(place);
      }
    },

    addressSearchResults(results) {
      this.set('addressOptions', results);
    },

    searchAddress: (0, _emberConcurrency.task)(function* (term) {
      yield _tomtom.default.fuzzySearch().key(_environment.default.tomtom.search.searchKey).query(term).countrySet(_environment.default.tomtom.search.countrySet).typeahead(true).center(_environment.default.tomtom.search.center).radius(_environment.default.tomtom.search.radius).callback(this.addressSearchResults.bind(this)).go();
    }),
    convertTTAddressToModel: function (record, ttAddress) {
      const useOptionRecord = this.get('useOptionRecord');
      const geocode = this.get('geocode') || [];
      const markerType = this.get('markerType');
      const valuePath = this.get('locationsPath');
      const addressTypeOption = this.get('addressTypeOption');
      const options = {
        record,
        valuePath,
        useOptionRecord,
        addressTypeOption
      };
      this.set('record.isForceDirty', true);
      this.get('onChange')(cleanFieldString(ttAddress.position), options);
      options.valuePath = this.get('streetNumberPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.streetNumber), options);
      options.valuePath = this.get('premisePath');
      this.get('onChange')(cleanFieldString(ttAddress.address.premise), options);
      options.valuePath = this.get('streetNamePath');
      this.get('onChange')(cleanFieldString(ttAddress.address.streetName), options);
      options.valuePath = this.get('streetAddressPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.streetName), options);
      options.valuePath = this.get('localityPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.municipality), options);
      options.valuePath = this.get('regionPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.countrySubdivisionName), options);
      options.valuePath = this.get('subRegionPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.countrySecondarySubdivision), options);
      options.valuePath = this.get('postalCodePath');
      this.get('onChange')(cleanFieldString(ttAddress.address.postalCode), options);
      options.valuePath = this.get('countryPath');
      this.get('onChange')(cleanFieldString(ttAddress.address.countryCode), options);

      if (this.get('freeformAddressPath')) {
        options.valuePath = this.get('freeformAddressPath');
        this.get('onChange')(cleanFieldString(ttAddress.address.freeformAddress), options);
      }

      options.valuePath = 'location.lat';
      this.get('onChange')(ttAddress.position.lat, options);
      options.valuePath = 'location.lng';
      this.get('onChange')(ttAddress.position.lon, options);
      options.valuePath = 'location.geoNode';
      this.get('onChange')(ttAddress.position.geoNode, options); // TODO: We need to deal with how zone name is populated

      if (Ember.isNone(record.get('address.zoneName.id'))) {
        record.set('address.zoneName', this.get('store').peekAll('zone-type').get('firstObject'));
      }

      return geocode.getGeonode(ttAddress.address.freeformAddress).then(result => {
        if (!Ember.isNone(result)) {
          options.valuePath = 'location.geoNode';
          this.get('onChange')(result.geonode, options);
        } else {
          this.clearAddressModel(record);
          this.get('notifications').warning(`CAN'T GET GEONODE FOR ${ttAddress.address.freeformAddress}`);
        }

        geocode.activateGeocode(false, record, markerType);
        this.validateAddresses();
        this.set('addressOptions', ttAddress);
      });
    },
    clearAddressModel: function (record) {
      const useOptionRecord = this.get('useOptionRecord');
      const valuePath = this.get('streetNumberPath');
      const addressTypeOption = this.get('addressTypeOption');
      const options = {
        record,
        valuePath,
        useOptionRecord,
        addressTypeOption
      };
      this.get('onChange')('', options);
      options.valuePath = this.get('streetNamePath');
      this.get('onChange')('', options);
      options.valuePath = this.get('localityPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('regionPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('subRegionPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('postalCodePath');
      this.get('onChange')('', options);
      options.valuePath = this.get('countryPath');
      this.get('onChange')('', options);
      options.valuePath = this.get('freeformAddressPath');
      this.get('onChange')('', options);
      options.valuePath = 'location.lat';
      this.get('onChange')('', options);
      options.valuePath = 'location.lng';
      this.get('onChange')('', options);
      options.valuePath = 'location.geoNode';
      this.get('onChange')('', options);
      record.set('lat', 0);
      record.set('lng', 0);
    },

    enableAddressSearchOnMap(record, markerType) {
      Ember.$('html,body,.tomtom-map').addClass('custom-cursor');
      this.get('geocode').activateGeocode(true, record, markerType);
      this.set('addressRecord', record);
      this.addObserver('geocode.tomtomAddress', this, 'geocodeAddressChange');
    },

    disableAddressSearchOnMap(record, markerType) {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
      this.get('geocode').activateGeocode(false, record, markerType);
      this.set('addressRecord', null);
      this.removeObserver('geocode.tomtomAddress', this, 'geocodeAddressChange');
    },

    /**
     * address's position contains empty lat, lng, and lon .. need to check all values empty..
     * Though correct address is selected, saveNewGeocode  is throwing error;
     * @param record
     * @returns {boolean}
     */
    isEmptyPosition(record) {
      const position = record.get('address.position');
      return Ember.isEmpty(position) || !position.lat && !position.lng && !position.lon;
    },

    actions: {
      setValue(value) {
        this.set('value', value);
        this.setAddress();
      },

      onInput(record, valuePath, value) {
        const options = {
          record,
          valuePath
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(value, options);
      },

      onAddressSelected(record, valuePath, value) {
        if (this.get('useRecordWithId') && !Ember.isNone(value.get('id'))) {
          this.set('record.place', value);
          this.set('addresses', [value]);
        } else {
          if (!(0, _zoneValidation.insideZone)([value.position.lat, value.position.lon])) {
            this.get('notifications').warning('Invalid Zone');
            return;
          }

          this.convertTTAddressToModel(record, value).then(() => {
            // reposition flag
            if (!this.isEmptyPosition(record)) {
              this.get('geocode').saveNewGeocode(record.get('address.position'), value);
            }
          });
        }
      },

      onPickOnMap(record) {
        const activeGeocode = this.get('geocode.activeGeo');
        const markerType = this.get('markerType');

        if (activeGeocode) {
          this.disableAddressSearchOnMap(record, markerType);
        } else {
          this.enableAddressSearchOnMap(record, markerType);
        }
      },

      handleKeydown(record, valuePath, dropdown, e) {
        if (e.keyCode !== 13) {
          return;
        }

        const text = e.target.value;
        const options = {
          record,
          valuePath: 'streetAddress'
        };
        this.clearAddressModel(record);
        this.get('onChange')(text, options);
        this.set('addressRecord', record);
        this.validateAddresses();
      }

    }
  });

  _exports.default = _default;
});