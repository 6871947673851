define("adept-iq/pods/components/side-drawer/scheduling-add-break/component", ["exports", "adept-iq/utils/unwrapProxy", "ember-changeset", "moment", "ember-concurrency", "adept-iq/models/break-type", "adept-iq/mixins/async-schedule-operation", "adept-iq/pods/components/side-drawer/scheduling-add-break/config"], function (_exports, _unwrapProxy, _emberChangeset, _moment, _emberConcurrency, _breakType, _asyncScheduleOperation, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    classNames: ['scheduling-add-break-side-drawer'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    onShrinkClick: null,
    onCloseClick: null,
    currentState: null,
    route: Ember.computed.readOnly('stackFrame.options.route'),
    breakTypes: null,
    fields: _config.fields,
    isFloatingBreakType: Ember.computed.equal('routeBreakChangeSet.breakType.name', _breakType.FLOATING_BREAK),
    isFixedBreakType: Ember.computed.equal('routeBreakChangeSet.breakType.name', _breakType.FIXED_BREAK),
    routeBreakChangeSet: Ember.computed('route', function () {
      const route = this.get('route');
      return this.setDefaultProperties(route);
    }),
    showClearButton: Ember.computed('routeBreakChangeSet.requestTime', function () {
      const requestTime = (0, _moment.default)(this.get('routeBreakChangeSet.requestTime'));
      return requestTime.isValid();
    }),
    calcEndTime: Ember.computed('routeBreakChangeSet.{requestTime,plannedDuration}', function () {
      const requestTime = this.get('routeBreakChangeSet.requestTime');
      const plannedDuration = parseInt(this.get('routeBreakChangeSet.plannedDuration'), 10) || 0;
      const calcEndTime = new Date(requestTime);

      if (Ember.isEmpty(requestTime)) {
        return null;
      }

      calcEndTime.setMinutes(requestTime.getMinutes() + plannedDuration);
      return calcEndTime;
    }),
    plannedDurationSeconds: Ember.computed('routeBreakChangeSet.plannedDuration', function () {
      const plannedDuration = this.get('routeBreakChangeSet.plannedDuration') ? this.get('routeBreakChangeSet.plannedDuration') : '0'; //convert plannedDuration into seconds.Payload request except  plannedDuration in seconds

      return Math.floor(plannedDuration * 60);
    }),
    projectedEndDate: Ember.computed('routeBreakChangeSet.{plannedDuration,requestTime}', function () {
      const plannedDuration = this.get('routeBreakChangeSet.plannedDuration') ? this.get('routeBreakChangeSet.plannedDuration') : '0';
      const requestTime = this.get('routeBreakChangeSet.requestTime');
      if (!requestTime) return;
      const requestTimeMoment = (0, _moment.default)(requestTime);
      const estimatedEndMoment = requestTimeMoment.clone();
      return estimatedEndMoment.add(parseInt(plannedDuration, 10), 'minutes').toDate();
    }),

    isValidateDriverBreak() {
      const errors = [];
      const breakTypeName = this.get('routeBreakChangeSet.breakType.name');
      const location = (0, _unwrapProxy.unwrapProxy)(this.get('routeBreakChangeSet.place.location'));
      const plannedDuration = this.get('routeBreakChangeSet.plannedDuration');
      const requestTime = (0, _moment.default)(this.get('routeBreakChangeSet.requestTime'));
      const routePlannedStartTime = (0, _moment.default)(this.get('routeBreakChangeSet.route.plannedStartTime'));
      const routeActualEndTime = (0, _moment.default)(this.get('routeBreakChangeSet.route.plannedEndTime'));

      if (!plannedDuration) {
        errors.push('Duration is Required');
      }

      if (!requestTime.isValid()) {
        errors.push('Start Time is required');
      }

      if (!breakTypeName) {
        errors.push('Break type is required');
      }

      if (breakTypeName && breakTypeName === _breakType.FIXED_BREAK && (!location || Ember.isEmpty(location.get('lat')))) {
        errors.push('Address for fixed break is required');
      }

      if (requestTime.isValid() && requestTime.isBefore(routePlannedStartTime)) {
        errors.push('Break start time must be after the route\'s planned start time.');
      }

      if (requestTime.isAfter(routeActualEndTime)) {
        errors.push('Break end time must be before the route\'s actual end time.');
      }

      this.set('errors', errors);
      return !errors.length;
    },

    didInsertElement() {
      const breakTypes = this.get('store').peekAll('break-type');
      this.set('breakTypes', breakTypes); // side panel width for add-break

      Ember.$('.side-drawer-modal').width('600px');
      this.loadCurrentState();
    },

    loadCurrentState() {
      const currentState = this.get('currentState');
      const routeBreakChangeSet = this.get('routeBreakChangeSet');

      if (currentState) {
        routeBreakChangeSet.set('breakType', currentState.breakType);
        routeBreakChangeSet.set('place', currentState.place);
        routeBreakChangeSet.set('requestTime', currentState.requestTime);
        routeBreakChangeSet.set('plannedDuration', currentState.plannedDuration);
        routeBreakChangeSet.set('notes', currentState.notes);
        routeBreakChangeSet.execute();
      }
    },

    //set default requested time is 5 minutes greater than  plannedStartTime
    getRequestedTime(plannedStartTime) {
      const year = plannedStartTime.getFullYear();
      const month = plannedStartTime.getMonth();
      const day = plannedStartTime.getDate();
      const hours = plannedStartTime.getHours();
      const minutes = plannedStartTime.getMinutes() + 5;
      const seconds = plannedStartTime.getSeconds();
      return new Date(year, month, day, hours, minutes, seconds);
    },

    setDefaultProperties(record) {
      const trips = (0, _unwrapProxy.unwrapProxy)(record.get('trips'));
      const routeBreaks = (0, _unwrapProxy.unwrapProxy)(record.get('routeBreaks'));
      const plannedDuration = 15;
      const requestTime = this.getRequestedTime(record.get('plannedStartTime'));
      const floatingBreakType = this.get('store').peekAll('break-type').findBy('id', _breakType.FLOATING_BREAK);
      const tripStops = trips.reduce((arr, trip) => {
        const stops = trip.get('stops');
        stops.forEach(stop => arr.push(stop));
        return arr;
      }, []);
      let lastStop = tripStops.sortBy('plannedRouteOrdinal').get('lastObject'); // if no-trip-stop is present find a last route-break by plannedRouteOrdinal

      if (!lastStop) {
        lastStop = routeBreaks.sortBy('plannedRouteOrdinal').get('lastObject');
      }

      const driverBreak = Ember.Object.create({
        estimatedStart: new Date(),
        estimatedEnd: new Date(),
        requestTime,
        route: record,
        schedule: record.get('schedule'),
        notes: '',
        plannedDuration: plannedDuration,
        promisedStart: new Date(),
        place: null,
        breakType: floatingBreakType,
        plannedRouteOrdinal: lastStop ? lastStop.get('plannedRouteOrdinal') + 1 : 1,
        state: 'planned',
        status: 'scheduled'
      });
      const placeModel = this.getDefaultPlaceModel();
      driverBreak.set('place', placeModel);
      this.set('pendingRecord', driverBreak);
      const pendingRecord = this.get('pendingRecord');
      return new _emberChangeset.default(pendingRecord);
    },

    routeBreakAsync: (0, _emberConcurrency.task)(function* (location) {
      const routeBreakChangeSet = this.get('routeBreakChangeSet');
      const route = (0, _unwrapProxy.unwrapProxy)(routeBreakChangeSet.get('route'));
      const schedule = (0, _unwrapProxy.unwrapProxy)(routeBreakChangeSet.get('route.schedule'));
      const scheduleId = parseInt(schedule.get('id'), 10);
      const destinationRouteId = parseInt(route.get('id'), 10);
      const requestTime = (0, _moment.default)(routeBreakChangeSet.get('requestTime'));
      const plannedDuration = routeBreakChangeSet.get('plannedDurationSeconds');
      let result;

      try {
        const operationData = {
          scheduleId,
          destinationRouteId,
          break: {
            requestTime: requestTime.format(),
            plannedDuration
          }
        };

        if (this.get('isFixedBreakType')) {
          operationData.break.breakStop = {
            xyCoord: {
              x: location.get('lat'),
              y: location.get('lng')
            },
            geoNode: parseInt(location.get('geoNode'), 10)
          };
        }

        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'addBreak', options);

        if (!result) {
          throw new Error('Unable to insert break.');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),

    getDefaultPlaceModel() {
      const store = this.get('store');
      const locationModel = store.createRecord('location');
      const addressModel = store.createRecord('address', {
        locations: [locationModel]
      });
      const placeModel = store.createRecord('place', {
        address: addressModel,
        location: locationModel
      });
      return placeModel;
    },

    validateBreakTime() {
      const tooltip = this.get('tooltip');
      const workspace = this.get('workspace');
      const routeBreakChangeSet = this.get('routeBreakChangeSet');
      const route = (0, _unwrapProxy.unwrapProxy)(routeBreakChangeSet.get('route'));
      const trips = route.get('trips').map(t => t).sort((a, b) => a.get('drop.plannedEta') - b.get('drop.plannedEta'));
      const breakStartTime = (0, _moment.default)(routeBreakChangeSet.get('requestTime'));
      const isFloatingBreakType = this.get('isFloatingBreakType');
      const isBreakInMiddleOfTrip = workspace.get('isBreakInMiddleOfTrip').bind(workspace);
      const isValidBreakTime = workspace.get('isValidBreakTime').bind(workspace);
      const breakEndTime = (0, _moment.default)(this.get('projectedEndDate'));
      const breakMiddleInfo = {
        trips: trips,
        breakTime: breakStartTime,
        pickKey: 'pick',
        dropKey: 'drop',
        plannedEta: 'plannedEta'
      };
      this.set('invalidRequestTime', false);

      if (isBreakInMiddleOfTrip(breakMiddleInfo)) {
        tooltip.pushConfirmation({
          tip: 'Unable to save. Adding the break will place the break in the middle of a trip.',
          title: 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      const breakTimeInfo = {
        trips: trips,
        breakEndTime,
        pickKey: 'pick',
        dropKey: 'drop',
        plannedEta: 'plannedEta',
        nextTravelTime: 'plannedTravelTimeToNext'
      };

      if (isFloatingBreakType && !isValidBreakTime(breakTimeInfo)) {
        tooltip.pushConfirmation({
          tip: 'Unable to save. Adding the break results in not enough time to travel from break to the next stop.',
          title: 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      breakMiddleInfo.breakTime = breakEndTime;

      if (isBreakInMiddleOfTrip(breakMiddleInfo)) {
        tooltip.pushConfirmation({
          tip: 'Unable to save. The break\'s end time will extend past the ETA of the stop after the break',
          title: 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      return true;
    },

    async validateBreakPlace() {
      const workspace = this.get('workspace');
      const tooltip = this.get('tooltip');
      const routeBreakChangeSet = this.get('routeBreakChangeSet');
      const route = (0, _unwrapProxy.unwrapProxy)(routeBreakChangeSet.get('route'));
      const trips = route.get('trips').map(t => t).sort((a, b) => a.get('drop.plannedEta') - b.get('drop.plannedEta'));
      const breakEndTime = (0, _moment.default)(this.get('projectedEndDate'));
      const validaBreakPlace = workspace.get('validaBreakPlace').bind(workspace);
      const breakPlace = routeBreakChangeSet.get('place');
      const breakTimeInfo = {
        trips: trips,
        breakEndTime,
        pickKey: 'pick',
        dropKey: 'drop',
        plannedEta: 'plannedEta',
        breakPlace
      };
      const isValidBreakPlace = await validaBreakPlace(breakTimeInfo);
      this.set('invalidRequestTime', false);

      if (!isValidBreakPlace) {
        tooltip.pushConfirmation({
          tip: 'Unable to save. Adding the break results in not enough time to travel from break to the next stop.',
          title: 'Add Driver Break',
          primaryActionText: 'OK',
          hasOverlay: true,
          primaryAction: () => {
            tooltip.reset();
          }
        });
        this.set('invalidRequestTime', true);
        return false;
      }

      return true;
    },

    actions: {
      onBreakTypeChange(val) {
        const record = this.get('routeBreakChangeSet');
        record.set('breakType', val);
        const routeBreakType = val.name;

        if (routeBreakType === _breakType.FLOATING_BREAK) {
          const placeModel = this.getDefaultPlaceModel();
          record.set('place', placeModel);
          record.execute();
        }
      },

      onCellValueChange(record, valuePath, value, options) {
        record.set(`${valuePath}.${options.valuePath}`, value);
        record.execute();
      },

      onChangeTime(record, valuePath, value) {
        const momentTime = (0, _moment.default)(value);
        const dateValue = momentTime.isValid() ? momentTime.toDate() : null;
        record.set(valuePath, dateValue);
      },

      onRefreshButtonClick() {
        const routeBreakChangeSet = this.get('routeBreakChangeSet');
        const placeModel = this.getDefaultPlaceModel();
        const floatingBreakType = this.get('store').peekAll('break-type').findBy('id', _breakType.FLOATING_BREAK);
        const requestTime = this.getRequestedTime(routeBreakChangeSet.get('route.plannedStartTime'));
        routeBreakChangeSet.set('breakType', floatingBreakType);
        routeBreakChangeSet.set('place', placeModel);
        routeBreakChangeSet.set('requestTime', requestTime);
        routeBreakChangeSet.set('plannedDuration', 15);
        routeBreakChangeSet.set('notes', null);
        routeBreakChangeSet.execute();
      },

      async onSaveClick() {
        const store = this.get('store');
        const title = 'Add Break';
        const locationAdapter = store.adapterFor('location');
        const addressAdapter = store.adapterFor('address');
        const placeAdapter = store.adapterFor('place');
        const record = this.get('routeBreakChangeSet');
        const requestTime = record.get('requestTime');
        const plannedDurationSeconds = this.get('plannedDurationSeconds');
        const estimatedEnd = this.get('projectedEndDate');
        const notifications = this.get('notifications');
        const tooltip = this.get('tooltip');
        const routeBreakAsync = this.get('routeBreakAsync');
        const isFixedBreakType = this.get('isFixedBreakType');
        const onCloseClick = this.get('onCloseClick');
        const refreshTableContent = this.get('activeContext.topActiveContext').get('refreshTableContent');
        if (!this.isValidateDriverBreak() || !this.validateBreakTime()) return;
        const isValidBreakPlace = this.get('isFixedBreakType') ? await this.validateBreakPlace() : true;
        if (isFixedBreakType && !isValidBreakPlace) return;
        record.set('plannedDurationSeconds', plannedDurationSeconds);
        record.set('estimatedStart', requestTime);
        record.set('estimatedEnd', estimatedEnd);
        const place = (0, _unwrapProxy.unwrapProxy)(record.get('place'));
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));

        if (this.get('isFixedBreakType')) {
          const locationResponse = await locationAdapter.postLocation(location);
          const addressResponse = await addressAdapter.postAddress(address);
          await addressAdapter.postAddressLocation(locationResponse, addressResponse);
          await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
        }

        return tooltip.pushConfirmation({
          tip: 'Would you like to commit your changes?',
          title,
          primaryActionText: 'Commit',
          primaryAction: () => {
            return routeBreakAsync.perform(location).then(() => {
              notifications.success(`Break successfully added to route ${record.get('route.name')}`);
              onCloseClick();
              refreshTableContent.perform(['route', 'route-break']);
            }).catch(() => {
              notifications.warning(`Break failed to be added to route ${record.get('route.name')}`);
            }).finally(() => {
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',

          secondaryAction() {
            return tooltip.reset();
          }

        });
      },

      onShrinkClick() {
        let currentState = this.get('currentState');
        const record = this.get('routeBreakChangeSet');
        const breakType = record.get('breakType');
        const place = record.get('place');
        const requestTime = record.get('requestTime');
        const plannedDuration = record.get('plannedDuration');
        const notes = record.get('notes'); // save current manual settings

        currentState = {
          breakType,
          place,
          requestTime,
          plannedDuration,
          notes
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      }

    }
  });

  _exports.default = _default;
});