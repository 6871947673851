define("adept-iq/pods/components/iq-widgets/map-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/map-widget/component", "adept-iq/classes/data-joins/map/marker", "adept-iq/classes/data-joins/map/polyline", "adept-iq/classes/data-joins/map/shape", "adept-iq/pods/components/iq-widgets/map-widget/config/polyline", "tomtom"], function (_exports, _component, _marker, _polyline, _shape, _polyline2, _tomtom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RecenterControl = void 0;
  const config = {
    title: 'Map'
  };
  const recenterControlOptions = {
    position: 'topleft',
    recenterAction: null
  };

  var RecenterControl = _tomtom.default.L.Control.extend({
    options: recenterControlOptions,
    onAdd: function ()
    /* map */
    {
      const name = 'leaflet-control-recenter';

      const container = _tomtom.default.L.DomUtil.create('div', 'leaflet-control-recenter leaflet-bar');

      this._recenterButton = this._createButton('<i class="fa fa-crosshairs fa-lg crosshairs-big" aria-hidden="true"></i>', 'Recenter', name, container, this._recenterMap);
      return container;
    },
    _recenterMap: function () {
      this.options.mapWidget.onRecenterClick();
    },
    _createButton: function (html, title, className, container, fn) {
      var link = _tomtom.default.L.DomUtil.create('a', className, container);

      link.innerHTML = html;
      link.href = '#';
      link.title = title;
      /*
      * Will force screen readers like VoiceOver to read this as "Zoom in - button"
      */

      link.setAttribute('role', 'button');
      link.setAttribute('aria-label', title);

      _tomtom.default.L.DomEvent.disableClickPropagation(link);

      _tomtom.default.L.DomEvent.on(link, 'click', _tomtom.default.L.DomEvent.stop);

      _tomtom.default.L.DomEvent.on(link, 'click', fn, this);

      _tomtom.default.L.DomEvent.on(link, 'click', this._refocusOnMap, this);

      return link;
    }
  });

  _exports.RecenterControl = RecenterControl;

  var _default = _component.default.extend(Ember.Evented, {
    mapZoomMethodHandler: null,
    activeContext: Ember.inject.service(),
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    mapService: Ember.inject.service('map'),
    // not to be confused with tomtom `map`!
    maximizer: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    address: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    work: Ember.inject.service(),
    config,
    locationControl: null,
    markerDataJoin: null,
    polylineDataJoin: null,
    isAddressSearchAllowed: false,
    searchMode: null,
    // `null`, 'filter', or 'address'
    addressSearchText: '',
    selectedAddress: null,
    componentName: null,
    isAddressSearchEnabled: Ember.computed.equal('searchMode', 'address'),
    isNavigateToEnabled: Ember.computed.bool('selectedAddress'),
    lat: Ember.computed.alias('mapContext.lat'),
    lng: Ember.computed.alias('mapContext.lng'),
    isVisible: Ember.computed.alias('mapContext.isVisible'),
    agencyMarkers: Ember.computed.alias('mapContext.agencyMarkers'),
    shapeDataJoin: null,
    contextMenuPosition: null,
    contextMenuRecord: null,
    contextMenu: null,
    _mapContext: null,
    distanceAllowed: true,
    isComputedDistance: false,
    isComputingDistance: false,
    distancePt1: null,
    distancePt2: null,
    distancePolyline: null,
    computedDistance: null,
    // all map widgets are bound to the top context in stack
    mapContext: Ember.computed.readOnly('mapService.topMapContext'),
    vehicleCluster: Ember.computed.alias('mapContext.vehicleCluster'),
    layers: Ember.computed.readOnly('mapContext.layers'),
    markers: Ember.computed.readOnly('mapContext.markers'),
    bounds: Ember.computed.readOnly('mapContext.bounds'),
    boundsID: Ember.computed.readOnly('mapContext.boundsID'),
    polylines: Ember.computed.readOnly('mapContext.polylines'),
    shapes: Ember.computed.alias('mapContext.shapes'),
    centerLat: Ember.computed.oneWay('mapContext.centerLat'),
    centerLng: Ember.computed.oneWay('mapContext.centerLng'),
    isSearchAllowed: Ember.computed.readOnly('mapContext.isSearchAllowed'),
    isSearchEnabled: Ember.computed.readOnly('mapContext.isSearchEnabled'),
    searchText: Ember.computed.readOnly('mapContext.searchText'),
    isFilterAllowed: Ember.computed.readOnly('mapContext.isFilterAllowed'),
    isFiltered: Ember.computed.readOnly('mapContext.isFiltered'),
    contextMenuOptions: Ember.computed.readOnly('mapContext.contextMenuOptions'),
    clearButtonEnabled: Ember.computed.gt('activeContext.checkedItems.length', 0),

    init() {
      this._super(...arguments);

      const typeId = this.get('widget.typeId');
      this.set('componentName', `${typeId}Widget`); // callback wrappers

      this._onMapContextRefresh = () => this.onMapContextRefresh();

      this._onContextMenu = (e, marker) => this.onContextMenu(e, marker);

      this._mapZoomMethodHandler = e => this.mapZoomMethod(e); // initialize observer


      this.mapContextChanged();
    },

    destroy() {
      this._onMapContextRefresh = null;
      this._onContextMenu = null;
      this._mapZoomMethodHandler = null;

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments); //TODO FIXME - hack for today


      this.set('isNavigateToAllowed', true);
      this.set('isSearchAddressEnabled', true);

      if (this.user.isRoadSupEnable()) {
        this.set('isAddressSearchAllowed', true);
      } // set up location control; map was initialized in `_super`


      const map = this.get('map');
      const vehicleCluster = this.get('vehicleCluster');
      const locationControl = new RecenterControl({
        mapWidget: this
      });
      locationControl.addTo(map);
      this.set('locationControl', locationControl);
      map.on('zoom', this._mapZoomMethodHandler);

      _tomtom.default.L.control.scale({
        position: 'bottomright'
      }).addTo(map);

      _tomtom.default.L.DomEvent.on(map._container, 'wheel', this._didWheelZoom); // build data joins


      const work = this.get('work');
      const workspace = this.get('workspace');
      const mapService = this.get('mapService'); // add cluster group to map

      if (map) {
        map.addLayer(vehicleCluster);
      }

      const markerDataJoin = _marker.default.create({
        map,
        mapService,
        work,
        workspace,
        vehicleCluster,
        onContextMenu: this._onContextMenu
      });

      const polylineDataJoin = _polyline.default.create({
        map,
        mapService,
        work,
        workspace,
        onContextMenu: this._onContextMenu
      }); // this one doesn't use context menu (yet)


      const shapeDataJoin = _shape.default.create({
        map,
        mapService,
        work,
        workspace
      });

      this.set('markerDataJoin', markerDataJoin);
      this.set('polylineDataJoin', polylineDataJoin);
      this.set('shapeDataJoin', shapeDataJoin); // schedule initial map render

      Ember.run.scheduleOnce('afterRender', this, 'updateMap');
      Ember.run.scheduleOnce('afterRender', this, 'activeGeocode');
      Ember.run.scheduleOnce('afterRender', this, 'updateMapView');
      this.updateDashboardMap(map);
      this.$('a').removeAttr('title');
    },

    willDestroyElement() {
      const map = this.get('map');
      this.get('locationControl').remove(); // clear join memory

      this.get('markerDataJoin').destroy();
      this.get('polylineDataJoin').destroy();
      this.get('shapeDataJoin').destroy(); // remove map context refresh listener, but don't destroy context

      const mapContext = this.get('_mapContext');

      if (mapContext) {
        mapContext.off('refresh', this._onMapContextRefresh);
      }

      _tomtom.default.L.DomEvent.off(map._container, 'wheel', this._didWheelZoom); // template caches this on the component


      this.set('contextMenu', null);

      this._super(...arguments);
    },

    mapContextChanged: Ember.observer('mapContext', function () {
      const oldMapContext = this.get('_mapContext');
      const newMapContext = this.get('mapContext'); // need to swap out refresh listener!

      if (oldMapContext) {
        oldMapContext.off('refresh', this._onMapContextRefresh);
      }

      if (newMapContext) {
        newMapContext.on('refresh', this._onMapContextRefresh);
      }

      this.set('_mapContext', newMapContext); // force a refresh immediately

      this.onMapContextRefresh();
      this.activeGeocode();
    }),

    onMapContextRefresh() {
      Ember.run.scheduleOnce('afterRender', this, 'updateMap');
    },

    mapZoomMethod(event) {
      const map = event.target;
      var zoom = map.getZoom();
      const geocodeService = this.get('geocode');
      geocodeService.set('zoom', zoom);
    },

    // TODO: refactor; all of these `geocode`-related observers modify the map
    // directly, which will bleed state. The features that depend on these should
    // be refactored to use a custom map context instead.
    activeGeocode: Ember.observer('geocode.activeGeo', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');
      const activeGeocode = geocodeService.get('activeGeo');
      if (!map) return;

      if (activeGeocode) {
        map.on('click', function (event) {
          geocodeService.saveNewGeocode(event.latlng);
        });
      } else {
        map.off('click');
      }
    }),
    activeMarker: Ember.observer('geocode.activeMarker', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');

      if (geocodeService.get('oldMarker')) {
        geocodeService.get('oldMarker').remove();
      }

      if (geocodeService.get('activeMarker')) {
        const marker = geocodeService.get('activeMarker');
        marker.addTo(map);
        map.setView(marker._latlng, geocodeService.get('zoom'));
      }
    }),
    activePickMarker: Ember.observer('geocode.activePickMarker', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');

      if (geocodeService.get('oldPickMarker')) {
        geocodeService.get('oldPickMarker').remove();
      }

      if (geocodeService.get('activePickMarker')) {
        const marker = geocodeService.get('activePickMarker');
        marker.addTo(map);
        map.setView(marker._latlng, geocodeService.get('zoom'));
      }
    }),
    activeDropMarker: Ember.observer('geocode.activeDropMarker', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');

      if (geocodeService.get('oldDropMarker')) {
        geocodeService.get('oldDropMarker').remove();
      }

      if (geocodeService.get('activeDropMarker')) {
        const marker = geocodeService.get('activeDropMarker');
        marker.addTo(map);
        map.setView(marker._latlng, geocodeService.get('zoom'));
      }
    }),
    activeTripPolyline: Ember.observer('geocode.activeTripPolyline', function () {
      const map = this.get('map');
      const geocodeService = this.get('geocode');

      if (geocodeService.get('oldTripPolyline')) {
        geocodeService.get('oldTripPolyline').remove();
      }

      if (geocodeService.get('oldTripArrow')) {
        geocodeService.get('oldTripArrow').remove();
      }

      if (geocodeService.get('activeTripPolyline')) {
        const tripPolyline = geocodeService.get('activeTripPolyline');
        const tripArrow = geocodeService.get('activeTripArrow');
        tripPolyline.addTo(map);
        tripArrow.addTo(map);
      }
    }),

    updateMap() {
      // don't try to update until map is initialized
      if (!this.get('map')) return;
      const markerDataJoin = this.get('markerDataJoin');
      const polylineDataJoin = this.get('polylineDataJoin');
      const shapeDataJoin = this.get('shapeDataJoin');
      const markers = this.get('markers') || [];
      const polylines = this.get('polylines') || [];
      const shapes = this.get('shapes') || [];
      markerDataJoin.join(markers);
      polylineDataJoin.join(polylines);
      shapeDataJoin.join(shapes);
      const map = this.get('map');
      map.on('zoom', this._mapZoomMethodHandler);
    },

    onRecenterClick() {
      this.set('autoZoom', false);
      return this.reCenterMapView(true);
    },

    onContextMenu(e, markerOrPolyline) {
      // available options are determined in the map context; this method is only
      // responsible for positioning and opening the context menu
      this.set('contextMenuPosition', function () {
        return {
          style: {
            top: e.originalEvent.clientY,
            left: e.originalEvent.clientX
          }
        };
      });
      const record = markerOrPolyline.get('record');
      this.set('contextMenuRecord', record);
      this.get('contextMenu').actions.open();
    },

    markerPinPoint(coord) {
      const map = this.get('map');
      const pintPointIcon = {
        icon: _tomtom.default.L.icon({
          iconUrl: '/map-icons/icon-pinpoint.svg',
          iconSize: [28, 28],
          iconAnchor: [14, 28]
        }),
        keyboard: false,
        interactive: false
      };
      return _tomtom.default.L.marker(coord, pintPointIcon).addTo(map);
    },

    setDistances(coord) {
      if (this.get('distancePt1') === null) {
        this.set('distancePt1', this.markerPinPoint(coord));
      } else if (this.get('distancePt2') === null) {
        this.set('distancePt2', this.markerPinPoint(coord));
        let distance = geolib.getDistance(this.get('distancePt1._latlng'), coord); // eslint-disable-line
        //convert from metre -> feet -> mile

        distance = distance * 3.281 / 5280;
        this.set('computedDistance', `${distance.toFixed(3)} mi`);
        const polyLineOptions = _polyline2.default['dottedDistance']; // eslint-disable-line

        const distancePoints = [[this.get('distancePt1._latlng.lat'), this.get('distancePt1._latlng.lng')], [this.get('distancePt2._latlng.lat'), this.get('distancePt2._latlng.lng')]];

        const distancePolyline = _tomtom.default.L.polyline(distancePoints, polyLineOptions).addTo(this.get('map'));

        this.set('distancePolyline', distancePolyline);
      } else {
        this.distanceReset();
      }
    },

    distanceReset() {
      if (this.get('distancePt1')) {
        this.get('distancePt1').remove();
      }

      if (this.get('distancePt2')) {
        this.get('distancePt2').remove();
      }

      if (this.get('distancePolyline')) {
        this.get('distancePolyline').remove();
      }

      this.setProperties({
        distancePt1: null,
        distancePt2: null,
        computedDistance: null,
        distancePolyline: null
      });
    },

    updateDashboardMap(map) {
      if (map) {
        const fetchLayerIds = [];
        map.eachLayer(layer => {
          fetchLayerIds.push(layer._leaflet_id);
        });
        Ember.set(this, 'workspace.activeContext.topActiveContext.map', map);
        Ember.set(this, 'workspace.activeContext.topActiveContext.mapResetLayers', fetchLayerIds);
      }
    },

    actions: {
      onSearchButtonClick() {
        this.toggleProperty('mapContext.isSearchEnabled');
        this.set('mapContext.searchText', '');
        Ember.run.scheduleOnce('afterRender', () => {
          if (this.get('isSearchEnabled')) {
            this.$('.map-widget-search-box').focus();
          }
        });
      },

      onAddressSearchButtonClick() {
        if (this.get('searchMode') === 'address') {
          this.set('searchMode', null);
        } else {
          this.set('searchMode', 'address'); // start fresh each time

          this.set('addressSearchText', '');
          this.set('selectedAddress', null);
        }

        Ember.run.scheduleOnce('afterRender', () => {
          if (this.get('isAddressSearchEnabled')) {
            this.$('.map-widget-location-search-box').focus();
          }
        });
      },

      onRemoveSearchClick() {
        this.set('mapContext.searchText', '');
        this.set('addressSearchText', '');
        this.set('selectedAddress', null);
        Ember.run.scheduleOnce('afterRender', this, 'updateMap');
      },

      onNavigateToClick() {
        const address = this.get('selectedAddress');
        this.get('roadSupervisor').navigateToAddressFromMap(address);
      },

      onSearchTextChange(searchText) {
        const previousSearchText = this.get('searchText');
        if (previousSearchText === searchText) return;
        this.set('mapContext.searchText', searchText);
        Ember.run.scheduleOnce('afterRender', this, 'updateMap');
      },

      onAddressSelect(address, close) {
        this.set('selectedAddress', address);
        if (close) close();
      },

      onFilterButtonClick() {
        this.get('workspace').pushState('filterMapWidget', {
          displayName: 'Map Layers'
        }, {
          replaceSelf: true
        });
      },

      setContextMenu(dropdown) {
        this.set('contextMenu', dropdown);
      },

      onContextMenuOptionClick(option) {
        const record = this.get('contextMenuRecord');
        this.get('contextMenu').actions.close();

        if (option.action) {
          option.action(record);
        }
      },

      onHeaderDoubleClick(event) {
        const button = event.target.closest('button');
        const input = event.target.closest('input'); // don't maximize if user double-clicked a control

        if (button || input) return;
        const maximizer = this.get('maximizer');

        if (maximizer.maximizedWidget === this.get('widget')) {
          maximizer.minimize();
          return;
        }

        maximizer.maximize(this.get('widget'));
      },

      onExitMaximizedClick() {
        const maximizer = this.get('maximizer');
        maximizer.minimize();
      },

      onDistanceButtonClick() {
        this._bodyClickFunction = event => {
          this.actions.onBodyClickFunction(event, this);
        };

        document.body.addEventListener('click', this._bodyClickFunction);
        this.set('isComputingDistance', true);
        this.get('map').on('click', e => {
          this.setDistances(e.latlng);
        });
      },

      onBodyClickFunction(e, thisObject) {
        const node = e.target;
        let isInPage = true;

        if (document.getElementsByClassName('map-widget')[0]) {
          isInPage = document.getElementsByClassName('map-widget')[0].contains(node);
        }

        if (isInPage === false) {
          thisObject.distanceReset();
        }
      },

      onDistanceClose() {
        document.body.removeEventListener('click', this._bodyClickFunction);
        this.set('isComputingDistance', false);
        this.distanceReset();
        this.get('map').off('click');
      },

      onDistanceReset() {
        this.distanceReset();
      }

    }
  });

  _exports.default = _default;
});