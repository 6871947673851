define("adept-iq/pods/components/form-components/text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-components-text'],
    value: null,
    disabled: false,
    placeholder: null,
    extra: null,
    max: Ember.computed.readOnly('extra.max'),
    actions: {
      onInput(event) {
        const {
          value
        } = event.target;
        const max = this.get('max');

        if (max) {
          const maxVal = value.trim().slice(0, max);
          event.target.value = maxVal;
          this.get('onChange')(maxVal);
          return;
        }

        this.get('onChange')(value.trim());
      }

    }
  });

  _exports.default = _default;
});