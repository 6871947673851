define("adept-iq/pods/components/reconcile-search-passenger/component", ["exports", "ember-light-table", "adept-iq/pods/components/reconcile-search-passenger/config", "ember-concurrency"], function (_exports, _emberLightTable, _config, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    classNames: ['reconcile-search-passenger'],
    config: _config.default,
    onCloseClick: null,
    isEmptySearchTable: false,
    contextMenuCallback: null,
    activePassenger: 'Active',
    inactivePassenger: 'Inactive',
    updateReconcileSearchedPassenger: Ember.observer('reconcileSearchedPassenger', function () {
      this.set('isEmptySearchTable', false);
      const searchedPassenger = Ember.get(this, 'reconcileSearchedPassenger');
      this.set('searchedPassenger', searchedPassenger);
      this.get('searchPassenger').setRows(searchedPassenger);

      if (Ember.get(this, 'searchPassenger').isEmpty) {
        this.set('isEmptySearchTable', true);
      }
    }),

    init() {
      this._super(...arguments);

      const searchPassenger = new _emberLightTable.default(_config.default.searchPassengerColumns);
      Ember.set(this, 'isDisabledSearch', true);
      Ember.set(this, 'isDisabledAddnewTrip', true);
      this.set('searchPassenger', searchPassenger);
      Ember.set(this, 'isActive', true);
    },

    fetchReconSearchedPassenger: (0, _emberConcurrency.task)(function* (options) {
      const searchedPassenger = [];
      const passArray = yield Ember.get(this, 'store').query('reconcile-passenger', options);

      if (Ember.isEmpty(passArray)) {
        Ember.set(this, 'reconcileSearchedPassenger', []);
        return;
      }

      passArray.map(data => {
        searchedPassenger.push(data);
      });
      Ember.set(this, 'reconcileSearchedPassenger', searchedPassenger);
    }),

    didInsertElement() {},

    createContextMenuOptions() {},

    willDestroyElement() {
      this._super(...arguments);
    },

    actions: {
      onCloseButtonClick() {
        this.onCloseClick();
      },

      onUndoButtonClick() {},

      onSearchTextChange(type, valuePath) {
        Ember.set(this, 'isDisableNamesField', false);
        Ember.set(this, 'isDisablePassField', false);
        Ember.set(this, 'isDisabledSearch', false);

        if (!valuePath) {
          this.get('searchPassenger').setRows([]);
          Ember.set(this, 'selectedRow', null);
          return;
        }

        switch (type) {
          case _config.default.PASSENGER_ID:
            Ember.set(this, 'passengerId', valuePath.trim());

            if (valuePath) {
              Ember.set(this, 'firstName', '');
              Ember.set(this, 'lastName', '');
              Ember.set(this, 'isDisableNamesField', true);
              this.send('searchTask');
            }

            break;

          case _config.default.FIRST_NAME:
            Ember.set(this, 'firstName', valuePath.trim());

            if (valuePath) {
              Ember.set(this, 'passengerId', '');
              Ember.set(this, 'isDisablePassField', true);
              this.send('searchTask');
            }

            break;

          case _config.default.LAST_NAME:
            Ember.set(this, 'lastName', valuePath.trim());

            if (valuePath) {
              Ember.set(this, 'passengerId', '');
              Ember.set(this, 'isDisablePassField', true);
              this.send('searchTask');
            }

            break;

          default:
        }
      },

      searchTask() {
        let options;
        const passengerId = Ember.get(this, 'passengerId');
        const firstName = Ember.get(this, 'firstName');
        const lastName = Ember.get(this, 'lastName');

        if (passengerId && (!firstName || !lastName)) {
          options = {
            passengerId: passengerId
          };
        } else if (!passengerId && (firstName || !lastName) && !Ember.get(this, 'isActive')) {
          options = {
            firstName: firstName
          };
        } else if (!passengerId && (!firstName || lastName) && !Ember.get(this, 'isActive')) {
          options = {
            lastName: lastName
          };
        } else if (!passengerId && firstName && !lastName && Ember.get(this, 'isActive')) {
          options = {
            firstName: firstName,
            status: this.activePassenger
          };
        } else if (!passengerId && !firstName && lastName && Ember.get(this, 'isActive')) {
          options = {
            lastName: lastName,
            status: this.activePassenger
          };
        }

        if (options) {
          Ember.get(this, 'fetchReconSearchedPassenger').perform(options);
        } else {
          this.get('searchPassenger').setRows([]);
          Ember.set(this, 'selectedRow', null);
        }
      },

      onRowClick(row) {
        if (row) {
          const selectedRow = {
            passengerId: row.get('passengerId'),
            firstName: row.get('firstName'),
            lastName: row.get('lastName'),
            phoneNumber: row.get('phoneNumber'),
            address: row.get('address'),
            travelNeeds: row.get('travelneeds')
          };
          Ember.set(this, 'selectedRow', selectedRow);
          Ember.set(this, 'isDisabledAddnewTrip', false);
        }
      },

      onAddNewTrip() {
        const selectedRow = this.get('selectedRow');

        if (selectedRow) {
          this.get('workspace').pushState('reconcileAddNewTrip', {
            selectedRow,
            action: this.contextMenuCallback
          });
          this.onCloseClick();
        }
      },

      resetSearch() {
        Ember.set(this, 'firstName', '');
        Ember.set(this, 'lastName', '');
        Ember.set(this, 'passengerId', '');
        this.get('searchPassenger').setRows([]);
        Ember.set(this, 'selectedRow', null);
        Ember.set(this, 'isDisableNamesField', false);
        Ember.set(this, 'isDisablePassField', false);
        Ember.set(this, 'isActive', true);
        Ember.set(this, 'isEmptySearchTable', false);
      },

      onCheckboxToggle(flag) {
        Ember.set(this, 'selectedRow', null);
        Ember.set(this, 'isActive', flag);
        const firstName = Ember.get(this, 'firstName');
        const lastName = Ember.get(this, 'lastName');

        if (firstName) {
          this.send('onSearchTextChange', _config.default.FIRST_NAME, firstName);
        }

        if (lastName) {
          this.send('onSearchTextChange', _config.default.LAST_NAME, lastName);
        }
      }

    }
  });

  _exports.default = _default;
});