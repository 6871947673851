define("adept-iq/classes/polylines/routes/performed/iq", ["exports", "adept-iq/classes/polylines/routes/performed/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // `BasePolyline` overrides
    isActive: true,
    isHidden: false,
    isRouteEnded: Ember.computed.alias('iqRoute.isPerformed'),
    points: Ember.computed.alias('iqRoute.performedPolylineDispatch'),
    inProgress: false,
    // local properties
    polylineService: null,
    record: null,
    iqRoute: Ember.computed.alias('record'),

    init() {
      this._super(...arguments);

      this.visibilityListener();
    },

    visibilityListener: Ember.observer('iqRoute.isPerformed', 'isHidden', 'isJoined', 'iqRoute.isPerformCoordEmpty', function () {
      if (!this.get('iqRoute.isPerformCoordEmpty') || this.get('inProgress') || !this.get('isRouteEnded')) return;
      this.set('inProgress', true);
      const dispatchRouteId = this.get('iqRoute.id');
      const routeId = this.get('iqRoute.route.id');
      this.get('polylineService.fetchPerformedPolyline').perform(routeId, dispatchRouteId).finally(() => {
        this.set('inProgress', false);
      });
    })
  });

  _exports.default = _default;
});