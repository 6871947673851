define("adept-iq/services/subscription", ["exports", "adept-iq/classes/json-api-serializer/JSONAPISerializer", "adept-iq/config/api-urls", "moment", "lodash"], function (_exports, _JSONAPISerializer, _apiUrls, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    booking: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    serializer: null,

    init() {
      this._super(...arguments);

      this.serializer = new _JSONAPISerializer.default(); //Register 'subscription' type

      this.serializer.register('subscription', {
        id: 'id',
        relationships: {
          originPlace: {
            type: 'place'
          },
          destinationPlace: {
            type: 'place'
          },
          rider: {
            type: 'rider'
          },
          group: {
            type: 'subscriptionGroup'
          },
          exclusions: {
            type: 'subscriptionExclusion'
          },
          recurrencePatterns: {
            type: 'subscriptionRecurrencePattern'
          },
          bookings: {
            type: 'generatedSubscriptionBooking'
          },
          travelNeeds: {
            type: 'subscriptionTravelNeed'
          }
        }
      });
      this.serializer.register('place', {
        id: 'id'
      });
      this.serializer.register('rider', {
        id: 'id'
      });
      this.serializer.register('subscriptionTravelNeed', {
        id: 'id'
      });
      this.serializer.register('subscriptionGroup', {
        id: 'id'
      });
      this.serializer.register('subscriptionExclusion', {
        id: 'id'
      });
      this.serializer.register('subscriptionRecurrencePattern', {
        id: 'id'
      });
      this.serializer.register('generatedSubscriptionBooking', {
        id: 'id'
      });
      this.serializer.register('address', {
        id: 'id'
      });
      this.serializer.register('location', {
        id: 'id'
      });
    },

    saveSubscription(subscription, isEditMode, travelNeeds) {
      return new Promise((resolve, reject) => {
        const payload = this.convertSubscriptionRecordToPayloadFormat(subscription, travelNeeds);

        if (isEditMode) {
          this.get('ajax').request(_apiUrls.API.bookingService.host + '/subscription-with-subentity/' + subscription.get('id'), {
            method: 'PATCH',
            contentType: 'application/json',
            headers: {
              Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
            },
            data: JSON.stringify(payload)
          }).then(res => {
            resolve(res);
          }).catch(err => {
            reject(err);
          });
        } else {
          this.get('ajax').post(_apiUrls.API.bookingService.host + '/subscription-with-subentity', {
            method: 'POST',
            contentType: 'application/json',
            headers: {
              Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
            },
            data: JSON.stringify(payload)
          }).then(res => {
            resolve(res);
          }).catch(err => {
            reject(err);
          });
        }
      });
    },

    convertSubscriptionRecordToPayloadFormat(subscriptionRecord, travelNeeds) {
      const subscriptionRawData = this.prepareSubscriptionRawData(subscriptionRecord, travelNeeds);
      const jsonapi = this.serializer.serialize('subscription', subscriptionRawData);
      const payload = this.prepareFinalPayloadForSubscription(jsonapi);
      console.log(JSON.stringify(payload));
      return payload;
    },

    prepareSubscriptionRawData(subscriptionRecord, subTravelNeeds) {
      const leg = this.booking.get('activeBooking.legs.firstObject');
      const serviceWindowName = leg.get('serviceWindow.name');
      const segment = leg.get('segments.firstObject');
      const origin = leg.get('segments.firstObject.pick.place');
      const destination = leg.get('segments.firstObject.drop.place');
      const exclusions = this.prepareExclusions(subscriptionRecord.get('exclusions'));
      const recurrencePatterns = this.prepareReccurencePatterns(subscriptionRecord.get('recurrencePatterns'));
      const provider = this.store.peekAll('provider.firstObject');
      const rider = this.booking.prepareRider(this.booking.get('selectedRiders.firstObject'));
      const travelNeeds = this.prepareSubscriptionTravelNeeds(this.refactorTNs(subTravelNeeds));
      return {
        providerName: provider.get('providerName'),
        //
        requestTime: leg.get('requestTime'),
        anchor: leg.get('anchor'),
        purpose: leg.get('purpose'),
        serviceWindowName,
        fare: segment.get('fare'),
        fareTypeName: 'cash',
        legNotes: leg.get('notes'),
        originNotes: origin.get('address.notes'),
        destinationNotes: destination.get('address.notes'),
        startDate: subscriptionRecord.get('startDate'),
        endDate: subscriptionRecord.get('endDate'),
        status: subscriptionRecord.get('status'),
        maximumOccurrences: subscriptionRecord.get('maximumOccurrences'),
        remainingOccurrences: subscriptionRecord.get('remainingOccurrences'),
        originPlace: this.preparePlace(origin, '-1'),
        destinationPlace: this.preparePlace(destination, '-2'),
        rider,
        exclusions,
        recurrencePatterns,
        travelNeeds
      };
    },

    refactorTNs(legTravelNeedRecords) {
      const configItems = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-travel_need_types';
      });
      const bigSeatsTN = configItems.toArray().filter(tr => parseInt(tr.get('value.Vehicle Capacity Count'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('value.Name').toUpperCase());
      const legTravelNeeds = this.filterConsumableTravelNeeds(legTravelNeedRecords);
      const bigSeatTravelneeds = legTravelNeeds.filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      const uniqBigTns = {};
      bigSeatTravelneeds.forEach(t => {
        const type = t.get('travelNeedTypeNameUppercase');

        if (!uniqBigTns[type]) {
          uniqBigTns[type] = t;
          return;
        }

        uniqBigTns[type].set('count', parseInt(t.count, 10) + parseInt(uniqBigTns[type].count, 10));
      });
      const otherTravelNeeds = legTravelNeeds.filter(travelNeed => {
        return !bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      const uniqBigTRNs = Object.values(uniqBigTns);
      return [...otherTravelNeeds, ...uniqBigTRNs];
    },

    // todo : remove this logic in the future when the payload is json api complacent
    prepareFinalPayloadForSubscription(jsonApi) {
      const included = jsonApi.included.map(entity => {
        if (entity.type === 'place') {
          const address = entity.attributes.address;
          const location = entity.attributes.location;
          delete entity.attributes.address;
          delete entity.attributes.location;
          return { ...entity,
            relationships: {
              address: {
                data: {
                  type: 'address',
                  ...address
                }
              },
              location: {
                data: {
                  type: 'location',
                  ...location
                }
              }
            }
          };
        }

        return entity;
      });
      return {
        data: jsonApi.data,
        included
      };
    },

    preparePlace(place, idx) {
      return {
        id: idx,
        placeCategoryTypeName: 'passenger',
        geocodingMethod: 'something',
        address: this.booking.prepareAddress(place.get('address')),
        location: this.booking.prepareLocation(place.get('location'))
      };
    },

    prepareSubscriptionTravelNeeds(legTravelNeeds) {
      const subscriptionTravelNeeds = this.get('booking').filterPcaAndServiceAnimal(legTravelNeeds);
      return subscriptionTravelNeeds.map((travelNeed, idx) => {
        const positiveIndex = idx + 1;
        const subTrNeed = {
          id: `-${positiveIndex}`,
          count: travelNeed.get('count'),
          travelNeedTypeName: travelNeed.get('travelNeedType.id'),
          passengerTypeName: travelNeed.get('passengerType.id')
        };
        return subTrNeed;
      });
    },

    prepareExclusions(exclusions) {
      const sExclusions = exclusions.map((e, idx) => {
        const positiveIndex = idx + 1;

        if (e.get('suspend')) {
          return {
            id: `-${positiveIndex}`,
            startDate: e.get('startDate'),
            endDate: e.get('endDate'),
            type: e.get('type')
          };
        }

        return null;
      });
      return sExclusions;
    },

    prepareReccurencePatterns(reccurencePatterns) {
      const sReccurencePatterns = reccurencePatterns.map((r, idx) => {
        const positiveIndex = idx + 1;
        return {
          id: `-${positiveIndex}`,
          ...r.toJSON()
        };
      });
      return sReccurencePatterns;
    },

    // NYAAR-13442 Booking: Consumable travel needs with count=0 should not be saved in leg travel needs tables
    filterConsumableTravelNeeds(legTravelNeedRecords) {
      const configItems = this.get('booking.configTravelNeedItems');
      const consumableTravelNeeds = configItems.toArray().filter(tr => parseInt(tr.get('value.Vehicle Capacity Count'), 10) > 0);
      const TR_N_TO_NOT_SAVE_IF_COUNT_ZERO = consumableTravelNeeds.map(tr => tr.get('value.Name').toUpperCase());
      return legTravelNeedRecords.filter(lt => !(TR_N_TO_NOT_SAVE_IF_COUNT_ZERO.indexOf(lt.get('travelNeedTypeNameUppercase')) > -1 && parseInt(lt.get('count'), 10) === 0));
    },

    async fetchFutureTrips(subscriptionId) {
      try {
        let subscriptionTrips = await this.get('booking').fetchSubscriptionTrips(subscriptionId);
        subscriptionTrips = (0, _lodash.flattenDeep)(subscriptionTrips);
        let futureTrips = this.filterFutureTrips(subscriptionTrips);
        const futureTripIds = futureTrips.map(function (trip) {
          return trip && trip.id;
        });
        const futureTripsIdsInClause = futureTripIds.map(t => `'${t}'`).join(',');
        const stopByTripId = `in(trip,(${futureTripsIdsInClause}))`;
        const dispatchTrips = await this.get('store').query('stop-point', {
          filter: stopByTripId
        });

        if (Ember.isEmpty(dispatchTrips)) {
          futureTrips = this.get('booking.currentTrips').filter(trip => futureTripIds.includes(trip.get('id')));
          return this.addIsCheckedPropertySubscriptionTrips(futureTrips) || [];
        }

        futureTrips = this.differenceDispatchTrips(dispatchTrips.toArray(), futureTripIds);
        return this.addIsCheckedPropertyInDispatchTrips(futureTrips) || [];
      } catch (e) {
        console.error(e);
      }
    },

    filterFutureTrips(trips) {
      const futureTrips = trips.filter(trip => {
        this.tripHaveNoPromiseTimeAndLegRequestTime(trip);
        const promiseTime = trip.attributes.promiseTime && (0, _moment.default)(trip.attributes.promiseTime);
        const currentTime = (0, _moment.default)().startOf('day');
        const greaterThanPromiseTime = promiseTime ? currentTime.isBefore(promiseTime) && currentTime.format('DD-MM-YYYY') !== promiseTime.format('DD-MM-YYYY') : false;
        const status = trip.attributes.status && trip.attributes.status.toLowerCase();
        return greaterThanPromiseTime && status !== 'canceled';
      });
      return futureTrips || [];
    },

    tripHaveNoPromiseTimeAndLegRequestTime(trip) {
      const hasPromiseTime = trip.attributes.hasOwnProperty('promiseTime');

      if (!hasPromiseTime) {
        const bookingId = trip.relationships.booking.data.id;
        const bookingInfo = this.get('booking.currentBookings').find(booking => booking.get('id') === bookingId);
        trip.attributes.promiseTime = bookingInfo.firstLeg.get('requestTime').toISOString();
      }
    },

    addIsCheckedPropertyInDispatchTrips(dispatchTrips) {
      const futureTrips = (0, _lodash.uniqBy)(dispatchTrips, ft => ft.get('trip.id'));
      return futureTrips.map(trip => {
        const id = parseInt(trip.get('trip.id'), 10);
        return {
          tripId: trip.get('trip.tripId'),
          id: id,
          isChecked: false
        };
      });
    },

    addIsCheckedPropertySubscriptionTrips(trips) {
      const futureTrips = trips;
      return futureTrips.map(trip => {
        const id = parseInt(trip.get('id'), 10);
        return {
          tripId: trip.get('tripId'),
          id: id,
          isChecked: false
        };
      });
    },

    differenceDispatchTrips(dispatchTrips, futureTripIds) {
      return dispatchTrips.filter(trip => {
        return futureTripIds.includes(trip.get('trip.tripId'));
      });
    },

    async cancelFutureTrips(subscriptionId) {
      try {
        const session = this.get('session');
        const tripIds = this.get('booking').tripIds;
        const subscriptionTripsCancelPayload = {
          data: {
            type: 'subscriptionTripCancellation',
            attributes: {
              tripIds: tripIds
            }
          }
        };
        const json = JSON.stringify(subscriptionTripsCancelPayload);
        return await this.get('ajax').post(_apiUrls.API.bookingService.host + '/subscription/' + subscriptionId + '/trip-cancellation', {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${session.data.authenticated.token}`
          },
          data: json
        });
      } catch (e) {
        const errorMessage = 'FAILED TO CANCEL SUBSCRIPTION TRIPS ' + e.message; // eslint-disable-next-line no-throw-literal

        throw {
          message: errorMessage
        };
      }
    },

    async deleteSubscription(subscriptionId) {
      try {
        const session = this.get('session');
        return await this.get('ajax').post(_apiUrls.API.bookingService.host + '/subscription/' + subscriptionId, {
          method: 'DELETE',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${session.data.authenticated.token}`
          }
        });
      } catch (e) {
        const errorMessage = 'FAILED TO DELETE SUBSCRIPTION ' + e.message; // eslint-disable-next-line no-throw-literal

        throw {
          message: errorMessage
        };
      }
    }

  });

  _exports.default = _default;
});