define("adept-iq/pods/components/side-drawer/system-config/route-master/table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/system-config/route-master/table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LOAD_INTERVAL = 1000;

  var _default = _component.default.extend({
    classNames: ['route-master-table'],
    config: _config.default,
    store: Ember.inject.service(),
    paginationEnabled: true,
    enableWarningRowChangeAlert: true,

    init() {
      this._super(...arguments);
    },

    async didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      } //Before loading route-template records, fetch the models(route-template-availability, vehicle,shift-break )


      this.set('isLoading', true);
      await this.fetchModels();
      Ember.run.later(() => {
        if (this.get('isDestroyed')) return;
        this.set('isLoading', false);
        Ember.run.scheduleOnce('afterRender', this, 'refreshData');
      }, LOAD_INTERVAL);
    },

    async fetchModels() {
      const modelPromises = [this.get('store').findAll('route-template-availability'), this.get('store').query('vehicle', {
        useVehicleService: true
      }), this.get('store').findAll('shift-break')];
      await Promise.all(modelPromises);
    },

    rollbackRecord() {
      const rollBackDirtyRecord = this.get('rollBackDirtyRecord');
      rollBackDirtyRecord();
    },

    fetchDataQuery() {
      if (this.get('isLoading')) {
        return Promise.resolve([]);
      }

      return this._super(...arguments).then(records => {
        return records.filter(record => !record.get('isNew'));
      });
    },

    // if any one of rows is selected, hasPendingChanges method will be executed
    hasPendingChanges() {
      const hasRouteTemplateChanged = this.get('hasRouteTemplateChanged') || false;
      return hasRouteTemplateChanged;
    },

    // this will return  a function to save previously edited record
    savePreviousRecord() {
      const saveRecordFunction = this.get('saveRecordFunction');
      return saveRecordFunction;
    },

    actions: {
      selectRow(currentRow, event) {
        this._super(currentRow, event);

        const zoneTableRef = this.get('zoneTableRef');
        if (zoneTableRef) zoneTableRef.get('table.selectedRows').setEach('selected', false);
      }

    }
  });

  _exports.default = _default;
});