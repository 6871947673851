define("adept-iq/pods/components/iq-widgets/previous-bookings-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/previous-bookings-widget/config", "adept-iq/pods/components/iq-widgets/previous-bookings-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/config/mapped-permIds", "lodash", "moment"], function (_exports, _component, _config, _avlmConfig, _environment, _mappedPermIds, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-unused-vars */
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['previous-booking-widget'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    ajax: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    editComponent: 'iq-widgets/passengers-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    canShowHeader: true,
    config: _config.default,
    defaultIncludes: INCLUDES,
    isDeleteConfirm: false,
    selectedRiders: null,
    cancelAction: null,
    editAction: null,
    deleteAction: null,
    bookingForEdit: null,
    bookingForCancel: null,
    cutOffTime4LateCancelTrip: 0,
    lateTripCancelMessage: null,
    cancelBookingComponent: 'iq-widgets/cancel-booking-form',
    deleteBookingComponent: 'iq-widgets/delete-booking-form',
    newBookingComponent: 'iq-widgets/new-booking-form',

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);

      this.onBookingEdit = this.onBookingEdit.bind(this);
      this.set('selectedRiders', []);
      this.get('tableColumns').popObject();
      this.get('tableColumns').lastObject.resizable = false;
      this.onGroupBookingEditConfirm = this.onGroupBookingEditConfirm.bind(this);
      this.lateTripCancelBooking = this.lateTripCancelBooking.bind(this);
      this.onLateTripCancelBooking = this.onLateTripCancelBooking.bind(this);
      this.onPreviousBookingsChange();
    },

    onPreviousBookingsChange: Ember.observer('booking.currentBookings.[]', 'booking.currentBookings', function () {
      this.sortLegsInAllBookings();
    }),

    sortLegsInAllBookings() {
      const currentBookings = this.get('booking.currentBookings').map(booking => {
        const legs = booking.get('legs');
        const sortedLegs = legs.sortBy('legOrdinal');
        booking.set('legs', sortedLegs);
        return booking;
      }).sortBy('id').reverse();
      this.set('records', currentBookings);
    },

    showGroupBookingWarning() {
      this.get('tooltip').pushConfirmation({
        title: 'Edit Group Booking',
        tip: 'Are you sure you want to edit this Group booking?',
        primaryActionText: 'Confirm',
        primaryAction: this.onGroupBookingEditConfirm,
        secondaryActionText: 'Cancel',
        hasOverlay: true,
        secondaryAction: () => {
          this.get('tooltip').reset();
        }
      });
    },

    showLateTripCancelBookingWarning() {
      this.get('tooltip').pushConfirmation({
        title: 'LateTripCancel',
        tip: this.get('lateTripCancelMessage').get('value'),
        primaryActionText: 'Ok',
        primaryAction: this.lateTripCancelBooking,
        hasOverlay: true,
        secondaryAction: () => {
          this.get('tooltip').reset();
        }
      });
    },

    onLateTripCancelBooking(booking) {
      this.set('bookingForCancel', booking);
      const requestTime = booking.get('legs.firstObject.requestTime');
      const value = Number(this.get('cutOffTime4LateCancelTrip').get('value')) / 60 || Number(this.get('cutOffTime4LateCancelTrip').get('defaultValue')) / 60;
      const bookingDate = (0, _moment.default)(requestTime);
      const currentDate = (0, _moment.default)().startOf('day');
      const bookingTime = bookingDate;
      /*if this is set to 120 minutes (2 hours), then trip with a 9:00 AM promise time will be considered a late cancel if the cancellation occurs anytime after 7:00 AM*/

      const lessTwoHour = bookingDate.subtract(value, 'hours');
      const currentTime = (0, _moment.default)();

      if (bookingDate.date() <= currentDate.date() && (0, _moment.default)(lessTwoHour).isBefore(currentTime)) {
        this.showLateTripCancelBookingWarning();
      } else {
        this.onOpenCancelBooking(booking, 'Canceled');
      }
    },

    lateTripCancelBooking() {
      this.onOpenCancelBooking(this.get('bookingForCancel'), 'lateCancelled');
      this.get('tooltip').reset();
    },

    async onGroupBookingEditConfirm() {
      await this.openBookingForEdit(this.get('bookingForEdit'));
      this.get('tooltip').reset();
    },

    onOpenCancelBooking(booking, status) {
      const cancelBookingComponent = this.get('cancelBookingComponent');
      this.get('editModal').open(cancelBookingComponent, [booking, status]);
    },

    dedupeLegs(legsIncludingDupe, riderExternalIds) {
      const anyOneRider = riderExternalIds[0];
      const legsArray = legsIncludingDupe.toArray();
      const legsGroupedByRider = (0, _lodash.groupBy)(legsArray, leg => {
        return leg.get('rider.riderId');
      });
      return legsGroupedByRider[anyOneRider];
    },

    fetchAndReturnRiders(riderIds) {
      return new Promise((res, rej) => {
        const riderPromises = riderIds.map(rId => {
          return this.store.findRecord('rider', rId);
        });
        Promise.all(riderPromises).then(riderRecords => {
          res(riderRecords);
        }).catch(e => {
          rej(e);
        });
      });
    },

    fetchExternalRidersAndSetToSelectedRiders(externalRiderIds) {
      return new Promise((res, rej) => {
        const riderExternalPromises = externalRiderIds.map(rId => {
          return this.store.findRecord('rider-external', rId);
        });
        Promise.all(riderExternalPromises).then(riderExternalRecords => {
          riderExternalRecords.forEach(rExt => {
            this.get('booking').addSelectedRider(rExt);
          });
          res(riderExternalRecords);
        }).catch(e => {
          rej(e);
        });
      });
    },

    async fetchPlaces(legsInBooking) {
      const placeIds = (0, _lodash.compact)((0, _lodash.uniq)((0, _lodash.flattenDeep)(legsInBooking.map(leg => {
        return leg.get('segments').map(segment => [segment.get('pick.place.id'), segment.get('drop.place.id')]);
      }))));
      if (!placeIds.length) return;
      const query = 'in(\'id\',(' + placeIds + '))&include=address,location';
      return await this.store.query('place', {
        filter: query
      });
    },

    openBookingForEdit: async function (booking) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const bookingId = booking.get('id');

      try {
        await this.get('store').queryRecord('booking', bookingId);
        const bookingRecord = this.get('store').peekRecord('booking', bookingId); // todo :should be handled differently

        const dummySubscription = this.get('booking').createSubscriptionRecord();
        await bookingRecord.set('subscription', dummySubscription);
        this.set('booking.activeBooking', bookingRecord);
        const legsInBooking = await bookingRecord.get('legs');
        await this.fetchPlaces(legsInBooking);
        this.get('booking').clearSelectedRiders();
        const legsIncludingDupes = bookingRecord.get('legs');
        const allRiderIdsIncludingDupes = legsIncludingDupes.map(leg => {
          return leg.get('rider.id');
        });
        const riderIds = (0, _lodash.uniq)(allRiderIdsIncludingDupes).filter(rId => rId); // unpersisted record will have null

        const riders = await this.fetchAndReturnRiders(riderIds);
        const riderExternalIds = riders.map(r => r.get('riderId'));
        await this.fetchExternalRidersAndSetToSelectedRiders(riderExternalIds);
        await this.get('booking').fetchAllSelectedRidersForTravelNeeds();
        await this.get('booking').fetchAndStoreEligibilityForAllSelectedRiders();
        const legsAfterDedupe = this.dedupeLegs(legsIncludingDupes, riderExternalIds);
        const legsWithZName = legsAfterDedupe.map(leg => {
          const pickZName = leg.get('segments.firstObject.pick.place.address.zoneName.id');
          const dropZName = leg.get('segments.lastObject.drop.place.address.zoneName.id');
          leg.set('segments.firstObject.pick.place.address.zName', pickZName);
          leg.set('segments.lastObject.drop.place.address.zName', dropZName);
          return leg;
        }).sortBy('legOrdinal');
        await bookingRecord.get('legs').clear();
        await bookingRecord.get('legs').pushObjects(legsWithZName);
        const isBookingEditMode = true;
        const selectedRider = this.get('booking.selectedRiders').firstObject;
        const newBookingComponent = this.get('newBookingComponent');
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('editModal').open(newBookingComponent, [isBookingEditMode, selectedRider, bookingRecord]);
      } catch (e) {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('notifications').warning('error while editing booking ' + e.message);
      }
    },

    onBookingEdit(booking) {
      this.set('bookingForEdit', booking);

      if (booking.get('isGroupBooking')) {
        this.showGroupBookingWarning();
      } else {
        this.openBookingForEdit(booking);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('cancelAction', {
        id: 'cancel',
        name: 'Cancel Booking',
        permIds: [_mappedPermIds.default.cancelFutureBookings, _mappedPermIds.default.cancelSameDayBookings],
        action: this.onLateTripCancelBooking
      });
      this.set('editAction', {
        id: 'edit',
        name: 'Edit Booking',
        permId: [_mappedPermIds.default.editFutureBookings, _mappedPermIds.default.editSameDayBookings],
        action: this.onBookingEdit
      });
      this.set('deleteAction', {
        id: 'delete',
        name: 'Delete Booking',
        permId: _mappedPermIds.default.deleteBooking,
        action: model => {
          const deleteBookingComponent = this.get('deleteBookingComponent');
          this.get('editModal').open(deleteBookingComponent, [model]);
        }
      });
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.selectAndDeselectTableRows(false);
      this.filterTripsForselectedRiders();
    },

    selectAndDeselectTableRows(isSelected) {
      const bookingWidgetTableRows = this.get('table.rows');
      bookingWidgetTableRows.setEach('selected', isSelected);
      bookingWidgetTableRows.setEach('isChecked', isSelected);
    },

    filterTripsForselectedRiders() {
      const bookingRows = this.get('table.rows');
      const selectedBookings = bookingRows.filter(row => row.get('isChecked'));
      const selectedBookingIds = selectedBookings.map(booking => booking.get('id'));
      const filteredTrips = this.get('booking.currentTrips').filter(trip => selectedBookingIds.some(bookingId => bookingId === trip.get('booking.id')));
      this.set('booking.filteredCurrentTrips', filteredTrips);
    },

    actions: {
      onRowClick() {
        this.filterTripsForselectedRiders();
      },

      onRowRightClick(row) {
        if (row.get('status') === 'cancelled' || row.get('status') === 'lateCancelled') {
          this.set('dynamicSingleActions', [this.get('deleteAction')]);
        } else if (row.get('isSameDayBooking') || row.get('status') === 'scheduled') {
          this.set('dynamicSingleActions', [this.get('cancelAction'), this.get('deleteAction')]);
        } else {
          this.set('dynamicSingleActions', [this.get('cancelAction'), this.get('editAction'), this.get('deleteAction')]);
        }

        const cutOffTime4LateCancelTrip = this.store.peekRecord('cs-config-item', 'config-Cancel_Trips/cutOffTime4LateCancelTrip');
        const lateTripCancelMessage = this.store.peekRecord('cs-config-item', 'config-Cancel_Trips/Late_Trip_Cancel_Message');
        this.set('lateTripCancelMessage', lateTripCancelMessage);
        this.set('cutOffTime4LateCancelTrip', cutOffTime4LateCancelTrip);

        this._super(...arguments);
      },

      onCheckAll() {
        const isRowHeadChecked = true;
        this.set('booking.filteredCurrentTrips', this.get('booking.currentTrips'));
        this.selectAndDeselectTableRows(isRowHeadChecked);
      },

      onUncheckAll() {
        const isRowHeadUnchecked = false;
        this.set('booking.filteredCurrentTrips', []);
        this.selectAndDeselectTableRows(isRowHeadUnchecked);
      }

    }
  });

  _exports.default = _default;
});