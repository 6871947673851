define("adept-iq/adapters/maintenance-type-name", ["exports", "adept-iq/adapters/cs-config-model"], function (_exports, _csConfigModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigModel.default.extend({
    categoryId: 'config-System_Configuration-vehicle_maint_types'
  });

  _exports.default = _default;
});