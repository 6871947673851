define("adept-iq/pods/components/side-drawer/reconcile-add-new-trip/component", ["exports", "moment", "adept-iq/pods/components/side-drawer/reconcile-add-new-trip/stubs", "ember-concurrency"], function (_exports, _moment, _stubs, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    session: Ember.inject.service(),
    geocode: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    defaultFare: 2.75,
    stubs: _stubs.default,
    onCloseClick: null,
    coordinates: null,
    travelNeedsOptionsCount: 0,
    travelNeedsGroups: null,
    travelNeedsOpions: _stubs.default.travelNeeds,
    isEnableCheckbox: false,
    selectedDate: Ember.computed.alias('reconcile.reconcileDate'),
    serviceMode: Ember.computed.alias('reconcile.reconcileMode'),
    // Reset map and address picker
    canResetAddress: Ember.computed.alias('workspace.activeContext.topActiveContext.resetLocation'),
    callBack: Ember.computed.readOnly('workspace.topOptions.action'),
    isEditStart: Ember.computed.alias('isEditingStart'),
    passengerDetails: Ember.computed.readOnly('workspace.topOptions.selectedRow'),
    reconcileRouteStartTime: Ember.computed.readOnly('reconcile.reconcileRouteDetails.actualStartTime'),
    reconcileRouteEndTime: Ember.computed.readOnly('reconcile.reconcileRouteDetails.actualEndTime'),
    passengerTravelNeeds: Ember.computed.readOnly('passengerDetails.travelNeeds'),
    canPlus: Ember.computed('travelNeedsOpions', 'travelNeedsOptionsCount', function () {
      return Ember.get(this, 'travelNeedsOptionsCount') < Ember.get(this, 'travelNeedsOpions').length;
    }),
    canSave: Ember.computed.and('selectedAnchor', 'selectedPickLocation', 'selectedDropLocation', 'shiftTimeRange.{pickTime,dropTime}'),
    anchorOptions: Ember.computed('workspaceData', 'selectedAnchor', function () {
      const options = [];
      const anchorOptionsData = _stubs.default.anchorData;
      anchorOptionsData.map(item => {
        options.push(item);
      });
      return options;
    }),
    companionOptions: Ember.computed('workspaceData', 'selectedNoOfCompanions', function () {
      const options = [];
      const companions = _stubs.default.companions;
      companions.map(item => {
        options.push(item);
      });
      return options;
    }),

    init() {
      this._super(...arguments);

      this.travelNeedsGroups = [];
      this.coordinates = {
        lat: null,
        lng: null
      };
      this.setDefaultTravelNeeds();
      const serviceNeedOptions = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-service_needs_types';
      });
      this.setProperties({
        shiftTimeRange: {
          pickTime: null,
          dropTime: null,
          startofday: (0, _moment.default)(this.selectedDate).startOf('day').toISOString(),
          endofday: (0, _moment.default)(this.selectedDate).endOf('day').toISOString()
        },
        displayAmbulatory: true,
        isDisableDatePicker: true,
        isEnableServiceAnimal: false,
        isEditingStart: false,
        serviceNeedOptions: serviceNeedOptions
      });
      this.getGeocodeFromAddress();
      Ember.set(this, 'displayWheelChairOption', this.get('wheelChairEligibility') === true);
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('.side-drawer-modal').width('470px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    async getGeocodeFromAddress() {
      const passengerAddress = this.get('passengerDetails.address');

      if (Ember.isEmpty(passengerAddress)) {
        return;
      }

      const addresses = await this.get('geocode').fuzzySearch(passengerAddress);

      if (addresses && addresses.length) {
        const address = addresses[0];

        if (address && address.position && address.position.lat && address.position.lon) {
          this.set('coordinates', {
            lat: address.position.lat,
            lng: address.position.lon
          });
        }
      }

      this.setDefaultPickupLocation();
    },

    setDefaultPickupLocation() {
      if (this.get('passengerDetails.address')) {
        Ember.set(this, 'selectedPickLocation', {
          freeformAddress: this.get('passengerDetails.address'),
          lat: this.get('coordinates.lat'),
          lng: this.get('coordinates.lng')
        });
      }
    },

    searchTask: (0, _emberConcurrency.task)(function* () {
      const passengerAddress = this.get('passengerDetails.address'); // can't do a blank search

      if (Ember.isEmpty(passengerAddress)) {
        return;
      }

      const results = yield this.get('geocode').getGeonode(passengerAddress);

      if (results && results.attributes && results.attributes.coordinates) {
        this.set('coordinates', results.attributes.coordinates);
      }
    }),
    saveAddTripTask: (0, _emberConcurrency.task)(function* () {
      this.send('onRefreshFare');
      const provider = this.get('reconcile.reconcileSelectedProviderName');
      const store = this.get('store');
      const reconcilAdapter = store.adapterFor('reconcile-trip');
      const routeId = this.get('reconcile.reconcileSelectedRouteId');
      const passengerDetails = this.get('passengerDetails');
      const pickAddress = this.get('selectedPickLocation');
      const dropAddress = this.get('selectedDropLocation');
      const fare = this.get('expectedFair').substring(1);
      const pickTime = this.get('shiftTimeRange.pickTime');
      const dropTime = this.get('shiftTimeRange.dropTime');
      const usedAttributes = [];
      usedAttributes.push({
        name: 'wheelChair',
        cnt: this.wheelChairOption ? this.wheelChairOption.value : 0
      });
      usedAttributes.push({
        name: 'ambulatory',
        cnt: this.selectedNeedsOptions ? this.selectedNeedsOptions.value : 0
      });
      usedAttributes.push({
        name: 'pca',
        // eslint-disable-next-line no-nested-ternary
        cnt: this.isEnablePCA ? this.defaultPcaCount ? this.defaultPcaCount : 1 : 0
      });
      usedAttributes.push({
        name: 'companion',
        cnt: this.selectedNoOfCompanions ? this.selectedNoOfCompanions.value : 0
      });
      usedAttributes.push({
        name: 'serviceAnimal',
        // eslint-disable-next-line no-nested-ternary
        cnt: this.isEnableServiceAnimal ? this.defaultServiceAnimalCount ? this.defaultServiceAnimalCount : 1 : 0
      });
      usedAttributes.push({
        name: 'loadTime',
        cnt: this.loadTime ? this.loadTime : 0
      });
      usedAttributes.push({
        name: 'unloadTime',
        cnt: this.unloadTime ? this.unloadTime : 0
      });
      Ember.get(this, 'travelNeedsGroups').map(item => {
        if (item.selected) {
          usedAttributes.push({
            name: item.selected.id,
            cnt: item.selected.value ? item.selected.value : 0
          });
        }
      });
      const payload = {
        routeId: routeId,
        type: 'trip',
        startTime: pickTime,
        endTime: dropTime,
        travelNeeds: usedAttributes,
        providerName: provider,
        userId: this.session.data.authenticated.userId,
        serviceMode: this.serviceMode,
        passenger: {
          id: passengerDetails.passengerId,
          firstName: passengerDetails.firstName,
          lastName: passengerDetails.lastName,
          phoneNumber: passengerDetails.phoneNumber,
          serviceNeeds: [{
            name: 'Door to Door'
          }, {
            name: 'Visually impaired'
          }]
        },
        price: {
          id: null,
          type: 'cash',
          fare: fare,
          currencyCode: null,
          tip: null
        },
        stops: [{
          type: 'pickup',
          date: pickTime,
          notes: this.get('pickNotes'),
          address: {
            formattedAddress: pickAddress.freeformAddress,
            lat: pickAddress.lat,
            lng: pickAddress.lng
          }
        }, {
          type: 'dropoff',
          date: dropTime,
          notes: this.get('dropNotes'),
          address: {
            formattedAddress: dropAddress.freeformAddress,
            lat: dropAddress.lat,
            lng: dropAddress.lng
          }
        }]
      };

      try {
        return yield reconcilAdapter.postTrip(payload);
      } catch (e) {
        throw e;
      }
    }),

    showToolTip(title, tip, successMsg) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveAddTripTask').perform().then(() => {
            this.send('cancel');
            this.get('workspace').popState('reconcileAddNewTrip');
            tooltip.reset();
            this.get('notifications').success(successMsg);
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem creating the route, please try again.';
                break;
            }

            this.set('errorMessage', message);
            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    checkValidations() {
      const canSave = this.get('canSave');

      if (!canSave) {
        this.set('errorMessage', 'Please fill out all of the fields before saving.');
        return false;
      }

      if (this.get('shiftTimeRange.pickTime') && this.get('shiftTimeRange.dropTime')) {
        if (!_moment.default.utc(this.get('shiftTimeRange.dropTime')).isAfter(_moment.default.utc(this.get('shiftTimeRange.pickTime')))) {
          Ember.set(this, 'errorMessage', 'Pickup time can not be greater than or equal to dropoff time');
          return false;
        }

        if (_moment.default.utc(this.reconcileRouteStartTime).isAfter(_moment.default.utc(this.get('shiftTimeRange.pickTime')))) {
          Ember.set(this, 'errorMessage', 'Pickup time can not be less than route start time');
          return false;
        }

        if (_moment.default.utc(this.get('shiftTimeRange.dropTime')).isAfter(_moment.default.utc(this.reconcileRouteEndTime))) {
          Ember.set(this, 'errorMessage', 'Dropoff time can not be greater than route end time');
          return false;
        }
      }

      const selectedGroupOptions = Ember.get(this, 'travelNeedsGroups').filter(item => item.selected);

      if (selectedGroupOptions) {
        const nameArray = selectedGroupOptions.map(item => item.selected.id);
        const isDuplicate = nameArray.some((item, idx) => nameArray.indexOf(item) !== idx);

        if (isDuplicate) {
          Ember.set(this, 'errorMessage2', 'Duplicate travel needs option selected');
          return false;
        }
      }

      return true;
    },

    validateLoadUnloadTime(type, value) {
      if (value > 15) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'Add New Trip',
          tip: type + ' Time cannot be greater than 15 minutes',
          primaryActionText: 'Ok',
          primaryAction: () => {
            this.resetLoadUnloadValues(type, '');
            return tooltip.reset();
          },
          closeAction: () => {
            this.resetLoadUnloadValues(type, '');
            return tooltip.reset();
          },
          hasOverlay: true
        });
      } else {
        this.resetLoadUnloadValues(type, value);
      }
    },

    resetLoadUnloadValues(type, value) {
      if (type === 'Load') {
        Ember.set(this, 'loadTime', value);
      } else {
        Ember.set(this, 'unloadTime', value);
      }
    },

    setMaxMinDate() {
      this.set('maxPickupDate', this.shiftTimeRange.dropTime ? this.shiftTimeRange.dropTime : this.shiftTimeRange.endofday);
      this.set('minDropDate', this.shiftTimeRange.pickTime ? this.shiftTimeRange.pickTime : this.shiftTimeRange.startofday);
    },

    setDefaultTravelNeeds() {
      this.setProperties({
        wheelChairOption: {
          id: 'wheelchair',
          name: 'Wheelchair',
          value: 0
        },
        wheelChairEligibility: false,
        isEnablePCA: false,
        isEnableCheckbox: false,
        defaultPcaCount: 0,
        selectedNoOfCompanions: {
          id: 0,
          name: 0,
          value: 0
        },
        defaultAmbulatoryCount: 0,
        isEnableServiceAnimal: false,
        defaultServiceAnimalCount: 0,
        pcaCount: 0,
        serviceAnimalCount: 0,
        selectedNeedsOptions: {
          id: 'ambulatory',
          name: 'Ambulatory',
          value: 0
        }
      });

      if (Ember.isEmpty(this.passengerTravelNeeds) || !this.passengerTravelNeeds.length) {
        Ember.set(this, 'defaultAmbulatoryCount', 1);
        Ember.set(this, 'selectedNeedsOptions.value', 1);
        Ember.set(this, 'expectedFair', `$${this.defaultFare}`);
        return;
      }

      let ambulatoryCount = 0;
      this.get('passengerTravelNeeds').map(data => {
        if (data && data.passengerTypeName.toLowerCase() === 'client' && data.travelNeedTypeName.toLowerCase() === 'wheelchair') {
          Ember.set(this, 'wheelChairEligibility', true);
          Ember.set(this, 'wheelChairOption.value', data.count);
        }

        if (data && data.travelNeedTypeName.toLowerCase() === 'ambulatory') {
          if (data.passengerTypeName.toLowerCase() === 'pca') {
            Ember.set(this, 'isEnablePCA', true);
            Ember.set(this, 'isEnableCheckbox', true);
            Ember.set(this, 'defaultPcaCount', data.count);
            Ember.set(this, 'pcaCount', data.count);
            ambulatoryCount += data.count;
          } else if (data.passengerTypeName.toLowerCase() === 'companion') {
            Ember.set(this, 'selectedNoOfCompanions.value', data.count);
            ambulatoryCount += data.count;
          } else {
            ambulatoryCount += data.count;
            Ember.set(this, 'defaultAmbulatoryCount', data.count);
          }
        }

        if (data && data.travelNeedTypeName === 'serviceAnimal') {
          Ember.set(this, 'isEnableServiceAnimal', true);
          Ember.set(this, 'defaultServiceAnimalCount', data.count);
          Ember.set(this, 'serviceAnimalCount', data.count);
        }
      });
      Ember.set(this, 'selectedNeedsOptions.value', ambulatoryCount);
      this.calculateFare();
    },

    calculateFare() {
      let fare = 0;
      const wheelchairOption = Ember.get(this, 'wheelChairOption');
      const ambulatoryOption = Ember.get(this, 'selectedNeedsOptions');

      if (wheelchairOption && wheelchairOption.value > 0) {
        fare += wheelchairOption.value * this.defaultFare;
      }

      if (ambulatoryOption && ambulatoryOption.value > 0) {
        fare += ambulatoryOption.value * this.defaultFare;
      }

      Ember.set(this, 'expectedFair', `$${fare}`);
      return fare;
    },

    handleAmbulatoryCount() {
      const pcaOption = Ember.get(this, 'isEnablePCA');
      const companionOption = Ember.get(this, 'selectedNoOfCompanions');
      const wheelchairOption = Ember.get(this, 'wheelChairOption');
      let value = this.defaultAmbulatoryCount; // eslint-disable-next-line no-nested-ternary

      value = pcaOption ? this.defaultPcaCount + (companionOption ? companionOption.value : 0) : companionOption ? companionOption.value : 0;
      value = wheelchairOption.value ? value : value + 1;
      Ember.set(this, 'selectedNeedsOptions', {
        id: 'ambulatory',
        name: 'Ambulatory',
        value
      }); // this.calculateFare();
    },

    actions: {
      onAnchorSelect(option) {
        Ember.set(this, 'isEditingStart', true);

        if (this.get('shiftTimeRange.pickTime') === null || this.get('shiftTimeRange.dropTime') === null) {
          this.set('isDisableDatePicker', false);
        }

        Ember.set(this, 'selectedAnchor', option);
      },

      onServiceNeedsSelect(option) {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'selectedNeedsOptions', option);
      },

      onSelectCompanion(option) {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'selectedNoOfCompanions', option);
        this.handleAmbulatoryCount();
      },

      addTravelNeedItem() {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'travelNeedsOptionsCount', Ember.get(this, 'travelNeedsOptionsCount') + 1);
        this.travelNeedsGroups.pushObject({
          id: Ember.get(this, 'travelNeedsOptionsCount'),
          options: this.travelNeedsOpions,
          selected: false
        });
      },

      onUndoClick() {
        this.setDefaultPickupLocation();
        this.setDefaultTravelNeeds();
        Ember.set(this, 'isEditingStart', false);

        if (!this.get('passengerDetails.address') && this.get('selectedPickLocation.freeformAddress') || this.get('selectedDropLocation')) {
          Ember.set(this, 'canResetAddress', true);
        }

        Ember.set(this, 'pickNotes', '');
        Ember.set(this, 'dropNotes', '');
        Ember.set(this, 'selectedAnchor', []);
        Ember.set(this, 'travelNeedsOptionsCount', 0);
        Ember.set(this, 'travelNeedsGroups', []);
        Ember.set(this, 'selectedDropLocation', null);
        Ember.set(this, 'shiftTimeRange.pickTime', null);
        Ember.set(this, 'shiftTimeRange.dropTime', null);
        Ember.set(this, 'loadTime', '');
        Ember.set(this, 'unloadTime', '');
        Ember.set(this, 'displayWheelChairOption', this.get('wheelChairEligibility') === true);
      },

      onSaveClick() {
        if (!this.checkValidations()) {
          return false;
        }

        this.set('errorMessage', null);
        this.set('errorMessage2', null);
        const title = 'Add New Trip';
        const tip = 'Would you like to add this new trip?';
        const successMsg = 'NEW TRIP ADDED SUCCESSFULLY';
        this.showToolTip(title, tip, successMsg);
      },

      onTimeChange(valuePath, value) {
        Ember.set(this, 'isEditingStart', true);
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      },

      onChangePickLocation(record) {
        Ember.set(this, 'isEditingStart', true);
        this.set('selectedPickLocation', record);
      },

      onChangeDropLocation(record) {
        Ember.set(this, 'isEditingStart', true);
        this.set('selectedDropLocation', record);
      },

      onDeleteTravelNeeds(index) {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'travelNeedsOptionsCount', Ember.get(this, 'travelNeedsOptionsCount') - 1);
        Ember.get(this, 'travelNeedsGroups').removeAt(index);
      },

      onDeleteWheelchair() {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'displayWheelChairOption', false);
        Ember.set(this, 'wheelChairOption', {
          id: 'wheelchair',
          name: 'Wheelchair',
          value: 0
        });
        this.handleAmbulatoryCount();
      },

      onServiceAnimalChanged() {
        Ember.set(this, 'isEditingStart', true);
        this.toggleProperty('isEnableServiceAnimal');
      },

      onPcaChanged() {
        Ember.set(this, 'isEditingStart', true);
        this.toggleProperty('isEnablePCA');
        this.handleAmbulatoryCount();
      },

      onRefreshFare() {
        this.calculateFare();
      },

      oninputTextChange(type, value) {
        Ember.set(this, 'isEditingStart', true);

        switch (type) {
          case _stubs.default.LOAD_TIME:
            this.validateLoadUnloadTime('Load', value);
            break;

          case _stubs.default.UNLOAD_TIME:
            this.validateLoadUnloadTime('Unload', value);
            break;

          case _stubs.default.PICK_NOTES:
            Ember.set(this, 'pickNotes', value);
            break;

          case _stubs.default.DROP_NOTES:
            Ember.set(this, 'dropNotes', value);
            break;

          default:
        }
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      },

      onCloseButtonClick() {
        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});