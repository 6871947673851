define("adept-iq/services/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),

    reverseGeocode(latlng) {
      const store = this.get('store');
      return this.get('geocode').reverseGeocode(latlng).then(result => {
        const latlngArray = result.position.split(','); // make sure we create address successfully

        return store.createRecord('address', {
          freeformAddress: result.address.freeformAddress || 'Unknown',
          streetNumber: result.address.streetNumber || '1',
          streetAddress: result.address.streetName || 'Unknown',
          region: result.address.countrySubdivisionName || 'Unknown',
          locality: result.address.countrySecondarySubdivision || 'Unknown',
          subLocality: result.address.countryTertiarySubdivision || 'Unknown',
          postalCode: result.address.postalCode || 'Unknown',
          country: result.address.country || 'US',
          lat: latlngArray[0],
          lng: latlngArray[1]
        });
      });
    },

    // address models returned by this function are not saved by default
    fuzzySearch(query, options = {}) {
      const store = this.get('store');
      return this.get('geocode').fuzzySearch(query, options).then(results => {
        return results.map(result => {
          // translate from tomtom fields
          return store.createRecord('address', {
            freeformAddress: result.address.freeformAddress,
            streetNumber: result.address.streetNumber,
            streetAddress: result.address.streetName,
            region: result.address.countrySubdivisionName,
            locality: result.address.countrySecondarySubdivision || result.address.municipality,
            subLocality: result.address.countryTertiarySubdivision,
            postalCode: result.address.postalCode,
            country: result.address.country,
            lat: result.position.lat,
            lng: result.position.lon
          });
        });
      });
    },

    async copyAddress(address) {
      const store = this.get('store');
      return store.createRecord('address', {
        freeformAddress: address.get('tomtomFormattedAddress.address.freeformAddress'),
        streetNumber: address.get('streetNumber'),
        streetAddress: address.get('streetAddress'),
        region: address.get('region'),
        locality: address.get('locality'),
        subLocality: address.get('subLocality'),
        postalCode: address.get('postalCode'),
        country: address.get('country'),
        lat: address.get('position.lat'),
        lng: address.get('position.lon')
      });
    },

    async createWayPoint(address) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const placeSerializer = store.serializerFor('place');
      const placeModelClass = store.modelFor('place');
      const result = await this.get('geocode').getGeonode(address.freeformAddress); // TODO: geonode is only working in NY

      const location = store.createRecord('location', {
        lat: address.lat,
        lng: address.lng,
        geoNode: result ? result.geonode : 5444 // TODO: add default geoNode

      }); // TODO: how does zone types fit in the address

      address.set('zoneName', this.store.peekAll('zone-type').get('firstObject'));
      const place = store.createRecord('place', {
        address: address,
        location: location
      });

      if (address) {
        place.set('location.lat', address.lat);
        place.set('location.lng', address.lng);
      }

      const locationResponse = await locationAdapter.postLocation(location);
      const addressResponse = await addressAdapter.postAddress(address);
      await addressAdapter.postAddressLocation(locationResponse, addressResponse);
      const placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'ad_hoc');
      const placeNorm = placeSerializer.normalizeResponse(store, placeModelClass, placeResponse, placeResponse.data.id, 'createRecord');
      const placeRecord = store.push(placeNorm);
      return placeRecord;
    }

  });

  _exports.default = _default;
});