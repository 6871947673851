define("adept-iq/pods/components/iq-widgets/new-booking-form/passenger-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedRiders: null,
    booking: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('selectedRiders', this.get('booking.selectedRiders'));
    },

    validatePhoneNumber(number) {
      if (number.length != 10) {
        return false;
      }

      return true;
    },

    actions: {
      onNumberChange(rider, number) {
        // eslint-disable-next-line no-useless-escape
        const properNumber = number.replace('-', '').replace('\s', ''); // removing hyphen

        if (this.validatePhoneNumber(properNumber)) {
          const areaCode = properNumber.substring(0, 3).trim();
          const phoneNumber = properNumber.substring(3).trim();
          const notificationPhoneNumbers = rider.get('notificationPhoneNumbers'); // the rider does have a main phone number from nyct

          if (notificationPhoneNumbers.length == 0) {
            const phoneNumberObj = {
              type: 'MAIN',
              areaCode: areaCode,
              phoneNumber: phoneNumber,
              extension: '',
              description: 'MAIN CONTACT #',
              useForSmsNotifications: false,
              useForVoiceNotifications: true
            };
            notificationPhoneNumbers.pushObject(phoneNumberObj);
          } else {
            const updatedNotificationPhoneNumbers = notificationPhoneNumbers.map(phoneNumberObj => {
              if (phoneNumberObj.type == 'MAIN') {
                return { ...phoneNumberObj,
                  areaCode,
                  phoneNumber
                };
              }

              return phoneNumberObj;
            });
            rider.set('notificationPhoneNumbers', updatedNotificationPhoneNumbers);
          }
        } else {
          this.get('booking').showErrorMessage('Please enter valid phone number with area code.');
        }
      }

    }
  });

  _exports.default = _default;
});