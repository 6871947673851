define("adept-iq/models/perm-sso-role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    permissionHash: null,
    groupedPermissions: null,
    roleName: attr('string'),
    name: attr('string'),
    displayName: attr('string'),
    status: attr('string'),
    permissions: attr(),
    permissionList: attr(),
    users: hasMany('sso-user'),

    reconfigurePermissionHash(permissions) {
      const permissionList = this.get('permissionList');
      const permHash = permissions.reduce((acu, perm) => {
        acu[perm.parsedId] = false;
        return acu;
      }, {});
      permissionList.forEach(id => {
        permHash[id] = true;
      });
      this.set('permissionHash', permHash);
    },

    reconfigurePermissionCheck(permissions) {
      const permissionList = this.get('permissionList');
      permissions.map(data => {
        permissionList.map(item => {
          if (item === data.parsedId) {
            data.state = true;
          }
        });
      });
    }

  });

  _exports.default = _default;
});