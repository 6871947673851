define("adept-iq/pods/components/side-drawer/reconcile-add-new-route/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    reconcile: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    vehicles: null,
    routeId: null,
    timeFormat: 'm-d-Y h:i K',
    reconcileProviderName: Ember.computed.readOnly('reconcile.reconcileProviderName'),
    reconcileTripDate: Ember.computed.readOnly('reconcile.reconcileDate'),
    // Reset map and address picker
    canResetAddress: Ember.computed.alias('workspace.activeContext.topActiveContext.resetLocation'),
    canSave: Ember.computed.and('routeId', 'selectedDateTimeRage.{starttime,endtime}', 'selectedVehicle', 'selectedDriver', 'selectedStartLocation', 'selectedEndLocation'),
    canUndo: Ember.computed.or('routeId', 'selectedDateTimeRage.{starttime,endtime}', 'selectedVehicle', 'selectedDriver', 'selectedStartLocation', 'selectedEndLocation', 'selectedStartZone', 'selectedEndZone', 'startAddressIdentifier', 'endAddressIdentifier', 'startAddressNotes', 'endAddressNotes'),
    vehicleOptions: Ember.computed('reconcile.reconcileVehicle', 'selectedVehicle', function () {
      const vehicleData = this.get('reconcile.reconcileVehicle');
      return vehicleData;
    }),
    driverOptions: Ember.computed('reconcile.reconcileDriver', 'selectedDriver', function () {
      const driverData = this.get('reconcile.reconcileDriver');
      return driverData;
    }),
    zoneOptions: Ember.computed('selectedStartZone', 'selectedEndZone', function () {
      const zones = this.store.peekAll('zone');
      const zoneArray = [];
      zones.map(zone => {
        if (zone && zone.displayName) {
          zoneArray.push(zone.displayName);
        }
      });
      return zoneArray;
    }),

    init() {
      this._super();

      Ember.set(this, 'selectedRadioValue', 'start');
      Ember.set(this, 'selectedDateTimeRage', {
        starttime: null,
        endtime: null,
        startofday: (0, _moment.default)(this.reconcileTripDate).startOf('day').toISOString(),
        endofday: (0, _moment.default)(this.reconcileTripDate).endOf('day').toISOString()
      });
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('.side-drawer-modal').width('470px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    saveNewRouteTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const reconcileAddNewRouteAdapter = store.adapterFor('reconcile-route');
      const driverOptions = this.get('selectedDriver');
      const vehicleOptions = this.get('selectedVehicle');
      const starttime = this.get('selectedDateTimeRage').starttime;
      const endTime = this.get('selectedDateTimeRage').endtime;
      const startAddress = this.get('selectedStartLocation');
      const endAddress = this.get('selectedEndLocation');
      const serviceMode = Ember.get(this, 'reconcile.reconcileMode');
      const payload = {
        id: this.routeId,
        type: 'regular',
        serviceMode: serviceMode,
        plannedStartTime: starttime,
        plannedEndTime: endTime,
        providerName: this.reconcileProviderName,
        driver: {
          id: driverOptions.driverId
        },
        vehicle: {
          id: vehicleOptions.vehicleId
        },
        start: {
          address: {
            formattedAddress: startAddress.freeformAddress,
            zone: this.selectedStartZone,
            notes: this.startAddressNotes,
            identifier: this.startAddressIdentifier,
            lat: startAddress.lat,
            lng: startAddress.lng
          }
        },
        end: {
          address: {
            formattedAddress: endAddress.freeformAddress,
            zone: this.selectedEndZone,
            notes: this.endAddressNotes,
            identifier: this.endAddressIdentifier,
            lat: endAddress.lat,
            lng: endAddress.lng
          }
        }
      };

      try {
        return yield reconcileAddNewRouteAdapter.postRoute(payload, payload.type);
      } catch (e) {
        throw e;
      }
    }),

    showToolTip() {
      const tooltip = this.get('tooltip');
      const title = 'Add New Route';
      const tip = 'Create Route ID ' + this.get('routeId') + '?';
      const successMsg = 'SUCCESSFULLY CREATED NEW ROUTE ' + this.get('routeId');
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveNewRouteTask').perform().then(() => {
            this.send('saveRouteSuccess');
            Ember.set(this, 'workspace.activeContext.topActiveContext.resetLocation', true);
            this.get('workspace').popState('reconcileAddNewRoute');
            tooltip.reset();
            this.get('notifications').success(successMsg);
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              case 409:
                message = `The route name ${this.get('routeId')} is already taken, please try another.`;
                break;

              default:
                message = 'There was a problem creating the route, please try again.';
                break;
            }

            this.set('errorMessage', message);
            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    calculateShiftLength() {
      let routeLength = 0;
      const startTime = this.get('selectedDateTimeRage').starttime;
      const endTime = this.get('selectedDateTimeRage').endtime;

      if (startTime && endTime) {
        const duration = _moment.default.duration((0, _moment.default)(endTime).diff((0, _moment.default)(startTime)));

        const hours = parseInt(duration.asHours(), 10) < 10 ? `0${parseInt(duration.asHours(), 10)}` : parseInt(duration.asHours(), 10);
        const minutes = parseInt(duration.minutes(), 10) < 10 ? `0${parseInt(duration.minutes(), 10)}` : parseInt(duration.minutes(), 10);
        routeLength = [hours, minutes].join(':');
      }

      Ember.set(this, 'routeLength', routeLength);
    },

    actions: {
      onUndoClick() {
        const startLocation = Ember.get(this, 'selectedStartLocation');
        const endLocation = Ember.get(this, 'selectedEndLocation');

        if (startLocation || endLocation) {
          this.set('canResetAddress', true);
        }

        this.setProperties({
          routeId: null,
          selectedDriver: null,
          selectedVehicle: null,
          selectedStartLocation: null,
          selectedEndLocation: null,
          selectedRadioValue: 'start',
          driverStartTime: null,
          driverEndTime: null,
          'selectedDateTimeRage.starttime': null,
          'selectedDateTimeRage.endtime': null,
          routeLength: null,
          startAddressIdentifier: null,
          endAddressIdentifier: null,
          selectedStartZone: null,
          selectedEndZone: null,
          startAddressNotes: null,
          endAddressNotes: null
        });
      },

      onSaveClick() {
        if (this.get('routeId')) {
          this.showToolTip();
        }
      },

      onVehicleOptionSelect(option) {
        Ember.set(this, 'selectedVehicle', option);
      },

      onDriverOptionSelect(option) {
        Ember.set(this, 'selectedDriver', option);
        Ember.set(this, 'driverStartTime', option.shiftStartTime);
        Ember.set(this, 'driverEndTime', option.shiftEndTime);
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
        this.calculateShiftLength();
      },

      onChangeDriverShiftTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      },

      onRadioButtonClick(val) {
        this.set('selectedRadioValue', val);
      },

      onChangeStartLocation(record) {
        this.set('selectedStartLocation', record);
      },

      onChangeEndLocation(record) {
        this.set('selectedEndLocation', record);
      },

      onZoneOptionsSelect(isStart, option) {
        if (isStart) {
          Ember.set(this, 'selectedStartZone', option);
        } else {
          Ember.set(this, 'selectedEndZone', option);
        }
      },

      saveRouteSuccess() {
        Ember.set(this, 'workspace.activeContext.topActiveContext.resetLocation', true);
        const recon = Ember.get(this, 'reconcile');
        Ember.get(recon, 'fetchRouteIds').perform();
      }

    }
  });

  _exports.default = _default;
});