define("adept-iq/pods/components/side-drawer/system-config/route-master/vehicle-search-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'vehicle',
    title: 'Vehicle',
    defaultSortId: 'name',
    defaultSortAsc: true,
    queryModelData: true,
    selectFirstRow: true,
    hideWidgetControls: true,
    columns: [{
      id: 'name',
      index: 0,
      type: 'text',
      label: 'ID',
      valuePath: 'name',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'placeholder',
      index: 1,
      type: 'boolean',
      label: 'Placeholder',
      valuePath: 'placeholder',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'providerId',
      index: 2,
      type: 'text',
      label: 'Provider ID',
      valuePath: 'provider.id',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'licensePlate',
      index: 3,
      type: 'text',
      label: 'License Plate',
      valuePath: 'licensePlate',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'vehicleStatus',
      index: 4,
      type: 'text',
      label: 'Vehicle Status',
      valuePath: 'status',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'vin',
      index: 5,
      type: 'text',
      label: 'VIN',
      valuePath: 'vin',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'leaseCompanyName',
      index: 6,
      type: 'text',
      label: 'Lease Company',
      valuePath: 'leaseCompanyName',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'leaseEndDate',
      index: 7,
      type: 'Date',
      label: 'Lease End Date',
      valuePath: 'leaseEndDate',
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      searchable: true
    }, {
      id: 'inService',
      index: 8,
      type: 'boolean',
      label: 'In Service',
      valuePath: 'inService',
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      searchable: true
    }, {
      id: 'vehicleTypeName',
      index: 9,
      type: 'text',
      label: 'Type',
      valuePath: 'vehicleTypeName',
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      searchable: true
    }, {
      id: 'manufacturerName',
      index: 10,
      type: 'boolean',
      label: 'Manufacturer',
      valuePath: 'manufacturerName',
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      searchable: true
    }, {
      id: 'modelYear',
      index: 11,
      type: 'number',
      label: 'Model Year',
      valuePath: 'modelYear',
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      searchable: true
    }, {
      id: 'comments',
      index: 12,
      type: 'text',
      label: 'Comments',
      valuePath: 'comments',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'extraWideLift',
      index: 13,
      type: 'number',
      label: 'XWide Lift',
      valuePath: 'vehicleType.extraWideLift',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'noOfAmbulatorySeats',
      index: 14,
      type: 'number',
      label: 'Amb Seats',
      valuePath: 'noOfAmbulatorySeats',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'noOfWheelChairSeats',
      index: 15,
      type: 'number',
      label: 'WC Seats',
      valuePath: 'noOfWheelChairSeats',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'noOfServiceAnimalSeats',
      index: 16,
      type: 'number',
      label: 'Max Service Animals',
      valuePath: 'noOfServiceAnimalSeats',
      defaultWidth: 100,
      searchable: true
    }]
  };
  _exports.default = _default;
});