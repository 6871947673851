define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'driver',
    title: 'Driver Records',
    defaultSortId: 'id',
    defaultSortAsc: true,
    fetchModelData: false,
    selectFirstRow: true,
    columns: [{
      id: 'id',
      index: 0,
      type: 'uuid',
      label: 'ID',
      valuePath: 'driverId',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'providerID',
      index: 1,
      type: 'text',
      label: 'Provider ID',
      valuePath: 'provider.name',
      defaultWidth: 70,
      searchable: false
    }, {
      id: 'lastName',
      index: 2,
      type: 'text',
      label: 'Last Name',
      valuePath: 'lastName',
      defaultWidth: 70,
      searchable: false
    }, {
      id: 'firstName',
      index: 3,
      type: 'text',
      label: 'First Name',
      valuePath: 'firstName',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'middleInitial',
      index: 4,
      type: 'text',
      label: 'MI',
      valuePath: 'middleInitial',
      defaultWidth: 100,
      format: 'HH:mm',
      searchable: false
    }, {
      id: 'placeholder',
      index: 5,
      type: 'boolean',
      label: 'Placeholder',
      valuePath: 'placeholder',
      defaultWidth: 100,
      searchable: false
    }, {
      id: 'birthDate',
      index: 6,
      type: 'date',
      label: 'Birth Date',
      valuePath: 'birthdate',
      defaultWidth: 100,
      format: 'MM/DD/YYYY',
      searchable: false
    }, {
      id: 'startDate',
      index: 7,
      type: 'date',
      label: 'Start Date',
      valuePath: 'tuesdayAvailability.vehicle.name',
      format: 'MM/DD/YYYY',
      defaultWidth: 100,
      searchable: true
    }]
  };
  _exports.default = _default;
});