define("adept-iq/classes/map-contexts/workspaces/reconcile", ["exports", "adept-iq/classes/map-contexts/layered", "adept-iq/classes/markers/agency", "adept-iq/classes/markers/stops/avlm-stop-point", "adept-iq/classes/polylines/trips/iq", "adept-iq/classes/polylines/routes/planned/iq", "adept-iq/classes/polylines/routes/navigation/iq", "adept-iq/classes/polylines/routes/performed/iq", "adept-iq/classes/polygons/zone", "adept-iq/classes/shapes/zone", "tomtom", "adept-iq/classes/map-contexts/map-configs/reconcile-layers"], function (_exports, _layered, _agency, _avlmStopPoint, _iq, _iq2, _iq3, _iq4, _zone, _zone2, _tomtom, _reconcileLayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _layered.default.extend({
    // must be passed on init
    activeContext: null,
    store: null,
    workspace: null,
    layersConfig: _reconcileLayers.default,
    vehicleCluster: _tomtom.default.L.markerClusterGroup({
      showCoverageOnHover: false
    }),
    polylineData: Ember.computed.readOnly('activeContext.topActiveContext.implicitData'),
    markerData: Ember.computed.readOnly('activeContext.topActiveContext.implicitData'),
    polygonData: Ember.computed.readOnly('activeContext.topActiveContext.implicitData'),
    shapeData: Ember.computed.readOnly('activeContext.topActiveContext.implicitData'),
    layeredRecords: Ember.computed('activeContext.topActiveContext.{implicitData,activeVehicles}', 'layers', function () {
      return this.get('layers').reduce((obj, layer) => {
        obj[layer.id] = this._recordsForLayer(layer);
        return obj;
      }, {});
    }),
    updateMap: Ember.observer('activeContext.topActiveContext.structuredMapData', function () {
      this.notifyPropertyChange('layers');
    }),

    init() {
      this._super(...arguments);

      const store = this.get('store');

      this.focusActiveContext = e => {
        const activeContext = this.get('activeContext.topActiveContext'); // this `iq` object gets attached by the marker/polyline data join

        if (!e.target.iq) return;
        const {
          isActive,
          modelName,
          modelId
        } = e.target.iq;
        if (!isActive) return;
        const record = store.peekRecord(modelName, modelId);
        if (!record) return;
        activeContext.replaceCheckedRecordsByType([record]);
      };
    },

    destroy() {
      this.focusActiveContext = null;

      this._super(...arguments);
    },

    // handle dbl click events for markers
    markerObjectFor()
    /* record */
    {
      const marker = this._super(...arguments);

      if (!marker.get('_dblclickHandlerInitialized')) {
        marker.on('dblclick', this.focusActiveContext);
        marker.set('_dblclickHandlerInitialized', true);
      }

      return marker;
    },

    // handle dbl click events for polylines
    polylineObjectFor() {
      const polyline = this._super(...arguments);

      if (!polyline.get('_dblclickHandlerInitialized')) {
        polyline.on('dblclick', this.focusActiveContext);
        polyline.set('_dblclickHandlerInitialized', true);
      }

      return polyline;
    },

    _recordsForLayer(layer) {
      switch (layer.id) {
        case 'stops':
          return this._recordsForStopsLayer(layer);

        default:
          return this._recordsForOtherLayer(layer);
      }
    },

    _recordsForStopsLayer(layer) {
      const stopPoints = this._recordsForOtherLayer(layer); // if it is a trip active context
      // only include the pick and drop stops


      if (this.get('activeContext.topActiveContext').tripActiveContext()) {
        return stopPoints.filter(stopPoint => {
          return ['pick', 'drop'].includes(stopPoint.get('stopType'));
        });
      }

      return stopPoints;
    },

    _recordsForOtherLayer(layer) {
      if (!layer.isVisible || !layer.isActive) return [];
      const structuredData = this.get('activeContext.topActiveContext.structuredMapData');
      const records = structuredData ? structuredData[layer.modelName] : this.get('store').peekAll(layer.modelName);
      if (Ember.isEmpty(records)) return [];
      return records;
    },

    // used to create marker, polyline, etc objects for data join
    _polygonObjectFor(layer, layerType, record) {
      if (!this._polygonCache) {
        this._polygonCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let polygon = this._polygonCache[id];

      if (!polygon) {
        const polygonClass = this._polygonClassForLayer(layer);

        polygon = polygonClass.create({
          id,
          record
        });
        this._polygonCache[id] = polygon;
      }

      return polygon;
    },

    _markerObjectFor(layer, layerType, record) {
      if (!this._markerCache) {
        this._markerCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let marker = this._markerCache[id];

      if (this._markerCache[id] && (!this._markerCache[id].lat || !this._markerCache[id].lng) || this._markerCache[id] && this._markerCache[id].record && (this._markerCache[id].record.lat !== record.lat || this._markerCache[id].record.lng !== record.lng)) {
        this._markerCache[id].set('record', record);

        marker = this._markerCache[id];
      }

      if (!marker) {
        const markerClass = this._markerClassForLayer(layer);

        marker = markerClass.create({
          id,
          record
        });
        this._markerCache[id] = marker;
      }

      return marker;
    },

    _shapeObjectFor(layer, layerType, record) {
      if (!this._shapeCache) {
        this._shapeCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let shape = this._shapeCache[id];

      if (!shape) {
        const shapeClass = this._shapeClassForLayer(layer);

        shape = shapeClass.create({
          id,
          record
        });
        this._shapeCache[id] = shape;
      }

      return shape;
    },

    _polylineObjectFor(layer, layerType, record) {
      if (!this._polylineCache) {
        this._polylineCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let polyline = this._polylineCache[id];

      if (this._polylineCache[id] && this._polylineCache[id].record && this._polylineCache[id].record.polyline !== record.polyline) {
        this._polylineCache[id].set('record', record);

        polyline = this._polylineCache[id];
      }

      if (!polyline) {
        const polylineClass = this._polylineClassForLayer(layer, layerType);

        polyline = polylineClass.create({
          id,
          record,
          polylineService: this.get('polylineService')
        });
        this._polylineCache[id] = polyline;
      }

      return polyline;
    },

    _markerClassForLayer(layer) {
      switch (layer.id) {
        case 'agency':
          return _agency.default;

        case 'stops':
          return _avlmStopPoint.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    },

    _polylineClassForLayer(layer) {
      switch (layer.id) {
        case 'trips':
          return _iq.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    },

    _routePolylineClassForLayerType(layerType) {
      switch (layerType.id) {
        case 'planned':
          return _iq2.default;

        case 'navigation':
          return _iq3.default;

        case 'performed':
          return _iq4.default;

        default:
          throw new Error(`unhandled route layerType id '${layerType.id}'`);
      }
    },

    _shapeClassForLayer(layer) {
      switch (layer.id) {
        case 'zones':
          return _zone2.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    },

    _polygonClassForLayer(layer) {
      switch (layer.id) {
        case 'zones':
          return _zone.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    }

  });

  _exports.default = _default;
});