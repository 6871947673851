define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/employment-status/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['driver-status'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tableRef: null,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    description: null,
    isNewEmployee: false,
    editAPI: null,
    disableForm: true,
    refreshRecord: true,

    //To refresh on undoRecord and newRecord
    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveEmploymentStatusRecord = this.get('onSaveEmploymentStatusRecord');
      const onNewEmploymentStatusRecord = this.get('onNewEmploymentStatusRecord').bind(this);
      const onEditEmploymentStatusRecord = this.get('onEditEmploymentStatusRecord').bind(this);
      const onDeleteEmploymentStatusRecord = this.get('onDeleteEmploymentStatusRecord');
      const onUndoEmploymentStatusRecord = this.get('onUndoEmploymentStatusRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveEmploymentStatusRecord,
          newAction: onNewEmploymentStatusRecord,
          deleteAction: onDeleteEmploymentStatusRecord,
          editAction: onEditEmploymentStatusRecord,
          undoAction: onUndoEmploymentStatusRecord
        });
        this.buttonSettings();
      });
    },

    buttonSettings(deleteBtn = false, editBtn = false, saveBtn = true, undoBtn = true, newBtn = false) {
      const editAPI = this.get('editAPI');
      editAPI.setProperties({
        deleteBtn,
        editBtn,
        saveBtn,
        undoBtn,
        newBtn
      });
    },

    employeeStatusChangeSet: Ember.computed('selectedRecord', 'refreshRecord', function () {
      const selectedRecord = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));

      if (selectedRecord) {
        this.onSelectTableRow(selectedRecord);
      }

      return this.buildChangeSet(selectedRecord);
    }),

    buildChangeSet(record = null) {
      const displayName = record ? record.get('displayName') : '';
      return new _emberChangeset.default(Ember.Object.create({
        displayName: displayName
      }));
    },

    onSaveEmploymentStatusRecord: (0, _emberConcurrency.task)(function* () {
      const value = {
        displayName: this.get('displayName')
      };
      const name = this.get('displayName');
      const isNewEmployee = this.get('isNewEmployee');
      const store = this.get('store');
      const notifications = this.get('notifications');
      let record = null;

      if (isNewEmployee) {
        const data = {
          category: 'config-System_Configuration-driver_statuses',
          type: 'object',
          name,
          displayName: name,
          value
        };
        record = this.get('store').createRecord('cs-config-item', data);
      } else {
        const id = `config-System_Configuration-driver_statuses/${this.get('selectedRecord.id')}`;
        record = store.peekRecord('cs-config-item', id);
        record.set('value', value);
        record.set('name', name);
        record.set('displayName', name);
      }

      try {
        yield record.save();
        notifications.success('Lookup record successfully saved.');
        this.set('isNewEmployee', false);
        this.set('disableForm', true);
        this.get('tableRef').refreshData(); //to refresh table data

        this.buttonSettings();
      } catch (e) {
        notifications.warning('Lookup record failed to save.');
        console.warn(e); // eslint-disable-line
      }
    }).drop(),
    onDeleteTask: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');

      try {
        const id = `config-System_Configuration-driver_statuses/${this.get('selectedRecord.id')}`;
        const record = this.store.peekRecord('cs-config-item', id);
        yield record.destroyRecord();
        notifications.success('Lookup record successfully deleted.');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (error) {
        console.error(error); // eslint-disable-line

        notifications.warning('Lookup record failed to save.');
      }
    }).drop(),

    onNewEmploymentStatusRecord() {
      this.set('isNewEmployee', true);
      this.get('tableRef').onUncheckAll();
      this.set('disableForm', false);
      this.toggleProperty('refreshRecord');
      this.buttonSettings(true, true, false, false, true);
    },

    onEditEmploymentStatusRecord() {
      this.set('isNewEmployee', false);
      this.set('disableForm', false);
      this.buttonSettings(true, true, false, false, true);
    },

    onDeleteEmploymentStatusRecord: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      yield tooltip.pushConfirmation({
        title: 'Look UP',
        hasoverlay: true,
        tip: 'Are you sure you want to delete this record?',
        primaryActionText: 'Confirm',
        primaryAction: () => {
          const store = this.get('store');
          const displayName = this.get('displayName') || '';

          if (displayName) {
            const driver = store.peekAll('driver').filter(record => record.get('active') && record.get('driverStatuses.displayName') === displayName);

            if (driver.length) {
              tooltip.pushConfirmation({
                title: 'Look UP',
                hasoverlay: true,
                tip: `There are ${driver.length} active driver(s) with this Driver Employment Status description. You must either change the Driver Employment Status description on these driver(s) or delete these driver(s) prior to changing the Driver Employment Status description.`,
                primaryActionText: 'OK',

                primaryAction() {
                  tooltip.reset();
                }

              });
            }
          }

          return this.get('onDeleteTask').perform().then(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        }

      });
    }).drop(),

    onUndoEmploymentStatusRecord() {
      this.toggleProperty('refreshRecord');
    },

    actions: {
      onTextChange(record, path, event) {
        const value = event.target.value;
        record.set(path, value);
        this.set('displayName', value);
        this.onSelectedRecordDirty(record.get('isDirty'), record);
      }

    }
  });

  _exports.default = _default;
});