define("adept-iq/models/route", ["exports", "ember-data", "adept-iq/mixins/otp-label", "moment"], function (_exports, _emberData, _otpLabel, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ROUTE_SCHEDULED_STATUS = void 0;
  // Scheduling Route Entity
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  const ROUTE_SCHEDULED_STATUS = 'scheduled';
  _exports.ROUTE_SCHEDULED_STATUS = ROUTE_SCHEDULED_STATUS;
  const otpStatusMapper = {
    'O': 'On Time',
    'D': 'In Danger',
    'L': 'Late',
    'E': 'Early'
  };

  var _default = Model.extend(_otpLabel.default, {
    session: Ember.inject.service(),
    affinities: attr(),
    name: attr('string'),
    plannedStartTime: attr('date'),
    plannedEndTime: attr('date'),
    type: attr('string', {
      defaultValue: 'regular'
    }),
    schedulingMode: attr('string'),
    optimizationFlags: attr('number', {
      defaultValue: 3
    }),
    // bit field
    tripLimit: attr('number', {
      defaultValue: 9
    }),
    distanceLimit: attr('number', {
      defaultValue: 99
    }),
    dispatchRoute: belongsTo('dispatch-route'),
    etaRoute: belongsTo('etanav-route'),
    navigationPolyline: belongsTo('polyline'),
    scheduledPolyline: belongsTo('schedule-polyline'),
    vehicle: belongsTo('vehicle'),
    schedule: belongsTo('schedule'),
    provider: belongsTo('provider'),
    routeTemplate: belongsTo('route-template'),
    trips: hasMany('trip'),
    routeBreaks: hasMany('route-break'),
    cannedMessages: hasMany('avlm-canned-message'),
    avlmAlert: hasMany('avlm-alert'),
    emergencyMessages: hasMany('avlm-emergency-message'),
    stopPoints: hasMany('stop-point'),
    // used for pullout and pullin
    replacedRoute: belongsTo('route', {
      inverse: null
    }),
    scheduleRouteLock: belongsTo('lock'),
    // populated by route-exec-event
    status: attr('string'),
    otp: attr('string'),
    otpValue: attr('number'),
    odometer: attr('string'),
    // in meters
    actualStartTimestamp: attr('date'),
    actualEndTimestamp: attr('date'),
    isAdjusted: attr('boolean', {
      defaultValue: false
    }),
    isDriverBreakRemoved: attr('boolean', {
      defaultValue: false
    }),
    noOfTrips: Ember.computed.readOnly('trips.length'),
    noOfRouteBreaks: Ember.computed.readOnly('routeBreaks.length'),
    hasRouteTemplateConsolidated: Ember.computed.readOnly('routeTemplate.isConsolidateRoute'),
    hasRouteTemplateAdjusted: Ember.computed.readOnly('routeTemplate.isAdjustBreak'),
    isScheduled: Ember.computed.equal('status', ROUTE_SCHEDULED_STATUS),
    canShowRemoveDriverBreak: Ember.computed('adjustedStartTime', 'adjustedEndTime', 'routeBreaks', 'isDriverBreakRemoved', function () {
      const adjustedStartTime = this.get('adjustedStartTime');
      const adjustedEndTime = this.get('adjustedEndTime');
      const isDriverBreakRemoved = this.get('isDriverBreakRemoved');
      const routeBreaks = this.get('routeBreaks');
      return adjustedStartTime && adjustedEndTime && !isDriverBreakRemoved && !Ember.isEmpty(routeBreaks);
    }),
    containsSlack: Ember.computed('plannedStartTime', 'plannedEndTime', 'adjustedStartTime', 'adjustedEndTime', function () {
      const plannedStartTime = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEndTime = (0, _moment.default)(this.get('plannedEndTime'));
      const newStart = (0, _moment.default)(this.get('adjustedStartTime'));
      const newEnd = (0, _moment.default)(this.get('adjustedEndTime'));
      return !plannedStartTime.isSame(newStart) || !plannedEndTime.isSame(newEnd);
    }),
    containsAdjustedTime: Ember.computed('adjustedStartTime', 'adjustedEndTime', function () {
      const newStart = this.get('adjustedStartTime');
      const newEnd = this.get('adjustedEndTime');
      return !!newStart && !!newEnd;
    }),
    otpValueWithSign: Ember.computed('otp', 'otpValue', function () {
      const otp = this.get('otp');
      const otpValue = this.get('otpValue');
      const sign = otp === 'O' || otp === 'E' || otp === otpStatusMapper.O || otp === otpStatusMapper.E ? '-' : '+';

      if (!Ember.isEmpty(otpValue)) {
        return otpValue !== 0 ? `${sign}${Math.abs(otpValue)}` : Math.abs(otpValue);
      }

      return '';
    }),
    tripStartTime: Ember.computed('trips.@each.tripStartTime', 'plannedStartTime', function () {
      const trips = this.get('trips');
      let min = this.get('plannedStartTime') ? this.get('plannedStartTime').getTime() : null;

      if (!Ember.isEmpty(trips)) {
        trips.forEach(trip => {
          if (trip && trip.get('tripStartTime')) {
            min = Math.min(trip.get('tripStartTime').getTime(), min);
          }
        });
      }

      return min ? new Date(min) : null;
    }),
    tripEndTime: Ember.computed('trips.@each.tripEndTime', 'plannedEndTime', function () {
      const trips = this.get('trips');
      let max = this.get('plannedEndTime') ? this.get('plannedEndTime').getTime() : null;

      if (!Ember.isEmpty(trips)) {
        trips.forEach(trip => {
          if (trip && trip.get('tripEndTime')) {
            max = Math.max(trip.get('tripEndTime').getTime(), max);
          }
        });
      }

      return max ? new Date(max) : null;
    }),
    travelNeedCounts: Ember.computed('dispatchRoute.maxTravelNeedCounts', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0
      };
      const travelNeedCounts = this.get('dispatchRoute.maxTravelNeedCounts');
      return travelNeedCounts ? travelNeedCounts : initialCounts;
    }),
    shiftLength: Ember.computed('plannedStartTime', 'plannedEndTime', function () {
      const plannedStartTime = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEndTime = (0, _moment.default)(this.get('plannedEndTime'));

      const duration = _moment.default.duration(plannedEndTime.diff(plannedStartTime));

      return Math.floor(duration.asHours());
    }),
    routeMinutes: Ember.computed('adjustedStartTime', 'adjustedEndTime', function () {
      const adjustedStartTime = (0, _moment.default)(this.get('adjustedStartTime'));
      const adjustedEndTime = (0, _moment.default)(this.get('adjustedEndTime'));

      const duration = _moment.default.duration(adjustedEndTime.diff(adjustedStartTime));

      return duration.isValid() ? Math.floor(duration.asMinutes()) : null;
    }),
    plannedDriverBreaks: Ember.computed('routeBreaks', 'routeBreaks.@each.isRemoved', function () {
      const routeBreaks = this.get('routeBreaks') || [];
      let count = 0;
      routeBreaks.forEach(routeBreak => {
        if (!routeBreak.isRemoved) {
          count = count + 1;
        }
      });
      if (count === 0) return '';
      return count;
    }),
    isLocked: Ember.computed('scheduleRouteLock.{isLocked,owner.firstName}', function () {
      return this.get('scheduleRouteLock.isLocked');
    }),
    lockedBy: Ember.computed('scheduleRouteLock.owner.firstName', function () {
      return `${this.get('scheduleRouteLock.owner.firstName')} ${this.get('scheduleRouteLock.owner.lastName')}`;
    }),
    isLockedByOtherUser: Ember.computed('scheduleRouteLock.isLocked', function () {
      const userId = this.get('session').data.authenticated.userId;
      return this.get('scheduleRouteLock.isLocked') && this.get('scheduleRouteLock.owner.id') !== userId;
    })
  });

  _exports.default = _default;
});