define("adept-iq/models/cluster", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    state: attr('string'),
    routeOrdinal: attr('number'),
    dispatchRoute: belongsTo('dispatch-route'),
    dispatchSchedule: belongsTo('dispatch-schedule'),
    stopPoints: hasMany('stop-point'),
    startTime: Ember.computed.alias('dispatchRoute.plannedStartTime'),
    endTime: Ember.computed.alias('dispatchRoute.plannedEndTime'),
    orderedStops: Ember.computed.sort('stopPoints', 'orderedStopsSortKeys'),
    orderedStopsSortKeys: ['routeOrdinal'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    tripStartTime: Ember.computed('stopPoints.@each.tripStartTime', 'startTime', function () {
      const stopPoints = this.get('stopPoints').toArray();
      const validDate = this.get('isValidDate');
      let min = null;

      if (!Ember.isEmpty(stopPoints)) {
        stopPoints.forEach(stopPoint => {
          const tripStartTime = stopPoint.get('tripStartTime');

          if (min && validDate(tripStartTime)) {
            const tripTime = new Date(tripStartTime);
            min = Math.min(tripTime.getTime(), min);
          } else if (validDate(tripStartTime)) {
            const tripTime = new Date(tripStartTime);
            min = tripTime.getTime();
          }
        });
      } else {
        min = validDate(this.get('startTime')) ? new Date(this.get('startTime')).getTime() : null;
      }

      return min ? new Date(min) : null;
    }),
    tripEndTime: Ember.computed('stopPoints.@each.tripEndTime', 'endTime', function () {
      const stopPoints = this.get('stopPoints').toArray();
      const validDate = this.get('isValidDate');
      let max = validDate(this.get('endTime')) ? new Date(this.get('endTime')).getTime() : null;

      if (!Ember.isEmpty(stopPoints)) {
        stopPoints.forEach(stopPoint => {
          if (validDate(stopPoint.get('tripEndTime'))) {
            const tripEndTime = new Date(stopPoint.get('tripEndTime'));
            max = Math.max(tripEndTime.getTime(), max);
          }
        });
      }

      return max ? new Date(max) : null;
    }),
    maxTravelNeedCounts: Ember.computed('orderedStops', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0
      };
      const maxCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0
      };
      const orderedStops = this.get('orderedStops');
      if (Ember.isEmpty(orderedStops)) return null;
      orderedStops.forEach(stopPoint => {
        const travelNeedCounts = stopPoint.get('trip.travelNeedCounts');
        const stopType = stopPoint.get('stopType');
        if (Ember.isEmpty(travelNeedCounts)) return maxCounts;

        if (stopType === 'pick') {
          Object.entries(travelNeedCounts).forEach(([travelNeedTypeName]) => {
            if (Ember.isNone(initialCounts[travelNeedTypeName])) return;
            initialCounts[travelNeedTypeName] += travelNeedCounts[travelNeedTypeName];
          });
        } else if (stopType === 'drop') {
          Object.entries(travelNeedCounts).forEach(([travelNeedTypeName]) => {
            if (Ember.isNone(initialCounts[travelNeedTypeName])) return;
            initialCounts[travelNeedTypeName] -= travelNeedCounts[travelNeedTypeName];
          });
        }

        Object.entries(initialCounts).forEach(([travelNeedTypeName, count]) => {
          maxCounts[travelNeedTypeName] = Math.max(maxCounts[travelNeedTypeName], count);
        });
      });
      return maxCounts;
    }),

    isValidDate(dateTime) {
      const daTime = new Date(dateTime);
      return daTime instanceof Date && !isNaN(daTime);
    }

  });

  _exports.default = _default;
});