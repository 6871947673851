define("adept-iq/pods/components/generic-components/availabilities/component", ["exports", "ember-changeset-cp-validations", "ember-changeset", "lodash", "moment"], function (_exports, _emberChangesetCpValidations, _emberChangeset, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const daysOfWeek = [{
    day: 'monday',
    display: 'Mon',
    disabled: false
  }, {
    day: 'tuesday',
    display: 'Tues',
    disabled: false
  }, {
    day: 'wednesday',
    display: 'Wed',
    disabled: false
  }, {
    day: 'thursday',
    display: 'Thurs',
    disabled: false
  }, {
    day: 'friday',
    display: 'Fri',
    disabled: false
  }, {
    day: 'saturday',
    display: 'Sat',
    disabled: false
  }, {
    day: 'sunday',
    display: 'Sun',
    disabled: false
  }];

  var _default = Ember.Component.extend({
    classNames: ['availablities'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    daysOfWeek,
    selectedRouteTemplateAvailabilities: null,
    availablities: null,
    startTime: null,
    shouldDisplayValidations: true,
    refreshData: Ember.computed('refreshRecord', function () {
      return this.resetAvailability();
    }),

    async init() {
      this._super(...arguments);

      this.resetAvailability();
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('availabilityRef', this);
    },

    configureAvailabilities(availability) {
      const {
        validateFn,
        validationMap
      } = (0, _emberChangesetCpValidations.buildChangeset)(availability);
      return new _emberChangeset.default(availability, validateFn, validationMap);
    },

    resetAvailability() {
      const availablities = this.get('availablities') || [];
      const dayOptions = this.get('daysOfWeek') || [];
      availablities.forEach(availability => {
        const DOWSelected = [];
        dayOptions.forEach(option => {
          if (availability.get(option.day)) {
            Ember.set(option, 'disabled', true);
            DOWSelected.pushObject(option);
          }
        });
        availability.setProperties({
          DOWSelected
        });
      });
      const daysAvailable = dayOptions.every(day => day.disabled);
      this.set('disableAddAvailabilityBtn', daysAvailable);
      return true;
    },

    calcEndDate(availablities, routeLength) {
      const start = availablities.get('shiftStart');
      if (!start || !routeLength) return null;
      const clone = (0, _moment.default)(start);
      const duration = routeLength.split(':');
      const hours = duration[0];
      const minutes = duration.length > 1 ? duration[1] : 0;
      clone.add(hours, 'hours');
      clone.add(minutes, 'minutes');
      return clone.toDate();
    },

    async onDateChange(record, path, dateStr) {
      record.set(path, dateStr);
      const calcEndDate = this.get('calcEndDate')(record, record.get('routeLength'));
      record.set('calcEndDate', calcEndDate);
    },

    actions: {
      onRouteLengthChange: async function (availablities, maskObj) {
        const routeLength = maskObj.masked;
        const oldVal = availablities.get('routeLength'); // This method gets called multiple times (even while hitting a ENTER key). So If oldValue is equal to newValue then no need to validate

        if (routeLength === oldVal) {
          return;
        }

        let value = maskObj.masked.replace(/_/img, '0');
        if (!value) value = '00:00';
        availablities.set('routeLength', routeLength);
        const calcEndDate = this.get('calcEndDate')(availablities, value);
        availablities.set('calcEndDate', calcEndDate);
      },

      async onDOWChange(availablities, options) {
        const sortedAvailabilities = this.get('availablities').filter(avail => avail !== availablities);
        const dayOptions = this.get('daysOfWeek') || [];
        const DOWSelected = [];
        dayOptions.forEach(day => {
          availablities.set(day.day, false);
        });
        let deselectedDayOptions = (0, _lodash.difference)(dayOptions, options);
        options.forEach(option => {
          availablities.set(option.day, true);
          const findDay = dayOptions.find(dayOption => dayOption.day === option.day);
          Ember.set(findDay, 'disabled', true);
          DOWSelected.pushObject(findDay);
        });
        sortedAvailabilities.forEach(avail => {
          deselectedDayOptions = (0, _lodash.difference)(deselectedDayOptions, avail.get('DOWSelected')) || [];
        });
        deselectedDayOptions.forEach(option => {
          Ember.set(option, 'disabled', false);
        }); // no available options remain

        if (!deselectedDayOptions.length) {
          this.set('disableAddAvailabilityBtn', true);
        } else {
          this.set('disableAddAvailabilityBtn', false);
        }

        availablities.set('DOWSelected', DOWSelected);
      },

      onDateChange(record, path, value) {
        this.onDateChange(record, path, value);
      },

      addNewAvailability() {
        const store = this.get('store');
        const modelName = this.get('modelName');
        const addAvailability = store.createRecord(modelName);
        const availability = this.configureAvailabilities(addAvailability);
        this.get('availablities').pushObject(availability);
        this.resetAvailability();
      },

      onRemoveAvailability(availability) {
        const dayOptions = this.get('daysOfWeek');
        const DOWSelected = availability.get('DOWSelected');
        const deselectedDayOptions = (0, _lodash.intersection)(dayOptions, DOWSelected) || [];
        deselectedDayOptions.forEach(option => {
          Ember.set(option, 'disabled', false);
        });
        availability.set('isDeleted', true); // no available options remain

        if (deselectedDayOptions.length || Ember.isEmpty(deselectedDayOptions)) {
          this.set('disableAddAvailabilityBtn', false);
        } else {
          this.set('disableAddAvailabilityBtn', true);
        }
      }

    }
  });

  _exports.default = _default;
});