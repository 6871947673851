define("adept-iq/pods/components/form-components/datetimeflatpickr/component", ["exports", "moment", "adept-iq/config/environment", "adept-iq/utils/unwrapProxy"], function (_exports, _moment, _environment, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['form-components-datetimeflatpickr'],
    editModal: Ember.inject.service(),
    value: null,
    disabled: false,
    defaultDate: null,
    maxDate: null,
    minDate: null,
    clickOpens: true,
    format: null,
    extra: null,
    readonlyCell: false,
    minTimePath: readOnly('extra.minTimePath'),
    maxTimePath: readOnly('extra.maxTimePath'),
    routeStartFlag: readOnly('extra.routeStartFlag'),

    init() {
      this._super(...arguments);

      const record = (0, _unwrapProxy.unwrapProxy)(this.get('record')); //this "path"/flag is set only to allow this component to know that it is the edit form,
      //otherwise we end up with both start and end datetimeflatpickrs made read-only, when it should just be start

      const routeStartFlag = this.get('routeStartFlag');
      const computedReadOnlyCell = record.get('computedReadOnlyCell') && routeStartFlag;
      const minTimePath = this.get('minTimePath');
      const maxDateTimePath = this.get('maxTimePath');

      const format = this.get('format') || _environment.default.dateTimeFormat.dateTimeFlatPickr; // time needs to be inclusive, so we cut seconds off


      const minDateValue = minTimePath ? record.get(`${minTimePath}`) : this.get('value');
      const maxDateValue = maxDateTimePath ? record.get(`${maxDateTimePath}`) : null;
      const minDate = _moment.default.isMoment(minDateValue) ? minDateValue.toDate() : minDateValue;
      let maxDate = null;

      if (maxDateValue) {
        maxDate = _moment.default.isMoment(maxDateValue) ? maxDateValue.toDate() : maxDateValue;
      }

      this.set('dateFormat', format);
      this.set('minDate', minDate);
      this.set('maxDate', maxDate);
      this.set('readonlyCell', computedReadOnlyCell || this.get('readonlyCell'));

      if (this.get('readonlyCell')) {
        this.set('clickOpens', false);
      }
    },

    actions: {
      isValidDate(value) {
        const newValueMoment = (0, _moment.default)(value);

        if (newValueMoment._isValid) {
          this.set('value', newValueMoment.toDate());
        }
      },

      onChangeDate(value) {
        this.get('onChange')(value[0]);
      },

      onClose(currentValue, stringValue, datePicker) {
        const newValueMoment = (0, _moment.default)(stringValue);
        const hasMinDate = this.get('minDate');
        const hasMaxDate = this.get('maxDate');

        if (!hasMinDate && !hasMaxDate) {
          if (!newValueMoment._isValid) {
            this.set('editModal.errors', ['Date entered is invalid.']);
          }
        }

        if (datePicker.config.allowInput && datePicker._input.value && newValueMoment._isValid) {
          this.set('value', newValueMoment.toDate());
        }
      }

    }
  });

  _exports.default = _default;
});