define("adept-iq/pods/components/iq-widgets/route-information-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: 'Reconcile',
    DRIVER_PHONE_NUMBER: 100,
    ODOMETER_START: 101,
    ODOMETER_END: 102,
    ROUTE_NOTES: 103,
    ROUTE_START_IDENTIFIER: 104,
    ROUTE_START_NOTES: 105,
    ROUTE_END_IDENTIFIER: 106,
    ROUTE_END_NOTES: 107,
    routeActionTypes: [{
      id: 1,
      name: 'Verify Route'
    }, {
      id: 2,
      name: 'Reopen Route'
    }, {
      id: 3,
      name: 'Report Breakdown'
    }, {
      id: 4,
      name: 'Breakdown Replacement'
    }, {
      id: 5,
      name: 'Rescue'
    }],
    routeTypes: [{
      id: 1,
      name: 'Regular'
    }, {
      id: 2,
      name: 'Breakdown'
    }, {
      id: 3,
      name: 'Breakdown Replacement'
    }, {
      id: 4,
      name: 'Rescue'
    }, {
      id: 5,
      name: 'Rescue Replacement'
    }, {
      id: 6,
      name: 'Keeper'
    }]
  };
  _exports.default = _default;
});