define("adept-iq/serializers/rider-external", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONSerializer
  } = _emberData.default;

  var _default = JSONSerializer.extend({
    booking: Ember.inject.service(),

    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      this.set('booking.hasMoreExternalRiders', payload.moreResults);
      return this._super(store, primaryModelClass, payload.riders, id, requestType);
    }

  });

  _exports.default = _default;
});