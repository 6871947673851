define("adept-iq/serializers/zip-code", ["exports", "adept-iq/serializers/cs-config-model"], function (_exports, _csConfigModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigModel.default.extend({
    modelName: 'zip-code',

    normalizeAttributes(attributes) {
      if (Ember.isPresent(attributes)) {
        attributes.zipCodes = Array.isArray(attributes.zipCodes) ? attributes.zipCodes : JSON.parse(attributes.zipCodes);
      }

      return attributes;
    }

  });

  _exports.default = _default;
});