define("adept-iq/pods/components/side-drawer/reconcile-edit-trip/component", ["exports", "moment", "adept-iq/pods/components/side-drawer/reconcile-edit-trip/stubs", "ember-concurrency"], function (_exports, _moment, _stubs, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['reconcile-widget-base'],
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    stubs: _stubs.default,
    defaultFare: 2.75,
    onCloseClick: null,
    serviceModeTaxi: 'Taxi',
    travelNeedsOptionsCount: 0,
    travelNeedsGroups: null,
    isEnableCheckbox: false,
    travelNeedsOpions: _stubs.default.travelNeeds,
    selectedDate: Ember.computed.alias('reconcile.reconcileDate'),
    reconcileMode: Ember.computed.alias('reconcile.reconcileMode'),
    tripDetails: Ember.computed.alias('reconcile.reconcileTrip'),
    passengerDetails: Ember.computed.alias('reconcile.reconcilePassenger'),
    reconcilePrice: Ember.computed.alias('reconcile.reconcilePrice'),
    pickupAddress: Ember.computed.alias('reconcile.reconcilePickupAddress'),
    dropoffAddress: Ember.computed.alias('reconcile.reconcileDropOffAddress'),
    pickupStop: Ember.computed.alias('reconcile.reconcilePickupStop'),
    dropoffStop: Ember.computed.alias('reconcile.reconcileDropOffStop'),
    callBack: Ember.computed.readOnly('workspace.topOptions.action'),
    selectedTrip: Ember.computed.alias('workspace.topOptions.trip'),
    isEditStart: Ember.computed.alias('isEditingStart'),
    reconcileRouteStartTime: Ember.computed.readOnly('reconcile.reconcileRouteDetails.actualStartTime'),
    reconcileRouteEndTime: Ember.computed.readOnly('reconcile.reconcileRouteDetails.actualEndTime'),
    canPlus: Ember.computed('travelNeedsOpions', 'travelNeedsOptionsCount', function () {
      return Ember.get(this, 'travelNeedsOptionsCount') < Ember.get(this, 'travelNeedsOpions').length;
    }),
    pickupArriveTime: Ember.computed('pickupStop', function () {
      return Ember.get(this, 'pickupStop.actualArriveTime');
    }),
    dropoffArriveTime: Ember.computed('dropoffStop', function () {
      return Ember.get(this, 'dropoffStop.actualArriveTime');
    }),
    tripDate: Ember.computed('tripDetails.serviceStartTime', function () {
      const selectedTripDate = Ember.get(this, 'tripDetails.serviceStartTime');

      if (selectedTripDate) {
        return (0, _moment.default)(selectedTripDate).format('MM-DD-YYYY');
      }

      const reconcileDate = Ember.get(this, 'reconcile.reconcileDate');

      if (reconcileDate) {
        return (0, _moment.default)(reconcileDate).format('MM-DD-YYYY');
      }

      return '';
    }),
    anchorOptions: Ember.computed('workspaceData', 'selectedAnchor', function () {
      const options = [];
      const anchorOptionsData = _stubs.default.anchorData;
      anchorOptionsData.map(item => {
        options.push(item);
      });
      return options;
    }),
    companionOptions: Ember.computed('workspaceData', 'selectedNoOfCompanions', function () {
      const options = [];
      const companions = _stubs.default.companions;
      companions.map(item => {
        options.push(item);
      });
      return options;
    }),
    serviceNeedOptions: Ember.computed('workspaceData', 'selectedNeedsOptions', function () {
      return _stubs.default.usedAttributes;
    }),

    init() {
      this._super(...arguments);

      this.travelNeedsGroups = [];
      Ember.set(this, 'shiftTimeRange', {
        pickTime: null,
        dropTime: null,
        startforendtime: (0, _moment.default)(this.tripDate).startOf('day').toISOString(),
        startofday: (0, _moment.default)(this.tripDate).startOf('day').toISOString(),
        endofday: (0, _moment.default)(this.tripDate).endOf('day').toISOString()
      });
      this.updateValues();
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('.side-drawer-modal').width('470px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    updateValues() {
      const passDetails = this.get('passengerDetails');
      const pickupNotes = this.get('pickupAddress.notes');
      const dropoffNotes = this.get('dropoffAddress.notes');
      const tripDetails = this.get('tripDetails');
      this.setProperties({
        isEditingStart: false,
        selectedAnchor: {
          id: 'pick',
          'name': 'Pick'
        },
        'shiftTimeRange.pickTime': (0, _moment.default)(this.pickupArriveTime).toISOString(),
        'shiftTimeRange.dropTime': (0, _moment.default)(this.dropoffArriveTime).toISOString(),
        selectedNoOfCompanions: {
          id: tripDetails.cmp,
          name: tripDetails.cmp,
          value: tripDetails.cmp
        },
        wheelChairOption: {
          id: 'wheelchair',
          name: 'Wheelchair',
          value: tripDetails.wcSeats
        },
        selectedNeedsOptions: {
          id: 'ambulatory',
          name: 'Ambulatory',
          value: tripDetails.ambSeats
        },
        passengerDetails: passDetails,
        isDisableDatePicker: true,
        pickNotes: pickupNotes,
        dropNotes: dropoffNotes,
        loadTime: tripDetails.loadTime,
        unloadTime: tripDetails.unloadTime,
        isEnablePCA: tripDetails.pca !== 0,
        isEnableCheckbox: tripDetails.pca !== 0,
        defaultPcaCount: tripDetails.pca,
        isServiceAnimalEnable: tripDetails.serviceAnimal !== 0,
        defaultServiceAnimalCount: tripDetails.serviceAnimal,
        displayWheelChairOption: this.tripDetails.wcSeats !== 0,
        selectedPickLocation: {
          freeformAddress: this.get('pickupAddress.formattedAddress'),
          lat: Ember.get(this, 'pickupAddress.lat'),
          lng: Ember.get(this, 'pickupAddress.lng')
        },
        selectedDropLocation: {
          freeformAddress: this.get('dropoffAddress.formattedAddress'),
          lat: Ember.get(this, 'dropoffAddress.lat'),
          lng: Ember.get(this, 'dropoffAddress.lng')
        }
      });
      this.manageDefaultTravelNeeds(tripDetails);
      this.calculateFare();
    },

    calculateFare() {
      let fare = 0;
      const wheelchairOption = Ember.get(this, 'wheelChairOption');
      const ambulatoryOption = Ember.get(this, 'selectedNeedsOptions');

      if (wheelchairOption && wheelchairOption.value > 0) {
        fare += wheelchairOption.value * this.defaultFare;
      }

      if (ambulatoryOption && ambulatoryOption.value > 0) {
        fare += ambulatoryOption.value * this.defaultFare;
      }

      Ember.set(this, 'expectedFair', `$${fare}`);
      return fare;
    },

    handleAmbulatoryCount() {
      const pcaOption = Ember.get(this, 'isEnablePCA');
      const companionOption = Ember.get(this, 'selectedNoOfCompanions');
      const wheelchairOption = Ember.get(this, 'wheelChairOption');
      let value = this.defaultAmbulatoryCount; // eslint-disable-next-line no-nested-ternary

      value = pcaOption ? this.defaultPcaCount + (companionOption ? companionOption.value : 0) : companionOption ? companionOption.value : 0;
      value = wheelchairOption.value ? value : value + 1;
      Ember.set(this, 'selectedNeedsOptions', {
        id: 'ambulatory',
        name: 'Ambulatory',
        value
      }); // this.calculateFare();
    },

    manageDefaultTravelNeeds(tripDetails) {
      Ember.set(this, 'travelNeedsOptionsCount', 0);
      Ember.set(this, 'travelNeedsGroups', []);

      if (!Ember.isEmpty(tripDetails.braces)) {
        Ember.set(this, 'travelNeedsOptionsCount', Ember.get(this, 'travelNeedsOptionsCount') + 1);
        this.travelNeedsGroups.pushObject({
          id: Ember.get(this, 'travelNeedsOptionsCount'),
          options: this.travelNeedsOpions,
          selected: {
            id: 'braces',
            name: 'Braces',
            value: tripDetails.braces
          }
        });
      }

      if (!Ember.isEmpty(tripDetails.supportCane)) {
        Ember.set(this, 'travelNeedsOptionsCount', Ember.get(this, 'travelNeedsOptionsCount') + 1);
        this.travelNeedsGroups.pushObject({
          id: Ember.get(this, 'travelNeedsOptionsCount'),
          options: this.travelNeedsOpions,
          selected: {
            id: 'supportCane',
            name: 'Support Cane',
            value: tripDetails.supportCane
          }
        });
      }

      if (!Ember.isEmpty(tripDetails.crutches)) {
        Ember.set(this, 'travelNeedsOptionsCount', Ember.get(this, 'travelNeedsOptionsCount') + 1);
        this.travelNeedsGroups.pushObject({
          id: Ember.get(this, 'travelNeedsOptionsCount'),
          options: this.travelNeedsOpions,
          selected: {
            id: 'crutches',
            name: 'Crutches',
            value: tripDetails.crutches
          }
        });
      }

      if (!Ember.isEmpty(tripDetails.doubleWheelchair)) {
        Ember.set(this, 'travelNeedsOptionsCount', Ember.get(this, 'travelNeedsOptionsCount') + 1);
        this.travelNeedsGroups.pushObject({
          id: Ember.get(this, 'travelNeedsOptionsCount'),
          options: this.travelNeedsOpions,
          selected: {
            id: 'doubleWheelchair',
            name: 'Double Wheelchair',
            value: tripDetails.doubleWheelchair
          }
        });
      }
    },

    getStopTime(isPickup) {
      let stopTime = 0;

      if (isPickup) {
        stopTime = this.pickupStop.stopTime;
      } else {
        stopTime = this.dropoffStop.stopTime;
      }

      return stopTime;
    },

    getDepartTime(dateTime, isPickup) {
      const date = (0, _moment.default)(dateTime).format('MM-DD-YYYY');
      const startTime = (0, _moment.default)(dateTime).format('hh:mm:A');
      const stopMins = this.getStopTime(isPickup);
      const endTime = (0, _moment.default)(startTime, 'h:mm A').add(stopMins, 'minutes').format('hh:mm A');
      return (0, _moment.default)(date + ' ' + endTime).toISOString();
    },

    editTripTask: (0, _emberConcurrency.task)(function* () {
      this.send('onRefreshFare');
      const store = this.get('store');
      const reconcilAdapter = store.adapterFor('reconcile-trip');
      const passengerDetails = this.get('passengerDetails');
      const pickAddress = this.get('selectedPickLocation');
      const dropAddress = this.get('selectedDropLocation');
      const tripDetails = this.get('tripDetails');
      const pickTime = this.get('shiftTimeRange.pickTime');
      const dropTime = this.get('shiftTimeRange.dropTime');
      const pickupDepartTime = this.getDepartTime(pickTime, true);
      const dropoffDepartTime = this.getDepartTime(dropTime, false);
      const fare = this.get('expectedFair') ? this.get('expectedFair').substring(1) : '';
      const usedAttributes = [];
      usedAttributes.push({
        name: 'wheelChair',
        cnt: this.wheelChairOption ? this.wheelChairOption.value : 0
      });
      usedAttributes.push({
        name: 'ambulatory',
        cnt: this.selectedNeedsOptions ? this.selectedNeedsOptions.value : 0
      });
      usedAttributes.push({
        name: 'pca',
        // eslint-disable-next-line no-nested-ternary
        cnt: this.isEnablePCA ? this.defaultPcaCount ? this.defaultPcaCount : 1 : 0
      });
      usedAttributes.push({
        name: 'companion',
        cnt: this.selectedNoOfCompanions ? this.selectedNoOfCompanions.value : 0
      });
      usedAttributes.push({
        name: 'serviceAnimal',
        // eslint-disable-next-line no-nested-ternary
        cnt: this.isServiceAnimalEnable ? this.defaultServiceAnimalCount ? this.defaultServiceAnimalCount : 1 : 0
      });
      usedAttributes.push({
        name: 'loadTime',
        cnt: this.loadTime ? this.loadTime : 0
      });
      usedAttributes.push({
        name: 'unloadTime',
        cnt: this.unloadTime ? this.unloadTime : 0
      });
      Ember.get(this, 'travelNeedsGroups').map(item => {
        if (item.selected) {
          usedAttributes.push({
            name: item.selected.id,
            cnt: item.selected.value ? item.selected.value : 0
          });
        }
      });
      const payload = {
        tripId: tripDetails.tripId,
        routeId: tripDetails.routeId,
        startTime: pickTime,
        endTime: dropTime,
        travelNeeds: usedAttributes,
        passenger: {
          id: passengerDetails.passengerId,
          firstName: passengerDetails.firstName,
          lastName: passengerDetails.lastName,
          phoneNumber: passengerDetails.phoneNumber,
          serviceNeeds: [{
            name: 'Door to Door'
          }, {
            name: 'Visually impaired'
          }]
        },
        price: {
          id: null,
          type: 'cash',
          fare: fare,
          currencyCode: null,
          tip: null
        },
        stops: [{
          type: 'pickup',
          actualArriveTime: pickTime,
          actualDepartTime: pickupDepartTime,
          notes: this.get('pickNotes'),
          address: {
            formattedAddress: pickAddress.freeformAddress,
            lat: pickAddress.lat,
            lng: pickAddress.lng
          }
        }, {
          type: 'dropoff',
          actualArriveTime: dropTime,
          actualDepartTime: dropoffDepartTime,
          notes: this.get('dropNotes'),
          address: {
            formattedAddress: dropAddress.freeformAddress,
            lat: dropAddress.lat,
            lng: dropAddress.lng
          }
        }]
      };

      try {
        const putTripResponse = yield reconcilAdapter.putTrip(payload, 'edit trip');
        return putTripResponse;
      } catch (e) {
        throw e;
      }
    }),

    showToolTip(title, tip, successMsg) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('editTripTask').perform().then(() => {
            this.send('cancel');
            this.get('workspace').popState('reconcileEditAdditionalTripInfo');
            tooltip.reset();
            this.get('notifications').success(successMsg);
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in edit trip, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    validateLoadUnloadTime(type, value) {
      if (value > 15) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'Edit Trip',
          tip: type + ' Time cannot be greater than 15 minutes',
          primaryActionText: 'Ok',
          primaryAction: () => {
            this.resetLoadUnloadValues(type, '');
            return tooltip.reset();
          },
          closeAction: () => {
            this.resetLoadUnloadValues(type, '');
            return tooltip.reset();
          },
          hasOverlay: true
        });
      } else {
        this.resetLoadUnloadValues(type, value);
      }
    },

    resetLoadUnloadValues(type, value) {
      if (type === 'Load') {
        Ember.set(this, 'loadTime', value);
      } else {
        Ember.set(this, 'unloadTime', value);
      }
    },

    unloadCurrentTrip() {
      if (this.reconcileMode === this.serviceModeTaxi) {
        const record = this.store.peekRecord('reconcile-trip', this.tripDetails.get('tripId'));

        if (record) {
          this.store.unloadRecord(record);
          Ember.set(this, 'reconcile.reconcileSelectedTripId', null);
        }
      }
    },

    checkValidations() {
      if (this.reconcileMode !== this.serviceModeTaxi) {
        if (this.get('shiftTimeRange.pickTime') && this.get('shiftTimeRange.dropTime')) {
          if (!_moment.default.utc(this.get('shiftTimeRange.dropTime')).isAfter(_moment.default.utc(this.get('shiftTimeRange.pickTime')))) {
            Ember.set(this, 'errorMessage', 'Pickup time can not be greater than or equal to dropoff time');
            return false;
          }

          if (_moment.default.utc(this.reconcileRouteStartTime).isAfter(_moment.default.utc(this.get('shiftTimeRange.pickTime')))) {
            Ember.set(this, 'errorMessage', 'Pickup time can not be less than route start time');
            return false;
          }

          if (_moment.default.utc(this.get('shiftTimeRange.dropTime')).isAfter(_moment.default.utc(this.reconcileRouteEndTime))) {
            Ember.set(this, 'errorMessage', 'Dropoff time can not be greater than route end time');
            return false;
          }
        }
      }

      const selectedGroupOptions = Ember.get(this, 'travelNeedsGroups').filter(item => item.selected);

      if (selectedGroupOptions) {
        const nameArray = selectedGroupOptions.map(item => item.selected.id);
        const isDuplicate = nameArray.some((item, idx) => nameArray.indexOf(item) !== idx);

        if (isDuplicate) {
          Ember.set(this, 'errorMessage2', 'Duplicate travel needs option selected');
          return false;
        }
      }

      return true;
    },

    actions: {
      onAnchorSelect(option) {
        if (this.get('shiftTimeRange.pickTime') === null || this.get('shiftTimeRange.dropTime') === null) {
          this.set('isDisableDatePicker', false);
        }

        Ember.set(this, 'selectedAnchor', option);
      },

      onServiceNeedsSelect(option) {
        Ember.set(this, 'selectedNeedsOptions', option);
      },

      onSelectCompanion(option) {
        Ember.set(this, 'selectedNoOfCompanions', option);
        Ember.set(this, 'isEditingStart', true);
        this.handleAmbulatoryCount();
      },

      onSelectTravelNeeds(index, option) {
        Ember.set(this, 'isEditingStart', true);
        const travelNeedsGroup = Ember.get(this, 'travelNeedsGroups')[index];
        Ember.set(travelNeedsGroup, 'selected', option);
      },

      onUndoClick() {
        const pickAddress = this.get('selectedPickLocation');
        const dropAddress = this.get('selectedDropLocation');

        if (pickAddress || dropAddress) {
          Ember.set(this, 'canReset', true);
        }

        Ember.set(this, 'isEditingStart', false);
        this.updateValues();
      },

      onSaveClick() {
        const isValid = this.checkValidations();

        if (!isValid) {
          return;
        }

        Ember.set(this, 'errorMessage', null);
        Ember.set(this, 'errorMessage2', null);
        const tripDetails = this.get('tripDetails');
        const title = 'Edit Trip';
        const tip = 'Would you like to commit your changes?';
        const successMsg = 'TRIP ' + tripDetails.tripId + ' EDITED SUCCESSFULLY';
        this.showToolTip(title, tip, successMsg);
      },

      onTimeChange(valuePath, value) {
        Ember.set(this, 'isEditingStart', true);
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      },

      onChangePickLocation(record) {
        Ember.set(this, 'isEditingStart', true);
        this.set('selectedPickLocation', record);
      },

      onChangeDropLocation(record) {
        Ember.set(this, 'isEditingStart', true);
        this.set('selectedDropLocation', record);
      },

      onDeleteWheelchair() {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'displayWheelChairOption', false);
        Ember.set(this, 'wheelChairOption', {
          id: 'wheelchair',
          name: 'Wheelchair',
          value: 0
        });
        this.handleAmbulatoryCount();
      },

      onPcaChanged() {
        Ember.set(this, 'isEditingStart', true);
        this.toggleProperty('isEnablePCA');
        this.handleAmbulatoryCount();
      },

      onDeleteTravelNeeds(index) {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'travelNeedsOptionsCount', Ember.get(this, 'travelNeedsOptionsCount') - 1);
        Ember.get(this, 'travelNeedsGroups').removeAt(index);
      },

      oninputTextChange(type, value) {
        Ember.set(this, 'isEditingStart', true);

        switch (type) {
          case _stubs.default.PICK_NOTES:
            Ember.set(this, 'pickNotes', value);
            break;

          case _stubs.default.DROP_NOTES:
            Ember.set(this, 'dropNotes', value);
            break;

          case _stubs.default.LOAD_TIME:
            this.validateLoadUnloadTime('Load', value);
            break;

          case _stubs.default.UNLOAD_TIME:
            this.validateLoadUnloadTime('Unload', value);
            break;

          default:
        }
      },

      onCloseButtonClick() {
        this.onCloseClick();
      },

      addTravelNeedItem() {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'travelNeedsOptionsCount', Ember.get(this, 'travelNeedsOptionsCount') + 1);
        this.travelNeedsGroups.pushObject({
          id: Ember.get(this, 'travelNeedsOptionsCount'),
          options: this.travelNeedsOpions,
          selected: false
        });
      },

      onRefreshFare() {
        this.calculateFare();
      },

      cancel() {
        this.unloadCurrentTrip();

        if (this.callBack) {
          this.callBack(true);
        }
      },

      onServiceAnimalChanged() {
        Ember.set(this, 'isEditingStart', true);
        this.toggleProperty('isServiceAnimalEnable');
      }

    }
  });

  _exports.default = _default;
});