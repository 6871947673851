define("adept-iq/pods/components/form-components/address-search/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_DEBOUNCE_TIME = 300;

  var _default = Ember.Component.extend({
    classNames: ['address-search-component'],
    classNameBindings: ['isFocused', 'isBlurred', 'hasSelection', 'isEmpty'],
    addressService: Ember.inject.service('address'),
    user: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    text: '',
    selectedAddress: null,
    inputClass: null,
    debounceTime: DEFAULT_DEBOUNCE_TIME,
    onTextChange: () => null,
    onAddressSelect: () => null,
    searchResults: null,
    assesmentCenterList: null,
    isFocused: false,
    isExpanded: false,
    autoFocus: true,
    isBlurred: Ember.computed.not('isFocused'),
    hasSelection: Ember.computed.bool('selectedAddress'),
    isEmpty: Ember.computed.not('hasSelected'),

    init() {
      this._super(...arguments);

      this.setProperties({
        assesmentCenterList: this.get('assesmentCenterList') || []
      });
      const initialAddresses = this.get('initialAddresses');

      if (initialAddresses) {
        this.set('searchResults', initialAddresses);
      }
    },

    didInsertElement() {
      Ember.run.schedule('afterRender', () => {
        if (this.get('autoFocus')) {
          this.$('input').focus();
        }
      });

      this._bodyClickHandler = event => {
        if (this.get('isDestroyed')) return; // close dropdown if we click elsewhere in application

        if (!event.target.closest('.address-search-component')) {
          this.set('isExpanded', false);
        }
      };

      document.body.addEventListener('click', this._bodyClickHandler);
    },

    willRemoveElement() {
      document.body.removeEventListener('click', this._bodyClickHandler);
    },

    searchTask: (0, _emberConcurrency.task)(function* () {
      const text = this.get('text');
      let results; // can't do a blank search

      if (Ember.isEmpty(text)) {
        this.set('searchResults', null);
        this.set('isExpanded', false);
        return;
      }

      this.set('isExpanded', true);

      if (this.user.isRoadSupEnable()) {
        const center = this.roadSupervisor.getLocation();
        results = yield this.get('addressService').fuzzySearch(text, {
          center,
          limit: 25
        });
      } else {
        results = yield this.get('addressService').fuzzySearch(text, {
          limit: 25
        });
      } // Show Assesment Center Place into Address lookup NYAAR-10385


      results = this.insertAssesmentAddressList(results, text);
      const matchedAddresses = results.filter(result => {
        return result.get('fullAddress') === text;
      });

      if (matchedAddresses.length > 0) {
        Ember.run.schedule('afterRender', () => {
          this.onAddressSelect(matchedAddresses[0]);
          this.set('searchResults', matchedAddresses);
          this.set('isExpanded', false);
        });
        return;
      }

      this.set('searchResults', results);
    }).restartable(),

    // wrapped so we can use `debounce`
    performSearchTask() {
      this.get('searchTask').perform();
    },

    //get AssementCenterList
    getAssesmentCenterList() {
      return this.get('assesmentCenterList');
    },

    insertAssesmentAddressList(searchResults, searchText) {
      const assesmentCenterList = this.getAssesmentCenterList(); //if assesmentCenterList is empty return the searchResults

      if (assesmentCenterList.length === 0) {
        return searchResults;
      }

      const assesmentAddressList = assesmentCenterList.filter(result => {
        return result.get('fullAddress').includes(searchText);
      }); // insert assesmentAddressList to top of the searchResults

      searchResults.unshiftObjects(assesmentAddressList); // remove duplicate address

      const uniqSearchResults = (0, _lodash.uniqBy)(searchResults, function (address) {
        return address.get('streetAddress');
      });
      return uniqSearchResults;
    },

    actions: {
      onInputFocus() {
        Ember.run.schedule('afterRender', () => {
          this.set('isFocused', true);

          if (!Ember.isEmpty(this.get('searchResults'))) {
            this.set('isExpanded', true);
          }
        });
      },

      onInputBlur() {
        Ember.run.schedule('afterRender', () => {
          this.set('isFocused', false);
        });
      },

      onInput(text) {
        Ember.run.schedule('afterRender', () => {
          this.onAddressSelect(null);
          this.onTextChange(text);
          Ember.run.debounce(this, 'performSearchTask', this.get('debounceTime'));
        });
      },

      onAddressSelect(address) {
        Ember.run.schedule('afterRender', () => {
          this.onAddressSelect(address);
          this.set('isExpanded', false);
        });
      }

    }
  });

  _exports.default = _default;
});