define("adept-iq/pods/dashboard/route", ["exports", "lodash", "moment", "ember-simple-auth/mixins/authenticated-route-mixin", "adept-iq/classes/road-supervisor-api", "adept-iq/classes/road-supervisor-special-route-api", "adept-iq/config/environment", "adept-iq/classes/active-contexts/dispatch/dispatch", "adept-iq/classes/active-contexts/playback/playback", "adept-iq/classes/active-contexts/schedule/schedule", "adept-iq/classes/active-contexts/booking/booking", "adept-iq/classes/map-contexts/workspaces/dispatch", "adept-iq/classes/map-contexts/workspaces/avlm", "adept-iq/classes/map-contexts/workspaces/reconcile", "adept-iq/classes/map-contexts/workspaces/schedule", "adept-iq/classes/map-contexts/workspaces/booking", "adept-iq/models/dashboard"], function (_exports, _lodash, _moment, _authenticatedRouteMixin, _roadSupervisorApi, _roadSupervisorSpecialRouteApi, _environment, _dispatch, _playback, _schedule, _booking, _dispatch2, _avlm, _reconcile, _schedule2, _booking2, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // active context classes
  // map context classes
  const LOCAL_STORAGE_KEY = 'dashboard-info';

  function getAccessData(userId) {
    let accessData;
    let data = localStorage.getItem(LOCAL_STORAGE_KEY) || '{}';

    try {
      let json = JSON.parse(data);

      if (json.userId === userId && json.accessData) {
        accessData = json.accessData;
      }
    } catch (e) {}

    return accessData;
  }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    map: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    user: Ember.inject.service(),
    gps: Ember.inject.service(),
    polyline: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    widget: Ember.inject.service(),
    coreEntityPopulator: Ember.inject.service(),
    activeContextCache: null,

    beforeModel() {
      if (this.user.isRoadSupEnable()) {
        let accessData = getAccessData(this.user.userId);

        if (accessData) {
          let {
            vehicleId,
            driverId
          } = accessData;
          let driver = this.store.peekRecord('driver', driverId) || {
            id: "3000"
          };
          let vehicle = this.store.peekRecord('vehicle', vehicleId) || {
            id: "2001"
          };
          let roadSupervisorApi = _environment.default.APP.avlmLite ? _roadSupervisorApi.default.getSingleton() : _roadSupervisorSpecialRouteApi.default.getSingleton();

          if (!this.get('maximizer.isWiddgetMinified')) {
            return this.gps.getLocation().then(pos => {
              let location = [pos.coords.latitude, pos.coords.longitude];
              roadSupervisorApi.initialize({
                driver,
                vehicle,
                location
              });
              roadSupervisorApi.startLocationPing(); // do something with access data
            }).catch(e => {
              console.log(e);
            });
          }

          return;
        } else {
          this.transitionTo('dashboard-info');
        }
      }
    },

    model(params) {
      return this.store.findAll('cs-config-permission').then(configPermissions => {
        this.set('workspace.configPermissions', configPermissions.get('firstObject.value'));
        return this.store.findRecord('dashboard', params.id);
      }).catch(e => {
        console.log(e);
        this.transitionTo('login');
      });
    },

    setupTimelinePermission(model) {
      const isFullAccess = this.store.peekAll('cs-config-permission').get('firstObject.isFullAccessTimeline');

      if (!isFullAccess) {
        model.set('startDate', (0, _moment.default)().hours(0).minutes(1).toDate());
        model.set('endDate', (0, _moment.default)().hours(23).minutes(59).toDate()); // moment().startOf('day') not get the same value everytime

        const referenceDate = (0, _moment.default)().hours(0).minutes(0).toDate();
        model.set('referenceDate', referenceDate);
      } // currently a hack to allow schedule dashboard to be a day ahead.
      // however we only need to modify the schedule default workspace to be a day ahead
      // and then we can remove this *code*


      if (model.get('isScheduleDashboard')) {
        const nextDay = (0, _moment.default)().startOf('day').add(1, 'day');
        model.set('startDate', nextDay.hours(0).minutes(0).seconds(2).toDate());
        model.set('endDate', nextDay.hours(23).minutes(59).seconds(59).toDate());
        const referenceDate = (0, _moment.default)().hours(0).minutes(0).seconds(0).toDate();
        model.set('referenceDate', referenceDate);
      }
    },

    afterModel(model) {
      if (model) {
        this.setupTimelinePermission(model);
        localStorage.setItem('lastDashboardId', model.get('id'));
        const userDash = localStorage.getItem('userDash');
        const currentUser = localStorage.getItem('currentUser');
        const arr = Ember.isPresent(userDash) ? userDash.split(',') : [];

        const user = _lodash.default.find(arr, users => {
          return users.split('*')[0] === currentUser;
        });

        arr.splice(arr.indexOf(user), 1);
        arr.push(`${currentUser}*${model.get('id')}`);
        localStorage.setItem('userDash', arr);
      } // update active context state


      this.updateActiveContextState(model); // update map context state

      this.updateMapContextState(model); // wait for 1 ms. This is enough to eliminate exceptions
      // when switching dashboards.

      Ember.run.next(() => {
        const configPermissions = this.get('workspace.configPermissions');

        if (configPermissions && model) {
          this.get('workspace').set('dashboardModel', model);
        } else {
          this.store.findAll('cs-config-permission').then(currConfigPermissions => {
            this.set('workspace.configPermissions', currConfigPermissions.get('firstObject.value'));
            if (model) this.get('workspace').set('dashboardModel', model);
          });
        }

        const maximizer = this.get('maximizer');

        if (maximizer.get('checkedItems')) {
          maximizer.set('checkedItems', null);
          return;
        } // clear out any checked items


        this.get('activeContext.topActiveContext').reset(); // setup notification banners

        this.get('notifications').setup();
      });
    },

    updateActiveContextState(model) {
      const activeContext = this.get('activeContext');

      if (!activeContext.get('isEmpty')) {
        activeContext.popContext();
      }

      let newActiveContext = null;
      const activeContextCache = this.get('activeContextCache') || {};

      if (activeContextCache[model.role]) {
        activeContext.pushContext(activeContextCache[model.role]);
        return;
      }

      switch (model.role) {
        case _dashboard.SCHEDULE_ROLENAME:
          newActiveContext = _schedule.default.create({
            activeContext: this.get('activeContext'),
            widget: this.get('widget'),
            coreEntityPopulator: this.get('coreEntityPopulator'),
            roadSupervisor: this.get('roadSupervisor'),
            editModal: this.get('editModal'),
            workspaceContext: this.get('workspaceContext'),
            store: this.get('store'),
            workspace: this.get('workspace'),
            user: this.get('user')
          });
          break;

        case _dashboard.PLAYBACK_ROLENAME:
          newActiveContext = _playback.default.create({
            activeContext: this.get('activeContext'),
            widget: this.get('widget'),
            coreEntityPopulator: this.get('coreEntityPopulator'),
            roadSupervisor: this.get('roadSupervisor'),
            editModal: this.get('editModal'),
            workspaceContext: this.get('workspaceContext'),
            store: this.get('store'),
            workspace: this.get('workspace'),
            user: this.get('user')
          });
          break;

        case _dashboard.BOOK_ROLENAME:
          newActiveContext = _booking.default.create({
            activeContext: this.get('activeContext'),
            widget: this.get('widget'),
            coreEntityPopulator: this.get('coreEntityPopulator'),
            roadSupervisor: this.get('roadSupervisor'),
            editModal: this.get('editModal'),
            workspaceContext: this.get('workspaceContext'),
            store: this.get('store'),
            workspace: this.get('workspace'),
            user: this.get('user')
          });
          break;

        case _dashboard.DISPATCH_ROLENAME:
        default:
          if (_environment.default.APP.avlmLite) {
            newActiveContext = AvlmActiveContext.create({
              activeContext: this.get('activeContext'),
              widget: this.get('widget'),
              coreEntityPopulator: this.get('coreEntityPopulator'),
              roadSupervisor: this.get('roadSupervisor'),
              editModal: this.get('editModal'),
              workspaceContext: this.get('workspaceContext'),
              store: this.get('store'),
              workspace: this.get('workspace'),
              user: this.get('user')
            });
          } else {
            newActiveContext = _dispatch.default.create({
              activeContext: this.get('activeContext'),
              widget: this.get('widget'),
              coreEntityPopulator: this.get('coreEntityPopulator'),
              roadSupervisor: this.get('roadSupervisor'),
              editModal: this.get('editModal'),
              workspaceContext: this.get('workspaceContext'),
              store: this.get('store'),
              workspace: this.get('workspace'),
              user: this.get('user')
            });
          }

          break;
      }

      if (newActiveContext) {
        this.set('activeContextCache', { ...activeContextCache,
          [model.role]: newActiveContext
        });
      }

      activeContext.pushContext(newActiveContext);
    },

    updateMapContextState(model) {
      const map = this.get('map');

      if (!map.get('isEmpty')) {
        map.popContext();
      }

      let newMapContext = null;

      switch (model.role) {
        case _dashboard.SCHEDULE_ROLENAME:
          newMapContext = _schedule2.default.create({
            activeContext: this.get('activeContext'),
            roadSupervisor: this.get('roadSupervisor'),
            editModal: this.get('editModal'),
            polylineService: this.get('polyline'),
            store: this.get('store'),
            workspace: this.get('workspace')
          });
          break;

        case _dashboard.PLAYBACK_ROLENAME:
          newMapContext = _avlm.default.create({
            activeContext: this.get('activeContext'),
            roadSupervisor: this.get('roadSupervisor'),
            editModal: this.get('editModal'),
            polylineService: this.get('polyline'),
            store: this.get('store'),
            workspace: this.get('workspace')
          });
          break;

        case _dashboard.RECONCILE_ROLENAME:
          newMapContext = _reconcile.default.create({
            activeContext: this.get('activeContext'),
            roadSupervisor: this.get('roadSupervisor'),
            editModal: this.get('editModal'),
            polylineService: this.get('polyline'),
            store: this.get('store'),
            workspace: this.get('workspace')
          });
          break;

        case _dashboard.BOOK_ROLENAME:
          newMapContext = _booking2.default.create({
            activeContext: this.get('activeContext'),
            roadSupervisor: this.get('roadSupervisor'),
            editModal: this.get('editModal'),
            polylineService: this.get('polyline'),
            store: this.get('store'),
            workspace: this.get('workspace')
          });
          break;

        case _dashboard.DISPATCH_ROLENAME:
        default:
          if (_environment.default.APP.avlmLite) {
            newMapContext = _avlm.default.create({
              activeContext: this.get('activeContext'),
              roadSupervisor: this.get('roadSupervisor'),
              editModal: this.get('editModal'),
              polylineService: this.get('polyline'),
              store: this.get('store'),
              workspace: this.get('workspace')
            });
          } else {
            newMapContext = _dispatch2.default.create({
              activeContext: this.get('activeContext'),
              roadSupervisor: this.get('roadSupervisor'),
              editModal: this.get('editModal'),
              polylineService: this.get('polyline'),
              store: this.get('store'),
              workspace: this.get('workspace')
            });
          }

          break;
      }

      map.pushContext(newMapContext);
    },

    deactivate() {
      if (this.user.isRoadSupEnable()) {
        _roadSupervisorApi.default.getSingleton().endLocationPing();
      }
    }

  });

  _exports.default = _default;
});