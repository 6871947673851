define("adept-iq/pods/components/iq-widgets/noshow-event-stop-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/noshow-event-stop-form-widget/config", "adept-iq/utils/unwrapProxy"], function (_exports, _component, _config, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),

    validateRecords() {
      const valid = true;
      this.set('editModal.errors', []);
      return valid;
    },

    // needed to bubble up errors or edit modal would close prematurely
    editModalCommitFailed: Ember.observer('editModal.errors.[]', function () {
      const errors = this.get('editModal.errors');

      if (errors.length > 0) {
        this.set('editModal.errors', []);
      }
    }),

    undoForNoShow() {
      const lastUndoState = this.get('editModal.undoHistory').popObject();

      if (lastUndoState === null) {
        const records = this.get('editModal.editableRecords') || [];
        records.forEach(record => {
          record.set('isForceDirty', false);
        });
      } else {
        lastUndoState.forEach(({
          record,
          properties
        }) => {
          const propertiesToset = {
            'noShow.notes': ''
          };

          if (Ember.isPresent(properties.timestamp)) {
            propertiesToset['noShow.timestamp'] = properties.timestamp;
          }

          record.setProperties(propertiesToset);
        });
      }

      this.set('isLastUndoStateCommitted', true);
    },

    undoAllNoShow() {
      const undoHistory = this.get('editModal.undoHistory');
      if (Ember.isPresent(undoHistory)) while (undoHistory.length > 0) this.undoForNoShow();
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        this.undoAllNoShow();
      },

      onCloseClick() {
        const records = this.get('editModal.editableRecords') || [];
        records.forEach(record => {
          const dsStop = (0, _unwrapProxy.unwrapProxy)(record);
          const noShowReasonCode = (0, _unwrapProxy.unwrapProxy)(dsStop.get('noShow.noShowReasonCode'));
          const noShow = (0, _unwrapProxy.unwrapProxy)(dsStop.get('noShow'));
          if (noShowReasonCode) noShowReasonCode.rollbackAttributes();
          if (noShow) noShow.rollbackAttributes();
          if (dsStop) dsStop.rollbackAttributes();
          record.rollbackAttributes();
        });

        this._super(...arguments);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          const title = this.get('title');
          this.get('service').apply(title);
        }
      },

      onCellValueChange(record, valuePath, value, options) {
        this.get('service').setRecordValue(record, valuePath, value, options);
      }

    }
  });

  _exports.default = _default;
});