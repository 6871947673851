define("adept-iq/pods/components/iq-widgets/reconcile-verify-route/component", ["exports", "ember-light-table", "adept-iq/pods/components/iq-widgets/reconcile-verify-route/config"], function (_exports, _emberLightTable, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    classNames: ['reconcile-widget'],
    config: _config.default,
    unverifiedRouteData: null,
    onCloseClick: null,

    init() {
      this._super(...arguments);

      this.displayUnVerifyRouteErrorDetails(this.unverifiedRouteData);
    },

    displayUnVerifyRouteErrorDetails(routeData) {
      const tripTable = new _emberLightTable.default(_config.default.tripTableColumns);
      const routeTable = new _emberLightTable.default(_config.default.routeTableColumns);
      this.setProperties({
        isTripMessageExists: false,
        isRouteMessageExists: false,
        tripTable,
        routeTable
      });

      if (routeData && routeData.errors && routeData.errors.length) {
        const tripErrors = routeData.errors.filter(item => item.tripId);
        const routeErrors = routeData.errors.filter(item => item.routeId);
        const tripMessages = [];
        const routeMessages = [];
        tripErrors.map(data => {
          tripMessages.push(data);
        });
        routeErrors.map(data => {
          routeMessages.push(data);
        });

        if (tripMessages.length) {
          this.get('tripTable').setRows(tripMessages);
          this.set('isTripMessageExists', true);
        }

        if (routeMessages.length) {
          this.get('routeTable').setRows(routeMessages);
          this.set('isRouteMessageExists', true);
        }
      }
    },

    actions: {
      cancel() {
        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});