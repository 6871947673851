define("adept-iq/serializers/vehicle", ["exports", "adept-iq/serializers/-vehicle", "lodash"], function (_exports, _vehicle, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _vehicle.default.extend({
    attrs: {
      etaRoute: {
        serialize: false
      },
      nonRevenueVehicle: {
        serialize: false
      } //disabled until backend supports it

    },

    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      if (json.data && json.data.relationships) {
        delete json.data.relationships.breakdownType;
        delete json.data.relationships.iqVehicle;
        delete json.data.attributes.avlLocation;
        delete json.data.attributes.mobileId;
        delete json.data.attributes.version;
        const providerRelationship = json.data.relationships.provider;

        if (!Ember.isNone(providerRelationship)) {
          providerRelationship.data.type = 'providerName';
          json.data.relationships.providerName = json.data.relationships.provider;
          delete json.data.relationships.provider;
        }

        const vehicleTypeRelationship = json.data.relationships.vehicleType;

        if (!Ember.isNone(vehicleTypeRelationship)) {
          vehicleTypeRelationship.data.type = 'vehicleTypeName';
          json.data.relationships.vehicleTypeName = json.data.relationships.vehicleType;
          delete json.data.relationships.vehicleType;
        }

        const startGarageRelationship = json.data.relationships.startGarage;

        if (!Ember.isNone(startGarageRelationship)) {
          json.data.relationships.startGaragePlace = json.data.relationships.startGarage;
          delete json.data.relationships.startGarage;
        }

        const endGarageRelationship = json.data.relationships.endGarage;

        if (!Ember.isNone(endGarageRelationship)) {
          json.data.relationships.endGaragePlace = json.data.relationships.endGarage;
          delete json.data.relationships.endGarage;
        }

        const leaseCompanyRelationship = json.data.relationships.leaseCompany;

        if (!Ember.isNone(leaseCompanyRelationship)) {
          leaseCompanyRelationship.data.type = 'leaseCompanyName';
          json.data.relationships.leaseCompanyName = json.data.relationships.leaseCompany;
          delete json.data.relationships.leaseCompany;
        }
      }

      return json;
    },

    // currently vehicles included payload returns vehicleTypeName & providerName
    // this means the console will spew a bunch of warning messages for missing these models
    // we currently don't have a way to remove these problems especially we can't traverse the array as that in itself is a code smell
    // @FIXME: find a way to address this data situation
    // I left this code as a reminder for this small low priority problem
    // normalizeResponse: function(store, primaryModelClass, payload) {
    //   delete payload.included;
    //   return payload;
    // },
    normalizeResponse(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      delete payload.included;
      return this._super(...arguments);
    },

    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};
      const {
        inspections
      } = resourceHash.relationships;

      if (inspections) {
        inspections.data.forEach(dot => {
          dot.type = 'vehicleDotInspection';
        });
      }

      if (resourceHash.attributes) {
        if (!Ember.isNone(resourceHash.attributes.vehicleTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.vehicleType = {
            data: {
              type: 'vehicle-type',
              id: resourceHash.attributes.vehicleTypeName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.vehicleTypeName;
        }

        if (!Ember.isNone(resourceHash.attributes.providerName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.provider = {
            data: {
              type: 'provider',
              id: resourceHash.attributes.providerName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.providerName;
        }
      }

      if (resourceHash.relationships.providerName) {
        resourceHash.relationships.providerName.data.type = 'provider';
        resourceHash.relationships.provider = (0, _lodash.cloneDeep)(resourceHash.relationships.providerName);
        delete resourceHash.relationships.providerName;
      }

      if (resourceHash.relationships.vehicleTypeName) {
        resourceHash.relationships.vehicleTypeName.data.type = 'vehicle-type';
        resourceHash.relationships.vehicleType = (0, _lodash.cloneDeep)(resourceHash.relationships.vehicleTypeName);
        delete resourceHash.relationships.vehicleTypeName;
      }

      if (resourceHash.relationships.startGaragePlace) {
        resourceHash.relationships.startGarage = resourceHash.relationships.startGaragePlace;
        delete resourceHash.relationships.startGaragePlace;
      }

      if (resourceHash.relationships.endGaragePlace) {
        resourceHash.relationships.endGarage = resourceHash.relationships.endGaragePlace;
        delete resourceHash.relationships.endGaragePlace;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {};

      if (Ember.isPresent(payload.data.attributes.availabilities)) {
        const availabilities = Array.isArray(payload.data.attributes.availabilities) ? payload.data.attributes.availabilities : [payload.data.attributes.availabilities];
        availabilities.forEach(availability => {
          const vehicleAvailability = {
            type: 'vehicle-availability',
            id: availability.id,
            attributes: availability,
            relationships: {
              vehicle: {
                data: {
                  type: 'vehicle',
                  id: payload.data.id
                }
              }
            }
          };
          payload.included = payload.included || [];
          payload.included.push(vehicleAvailability);
        });
      }

      if (Ember.isPresent(payload.data.attributes.type)) {
        relationships.vehicleType = {
          data: {
            type: 'vehicle-type',
            id: payload.data.attributes.type,
            name: payload.data.attributes.type,
            displayName: payload.data.attributes.type
          }
        };
        delete payload.data.attributes.type;
      }

      if (Ember.isPresent(payload.data.attributes.lastGpsPingLat) && Ember.isPresent(payload.data.attributes.lastGpsPingLng)) {
        payload.data.attributes.avlLocation = {
          lat: parseFloat(payload.data.attributes.lastGpsPingLat),
          lng: parseFloat(payload.data.attributes.lastGpsPingLng),
          timestamp: payload.data.attributes.lastGpsPingTime
        };
      }

      if (Ember.isPresent(payload.data.attributes.status)) {
        payload.data.attributes.vehicleStatus = payload.data.attributes.status;
        delete payload.data.attributes.status;
      }

      const isRoadSupervisor = payload.data.attributes.routeName && payload.data.attributes.routeName.includes('SUP');
      payload.data.attributes.otp = payload.data.attributes.otpCode || payload.data.attributes.otpStatus;
      payload.data.attributes.driverBadgeNumber = isRoadSupervisor ? payload.data.attributes.driverPk : payload.data.attributes.driverId;
      payload.data.attributes.driverFullName = payload.data.attributes.driverName; //TODO can make it better

      if (payload.data.attributes.vehicleStateEvent) {
        switch (payload.data.attributes.vehicleStateEvent) {
          case 'commFailure':
            payload.data.attributes.isCommFailure = true;
            break;

          case 'commRestored':
            payload.data.attributes.isCommFailure = false;
            break;

          case 'gpsFailure':
            payload.data.attributes.isGpsFailure = true;
            break;

          case 'leaveGarageSignOff':
            payload.data.attributes.isLeaveGarageSignOff = true;
            break;

          default:
            break;
        }
      }

      if (payload.data.attributes.driverStateEvent) {
        switch (payload.data.attributes.driverStateEvent) {
          case 'signOn':
            payload.data.attributes.isLeaveGarageSignOff = false;
            break;

          case 'signOff':
            // Do nothing for signOff because it might not even in drving state
            break;

          case 'emergencyOn':
            break;

          case 'emergencyOff':
            break;

          default:
            break;
        }
      }

      if (payload.data.attributes.vehicleRouteExecEvent) {
        switch (payload.data.attributes.vehicleRouteExecEvent) {
          case 'onBreak':
            payload.data.attributes.isOnBreak = true;
            break;

          case 'offBreak':
            payload.data.attributes.isOnBreak = false;
            break;

          default:
            break;
        }
      }

      const driverPk = payload.data.attributes.driverPk;
      const provider = payload.data.attributes.provider;
      const routeName = payload.data.attributes.routeName;

      if (routeName) {
        payload.data.attributes.currentRouteName = routeName;
      }

      if (driverPk) {
        relationships.currentDriver = {
          data: {
            type: 'driver',
            id: `${driverPk}`
          }
        };
      }

      if (provider) {
        relationships.provider = {
          data: {
            type: 'provider',
            id: provider
          }
        };
        delete payload.data.attributes.provider;
      }

      payload.data.relationships = relationships;
      return payload;
    }

  });

  _exports.default = _default;
});