define("adept-iq/pods/components/side-drawer/perform-stop/drop/container/component", ["exports", "moment", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/models/stop-point"], function (_exports, _moment, _emberConcurrency, _unwrapProxy, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    // passed in by side-drawer-container component
    stopPoint: null,
    onShrinkClick: null,
    onCloseClick: null,
    arrive: Ember.computed.equal('stopPoint.status', 'Arrived'),
    depart: false,
    shouldArrive: true,
    trip: Ember.computed.readOnly('stopPoint.trip'),
    dispatchRoute: Ember.computed.readOnly('stopPoint.dispatchRoute'),
    route: Ember.computed.readOnly('dispatchRoute.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    routeVehicleDriver: Ember.computed.readOnly('dispatchRoute.latestRouteVehicleDriver'),
    isArrived: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.ARRIVE_STOP_STATUS),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    actualArriveTime: Ember.computed('stopPoint.actualArriveTime', function () {
      const arriveTime = this.get('stopPoint.actualArriveTime');
      const curDate = new Date();

      if (Ember.isNone(arriveTime)) {
        return curDate.toISOString();
      }

      return arriveTime;
    }),
    actualDepartTime: Ember.computed('stopPoint.{actualDepartTime,actualArriveTime}', 'actualArriveTime', function () {
      const departTime = this.get('stopPoint.actualDepartTime');
      const curDate = this.get('actualArriveTime');

      if (Ember.isNone(departTime)) {
        return curDate;
      }

      return departTime;
    }),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      const setStopContainerReference = this.get('setStopContainerReference');

      if (setStopContainerReference) {
        setStopContainerReference(this);
      }
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const stopPoint = yield this.get('stopPoint');
      const actualArriveTime = stopPoint.get('actualArriveTime');
      const actualDepartTime = stopPoint.get('actualDepartTime');
      const curDate = (0, _moment.default)().startOf('minute').toDate();
      stopPoint.rollback();

      if (Ember.isEmpty(actualArriveTime)) {
        stopPoint.set('actualArriveTime', curDate);
      }

      if (Ember.isEmpty(actualDepartTime)) {
        stopPoint.set('actualDepartTime', curDate);
      }

      const isArrived = this.get('isArrived');
      stopPoint.set('odometer');
      this.set('arrive', isArrived);
      this.set('depart', false);
    }).keepLatest(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const stopPoint = (0, _unwrapProxy.unwrapProxy)(this.get('stopPoint'));
      const isArrived = this.get('isArrived');
      const savePromises = [];
      stopPoint.set('odometer', null);

      if (this.get('arrive') && !isArrived) {
        if (Ember.isEmpty(stopPoint.get('actualArriveTime')) && !Ember.isEmpty(this.get('actualArriveTime'))) {
          stopPoint.set('actualArriveTime', this.get('actualArriveTime'));
        }

        const arrive = yield routeExecEventAdapter.createStopArriveEvent(stopPoint);
        savePromises.push(arrive);
      }

      if (this.get('depart')) {
        if (Ember.isEmpty(stopPoint.get('actualDepartTime')) && !Ember.isEmpty(this.get('actualDepartTime'))) {
          stopPoint.set('actualDepartTime', this.get('actualDepartTime'));
        }

        const depart = yield routeExecEventAdapter.createStopDepartEvent(stopPoint);
        savePromises.push(depart);
      }

      return Ember.RSVP.all(savePromises);
    }).drop(),
    actions: {
      onRefreshButtonClick() {
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        this.onCloseClick();
      },

      onPerformButtonClick() {
        const tooltip = this.get('tooltip');
        const route = this.get('route');
        const stopPoint = this.get('stopPoint');
        const arrive = this.get('arrive');
        const depart = this.get('depart');
        const routeEndTime = route.get('dispatchRoute.plannedEndTime');
        const actualStartTime = route.get('dispatchRoute.computedStartTime');
        const arriveTime = (0, _moment.default)(this.get('actualArriveTime'));
        const departTime = (0, _moment.default)(this.get('actualDepartTime'));
        const routeOrdinal = stopPoint.get('routeOrdinal');
        const routeStops = route.get('dispatchRoute.orderedStopsWithGaragesAndBreaks');
        const lastPerformedStop = routeStops.filter(stop => stop.isPerformed).get('lastObject');
        const currentDateTime = (0, _moment.default)();
        const title = 'Perform Stop';
        let messageText = '';
        let tooltipText = ''; // Reset error text

        this.set('errorText');

        if ((0, _moment.default)(departTime).isBefore(arriveTime, 'minutes')) {
          this.set('actualDepartTime', this.get('actualArriveTime'));
        }

        if (arrive && depart) {
          messageText = 'Performed drop stop';
          tooltipText = 'Perform drop stop?';
        } else if (arrive) {
          messageText = 'Arrived drop stop';
          tooltipText = 'Arrive drop stop?';
        } else {
          this.set('errorText', 'Please select at least one option of arrive or depart to perform the stop.');
          return false;
        }

        if (arrive) {
          if (Ember.isEmpty(arriveTime)) {
            this.set('errorText', 'Please fill in a time for arrive time');
            return false;
          }
        }

        if (depart) {
          if (Ember.isEmpty(departTime)) {
            this.set('errorText', 'Please fill in a time for depart time');
            return false;
          }
        }

        if (arriveTime.isBefore(actualStartTime) || departTime.isBefore(actualStartTime)) {
          this.set('errorText', "Performed Drop Stop Failed. The stop cannot be performed before the route's start time");
          return false;
        }

        if (arriveTime.isAfter(routeEndTime) || departTime.isAfter(routeEndTime)) {
          this.get('notifications').warning('Stop performed after planned route end time.');
        }

        if (arriveTime.isAfter(currentDateTime) || departTime.isAfter(currentDateTime)) {
          this.set('errorText', 'Performed Drop Stop Failed. The stop cannot be performed after the current time.');
          return false;
        }

        if (routeOrdinal > 0 && lastPerformedStop && !lastPerformedStop.get('isNoShow')) {
          if (arriveTime.isBefore(lastPerformedStop.get('actualDepartTime')) || departTime.isBefore(lastPerformedStop.get('actualDepartTime'))) {
            this.set('errorText', 'Performed Drop Stop Failed. The stop cannot be performed before the previous Actual drop time.');
            return false;
          }
        }

        tooltip.pushConfirmation({
          tip: tooltipText,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('saveTask').perform().then(() => {
              this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
              this.get('notifications').success(`${messageText} successful`);
              return this.onCloseClick();
            }).catch(response => {
              // clear odometer in stop point model
              stopPoint.set('odometer');
              const payloadMessage = Ember.get(response, 'payload.message') ? JSON.parse(Ember.get(response, 'payload.message')) : null;
              messageText = messageText + ' failed';

              if (response.status === 400) {
                if (payloadMessage.errorDetail.includes('cannot be in state arrived unless all previous stops are performed') || payloadMessage.errorDetail.includes('with status arrived cannot come after')) {
                  messageText = 'Performed Drop Stop Failed. You cannot perform this stop until previous stops are performed';
                }
              }

              this.get('notifications').warning(`${messageText}`);
            }).finally(() => {
              tooltip.reset();
              return this.onCloseClick();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      }

    }
  });

  _exports.default = _default;
});