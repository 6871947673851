define("adept-iq/pods/components/iq-widgets/subscription-form/trip-details-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pYLMNXtm",
    "block": "{\"symbols\":[\"leg\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"g-side-drawer-panel-selector\"],[11,\"style\",\"display: block;\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"noOfLegs\"]],0],null]],null,{\"statements\":[[4,\"each\",[[23,[\"legsInBooking\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row mt-2\"],[11,\"style\",\"display: block;\"],[9],[0,\"\\n        \"],[1,[27,\"iq-widgets/subscription-form/leg-form\",null,[[\"legIndex\",\"addressesOfRiderToBook\",\"syncPickAndDrop\",\"leg\",\"disableRequestDate\",\"isReturnTrip\",\"onFirstLegDateChange\",\"onLegRemoveClick\",\"validateRequestDateTime\",\"showErrorMessage\",\"isEditMode\"],[[22,2,[]],[23,[\"addressesOfRiderToBook\"]],[23,[\"syncPickAndDrop\"]],[22,1,[]],[23,[\"disableRequestDate\"]],[23,[\"isReturnTrip\"]],[23,[\"onFirstLegDateChange\"]],[23,[\"onLegRemoveClick\"]],[23,[\"validateRequestDateTime\"]],[23,[\"showErrorMessage\"]],[23,[\"isEditMode\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/subscription-form/trip-details-form/template.hbs"
    }
  });

  _exports.default = _default;
});