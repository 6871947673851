define("adept-iq/classes/rules/base-rule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BaseRule {
    constructor() {
      this.ruleChainMap = {};
    }

    execute(ruleContext) {
      this.executePreAction(ruleContext).then(() => {
        this.executeRule(ruleContext);
      });
    } // pre action  handler should return promise


    executePreAction(ruleContext) {
      if (this.preAction) {
        return this.preAction(ruleContext);
      }

      return Promise.resolve();
    }

    executeRule(ruleContext) {
      const outCome = this.doEvaluate(ruleContext);

      if (outCome && outCome.then) {
        return outCome.then(result => {
          this.executeOutcome(result, ruleContext);
        });
      } // the result is string


      this.executeOutcome(outCome, ruleContext);
    }

    executeOutcome(outCome, ruleContext) {
      ruleContext.recordRuleResult(this.constructor.RULE_NAME, outCome);

      if (this.ruleChainMap[outCome].rule) {
        this.ruleChainMap[outCome].rule.execute(ruleContext);
        return;
      }

      if (this.ruleChainMap[outCome].action) {
        this.ruleChainMap[outCome].action(ruleContext);
        return;
      }
    }
    /**
     * return promise | string
     */


    doEvaluate()
    /*ruleContext*/
    {}
    /**
     *
     * @param result {string}
     * @param nextRule {BaseRule}
     */


    addOutComeRule(result, nextRule) {
      this.ruleChainMap[result] = {
        rule: nextRule
      };
    }
    /**
     *
     * @param result {string}
     * @param action {(context)=>{}}
     */


    addOutcomeAction(result, action) {
      this.ruleChainMap[result] = {
        action: action
      };
    }

    addPreRuleAction(action) {
      this.preAction = action;
    }

  }

  _exports.default = BaseRule;
});