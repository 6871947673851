define("adept-iq/pods/components/side-drawer/perform-stop/drop/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['perform-stop-drawer'],
    stopPoint: Ember.computed.readOnly('stackFrame.options.stopPoint'),
    componentName: 'performStop',
    stopUIReference: null,
    currentState: null,
    actions: {
      onShrinkClick() {
        const stopUIReference = this.get('stopUIReference');

        if (stopUIReference) {
          stopUIReference.backUpCurrentState();
        }

        this.onShrinkClick();
      },

      setStopUIReference(stopUIReference) {
        if (stopUIReference) {
          this.set('stopUIReference', stopUIReference);
        }
      }

    }
  });

  _exports.default = _default;
});