define("adept-iq/pods/components/generic-widgets/side-drawer/side-drawer-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['g-side-drawer-header'],
    title: 'Side Drawer',
    componentName: null,
    // these have no effect unless a click handler is passed
    canClose: true,
    canShrink: true,
    canRefresh: true,
    canSave: true,
    canSearch: false,
    isSearchEnabled: false,
    onCloseClick: null,
    onAddClick: null,
    onShrinkClick: null,
    onRefreshClick: null,
    onSaveClick: null,
    onSearchClick: null
  });

  _exports.default = _default;
});