define("adept-iq/adapters/place", ["exports", "adept-iq/adapters/-addressService", "adept-iq/config/api-urls"], function (_exports, _addressService, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _addressService.default.extend({
    ajax1: Ember.inject.service('ajax'),
    session: Ember.inject.service(),

    postPlace(place, addressId, locationId, categoryTypeId) {
      const session = this.get('session');
      const json = {
        type: 'place',
        attributes: {
          geocodingMethod: place.get('geocodingMethod')
        },
        relationships: {
          placeCategoryTypeName: {
            data: {
              type: 'placeCategoryTypeName',
              id: categoryTypeId
            }
          },
          address: {
            data: {
              type: 'address',
              id: addressId
            }
          },
          location: {
            data: {
              type: 'location',
              id: locationId
            }
          }
        }
      };
      return this.get('ajax1').post(_apiUrls.API.addressService.host + '/place', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    },

    patchPlace(place, addressId, locationId, categoryTypeId) {
      const session = this.get('session');
      const id = place.get('id');
      const json = {
        type: 'place',
        id,
        attributes: {
          geocodingMethod: place.get('geocodingMethod')
        },
        relationships: {
          placeCategoryTypeName: {
            data: {
              type: 'placeCategoryTypeName',
              id: categoryTypeId
            }
          },
          address: {
            data: {
              type: 'address',
              id: addressId
            }
          },
          location: {
            data: {
              type: 'location',
              id: locationId
            }
          }
        }
      };
      return this.get('ajax1').patch(_apiUrls.API.addressService.host + '/place/' + id, {
        method: 'PATCH',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    }

  });

  _exports.default = _default;
});