define("adept-iq/pods/components/generic-widgets/ember-react-table/component", ["exports", "react", "adept-iq/pods/components/generic-widgets/ember-react-table/react-component", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/gridwidget"], function (_exports, _react, _reactComponent, _gridwidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reactComponent.default.extend({
    reactVirtualGrid: null,
    // get a handle to the Grid, so it can be updated when ever data is changed
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    socket: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    widgetService: Ember.inject.service('widget'),
    maximizer: Ember.inject.service(),
    user: Ember.inject.service(),
    store: Ember.inject.service(),
    getCoreModelNameByWidgetRecord: Ember.computed.alias('activeContext.topActiveContext.getCoreModelNameByWidgetRecord'),
    widgetStateOberserver: Ember.observer('widgetState', function () {
      // Maximize widget may not have widgetState ready so update React component when it is ready.
      if (this.reactVirtualGrid) {
        this.reactVirtualGrid.updateWidgetState(this.get('widgetState'));
      }
    }),

    fetchModelbyWidgetRecord(widgetRecord) {
      let coreModelName = this.get('config.modelName');
      coreModelName = this.get('getCoreModelNameByWidgetRecord')(coreModelName, widgetRecord);
      const data = this.get('store').peekRecord(coreModelName, widgetRecord.id);
      return data;
    },

    fetchModelsbyCheckedRowIds(checkedRowIds) {
      const data = [];
      checkedRowIds.forEach(checkedIdObj => {
        data.push(this.get('store').peekRecord(checkedIdObj.model, checkedIdObj.id));
      });
      return data;
    },

    rollbackCache() {
      if (this.reactVirtualGrid) {
        this.reactVirtualGrid.rollbackCache();
      }
    },

    init() {
      this._super(...arguments);
    },

    renderTable() {
      const onWidgetStateUpdateHandler = this.get('onWidgetStateUpdateHandler');
      const rowCheckBtnSelectHandler = this.get('onSelectCheckBtnHandler');
      const rowCheckBtnUnSelectHandler = this.get('onUnSelectCheckBtnHandler');
      const rowReorderHandler = this.get('rowReorderHandler');
      const onCheckAllHandler = this.get('onCheckAll');
      const onUnCheckAllHandler = this.get('onUnCheckAll');
      const onRowRightClick = this.get('onRowRightClick');
      const onColumnClick = this.get('onColumnClick');
      const loadMoreRows = this.get('loadMoreRows');
      const rowStyleHandler = this.get('rowStyleHandler');
      const checkedRowIds = this.get('checkedRowIds');
      const disabledRowIds = this.get('disabledRowIds');
      const hiddenRowIds = this.get('hiddenRowIds');
      const isFurtherSelectionAllowed = this.get('isFurtherSelectionAllowed');
      const fetchModelbyWidgetRecord = this.get('fetchModelbyWidgetRecord').bind(this);
      const fetchModelsbyCheckedRowIds = this.get('fetchModelsbyCheckedRowIds').bind(this); //build config

      const gridConfig = this.get('config');
      const widgetState = this.get('widgetState');
      const modelName = this.get('modelName');
      const visibleColumns = this.get('visibleColumns'); //This works - though not a ideal solution. The dimensions needs to be in sync when User resizes the grid. TODO Ashraf
      // Set ref to column widget

      this.get('setServerDataTableRef')(this);
      const {
        columnBody,
        width,
        height
      } = this.getContainerDimension();
      columnBody.css('overflow', 'hidden');
      const currentWidgetInstanceId = this.get('widgetInstanceId');
      const isRoleSupervisor = this.user.isRoadSupEnable();
      const showGearIcon = isRoleSupervisor;
      const isMaximized = this.get('isMaximized');
      this.reactRender(_react.default.createElement(_gridwidget.default, {
        ref: gridRef => {
          this.reactVirtualGrid = gridRef;
        },
        widgetInstanceId: currentWidgetInstanceId,
        config: gridConfig,
        widgetState: widgetState,
        width: width,
        height: height,
        modelName: modelName,
        onLazyLoader: loadMoreRows,
        onWidgetStateUpdateHandler: onWidgetStateUpdateHandler,
        rowCheckBtnSelectHandler: rowCheckBtnSelectHandler,
        rowCheckBtnUnSelectHandler: rowCheckBtnUnSelectHandler,
        rowReorderHandler: rowReorderHandler,
        onCheckAllHandler: onCheckAllHandler,
        onUnCheckAllHandler: onUnCheckAllHandler,
        onRowRightClick: onRowRightClick,
        onSortClickHandler: onColumnClick,
        rowStyleHandler: rowStyleHandler,
        fetchModelbyWidgetRecord: fetchModelbyWidgetRecord,
        fetchModelsbyCheckedRowIds: fetchModelsbyCheckedRowIds,
        checkedRowIds: checkedRowIds,
        disabledRowIds: disabledRowIds,
        hiddenRowIds: hiddenRowIds,
        isFurtherSelectionAllowed: isFurtherSelectionAllowed,
        isMaximized: isMaximized,
        data: [],
        visibleColumns: visibleColumns,
        showGearIcon: showGearIcon,
        useTabletWidth: isRoleSupervisor
      }));
    },

    didInsertElement() {
      this.renderTable();
    },

    didRender() {
      this._super(...arguments);

      const checkedRowIds = this.get('checkedRowIds');
      const disabledRowIds = this.get('disabledRowIds');
      const isFurtherSelectionAllowed = this.get('isFurtherSelectionAllowed');
      const hiddenRowIds = this.get('hiddenRowIds'); //console.log("Tabel-widget didRender modelName ", this.get("modelName"));

      const data = this.get('widgetRecords') || []; //console.log(" data length", data.length);

      const visibleColumns = this.get('visibleColumns');

      if (this.reactVirtualGrid) {
        this.reactVirtualGrid.updateSelectionDataAndVisibility(data, checkedRowIds, disabledRowIds, isFurtherSelectionAllowed, hiddenRowIds, visibleColumns);
      }
    },

    getContainerDimension() {
      const columnBody = Ember.$(this.element).closest('.column-widget-body');
      const width = columnBody.outerWidth();
      const height = columnBody.outerHeight();
      return {
        columnBody,
        width,
        height
      };
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this._resizeObserver) {
        this._resizeObserver.disconnect();

        this._resizeObserver = null;
      }
    },

    // override this to resize 3rd party integrations (e.g. map)
    onWidgetResize() {
      if (this.get('isDestroyed')) return;
      const {
        width,
        height
      } = this.getContainerDimension();

      if (this.reactVirtualGrid) {
        this.reactVirtualGrid.updateDimension(width, height);
      }
    },

    onScroll() {
      if (this.reactVirtualGrid) {
        this.reactVirtualGrid.hideContextMenu();
      }
    }

  });

  _exports.default = _default;
});