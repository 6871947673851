define("adept-iq/pods/components/iq-widgets/booking-trips-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/booking-trips-widget/config", "adept-iq/pods/components/iq-widgets/booking-trips-widget/avlmConfig", "adept-iq/config/environment"], function (_exports, _component, _config, _avlmConfig, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['view-alltrips-widget'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    session: Ember.inject.service(),
    editComponent: 'iq-widgets/passengers-form-widget',
    newTripComponent: 'iq-widgets/new-trip-form-widget',
    canShowHeader: true,
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);

      this.get('tableColumns').popObject();
      this.get('tableColumns').lastObject.resizable = false;
    },

    selectAndDeselectTableRows(isRow) {
      const tripsWidgetTableRows = this.get('table.rows');
      tripsWidgetTableRows.setEach('selected', isRow);
      tripsWidgetTableRows.setEach('isChecked', isRow);
    },

    actions: {
      onRowClick() {},

      onCheckAll() {
        const isRowHeadChecked = true;
        this.selectAndDeselectTableRows(isRowHeadChecked);
      },

      onUncheckAll() {
        const isRowHeadUnchecked = false;
        this.selectAndDeselectTableRows(isRowHeadUnchecked);
      }

    }
  });

  _exports.default = _default;
});