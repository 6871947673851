define("adept-iq/helpers/handle-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invokeFunction = invokeFunction;
  _exports.default = void 0;

  function invokeFunction([prop, action]) {
    return function () {
      action(prop, ...arguments);
    };
  }

  var _default = Ember.Helper.helper(invokeFunction);

  _exports.default = _default;
});