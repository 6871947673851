define("adept-iq/adapters/route-exec-event", ["exports", "adept-iq/adapters/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    // TODO: remove this if possible
    routeExecEvent: Ember.inject.service('routeexecevent'),

    query(store, type, query) {
      const session = this.get('session');
      var url = this.buildURL(type.modelName, null, null, 'query', query);
      return this.get('ajax').post(url, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: query
      });
    },

    createStopArriveEvent(stopPoint) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.stopArriveData();
      const preparedData = routeExecEvent.prepareStopArriveData(data, stopPoint);
      return this._postRouteExecEvent(preparedData);
    },

    createStopDepartEvent(stopPoint) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.stopDepartData();
      const preparedData = routeExecEvent.prepareStopDepartData(data, stopPoint);
      return this._postRouteExecEvent(preparedData);
    },

    createAcceptEvent(stopPoint, noShowTime, noShowNotes) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.stopAcceptData();
      const preparedData = routeExecEvent.prepareStopAcceptData(data, stopPoint, noShowTime, noShowNotes);
      return this._postRouteExecEvent(preparedData);
    },

    createStopNoShowEvent(stopPoint) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.noShowData();
      const preparedData = routeExecEvent.prepareStopNoShowData(data, stopPoint);
      return this._postRouteExecEvent(preparedData);
    },

    createTripNoShowRequestEvent(trip, noShowTypeDecision) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.noShowData();
      const preparedData = routeExecEvent.prepareTripNoShowRequestData(data, trip, noShowTypeDecision);
      return this._postRouteExecEvent(preparedData);
    },

    createStartRouteEvent(route) {
      const routeExecEvent = this.get('routeExecEvent');
      const routeStartData = routeExecEvent.routeStartData();
      const preparedRouteStartData = routeExecEvent.prepareRouteStartData(routeStartData, route);
      return this._postRouteExecEvent(preparedRouteStartData);
    },

    createUndoStartRouteEvent(route) {
      const routeExecEvent = this.get('routeExecEvent');
      const undoRouteStartData = routeExecEvent.undoRouteStartData();
      const preparedUndoRouteStartData = routeExecEvent.prepareUndoRouteStartData(undoRouteStartData, route);
      return this._postRouteExecEvent(preparedUndoRouteStartData);
    },

    createEndRouteEvent(route) {
      const routeEndData = this.get('routeExecEvent').routeEndData();
      const preparedRouteEndData = this.get('routeExecEvent').prepareRouteEndData(routeEndData, route);
      return this._postRouteExecEvent(preparedRouteEndData);
    },

    createUndoEndRouteEvent(route) {
      const routeExecEvent = this.get('routeExecEvent');
      const undoRouteEndData = routeExecEvent.undoRouteEndData();
      const prepareUndoRouteEndData = routeExecEvent.prepareUndoRouteEndData(undoRouteEndData, route);
      return this._postRouteExecEvent(prepareUndoRouteEndData);
    },

    createStartBreakEvent(stopPoint) {
      const routeExecEvent = this.get('routeExecEvent');
      const startBreakData = routeExecEvent.onBreakData();
      const preparedStartBreakData = routeExecEvent.prepareStartBreakData(startBreakData, stopPoint);
      return this._postRouteExecEvent(preparedStartBreakData);
    },

    createEndBreakEvent(stopPoint) {
      const routeExecEvent = this.get('routeExecEvent');
      const endBreakData = routeExecEvent.offBreakData();
      const preparedStartBreakData = routeExecEvent.prepareEndBreakData(endBreakData, stopPoint);
      return this._postRouteExecEvent(preparedStartBreakData);
    },

    createAcceptBreakEvent(stopPoint, noShowTime, noShowNotes) {
      const routeExecEvent = this.get('routeExecEvent');
      const data = routeExecEvent.breakAcceptData();
      const preparedData = routeExecEvent.prepareBreakAcceptData(data, stopPoint, noShowTime, noShowNotes);
      return this._postRouteExecEvent(preparedData);
    },

    _postRouteExecEvent(data) {
      const session = this.get('session');
      const uri = this.buildURL('route-exec-event');
      return this.get('ajax').post(uri, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data
      });
    }

  });

  _exports.default = _default;
});