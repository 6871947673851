define("adept-iq/models/rider-external-eligibility", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    status: attr('string'),
    categories: attr(),
    riderExternal: belongsTo('rider-external'),
    allowedServiceModes: attr(),
    disabilities: attr(),
    medicaidQualified: attr('boolean'),
    fareAmount: attr(),
    pcaRequired: attr('boolean')
  });

  _exports.default = _default;
});