define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/tootip", ["exports", "react", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/base-cell-item", "adept-iq/config/api-urls"], function (_exports, _react, _baseCellItem, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-console */

  /* eslint-disable no-inner-declarations */

  /* eslint-disable no-unused-vars */

  /* eslint-disable vars-on-top */
  class Tooltip extends _baseCellItem.default {
    constructor(props) {
      super(props);
      this.state = {
        isHovering: false,
        positionX: 0,
        positionY: 0,
        User: ''
      };
      this.handleMouseHover = this.handleMouseHover.bind(this);
    }

    userList() {
      const localStorageTokenData = JSON.parse(localStorage.getItem('ember_simple_auth-session'));
      const {
        data,
        rowIndex
      } = this.props;
      const currRowData = this.getRowData(data, rowIndex);
      const lock = currRowData.lock;
      const token = localStorageTokenData.authenticated.token;

      if (lock) {
        fetch(`${_apiUrls.API.ssoService.host}/user/${lock.user}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => response.json()).then(userData => this.setState({
          User: userData
        }));
      }
    }

    handleMouseHover() {
      this.setState({
        positionX: window.pageXOffset + event.clientX,
        positionY: window.pageYOffset + event.clientY
      });
      this.userList();
      this.setState(this.toggleHoverState);
    }

    toggleHoverState(state) {
      const clonedState = { ...state
      };
      clonedState.isHovering = !state.isHovering;
      return clonedState;
    }

    render() {
      const {
        data,
        style,
        columnIndex,
        rowIndex,
        columnInfo,
        selectedRowIndex,
        selectedColumnIndex,
        columnValue
      } = this.props;
      const {
        isHovering,
        positionX,
        positionY
      } = this.state;
      const toolTipStyles = {
        width: '150px',
        backgroundColor: 'black',
        color: 'white',
        textAlign: 'center',
        borderRadius: '6px',
        padding: '5px 0',
        position: 'absolute',
        left: `${positionX - 100}px`,
        top: '10px',
        zIndex: 1000
      };
      return _react.default.createElement("span", {
        role: "none",
        onMouseEnter: this.handleMouseHover,
        onMouseLeave: this.handleMouseHover
      }, this.props.children, isHovering && this.renderTooltip(data, rowIndex) ? _react.default.createElement("label", {
        style: toolTipStyles,
        className: "selected-row"
      }, "Locked by ", this.state.User.firstName) : '');
    } //render


    renderTooltip(data, rowIndex) {
      const currRowData = this.getRowData(data, rowIndex);
      const isLocked = currRowData.isLocked;
      return isLocked;
    }

  }

  var _default = Tooltip;
  _exports.default = _default;
});