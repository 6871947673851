define("adept-iq/config/placeholders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TYPE_PLACEHOLDER = _exports.ROAD_CALL_NEW_VEHICLE_PLACEHOLDER = _exports.ROAD_CALL_CODE_DESCRIPTION_PLACEHOLDER = _exports.ROAD_CALL_CODE_PLACEHOLDER = _exports.INSPECTION_PROVIDER_NAME_PLACEHOLDER = _exports.PROBLEM_IDENTIFIED_PLACEHOLDER = _exports.MAINTENANCE_LOCATION_PLACEHOLDER = _exports.MAINTENANCE_TYPE_PLACEHOLDER = _exports.MAINTENANCE_STATUS_PLACEHOLDER = _exports.INSPECTION_CATEGORY_PLACEHOLDER = _exports.DESCRIPTION_PLACEHOLDER = _exports.PROVIDER_PLACEHOLDER = _exports.MILEAGE_PLACEHOLDER = _exports.FAILEDDOT_PLACEHOLDER = _exports.NOTES_PLACEHOLDER = _exports.WHEELCHAIR_SERIAL_NUMBER_PLACEHOLDER = _exports.VIN_PLACEHOLDER = _exports.VEHICLE_SITE_DEF_PLACEHOLDER = _exports.VEHICLE_ENGINE_TYPE_PLACEHOLDER = _exports.VEHICLE_LEASE_COMPANY_PLACEHOLDER = _exports.VEHICLE_MANUFACTURER_PLACEHOLDER = _exports.VEHICLE_LICENCE_PLACEHOLDER = _exports.VEHICLE_MODEL_YEAR_PLACEHOLDER = _exports.VEHICLE_TYPE_PLACEHOLDER = _exports.VEHICLE_STATUS_PLACEHOLDER = _exports.VEHICLE_END_GARAGE_PLACEHOLDER = _exports.VEHICLE_START_GARAGE_PLACEHOLDER = _exports.VEHICLE_ID_PLACEHOLDER = _exports.DRIVER_CDL_PLACEHOLDER = _exports.DRIVER_FIRST_NAME_PLACEHOLDER = _exports.DRIVER_LAST_NAME_PLACEHOLDER = _exports.DRIVER_ID_PLACEHOLDER = _exports.ROUTE_ID_PLACEHOLDER = _exports.TIME_PLACEHOLDER = _exports.INTERNATIONAL_DATE_TIME_PLACEHOLDER = _exports.INTERNATIONAL_DATE_PLACEHOLDER = _exports.US_DATE_TIME_PLACEHOLDER = _exports.US_DATE_PLACEHOLDER = _exports.SOCIAL_SECURITY_NUMBER_PLACEHOLDER = _exports.PHONE_NUMBER_EXT_PLACEHOLDER = _exports.PHONE_NUMBER_PLACEHOLDER = _exports.CDL_NUMBER_PLACEHOLDER = _exports.USER_ADDRESS_PLACEHOLDER = _exports.ADDRESS_PLACEHOLDER = _exports.SEARCH_ADDRESS_PLACEHOLDER = _exports.LOCATION_PLACEHOLDER = _exports.FULL_NAME_PLACEHOLDER = _exports.LAST_NAME_PLACEHOLDER = _exports.FIRST_NAME_PLACEHOLDER = void 0;
  // Common Placeholders
  // Personal Information place holders
  const FIRST_NAME_PLACEHOLDER = 'First Name';
  _exports.FIRST_NAME_PLACEHOLDER = FIRST_NAME_PLACEHOLDER;
  const LAST_NAME_PLACEHOLDER = 'Last Name';
  _exports.LAST_NAME_PLACEHOLDER = LAST_NAME_PLACEHOLDER;
  const FULL_NAME_PLACEHOLDER = 'Full Name'; // address place holders

  _exports.FULL_NAME_PLACEHOLDER = FULL_NAME_PLACEHOLDER;
  const LOCATION_PLACEHOLDER = 'Enter a Location';
  _exports.LOCATION_PLACEHOLDER = LOCATION_PLACEHOLDER;
  const SEARCH_ADDRESS_PLACEHOLDER = 'City, Address, ZIP';
  _exports.SEARCH_ADDRESS_PLACEHOLDER = SEARCH_ADDRESS_PLACEHOLDER;
  const ADDRESS_PLACEHOLDER = 'Enter an Address';
  _exports.ADDRESS_PLACEHOLDER = ADDRESS_PLACEHOLDER;
  const USER_ADDRESS_PLACEHOLDER = 'Enter Your Address'; // CDL number placeholder

  _exports.USER_ADDRESS_PLACEHOLDER = USER_ADDRESS_PLACEHOLDER;
  const CDL_NUMBER_PLACEHOLDER = '###-###-###'; // phone number place holders

  _exports.CDL_NUMBER_PLACEHOLDER = CDL_NUMBER_PLACEHOLDER;
  const PHONE_NUMBER_PLACEHOLDER = '###-###-####';
  _exports.PHONE_NUMBER_PLACEHOLDER = PHONE_NUMBER_PLACEHOLDER;
  const PHONE_NUMBER_EXT_PLACEHOLDER = '######'; // social security number place holders

  _exports.PHONE_NUMBER_EXT_PLACEHOLDER = PHONE_NUMBER_EXT_PLACEHOLDER;
  const SOCIAL_SECURITY_NUMBER_PLACEHOLDER = '###-##-####'; // date-time place holders

  _exports.SOCIAL_SECURITY_NUMBER_PLACEHOLDER = SOCIAL_SECURITY_NUMBER_PLACEHOLDER;
  const US_DATE_PLACEHOLDER = 'MM/DD/YYYY';
  _exports.US_DATE_PLACEHOLDER = US_DATE_PLACEHOLDER;
  const US_DATE_TIME_PLACEHOLDER = 'MM/DD/YYYY HH:MM';
  _exports.US_DATE_TIME_PLACEHOLDER = US_DATE_TIME_PLACEHOLDER;
  const INTERNATIONAL_DATE_PLACEHOLDER = 'DD/MM/YYYY';
  _exports.INTERNATIONAL_DATE_PLACEHOLDER = INTERNATIONAL_DATE_PLACEHOLDER;
  const INTERNATIONAL_DATE_TIME_PLACEHOLDER = 'DD/MM/YYYY HH:MM';
  _exports.INTERNATIONAL_DATE_TIME_PLACEHOLDER = INTERNATIONAL_DATE_TIME_PLACEHOLDER;
  const TIME_PLACEHOLDER = 'HH:MM'; // Route placeholder

  _exports.TIME_PLACEHOLDER = TIME_PLACEHOLDER;
  const ROUTE_ID_PLACEHOLDER = 'Select Route ID'; // Driver place holder

  _exports.ROUTE_ID_PLACEHOLDER = ROUTE_ID_PLACEHOLDER;
  const DRIVER_ID_PLACEHOLDER = 'Select Driver ID';
  _exports.DRIVER_ID_PLACEHOLDER = DRIVER_ID_PLACEHOLDER;
  const DRIVER_LAST_NAME_PLACEHOLDER = 'Driver Last Name';
  _exports.DRIVER_LAST_NAME_PLACEHOLDER = DRIVER_LAST_NAME_PLACEHOLDER;
  const DRIVER_FIRST_NAME_PLACEHOLDER = 'Driver First Name';
  _exports.DRIVER_FIRST_NAME_PLACEHOLDER = DRIVER_FIRST_NAME_PLACEHOLDER;
  const DRIVER_CDL_PLACEHOLDER = 'Driver CDL Number'; // Vehicle placeholders

  _exports.DRIVER_CDL_PLACEHOLDER = DRIVER_CDL_PLACEHOLDER;
  const VEHICLE_ID_PLACEHOLDER = 'Enter Vehicle ID';
  _exports.VEHICLE_ID_PLACEHOLDER = VEHICLE_ID_PLACEHOLDER;
  const VEHICLE_START_GARAGE_PLACEHOLDER = 'Select Start Garage Location';
  _exports.VEHICLE_START_GARAGE_PLACEHOLDER = VEHICLE_START_GARAGE_PLACEHOLDER;
  const VEHICLE_END_GARAGE_PLACEHOLDER = 'Select End Garage Location';
  _exports.VEHICLE_END_GARAGE_PLACEHOLDER = VEHICLE_END_GARAGE_PLACEHOLDER;
  const VEHICLE_STATUS_PLACEHOLDER = 'Select Vehicle Status';
  _exports.VEHICLE_STATUS_PLACEHOLDER = VEHICLE_STATUS_PLACEHOLDER;
  const VEHICLE_TYPE_PLACEHOLDER = 'Select Vehicle Type';
  _exports.VEHICLE_TYPE_PLACEHOLDER = VEHICLE_TYPE_PLACEHOLDER;
  const VEHICLE_MODEL_YEAR_PLACEHOLDER = 'YYYY';
  _exports.VEHICLE_MODEL_YEAR_PLACEHOLDER = VEHICLE_MODEL_YEAR_PLACEHOLDER;
  const VEHICLE_LICENCE_PLACEHOLDER = 'Enter Vehicle Licence';
  _exports.VEHICLE_LICENCE_PLACEHOLDER = VEHICLE_LICENCE_PLACEHOLDER;
  const VEHICLE_MANUFACTURER_PLACEHOLDER = 'Select Vehicle Manufacturer';
  _exports.VEHICLE_MANUFACTURER_PLACEHOLDER = VEHICLE_MANUFACTURER_PLACEHOLDER;
  const VEHICLE_LEASE_COMPANY_PLACEHOLDER = 'Select Lease Company Name';
  _exports.VEHICLE_LEASE_COMPANY_PLACEHOLDER = VEHICLE_LEASE_COMPANY_PLACEHOLDER;
  const VEHICLE_ENGINE_TYPE_PLACEHOLDER = 'Enter Vehicle Engine Type';
  _exports.VEHICLE_ENGINE_TYPE_PLACEHOLDER = VEHICLE_ENGINE_TYPE_PLACEHOLDER;
  const VEHICLE_SITE_DEF_PLACEHOLDER = 'Enter';
  _exports.VEHICLE_SITE_DEF_PLACEHOLDER = VEHICLE_SITE_DEF_PLACEHOLDER;
  const VIN_PLACEHOLDER = 'Enter Vehicle VIN Number';
  _exports.VIN_PLACEHOLDER = VIN_PLACEHOLDER;
  const WHEELCHAIR_SERIAL_NUMBER_PLACEHOLDER = 'Enter Serial Number'; // Note place holder

  _exports.WHEELCHAIR_SERIAL_NUMBER_PLACEHOLDER = WHEELCHAIR_SERIAL_NUMBER_PLACEHOLDER;
  const NOTES_PLACEHOLDER = 'Enter Notes'; // Note place holder

  _exports.NOTES_PLACEHOLDER = NOTES_PLACEHOLDER;
  const FAILEDDOT_PLACEHOLDER = 'Enter Failed DOT'; // Mileage place holder

  _exports.FAILEDDOT_PLACEHOLDER = FAILEDDOT_PLACEHOLDER;
  const MILEAGE_PLACEHOLDER = 'Enter Mileage #'; // provider place holders

  _exports.MILEAGE_PLACEHOLDER = MILEAGE_PLACEHOLDER;
  const PROVIDER_PLACEHOLDER = 'Select a Provider'; // description place holder

  _exports.PROVIDER_PLACEHOLDER = PROVIDER_PLACEHOLDER;
  const DESCRIPTION_PLACEHOLDER = 'Enter Description'; // vehicle DOT Inspection placeholder

  _exports.DESCRIPTION_PLACEHOLDER = DESCRIPTION_PLACEHOLDER;
  const INSPECTION_CATEGORY_PLACEHOLDER = 'Select a Category'; // vehicle Maintenance Placeholder

  _exports.INSPECTION_CATEGORY_PLACEHOLDER = INSPECTION_CATEGORY_PLACEHOLDER;
  const MAINTENANCE_STATUS_PLACEHOLDER = 'Select Status';
  _exports.MAINTENANCE_STATUS_PLACEHOLDER = MAINTENANCE_STATUS_PLACEHOLDER;
  const MAINTENANCE_TYPE_PLACEHOLDER = 'Select Type';
  _exports.MAINTENANCE_TYPE_PLACEHOLDER = MAINTENANCE_TYPE_PLACEHOLDER;
  const MAINTENANCE_LOCATION_PLACEHOLDER = 'Select Location';
  _exports.MAINTENANCE_LOCATION_PLACEHOLDER = MAINTENANCE_LOCATION_PLACEHOLDER;
  const PROBLEM_IDENTIFIED_PLACEHOLDER = 'Select';
  _exports.PROBLEM_IDENTIFIED_PLACEHOLDER = PROBLEM_IDENTIFIED_PLACEHOLDER;
  const INSPECTION_PROVIDER_NAME_PLACEHOLDER = 'Select Inspector'; // vehicle Road Call Placeholders

  _exports.INSPECTION_PROVIDER_NAME_PLACEHOLDER = INSPECTION_PROVIDER_NAME_PLACEHOLDER;
  const ROAD_CALL_CODE_PLACEHOLDER = 'Select Code';
  _exports.ROAD_CALL_CODE_PLACEHOLDER = ROAD_CALL_CODE_PLACEHOLDER;
  const ROAD_CALL_CODE_DESCRIPTION_PLACEHOLDER = 'Code Description';
  _exports.ROAD_CALL_CODE_DESCRIPTION_PLACEHOLDER = ROAD_CALL_CODE_DESCRIPTION_PLACEHOLDER;
  const ROAD_CALL_NEW_VEHICLE_PLACEHOLDER = 'Search for new Vehicle'; // Vehicle Equipment Placeholder

  _exports.ROAD_CALL_NEW_VEHICLE_PLACEHOLDER = ROAD_CALL_NEW_VEHICLE_PLACEHOLDER;
  const TYPE_PLACEHOLDER = 'Enter Type';
  _exports.TYPE_PLACEHOLDER = TYPE_PLACEHOLDER;
});