define("adept-iq/models/vehicle-type", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    hasMany,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    displayName: attr('string'),
    vehicleCapacityConfigs: hasMany('vehicle-capacity-config'),
    vehicle: hasMany('vehicle'),
    // TODO: Ankur -  Hardcoded will be remove to some configuration
    noOfAmbulatorySeats: Ember.computed.readOnly('capacityCounts.ambulatory'),
    noOfWideAmbulatorySeats: Ember.computed.readOnly('capacityCounts.wideAmbulatory'),
    noOfWheelChairSeats: Ember.computed.readOnly('capacityCounts.wheelchair'),
    noOfWideWheelChairSeats: Ember.computed.readOnly('capacityCounts.wideWheelchair'),
    noOfServiceAnimalSeats: Ember.computed.readOnly('capacityCounts.serviceAnimal'),
    noOfFlexSeats: Ember.computed.readOnly('capacityCounts.flexSeats'),
    extraWideLift: Ember.computed.gt('capacityCounts.extraWideLift', 0),
    noOfAmbulatoryFlexSeats: Ember.computed('capacityCounts.{flexSeats,ambulatory}', function () {
      const flexSeats = this.get('capacityCounts.flexSeats');

      if (flexSeats.length > 1) {
        return flexSeats.map(flexSeat => flexSeat.ambulatory).join(' / ');
      }

      return this.get('capacityCounts.ambulatory');
    }),
    noOfWheelChairFlexSeats: Ember.computed('capacityCounts.{flexSeats,wheelchair}', function () {
      const flexSeats = this.get('capacityCounts.flexSeats');

      if (flexSeats.length > 1) {
        return flexSeats.map(flexSeat => flexSeat.wheelchair).join(' / ');
      }

      return this.get('capacityCounts.wheelchair');
    }),
    capacityCounts: Ember.computed('vehicleCapacityConfigs.@each.{vehicleCapacityTypeName,count}', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0,
        extraWideLift: 0,
        flexSeats: []
      };
      const vehicleCapacityConfigs = this.get('vehicleCapacityConfigs') || [];
      let capacityCount = initialCounts;
      const flexSeats = {};
      capacityCount = vehicleCapacityConfigs.reduce((obj, vehicleCapacityConfig) => {
        const vehicleCapacityTypeName = vehicleCapacityConfig.get('vehicleCapacityTypeName');
        const count = vehicleCapacityConfig.get('count');
        const groupNumber = vehicleCapacityConfig.get('group');
        obj[vehicleCapacityTypeName] = count; // collect the group capacity config

        let group = flexSeats[groupNumber];

        if (Ember.isNone(group)) {
          group = {
            group: groupNumber,
            wheelchair: 0,
            wideWheelchair: 0,
            ambulatory: 0,
            wideAmbulatory: 0,
            serviceAnimal: 0,
            extraWideLift: 0
          };
        }

        group[vehicleCapacityTypeName] = count;
        flexSeats[groupNumber] = group;
        return obj;
      }, initialCounts); // filter out none ambulatory or wheelchair types

      const filteredFlexSeats = (0, _lodash.pickBy)(flexSeats, value => {
        return value.ambulatory > 0 || value.wheelchair > 0;
      });
      capacityCount.flexSeats = Object.values(filteredFlexSeats);
      return capacityCount;
    })
  });

  _exports.default = _default;
});