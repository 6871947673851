define("adept-iq/pods/components/side-drawer/scheduling-auto-reschedule/component", ["exports", "ember-light-table", "ember-concurrency", "adept-iq/utils/unwrapProxy", "lodash", "adept-iq/pods/components/side-drawer/scheduling-auto-reschedule/config", "adept-iq/mixins/async-schedule-operation"], function (_exports, _emberLightTable, _emberConcurrency, _unwrapProxy, _lodash, _config, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    // passed by the side drawer container component
    onCloseClick: null,
    solutionTable: null,
    rowComponent: 'table/rows/colored-row',
    title: 'Trip Rescheduled',
    previouslyTriedRoutes: null,
    //passed  by the workspace service
    trip: Ember.computed.readOnly('stackFrame.options.trip'),
    status: Ember.computed.readOnly('stackFrame.options.status'),
    isWaitlisted: Ember.computed.readOnly('stackFrame.options.isWaitlistedTripScheduled'),
    originalRouteId: Ember.computed.readOnly('stackFrame.options.originalRouteId'),
    originalRouteName: Ember.computed.readOnly('stackFrame.options.originalRouteName'),
    isFailure: Ember.computed.equal('status', 'failure'),
    rescheduleTrip: null,
    timeout: 1000,
    isWaitListedTrip: Ember.computed.readOnly('trip.isWaitlisted'),
    isOriginalRouteRunning: Ember.computed.readOnly('originalRouteTrip.isRunning'),
    isRetryRouteRunning: Ember.computed.readOnly('retryTrip.isRunning'),
    isDisabledOriginalRouteBtn: Ember.computed('isWaitlisted', 'isOriginalRouteRunning', 'isFailure', function () {
      return this.get('isWaitlisted') || this.get('isOriginalRouteRunning') || this.get('isFailure');
    }),
    isDisabledRetryBtn: Ember.computed('isFailure', 'isRetryRouteRunning', function () {
      return this.get('isFailure') || this.get('isRetryRouteRunning');
    }),

    init() {
      this._super(...arguments);

      const table = new _emberLightTable.default(_config.solutionColumns);
      const errorMsg = `Trip ${this.get('trip.id')} failed to reschedule.`;
      const delay = this.get('timeout');
      this.set('solutionTable', table);
      this.set('previouslyTriedRoutes', []);

      if (this.get('isFailure')) {
        this.get('notifications').warning(errorMsg);
      } else {
        this.get('fetchRescheduleTrip').perform().then(() => {
          setTimeout(() => {
            const successMsg = `Trip ${this.get('rescheduleTrip.tripId')} successfully rescheduled to route ${this.get('rescheduleTrip.routeName')}`;
            this.get('notifications').success(successMsg);
          }, delay);
        });
      }
    },

    didInsertElement() {
      this._super(...arguments); // side panel width just for trip transfer


      Ember.$('.side-drawer-modal').width('600px');
    },

    setupSolutionPreview() {
      const trip = this.get('trip');
      const rescheduleTrip = this.get('rescheduleTrip');
      let tripList = [];
      const rider = (0, _unwrapProxy.unwrapProxy)(trip.get('rider'));
      const rescheduleTripRider = (0, _unwrapProxy.unwrapProxy)(rescheduleTrip.get('rider'));
      trip.get('stops').forEach(stop => {
        const data = {
          tripId: stop.get('trip.tripId'),
          plannedEta: stop.get('plannedEta'),
          stopType: stop.get('type'),
          computedPromiseTime: stop.get('trip.promiseTime'),
          lastName: rider.get('lastName'),
          selectedOrdinalRow: true,
          simpleAddress: stop.get(`trip.${stop.get('type')}.segmentStop.place.address.simpleAddress`),
          locality: stop.get(`trip.${stop.get('type')}.segmentStop.place.address.locality`),
          apptTime: stop.get('trip.segment.leg.requestTime')
        };
        tripList.push(data);
      });
      rescheduleTrip.get('stops').forEach(stop => {
        const data = {
          tripId: stop.get('trip.tripId'),
          plannedEta: stop.get('plannedEta'),
          stopType: stop.get('type'),
          computedPromiseTime: stop.get('trip.promiseTime'),
          lastName: rescheduleTripRider.get('lastName'),
          simpleAddress: stop.get(`trip.${stop.get('type')}.segmentStop.place.address.simpleAddress`),
          locality: stop.get(`trip.${stop.get('type')}.segmentStop.place.address.locality`),
          apptTime: stop.get('trip.segment.leg.requestTime')
        };
        tripList.push(data);
      });
      tripList = (0, _lodash.orderBy)(tripList, tripInfo => {
        return tripInfo.plannedEta;
      }, ['asc']);
      this.get('solutionTable').setRows(tripList);
    },

    fetchRescheduleTrip: (0, _emberConcurrency.task)(function* () {
      const delay = this.get('timeout');
      const tripId = this.get('trip.id');
      const rescheduleTrip = yield this.get('store').findRecord('trip', tripId);
      yield (0, _emberConcurrency.timeout)(delay);
      this.set('rescheduleTrip', rescheduleTrip);
      const trip = this.get('rescheduleTrip');
      const routeId = parseInt(trip.get('route.id'), 10);
      this.get('previouslyTriedRoutes').push(routeId);
      this.setupSolutionPreview();
    }).drop(),
    waitListTrip: (0, _emberConcurrency.task)(function* () {
      const schedule = (0, _unwrapProxy.unwrapProxy)(this.get('trip.route.schedule'));
      const tripId = parseInt(this.get('trip.id'), 10);
      let result;

      try {
        const operationData = {
          tripId
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripWaitlist', options);

        if (!result) {
          throw new Error('Unable to place trip on waitlist.');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),
    retryTrip: (0, _emberConcurrency.task)(function* () {
      const schedule = (0, _unwrapProxy.unwrapProxy)(this.get('trip.route.schedule'));
      const tripId = parseInt(this.get('trip.id'), 10);
      const previouslyTriedRoutes = this.get('previouslyTriedRoutes');
      let result;

      try {
        const operationData = {
          tripId,
          routes: previouslyTriedRoutes,
          routeUsage: 1
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripReschedule', options);

        if (!result) {
          throw new Error('Unable to retry a different route');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),
    originalRouteTrip: (0, _emberConcurrency.task)(function* (routeId) {
      const schedule = (0, _unwrapProxy.unwrapProxy)(this.get('trip.route.schedule'));
      const tripId = parseInt(this.get('trip.id'), 10);
      let result;

      try {
        const operationData = {
          tripId,
          routes: [routeId],
          routeUsage: 2
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripReschedule', options);

        if (!result) {
          throw new Error('Unable to return trip to original route');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),
    actions: {
      onAcceptButtonClick() {
        this.onCloseClick();
      },

      onWaitListButtonClick() {
        const tripId = this.get('trip.tripId');
        const successMsg = `Trip ${tripId} successfully placed on waitlist`;
        const errorMsg = 'Unable to place trip on waitlist.';
        this.get('waitListTrip').perform().then(() => {
          this.get('notifications').success(successMsg);
          this.onCloseClick();
        }).catch(() => {
          this.get('notifications').warning(errorMsg);
        });
      },

      onRetryButtonClick() {
        const tripId = this.get('trip.tripId');
        const errorMsg = 'Unable to retry a different route';
        this.get('retryTrip').perform().then(() => {
          const routeName = this.get('rescheduleTrip.routeName');
          const successMsg = `Trip ${tripId} successfully rescheduled to route ${routeName}`;
          this.get('notifications').success(successMsg);
        }).catch(() => {
          this.get('notifications').warning(errorMsg);
          this.get('fetchRescheduleTrip').perform();
        });
      },

      onOriginalRouteButtonClick() {
        const tripId = this.get('trip.tripId');
        const originalRouteId = this.get('originalRouteId');
        const originalRouteName = this.get('originalRouteName');
        const routeAssignments = this.get('trip.routeAssignments');
        const originalRouteAssignment = routeAssignments.find(routeAssignment => {
          return routeAssignment.get('route.id') === originalRouteId;
        });
        const routeName = originalRouteAssignment ? originalRouteAssignment.get('route.name') : originalRouteName;
        const routeId = originalRouteAssignment ? parseInt(originalRouteAssignment.get('route.id'), 10) : originalRouteId;
        const successMsg = `Trip ${tripId} successfully placed back to original route ${routeName}`;
        const errorMsg = 'Unable to return trip to original route';
        this.get('originalRouteTrip').perform(routeId).then(() => {
          this.get('notifications').success(successMsg);
          this.onCloseClick();
        }).catch(() => {
          this.get('notifications').warning(errorMsg);
        });
      }

    }
  });

  _exports.default = _default;
});