define("adept-iq/pods/components/reoptimize-modal/operation-views/trim-slack/component", ["exports", "ember-concurrency", "moment", "adept-iq/mixins/async-schedule-operation"], function (_exports, _emberConcurrency, _moment, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    classNames: ['trim-slack'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    isTaskRunning: Ember.computed.or('hypoTrimSlackTask.isRunning', 'applyTrimSlack.isRunning'),
    // Passed In Attributes
    title: null,
    currentSchedule: null,
    tableRef: null,
    checkedRows: Ember.computed.alias('tableRef.checkedRows'),
    hasRowsSelected: Ember.computed.lt('checkedRows.length', 1),
    disableApplyButton: Ember.computed.or('hasRowsSelected', 'isTaskRunning'),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('currentAPITask', this.get('applyTrimSlack'));
      this.hypoTrimSlackTask.perform();
    },

    hypoTrimSlackTask: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      const currentSchedule = this.get('currentSchedule');
      let response;

      try {
        const options = {
          outputResult: true
        };
        response = yield this.createScheduleAsyncOperation.perform(currentSchedule, 'routeTrimSlack', options);
        const {
          isJobSuccess,
          results
        } = response;

        if (isJobSuccess) {
          this.get('notifications').success('Adjusted start and end times successfully  retrieved ');
          const {
            deductData
          } = JSON.parse(results);
          if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
          deductData.forEach(trim => {
            const route = this.store.peekRecord('route', trim.routeId);

            if (route) {
              route.setProperties({
                adjustedEndTime: trim.adjustedEndTime,
                adjustedStartTime: trim.adjustedStartTime
              });
            }
          });
        } else {
          this.get('notifications').warning('Failed to retrieve adjusted start and end times');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          this.get('notifications').warning('Failed to retrieve adjusted start and end times');
          throw e;
        }
      }

      return Promise.resolve(response);
    }),
    // be able to trim slack from selected rows
    // In backend, for Trimslack action we need to create activity logs.
    // Inorder to differentiate trim-slack actions from other, here we are passing 'trimSlack' as param to updateScheduleRoute
    applyTrimSlack: (0, _emberConcurrency.task)(function* (routes) {
      const tableRef = this.get('tableRef');
      routes.forEach(route => {
        const adjustedStartTime = (0, _moment.default)(route.get('adjustedStartTime')).toDate();
        const adjustedEndTime = (0, _moment.default)(route.get('adjustedEndTime')).toDate();

        if (typeof route.affinities === 'string') {
          route.set('affinities', JSON.parse(route.get('affinities')));
        }

        if (adjustedStartTime && adjustedEndTime) {
          route.set('plannedStartTime', adjustedStartTime);
          route.set('plannedEndTime', adjustedEndTime);
        }
      });
      let failureRoutes = 0;
      const routeAdapter = this.store.adapterFor('route');
      const actionType = 'trimSlack';

      try {
        yield routes.reduce(async (acu, route) => {
          return acu.then(() => routeAdapter.updateScheduleRoute(route, actionType).then(() => route.set('isTrimmed', true)).catch(() => {
            route.rollbackAttributes();
            route.set('result', 'Failed');
            failureRoutes++;
          }));
        }, Promise.resolve());
      } catch (e) {
        failureRoutes++;
      }

      if (failureRoutes) {
        this.get('notifications').warning(`Failed to trim slack from ${failureRoutes} Routes`);
      } else {
        this.get('notifications').success('Slack Successfully trimmed');
      }

      tableRef.refreshData(); // Trim slack route removed or failed re-fetch  the records

      tableRef.onUncheckAll();
    }).drop(),
    actions: {
      onTrimSlackClick() {
        const routes = this.get('checkedRows');
        this.applyTrimSlack.perform(routes);
      }

    }
  });

  _exports.default = _default;
});