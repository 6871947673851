define("adept-iq/services/booking", ["exports", "adept-iq/classes/json-api-serializer/JSONAPISerializer", "adept-iq/utils/unwrapProxy", "tomtom", "adept-iq/config/environment", "adept-iq/utils/zone-validation", "adept-iq/config/api-urls", "moment", "lodash", "adept-iq/classes/rules/itinerary-type", "adept-iq/classes/rules/trip-type", "adept-iq/mixins/async-schedule-operation", "adept-iq/config/itinerary-action", "ember-concurrency"], function (_exports, _JSONAPISerializer, _unwrapProxy, _tomtom, _environment, _zoneValidation, _apiUrls, _moment, _lodash, _itineraryType, _tripType, _asyncScheduleOperation, _itineraryAction, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function cleanFieldString(text) {
    return typeof text === 'undefined' ? '' : text;
  }

  const WEATHER_API = `https://api.weather.gov/points/${_environment.default.tomtom.search.center.lat},${_environment.default.tomtom.search.center.lon}/forecast/hourly`;
  const WEATHER_WITHOUT_HOURLY_API = `https://api.weather.gov/points/${_environment.default.tomtom.search.center.lat},${_environment.default.tomtom.search.center.lon}/forecast`; //TODO - Temporary fix need to rollback once got NYAAR-11301 fix

  const ITP_DEMAND_TRIP_API = 'https://devfres.aiqtan.ddsdeploytest.com/scheduler/demand-trip'; //const ITP_DEMAND_TRIP_API = 'http://52.88.175.146/scheduler/demand-trip';

  const generateUUID = () => '_' + Math.random().toString(36).substr(2, 9);

  const TRAVEL_NEEDS_VERIFY = ['pca', 'serviceAnimal'];

  var _default = Ember.Service.extend(_asyncScheduleOperation.default, {
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    workspace: Ember.inject.service(),
    serializer: null,
    activeBooking: null,
    activeDragPlace: null,
    activeLegsInBooking: null,
    segmentStopIdCount: 0,
    legTravelNeedIdCount: 0,
    legIdCount: 0,
    selectedRiders: null,
    // this is used for add/edit bookings
    currentBookings: null,
    //this is to show the bookings in the bookings widget
    currentTrips: null,
    filteredCurrentTrips: null,
    //this is to show the bookings in the bookings widget
    currentSubscriptions: null,
    //this is to show the subscriptions in the bookings widget
    hasMoreExternalRiders: false,
    // this is to plug moreResuts field that comes from nyct when we search
    selectedCompanionCount: 0,
    isOpenErrorModal: false,
    errorMessage: null,
    canSave: true,
    riderIdToConditionMap: null,
    temporaryBookingResult: null,
    tempBookingInfo: null,
    tripIds: null,
    enableNewPromiseTimeApi: true,
    configTravelNeedItems: null,
    anchorTypeOptions: null,
    previousSubscriptionStartDate: null,
    previousSubscriptionEndDate: null,
    previousSubscriptionSuspendStartDate: null,
    previousSubscriptionSuspendEndDate: null,
    addressSelectType: null,
    itpSegmentPromiseTimeResults: null,

    init() {
      this._super(...arguments);

      this.set('currentBookings', []);
      this.set('currentSubscriptions', []);
      this.set('currentTrips', []);
      this.set('filteredCurrentTrips', []);
      this.set('selectedRiders', []);
      this.set('selectedTravelModeMap', {}); // {<legId> : <travelMode>}

      this.set('itpInfo', {
        itpRequestResults: [],
        itpResultType: null
      });
      this.set('itpSegmentPromiseTimeResults', []);
      this.set('tempBookingInfo', {});
      this.set('anchorTypeOptions', [{
        id: 'pick',
        name: 'Pick'
      }, {
        id: 'drop',
        name: 'Appt'
      }]);
      this.serializer = new _JSONAPISerializer.default(); // Register 'booking' type

      this.serializer.register('booking', {
        id: 'id',
        relationships: {
          legs: {
            type: 'leg'
          }
        }
      });
      this.serializer.register('leg', {
        id: 'id',
        relationships: {
          segments: {
            type: 'segment'
          },
          legTravelNeeds: {
            type: 'legTravelNeed'
          },
          serviceWindowName: {
            type: 'serviceWindowName'
          }
        }
      });
      this.serializer.register('segment', {
        id: 'id',
        relationships: {
          pick: {
            type: 'segmentStop'
          },
          drop: {
            type: 'segmentStop'
          },
          leg: {
            type: 'leg'
          },
          fareTypeName: {
            type: 'fareTypeName'
          }
        }
      });
      this.serializer.register('legTravelNeed', {
        id: 'id',
        relationships: {
          travelNeedTypeName: {
            type: 'travelNeedTypeName'
          },
          leg: {
            type: 'leg'
          },
          passengerTypeName: {
            type: 'passengerTypeName'
          }
        }
      });
      this.serializer.register('travelNeedTypeName', {
        id: 'id'
      });
      this.serializer.register('passengerTypeName', {
        id: 'id'
      });
      this.serializer.register('serviceWindowName', {
        id: 'id'
      });
      this.serializer.register('fareTypeName', {
        id: 'id'
      });
      this.serializer.register('segmentStop', {
        id: 'id'
      });
      this.serializer.register('place', {
        id: 'id'
      });
      this.serializer.register('address', {
        id: 'id'
      });
      this.serializer.register('location', {
        id: 'id'
      });
      this.serializer.register('rider', {
        id: 'id'
      });
      this.setProperties({
        pickDropCoordinatesOfLegs: [],
        configTravelNeedItems: []
      });
    },

    noOfSelectedRiders: Ember.computed('selectedRiders', 'selectedRiders.[]', function () {
      return this.get('selectedRiders.length');
    }),
    selectedRidersWithConditionalEligibility: Ember.computed('selectedRiders', 'selectedRiders.[]', function () {
      return this.get('selectedRiders').filter(rExt => {
        return rExt.get('isEligibilityConditional');
      });
    }),

    /**
     *
     * @param riderConditionResultMap
     * @param filterFailedConditions : Boolean
     */
    getRiderIdToConditionsMap(riderConditionResultMap, filterFailedConditions) {
      const selectedRidersWithConditionalEligibility = this.get('selectedRidersWithConditionalEligibility');
      const conditionsInConfig = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-eligibility_conditions';
      });
      const allRiderIdToConditionMap = selectedRidersWithConditionalEligibility.reduce((result, r) => {
        const riderId = r.get('id');
        const riderEligibilityCategories = r.get('eligibility.categories') || [];
        const pcaRequired = r.get('eligibility.pcaRequired');
        const conditionsFromNYCT = riderEligibilityCategories[0].conditions ? riderEligibilityCategories[0].conditions : []; // allow only configured conditions

        const conditions = conditionsFromNYCT.filter(c => {
          return conditionsInConfig.find(cConfig => {
            return cConfig.get('displayName').toUpperCase().trim() === c.toUpperCase().trim();
          });
        });
        const conditionResultMap = riderConditionResultMap[riderId] || {};
        const conditionMap = conditions.map(c => {
          const conditionResult = conditionResultMap[c];
          return {
            conditionName: c,
            result: (0, _lodash.isUndefined)(conditionResult) ? '-' : conditionResult
          };
        });

        if (pcaRequired) {
          conditionMap.push({
            conditionName: 'PCA REQUIRED',
            result: '-'
          });
        }

        result[riderId] = conditionMap;
        return result;
      }, {});

      if (!filterFailedConditions) {
        return allRiderIdToConditionMap;
      } //filter failed conditions riders and its result


      const riderIdToConditionMap = (0, _lodash.reduce)(allRiderIdToConditionMap, (result, riderConditionList, riderId) => {
        const failedConditionList = riderConditionList.filter(conditionResult => !conditionResult.result);

        if (failedConditionList.length > 0) {
          result[riderId] = failedConditionList;
        }

        return result;
      }, {});
      return riderIdToConditionMap;
    },

    async prepareBookingRawData(bookingRecord) {
      const legs = await this.prepareLegs(bookingRecord.get('legs'));
      const bookingPayload = {
        status: bookingRecord.get('status'),
        legs
      };

      if (this.get('tempBookingInfo.tempBookingId')) {
        bookingPayload.temporaryBookingId = this.get('tempBookingInfo.tempBookingId');
      }

      return bookingPayload;
    },

    prepareRider(riderRecord) {
      const formattedDob = (0, _moment.default)(riderRecord.get('dateOfBirth')).format('YYYY-MM-DD');
      return {
        riderId: riderRecord.get('id'),
        firstName: riderRecord.get('firstName'),
        middleName: riderRecord.get('middleName'),
        lastName: riderRecord.get('lastName'),
        dateOfBirth: formattedDob,
        notes: riderRecord.get('notes')
      };
    },

    prepareRiderPhoneNumber(riderRecord) {
      const mainPhoneNumber = riderRecord.get('notificationPhoneNumbers').find(pNo => {
        return pNo.type === 'MAIN' || pNo.type === 'client';
      });

      if (!mainPhoneNumber) {
        return null;
      }

      if (!mainPhoneNumber.description) {
        mainPhoneNumber.description = '# AT TOP OF CLIENT SCREEN';
      }

      return {
        areaCode: mainPhoneNumber.areaCode,
        phoneNumber: mainPhoneNumber.phoneNumber,
        extension: '',
        type: 'client',
        description: mainPhoneNumber.description,
        mobileCarrier: 'yes',
        useForSMSNotifications: mainPhoneNumber.useForSmsNotifications,
        useForVoiceNotifications: mainPhoneNumber.useForVoiceNotifications
      };
    },

    prepareServiceWindowName(serviceWindow) {
      return {
        id: serviceWindow.get('name')
      };
    },

    prepareFareTypeName() {
      return {
        id: 'cash'
      };
    },

    prepareLegTravelNeeds(legTravelNeedRecords, legId) {
      const travelNeedsRecords = this.filterPcaAndServiceAnimal(legTravelNeedRecords);
      return travelNeedsRecords.map(legtneedRecord => {
        const legTravelNeedIdCount = this.get('legTravelNeedIdCount') + 1;
        this.set('legTravelNeedIdCount', legTravelNeedIdCount);
        return {
          id: `-${legTravelNeedIdCount}`,
          count: legtneedRecord.get('count'),
          leg: {
            id: `-${legId}`
          },
          travelNeedTypeName: {
            id: legtneedRecord.get('travelNeedType.id')
          },
          passengerTypeName: {
            id: legtneedRecord.get('passengerType.id')
          }
        };
      });
    },

    filterPcaAndServiceAnimal(legTravelNeedRecords) {
      if (!this.isCheckPca()) {
        return legTravelNeedRecords.filter(ltr => {
          return ltr.get('travelNeedType.name').toLowerCase() !== TRAVEL_NEEDS_VERIFY[0];
        });
      }

      if (!this.isCheckServiceAnimal()) {
        return legTravelNeedRecords.filter(ltr => {
          return ltr.get('travelNeedType.name').toLowerCase() !== TRAVEL_NEEDS_VERIFY[1];
        });
      }

      return legTravelNeedRecords;
    },

    async prepareLegs(legRecords) {
      const selectedRiders = this.get('selectedRiders');
      const legsForAllRiders = Ember.A();
      const configItems = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-travel_need_types';
      });
      const bigSeatsTN = configItems.toArray().filter(tr => parseInt(tr.get('value.Vehicle Capacity Count'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('value.Name').toUpperCase());
      const legTravelNeedRecords = legRecords.firstObject.legTravelNeeds;
      const bigSeatTravelneeds = legTravelNeedRecords.filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      const uniqBigTns = {};
      bigSeatTravelneeds.forEach(t => {
        const type = t.get('travelNeedTypeNameUppercase');

        if (!uniqBigTns[type]) {
          uniqBigTns[type] = t;
          return;
        }

        uniqBigTns[type].set('count', parseInt(t.count, 10) + parseInt(uniqBigTns[type].count, 10));
      });
      const otherTravelNeeds = legTravelNeedRecords.filter(travelNeed => {
        return !bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      const uniqBigTRNs = Object.values(uniqBigTns);
      const allTravelNeeds = [...otherTravelNeeds, ...uniqBigTRNs];

      for (let i = 0; i < selectedRiders.length; i++) {
        const selectedRider = selectedRiders[i];
        const rider = this.prepareRider(selectedRider);
        const riderPhoneNumber = this.prepareRiderPhoneNumber(selectedRider);
        const riderLegs = [];

        for (let legIndex = 0; legIndex < legRecords.length; legIndex++) {
          const riderLeg = await this.prepareLeg(legRecords.objectAt(legIndex), allTravelNeeds, rider, riderPhoneNumber);
          riderLegs.push(riderLeg);
        }

        legsForAllRiders.pushObjects(riderLegs);
      }

      return legsForAllRiders;
    },

    async prepareLeg(leg, legTravelNeedRecords, rider, riderPhoneNumber) {
      const legIdCount = this.get('legIdCount') + 1;
      this.set('legIdCount', legIdCount);
      const segments = await this.prepareSegments(leg.get('segments'), legIdCount, leg);
      console.log(segments);
      const serviceWindowName = this.prepareServiceWindowName(leg.get('serviceWindow'));
      const legTravelNeeds = this.prepareLegTravelNeeds(this.filterConsumableTravelNeeds(legTravelNeedRecords), legIdCount, leg);
      return {
        id: `-${legIdCount}`,
        requestTime: leg.get('requestTime'),
        anchor: leg.get('anchor'),
        purpose: leg.get('purpose'),
        segments,
        serviceWindowName,
        rider,
        riderPhoneNumber,
        legTravelNeeds
      };
    },

    prepareSegments: async function (segmentRecords, legId, leg) {
      const legSegments = segmentRecords;

      if (this.canCreateItpSegment(legId)) {
        const itpRequestResults = this.get('itpInfo.itpRequestResults');
        const itpRequest = (0, _lodash.find)(itpRequestResults, itpResult => itpResult.legIndex === legId - 1);
        const itinerarySegments = await this.createSegmentsForItp(leg, legId, itpRequest);
        return itinerarySegments.map((segment, index) => {
          const pickSegmentStop = this.prepareSegmentStop(segment.get('pick'), 'pick');
          const dropSegmentStop = this.prepareSegmentStop(segment.get('drop'), 'drop');
          return this.prepareSegmentJson(segment, index, pickSegmentStop, dropSegmentStop, leg, legId, _tripType.ITP_TRIP);
        });
      }

      const firstSegment = legSegments.get('firstObject');
      const lastSegment = legSegments.get('lastObject');
      const pick = this.prepareSegmentStop(firstSegment.get('pick'), 'pick'); // there is only one segment in every leg

      const drop = this.prepareSegmentStop(lastSegment.get('drop'), 'drop');
      const segmentData = this.prepareSegmentJson(firstSegment, 0, pick, drop, leg, legId, _tripType.DEMAND_TRIP);
      return [segmentData];
    },

    prepareSegmentJson(segment, segmentIndex, pick, drop, leg, legId, segmentType) {
      const segmentId = `-${legId}${segmentIndex + 1}`;
      const legIndex = legId - 1;
      const legID = segmentType === _tripType.ITP_TRIP ? leg.get('id') : `-${legId}`;
      return {
        id: segmentId,
        promiseTime: this.getPromiseTime(legIndex, leg.toJSON()),
        anchor: 'pick',
        fare: leg.get('segment.fare'),
        travelMode: segment.get('travelMode') ? segment.get('travelMode') : 'paratransit',
        noSharing: leg.get('segment.noSharing'),
        legOrdinal: legId,
        segmentType: segmentType,
        pick,
        drop,
        leg: {
          id: legID ? legID : `-${legId}`
        },
        fareTypeName: this.prepareFareTypeName()
      };
    },

    /**
     * return true  if  travel model is selected as 'itp' and not a no_solution type
     * @returns {boolean|*}
     */
    canCreateItpSegment(legId) {
      const legIndex = legId - 1;
      const itpRequestResults = this.get('itpInfo.itpRequestResults');
      const itpRequest = (0, _lodash.find)(itpRequestResults, itpResult => itpResult.legIndex === legIndex);
      if (!itpRequest) return false;
      const itpResultType = itpRequest.result.type;
      const selectedTravelMode = this.getSelectedTravelMode(legIndex);
      const isSelectedTravelModeITP = selectedTravelMode === 'itp';
      const isNotNoSolutionType = itpResultType !== _itineraryType.NO_SOLUTION;
      return isSelectedTravelModeITP && isNotNoSolutionType;
    },

    prepareAddress(addressRecord) {
      const addressJson = (0, _unwrapProxy.unwrapProxy)(addressRecord).toJSON();
      return { ...addressJson,
        streetNumber: addressJson.streetNumber,
        zoneName: addressJson.zName || '1'
      };
    },

    prepareSegmentStop(segmentStop, type, ignoreSegmentStopCount) {
      const placeRecord = segmentStop.get('place');
      const addressRecord = placeRecord.get('address');
      const locationRecord = placeRecord.get('location');
      const place = this.preparePlace();
      const segmentStopId = this.segmentStopIdCount + 1;

      if (!ignoreSegmentStopCount) {
        this.set('segmentStopIdCount', segmentStopId);
      }

      return {
        id: `-${segmentStopId}`,
        type,
        dwell: segmentStop.get('dwell'),
        place,
        notes: segmentStop.get('notes'),
        address: this.prepareAddress(addressRecord),
        location: this.prepareLocation(locationRecord)
      };
    },

    prepareLocation(locationRecord) {
      const location = (0, _unwrapProxy.unwrapProxy)(locationRecord);
      return {
        lat: location.get('lat').toString(),
        lng: location.get('lng').toString(),
        geoNode: location.get('geoNode')
      };
    },

    preparePlace() {
      return {
        placeCategoryTypeName: 'passenger',
        geocodingMethod: 'something'
      };
    },

    async convertBookingRecordToPayloadFormat(bookingRecord) {
      const bookingRawData = await this.prepareBookingRawData(bookingRecord);
      const jsonapi = this.serializer.serialize('booking', bookingRawData);
      const payload = this.prepareFinalPayloadForBooking(jsonapi);
      console.log(JSON.stringify(payload));
      return payload;
    },

    bookingScheduleData() {
      const bookingScheduleData = {
        'data': {
          'id': '',
          'type': 'booking',
          'attributes': {
            'destination': 'dispatch'
          }
        }
      };
      return bookingScheduleData;
    },

    resetAllEntityCounts() {
      this.setProperties({
        legIdCount: 0,
        legTravelNeedIdCount: 0,
        segmentStopIdCount: 0
      });
    },

    saveBooking(isEditMode) {
      return new Promise(async (resolve, reject) => {
        const payload = await this.convertBookingRecordToPayloadFormat(this.get('activeBooking'));

        if (isEditMode) {
          this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking-with-subentity/' + this.get('activeBooking.id'), {
            method: 'PUT',
            contentType: 'application/json',
            headers: {
              Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
            },
            data: JSON.stringify(payload)
          }).then(res => {
            this.resetAllEntityCounts();
            resolve(res);
          }).catch(err => {
            if (err.status === 400) {
              err.message = 'There was a problem with one of the fields. Please check over the form and try again.';
            }

            this.resetAllEntityCounts();
            reject(err);
          });
        } else {
          this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking-with-subentity', {
            method: 'POST',
            contentType: 'application/json',
            headers: {
              Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
            },
            data: JSON.stringify(payload)
          }).then(res => {
            this.resetAllEntityCounts();
            resolve(res);
          }).catch(err => {
            if (err.status === 400) {
              err.message = 'There was a problem with one of the fields. Please check over the form and try again.';
            }

            this.resetAllEntityCounts();
            reject(err);
          });
        }
      });
    },

    saveBookingStatus(bookingId) {
      return new Promise(async (resolve, reject) => {
        const payload = await this.convertBookingRecordToPayloadFormat(this.get('activeBooking'));
        this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking-with-subentity/' + bookingId, {
          method: 'PATCH',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
          },
          data: JSON.stringify(payload)
        }).then(res => {
          this.resetAllEntityCounts();
          resolve(res);
        }).catch(err => {
          if (err.status === 400) {
            err.message = 'There was a problem with one of the fields. Please check over the form and try again.';
          }

          this.resetAllEntityCounts();
          reject(err);
        });
      });
    },

    /**
     * update temp booking with  status 'scheduled' and  update  legs and segments..
     * then call schedule service to create trips
     * @returns {Promise<void>}
     */
    async acceptBooking(isEditMode) {
      const payload = await this.convertBookingRecordToPayloadFormat(this.get('activeBooking'));
      let acceptBookingApi = `${_apiUrls.API.bookingService.host}/primary-booking`;
      let apiMethod = 'POST';

      if (isEditMode) {
        acceptBookingApi = `${_apiUrls.API.bookingService.host}/primary-booking/${this.get('activeBooking.id')}`;
        apiMethod = 'PUT';
      } // this api will update temporary booking with status 'Scheduled' and update promise time
      // Create Trips into Primary Schedule into Scheduling with "scheduleType": "primary"


      return this.get('ajax').post(acceptBookingApi, {
        method: apiMethod,
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
        },
        data: JSON.stringify(payload)
      }).then(res => {
        this.resetAllEntityCounts();
        return res;
      }).catch(err => {
        if (err.status === 400) {
          err.message = 'There was a problem with one of the fields. Please check over the form and try again.';
        }

        this.resetAllEntityCounts();
        return Promise.reject(err);
      });
    },

    // todo : remove this logic in the future when the payload is json api complacent
    prepareFinalPayloadForBooking(jsonApi) {
      const included = jsonApi.included.map(entity => {
        if (entity.type === 'segmentStop') {
          const address = entity.attributes.address;
          const place = entity.attributes.place;
          const location = entity.attributes.location;
          delete entity.attributes.address;
          delete entity.attributes.place;
          delete entity.attributes.location;
          return { ...entity,
            relationships: {
              address: {
                data: {
                  type: 'address',
                  ...address
                }
              },
              place: {
                data: {
                  type: 'place',
                  ...place
                }
              },
              location: {
                data: {
                  type: 'location',
                  ...location
                }
              }
            }
          };
        }

        if (entity.type === 'leg') {
          const rider = entity.attributes.rider;
          const riderPhoneNumber = entity.attributes.riderPhoneNumber;
          delete entity.attributes.rider;
          return { ...entity,
            relationships: { ...entity.relationships,
              rider: {
                data: {
                  type: 'rider',
                  ...rider
                }
              },
              riderPhoneNumber: {
                data: { ...riderPhoneNumber
                }
              }
            }
          };
        }

        return entity;
      });
      return {
        data: jsonApi.data,
        included
      };
    },

    setActiveLegsInBooking(activeLegsInBooking) {
      this.set('activeLegsInBooking', activeLegsInBooking.toArray());
    },

    async fetchAddressFotLatLng(latlng) {
      // if the point is outside the valid zone polygon
      // return error and exit
      if (!(0, _zoneValidation.insideZone)([latlng.lat, latlng.lng])) {
        //this.get('notifications').warning('Unable to save. Location must be within the agency service boundary.');
        return;
      }

      return _tomtom.default.reverseGeocode().key(_environment.default.tomtom.search.searchKey).position(`${latlng.lat},${latlng.lng}`).go();
    },

    updateAddress(record, ttAddress) {
      record.set('streetNumber', cleanFieldString(ttAddress.address.streetNumber));
      record.set('premise', cleanFieldString(ttAddress.premise));
      record.set('streetAddress', cleanFieldString(ttAddress.address.streetName));
      record.set('locality', cleanFieldString(ttAddress.address.municipality));
      record.set('region', cleanFieldString(ttAddress.address.countrySubdivisionName));
      record.set('subRegion', cleanFieldString(ttAddress.address.countrySecondarySubdivision));
      record.set('postalCode', cleanFieldString(ttAddress.address.postalCode));
      record.set('country', cleanFieldString(ttAddress.address.countryCode));
      record.set('freeformAddress', cleanFieldString(ttAddress.address.freeformAddress));
    },

    setActiveDragPlace(place) {
      this.set('activeDragPlace', place);
    },

    addSelectedRider(rider) {
      this.get('selectedRiders').pushObject(rider);
    },

    removeSelectedRider(rider) {
      this.get('selectedRiders').removeObject(rider);
    },

    clearSelectedRiders() {
      this.set('selectedRiders', []);
    },

    async fetchRiderForTravelNeeds(externalRider) {
      const riderId = externalRider.get('id');

      try {
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        const externalRiderRecord = await this.store.queryRecord('rider-external', riderId);
        return externalRiderRecord;
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    async fetchAllSelectedRidersForTravelNeeds() {
      return new Promise((resolve, reject) => {
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        const externalRiderPromises = this.get('selectedRiders').map(rider => {
          return this.fetchRiderForTravelNeeds(rider);
        });
        Promise.all(externalRiderPromises).then(response => {
          console.log('fetched travel needs for all selected Riders');
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          resolve(response);
        }).catch(e => {
          this.get('notifications').warning('error while fetching external rider for travel needs.');
          reject(e);
        });
      });
    },

    async fetchEligibilityForRider(rider) {
      return new Promise((res, rej) => {
        const riderId = rider.get('id');
        const session = this.get('session');
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        this.get('ajax').request(_apiUrls.API.bookingService.host + `/rider-external/${riderId}/eligibility`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          contentType: 'application/json'
        }).then(riderEligibilityResponse => {
          const riderExternalEligibilityRecord = this.store.createRecord('rider-external-eligibility', { ...riderEligibilityResponse
          });
          rider.set('eligibility', riderExternalEligibilityRecord);
          riderExternalEligibilityRecord.set('riderExternal', rider);
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          res(riderExternalEligibilityRecord);
        }).catch(error => {
          rej(error);
        });
      });
    },

    // this can be done using store find but the url requires reshaping
    // we are trying to get Eligibilities for each selected Rider and set it to store
    async fetchAndStoreEligibilityForAllSelectedRiders() {
      return new Promise((resolve, reject) => {
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        const eligibilityPromises = this.get('selectedRiders').map(rider => {
          return this.fetchEligibilityForRider(rider);
        });
        Promise.all(eligibilityPromises).then(response => {
          console.log('fetched eligibility for all selected Riders');
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          resolve(response);
        }).catch(e => {
          this.get('notifications').warning('error while fetching eligibility for rider.');
          reject(e);
        });
      });
    },

    async getAddressGeoNode(freeformAddress, selectType) {
      try {
        const geoNode = await this.get('geocode').getGeonode(freeformAddress);

        if (Ember.isNone(geoNode)) {
          this.showErrorMessage(`Unknown Address. Please enter a valid ${selectType} address.`);
        }

        return geoNode;
      } catch (e) {
        this.showErrorMessage(`Error while getting genode for ${freeformAddress}.`);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    async getBookingDetails(bookingId) {
      this.store.findRecord('booking', bookingId);
    },

    createSubscriptionRecord() {
      const currentDate = new Date();
      const exclusion = this.get('store').createRecord('subscription-exclusion');
      const recurrencePattern = this.get('store').createRecord('subscription-recurrence-pattern', {
        type: 'daily',
        recurring: true
      });
      const subscription = this.get('store').createRecord('subscription', {
        requestTime: currentDate,
        exclusions: [exclusion],
        recurrencePatterns: [recurrencePattern]
      });
      return subscription;
    },

    createLegTravelNeeds(riderTravelNeeds) {
      const store = this.get('store');
      const legTravelNeeds = [];
      const ambulatoryTravelNeed = riderTravelNeeds.find(rtneed => {
        return rtneed.type.toUpperCase() === 'AMBULATORY';
      });
      const companionTravelNeed = riderTravelNeeds.find(rtneed => {
        return rtneed.type.toUpperCase() === 'COMPANION';
      });
      riderTravelNeeds.forEach(riderTravelNeed => {
        const travelNeedType = store.peekAll('travel-need-type').find(tntype => {
          return tntype.get('upperCaseName') === riderTravelNeed.type;
        });

        if (!Ember.isNone(travelNeedType)) {
          const legTravelNeed = store.createRecord('leg-travel-need', {
            count: riderTravelNeed.cnt,
            travelNeedType: travelNeedType,
            passengerType: store.peekAll('passenger-type').find(ptype => {
              const ptypename = riderTravelNeed.type === 'PCA' ? 'pca' : 'client';
              return ptype.name === ptypename;
            })
          });
          legTravelNeeds.push(legTravelNeed);
        }
      });

      if (!companionTravelNeed) {
        const companionTravelNeedType = store.peekAll('travel-need-type').find(tntype => {
          return tntype.get('name').toUpperCase() === 'COMPANION';
        });

        if (companionTravelNeedType) {
          const companionTravelNeedRecord = this.store.createRecord('leg-travel-need', {
            count: this.get('selectedCompanionCount'),
            travelNeedType: companionTravelNeedType,
            passengerType: this.store.peekRecord('passenger-type', 'client')
          });
          legTravelNeeds.push(companionTravelNeedRecord);
        }
      }

      if (!ambulatoryTravelNeed) {
        const ambulatoryTravelNeedType = store.peekAll('travel-need-type').find(tntype => {
          return tntype.get('name') && tntype.get('name').toUpperCase() === 'AMBULATORY';
        });

        if (ambulatoryTravelNeedType) {
          const ambulatoryTravelNeedRecord = this.store.createRecord('leg-travel-need', {
            count: 1,
            travelNeedType: ambulatoryTravelNeedType,
            passengerType: this.store.peekRecord('passenger-type', 'client')
          });
          legTravelNeeds.push(ambulatoryTravelNeedRecord);
        }
      }

      return legTravelNeeds;
    },

    createFirstLegSegmentRecord(legTravelNeeds, fareAmount) {
      const store = this.get('store');
      const serviceWindow = store.peekRecord('service-window', 'GENERAL');
      const fareType = store.peekAll('fare-type').firstObject;
      const date = new Date(new Date());
      date.setDate(date.getDate() + 1);
      const pickAddress = store.createRecord('address');
      const pickLocation = store.createRecord('location');
      const pickPlace = store.createRecord('place', {
        address: pickAddress,
        location: pickLocation
      });
      const dropAddress = store.createRecord('address');
      const dropLocation = store.createRecord('location');
      const dropPlace = store.createRecord('place', {
        address: dropAddress,
        location: dropLocation
      });
      const leg = store.createRecord('leg', {
        requestTime: date,
        anchor: 'pick',
        purpose: 'trip',
        notes: '',
        serviceWindow,
        legTravelNeeds
      });
      const segment = store.createRecord('segment', {
        promiseTime: date,
        anchor: 'pick',
        fare: fareAmount,
        travelMode: '',
        segmentType: _tripType.DEMAND_TRIP,
        noSharing: 0,
        legOrdinal: 1,
        pick: store.createRecord('segment-stop', {
          type: 'pick',
          notes: '',
          plannedTime: date,
          onboardCount: 1,
          place: pickPlace
        }),
        drop: store.createRecord('segment-stop', {
          type: 'drop',
          notes: '',
          plannedTime: date,
          onboardCount: 1,
          place: dropPlace
        }),
        fareType: fareType,
        leg: leg
      });
      leg.set('segments', [segment]);
      legTravelNeeds.forEach(legTravelneed => {
        legTravelneed.set('leg', leg);
      });
      return leg;
    },

    createFreeFormAddressFromNyctAddress(nyctAddress) {
      return `${nyctAddress.streetNumber} ${nyctAddress.streetAddress}, ${nyctAddress.locality}, NY ${nyctAddress.postalCode}`;
    },

    async createLegSegmentRecordForSingleBooking() {
      const externalRider = this.get('selectedRiders.firstObject');
      await this.fetchRiderForTravelNeeds(externalRider);
      const availableZones = this.getNYCTTravelNeeds();
      const availableConfigZones = this.getSysConfigTravelNeeds();
      await this.fetchEligibilityForRider(externalRider);
      const fareAmount = this.getSysConfigRegularFare();
      const riderTravelNeeds = externalRider.get('travelNeeds');
      const store = this.get('store');
      const legTravelNeeds = this.createLegTravelNeeds(riderTravelNeeds);
      const firstLeg = this.createFirstLegSegmentRecord(legTravelNeeds, fareAmount);
      const primaryAddressToCreateLeg = externalRider.places.filter(place => place.type === 'Primary')[0];
      const pickAddress = store.createRecord('address', { ...primaryAddressToCreateLeg.address,
        subLocality: primaryAddressToCreateLeg.address.locality,
        country: 'United States',
        freeformAddress: this.createFreeFormAddressFromNyctAddress(primaryAddressToCreateLeg.address)
      });
      const pickLocation = store.createRecord('location', {
        lat: primaryAddressToCreateLeg.address.lat,
        lng: primaryAddressToCreateLeg.address.lng
      });
      const lat = pickAddress.get('lat');
      const lng = pickAddress.get('lng');
      const zoneName = this.checkZoneForAddress(availableZones, availableConfigZones, lat, lng);

      if (!zoneName) {
        this.showErrorMessage('Invalid pick Zone for passengers primary address.');
      }

      const geoNode = await this.get('geocode').getGeonode(pickAddress.get('freeformAddress'));

      if (!Ember.isNone(geoNode) && zoneName) {
        pickLocation.set('geoNode', geoNode.geonode);
        pickAddress.set('zName', zoneName);
        firstLeg.set('segments.firstObject.pick.place.address', pickAddress);
        firstLeg.set('segments.firstObject.pick.place.location', pickLocation);
        firstLeg.set('segments.firstObject.pick.notes', primaryAddressToCreateLeg.address.notes);
      } else {
        this.showErrorMessage('Primary address of passenger doesnt have geoNode.');
        const pickAddressRecord = store.createRecord('address');
        const pickLocationRecord = store.createRecord('location');
        firstLeg.set('segments.firstObject.pick.place.address', pickAddressRecord);
        firstLeg.set('segments.firstObject.pick.place.location', pickLocationRecord);
      }

      return [firstLeg];
    },

    async createLegSegmentRecordForGroupBooking() {
      const selectedRiders = this.get('selectedRiders');
      const totalFareAmount = selectedRiders.length * this.getSysConfigRegularFare();
      const configItems = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-travel_need_types';
      });
      const bigSeatsTN = configItems.toArray().filter(tr => parseInt(tr.get('value.Vehicle Capacity Count'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('value.Name').toUpperCase());
      const travelNeedsOfAllSelectedRiders = selectedRiders.map(r => r.get('travelNeeds')).reduce((t1, t2) => {
        return t1.concat(t2);
      }, []);
      const bigSeatTravelneeds = travelNeedsOfAllSelectedRiders.filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.type);
      });
      const otherTravelNeeds = travelNeedsOfAllSelectedRiders.filter(travelNeed => {
        return !bigSeatTRs.includes(travelNeed.type);
      });
      const uniqTravelNeeds = (0, _lodash.uniqBy)(otherTravelNeeds, t => t.type);
      const allTravelNeeds = [...uniqTravelNeeds, ...bigSeatTravelneeds];
      const legTravelNeeds = this.createLegTravelNeeds(allTravelNeeds);
      const firstLeg = this.createFirstLegSegmentRecord(legTravelNeeds, totalFareAmount);
      return [firstLeg];
    },

    async createBookingRecord(isGroupBooking) {
      const provider = this.store.peekAll('provider').firstObject; // @TODO booking requires a provider

      const subscription = this.createSubscriptionRecord();
      const legs = isGroupBooking ? await this.createLegSegmentRecordForGroupBooking() : await this.createLegSegmentRecordForSingleBooking();
      const booking = this.store.createRecord('booking', {
        subscription,
        legs,
        provider
      });
      legs.forEach(leg => {
        leg.set('booking', booking);
      });
      return booking;
    },

    setCompanionCount(selectedCompanionCount) {
      this.set('selectedCompanionCount', selectedCompanionCount);
    },

    showErrorMessage(message) {
      this.set('errorMessage', message);
      this.set('isOpenErrorModal', true);
    },

    setActiveAddress(activeAddress) {
      this.set('activeAddress', activeAddress);
    },

    fetchWeatherForecastData() {
      return this.get('ajax').retryGet(WEATHER_API, WEATHER_WITHOUT_HOURLY_API);
    },

    preparePayloadForDemandTrip(leg, rider) {
      const {
        value: feederTripSchedulingWindows
      } = this.store.peekRecord('cs-config-item', 'config-Scheduling-service_windows/FeederTrip');
      const {
        value: maximumWalkingDistanceValue
      } = this.store.peekRecord('cs-config-item', 'config-Fixed_Route_Engine_Parameters/DefaultMaximumWalkingDistance');
      const firstSegment = leg.get('segments').firstObject;
      const lastSegment = leg.get('segments').lastObject;
      const pick = this.prepareSegmentStop(firstSegment.get('pick'), 'pick', true);
      const drop = this.prepareSegmentStop(lastSegment.get('drop'), 'drop', true);
      const tripAnchor = leg.get('anchor') === 'pick' ? 0 : 1; //TODO about .local() once got NYAAR-11301 fix

      const requestedTime = (0, _moment.default)(leg.get('requestTime')).local().format('YYYY-MM-DDTHH:mm');
      const data = {
        'request': {
          'requestId': generateUUID(),
          'pickCoordinates': {
            'lat': Number(pick.location.lat),
            'lng': Number(pick.location.lng)
          },
          'dropCoordinates': {
            'lat': Number(drop.location.lat),
            'lng': Number(drop.location.lng)
          },
          'tripAnchor': tripAnchor,
          'requestedTime': requestedTime,
          'searchWindow': {
            'start': feederTripSchedulingWindows['Search Window Start'],
            'end': feederTripSchedulingWindows['Search Window End']
          },
          'clientInformation': {
            'clientId': Number(rider.get('id')),
            'loadTime': rider.get('loadTime'),
            'unloadTime': rider.get('unloadTime'),
            'walkingSpeed': rider.get('walkingSpeed'),
            'maximumWalkingDistance': Number(maximumWalkingDistanceValue)
          }
        }
      };
      return data;
    },

    postScheduleDemandTripForLeg(leg, legIndex) {
      const selectedRider = this.get('selectedRiders').firstObject;
      const payload = this.preparePayloadForDemandTrip(leg, selectedRider);
      return this.get('ajax').request(ITP_DEMAND_TRIP_API, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
        },
        data: JSON.stringify(payload)
      }).then(results => {
        return {
          legIndex,
          result: results[0]
        };
      });
    },

    async createSegmentsForItp(leg, legId, itpRequestResult) {
      const {
        segments: itpItinerarySegments
      } = itpRequestResult.result;
      const segmentPromises = itpItinerarySegments.map(itpItinerarySegment => {
        return this.createLegSegmentForItp(itpItinerarySegment, legId, leg);
      });
      const segmentList = Promise.all(segmentPromises);
      return segmentList;
    },

    async createLegSegmentForItp(itpSegment, legOrdinal, leg) {
      const store = this.get('store');
      const fareType = store.peekAll('fare-type').firstObject; // create pick place  =>  itinerary pick address

      const pickPlace = await this.createPlaceByLatLng(itpSegment.start.coordinates, 'pickup'); // create drop place

      const dropPlace = await this.createPlaceByLatLng(itpSegment.end.coordinates, 'dropoff');
      const pickNotes = itpSegment.start.description || leg.get('segments.firstObject.pick.notes');
      const dropNotes = itpSegment.end.description || leg.get('segments.lastObject.drop.notes');
      const {
        action,
        description
      } = _itineraryAction.ITINERARY_ACTION_MAP[itpSegment.action];
      let travelMode = action;

      if (action === _itineraryAction.ITINERARY_TRAVEL_MODE.BUS) {
        travelMode = `${action} ${description}`;
      }

      const segment = store.createRecord('segment', {
        promiseTime: (0, _moment.default)(itpSegment.start.time).toDate(),
        anchor: 'pick',
        fare: leg.get('segment.fare'),
        travelMode: travelMode,
        segmentType: _tripType.ITP_TRIP,
        noSharing: 0,
        legOrdinal: legOrdinal,
        pick: store.createRecord('segment-stop', {
          type: 'pick',
          notes: pickNotes,
          plannedTime: (0, _moment.default)(itpSegment.start.time).toDate(),
          onboardCount: 1,
          place: pickPlace
        }),
        drop: store.createRecord('segment-stop', {
          type: 'drop',
          notes: dropNotes,
          plannedTime: (0, _moment.default)(itpSegment.end.time).toDate(),
          onboardCount: 1,
          place: dropPlace
        }),
        fareType: fareType
      });
      return segment;
    },

    async setPlaceAddressByLatLng(place, latlng, selectType) {
      const tomtomAddress = await this.fetchAddressFotLatLng(latlng);
      const freeformAddress = tomtomAddress.address.freeformAddress;
      const geoNode = await this.getAddressGeoNode(freeformAddress, selectType);

      if (!geoNode) {
        return;
      }

      const location = place.get('location');
      location.set('lat', parseFloat(latlng.lat.toFixed(5)));
      location.set('lng', parseFloat(latlng.lng.toFixed(5)));
      location.set('geoNode', geoNode.geonode);
      const placeAddress = place.get('address');
      this.updateAddress(placeAddress, tomtomAddress);
    },

    async setPlaceAddressByLatLngforITPInDB(location, address, latlng, selectType) {
      const tomtomAddress = await this.fetchAddressFotLatLng(latlng);
      const freeformAddress = tomtomAddress.address.freeformAddress;
      const geoNode = await this.getAddressGeoNode(freeformAddress, selectType);

      if (!geoNode) {
        return;
      }

      location.set('lat', parseFloat(latlng.lat.toFixed(5)));
      location.set('lng', parseFloat(latlng.lng.toFixed(5)));
      location.set('geoNode', geoNode.geonode);
      this.updateAddress(address, tomtomAddress);
    },

    async fetchOrCreatePlaceByLatLngForITPInDB(latlng, selectType) {
      const store = this.get('store');
      const locationsQuery = `and(eq(lat,'${latlng.lat}'),eq(lng,'${latlng.lng}'))`;
      const locations = await store.query('location', {
        filter: locationsQuery
      });

      if (!Ember.isEmpty(locations)) {
        const placeQuery = `in(locationId,('${locations.firstObject.id}'))`;
        const places = await store.query('place', {
          filter: placeQuery
        });
        return places.firstObject;
      }

      const address = store.createRecord('address');
      const location = store.createRecord('location');
      await this.setPlaceAddressByLatLngforITPInDB(location, address, latlng, selectType);
      const dbLocation = await location.save();
      const dbAddress = await address.save();
      const place = store.createRecord('place', {
        address: dbAddress,
        location: dbLocation
      });
      return place.save();
    },

    async createSegmentsForItpInDB(leg, legId, itpRequestResult) {
      const {
        segments: itpItinerarySegments
      } = itpRequestResult.result;
      const itpItineraryParaTransitSegments = itpItinerarySegments.filter(seg => {
        const {
          action
        } = _itineraryAction.ITINERARY_ACTION_MAP[seg.action];

        if (action === 'paratransit') {
          return true;
        }

        return false;
      });
      const segmentPromises = itpItineraryParaTransitSegments.map(async itpItinerarySegment => {
        return await this.createLegSegmentForItpInDB(itpItinerarySegment, legId, leg);
      });
      const segmentList = await Promise.all(segmentPromises);
      return segmentList;
    },

    async createLegSegmentForItpInDB(itpSegment, legOrdinal, leg) {
      const store = this.get('store');
      const fareType = store.peekAll('fare-type').firstObject; // create pick place  =>  itinerary pick address

      const pickPlace = await this.fetchOrCreatePlaceByLatLngForITPInDB(itpSegment.start.coordinates, 'pickup'); // create drop place

      const dropPlace = await this.fetchOrCreatePlaceByLatLngForITPInDB(itpSegment.end.coordinates, 'dropoff');
      const pickNotes = itpSegment.start.description || leg.get('segments.firstObject.pick.notes');
      const dropNotes = itpSegment.end.description || leg.get('segments.lastObject.drop.notes');
      const {
        action,
        description
      } = _itineraryAction.ITINERARY_ACTION_MAP[itpSegment.action];
      let travelMode = action;

      if (action === _itineraryAction.ITINERARY_TRAVEL_MODE.BUS) {
        travelMode = `${action} ${description}`;
      }

      const pickSegmentStop = await store.createRecord('segment-stop', {
        type: 'pick',
        notes: pickNotes,
        plannedTime: (0, _moment.default)(itpSegment.start.time).toDate(),
        onboardCount: 1,
        place: pickPlace,
        dwell: 0
      });
      const dropSegmentStop = await store.createRecord('segment-stop', {
        type: 'drop',
        notes: dropNotes,
        plannedTime: (0, _moment.default)(itpSegment.end.time).toDate(),
        onboardCount: 1,
        place: dropPlace,
        dwell: 0
      });
      const pick = await pickSegmentStop.save();
      const drop = await dropSegmentStop.save();
      const segment = await store.createRecord('segment', {
        promiseTime: (0, _moment.default)(itpSegment.start.time).toDate(),
        anchor: 'pick',
        fare: leg.get('segment.fare'),
        travelMode: travelMode,
        segmentType: _tripType.ITP_TRIP,
        noSharing: 0,
        legOrdinal: legOrdinal,
        pick: pick,
        drop: drop,
        leg: leg,
        fareType: fareType
      });
      return await segment.save();
    },

    addBookingTripForITPSegment: (0, _emberConcurrency.task)(function* (schedule, operation, options) {
      let asyncResult;

      try {
        const {
          isJobSuccess,
          results
        } = yield this.createScheduleAsyncOperation.perform(schedule, operation, options);

        if (!isJobSuccess) {
          return;
        }

        asyncResult = JSON.parse(results);
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          this.get('notifications').warning('Adding trip for ITP Segment Failed');
          throw e;
        }
      }

      return Promise.resolve(asyncResult);
    }).drop(),

    async getPromiseTimeForITPSegments(segments, booking) {
      const bookingId = booking.get('id');
      const tripsByBookingID = `and(eq(schedule.type,'booking'),in(booking,('${bookingId}')))&include=schedule,route,pick,drop`;
      const trips = await this.get('store').query('trip', {
        filter: tripsByBookingID
      });
      let schedule;

      if (!Ember.isEmpty(trips)) {
        schedule = trips.firstObject.get('schedule');
      }

      if (Ember.isEmpty(schedule)) {
        return;
      }

      const operationData = this.prepareAddBookingTripPayload(segments, booking, schedule);
      const options = {
        operationData,
        outputResult: true
      };
      const results = await this.addBookingTripForITPSegment.perform(schedule, 'addBookingTrip', options);
      return results;
    },

    prepareAddBookingTripPayload(segments, booking, schedule) {
      const {
        tripDataList,
        segmentStopIdMapList
      } = this.getTripDataPayloadInfo(booking, segments, schedule);
      const included = this.getIncludedPayload(booking, segments, segmentStopIdMapList);
      return {
        data: tripDataList,
        included
      };
    },

    /**
     * return tripdata payload for addBookingTrip schedule operation
     * @param booking
     * @param segments
     * @param schedule
     * @returns {{tripDataList: Array, segmentStopIdMapList: Array}}
     */
    getTripDataPayloadInfo(booking, segments, schedule) {
      const segmentStopIdMapList = [];
      const tripDataList = [];
      let segmentStopId = 0;

      for (const segment of segments) {
        const pickSegmentStop = segment.get('pick');
        const dropSegmentStop = segment.get('drop');
        const pickTripStopId = `-${++segmentStopId}`;
        const dropTripStopId = `-${++segmentStopId}`;
        const pickSegmentStopIdMap = {
          segmentId: segment.get('id'),
          tripStopId: pickTripStopId,
          segmentStopId: pickSegmentStop.get('id')
        };
        segmentStopIdMapList.push(pickSegmentStopIdMap);
        const dropSegmentStopIdMap = {
          segmentId: segment.get('id'),
          tripStopId: dropTripStopId,
          segmentStopId: dropSegmentStop.get('id')
        };
        segmentStopIdMapList.push(dropSegmentStopIdMap);
        const tripData = {
          type: 'trip',
          attributes: {
            status: 'waitlisted'
          },
          relationships: {
            pick: {
              data: {
                type: 'tripStop',
                id: pickTripStopId
              }
            },
            drop: {
              data: {
                type: 'tripStop',
                id: dropTripStopId
              }
            },
            schedule: {
              data: {
                type: 'schedule',
                id: schedule.get('id')
              }
            },
            segment: {
              data: {
                type: 'segment',
                id: segment.get('id')
              }
            },
            booking: {
              data: {
                type: 'booking',
                id: booking.get('id')
              }
            }
          }
        };

        if (booking.get('subscription.id')) {
          tripData.relationships.subscription = {
            data: {
              type: 'subscription',
              id: booking.get('subscription.id')
            }
          };
        }

        tripDataList.push(tripData);
      }

      return {
        tripDataList,
        segmentStopIdMapList
      };
    },

    /**
     * return included payload for addBookingTrip schedule operation
     * @param booking
     * @param segments
     * @param segmentStopIdMapList
     * @returns {{type: string, id: *}[]}
     */
    getIncludedPayload(booking, segments, segmentStopIdMapList) {
      const included = [{
        type: 'booking',
        id: booking.get('id')
      }];

      if (booking.get('subscription.id')) {
        included.push({
          type: 'subscription',
          id: booking.get('subscription.id')
        });
      }

      for (const segment of segments) {
        const segmentStopIdList = segmentStopIdMapList.filter(segmentStopIdInfo => {
          return segmentStopIdInfo.segmentId === segment.get('id');
        });
        const segmentInfo = {
          type: 'segment',
          id: segment.get('id')
        };
        included.push(segmentInfo);
        segmentStopIdList.forEach(segmentStopIdInfo => {
          const segmentStop = {
            type: 'segmentStop',
            id: segmentStopIdInfo.segmentStopId
          };
          included.push(segmentStop);
          const tripStop = {
            type: 'tripStop',
            id: segmentStopIdInfo.tripStopId,
            attributes: {
              plannedRouteOrdinal: 1
            },
            relationships: {
              segmentStop: {
                data: {
                  type: 'segmentStop',
                  id: segmentStopIdInfo.segmentStopId
                }
              }
            }
          };
          included.push(tripStop);
        });
      }

      return included;
    },

    /**
     *
     * @param latlng {lat : <value> , lng : <value>}
     * @returns {Promise<void>}
     */
    async createPlaceByLatLng(latlng, selectType) {
      const store = this.get('store');
      const address = store.createRecord('address');
      const location = store.createRecord('location');
      const place = store.createRecord('place', {
        address: address,
        location: location
      });
      await this.setPlaceAddressByLatLng(place, latlng, selectType);
      return place;
    },

    setItpInfo(itpRequestResults, itpResultType, hasOverrideITP) {
      if (!itpRequestResults || !itpResultType) {
        return this.set('itpInfo', {
          itpRequestResults: [],
          itpResultType: null,
          hasOverrideITP: null
        });
      }

      this.set('itpInfo', {
        itpRequestResults,
        itpResultType,
        hasOverrideITP
      });
    },

    setSelectedTravelMode(legIndex, travelMode) {
      if (legIndex < 0 || !travelMode) {
        return this.set('selectedTravelModeMap', {});
      }

      this.set(`selectedTravelModeMap.${legIndex}`, travelMode);
    },

    setSelectedTravelModeInBooking(legIndex, travelMode) {
      if (legIndex < 0 || !travelMode) {
        return;
      }

      const legs = this.get('activeBooking').legs;
      const concernLeg = legs.objectAt(legIndex);
      concernLeg.set('segments.firstObject.travelMode', travelMode);
    },

    getSelectedTravelMode(legIndex) {
      const legTravelModeMap = this.get('selectedTravelModeMap');
      return legTravelModeMap[legIndex];
    },

    /**
     * save temporary booking with status 'requested' and adding booking to trips.
     * this api will give  promise time  for each legs
     * @returns {Promise<*>}
     */
    async saveBookingWithPromiseTime() {
      const payload = await this.convertBookingRecordToPayloadFormat(this.get('activeBooking'));
      return await this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking-with-promise-time', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
        },
        data: JSON.stringify(payload)
      }).then(result => {
        this.resetAllEntityCounts();
        return result;
      }).catch(err => {
        if (err.status === 400) {
          err.message = 'There was a problem with one of the fields. Please check over the form and try again.';
        }

        this.resetAllEntityCounts();
        return Promise.reject(err);
      });
    },

    // call promise time  async operation  and  store the promise time result
    async processPromiseTimeAsyncOperation(temporaryBookingResult) {
      const {
        promiseTimeAsyncOperationInfoArray,
        bookingId
      } = temporaryBookingResult;
      this.set('tempBookingInfo.tempBookingId', bookingId); // if there is no schedule, promiseTimeAsyncOperationInfo will be empty
      // continue booking even promise time retrieval fails

      if (!promiseTimeAsyncOperationInfoArray) {
        return;
      }

      await this.get('store').queryRecord('booking', bookingId);
      let promiseTimeResultIncludedData = [];
      const promiseTimeResultDataList = {};

      for (const promiseTimeAsyncOperationInfo of promiseTimeAsyncOperationInfoArray) {
        const {
          isJobSuccess,
          results
        } = await this.callScheduleAsyncOperation(promiseTimeAsyncOperationInfo);

        if (isJobSuccess) {
          const promiseTimeResult = JSON.parse(results);
          const promiseTimeResultData = promiseTimeResult.data || [];
          const included = promiseTimeResult.included || [];
          promiseTimeResultData.forEach(promiseTimeResponse => {
            const segID = promiseTimeResponse.relationships.segment.data.id;
            const segData = this.get('store').peekRecord('segment', segID);
            const legOrdinal = segData.get('legOrdinal');
            promiseTimeResultDataList[`'${legOrdinal}'`] = promiseTimeResponse;
          });
          promiseTimeResultIncludedData = [...promiseTimeResultIncludedData, ...included];
        } else {
          console.warn('failed to fetch promise time ' + results);
        }
      }

      this.set('tempBookingInfo.promiseTimeResult', promiseTimeResultDataList);
      this.set('tempBookingInfo.promiseTimeResultIncludedData', promiseTimeResultIncludedData);
    },

    async callScheduleAsyncOperation(asyncOperationInfo) {
      return await this.get('asyncOperationState').perform(asyncOperationInfo, true);
    },

    setTemporaryBookingInfo(tempBookingId, promiseTimeResult) {
      this.set('tempBookingInfo.tempBookingId', tempBookingId);
      this.set('tempBookingInfo.promiseTimeResult', promiseTimeResult);
    },

    /**
     *  we can get promise time if these  4  condition  is passed
     * @param legIndex
     * @returns {*|boolean}
     */
    canGetPromiseTime(legIndex) {
      return this.get('enableNewPromiseTimeApi') && this.get('tempBookingInfo.tempBookingId') && this.hasPromiseTime(legIndex);
    },

    /**
     * get promise time  if paratransit or itp(feeder mode)  is selected..
     * otherwise return leg requested time
     * @param legIndex
     * @param leg  ==> leg object is plain javascript object
     * @returns {*}
     */
    getPromiseTime(legIndex, leg) {
      // if promise time  cannot be selected , get default   leg request time
      if (!this.canGetPromiseTime(legIndex)) {
        return leg.requestTime;
      }

      const promiseTimeData = this.get('tempBookingInfo.promiseTimeResult') || {};
      const promiseTimeResult = promiseTimeData[`'${legIndex + 1}'`];
      return promiseTimeResult.attributes.promiseTime;
    },

    /**
     * @param legIndex
     * @returns {boolean}
     */
    hasPromiseTimeforItpsegment(legIndex) {
      const promiseTimeResultList = this.get('itpSegmentPromiseTimeResults.data') || [];
      const promiseTimeResult = promiseTimeResultList[legIndex];
      return !!promiseTimeResult;
    },

    /**
     *  we can get promise time if these conditions passed
     * @param legIndex
     * @returns {*|boolean}
     */
    canGetPromiseTimeforItpsegment(legIndex) {
      return this.get('enableNewPromiseTimeApi') && this.hasPromiseTimeforItpsegment(legIndex);
    },

    /**
     * get promise time  for paratransit segments when itp(feeder mode)  is selected..
     * otherwise return leg requested time
     * @param legIndex
     * @param leg  ==> leg object is plain javascript object
     * @returns {*}
     */
    getPromiseTimeforItpsegment(legIndex) {
      const promiseTimeData = this.get('itpSegmentPromiseTimeResults.data') || [];
      const promiseTimeResult = promiseTimeData[legIndex];
      return (0, _moment.default)(promiseTimeResult.attributes.promiseTime).format('hh:mm A');
    },

    /**
     *
     * @param legIndex
     * @param leg ==> leg object is plain javascript object
     * @returns {*}
     */
    getArrivalTime(legIndex, leg) {
      // if promise time  cannot be selected , get default   leg request time
      if (!this.canGetPromiseTime(legIndex)) {
        return leg.requestTime;
      }

      const promiseTimeData = this.get('tempBookingInfo.promiseTimeResult') || {};
      const promiseTimeResult = promiseTimeData[`'${legIndex + 1}'`];
      const dropTripStopId = promiseTimeResult.relationships.drop.data.id;
      const promiseTimeIncludedList = this.get('tempBookingInfo.promiseTimeResultIncludedData') || [];
      const dropTripStop = promiseTimeIncludedList.find(data => data.id === dropTripStopId);
      return dropTripStop && dropTripStop.attributes.plannedEta || leg.requestTime;
    },

    /**
     * @param legIndex
     * @returns {boolean}
     */
    hasPromiseTime(legIndex) {
      const promiseTimeResultList = this.get('tempBookingInfo.promiseTimeResult') || {};
      const promiseTimeResult = promiseTimeResultList[`'${legIndex + 1}'`];
      return !!promiseTimeResult;
    },

    // check temp booking is created
    hasTempBooking() {
      const tempBookingId = this.get('tempBookingInfo.tempBookingId');
      return !!tempBookingId;
    },

    async deleteTemporaryBooking(action) {
      try {
        this.get('workspace').set('isGlobalSpinnerVisible', true);

        if (this.get('enableNewPromiseTimeApi')) {
          const bookingID = this.get('tempBookingInfo.tempBookingId');
          const tripCancelJobs = await this.deleteBooking(bookingID, action);
          this.set('tempBookingInfo', {});
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          return tripCancelJobs ? tripCancelJobs.tripCancelJobs : [];
        }
      } catch (error) {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('notifications').warning(`ERROR: ${error.message} WHILE DELETING TEMPORARY DATA`);
      }
    },

    async deleteBooking(bookingID, action) {
      const session = this.get('session');
      let json, resource;

      switch (action) {
        case 'x':
          json = JSON.stringify({
            doCancelBooking: true,
            doCancelTrip: true
          });
          resource = 'booking-with-subentity';
          break;

        case 'refuse':
        case 'taxi':
        case 'edit':
          json = JSON.stringify({
            doCancelTrip: true
          });
          resource = 'booking-with-subentity';
          break;

        default:
          json = JSON.stringify({
            doCancel: true
          });
          resource = 'booking';
      }

      return await this.get('ajax').post(_apiUrls.API.bookingService.host + `/${resource}/` + bookingID, {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    async fetchSubscriptionTrips(subscriptionId) {
      const session = this.get('session');
      return await this.get('ajax').request(_apiUrls.API.bookingService.host + '/subscription/' + subscriptionId + '/trips', {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    setCheckedSubscriptionTrips(trips) {
      const tripIds = trips.map(trip => trip.id);
      this.set('tripIds', tripIds);
    },

    isCheckPca() {
      const configTravelNeeds = this.get('configTravelNeedItems');
      return configTravelNeeds.some(tr => TRAVEL_NEEDS_VERIFY[0].includes(tr.get('value.Name')));
    },

    isCheckServiceAnimal() {
      const configTravelNeeds = this.get('configTravelNeedItems');
      return configTravelNeeds.some(tr => TRAVEL_NEEDS_VERIFY[1].includes(tr.get('value.Name')));
    },

    getNYCTTravelNeeds() {
      return this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-NYCT-zones';
      });
    },

    getSysConfigTravelNeeds() {
      return this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-zones';
      });
    },

    checkZoneForAddress(availableZones, availableConfigZones, lat, lng) {
      let zoneName, isInsideAvailZones;
      availableZones.forEach(zone => {
        const polygon = zone.get('value.points');
        const isInside = (0, _zoneValidation.isPointInsidePolygon)([lat, lng], polygon);

        if (isInside) {
          isInsideAvailZones = true;
          const isInSysConfig = availableConfigZones.find(configZone => {
            return configZone.get('name').toLowerCase() === zone.get('name').toLowerCase();
          });

          if (isInSysConfig) {
            zoneName = zone.get('displayName');
          }
        }
      });
      return {
        zoneName,
        isInsideAvailZones
      };
    },

    /**
     * check whether taxi mode selected
     * @returns {*|boolean}
     */
    isTaxiModeSelected() {
      const selectedTravelModeMap = this.get('selectedTravelModeMap');
      const selectedTravelModes = Object.values(selectedTravelModeMap) || [];
      const isTaxiSelected = selectedTravelModes.length === 0 ? false : selectedTravelModes.every(travelMode => {
        return travelMode.toLowerCase() === 'taxi';
      });
      return isTaxiSelected;
    },

    /**
     * check either  paratransit or  itp with feeder mode selected
     * @returns {*|boolean}
     */
    hasParaTransitOrItpFeederModeSelected(legIndex) {
      const {
        itpResultType
      } = this.get('itpInfo');
      const selectedTravelModeMap = this.get('selectedTravelModeMap');
      const selectedTravelModes = Object.values(selectedTravelModeMap) || [];
      const isParatransitSelected = selectedTravelModes.length === 0 ? false : selectedTravelModes[legIndex].toLowerCase() === 'paratransit';
      const isItpFeederResultType = itpResultType === _itineraryType.FEEDER;
      return isParatransitSelected || isItpFeederResultType;
    },

    // NYAAR-13442 Booking: Consumable travel needs with count=0 should not be saved in leg travel needs tables
    filterConsumableTravelNeeds(legTravelNeedRecords) {
      const configItems = this.get('configTravelNeedItems');
      const consumableTravelNeeds = configItems.toArray().filter(tr => parseInt(tr.get('value.Vehicle Capacity Count'), 10) > 0);
      const TR_N_TO_NOT_SAVE_IF_COUNT_ZERO = consumableTravelNeeds.map(tr => tr.get('value.Name').toUpperCase());
      return legTravelNeedRecords.filter(lt => !(TR_N_TO_NOT_SAVE_IF_COUNT_ZERO.indexOf(lt.get('travelNeedTypeNameUppercase')) > -1 && parseInt(lt.get('count'), 10) === 0));
    },

    // NYAAR-13459 Booking - Upon editing a booking, service mode is getting changed
    getActiveBookingTravelModeMap() {
      const legTravelModeMap = {};
      this.get('activeBooking.legs').forEach((leg, index) => {
        legTravelModeMap[index] = leg.get('segments.firstObject.travelMode');
      });
      return legTravelModeMap;
    },

    isNumber(val) {
      const regEx = /^[0-9]*$/;

      if (val && val.toString().match(regEx)) {
        return true;
      }

      return false;
    },

    getSysConfigCompanionFare() {
      const configItemsFareCategories = this.getSystemConfigFareCategories();
      const guestFareCategory = configItemsFareCategories.find(fareCategory => fareCategory.get('displayName') === 'Guest');
      return Number(guestFareCategory.value.Amount);
    },

    getSysConfigRegularFare() {
      const configItemsFareCategories = this.getSystemConfigFareCategories();
      const regularFareCategory = configItemsFareCategories.find(fareCategory => fareCategory.get('displayName') === 'REG');
      return Number(regularFareCategory.value.Amount);
    },

    getSystemConfigFareCategories() {
      return this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-fare_categories';
      });
    },

    setEmptyAddressLocation(place) {
      const address = this.store.createRecord('address');
      const location = this.store.createRecord('location');
      place.set('address', address);
      place.set('location', location);
    },

    validateDateNotToBePastDate(date, currentDate, prevDate, subscriptionType, dateType) {
      if ((0, _moment.default)(date).isBefore(currentDate) && !(0, _moment.default)(prevDate).isSame((0, _moment.default)(date))) {
        this.showErrorMessage(`Please select valid ${subscriptionType} ${dateType} date.`);
        return false;
      }

      return true;
    },

    async deleteTrips(bookingId) {
      const tripsByBookingID = `in(booking,('${bookingId}'))&include=schedule,route,pick,drop`;
      const trips = await this.get('store').query('trip', {
        filter: tripsByBookingID
      });

      if (!Ember.isEmpty(trips)) {
        for (const trip of trips.toArray()) {
          await trip.destroyRecord();
        }
      }
    }

  });

  _exports.default = _default;
});