define("adept-iq/services/user", ["exports", "adept-iq/helpers/has-role", "adept-iq/classes/road-supervisor-api", "adept-iq/classes/road-supervisor-special-route-api", "adept-iq/config/environment"], function (_exports, _hasRole, _roadSupervisorApi, _roadSupervisorSpecialRouteApi, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SCHEDULER_ROLE = 'scheduler';

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    userId: Ember.computed.alias('session.data.authenticated.tokenInfo.userId'),
    roles: Ember.computed.alias('session.data.authenticated.tokenInfo.roleNames'),

    hasRole(roleName) {
      return (0, _hasRole.hasRole)(this.roles, roleName);
    },

    isScheduler: Ember.computed('roles', function () {
      const roleNames = this.get('roles');
      return (0, _hasRole.hasRole)(roleNames, SCHEDULER_ROLE);
    }),

    isRoadSupEnable() {
      const roleName = _environment.default.APP.avlmLite ? _roadSupervisorApi.default.getRoleName() : _roadSupervisorSpecialRouteApi.default.getRoleName();
      return this.roles && (0, _hasRole.hasRole)(this.roles, roleName);
    }

  });

  _exports.default = _default;
});