define("adept-iq/pods/components/side-drawer/system-config/contract-management/component", ["exports", "ember-concurrency", "adept-iq/utils/regex-functions"], function (_exports, _emberConcurrency, _regexFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CONTRACT_MANAGEMENT_FIELDS = {
    providerName: '',
    startRouteName: '',
    endRouteName: '',
    trimSlack: true,
    adjustMins: '',
    convertToHourMinutes: '',
    pullOutMinCap: '',
    pullOutMaxCap: '',
    pullInMinCap: '',
    pullInMaxCap: ''
  };

  var _default = Ember.Component.extend({
    classNames: ['contract-management'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    providers: null,
    selectedProvider: null,
    disableBtns: null,
    contractManagement: null,
    isNewContract: false,
    routeError: false,
    minRouteLenError: false,
    disableForm: true,
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    disableSavebtn: Ember.computed('disableForm', 'contractManagement.{providerName,startRouteName,endRouteName,adjustMins,minRouteLength,pullOutMinCap,pullOutMaxCap,pullInMinCap,pullInMaxCap,convertToHourMinutes}', function () {
      const disableForm = this.get('disableForm');
      const contractManagement = this.get('contractManagement');
      const selectedRow = this.get('selectedRow');

      if (disableForm) {
        return true;
      }

      const hasValues = Object.values(contractManagement).every(input => !!input);
      return !hasValues || Ember.isPresent(selectedRow) && !this.isContractManagementChanged(contractManagement, selectedRow);
    }),

    isContractManagementChanged(contractManagement, selectedRow) {
      const fields = {};
      Object.entries(CONTRACT_MANAGEMENT_FIELDS).forEach(key => {
        fields[key[0]] = selectedRow.get(key[0]);
      });
      return JSON.stringify(contractManagement) !== JSON.stringify(fields);
    },

    disableUndobtn: Ember.computed('disableForm', 'contractManagement.{providerName,startRouteName,endRouteName,adjustMins,minRouteLength,pullOutMinCap,pullOutMaxCap,pullInMinCap,pullInMaxCap}', function () {
      const disableForm = this.get('disableForm');

      if (disableForm) {
        return true;
      }

      const hasValues = Object.values(this.get('contractManagement')).some(input => input !== '');
      return !hasValues;
    }),
    selectedRowChange: Ember.observer('selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      if (selectedRow) this.set('disableForm', true);
      this.setDefaultProperties(selectedRow);
      this.defaultSettings();
    }),

    init() {
      this._super(...arguments);

      const providers = [];
      this.get('store').findAll('provider').then(record => {
        record.forEach(val => {
          providers.push(val.get('id'));
        });
        this.set('providers', providers);
      });
      this.setDefaultProperties();
      this.defaultSettings();
    },

    setDefaultProperties(record) {
      const fields = {};
      Object.entries(CONTRACT_MANAGEMENT_FIELDS).forEach(key => {
        fields[key[0]] = record ? record.get(key[0]) : CONTRACT_MANAGEMENT_FIELDS[key[0]];
      });
      this.resetErrors();
      this.set('contractManagement', Ember.Object.create(fields));
    },

    resetErrors() {
      this.setProperties({
        'routeStartError': false,
        'minRouteLenError': false,
        'routeOverlapError': false
      });
    },

    defaultSettings() {
      const disableBtns = {
        new: false,
        edit: false,
        delete: false
      };
      this.set('disableBtns', disableBtns);
    },

    convertToMinutes(hours) {
      const fieldArray = hours.split(':');
      return parseInt(fieldArray[1], 10) + 60 * parseInt(fieldArray[0], 10);
    },

    save: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      let convertToHourMinutes = this.get('contractManagement.convertToHourMinutes');

      if (!convertToHourMinutes.includes(':')) {
        convertToHourMinutes = `00:${convertToHourMinutes}`;
      }

      this.set('contractManagement.convertToHourMinutes', convertToHourMinutes);
      const mins = this.convertToMinutes(convertToHourMinutes);
      this.set('contractManagement.minRouteLength', mins);
      const attributes = this.get('contractManagement');
      const notifications = this.get('notifications');
      let record = null;

      if (!this.get('isNewContract')) {
        record = this.get('selectedRow');
        const fields = this.get('contractManagement');
        Object.entries(fields).forEach(([key, val]) => {
          record.set(key, val);
        });
      } else {
        record = store.createRecord('trim-slack-contract-management', attributes);
      }

      try {
        yield record.save();
        notifications.success('Record successfully saved');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        notifications.warning('Record failed to save');
      }
    }).drop(),
    delete: (0, _emberConcurrency.task)(function* () {
      const record = this.get('selectedRow');
      const notifications = this.get('notifications');

      try {
        record.deleteRecord();
        yield record.save();
        notifications.success('Record successfully deleted.');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (error) {
        notifications.warning('Record failed to delete.');
      }
    }).drop(),

    validateRecords() {
      const store = this.get('store');
      const record = this.get('contractManagement') || [];
      const convertToHourMinutes = this.get('contractManagement.convertToHourMinutes');
      const startRouteName = parseInt(record.startRouteName, 10);
      const endRouteName = parseInt(record.endRouteName, 10);

      const isHHMMformat = _regexFunctions.REGEX_HHMM.test(convertToHourMinutes);

      const isValidMinRouteLength = this.validateMinRouteLengthField(convertToHourMinutes);
      const errors = [];
      const id = this.get('selectedRow.id') || '';

      if (startRouteName > endRouteName) {
        errors.pushObject({
          'validation': 'Start Route must be less than End Route.'
        });
        this.set('routeStartError', true);
      } else {
        let data = store.peekAll('trim-slack-contract-management');

        if (!this.get('isNewContract')) {
          data = data.filter(item => item.get('id') !== id);
        }

        data.forEach(contract => {
          if (startRouteName >= contract.get('startRouteName') && startRouteName <= contract.get('endRouteName') || endRouteName >= contract.get('startRouteName') && endRouteName <= contract.get('endRouteName')) {
            errors.pushObject({
              'validation': 'Route Number range cannot overlap an existing Route Number range.'
            });
            this.set('routeOverlapError', true);
            return false;
          }
        });
      }

      if (!isHHMMformat || !isValidMinRouteLength) {
        errors.pushObject({
          'validation': 'Min Route Length value must be entered in hh:mm format'
        });
        this.set('minRouteLenError', true);
      }

      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'System Configuration',
        hasoverlay: true,
        errors,
        primaryActionText: 'OK',
        primaryAction: () => {
          return tooltip.reset();
        }
      });
      return !errors.length;
    },

    /**
     * Input Value(10:11)
     * @param value
     * @returns {boolean}
     */
    validateMinRouteLengthField(value) {
      let valid = true;
      const splits = value.split(':');

      if (splits.length !== 2) {
        valid = false;
      }

      splits.forEach(str => {
        if (str.length !== 2) {
          valid = false;
        }
      });
      return valid;
    },

    actions: {
      onProviderChange(item) {
        this.set('contractManagement.providerName', item);
      },

      onRouteLengthChange(contractManagement, maskObj) {
        const value = maskObj.masked;
        contractManagement.set('convertToHourMinutes', value);
      },

      newContract() {
        this.setDefaultProperties();
        this.set('isNewContract', true);
        this.set('disableForm', false);
        this.get('tableRef').onUncheckAll();
        this.set('disableBtns.delete', true);
        this.set('disableBtns.edit', true);
        this.set('disableBtns.new', true);
      },

      editContract() {
        const selectedRow = this.get('selectedRow');
        this.setDefaultProperties(selectedRow);
        this.set('isNewContract', false);
        this.set('disableForm', false);
        this.set('disableBtns.delete', true);
        this.set('disableBtns.edit', true);
        this.set('disableBtns.new', true);
      },

      deleteContract() {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          tip: 'Are you sure you want to delete this record?',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('delete').perform().then(() => {
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',

          secondaryAction() {
            return tooltip.reset();
          }

        });
        this.resetErrors();
      },

      saveContract() {
        this.resetErrors();

        if (this.validateRecords()) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'System Configuration',
            hasoverlay: true,
            tip: 'Are you sure you want to save these changes?',
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return this.get('save').perform().then(() => {
                tooltip.reset();
                this.set('disableForm', true);
                this.defaultSettings();
              });
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              tooltip.reset();
            }

          });
        }
      },

      undoContract() {
        this.resetErrors();

        if (this.get('isNewContract')) {
          this.setDefaultProperties();
        } else {
          const selectedRow = this.get('selectedRow');
          this.setDefaultProperties(selectedRow);
        }
      }

    }
  });

  _exports.default = _default;
});