define("adept-iq/pods/components/iq-widgets/subscription-form/component", ["exports", "moment", "adept-iq/config/mapped-permIds", "adept-iq/pods/components/generic-widgets/base-widget/component", "lodash"], function (_exports, _moment, _mappedPermIds, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PICK = 'pick';
  const DROP = 'appt';
  let CUT_OFF_TIME_FOR_SAMEDAY = 0;

  const convertDate = str => {
    var date = new Date(str);
    var mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  var _default = _component.default.extend({
    classNames: ['new-booking-form-side-drawer'],
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    booking: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    subscriptionService: Ember.inject.service('subscription'),
    isProgressShown: false,
    riderToBook: null,
    serviceNeeds: null,
    subscription: null,
    isLoadAndUnLoadTimeValid: true,
    isLoadAndUnLoadTimeNumber: false,
    travelNeedCollapseState: 'expanded',
    // Checking the leg date eligibility
    selectedLegDate: null,
    isEligiblitiyCheck: false,
    isOpenErrorModal: Ember.computed.alias('booking.isOpenErrorModal'),
    errorMessage: Ember.computed.alias('booking.errorMessage'),
    canSave: Ember.computed.alias('booking.canSave'),
    isEditMode: false,
    passengerInfo: null,
    legTravelNeeds: null,
    legsInBooking: Ember.computed.alias('activeLegsInBooking'),
    pickupPostalAddress: null,
    dropoffPostalAddress: null,
    // eslint-disable-next-line ember/use-brace-expansion
    maxLegOrdinal: Ember.computed('booking.activeBooking.legs.[]', 'booking.activeBooking.legs', function () {
      const legOrdinals = this.get('booking.activeBooking.legs').map(leg => {
        return leg.get('segments.firstObject.legOrdinal');
      });
      return Math.max(...legOrdinals);
    }),
    riderFullNames: Ember.computed('booking.selectedRiders.[]', function () {
      return this.get('booking.selectedRiders').map(rider => rider.get('fullName')).join(',');
    }),
    noOfActiveRiders: Ember.computed('booking.selectedRiders.[]', function () {
      return this.get('booking.selectedRiders').length;
    }),
    passengerListTitle: Ember.computed('noOfActiveRiders', function () {
      return `Passenger List (${this.get('noOfActiveRiders')})`;
    }),
    bookingPanelTitle: Ember.computed('isEditMode', function () {
      return this.get('isEditMode') ? 'Edit Subscription' : 'Create Subscription';
    }),
    activeLegsInBooking: Ember.computed('booking.activeBooking.legs.[]', function () {
      return this.get('booking.activeBooking.legs');
    }),
    activeLegsInBookingForMapObserver: Ember.observer('booking.activeBooking.legs.[]', function () {
      this.booking.setActiveLegsInBooking(this.get('activeLegsInBooking'));
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    tripLegsInBooking: Ember.computed('booking.activeBooking.legs.[]', 'booking.activeBooking.legs', function () {
      const tripLegs = this.get('booking.activeBooking.legs').filterBy('purpose', 'trip');
      return tripLegs.sortBy('legOrdinal');
    }),
    isSubscription: Ember.computed('subscription.recurrencePatterns.firstObject.recurring', function () {
      return this.get('subscription.recurrencePatterns.firstObject.recurring');
    }),
    addressesOfRiderToBook: Ember.computed('riderToBook', function () {
      return this.get('riderToBook.places').map(place => {
        if (Ember.typeOf(place) === 'object') {
          return this.store.createRecord('address', {
            freeformAddress: '',
            // we give this empty because nyct does give this field,full address in address model checks for this
            streetNumber: place.address.streetNumber,
            streetAddress: place.address.streetAddress,
            notes: place.address.notes,
            premise: place.address.premise,
            region: place.address.region,
            locality: place.address.locality,
            subLocality: place.address.locality,
            postalCode: place.address.postalCode,
            lat: place.address.lat,
            lng: place.address.lng,
            zName: place.address.zName
          });
        }

        return this.store.createRecord('address', {
          freeformAddress: place.get('address.freeformAddress') || '',
          streetNumber: place.get('address.streetNumber'),
          streetAddress: place.get('address.streetAddress'),
          notes: place.get('address.notes'),
          premise: place.get('address.premise'),
          region: place.get('address.region'),
          locality: place.get('address.locality'),
          subLocality: place.get('address.subLocality'),
          postalCode: place.get('address.postalCode'),
          lat: place.get('location.lat'),
          lng: place.get('location.lng'),
          zName: place.get('address.zName')
        });
      });
    }),

    init() {
      this._super(...arguments);

      this.configureAddTripPermission();
      const records = this.get('editableRecords');
      this.set('isEditMode', records[0]);
      this.set('subscription', records[2].get('subscription'));
      this.activeLegsInBookingForMapObserver();

      if (this.get('isEditMode')) {
        this.initEditMode();
      } else {
        this.initAddMode();
      }

      this.onNumberChange = this.onNumberChange.bind(this);
      this.syncPickAndDrop = this.syncPickAndDrop.bind(this);
      this.onFirstLegDateChange = this.onFirstLegDateChange.bind(this);
      const cutOffTime4SameDay = this.store.peekRecord('cs-config-item', 'config-Book_Trips/cutOffTime4SameDay'); // converting minutes to hour
      // eslint-disable-next-line radix

      CUT_OFF_TIME_FOR_SAMEDAY = parseInt(cutOffTime4SameDay.get('value')) / 60;
      this.combineServiceNeedsForGroupBooking();
      this.set('booking.previousSubscriptionStartDate', this.get('subscription.startDate'));
      this.set('booking.previousSubscriptionEndDate', this.get('subscription.endDate'));
      this.set('booking.previousSubscriptionSuspendStartDate', this.get('subscription.exclusions.firstObject.startDate'));
      this.set('booking.previousSubscriptionSuspendEndDate', this.get('subscription.exclusions.firstObject.endDate'));
    },

    willDestroyElement() {
      this.set('booking.selectedCompanionCount', 0);
      this.set('booking.isOpenErrorModal', false);
      this.set('booking.previousSubscriptionStartDate', null);
      this.set('booking.previousSubscriptionEndDate', null);
      this.set('booking.previousSubscriptionSuspendStartDate', null);
      this.set('booking.previousSubscriptionSuspendEndDate', null);

      this._super(...arguments);
    },

    selectedCompanionCount: Ember.computed.alias('booking.selectedCompanionCount'),

    initEditMode() {
      const record = this.get('editableRecords');
      const legTravelNeeds = this.duplicateWideSeats(this.get('subscription.subscriptionTravelNeeds'));
      this.set('riderToBook', record[1]);
      this.set('legTravelNeeds', legTravelNeeds);
    },

    initAddMode() {
      const record = this.get('editableRecords');
      const travelNeeds = record[2].legs.firstObject.legTravelNeeds;
      const store = this.get('store');
      const subscriptionTravelneeds = travelNeeds.map(travelNeed => {
        return store.createRecord('subscription-travel-need', {
          count: travelNeed.get('count'),
          travelNeedType: travelNeed.get('travelNeedType'),
          passengerType: travelNeed.get('passengerType')
        });
      });
      this.set('riderToBook', record[1]);
      this.set('legTravelNeeds', subscriptionTravelneeds);
    },

    duplicateWideSeats(legTravelNeeds) {
      const configItems = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-travel_need_types';
      });
      const bigSeatsTN = configItems.toArray().filter(tr => parseInt(tr.get('value.Vehicle Capacity Count'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('value.Name').toUpperCase());
      const bigSeatTravelneeds = legTravelNeeds.filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      bigSeatTravelneeds.forEach(tr => {
        const wideTravelNeed = bigSeatsTN.find(travelNeed => {
          return travelNeed.get('travelNeedTypeNameUppercase') === tr.get('value.Name');
        });
        const configCount = parseInt(wideTravelNeed.get('value.Vehicle Capacity Count'), 10);

        if (tr.count > configCount) {
          const count = tr.count / configCount;

          for (let i = 1; i < count; i++) {
            const newLegTravelNeed = this.store.createRecord('subscription-travel-need', {
              count: configCount,
              travelNeedType: tr.get('travelNeedType'),
              passengerType: tr.get('passengerType')
            });
            legTravelNeeds.pushObject(newLegTravelNeed);
          }
        }
      });
      return legTravelNeeds;
    },

    onNoteChange(value) {
      this.set('riderToBook.notes', value);
    },

    checkIfFullAddressPresent(address) {
      return !Ember.isEmpty(address.get('fullAddress'));
    },

    syncPickAndDrop() {
      const legsInBooking = this.get('tripLegsInBooking').toArray();
      this.syncLegsInBooking(legsInBooking); // last trip leg's drop should first return trip leg's pick

      if (this.get('returnTripLegsInBooking.length')) {
        const lastTripLeg = this.get('tripLegsInBooking.lastObject');
        const firstReturnLeg = this.get('returnTripLegsInBooking.firstObject');
        const prevDrop = lastTripLeg.get('segments.firstObject.drop.place');
        const currPick = firstReturnLeg.get('segments.firstObject.pick');
        const previousDrop = this.addressRecordCreation(prevDrop);
        currPick.set('place', previousDrop);
      }

      const returnLegsInBooking = this.get('returnTripLegsInBooking').toArray();
      this.syncLegsInBooking(returnLegsInBooking);
      const activelegsInBooking = this.get('activeLegsInBooking');
      this.booking.setActiveLegsInBooking(activelegsInBooking);
    },

    syncLegsInBooking(legsInBooking) {
      if (legsInBooking.length <= 1) {
        return;
      }

      for (let i = 1; i < legsInBooking.length; i++) {
        const previousLeg = legsInBooking[i - 1];
        const currentLeg = legsInBooking[i];
        const prevDrop = previousLeg.get('segments.firstObject.drop.place');
        const currPick = currentLeg.get('segments.firstObject.pick');
        const pickPlace = this.addressRecordCreation(prevDrop);
        currPick.set('place', pickPlace);
      }
    },

    /**
     * address record creation
     */
    addressRecordCreation(prevDrop) {
      const pickAddress = this.store.createRecord('address', {
        freeformAddress: prevDrop.get('address.freeformAddress') || '',
        streetNumber: prevDrop.get('address.streetNumber'),
        streetAddress: prevDrop.get('address.streetAddress'),
        notes: prevDrop.get('address.notes'),
        premise: prevDrop.get('address.premise'),
        region: prevDrop.get('address.region'),
        locality: prevDrop.get('address.locality'),
        subLocality: prevDrop.get('address.subLocality'),
        postalCode: prevDrop.get('address.postalCode'),
        zName: prevDrop.get('address.zName')
      });
      const pickLocation = this.store.createRecord('location', {
        lat: prevDrop.get('location.lat'),
        lng: prevDrop.get('location.lng'),
        geoNode: prevDrop.get('location.geoNode')
      });
      const pickPlace = this.store.createRecord('place', {
        address: pickAddress,
        location: pickLocation
      });
      return pickPlace;
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('.edit-widget-modal').width('400');
      Ember.$('.booking-map-container').css({
        'width': 'calc(95% - ' + Ember.$('.edit-widget-modal').width() + 'px)'
      }).css({
        'height': 'calc(100% - 20%)',
        'left': Ember.$('.edit-widget-modal').width() + 'px)'
      });
      this.set('travelNeedCollapseState', 'collapsed');
    },

    getNewLegDate(prevLeg) {
      const bufferMinutes = 2;
      const rTime = prevLeg.get('requestTime');
      const currentDateAndTime = (0, _moment.default)(rTime);
      const newMomentDate = (0, _moment.default)();
      const currentYear = currentDateAndTime.year();
      const currentMonth = currentDateAndTime.month();
      const currentDay = currentDateAndTime.date();
      const currentHour = currentDateAndTime.hour();
      const currentMinutes = currentDateAndTime.minutes();
      const currentSeconds = currentDateAndTime.seconds();
      newMomentDate.year(currentYear);
      newMomentDate.month(currentMonth);
      newMomentDate.date(currentDay);
      newMomentDate.hour(currentHour);
      newMomentDate.minutes(currentMinutes + bufferMinutes);
      newMomentDate.seconds(currentSeconds);
      const selectedDateObjectWithTime = newMomentDate.toDate();
      return selectedDateObjectWithTime;
    },

    configureAddTripPermission() {
      const record = this.get('editableRecords')[1];
      const sameDayTripPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createSameDay, null);
      const FutureDayTripPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createFuture, null);
      let maxDate = null;
      let minDate = null;

      if (sameDayTripPermGranted && !FutureDayTripPermGranted) {
        maxDate = (0, _moment.default)().endOf('day');
      }

      if (FutureDayTripPermGranted && !sameDayTripPermGranted) {
        minDate = (0, _moment.default)().startOf('day').add(1, 'day').toDate();
      }

      record.set('tripMaxDate', maxDate);
      record.set('tripMinDate', minDate);
    },

    createLegSegmentRecord(isReturn) {
      const store = this.get('store');
      const serviceWindow = store.peekRecord('service-window', 'GENERAL');
      const fareType = store.peekAll('fare-type').firstObject;
      let date;

      if (!isReturn || !this.get('returnTripLegsInBooking.lastObject.requestTime')) {
        const legToUse = this.get('tripLegsInBooking.lastObject');
        date = this.getNewLegDate(legToUse);
      } else {
        const legToUse = this.get('returnTripLegsInBooking.lastObject');
        date = this.getNewLegDate(legToUse);
      }

      const pickAddress = store.createRecord('address');
      const pickLocation = store.createRecord('location');
      const pickPlace = store.createRecord('place', {
        address: pickAddress,
        location: pickLocation
      });
      const dropAddress = store.createRecord('address');
      const dropLocation = store.createRecord('location');
      const dropPlace = store.createRecord('place', {
        address: dropAddress,
        location: dropLocation
      });
      const leg = store.createRecord('leg', {
        requestTime: date,
        anchor: 'pick',
        purpose: isReturn ? 'return' : 'trip',
        notes: '',
        serviceWindow
      });
      const legOrdinalForNewLeg = this.get('maxLegOrdinal') + 1;
      const segment = store.createRecord('segment', {
        promiseTime: date,
        anchor: 'Pick',
        fare: this.get('booking.activeBooking.legs.firstObject.segments.firstObject.fare'),
        travelMode: 'paratransit',
        segmentType: 2,
        noSharing: 0,
        legOrdinal: legOrdinalForNewLeg,
        pick: store.createRecord('segment-stop', {
          type: 'pick',
          notes: '',
          plannedTime: date,
          onboardCount: 1,
          place: pickPlace
        }),
        drop: store.createRecord('segment-stop', {
          type: 'drop',
          notes: '',
          plannedTime: date,
          onboardCount: 1,
          place: dropPlace
        }),
        fareType: fareType,
        leg: leg
      });
      leg.set('segments', [segment]);
      return leg;
    },

    getLegTypeField(isReturn) {
      return isReturn ? 'returnTripLegsInBooking' : 'tripLegsInBooking';
    },

    onNumberChange(value) {
      this.set('riderRecord.notificationPhoneNumbers.firstObject.areaCode', value[0].trim());
      this.set('riderRecord.notificationPhoneNumbers.firstObject.phoneNumber', value[1].trim());
    },

    // we are just checking using postalCode because both nyct and tomtom returns that consistently
    validateIfPickAndDropArePresentInAllLegs() {
      let allLegsHavePickAndDrop = true;
      this.get('legsInBooking').forEach(leg => {
        if (leg.get('segments.firstObject.pick.place.address.postalCode') && leg.get('segments.firstObject.drop.place.address.postalCode')) {
          allLegsHavePickAndDrop = true;
        } else {
          allLegsHavePickAndDrop = false;

          if (leg.get('segments.firstObject.pick.place.address.postalCode')) {
            const pickPostalCode = leg.get('segments.firstObject.pick.place.address.postalCode');
            this.set('pickupPostalAddress', pickPostalCode);
          }

          if (leg.get('segments.firstObject.drop.place.address.postalCode')) {
            const dropPostalCode = leg.get('segments.firstObject.drop.place.address.postalCode');
            this.set('dropoffPostalAddress', dropPostalCode);
          }
        }
      });
      return allLegsHavePickAndDrop;
    },

    validateFromToDatesNotToBePastDates(startDate, endDate, type) {
      const currentDate = (0, _moment.default)().startOf('day');
      const prevStartDate = type === 'Subscription' ? this.get('booking.previousSubscriptionStartDate') : this.get('booking.previousSubscriptionSuspendStartDate');
      const prevEndDate = type === 'Subscription' ? this.get('booking.previousSubscriptionEndDate') : this.get('booking.previousSubscriptionSuspendEndDate'); // prevent user to select previous date on editMode only allow current date and after for subscription

      if (!this.get('booking').validateDateNotToBePastDate(startDate, currentDate, prevStartDate, type, 'From')) {
        return false;
      }

      if (!this.get('booking').validateDateNotToBePastDate(endDate, currentDate, prevEndDate, type, 'To')) {
        return false;
      }

      return true;
    },

    validateFromToDates(startDate, endDate, type) {
      if (Ember.isEmpty(startDate) || Ember.isNone(startDate) || Ember.isEmpty(endDate) || Ember.isNone(endDate)) {
        this.get('booking').showErrorMessage(`Please select ${type} From date and To date.`);
        return false;
      }

      if (!(0, _moment.default)(convertDate(startDate)).isSameOrBefore((0, _moment.default)(convertDate(endDate)))) {
        this.get('booking').showErrorMessage(`${type} From date must be same or before To date.`);
        return false;
      }

      return true;
    },

    validateForAtleastOneDaySelected() {
      const recurrencePattern = this.get('subscription.recurrencePatterns.firstObject');
      return recurrencePattern.get('sunday') || recurrencePattern.get('monday') || recurrencePattern.get('tuesday') || recurrencePattern.get('wednesday') || recurrencePattern.get('thursday') || recurrencePattern.get('friday') || recurrencePattern.get('saturday') || recurrencePattern.get('holiday');
    },

    validateCutOffInAllSubsequentLegs() {
      const legsInBooking = this.get('activeLegsInBooking');

      for (let i = 0; i < legsInBooking.length; i++) {
        const leg = this.get('legsInBooking').objectAt(i);

        if (!this.validateCutOff(leg)) {
          return true;
        }
      }
    },

    validateSeatAndPassengerCount() {
      const ccount = this.get('selectedCompanionCount') ? this.get('selectedCompanionCount') : 0;
      const pcaTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'PCA';
      });
      const pcaRiders = [];
      this.get('booking.selectedRiders').forEach(rider => {
        const riderTravelNeeds = rider.get('travelNeeds');
        riderTravelNeeds.forEach(riderTravelNeed => {
          if (riderTravelNeed.type === 'PCA') {
            pcaRiders.push(rider);
          }
        });
      });
      const pcaCount = pcaTravelNeed ? pcaRiders.length : 0;
      const noOfRiders = this.get('booking').noOfSelectedRiders;
      const noOfPassengers = noOfRiders + pcaCount + parseInt(ccount, 10);
      const configItems = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-travel_need_types';
      });
      const bigSeatsTN = configItems.toArray().filter(tr => parseInt(tr.get('value.Vehicle Capacity Count'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('value.Name').toUpperCase());
      const bigSeatTravelneeds = this.get('legTravelNeeds').filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      const wheelchairTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'WHEELCHAIR';
      });
      const ambulatoryTravelNeed = this.get('legTravelNeeds').find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'AMBULATORY';
      });
      const wheelchaircount = wheelchairTravelNeed ? parseInt(wheelchairTravelNeed.count, 10) : 0;
      const ambulatoryCount = ambulatoryTravelNeed ? parseInt(ambulatoryTravelNeed.count, 10) : 0;
      const noOfSeats = ambulatoryCount + wheelchaircount + bigSeatTravelneeds.length;

      if (noOfPassengers !== noOfSeats) {
        return false;
      }

      return true;
    },

    validateEmptyTravelNeeds() {
      return this.get('legTravelNeeds').toArray().some(tr => !tr.get('travelNeedTypeNameUppercase'));
    },

    validateRecords() {
      let valid = true;
      this.set('editModal.errors', []);
      const firstLeg = this.get('legsInBooking.firstObject');
      const pickAddress = firstLeg.get('segments.firstObject.pick.place.address.postalCode');
      const dropAddress = firstLeg.get('segments.firstObject.drop.place.address.postalCode');
      const requestTime = firstLeg.get('requestTime');

      if (this.get('isSubscription')) {
        const startDate = this.get('subscription.startDate');
        const endDate = this.get('subscription.endDate');

        if (!this.validateFromToDates(startDate, endDate, 'Subscription')) {
          valid = false;
        }

        if (!this.validateFromToDatesNotToBePastDates(startDate, endDate, 'Subscription')) {
          valid = false;
        }

        if (!this.validateForAtleastOneDaySelected()) {
          this.get('booking').showErrorMessage('Please select at least one day from weekdays.');
          valid = false;
        }
      }

      if (this.get('subscription.exclusions.firstObject.suspend')) {
        const startDate = this.get('subscription.exclusions.firstObject.startDate');
        const endDate = this.get('subscription.exclusions.firstObject.endDate');

        if (!this.validateFromToDates(startDate, endDate, 'Suspend')) {
          valid = false;
        }

        if (!this.validateFromToDatesNotToBePastDates(startDate, endDate, 'Suspend')) {
          valid = false;
        }
      }

      if (!this.get('isLoadAndUnLoadTimeValid')) {
        this.get('booking').showErrorMessage('Load/Unload time value must be 15 min or less.');
        valid = false;
      }

      if (!this.get('isLoadAndUnLoadTimeNumber')) {
        this.get('booking').showErrorMessage('Please enter valid numbers for load/unload time.');
        valid = false;
      }

      if (Ember.isEmpty(requestTime) || Ember.isNone(requestTime)) {
        this.get('booking').showErrorMessage('Request time is missing. Please enter a valid time.');
        valid = false;
      }

      if (Ember.isEmpty(pickAddress) || Ember.isNone(pickAddress)) {
        this.get('booking').showErrorMessage('Unknown Address. Please enter a valid pickup address.');
        valid = false;
      }

      if (Ember.isEmpty(dropAddress) || Ember.isNone(dropAddress)) {
        this.get('booking').showErrorMessage('Unknown Address. Please enter a valid dropoff address.');
        valid = false;
      }

      if (!this.validateIfPickAndDropArePresentInAllLegs()) {
        if (!this.get('pickupPostalAddress') && !this.get('dropoffPostalAddress')) {
          this.get('booking').showErrorMessage('Unknown Address. Please enter a valid address.');
        } else {
          if (!this.get('pickupPostalAddress')) {
            this.get('booking').showErrorMessage('Unknown Address. Please enter a valid pickup address.');
          }

          if (!this.get('dropoffPostalAddress')) {
            this.get('booking').showErrorMessage('Unknown Address. Please enter a valid dropoff address.');
          }
        }

        valid = false;
      }

      if (!this.validateLegRequestTimeAfterPrevLegRequestTime()) {
        this.get('booking').showErrorMessage('The leg trip time must come after the previous trip request time.');
        valid = false;
      }

      if (this.validateCutOffInAllSubsequentLegs()) {
        this.get('booking').showErrorMessage(`Same day booking must be at least ${CUT_OFF_TIME_FOR_SAMEDAY * 60} mins in advance. Please select a different request time.`);
        valid = false;
      }

      if (!this.validateEligibility(firstLeg)) {
        this.set('selectedLegDate', (0, _moment.default)(firstLeg.get('requestTime')).format('M.D.Y'));
        this.get('booking').showErrorMessage(`${this.get('riderToBook.firstName')} ${this.get('riderToBook.lastName')}
      has no client eligibility records for the date \n  ${this.get('selectedLegDate')} . Please select another date.`);
        valid = false;
      }

      if (!this.validateSeatAndPassengerCount()) {
        this.get('booking').showErrorMessage('The number of seats used for the booking must match the number of passengers.');
        valid = false;
      }

      if (this.validateEmptyTravelNeeds()) {
        this.get('booking').showErrorMessage('Please remove empty Travel needs to proceed.');
        valid = false;
      }

      return valid;
    },

    // when the date of the first leg changes . all the date should change
    onFirstLegDateChange() {
      const rTime = this.get('legsInBooking.firstObject.requestTime');
      const firstLegDateAndTime = (0, _moment.default)(rTime);
      const firstLegYear = firstLegDateAndTime.year();
      const firstLegMonth = firstLegDateAndTime.month();
      const firstLegDay = firstLegDateAndTime.date();

      for (let i = 1; i < this.get('legsInBooking').length; i++) {
        const leg = this.get('legsInBooking').objectAt(i);
        const legDateAndTime = (0, _moment.default)(leg.get('requestTime'));
        legDateAndTime.year(firstLegYear);
        legDateAndTime.month(firstLegMonth);
        legDateAndTime.date(firstLegDay);
        const legDateObjectWithTime = legDateAndTime.toDate();
        leg.set('requestTime', legDateObjectWithTime);
      }
    },

    validateLegTimeBasedOnAnchor(leg, isReturn) {
      const bookingFieldType = this.getLegTypeField(isReturn);
      const legsInPanel = this.get(bookingFieldType);
      let currentLeg = legsInPanel.objectAt(0);
      const FORMAT = 'mm-dd-yyyy';

      for (let i = 0; i < legsInPanel.length - 1; i++) {
        const legToCompare = legsInPanel.objectAt(i + 1);
        const currentLegRequestTime = (0, _moment.default)(currentLeg.get('requestTime'));
        const legToCompareRequestTime = (0, _moment.default)(legToCompare.get('requestTime'));

        if (legToCompare.get('anchor').toLowerCase() === PICK) {
          const currentFormattedTime = currentLegRequestTime.format(FORMAT);
          const compareFormattedTime = legToCompareRequestTime.format(FORMAT);

          if (currentFormattedTime !== compareFormattedTime) {
            return false;
          }
        }

        if (legToCompare.get('anchor').toLowerCase() === DROP) {
          const dayAfterTwo = currentLegRequestTime.clone().add(1, 'days');

          if (!currentLegRequestTime.isBetween(currentLegRequestTime, dayAfterTwo)) {
            return false;
          }
        }

        currentLeg = legToCompare;
      }

      return true;
    },

    validateEligibility(leg) {
      const requestTimeOfLeg = leg.get('requestTime');
      const momentedRequestTime = (0, _moment.default)(requestTimeOfLeg);
      const eligibleEndDate = (0, _moment.default)(this.get('riderToBook').get('eligibilityEnd'), 'DD/MM/YYYY').add(1, 'days');
      const eligibleStartDate = (0, _moment.default)(this.get('riderToBook').get('eligibilityStart'), 'DD/MM/YYYY'); // Checking the rider eligibility

      if (!(momentedRequestTime.isSameOrAfter(eligibleStartDate) && momentedRequestTime.isSameOrBefore(eligibleEndDate))) {
        return false;
      }

      return true;
    },

    validateCutOff(leg) {
      const requestTimeOfLeg = leg.get('requestTime');
      const momentedRequestTime = (0, _moment.default)(requestTimeOfLeg);
      const anchor = leg.get('anchor').toLowerCase();

      if (momentedRequestTime.isSame((0, _moment.default)(), 'day') && anchor === PICK) {
        const today = new Date();
        const todayWithOneHourExtended = new Date(today);
        todayWithOneHourExtended.setHours(todayWithOneHourExtended.getHours() + CUT_OFF_TIME_FOR_SAMEDAY);

        if (momentedRequestTime.isBefore(todayWithOneHourExtended) && CUT_OFF_TIME_FOR_SAMEDAY !== 0) {
          return false;
        }
      }

      return true;
    },

    validateLegRequestTimeAfterPrevLegRequestTime() {
      const legsInBooking = this.get('tripLegsInBooking').toArray();

      if (legsInBooking.length <= 1) {
        return true;
      }

      for (let i = 1; i < legsInBooking.length; i++) {
        const prevLeg = legsInBooking[i - 1];
        const prevlegRequestime = prevLeg.get('requestTime');
        const curLeg = legsInBooking[i];
        const curLegRequestTime = curLeg.get('requestTime'); //Compares each leg requestTime with previous leg requestTime

        if ((0, _moment.default)(curLegRequestTime) <= (0, _moment.default)(prevlegRequestime)) {
          return false;
        }
      }

      return true;
    },

    checkIfLegIsFirstLeg(leg) {
      const legsInBooking = this.get('activeLegsInBooking');
      const indexOfLeg = legsInBooking.indexOf(leg);

      if (indexOfLeg > 0) {
        return false;
      }

      return true;
    },

    combineServiceNeedsForGroupBooking() {
      const serviceNeeds = this.get('booking.selectedRiders').map(rider => rider.serviceNeeds);
      const uniqServiceNeeds = (0, _lodash.uniq)((0, _lodash.flattenDeep)(serviceNeeds));
      this.set('serviceNeeds', uniqServiceNeeds);
    },

    async saveSubscription() {
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        await this.get('subscriptionService').saveSubscription(this.get('subscription'), this.get('isEditMode'), this.get('legTravelNeeds'));
        this.get('notifications').success('Subscription was saved');
        const [bookings, trips] = await this.get('apiContext').loadBookingsForSelectedExternalRiders();
        const subscriptions = await this.get('apiContext').loadSubscriptionsForSelectedExternalRiders();
        this.set('booking.currentBookings', bookings);
        this.set('booking.currentTrips', trips);
        this.set('booking.currentSubscriptions', subscriptions);
        this.get('editModal').close();
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      } catch (err) {
        const message = this.get('isEditMode') ? `Failed to update Subscription ${err.message}` : `Failed to create Subscription  ${err.message}`;
        this.get('notifications').warning(message);
        this.get('editModal').close();
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    actions: {
      onUndoClick() {
        const lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          const records = this.get('editableRecords') || [];
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onSaveClick() {
        this.set('canSave', true);

        if (this.validateRecords()) {
          this.saveSubscription();
        }
      },

      onXButtonClick() {
        this.toggleProperty('isProgressShown');
      },

      onProgressNoClick() {
        this.toggleProperty('isProgressShown');
        this.get('editModal').close();
      },

      onCloseClick() {
        this.toggleProperty('isProgressShown');
      },

      onShrinkClick() {
        this.onShrinkClick();
      },

      onApplyClick() {
        if (this.validateRecords()) {
          Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
          this.get('geocode').activateGeocode(false);
          this.set('addressRecord', null);
          this.get('service').apply();
        }
      },

      validateRequestDateTime(leg) {
        this.set('canSave', true);
        this.set('errorMessage', '');

        if (!this.validateEligibility(leg)) {
          this.set('selectedLegDate', (0, _moment.default)(leg.get('requestTime')).format('M.D.Y'));
          this.get('booking').showErrorMessage(`${this.get('riderToBook.firstName')} ${this.get('riderToBook.lastName')}
        has no client eligibility records for the date \n  ${this.get('selectedLegDate')} . Please select another date.`);
          return;
        }

        if (!this.validateCutOff(leg)) {
          this.get('booking').showErrorMessage(`Same day booking must be at least ${CUT_OFF_TIME_FOR_SAMEDAY * 60} mins in advance. Please select a different request time.`);
          return;
        }

        if (!this.validateLegRequestTimeAfterPrevLegRequestTime()) {
          this.get('booking').showErrorMessage('The leg trip time must come after the previous trip request time.');
          return;
        }
      },

      async onYesButtonClick() {
        if (this.validateRecords()) {
          this.saveSubscription();
        }
      },

      onWarningModalClose() {
        this.set('canSave', true);
        this.set('isOpenErrorModal', false);
      },

      setLoadAndUnLoadTimeFlag(isLoadAndUnLoadTimeValid) {
        this.set('isLoadAndUnLoadTimeValid', isLoadAndUnLoadTimeValid);
      },

      setIsLoadAndUnLoadTimeNumberFlag(isLoadAndUnLoadTimeNumber) {
        this.set('isLoadAndUnLoadTimeNumber', isLoadAndUnLoadTimeNumber);
      }

    }
  });

  _exports.default = _default;
});