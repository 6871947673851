define("adept-iq/pods/components/side-drawer/reconcile-add-break/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FLOATING_BREAK_TYPE = 'Floating';
  const FIXED_BREAK_TYPE = 'Fixed';

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    // Reset map and address picker
    canResetAddress: Ember.computed.alias('workspace.activeContext.topActiveContext.resetLocation'),
    trip: Ember.computed.readOnly('workspace.topOptions.trip'),
    routeDetails: Ember.computed.readOnly('workspace.topOptions.route'),
    tripDetails: Ember.computed.alias('reconcile.reconcileTrip'),
    canUndo: Ember.computed.or('selectedBreakType', 'selectedDateTimeRage.startTime', 'breakDuration', 'breakNote', 'selectedLocation', 'breakEndTime'),
    canSave: Ember.computed.and('selectedBreakType', 'selectedDateTimeRage.startTime', 'breakDuration'),
    tripDate: Ember.computed.alias('reconcile.reconcileDate'),
    callBack: Ember.computed.readOnly('workspace.topOptions.action'),
    disableAddress: Ember.computed('selectedBreakType', function () {
      if (this.selectedBreakType === FLOATING_BREAK_TYPE) {
        return true;
      }

      return false;
    }),
    breakTypeOptions: Ember.computed('selectedBreakType', function () {
      const breakTypes = this.store.peekAll('cs-config-item').filter(message => {
        return message.category === 'config-System_Configuration-break_types';
      });
      return breakTypes.map(reason => reason.displayName);
    }),
    plannedStartTime: Ember.computed('routeDetails.plannedStartTime', function () {
      const plannedStartTime = this.get('routeDetails.plannedStartTime');
      return plannedStartTime ? (0, _moment.default)(plannedStartTime).format('hh:mm A') : '';
    }),
    plannedEndTime: Ember.computed('routeDetails.plannedEndTime', function () {
      const plannedEndTime = this.get('routeDetails.plannedEndTime');
      return plannedEndTime ? (0, _moment.default)(plannedEndTime).format('hh:mm A') : '';
    }),
    actualStartTime: Ember.computed('routeDetails.actualStartTime', function () {
      const actualStartTime = this.get('routeDetails.actualStartTime');
      return actualStartTime ? (0, _moment.default)(actualStartTime).format('hh:mm A') : '';
    }),
    actualEndTime: Ember.computed('routeDetails.actualEndTime', function () {
      const actualEndTime = this.get('routeDetails.actualEndTime');
      return actualEndTime ? (0, _moment.default)(actualEndTime).format('hh:mm A') : '';
    }),

    init() {
      Ember.set(this, 'selectedDateTimeRage', {
        startTime: null,
        startofday: (0, _moment.default)(this.reconcileTripDate).startOf('day').toISOString()
      });

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('.side-drawer-modal').width('470px');
      this.get('systemConfig').set('showRightPanel', true);
    },

    saveDriverBreakTask: (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const reconcileStopAdapter = store.adapterFor('reconcile-stop');
        const date = (0, _moment.default)(this.tripDate).format('MM-DD-YYYY');
        const startTime = (0, _moment.default)(this.get('selectedDateTimeRage').startTime).format('hh:mm a');
        const endTime = Ember.get(this, 'breakEndTime');
        const stDt = (0, _moment.default)(date + ' ' + startTime);
        const edDt = (0, _moment.default)(date + ' ' + endTime);
        const selectedAddress = Ember.get(this, 'selectedBreakType') === FIXED_BREAK_TYPE && Ember.get(this, 'selectedLocation') ? Ember.get(this, 'selectedLocation') : null;
        let address = null;

        if (selectedAddress && selectedAddress.fullAddress) {
          address = {
            formattedAddress: selectedAddress.fullAddress,
            lat: selectedAddress.lat,
            lng: selectedAddress.lng
          };
        }

        const payload = {
          address,
          startTime: stDt.toISOString(),
          endTime: edDt.toISOString(),
          note: this.get('breakNote'),
          duration: this.get('breakDuration'),
          routeId: Ember.get(this, 'routeDetails').routeId,
          breakType: Ember.get(this, 'selectedBreakType'),
          userId: this.session.data.authenticated.userId
        };
        return yield reconcileStopAdapter.postStop(payload, 'break');
      } catch (error) {
        throw error;
      }
    }),

    showToolTip() {
      const tooltip = this.get('tooltip');
      const title = 'Insert Driver Break';
      const tip = 'Add driver break?';
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          return Ember.get(this, 'saveDriverBreakTask').perform().then(response => {
            const breakId = response && response.data && response.data.id ? response.data.id : '';
            this.send('cancel');
            this.get('workspace').popState('reconcileAddBreak');
            this.get('notifications').success(`SUCCESSFULLY ADDED DRIVER BREAK ${breakId}`);
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in driver break, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    checkValidations() {
      let isValid = true;
      Ember.set(this, 'errors', []);

      if (!Ember.get(this, 'selectedBreakType')) {
        isValid = false;
        this.errors.push('Please select driver break type');
      }

      if (!Ember.get(this, 'selectedLocation') && Ember.get(this, 'selectedBreakType') === FIXED_BREAK_TYPE) {
        isValid = false;
        this.errors.push('Please select break address');
      }

      if (!Ember.get(this, 'selectedDateTimeRage').startTime) {
        isValid = false;
        this.errors.push('Please select break time');
      }

      if (!Ember.get(this, 'breakDuration')) {
        isValid = false;
        this.errors.push('Please select break duration');
      }

      if (Ember.get(this, 'breakDuration') === '0') {
        isValid = false;
        this.errors.push('Break duration should be greater than 0');
      }

      return isValid;
    },

    setBreakEndTime() {
      if (this.get('selectedDateTimeRage').startTime) {
        const startTime = (0, _moment.default)(this.get('selectedDateTimeRage').startTime).format('hh:mm A');
        const endTime = (0, _moment.default)(startTime, 'h:mm A').add(Ember.get(this, 'breakDuration'), 'minutes').format('hh:mm A');
        Ember.set(this, 'breakEndTime', endTime);
      }
    },

    actions: {
      onUndoClick() {
        Ember.set(this, 'breakDuration', null);
        Ember.set(this, 'breakNote', null);
        Ember.set(this, 'selectedBreakType', null);

        if (Ember.get(this, 'selectedLocation')) {
          Ember.set(this, 'canResetAddress', true);
        }

        Ember.set(this, 'selectedDateTimeRage.startTime', null);
        Ember.set(this, 'breakEndTime', null);
        Ember.set(this, 'selectedLocation', null);
      },

      onSaveClick() {
        const isValid = this.checkValidations();

        if (isValid) {
          this.showToolTip();
        }
      },

      onbreakTypeChange(option) {
        Ember.set(this, 'selectedBreakType', option);
      },

      onChangeLocation(record) {
        Ember.set(this, 'selectedLocation', record);
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        Ember.set(this, valuePath, momentTime.toISOString());
        this.setBreakEndTime();
      },

      onDurationChange(value) {
        Ember.set(this, 'breakDuration', value);
        this.setBreakEndTime();
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      }

    }
  });

  _exports.default = _default;
});