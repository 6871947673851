define("adept-iq/pods/components/search-modal/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tableRef: null,
    searchText: null,
    isSearchEnabled: false,
    warning: false,
    modalAPI: null,
    queryParams: Ember.computed.readOnly('modalAPI.queryParams'),
    title: Ember.computed.readOnly('modalAPI.title'),
    config: Ember.computed.readOnly('modalAPI.config'),
    primaryActionText: Ember.computed.readOnly('modalAPI.primaryActionText'),
    primaryAction: Ember.computed.readOnly('modalAPI.primaryAction'),
    hasOverlay: Ember.computed.readOnly('modalAPI.hasOverlay'),
    // covers interface so user must confirm or cancel
    selectedRecord: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    isRunning: Ember.computed.or('primaryActionTask.isRunning'),
    // `drop()` might be better than `enqueue()` here, but this ensures that
    // tasks won't be cancelled by accident during messy transitions
    primaryActionTask: (0, _emberConcurrency.task)(function* (record) {
      const primaryAction = this.get('primaryAction');

      if (primaryAction) {
        try {
          yield primaryAction(record);
          this.reset();
        } catch (e) {
          // TODO: allow user to try again without reset
          this.reset();
          throw new Error(e);
        }
      }
    }).enqueue(),
    secondaryActionTask: (0, _emberConcurrency.task)(function* () {
      const secondaryAction = this.get('secondaryAction');

      if (secondaryAction) {
        yield secondaryAction();
      }

      this.reset();
    }).enqueue(),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('dropdownActions', [{
        id: 'print',
        name: 'Download',
        action: () => {
          const tableRef = this.get('tableRef');
          tableRef.exportData();
        }
      }]);
    },

    didRender() {
      this._super(...arguments);
    },

    onCloseModal() {
      this.reset();
      this.resetSearchInfo();
    },

    reset() {
      const modalAPI = this.get('modalAPI');
      modalAPI.setProperties({
        title: null,
        primaryActionText: null,
        secondaryActionText: null,
        primaryAction: null,
        secondaryAction: null,
        hasOverlay: false,
        warning: false,
        config: null
      });
    },

    resetSearchInfo() {
      this.onClearSearchText();
      this.set('isSearchEnabled', false);
    },

    onClearSearchText() {
      this.set('searchText', '');
      const tableRef = this.get('tableRef');

      if (!tableRef) {
        return;
      }

      tableRef.onClearSearchText();
    },

    actions: {
      onPrimaryActionButtonClick() {
        const selectedRecord = this.get('selectedRecord');

        if (selectedRecord) {
          this.get('primaryActionTask').perform(selectedRecord).then(() => {
            this.onCloseModal();
          });
        }
      },

      onCloseModal() {
        this.onCloseModal();
      },

      onClickOverlayModal() {
        const hasOverlay = this.get('modalAPI.hasOverlay');

        if (hasOverlay) {
          return false;
        }

        this.reset();
      },

      onHelp() {},

      toggleSearchInput() {
        this.toggleProperty('isSearchEnabled');
      },

      onSearchTextChange(searchText) {
        const previousSearchText = this.get('searchText');

        if (searchText === previousSearchText) {
          return;
        }

        const tableRef = this.get('tableRef');

        if (!tableRef) {
          return;
        }

        this.set('searchText', searchText);
        tableRef.onSearchTextChange(searchText);
      },

      onClearSearchText() {
        this.onClearSearchText();
      },

      onDropDownActionClick(action, dropdown) {
        dropdown.actions.close();
        if (action.action) action.action();
      }

    }
  });

  _exports.default = _default;
});