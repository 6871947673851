define("adept-iq/pods/components/iq-widgets/navigation-widget/component", ["exports", "tomtom", "adept-iq/config/environment", "resize-observer-polyfill"], function (_exports, _tomtom, _environment, _resizeObserverPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBUG_MODE = false;
  const FPS = 30; // eslint-disable-line no-unused-vars

  var _default = Ember.Component.extend({
    classNames: ['navigation-widget'],
    location: null,
    initialZoom: 17,
    bearing: 0,
    rotate: false,
    destination: null,
    navigationPolyline: null,
    navigationPolylineLocation: null,
    // TODO: get rid of this after debug done
    guidance: null,
    // tomtom objects
    map: null,
    markers: null,
    gpsPolylineLayer: null,
    navigationPolylineLayer: null,
    // tomtom will only accept an element ID, so create a unique one
    mapElementId: Ember.computed('elementId', function () {
      return `${this.get('elementId')}-map`;
    }),
    viewChange: Ember.observer('map', 'location', 'zoom', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateLocation');
    }),
    destinationChange: Ember.observer('destination', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateDestination');
    }),
    bearingChange: Ember.observer('rotate', 'bearing', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateBearing');
    }),
    navigationPolylineChange: Ember.observer('navigationPolyline', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateNavigationPolyline');
    }),
    navigationPolylineLocationChange: Ember.observer('navigationPolylineLocation', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateNavigationPolylineLocation');
    }),

    didInsertElement() {
      this._super(...arguments);

      this.initializeMap();
      this._nowInterval = setInterval(() => {
        this.set('now', new Date());
      }, 1000);
      this._resizeObserver = new _resizeObserverPolyfill.default(() => {
        Ember.run.debounce(this, 'onWidgetResize', 250);
      });

      this._resizeObserver.observe(this.$()[0]); // have to manually init this one


      this.navigationPolylineChange();
      this.navigationPolylineLocationChange();
    },

    willDestroyElement() {
      clearInterval(this._nowInterval);
      this.destroyMap();

      if (this._resizeObserver) {
        this._resizeObserver.disconnect();

        this._resizeObserver = null;
      }

      this._super(...arguments);
    },

    initializeMap() {
      const mapElementId = this.get('mapElementId'); // set `scrollWheelZoom` and `touchZoom` to 'center' to re-enable zoom

      const map = _tomtom.default.map(mapElementId, {
        key: _environment.default.tomtom.key,
        source: _environment.default.tomtom.source,
        basePath: _environment.default.tomtom.basePath,
        styleUrlMapping: _environment.default.tomtom.styleUrlMapping,
        zoomControl: false,
        dragging: false,
        boxZoom: false,
        doubleClickZoom: false,
        scrollWheelZoom: false,
        touchZoom: false,
        zoom: this.get('initialZoom'),
        keyboard: false
      });

      this.set('map', map);

      const navigationPolylineLayer = _tomtom.default.L.polyline([], {
        color: 'blue'
      }).addTo(map);

      const gpsPolylineLayer = _tomtom.default.L.polyline([], {
        color: 'green'
      }).addTo(map);

      this.set('navigationPolylineLayer', navigationPolylineLayer);
      this.set('gpsPolylineLayer', gpsPolylineLayer);
      const destinationIcon = {
        icon: _tomtom.default.L.icon({
          iconUrl: '/stops-icons/stop_next@2x.png',
          iconSize: [23, 23],
          iconAnchor: [11.5, 11.5]
        }),
        keyboard: false,
        interactive: false
      };

      const destinationMarker = _tomtom.default.L.marker([0, 0], destinationIcon).addTo(map);

      this.set('destinationMarker', destinationMarker);
      const projectedIcon = {
        icon: _tomtom.default.L.icon({
          iconUrl: '/location-26.png',
          iconSize: [23, 23],
          iconAnchor: [11.5, 11.5]
        }),
        keyboard: false,
        interactive: false
      };

      const navigationPolylineLocationMarker = _tomtom.default.L.marker([0, 0], projectedIcon);

      if (DEBUG_MODE) {
        navigationPolylineLocationMarker.addTo(map);
      }

      this.set('navigationPolylineLocationMarker', navigationPolylineLocationMarker);
      this.set('rotatableMarkers', []);
    },

    destroyMap() {
      this.get('map').remove();
      this.set('map', null);
    },

    // onGuidanceChange: observer('guidance', function() {
    //   this.onWidgetResize();
    // }),
    onWidgetResize() {
      const map = this.get('map');
      if (!map) return;
      Ember.run.scheduleOnce('afterRender', () => {
        map.invalidateSize();
      });
    },

    updateLocation() {
      const map = this.get('map');
      const location = this.get('location');
      if (!map || !location) return;
      const options = {
        noMoveStart: true,
        easeLinearity: 1.0
      };
      const t = new Date();

      if (this._tLocation) {
        options.duration = (t - this._tLocation) / 1000.0;
        this._tLocation = t;
      } else {
        options.duration = 0;
        this._tLocation = t;
      }

      if (this._location) {
        // cancel existing animation
        map.panTo(map.getCenter(), {
          animate: false,
          noMoveStart: true
        });
      }

      map.panTo(location, options);
      this._location = location;
    },

    updateDestination() {
      const destination = this.get('destination');
      const destinationMarker = this.get('destinationMarker');
      if (!destination || !destinationMarker) return;
      destinationMarker.setLatLng(destination);
    },

    updateBearing() {
      const mapElementId = this.get('mapElementId');
      const element = document.getElementById(mapElementId);
      const rotate = this.get('rotate');
      const bearing = this.get('bearing');
      element.style.transform = `rotate(${rotate ? -bearing : 0}deg)`;
      let markerAngle = bearing; // translate angle to within [-180, 180)

      while (markerAngle < -180) markerAngle += 360;

      while (markerAngle >= 180) markerAngle -= 360;

      this.get('rotatableMarkers').forEach(marker => {
        marker.setRotationAngle(rotate ? markerAngle / 2 : 0);
      });

      if (!this.$('.driver-icon').hasClass('driving')) {
        this.$('.driver-icon').addClass('driving');
      }

      this.$('.driver-icon').css({
        transform: `rotate(${rotate ? 0 : bearing}deg)`
      });
    },

    updateNavigationPolyline() {
      const navigationPolyline = this.get('navigationPolyline') || [];
      const navigationPolylineLayer = this.get('navigationPolylineLayer');

      if (Ember.isEmpty(navigationPolyline)) {
        navigationPolylineLayer.setLatLngs([]);
        return;
      }

      navigationPolylineLayer.setLatLngs(navigationPolyline);
    },

    updateNavigationPolylineLocation() {
      const navigationPolylineLocation = this.get('navigationPolylineLocation');
      const navigationPolylineLocationMarker = this.get('navigationPolylineLocationMarker');
      if (!navigationPolylineLocation || !navigationPolylineLocationMarker) return;
      navigationPolylineLocationMarker.setLatLng(navigationPolylineLocation);
    }

  });

  _exports.default = _default;
});