define("adept-iq/pods/components/iq-widgets/breakdown-replacement-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/breakdown-replacement-form-widget/config", "moment", "adept-iq/utils/unwrapProxy"], function (_exports, _component, _config, _moment, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    editableSections: _config.default.editableSections,
    classNames: ['breakdown-replacement-form-widget'],

    init() {
      this._super(...arguments);

      const records = this.get('editableRecords') || [];
      records.forEach(record => {
        record.set('vehicleSelectionRefreshFlag', false);
      });
    },

    didInsertElement() {
      this._super(...arguments); // side panel width just for assign-vehicle-driver-to-route


      Ember.$('.breakdown-replacement-form-widget').width('600px');
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const replacementRouteId = record.get('replacementRouteId');
        const replacementStartTime = (0, _moment.default)(record.get('replacementStartTime'));
        const replacementEndTime = (0, _moment.default)(record.get('replacementEndTime'));
        const vehicleId = record.get('replacementVehicle.id');

        if (isNaN(parseInt(replacementRouteId, 10))) {
          valid = false;
          this.get('editModal.errors').pushObject('Route ID must be a numerical entry');
        }

        if (replacementStartTime.isSameOrAfter(replacementEndTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Start Time cannot be after End Time');
        }

        if (Ember.isEmpty(vehicleId)) {
          valid = false;
          this.get('editModal.errors').pushObject('Vehicle is required');
        } else {
          const maxTravelNeedCounts = record.get('maxTravelNeedCounts');
          const vehicleType = (0, _unwrapProxy.unwrapProxy)(record.get('replacementVehicle.vehicleType'));

          if (vehicleType.noOfAmbulatorySeats < maxTravelNeedCounts.ambulatory || vehicleType.noOfServiceAnimalSeats < maxTravelNeedCounts.serviceAnimal || vehicleType.noOfWheelChairSeats < maxTravelNeedCounts.wheelchair || vehicleType.noOfWideAmbulatorySeats < maxTravelNeedCounts.wideAmbulatory || vehicleType.noOfWideWheelChairSeats < maxTravelNeedCounts.wideWheelchair) {
            const tooltip = this.get('tooltip');
            const title = this.get('title');
            const editModal = this.get('service');
            tooltip.pushConfirmation({
              tip: 'The route exceeds the selected vehicle capacity. Are you sure you want to proceed?',
              title,
              hasOverlay: true,
              warning: true,
              primaryActionText: 'Yes',
              primaryAction: () => {
                editModal.apply(title);
              },
              secondaryActionText: 'No',
              secondaryAction: () => {
                tooltip.reset();
              }
            });
            valid = false;
          }
        }
      });
      return valid;
    },

    actions: {
      onUndoClick() {
        const dispatchRoute = this.get('editableRecords.firstObject');

        if (Ember.isPresent(dispatchRoute)) {
          dispatchRoute.rollbackAttributes();
          dispatchRoute.set('replacementDriver', null);
          dispatchRoute.set('replacementVehicle', null);
        }
      },

      onApplyClick() {
        if (this.validateRecords()) {
          const title = this.get('title');
          this.get('service').apply(title);
        }
      }

    }
  });

  _exports.default = _default;
});