define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/19a-review/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DRIVER_REVIEW_VALIDATION = void 0;
  const DRIVER_REVIEW_VALIDATION = {
    provider: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Provider',
      message: '{description} can not be blank'
    })],
    examTypeName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Type of Date',
      message: '{description} can not be blank'
    })]
  };
  _exports.DRIVER_REVIEW_VALIDATION = DRIVER_REVIEW_VALIDATION;
});