define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/maintenance/component", ["exports", "moment", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/maintenance/maintenance-validation", "adept-iq/config/placeholders"], function (_exports, _moment, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _maintenanceValidation, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fields = {
    maintenanceDate: '',
    mileage: '',
    acceptedDate: '',
    accepted: '',
    notes: '',
    serviceDate: ''
  };
  const dropDownFields = {
    inspectionProviderName: '',
    problemIdentifiedName: '',
    maintenanceLocationName: '',
    maintenanceTypeName: '',
    maintenanceStatusName: ''
  };

  var _default = Ember.Component.extend({
    classNames: ['maintenance'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Placeholders
    maintenanceTypePlaceholder: _placeholders.MAINTENANCE_TYPE_PLACEHOLDER,
    maintenanceStatusPlaceholder: _placeholders.MAINTENANCE_STATUS_PLACEHOLDER,
    maintenanceLocationPlaceholder: _placeholders.MAINTENANCE_LOCATION_PLACEHOLDER,
    ProblemIdentifiedPlaceholder: _placeholders.PROBLEM_IDENTIFIED_PLACEHOLDER,
    inspectionProviderNamePlaceholder: _placeholders.INSPECTION_PROVIDER_NAME_PLACEHOLDER,
    mileagePlaceholder: _placeholders.MILEAGE_PLACEHOLDER,
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    disableBtns: null,
    vehicleInspector: null,
    maintenanceStatus: null,
    maintenanceType: null,
    maintenanceLocation: null,
    ProblemIdentified: null,
    selectedVal: null,
    maxLength: null,
    refreshRecord: true,
    //To refresh on undoRecord and newRecord
    isNewRecord: false,
    disableForm: true,
    deleteBtn: true,
    showErrorMsg: false,
    timeFormat: 'Y-m-d h:i K',
    // passed into view
    editAPI: null,
    selectedVehicle: Ember.computed.readOnly('editAPI.selectedVehicle'),
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedMaintenanceRow: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    vehicleMaintenance: Ember.computed('selectedRow', 'refreshRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRow'));
      return this.setDefaultProperties(record);
    }),
    hasTemplateChanged: Ember.computed('vehicleMaintenance.isDirty', function () {
      const vehicleMaintenance = this.get('vehicleMaintenance');
      if (vehicleMaintenance.get('isDirty')) return true;
      return false;
    }),
    saveBtn: Ember.computed('disableForm', 'hasTemplateChanged', function () {
      const disableForm = this.get('disableForm');
      const hasTemplateChanged = this.get('hasTemplateChanged') || false;

      if (disableForm) {
        return true;
      }

      return !hasTemplateChanged;
    }),

    init() {
      this._super(...arguments);

      this.set('timePlaceHolder', 'MM-DD-YYYY hh:mm');
      this.setupDropDownValues();
      this.set('scheduled', true);
      this.set('maxLength', {
        max: 9
      });
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleMaintenanceRecord = this.get('onSaveVehicleMaintenanceRecord');
      const onNewVehicleMaintenancenRecord = this.get('onNewVehicleMaintenancenRecord').bind(this);
      const onEditVehicleMaintenanceRecord = this.get('onEditVehicleMaintenanceRecord').bind(this);
      const onDeleteVehicleMaintenanceRecord = this.get('onDeleteVehicleMaintenanceRecord');
      const onUndoVehicleMaintenanceRecord = this.get('onUndoVehicleMaintenanceRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleMaintenanceRecord,
          newAction: onNewVehicleMaintenancenRecord,
          deleteAction: onDeleteVehicleMaintenanceRecord,
          editAction: onEditVehicleMaintenanceRecord,
          undoAction: onUndoVehicleMaintenanceRecord
        });
        this.buttonSettings();
      });
    },

    setDefaultProperties(record = []) {
      let maintenanceRecord = record;

      if (Ember.isEmpty(maintenanceRecord) && this.get('isNewRecord')) {
        const store = this.get('store');
        maintenanceRecord = store.createRecord('vehicle-maintenance');
      } else {
        this.set('disableForm', true);
        Ember.run.scheduleOnce('afterRender', this, 'buttonSettings');
      }

      if (Ember.isEmpty(maintenanceRecord)) {
        maintenanceRecord = Ember.Object.create(fields, dropDownFields);
      }

      this.set('showErrorMsg', false);
      this.set('acceptedDateError', true);
      return new _emberChangeset.default(maintenanceRecord, (0, _emberChangesetValidations.default)(_maintenanceValidation.VALIDATIONS), _maintenanceValidation.VALIDATIONS);
    },

    setupDropDownValues() {
      const store = this.get('store');
      const dropDownModels = ['maintenance-type-name', 'maintenance-status-name', 'maintenance-location-name', 'problem-identified-name', 'inspection-provider-name'];
      dropDownModels.forEach(model => {
        const records = store.peekAll(model);
        this.set(model, records);
      });
    },

    buttonSettings(deleteBtn = false, editBtn = false, saveBtn = true, undoBtn = true, newBtn = false) {
      const editAPI = this.get('editAPI');
      editAPI.setProperties({
        deleteBtn,
        editBtn,
        saveBtn,
        undoBtn,
        newBtn
      });
    },

    rollbackRecord(record = null) {
      if (record && !record.get('isNew')) record.rollbackAttributes();
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const vehicleMaintenance = this.get('vehicleMaintenance');
      yield vehicleMaintenance.validate();
      const errors = vehicleMaintenance.errors;
      const vehicleMaintenanceRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleMaintenance'));
      const accepted = vehicleMaintenanceRecord.get('accepted');

      if (accepted) {
        const acceptedDate = (0, _moment.default)(vehicleMaintenanceRecord.get('acceptedDate'));
        const serviceDate = (0, _moment.default)(vehicleMaintenanceRecord.get('serviceDate'));

        if (acceptedDate < serviceDate) {
          this.set('acceptedDateError', true);
          errors.pushObject({
            validation: 'The Fix Accepted Date must be equal to or after the Fix Date'
          });
        }
      }

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      this.set('acceptedDateError', false);
      return true;
    }),
    onSaveVehicleMaintenanceRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();

      if (isValid) {
        const notifications = this.get('notifications');
        const vehicleMaintenanceRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleMaintenance'));
        const isNewRecord = this.get('isNewRecord');
        const selectedVehicle = this.get('selectedVehicle');

        if (isNewRecord) {
          vehicleMaintenanceRecord.set('vehicle', selectedVehicle);
        }

        try {
          yield vehicleMaintenanceRecord.save();
          notifications.success('Record successfully saved');
          this.set('isNewRecord', false);
          this.get('tableRef').refreshData(); //to refresh table data

          this.buttonSettings();
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onEditVehicleMaintenanceRecord() {
      this.set('disableForm', false);
      this.buttonSettings(true, true, false, false, true);
    },

    onDeleteVehicleMaintenanceRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRow = this.get('selectedRow');
      const notifications = this.get('notifications');
      yield selectedRow.destroyRecord();

      try {
        notifications.success('Record successfully deleted');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (e) {
        notifications.warning('Record failed to delete');
        console.warn(e); // eslint-disable-line
      }
    }),

    onUndoVehicleMaintenanceRecord() {
      this.toggleProperty('refreshRecord');
    },

    onNewVehicleMaintenancenRecord() {
      this.set('isNewRecord', true);
      this.get('tableRef').onUncheckAll();
      this.set('disableForm', false);
      this.toggleProperty('refreshRecord');
      this.buttonSettings(true, true, false, false, true);
    },

    actions: {
      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      },

      onRadioButtonClick(val) {
        this.set('vehicleMaintenance.scheduled', val);
      }

    }
  });

  _exports.default = _default;
});