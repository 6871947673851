define("adept-iq/pods/components/iq-widgets/new-booking-form/booking-details/trip-request-details/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PARA_TRANSIT_MODE = 'paratransit';
  const ITP_TRANSIT_MODE = 'itp';
  const BROKER_TRANSIT_MODE = 'broker';

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    tripDate: null,
    requestTime: null,
    pickAddress: null,
    dropAddress: null,
    promiseTime: Ember.computed('tripBooking.requestTime', 'selectedtravelMode', function () {
      const requestTime = this.get('tripBooking.requestTime');

      if (!this.get('booking.enableNewPromiseTimeApi')) {
        return requestTime && (0, _moment.default)(requestTime).format('hh:mm A');
      }

      const legIndex = this.get('legIndex');

      if (!this.get('booking').hasPromiseTime(legIndex) && this.get('booking').hasParaTransitOrItpFeederModeSelected(legIndex)) {
        return 'Unable to generate Promise Time';
      }

      if (!this.get('booking').hasParaTransitOrItpFeederModeSelected(legIndex)) {
        return '';
      }

      const resolvedPromiseTime = this.get('booking').getPromiseTime(legIndex, this.get('tripBooking'));
      return (0, _moment.default)(resolvedPromiseTime).format('hh:mm A');
    }),
    // NYAAR-13400 added red-text paratransit = "Unable to generate Promise Time"
    // Incase promise time is generated  => For 'taxi' is empty  and   promise time will be displayed for paratransit;
    // Incase promise time is  not generated => For 'taxi' is empty and   promise time  'Unable to generate promise time' red-color-text will be displayed;
    isNotPromiseTime: Ember.computed('promiseTime', 'selectedtravelMode', function () {
      const legIndex = this.get('legIndex');

      if (!this.get('booking').hasPromiseTime(legIndex) && this.get('booking').hasParaTransitOrItpFeederModeSelected(legIndex)) {
        return true;
      }

      return false;
    }),
    disableServiceModeDropDown: Ember.computed('booking.itpInfo.hasOverrideITP', 'selectedtravelMode', function () {
      const hasOverrideITP = this.get('booking.itpInfo.hasOverrideITP');
      const selectedtravelMode = this.get('selectedtravelMode.displayName'); // if selected travel mode is itp and user doesn't have permission to override itp , disable service mode  dropdown

      if (selectedtravelMode.toLowerCase() === 'itp' && hasOverrideITP === false) {
        return true;
      }

      return false;
    }),
    travelModes: null,
    selectedtravelMode: null,
    riderRecord: null,
    allowedServiceModes: null,

    init() {
      this._super(...arguments);

      this.setTravelModeOptions();
      this.setDefaultravelMode();
    },

    setTravelModeOptions() {
      const allowedServiceModes = this.getAllowedServiceModesForDropDown();
      const configTravelModes = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-travel_modes';
      });
      this.setAllTravelModeDisplayNameToCapitalize(configTravelModes);
      const travelModeOptions = configTravelModes.filter(tm => {
        return allowedServiceModes.includes(tm.get('name').toLowerCase());
      });
      this.set('travelModes', travelModeOptions);
    },

    // NYAAR-12200 Paratransit service mode is incorrectly capitalized on Booking details screen
    setAllTravelModeDisplayNameToCapitalize(travelModes) {
      travelModes.forEach(tm => {
        const displayName = tm.get('displayName').toLowerCase();
        const capitalizeDisplayName = Ember.String.capitalize(displayName);
        tm.set('displayName', capitalizeDisplayName);
      });
    },

    /**
     * this will return list of service mode string example : ['itp', 'paratransit', 'broker', 'taxi']
     * @returns {*}
     */
    getAllowedServiceModesForDropDown() {
      const riderTravelModes = this.get('riderSelectedTravelModes') || []; // if only itp model is evaluated in eligibility workflow, travel mode list should display only itp

      const isITPModeOnly = riderTravelModes.length === 1 && riderTravelModes.includes(ITP_TRANSIT_MODE);

      if (isITPModeOnly) {
        return [ITP_TRANSIT_MODE];
      }

      let allowedServiceModes = this.get('allowedServiceModes') || []; // ride allowedServiceModes doesnt contain para transit, add the para transit in travel mode  drop down
      // by default, paratransit is allowed

      if (!allowedServiceModes.includes(PARA_TRANSIT_MODE)) {
        allowedServiceModes.push(PARA_TRANSIT_MODE);
      } // if itp  travel mode is not eavluated by  eiligibity workflow, remove the itp service mode


      if (!riderTravelModes.includes(ITP_TRANSIT_MODE)) {
        allowedServiceModes = allowedServiceModes.filter(serviceMode => serviceMode !== ITP_TRANSIT_MODE);
      } // remove broker from  dropdown based on the comment in the story NYAAR-7795


      allowedServiceModes = allowedServiceModes.filter(serviceMode => serviceMode !== BROKER_TRANSIT_MODE);
      const normalizedServiceModes = allowedServiceModes.map(s => s.toLowerCase());
      return normalizedServiceModes;
    },

    setDefaultravelMode() {
      const riderTravelModes = this.get('riderSelectedTravelModes') || [];
      const defaultTravelMode = riderTravelModes[0] || PARA_TRANSIT_MODE; // get previous travel-mode-map for edit-booking

      const previousTravelModeMap = this.get('legTravelModeMap');
      const previousTravelMode = previousTravelModeMap[this.get('legIndex')];
      const prevTravelModeConfig = this.get('travelModes').find(travelModelConfig => {
        return travelModelConfig.name.toLowerCase() === previousTravelMode;
      });
      const selectedTravelModeConfig = prevTravelModeConfig || this.get('travelModes').find(travelModelConfig => {
        return travelModelConfig.name.toLowerCase() === defaultTravelMode;
      });
      this.set('selectedtravelMode', selectedTravelModeConfig);
      const onSelectedTravelModeChange = this.get('onSelectedTravelModeChange');

      if (onSelectedTravelModeChange) {
        onSelectedTravelModeChange(this.get('legIndex'), selectedTravelModeConfig);
      }
    },

    actions: {
      onTravelModeChange(travelMode) {
        this.set('selectedtravelMode', travelMode);
        const onSelectedTravelModeChange = this.get('onSelectedTravelModeChange');

        if (onSelectedTravelModeChange) {
          onSelectedTravelModeChange(this.get('legIndex'), travelMode);
        }
      }

    }
  });

  _exports.default = _default;
});