define("adept-iq/adapters/location", ["exports", "adept-iq/adapters/-addressService", "adept-iq/config/api-urls"], function (_exports, _addressService, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _addressService.default.extend({
    ajax1: Ember.inject.service('ajax'),
    session: Ember.inject.service(),

    postLocation(location) {
      const session = this.get('session');
      const json = {
        type: 'location',
        attributes: {
          lat: location.get('lat'),
          lng: location.get('lng'),
          geoNode: location.get('geoNode')
        }
      };
      return this.get('ajax1').post(_apiUrls.API.addressService.host + '/location', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    },

    patchLocation(location) {
      const session = this.get('session');
      const id = location.get('id');
      const json = {
        id,
        type: 'location',
        attributes: {
          lat: location.get('lat'),
          lng: location.get('lng'),
          geoNode: location.get('geoNode')
        }
      };
      return this.get('ajax1').patch(_apiUrls.API.addressService.host + '/location/' + id, {
        method: 'PATCH',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    }

  });

  _exports.default = _default;
});