define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/tables/road-call/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/tables/road-call/config", "adept-iq/utils/sorts", "adept-iq/utils/filters"], function (_exports, _component, _config, _sorts, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['road-call-table'],
    config: _config.default,
    store: Ember.inject.service(),
    paginationEnabled: true,
    hasPendingChanges: false,
    enableWarningRowChangeAlert: false,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    fetchDataQuery() {
      const params = {
        sorts: []
      };

      try {
        // this only applies for server-side pagination
        if (this.get('paginationEnabled')) {
          params.page = {
            offset: this.get('records.length'),
            limit: this.get('limit')
          };
        }

        const sort = this.buildSortParam();

        if (sort) {
          params.sorts.push(sort);
        }

        const includes = this.get('defaultIncludes');

        if (!Ember.isEmpty(includes)) {
          params.includes = includes;
        }

        const filters = this.setupFilterType();
        params.filter = (0, _filters.buildCompoundFilterNode)('and', filters);
        const modelName = this.get('config.modelName');
        return this.query(modelName, params);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Workspace view is not refreshed on selecting or deselecting' + error);
      }
    },

    async query(modelName, params = {}) {
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const activeData = this.get('source').get(modelName) || [];
      const filteredData = activeData.filter(filterFn).sort(compareFn);
      return filteredData.filter(model => !model.get('isDeleted'));
    },

    rollbackRecord(record = null) {
      if (this.get('usingRadioRowComponent')) {
        if (record && !record.get('isNew')) record.rollbackAttributes();
      }
    }

  });

  _exports.default = _default;
});