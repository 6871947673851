define("adept-iq/pods/components/side-drawer/activity-log/route-activity-log/component", ["exports", "ember-concurrency", "adept-iq/pods/components/side-drawer/activity-log/route-activity-log/config", "moment", "lodash", "adept-iq/pods/components/side-drawer/activity-log/base-activity-log/component", "adept-iq/config/environment"], function (_exports, _emberConcurrency, _config, _moment, _lodash, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ROUTE_LOGGING_TYPE = 'route';
  const avlmEventMockedEntry = {
    id: '9',
    type: 'routeExecEvent',
    routeId: '1',
    content: {
      type: 'userEntry',
      details: 'User Entry PlaceHolder',
      activity: 'activity placeholder',
      userId: 'User ID PlaceHolder',
      actualTime: null
    },
    attributes: {
      routeId: '1',
      source: {
        type: 'webApp'
      },
      driver: {
        id: '1',
        badgeNr: '1',
        name: 'Arthur Rojas'
      },
      vehicle: {
        id: '10',
        callsign: '10',
        odo: 1000,
        location: {
          coord: {
            lat: 50,
            lng: 50
          },
          time: (0, _moment.default)().toDate()
        }
      }
    }
  };

  var _default = _component.default.extend({
    tagName: '',
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    config: _config.default,
    userActivitySelected: null,
    errors: null,
    details: '',
    columns: _config.columns,
    table: null,
    addActivityLog: false,
    isAvlmLite: _environment.default.APP.avlmLite,
    route: Ember.computed.readOnly('workspace.topOptions.route'),
    displayName: Ember.computed.readOnly('workspace.topOptions.displayName'),
    title: Ember.computed.alias('displayName'),
    isLoading: Ember.computed.alias('fetchEventsTask.isRunning'),
    startDate: Ember.computed.alias('workspace.startDate'),
    endDate: Ember.computed.alias('workspace.endDate'),
    isPlayback: Ember.computed.alias('workspace.isPlaybackWorkspace'),
    fetchEventsTask: (0, _emberConcurrency.task)(function* () {
      const store = this.store;
      const routeId = this.get('route.id');
      const adaptor = this.get('route.adaptor') ? this.get('route.adaptor') : 'activity-log';
      const activityLogAdapter = this.get('store').adapterFor(adaptor);

      function fetchFullRecords(activityLogs) {
        let driverDetails = null;
        return Promise.all(activityLogs.data.map(activityLog => {
          const driver = store.peekRecord('driver', activityLog.attributes.driverId);
          const vehicle = store.peekRecord('vehicle', activityLog.attributes.vehicleId);

          if (driver) {
            activityLog.attributes.driver = driver;
            driverDetails = driver;
          } else if (activityLog.attributes.driverId && parseInt(activityLog.attributes.driverId, 10) > 0) {
            store.findRecord('driver', activityLog.attributes.driverId).then(driverModel => {
              activityLog.attributes.driver = driverModel;
              driverDetails = driver;
            });
          }

          if (activityLog.attributes.actionType === 'noShow' && driverDetails) {
            activityLog.attributes.driver = driverDetails;
          }

          if (vehicle) {
            activityLog.attributes.vehicle = vehicle;
          } else if (activityLog.attributes.vehicleId && parseInt(activityLog.attributes.vehicleId, 10) > 0) {
            store.findRecord('vehicle', activityLog.attributes.vehicleId).then(vehicleModel => {
              activityLog.attributes.vehicle = vehicleModel;
            });
          }
        })).then(() => {
          return activityLogs;
        });
      } // clear events table


      this.set('events', []);
      yield activityLogAdapter.getActivityLogs({
        routeId,
        eventLog: true,
        type: 'route'
      }).then(async activityLogs => {
        return fetchFullRecords(activityLogs);
      }).then(activityLogs => {
        this.processActivityLogs(activityLogs, ROUTE_LOGGING_TYPE);
      });
    }).drop(),
    actions: {
      onSaveClick() {
        if (!this.validateRecords()) return;
        this.set('errors', null);
        const activity = this.get('userActivitySelected');
        const details = this.get('details');
        const tooltip = this.get('tooltip');
        const currentUser = this.session.data.authenticated.userId;
        const title = this.get('title');
        avlmEventMockedEntry.content.details = details;
        avlmEventMockedEntry.content.activity = activity;
        avlmEventMockedEntry.content.userId = currentUser;
        avlmEventMockedEntry.content.actualTime = (0, _moment.default)().toDate();
        const newEntry = (0, _lodash.cloneDeep)(avlmEventMockedEntry);
        const fetchEventsTask = this.get('fetchEventsTask');
        const notifications = this.get('notifications');
        const self = this;
        tooltip.pushConfirmation({
          tip: 'Are you sure you want to add an entry to the activity log',
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          secondaryActionText: 'Cancel',

          primaryAction() {
            const activityLogAdapter = self.get('store').adapterFor('activity-log');
            const createRouteActivity = activityLogAdapter.createUserRouteActivity(newEntry, fetchEventsTask);
            return createRouteActivity.then(() =>
            /*response*/
            {
              notifications.success('New Activity Log Entry Added Successfully');
              tooltip.reset();
              self.toggleProperty('addActivityLog');
              self.fetchEventsTask.perform();
              self.set('userActivitySelected', '');
              self.set('details', '');
            }).catch(() => {
              notifications.warning('Failed to add Activity Log');
              tooltip.reset();
            });
          },

          secondaryAction() {
            tooltip.reset();
          }

        });
      }

    }
  });

  _exports.default = _default;
});