define("adept-iq/services/api-context", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    booking: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    externalRiderSystemToken: null,

    async loadSubscriptionsForSelectedExternalRiders() {
      return new Promise(async (resolve, reject) => {
        try {
          const selectedRiders = this.get('booking.selectedRiders');
          const selectedRiderIds = selectedRiders.map(r => r.get('id'));
          const ridersIdsClause = selectedRiderIds.map(r => `'${r}'`).join(',');
          this.get('workspace').set('isGlobalSpinnerVisible', true);
          const ridersQuery = `in(riderId,(${ridersIdsClause}))&include=phoneNumbers`;
          const riders = await this.get('store').query('rider', {
            filter: ridersQuery
          });

          if (Ember.isBlank(riders)) {
            this.get('workspace').set('isGlobalSpinnerVisible', false);
            resolve([]);
          }

          const riderIDs = riders.map(function (a) {
            return a.get('id');
          });
          const riderIdsInClause = riderIDs.map(r => `'${r}'`).join(',');
          const subscriptionsByRiderIdQuery = `in(riderId,(${riderIdsInClause}))&include=travelNeeds,exclusions,recurrencePatterns`;
          const subscriptions = await this.get('store').query('subscription', {
            filter: subscriptionsByRiderIdQuery
          });
          const allPlaceIds = subscriptions.map(s => [s.get('origin.id'), s.get('destination.id')]);

          const placeIds = _lodash.default.flatMap(allPlaceIds);

          const unipIds = _lodash.default.uniq(placeIds);

          const placeIdsInClause = unipIds.map(p => `'${p}'`).join(',');
          const placesQuery = `in(id,(${placeIdsInClause}))&include=address,location`;
          await this.get('store').query('place', {
            filter: placesQuery
          });
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          resolve(subscriptions);
        } catch (e) {
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          reject(e);
        }
      });
    },

    loadBookingsForSelectedExternalRiders() {
      const selectedRiders = this.get('booking.selectedRiders');
      const selectedRiderIds = selectedRiders.map(r => r.get('id'));
      let bookingIDs = [];
      let riderIDs = [];
      let riderQuery;
      const ridersIdsClause = selectedRiderIds.map(r => `'${r}'`).join(',');
      return new Promise(async (resolve, reject) => {
        this.get('workspace').set('isGlobalSpinnerVisible', true);

        try {
          riderQuery = `in(riderId,(${ridersIdsClause}))`;
          const riders = await this.get('store').query('rider', {
            filter: riderQuery
          });

          if (Ember.isBlank(riders)) {
            this.get('workspace').set('isGlobalSpinnerVisible', false);
            resolve([[], []]); // first is bookings and second is trips

            return;
          }

          riderIDs = riders.map(function (a) {
            return a.get('id');
          });
          const riderIdsInClause = riderIDs.map(r => `'${r}'`).join(',');
          const bookingByRiderID = `in(legs.rider,(${riderIdsInClause}))&include=group,legs,legs.segments,legs.legTravelNeeds,legs.segments.pick,legs.segments.drop,subscription,cancellation&fields[booking]=id,status`;
          const bookings = await this.get('store').query('booking', {
            filter: bookingByRiderID
          });
          bookingIDs = bookings.map(function (bkng) {
            return bkng.get('id');
          });
          let placeIds = '';
          bookings.forEach(booking => {
            const legsInBooking = booking.get('legs');
            legsInBooking.forEach(leg => {
              if (Ember.isPresent(leg.get('segment.pick.place.id'))) {
                placeIds = placeIds + leg.get('segment.pick.place.id') + ',';
              }

              if (Ember.isPresent(leg.get('segment.drop.place.id'))) {
                placeIds = placeIds + leg.get('segment.drop.place.id') + ',';
              }
            });
          });
          const query = 'in(\'id\',(' + placeIds.slice(0, placeIds.length - 1) + '))&include=address,location';
          await this.store.query('place', {
            filter: query
          });
          const bookingIdsInClause = bookingIDs.map(bId => `'${bId}'`).join(',');

          if (!Ember.isEmpty(bookingIdsInClause)) {
            const bookingAndTrips = await this.loadDataOnBasiesOfTripID(null, bookingIdsInClause, bookings);
            resolve(bookingAndTrips);
          } else {
            resolve([[], []]); // first is bookings and second is trips
          }

          this.get('workspace').set('isGlobalSpinnerVisible', false);
        } catch (error) {
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          reject(error);
        }
      });
    },

    loadDataOnBasiesOfTripID(bookingTripIds, bookingSearchIds, alreadyFetchedbookings) {
      let segmentIds = []; // let bookingIDs = [];

      let riderIDs = [];
      let tripIds = [];
      return new Promise(async (resolve, reject) => {
        this.get('workspace').set('isGlobalSpinnerVisible', true);

        try {
          let tripsByBookingID = [];

          if (!Ember.isEmpty(bookingSearchIds)) {
            tripsByBookingID = `and(eq(schedule.type,'primary'),in(booking,(${bookingSearchIds})))&include=schedule,route,pick,drop`;
          } else {
            tripsByBookingID = `and(eq(schedule.type,'primary'),in(booking,eq(id,'${bookingTripIds}')(&include=schedule,route,pick,drop`;
          }

          const trips = await this.get('store').query('trip', {
            filter: tripsByBookingID
          });

          if (Ember.isBlank(trips)) {
            resolve([alreadyFetchedbookings, []]);
            this.get('workspace').set('isGlobalSpinnerVisible', false);
          }

          tripIds = trips.map(function (trp) {
            return trp.get('id');
          });
          const tripIdStrArray = tripIds.map(t => `'${t}'`).join(',');
          segmentIds = trips.map(function (a) {
            return a.get('segment.id');
          }); // bookingIDs = trips.map(function(a) {
          //   return a.get('booking.id');
          // });

          const stopByTripId = `in(trip,(${tripIdStrArray}))&include=cluster.dispatchRoute`;
          await this.get('store').query('stop-point', {
            filter: stopByTripId
          }); // const bookingByBookingID = `and(in(id,(${bookingIDs.toString()})),eq(cancellationId,null))&include=legs,legs.legTravelNeeds,legs.segments,legs.segments.pick,legs.segments.drop,`;

          /*const bookings = await this.get('store').query('booking', {
            filter: bookingByBookingID
          });*/

          riderIDs = trips.map(function (a) {
            return a.get('rider.id');
          }); // some riderIds return undefined following is to remove them

          riderIDs = riderIDs.filter(r => !!r); // some riderIds return duplicate ids so uniq them;

          riderIDs = _lodash.default.uniq(riderIDs);
          const segmentBysegmentID = `in(id,(${segmentIds.toString()}))&include=pick,drop`;
          const segments = await this.get('store').query('segment', {
            filter: segmentBysegmentID
          });
          const pickPlaceIDs = segments.map(function (a) {
            return a.get('stops.0.place.id');
          });
          const dropPlaceIDs = segments.map(function (a) {
            return a.get('stops.1.place.id');
          });
          let placeIDs = pickPlaceIDs.concat(dropPlaceIDs); // some segment-stops are returnin null, following is to remove them

          placeIDs = placeIDs.filter(placeId => !!placeId);
          const placeByPlaceID = `in(id,(${placeIDs.toString()}))&include=address,location`;
          await this.get('store').query('place', {
            filter: placeByPlaceID
          });
          const riderByRiderID = `in(id,(${riderIDs.toString()}))`;
          await this.get('store').query('rider', {
            filter: riderByRiderID
          });
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          resolve([alreadyFetchedbookings, trips]);
        } catch (error) {
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          reject(error);
        }
      });
    },

    getExportedSchedule(bookingDateTime) {
      const schedules = this.get('store').peekAll('schedule');
      const schedule = schedules.find(s => {
        return s.get('start') <= bookingDateTime && bookingDateTime <= s.get('end');
      });
      if (!schedule) return;
      return schedule;
    },

    getExportBookingPayload(bookingId, bookingDateTime) {
      const schedule = this.getExportedSchedule(bookingDateTime);

      if (schedule) {
        return {
          'data': {
            'attributes': {
              'schedulingMode': 'automatic'
            },
            'relationships': {
              'schedule': {
                'data': {
                  'id': schedule.get('id'),
                  'type': 'schedule'
                }
              }
            },
            'id': bookingId,
            'type': 'booking'
          }
        };
      }

      return;
    },

    getSchedule() {
      const schedules = this.get('store').peekAll('schedule');
      const schedule = schedules.find(s => {
        return s.get('start') <= new Date() && new Date() <= s.get('end');
      });
      if (!schedule) return;
      return schedule;
    },

    getSameDayBookingPayload(bookingId) {
      const schedule = this.getSchedule();

      if (schedule) {
        return {
          'data': {
            'attributes': {
              'schedulingMode': 'automatic'
            },
            'relationships': {
              'schedule': {
                'data': {
                  'id': schedule.get('id'),
                  'type': 'schedule'
                }
              }
            },
            'id': bookingId,
            'type': 'booking'
          }
        };
      }

      return;
    }

  });

  _exports.default = _default;
});