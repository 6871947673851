define("adept-iq/serializers/avlm-vehicle", ["exports", "adept-iq/serializers/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      if (payload.data.attributes) {
        if (!Ember.isNone(payload.data.attributes.providerName)) {
          const relationships = payload.data.relationships ? payload.data.relationships : {};
          relationships.provider = {
            data: {
              type: 'provider',
              id: payload.data.attributes.providerName
            }
          };
          payload.data.relationships = relationships;
        }
      }

      const relationships = payload.data.relationships ? payload.data.relationships : {};

      if (Ember.isPresent(payload.data.attributes.lastGpsPingLat) && Ember.isPresent(payload.data.attributes.lastGpsPingLng)) {
        payload.data.attributes.avlLocation = {
          lat: parseFloat(payload.data.attributes.lastGpsPingLat),
          lng: parseFloat(payload.data.attributes.lastGpsPingLng),
          timestamp: payload.data.attributes.lastGpsPingTime
        };
      }

      if (Ember.isPresent(payload.data.attributes.status)) {
        payload.data.attributes.vehicleStatus = payload.data.attributes.status;
        delete payload.data.attributes.status;
      }

      const isRoadSupervisor = payload.data.attributes.routeName && payload.data.attributes.routeName.includes('SUP');
      payload.data.attributes.otp = payload.data.attributes.otpStatus;
      payload.data.attributes.driverBadgeNumber = isRoadSupervisor ? payload.data.attributes.driverPk : payload.data.attributes.driverId;
      payload.data.attributes.driverFullName = payload.data.attributes.driverName; //TODO can make it better

      if (payload.data.attributes.vehicleStateEvent) {
        switch (payload.data.attributes.vehicleStateEvent) {
          case 'commFailure':
            payload.data.attributes.isCommFailure = true;
            break;

          case 'commRestored':
            payload.data.attributes.isCommFailure = false;
            break;

          case 'gpsFailure':
            payload.data.attributes.isGpsFailure = true;
            break;

          case 'leaveGarageSignOff':
            payload.data.attributes.isLeaveGarageSignOff = true;
            break;

          default:
            break;
        }
      }

      if (payload.data.attributes.driverStateEvent) {
        switch (payload.data.attributes.driverStateEvent) {
          case 'signOn':
            payload.data.attributes.isLeaveGarageSignOff = false;
            break;

          case 'signOff':
            // Do nothing for signOff because it might not even in drving state
            break;

          case 'emergencyOn':
            break;

          case 'emergencyOff':
            break;

          default:
            break;
        }
      }

      if (payload.data.attributes.vehicleRouteExecEvent) {
        switch (payload.data.attributes.vehicleRouteExecEvent) {
          case 'onBreak':
            payload.data.attributes.isOnBreak = true;
            break;

          case 'offBreak':
            payload.data.attributes.isOnBreak = false;
            break;

          default:
            break;
        }
      }

      const driverPk = payload.data.attributes.driverPk;
      const provider = payload.data.attributes.provider;
      const routePk = payload.data.attributes.routePk;

      if (routePk) {
        relationships.avlmRoute = {
          data: {
            type: 'avlm-route',
            id: `${routePk}`
          }
        };
      }

      if (driverPk) {
        relationships.avlmDriver = {
          data: {
            type: 'avlm-driver',
            id: `${driverPk}`
          }
        };
      }

      if (provider) {
        relationships.provider = {
          data: {
            type: 'provider',
            id: provider
          }
        };
        delete payload.data.attributes.provider;
      }

      payload.data.relationships = relationships;
      return payload;
    }

  });

  _exports.default = _default;
});