define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/dot-inspection/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/dot-inspection/dot-inspection-validation", "adept-iq/config/placeholders", "moment"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _dotInspectionValidation, _placeholders, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dot-inspection-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    showErrorMsg: false,
    providerPlaceholder: _placeholders.PROVIDER_PLACEHOLDER,
    categoryPlaceholder: _placeholders.INSPECTION_CATEGORY_PLACEHOLDER,
    notesPlaceholder: _placeholders.NOTES_PLACEHOLDER,
    failedDotPlaceholder: _placeholders.FAILEDDOT_PLACEHOLDER,
    datePlaceholder: _placeholders.US_DATE_PLACEHOLDER,
    mileagePlaceholder: _placeholders.MILEAGE_PLACEHOLDER,
    // passed into view
    editAPI: null,
    selectedVehicle: Ember.computed.readOnly('editAPI.selectedVehicle'),
    selectedDOTInspection: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedDotInspectionRow: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    hasPendingChanges: Ember.computed.gt('dotInspectionChangeSet.changes.length', 0),

    async init() {
      this._super(...arguments);

      this.get('refreshTask').perform();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveDotInspectionRecord = this.get('onSaveDotInspectionRecord');
      const onNewDotInspectionRecord = this.get('onNewDotInspectionRecord').bind(this);
      const onEditDotInspectionRecord = this.get('onEditDotInspectionRecord').bind(this);
      const onDeleteDotInspectionRecord = this.get('onDeleteDotInspectionRecord');
      const onUndoDotInspectionRecord = this.get('onUndoDotInspectionRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDotInspectionRecord,
          newAction: onNewDotInspectionRecord,
          deleteAction: onDeleteDotInspectionRecord,
          editAction: onEditDotInspectionRecord,
          undoAction: onUndoDotInspectionRecord
        });
      });
      this.dotInspectionbuttonSettings();
    },

    dotInspectionbuttonSettings(deleteBtn = false, newBtn = false, editBtn = false, undoBtn = true, saveBtn = true) {
      const editAPI = this.get('editAPI');
      editAPI.setProperties({
        deleteBtn,
        editBtn,
        saveBtn,
        undoBtn,
        newBtn
      });
    },

    dotInspectionChangeSet: Ember.computed('selectedDOTInspection', function () {
      const dotInspection = (0, _unwrapProxy.unwrapProxy)(this.get('selectedDOTInspection'));

      if (dotInspection) {
        this.selectiveDateChange(dotInspection, 'inspectionDate');
        this.selectiveDateChange(dotInspection, 'nextInspectionDate');
        this.selectiveDateChange(dotInspection, 'oosDate');
      }

      return this.setDefaultProperties(dotInspection);
    }),

    async selectiveDateChange(dotInspectionChangeSet, value) {
      const dotInspectionDate = dotInspectionChangeSet.get(value);

      if (!Ember.isEmpty(dotInspectionDate)) {
        const requestCallDate = (0, _moment.default)(dotInspectionDate).format('MM/DD/YYYY');
        dotInspectionChangeSet.set(value, requestCallDate);
      }
    },

    setDefaultProperties(record = null
    /* disableForms = true */
    ) {
      const store = this.get('store');
      let pendingDotInspectionRecord = this.get('pendingDotInspectionRecord');

      if (record) {
        pendingDotInspectionRecord = record;
      } else if (!pendingDotInspectionRecord) {
        pendingDotInspectionRecord = store.createRecord('vehicle-dot-inspection');
        this.set('pendingDotInspectionRecord', pendingDotInspectionRecord);
      }

      if (pendingDotInspectionRecord.get('isNew')) {// pendingDotInspectionRecord.setProperties({});
      } else {
        Ember.run.scheduleOnce('afterRender', this, 'dotInspectionbuttonSettings');
      }

      this.set('showErrorMsg', false);
      this.set('showValidations', false);
      return new _emberChangeset.default(pendingDotInspectionRecord, (0, _emberChangesetValidations.default)(_dotInspectionValidation.VALIDATIONS), _dotInspectionValidation.VALIDATIONS);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedVehicle = this.get('selectedVehicle');
      const store = this.get('store');
      const providers = yield store.findAll('provider');
      this.set('providers', providers);
      const inspectCategories = yield store.findAll('inspection-category');
      this.set('inspectionCategories', inspectCategories);

      if (!selectedVehicle.get('isNew')) {
        yield selectedVehicle.reload();
      }

      const tableRef = this.get('tableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const dotInspectionChangeSet = this.get('dotInspectionChangeSet');
      yield dotInspectionChangeSet.validate();
      const errors = dotInspectionChangeSet.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveDotInspectionRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();

      if (isValid) {
        const notifications = this.get('notifications');
        const pendingDotInspectionRecord = this.get('dotInspectionChangeSet');
        const selectedVehicle = this.get('selectedVehicle');
        pendingDotInspectionRecord.set('vehicle', selectedVehicle);

        if (selectedVehicle.get('isNew')) {// save vehicle code
        }

        const oosDate = pendingDotInspectionRecord.get('oosDate');
        const inspectionDate = pendingDotInspectionRecord.get('inspectionDate');
        const nextInspectionDate = pendingDotInspectionRecord.get('nextInspectionDate');
        pendingDotInspectionRecord.set('oosDate', oosDate);
        pendingDotInspectionRecord.set('inspectionDate', inspectionDate);

        if (inspectionDate) {
          pendingDotInspectionRecord.set('oosDate', (0, _moment.default)(oosDate).toISOString());
        }

        if (inspectionDate) {
          pendingDotInspectionRecord.set('inspectionDate', (0, _moment.default)(inspectionDate).toISOString());
        }

        if (nextInspectionDate) {
          pendingDotInspectionRecord.set('nextInspectionDate', (0, _moment.default)(nextInspectionDate).toISOString());
        }

        try {
          yield pendingDotInspectionRecord.save();
          this.dotInspectionbuttonSettings();
          this.get('tableRef').refreshData(); //to refresh table data

          notifications.success('Successfully Saved Vehicle DOT Inspection Record');
        } catch (e) {
          notifications.warning('Failed to Save Vehicle DOT Inspection Record');
          console.warn(e); // eslint-disable-line
        }

        this.get('refreshTask').perform();
      }
    }),

    onNewDotInspectionRecord() {
      const selectedDotInspectionRow = this.get('selectedDotInspectionRow');

      if (selectedDotInspectionRow) {
        const selected = selectedDotInspectionRow.get('selected');
        selectedDotInspectionRow.set('selected', !selected);
      }

      this.get('tableRef').refreshData(); //to refresh table data

      this.dotInspectionbuttonSettings(true, true, true, false, false); //False to enable the button, TRUE to disable
    },

    onDeleteDotInspectionRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const selectedDOTInspection = this.get('selectedDOTInspection');

      try {
        yield selectedDOTInspection.destroyRecord();
        notifications.success('Record successfully deleted'); //this.get('tableRef').refreshData(); //to refresh table data
      } catch (e) {
        notifications.warning('Record failed to delete');
        console.warn(e); // eslint-disable-line
      }

      this.get('refreshTask').perform();
    }),

    onEditDotInspectionRecord() {
      this.dotInspectionbuttonSettings(true, true, true, false, false);
    },

    onUndoDotInspectionRecord() {
      const dotInspectionChangeSet = this.get('dotInspectionChangeSet');

      if (dotInspectionChangeSet) {
        dotInspectionChangeSet.rollback();
      }
    },

    actions: {
      onPowerSelectChange(valuePath, selected) {
        this.set(valuePath, selected);
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      }

    }
  });

  _exports.default = _default;
});