define("adept-iq/services/workspace", ["exports", "moment", "lodash", "adept-iq/config/environment", "adept-iq/config/workspace-states", "adept-iq/utils/guid", "adept-iq/mixins/async-schedule-operation", "adept-iq/utils/workspace-availability", "adept-iq/config/api-urls", "adept-iq/utils/flattenData"], function (_exports, _moment, _lodash, _environment, _workspaceStates, _guid, _asyncScheduleOperation, _workspaceAvailability, _apiUrls, _flattenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_SIZE = 'small';
  const DEFAULT_TILE_SIZE = 100;
  const DEFAULT_TILE_SPACING = 4;

  const logger = () => null;

  const STATE_MACHINE = {
    defaultStateId: 'default',
    // TODO
    // it'd better to reduce the states into categorical states side-drawer, modal, etc... and then in push state object the correct item is rendered dynamically
    // reduces the amount of adding states & reducing adding templates into the application.hbs
    // reducing the amount of side-drawer ones unless they have a specific hook they need or something
    states: _workspaceStates.default
  };

  var _default = Ember.Service.extend(_asyncScheduleOperation.default, {
    activeContext: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    scheduleGeneration: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax'),
    permissionLayer: Ember.inject.service(),
    geocode: Ember.inject.service(),
    size: DEFAULT_SIZE,
    tileSize: DEFAULT_TILE_SIZE,
    tileSpacing: DEFAULT_TILE_SPACING,
    isLightMode: false,
    isDragging: false,
    isResizing: false,
    isShowAbout: false,
    isDrawerShrunken: false,
    isEditingTimeline: false,
    hasLockedSchedule: false,
    reoptimizeInProgress: false,
    currentState: null,
    contentHeight: null,
    contentWidth: null,
    stack: null,
    configPermissions: null,
    _dashboardModel: null,
    _dashboardInstance: null,
    currentSchedule: null,
    triggeredReoptimize: null,
    // did user trigger reopt
    ajax: Ember.computed.readOnly('ajaxService'),
    isScheduleDashboard: Ember.computed.readOnly('dashboardModel.isScheduleDashboard'),
    isDispatchDashboard: Ember.computed.readOnly('dashboardModel.isDispatchDashboard'),
    isPlaybackWorkspace: Ember.computed.readOnly('dashboardModel.isPlaybackWorkspace'),
    isReconcileDashboard: Ember.computed.readOnly('dashboardModel.isReconcileDashboard'),
    isVehicleDriverDashboard: Ember.computed.readOnly('dashboardModel.isVehicleDriverDashboard'),
    isBookingDashboard: Ember.computed.readOnly('dashboardModel.isBookingDashboard'),
    sideDrawerComponent: Ember.computed.readOnly('stack.lastObject.sideDrawerComponent'),
    playbackFilterSettingClosed: false,
    playbackFilterSettingClosedofVehicleGrid: false,
    isSideDrawerVisible: Ember.computed.alias('stack.lastObject.isSideDrawerVisible'),
    topState: Ember.computed.alias('stack.lastObject.state'),
    topStateDisplayName: Ember.computed.alias('stack.lastObject.options.displayName'),
    topOptions: Ember.computed.alias('stack.lastObject.options'),
    topActivityId: Ember.computed.alias('stack.lastObject.activityId'),
    stackDepth: Ember.computed.alias('stack.length'),
    isEditing: Ember.computed.or('isEditingWorkspace', 'isEditingPlayback'),
    isEditingWorkspace: Ember.computed.equal('topState', 'editWorkspace'),
    isEditingPlayback: Ember.computed.equal('topState', 'editPlayback'),
    isGlobalSpinnerVisible: Ember.computed.alias('scheduleGeneration.isRunning'),
    isDashboardPickerOpen: Ember.computed.equal('topState', 'openWorkspace'),
    isDashboardSaveAsOpen: Ember.computed.equal('topState', 'saveWorkspaceAs'),
    isReoptimizeOpen: Ember.computed.equal('topState', 'reoptimize'),
    isScheduleGenerate: Ember.computed.equal('topState', 'schedule-generate'),
    isClosingSchedule: Ember.computed.equal('topState', 'closeSchedule'),
    isDashboardEditAsOpen: Ember.computed.equal('topState', 'editWorkspaceAs'),
    isRoadSupervisorModeEnabled: Ember.computed.equal('topState', 'roadSupervisor'),
    gridWidth: Ember.computed('contentWidth', 'tileSize', function () {
      return Math.floor(this.get('contentWidth') / this.get('tileSize'));
    }),
    gridHeight: Ember.computed('contentHeight', 'tileSize', function () {
      return Math.floor(this.get('contentHeight') / this.get('tileSize'));
    }),
    isExported: Ember.computed('currentSchedule.isExported', 'isScheduleDashboard', function () {
      const isScheduleDashboard = this.get('isScheduleDashboard');
      const isExported = this.get('currentSchedule.isExported');
      return isExported && isScheduleDashboard;
    }),
    useWidgetLayoutWorkspace: Ember.computed('isBookingDashboard', 'isDispatchDashboard', 'isScheduleDashboard', 'isPlaybackWorkspace', 'isReconcileDashboard', function () {
      const isDispatchDashboard = this.get('isDispatchDashboard');
      const isScheduleDashboard = this.get('isScheduleDashboard');
      const isPlaybackWorkspace = this.get('isPlaybackWorkspace');
      const isBookingDashboard = this.get('isBookingDashboard');
      const isReconcileDashboard = this.get('isReconcileDashboard'); //before defining the dashboard attributes  useWidgetLayoutWorkspace should be true

      if ((0, _lodash.isUndefined)(isBookingDashboard) && (0, _lodash.isUndefined)(isDispatchDashboard) && (0, _lodash.isUndefined)(isScheduleDashboard) && (0, _lodash.isUndefined)(isPlaybackWorkspace) && (0, _lodash.isUndefined)(isReconcileDashboard)) {
        return true;
      }

      return isDispatchDashboard || isScheduleDashboard || isPlaybackWorkspace || isReconcileDashboard || isBookingDashboard;
    }),
    // @FIXME: using an observer is a band aid, and there could be a way to refactor this, but it might need to ask the back-end for help
    // due to schedule not having distinct states
    currentScheduleStateChange: Ember.observer('isScheduleDashboard', 'currentSchedule.status', function () {
      const isScheduleDashboard = this.get('isScheduleDashboard');
      const triggeredReoptimize = this.get('triggeredReoptimize');
      const isGenerated = this.get('currentSchedule.isGenerated');
      if (isScheduleDashboard && !triggeredReoptimize && isGenerated) this._enableReoptimizeInProgressModal();
    }),

    _enableReoptimizeInProgressModal() {
      this.set('reoptimizeInProgress', true);
      this.get('pollScheduleOperationState').perform().then(res => {
        if (res.state === 'failure') this.resetReoptimizeInProgressModal();
        if (res.operationType === 'generate' && (res.progress >= 100 || res.isJobSuccess)) this.resetReoptimizeInProgressModal();else if (res.operationType === 'export' && res.isJobSuccess) this.resetReoptimizeInProgressModal();
      }).catch(() => {
        this.resetReoptimizeInProgressModal();
      });
    },

    resetReoptimizeInProgressModal() {
      setTimeout(() => {
        this.set('reoptimizeInProgress', false);
      }, 1000);
    },

    onDatesChange: Ember.observer('endDate', function () {
      this.validateTimeLineForLockedSchedule();
    }),

    async findCurrentScheduleOrCreate(schedules) {
      const {
        startDate,
        endDate
      } = this.getProperties('startDate', 'endDate');
      const schedule = (0, _lodash.findLast)(schedules, s => (0, _workspaceAvailability.processTimeConstraints)(startDate, endDate, s.get('start'), s.get('end'))); // if (isEmpty(schedule)) {
      // create schedule if not found
      // Fix NYAAR-9286 need to handle while merge with R5
      //   schedule = await this.createSchedule();
      // }

      this.set('currentSchedule', schedule);
    },

    async createSchedule() {
      const {
        startDate,
        endDate
      } = this.getProperties('startDate', 'endDate');
      let scheduleConfiguration = await this.get('store').findAll('schedule-configuration');

      if (scheduleConfiguration) {
        scheduleConfiguration = scheduleConfiguration.get('firstObject');
      } // ensure name is unique


      const baseName = (0, _moment.default)(startDate).format('YYYY-MM-DD');
      const ssSchedule = this.get('store').createRecord('schedule', {
        start: startDate,
        end: endDate,
        name: baseName,
        status: 'unscheduled',
        scheduleConfiguration
      });
      return ssSchedule.save();
    },

    // @TODO Fix when new Domain Shift for Schedule Service
    validateTimeLineForLockedSchedule() {
      const startDate = (0, _moment.default)(this.get('startDate'));
      const endDate = (0, _moment.default)(this.get('endDate'));
      const session = this.get('session');
      const isDispatchDashboard = this.get('isDispatchDashboard');
      const filterQuery = isDispatchDashboard ? 'and(eq(type,\'primary\'),eq(status,\'exported\'))' : 'eq(type,\'primary\')';

      if (!_environment.default.APP.avlmLite && session.isAuthenticated) {
        this.store.query('schedule', {
          filter: filterQuery
        }, {
          reload: true
        }).then(SSchedules => {
          this.findCurrentScheduleOrCreate(SSchedules.sortBy('end'));
          this.store.findAll('dispatch-schedule', {
            reload: true
          }).then(schedules => {
            schedules.forEach(schedule => {
              if (schedule.get('locked')) {
                const scheduleStart = (0, _moment.default)(schedule.get('schedule.start'));
                const scheduleEnd = (0, _moment.default)(schedule.get('schedule.end'));

                if (startDate.isBetween(scheduleStart, scheduleEnd) || endDate.isBetween(scheduleStart, scheduleEnd) || scheduleStart.isBetween(startDate, endDate) || scheduleEnd.isBetween(startDate, endDate)) {
                  this.get('notifications').info('The current timeline contains at least one day with a closed schedule. In order to edit data, you must be viewing day(s) where the schedule is not closed.');
                }
              }
            });
          }).then(() => {
            const workspaceContext = this.get('workspaceContext');
            const isScheduleDashboard = this.get('isScheduleDashboard'); // workspace dates change need to refresh the metric widgets

            if (isScheduleDashboard) {
              Ember.run.scheduleOnce('afterRender', workspaceContext, 'refreshMetricWidget');
            }
          });
        });
      }
    },

    isDiagnosticToolShown: Ember.computed('dashboardInstance.widgets.[]', function () {
      const widgets = this.get('dashboardInstance.widgets');
      return widgets.some(widget => widget.get('typeId') === 'scatter-chart');
    }),
    isTimelineSpanMultipleDays: Ember.computed('startDate', 'endDate', function () {
      const startDate = (0, _moment.default)(this.get('startDate'));
      const endDate = (0, _moment.default)(this.get('endDate'));
      const singleDate = (0, _moment.default)(startDate);
      let isMultipleDays = false;

      if (!(startDate.isSameOrAfter(singleDate.startOf('day')) && endDate.isSameOrBefore(singleDate.endOf('day')))) {
        isMultipleDays = true;
      }

      return isMultipleDays;
    }),
    startDate: Ember.computed('dashboard.{startDate,referenceDate}', {
      get()
      /* key */
      {
        const startDate = this.get('dashboard.startDate');
        const referenceDate = this.get('dashboard.referenceDate');
        return (0, _moment.default)().startOf('day').add(startDate - referenceDate, 'ms').toDate();
      },

      set(key, value) {
        const referenceDate = (0, _moment.default)().startOf('day').toDate();
        this.set('dashboard.startDate', value);
        this.set('dashboard.referenceDate', referenceDate);
        return value;
      }

    }),
    endDate: Ember.computed('dashboard.{endDate,referenceDate}', {
      get()
      /* key */
      {
        const endDate = this.get('dashboard.endDate');
        const referenceDate = this.get('dashboard.referenceDate');
        return (0, _moment.default)().startOf('day').add(endDate - referenceDate, 'ms').toDate();
      },

      set(key, value) {
        const referenceDate = (0, _moment.default)().startOf('day').toDate();
        this.set('dashboard.endDate', value);
        this.set('dashboard.referenceDate', referenceDate);
        return value;
      }

    }),
    // for backward compatibility; it's better to use `dashboardInstance` or
    // `dashboardModel` expclitily
    dashboard: Ember.computed.alias('dashboardInstance'),
    // this is a DashboardInstance class; it is decoupled from the model.
    // you can replace this to update local state without persisting
    dashboardInstance: Ember.computed('_dashboardInstance', {
      get() {
        return this.get('_dashboardInstance');
      },

      set(key, value) {
        (true && !(value.constructor.modelName !== 'dashboard') && Ember.assert('cannot set workspace dashboard instance to be dashboard model', value.constructor.modelName !== 'dashboard'));
        this.set('_dashboardInstance', value);
        return value;
      }

    }),
    dashboardModel: Ember.computed('_dashboardModel', {
      get() {
        return this.get('_dashboardModel');
      },

      set(key, value) {
        this.set('_dashboardModel', value);
        this.set('widgetStates', (0, _lodash.cloneDeep)(value.get('widgetStates')));

        if (value) {
          this.resetForDashboardChange();
          this.set('dashboardInstance', value.makeInstance());
        } else {
          this.set('dashboardInstance', null);
        }

        return value;
      }

    }),

    async init() {
      this._super(...arguments);

      this.set('stack', ['default']);
      this.validateTimeLineForLockedSchedule();
    },

    async initUserRecord() {
      const session = this.get('session');
      const permissionLayer = this.get('permissionLayer');
      let result = [];

      if (session.isAuthenticated) {
        const userId = session.data.authenticated.userId;
        localStorage.setItem('currentUserId', userId);
        await this.get('ajaxService').request(`${_apiUrls.API.ssoService.host}/user/${userId}`, {
          contentType: 'application/json',
          dataType: 'text',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        }).then(response => {
          const res = JSON.parse(response);

          if (res && res.basicLogin[0] && res.basicLogin[0].passwordUpdatedAt === null) {
            session.set('isNewUser', 'true');
            return result;
          }

          if (userId) {
            result = this.get('store').findRecord('sso-user', userId);
            session.set('isNewUser', 'false');
            permissionLayer.getPermissions();
            return result;
          }
        });
      }

      return result;
    },

    reset() {
      this.setProperties({
        isLightMode: false,
        size: DEFAULT_SIZE,
        tileSize: DEFAULT_TILE_SIZE
      });
      this.resetForDashboardChange();
    },

    resetForDashboardChange() {
      this.setProperties({
        isDragging: false,
        isResizing: false
      });
      this.set('stack', [{
        state: 'default'
      }]);
    },

    // save temporary dashboard state via filter settings etc...
    saveTempDashboardState() {
      const dashboard = this.get('dashboard');
      const dashboardModel = this.get('dashboardModel');
      dashboardModel.commitInstance(dashboard);
    },

    pushState(newStateId, options = {}, pushOptions = {}) {
      const previousStateId = this.get('topState');
      const previousState = STATE_MACHINE.states.findBy('id', previousStateId);
      logger('covering', previousStateId); // TODO: allow for promise actions

      if (previousState) {
        Ember.makeArray(previousState.hooks).filter(({
          to
        }) => {
          return to === newStateId || to === '*';
        }).forEach(({
          action
        }) => {
          action.call(this, this);
        });
      } // TODO: check if we are allowed to push state based on top state


      const newState = STATE_MACHINE.states.findBy('id', newStateId);
      /*eslint-disable */

      if (!newState) throw `unknown state with id '${newStateId}'`;
      /*eslint-enable */
      // Calculate an "activity id" for tracing related calls for this new state
      //   do this here so we can restore the old id if we're pushing the same state with different options

      const activityIdGuid = (0, _guid.guid)();
      let activityIdValue = `AIQ-CLI-${activityIdGuid}:${newStateId}`; // allow a state to replace itself with new options

      if (newStateId === previousStateId && pushOptions.replaceSelf) {
        activityIdValue = this.get('topActivityId');
        this.popState(previousStateId);
      }

      logger('pushing', newStateId);
      Ember.makeArray(newState.hooks).filter(({
        from
      }) => {
        return from === previousStateId || from === '*';
      }).forEach(({
        action
      }) => {
        action.call(this, this);
      }); // Combine options from state machine with options passed in when
      // calling `pushState`

      Object.assign(options, newState.options);
      this.get('stack').pushObject({
        state: newStateId,
        isSideDrawerVisible: newState.isSideDrawerVisible,
        sideDrawerComponent: newState.sideDrawerComponent,
        options,
        activityId: activityIdValue
      }); // added a new drawer. make sure it is not shrunken

      this.set('isDrawerShrunken', false);
      this.get('ajaxService').setActivityId(activityIdValue);
      return Ember.RSVP.Promise.resolve();
    },

    popState(stateId) {
      if (this.get('stackDepth') === 0) {
        /*eslint-disable */
        throw `cannot pop state: stack is empty`;
        /*eslint-enable */
      }

      const previousStateId = this.get('topState');
      const previousState = STATE_MACHINE.states.findBy('id', previousStateId);

      if (Ember.isPresent(stateId) && stateId !== previousStateId) {
        /*eslint-disable */
        throw `cannot pop state ${stateId}: top state is ${previousStateId}`;
        /*eslint-enable */
      }

      logger('popping', previousStateId);
      this.get('stack').popObject();
      const newStateId = this.get('topState');
      const newState = STATE_MACHINE.states.findBy('id', newStateId); // TODO: allow for promise actions

      Ember.makeArray(previousState.hooks).filter(({
        to
      }) => {
        return to === newStateId || to === '*';
      }).forEach(({
        action
      }) => {
        action.call(this, this);
      });
      logger('uncovering', newStateId); // TODO: allow for promise actions

      if (newState) {
        Ember.makeArray(newState.hooks).filter(({
          from
        }) => {
          return from === previousStateId || from === '*';
        }).forEach(({
          action
        }) => {
          action.call(this, this);
        });
      }

      const activityIdValue = this.get('topActivityId');
      this.get('ajaxService').setActivityId(activityIdValue);
      return Ember.RSVP.Promise.resolve();
    },

    buildPervNextTrip(validBreakTimeInfo) {
      const {
        trips,
        breakEndTime,
        pickKey,
        dropKey,
        plannedEta
      } = validBreakTimeInfo;
      let pervTripDrop, nextTripPick;
      trips.forEach((trip, index) => {
        const pTripDrop = trip.get(dropKey);
        const nextTrip = trips[index + 1];
        const nTripPick = nextTrip ? nextTrip.get(pickKey) : null;
        const isBetweenBreakTime = nTripPick && breakEndTime.isBetween((0, _moment.default)(pTripDrop.get(plannedEta)), (0, _moment.default)(nTripPick.get(plannedEta)));

        if (isBetweenBreakTime && !pervTripDrop && !nextTripPick) {
          pervTripDrop = pTripDrop;
          nextTripPick = nTripPick;
        }
      });
      return {
        pervTripDrop,
        nextTripPick
      };
    },

    isValidBreakTime(validBreakTimeInfo) {
      const {
        breakEndTime,
        nextTravelTime,
        plannedEta
      } = validBreakTimeInfo;
      let validTime = 0;
      const {
        pervTripDrop,
        nextTripPick
      } = this.buildPervNextTrip(validBreakTimeInfo);

      if (pervTripDrop && nextTripPick) {
        const plannedTravelTimeToNext = parseInt(pervTripDrop.get(nextTravelTime) || 0, 10);
        breakEndTime.set('minute', breakEndTime.get('minute') + plannedTravelTimeToNext);
        validTime = nextTripPick.get(plannedEta) - breakEndTime.toDate();
      }

      return validTime >= 0;
    },

    // The break start time has to fall in an empty gap on the route.
    // Empty gap means that there are no passengers on-board in the middle of the trip
    isBreakInMiddleOfTrip(validMiddleBreakInfo) {
      const {
        trips,
        breakTime,
        pickKey,
        dropKey,
        plannedEta
      } = validMiddleBreakInfo;
      let passangerCount = 0;
      let stopPoints = trips.map(t => t.get('stopPoints'));
      stopPoints = (0, _flattenData.flattenData)(stopPoints).map(st => st.record);
      stopPoints.forEach(stopPoint => {
        if (stopPoint.get('isPick')) {
          passangerCount++;
        } else if (stopPoint.get('isDrop')) {
          passangerCount--;
        }
      });
      const isBetweenBreakTime = trips.some(trip => {
        const pickStopPoint = trip.get(pickKey);
        const dropStopPoint = trip.get(dropKey);
        const breakTimeBetween = breakTime.isBetween((0, _moment.default)(pickStopPoint.get(plannedEta)), (0, _moment.default)(dropStopPoint.get(plannedEta)));
        return breakTimeBetween;
      });
      return passangerCount > 0 || isBetweenBreakTime;
    },

    async validaBreakPlace(validPlaceBreakInfo) {
      const {
        breakPlace,
        breakEndTime,
        plannedEta
      } = validPlaceBreakInfo;
      let validTime = 0;
      const {
        pervTripDrop,
        nextTripPick
      } = this.buildPervNextTrip(validPlaceBreakInfo);

      if (pervTripDrop && nextTripPick) {
        const destLngLatList = [{
          lat: pervTripDrop.get('lat'),
          lng: pervTripDrop.get('lng')
        }, {
          lat: breakPlace.get('location.lat'),
          lng: breakPlace.get('location.lng')
        }, {
          lat: nextTripPick.get('lat'),
          lng: nextTripPick.get('lng')
        }];
        const travelTimeInfo = await this.get('geocode').retriveTravelTime(destLngLatList);
        const plannedTravelTimeToNext = Math.round(travelTimeInfo.duration.value / 60);
        breakEndTime.set('minute', breakEndTime.get('minute') + plannedTravelTimeToNext);
        validTime = nextTripPick.get(plannedEta) - breakEndTime.toDate();
      }

      return validTime >= 0;
    }

  });

  _exports.default = _default;
});