define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/19a-review/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'driver-19-a-review',
    title: 'Dates',
    defaultSortId: 'date',
    defaultSortAsc: true,
    fetchModelData: false,
    selectFirstRow: true,
    columns: [{
      id: 'provider',
      index: 0,
      type: 'text',
      label: 'Provider',
      valuePath: 'provider.id',
      defaultWidth: 90,
      searchable: false
    }, {
      id: 'typeOfDate',
      index: 1,
      type: 'text',
      label: 'Type of Date',
      valuePath: 'examTypeName.displayName',
      defaultWidth: 100,
      searchable: false
    }, {
      id: 'date',
      index: 2,
      type: 'date',
      label: 'Date',
      valuePath: 'examDate',
      defaultWidth: 90,
      format: 'MM/DD/YYYY'
    }]
  };
  _exports.default = _default;
});