define("adept-iq/pods/components/iq-widgets/permissions-roles-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "adept-iq/pods/components/iq-widgets/permissions-roles-widget/config", "adept-iq/config/mapped-permIds", "ember-concurrency", "ember-light-table", "papaparse", "moment", "adept-iq/config/api-urls", "lodash"], function (_exports, _component, _config, _mappedPermIds, _emberConcurrency, _emberLightTable, _papaparse, _moment, _apiUrls, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const toPixels = x => `${x}px`;

  var _default = _component.default.extend({
    classNames: ['column-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    workspace: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    config: _config.default,
    isSearchAllowed: true,
    tableActions: null,
    downloadAction: null,
    singleActions: null,
    disableRightClick: Ember.computed.or('workspace.{isExported,reoptimizeInProgress}'),
    checkedItemsCount: Ember.computed.alias('checkedRowsCount'),
    selectedRoles: Ember.computed.alias('permissionsRolesTable.selectedRows.[]'),
    checkedRowsCount: Ember.computed('displayCount', 'permissionsRolesTable.rows.@each.selected', function () {
      const checkedRows = this.get('permissionsRolesTable.rows').filterBy('selected') || [];
      return checkedRows.length;
    }),
    isRowInCheckedRows: Ember.computed('lastRowClicked', 'permissionsRolesTable.rows', function () {
      const lastRowClicked = this.get('lastRowClicked');
      const checkedRows = this.get('permissionsRolesTable.rows').filterBy('isChecked') || [];

      if (!lastRowClicked) {
        return false;
      }

      return checkedRows.some(row => row.get('content.id') === lastRowClicked.get('id'));
    }),
    isFiltered: Ember.computed('permissionsRolesTable.columns.@each.{isFiltered}', function () {
      return this.get('permissionsRolesTable.columns').isAny('isFiltered');
    }),
    onWidgetStateChange: Ember.observer('widget.state', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateConfigColumns');
    }),

    init() {
      this._super(...arguments);

      this.refresh();
      this.setupTable();
      this.setupDefaultTableAction();
      this.configureSearchFilterPermission();
      this.getRoles();
    },

    getUsers() {
      Ember.get(this, 'fetchUsersTask').perform();
    },

    refresh() {
      this.get('refreshTask').perform();
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      yield this.get('refreshPermissionTask').perform();
    }),
    refreshPermissionTask: (0, _emberConcurrency.task)(function* () {
      const permissions = yield this.get('store').findAll('sso-permission');
      const ssoPermissions = (0, _lodash.groupBy)(permissions.toArray(), function (value) {
        return value.category.toLowerCase();
      });
      this.set('ssoPermissions', ssoPermissions);
      const rolePermissions = yield this.get('store').query('role-permission', {});
      const groupedPermissions = (0, _lodash.groupBy)(rolePermissions.toArray(), function (value) {
        return value.category.toLowerCase();
      });
      const columnArray = [{
        id: 'displayName',
        dataType: 'text',
        label: 'Role Name',
        valuePath: 'displayName',
        searchable: true,
        resizable: true,
        width: '90px',
        minResizeWidth: 70,
        hidden: false
      }];
      const permKeys = (0, _lodash.keys)(groupedPermissions) || [];
      this.set('permKeys', permKeys);
      this.set('groupedPermissions', groupedPermissions);
      permKeys.map(item => {
        const label = item.charAt(0).toUpperCase() + item.slice(1) + ' Permissions';
        columnArray.push({
          id: item,
          dataType: 'text',
          label: label,
          valuePath: item,
          searchable: true,
          resizable: true,
          width: '90px',
          minResizeWidth: 90,
          hidden: false
        });
      });
      const permissionsRolesTable = new _emberLightTable.default(columnArray);
      Ember.set(this, 'permissionsRolesTable', permissionsRolesTable);
      this.getRoles();
    }),

    setupSearchableColumns() {
      const PermissionsRolesSearchableColumns = [];
      this.config.columns.map(item => {
        PermissionsRolesSearchableColumns.push(item.valuePath);
      });
      Ember.set(this, 'PermissionsRolesSearchableColumns', PermissionsRolesSearchableColumns);
    },

    setupTable() {
      const permissionsRolesTable = new _emberLightTable.default(this.config.columns);
      Ember.set(this, 'permissionsRolesTable', permissionsRolesTable);
      this.updateColumnsForFilter();
      this.setupSearchableColumns();
    },

    updateConfigColumns() {
      const state = this.get('widget.state') || {};
      const columns = this.get('tableConfigColumns') || [];
      let shouldResize = false;
      const scrollLeft = this.getScrollLeft();
      const columnsHash = state.columns || {};
      Object.entries(columnsHash).forEach(([id, columnState]) => {
        const column = columns.findBy('id', id);

        if (!column) {
          return;
        }

        if (Ember.isPresent(columnState.hidden)) {
          column.set('hidden', columnState.hidden);
          const actualColumn = this.getRoleTableColumn(column);

          if (actualColumn) {
            actualColumn.set('hidden', columnState.hidden);
          }

          shouldResize = true;
        }

        const wasFiltered = column.get('isFiltered');
        ['filterTypeId', 'filterValues', 'filterType'].forEach(prop => {
          // allow null value to overwrite
          if (!columnState.hasOwnProperty(prop)) {
            return;
          }

          column.set(prop, columnState[prop]);
        });

        if (wasFiltered || column.get('isFiltered')) {
          Ember.run.scheduleOnce('afterRender', this, 'refreshRoles');
        }

        if (Ember.isPresent(columnState.width)) {
          column.set('width', toPixels(columnState.width));
          const actualColumn = this.getRoleTableColumn(column);

          if (actualColumn) {
            actualColumn.set('width', toPixels(columnState.width));
          }

          shouldResize = true;
        }
      });

      if (shouldResize) {
        // resize at end of _next_ loop so that all changes are applied
        Ember.run.next(() => {
          if (Ember.isPresent(scrollLeft)) {
            // wait an additional loop before restoring scroll position
            Ember.run.next(() => {
              Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
            });
          }
        });
      }

      Ember.run.debounce(this, 'refreshRoles', 250);
    },

    getRoleTableColumn(configColumn) {
      let column = null;
      const configColumns = this.config.columns;
      const configTableColumn = configColumns.filter(configCol => configCol.valuePath === configColumn.id)[0];

      if (configTableColumn) {
        const tableColumns = Ember.get(this, 'permissionsRolesTable.columns');
        column = tableColumns.filter(col => col.valuePath === configColumn.id)[0];
      }

      return column;
    },

    getScrollLeft() {
      const body = this.$('.column-widget-body');

      if (body) {
        return body.scrollLeft();
      }

      return 0;
    },

    setScrollLeft(scrollLeft) {
      if (!this.$('.column-widget-body')) {
        return;
      }

      this.$('.column-widget-body').scrollLeft(scrollLeft);
    },

    getFilteredColumns(columns) {
      return columns.filter(column => {
        if (!Ember.isEmpty(column.filterType)) {
          if (column.filterType.id === 'empty') {
            return true;
          } else if (!Ember.isEmpty(column.filterValues) && column.filterValues.filter(item => !Ember.isEmpty(item)).length) {
            return true;
          }
        }

        return false;
      });
    },

    refreshRoles() {
      const columns = this.get('tableConfigColumns') || [];
      const tableData = this.get('records') || [];
      const resultModel = [];

      if (columns && columns.length === 1 && columns[0].hidden === true) {
        const rows = [];
        const roleTable = Ember.get(this, 'permissionsRolesTable');
        roleTable.setRowsSynced(rows);
        return;
      }

      if (!Ember.isEmpty(tableData)) {
        const searchColumns = this.getFilteredColumns(columns);
        tableData.map(item => {
          if (Ember.isEmpty(searchColumns)) resultModel.push(item);else {
            let bool = true;
            searchColumns.forEach(column => {
              if (column.filterType && column.filterType.exp && column.filterType.exp(item[column.valuePath], column.filterValues)) return;
              bool = false;
            });
            if (bool) resultModel.push(item);
          }
        });

        if (resultModel) {
          const roleTable = Ember.get(this, 'permissionsRolesTable');
          const rows = [...new Set(resultModel)];
          roleTable.setRowsSynced(rows.toArray());
        }
      }
    },

    updateColumnsForFilter() {
      const columns = [];
      const tableColumns = this.config.columns;

      if (!Ember.isEmpty(tableColumns)) {
        tableColumns.forEach(column => {
          if (!column.unAvailable) {
            const col = Ember.Object.create(column);
            col.set('filterTypes', this.config.filters[column.dataType] || []);
            columns.push(col);
          }
        });
      }

      Ember.set(this, 'tableConfigColumns', columns);
    },

    didInsertElement() {
      this.createContextMenu();
    },

    exportData() {
      const table = this.get('permissionsRolesTable');
      const columns = table.get('columns') || [];
      const rows = table.get('rows') || [];
      const fields = columns.reduce((arr, column) => {
        const id = column.get('id');

        if (['checkbox', 'spacer', 'radio'].includes(id)) {
          return arr;
        }

        const label = column.get('label');
        arr.push(label);
        return arr;
      }, []);
      const data = rows.map(row => {
        return columns.reduce((arr, column) => {
          const id = column.get('id');

          if (['checkbox', 'spacer', 'radio'].includes(id)) {
            return arr;
          }

          const valuePath = column.get('valuePath');
          const value = row.get(valuePath);
          arr.push(value);
          return arr;
        }, []);
      });

      const csv = _papaparse.default.unparse({
        fields,
        data
      }, {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"'
      });

      const link = document.createElement('a');
      const timestamp = (0, _moment.default)().format('YYYY-MM-DD_HH-MM-SS');
      const {
        title
      } = this.get('config');
      const filename = `${timestamp}_${title}.csv`;
      const blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      });
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    setupDefaultTableAction() {
      const downloadAction = {
        id: 'print',
        permIds: [_mappedPermIds.default.downloadRoles],
        name: 'Download',
        action: () => {
          this.exportData();
        }
      };
      this.set('downloadAction', downloadAction); // eslint-disable-next-line new-cap

      this.set('tableActions', Ember.A([downloadAction]));
    },

    configureSearchFilterPermission() {
      const configPermissions = this.get('workspace.configPermissions');
      const widgetTypeId = this.get('widget.typeId');

      if (!Ember.isEmpty(configPermissions)) {
        const configPermission = configPermissions[widgetTypeId];

        if (!Ember.isEmpty(configPermission.searchAllowed)) {
          this.set('isSearchAllowed', configPermission.searchAllowed);
        }

        if (!Ember.isEmpty(configPermission.filterAllowed)) {
          this.set('isFilterAllowed', configPermission.filterAllowed);
        }
      }
    },

    fetchUsersTask: (0, _emberConcurrency.task)(function* () {
      const users = [];
      const usersData = yield this.get('store').query('perm-sso-user', {});

      if (!Ember.isEmpty(usersData)) {
        usersData.map(user => {
          users.push(user.roles);
        });
        Ember.set(this, 'usersRecords', users);
      }
    }).restartable(),
    deleteTask: (0, _emberConcurrency.task)(function* () {
      // using custom api call because sso api is non standard
      // and in-consistent.
      const ajax = this.get('ajax');
      const session = this.get('session');
      const role = this.get('lastRowClicked') || [];
      return yield ajax.request(`${_apiUrls.API.ssoService.host}/role/${role.roleName}`, {
        contentType: 'application/json',
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
    }),

    createContextMenu() {
      this.set('tableActions', this.createTableActions());
      this.set('singleActions', this.createSingleActions());
    },

    getRoles() {
      Ember.get(this, 'fetchRolesTask').perform();
    },

    unloadRole(id) {
      const record = this.store.peekRecord('perm-sso-role', id);
      this.store.unloadRecord(record);
    },

    unloadSelectedRole() {
      this.selectedRoles.map(roleRow => {
        this.unloadRole(roleRow.content.id);
      });
    },

    contextMenuHandler(optionId, isSaved) {
      if (isSaved) {
        this.unloadSelectedRole();
        this.refresh();
        this.getRoles();
        this.getUsers();
      }

      if (!optionId) {
        return;
      }
    },

    createTableActions() {
      return [{
        id: 'newRole',
        permId: _mappedPermIds.default.newRole,
        name: 'Add Role',
        action: function () {
          const newModel = this.get('store').createRecord(this.get('config.modelName'), {
            permissionList: []
          });
          this.get('workspace').pushState('permAddRole', {
            displayName: 'Add Role',
            roles: [newModel],
            roleData: this.get('records'),
            action: this.contextMenuHandler.bind(this, 'permAddRole')
          }, {
            replaceSelf: true
          });
        }.bind(this)
      }, {
        id: 'print',
        permIds: _mappedPermIds.default.downloadUsers,
        name: 'Download CSV',
        action: () => {
          this.exportData();
        }
      }];
    },

    isLoggedInUserSelected() {
      let isExists = false;
      this.selectedUsers.map(user => {
        if (user.content.id === this.session.data.authenticated.userId) {
          isExists = true;
        }
      });
      return isExists;
    },

    deleteUserCallback() {
      this.getUsers(); // this.handleUserLogout();
    },

    handleUserLogout() {
      if (this.isLoggedInUserSelected()) {
        this.get('session').invalidate();
      }
    },

    createSingleActions() {
      return [{
        id: 'edit',
        permId: _mappedPermIds.default.editRole,
        name: 'Edit Role',
        action: async function (model) {
          const session = this.get('session');
          let permissionList = [];
          const roleName = model.get('roleName');

          if (roleName) {
            permissionList = await this.get('ajax').request(`${_apiUrls.API.ssoService.host}/role/${roleName}/role-permission/`, {
              method: 'GET',
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${session.data.authenticated.token}`
              }
            }).catch(() => []);
          }

          permissionList = permissionList.map(perm => perm.permissionId);
          model.set('permissionList', permissionList);
          this.get('workspace').pushState('permAddRole', {
            displayName: 'Edit Role',
            roles: [model],
            roleData: this.get('records'),
            action: this.contextMenuHandler.bind(this, 'permAddRole')
          }, {
            replaceSelf: true
          });
        }.bind(this)
      }, {
        id: 'delete',
        permId: _mappedPermIds.default.deleteRole,
        name: 'Delete Role',
        action: function () {
          const tooltip = this.get('tooltip');
          const role = this.get('lastRowClicked') || [];
          const users = [];
          this.get('usersRecords').map(data => {
            data.map(item => {
              if (item.roleName === role.roleName) {
                users.push(item);
              }
            });
          });
          tooltip.pushConfirmation({
            tip: 'There are ' + users.length + ' users assigned to "' + role.displayName + '" role. Are you sure you want to delete?',
            title: 'Deleting Role',
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              this.get('deleteTask').perform().then(() => {
                this.unloadSelectedRole();
                this.getRoles();
                this.deleteUserCallback();
                this.get('notifications').success('DELETED ROLE SUCCESSFULLY');
                tooltip.reset();
                return;
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: () => {
              tooltip.reset();
            }
          });
        }.bind(this)
      }];
    },

    fetchRolesTask: (0, _emberConcurrency.task)(function* () {
      const roles = [];
      const rolesData = yield this.get('store').query('perm-sso-role', {});
      Ember.set(this, 'records', roles);
      Ember.get(this, 'permissionsRolesTable').setRows(roles);

      if (!Ember.isEmpty(rolesData)) {
        rolesData.map(role => {
          if (this.get('permKeys')) {
            this.get('permKeys').map(item => {
              Object.entries(this.get('groupedPermissions')).forEach(([modelName, data]) => {
                let count = 0;
                Object.entries(this.get('ssoPermissions')).forEach(([model, val]) => {
                  if (model === item) {
                    count = val.length;
                  }
                });

                if (modelName === item) {
                  const rolesByRoleID = (0, _lodash.groupBy)(data, 'roleId');
                  const recordLength = `(${rolesByRoleID[role.roleName] ? rolesByRoleID[role.roleName].length : 0}/${count})`;
                  role.set(item, recordLength);
                }
              });
            });
          }

          roles.push(role);
        });
        Ember.set(this, 'records', roles);
        Ember.get(this, 'permissionsRolesTable').setRowsSynced(roles.toArray());
      }
    }).restartable(),

    filterTableData(searchText) {
      const tableName = this.get('permissionsRolesTable');

      if (!Ember.isEmpty(tableName)) {
        const table = tableName;
        const tableColumns = Ember.get(this, 'PermissionsRolesSearchableColumns');
        const search = searchText !== null ? searchText.trim().toLowerCase().split('=') : [];
        const resultModel = [];
        this.get('records').map(item => {
          if (Ember.isEmpty(searchText)) resultModel.push(item);

          if (search && search.length > 1 && search[1] && search[1] !== '' && tableColumns.join(',').toLowerCase().includes(search[0].trim().toLowerCase().replace(' ', ''))) {
            const colm = tableColumns.map(column => column.toLowerCase());
            const index = colm.indexOf(search[0].trim().toLowerCase().replace(' ', ''));
            if (index !== -1 && item[tableColumns[index]] && item[tableColumns[index]].toString().toLowerCase().indexOf(search[1].trim().toLowerCase()) !== -1) resultModel.push(item);
          } else if (search.length === 1) tableColumns.filter(column => {
            if (item[column] && item[column].toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
              resultModel.push(item);
              return;
            }
          });
        });

        if (resultModel) {
          const rows = [...new Set(resultModel)];
          table.setRowsSynced(rows.toArray());
        }
      }
    },

    actions: {
      onScroll() {},

      onCheckAll() {},

      onUncheckAll() {},

      onColumnClick() {},

      onRowClick(row) {
        this.set('lastRowClicked', row.get('content'));
        const rows = this.get('permissionsRolesTable.rows.content');
        rows.forEach(_row => {
          if (_row.get('id') === row.get('id')) {
            if (_row.get('selected')) {
              _row.set('selected', false);

              _row.set('isChecked', false);
            } else {
              _row.set('selected', true);

              _row.set('isChecked', true);
            }
          } else {
            _row.set('selected', false);

            _row.set('isChecked', false);
          }
        });
      },

      onRowRightClick(row, event) {
        this.getUsers();
        const disableRightClick = this.get('disableRightClick');
        if (disableRightClick) return; // disable right row click for iq-widgets when a user runs schedule generation or release routes.

        this.set('inlineRowMenuPosition', () => {
          return {
            style: {
              top: event.clientY,
              left: event.clientX
            }
          };
        });
        this.set('lastRowClicked', row.get('content'));
        this.inlineRowMenu.actions.open();
      },

      setInlineRowMenu(dropdown) {
        this.set('inlineRowMenu', dropdown);
      },

      setPlusIconMenu(dropdown) {
        this.set('plusIconMenu', dropdown);
      },

      onSearchTextChange(searchText) {
        Ember.run.debounce(this, 'filterTableData', searchText, 300);
      },

      onSearchButtonClick() {
        this.send('onSearchTextChange', '');
        this.toggleProperty('isSearchEnabled');
        this.set('displayCount', true);
        Ember.run.schedule('afterRender', () => {
          if (this.get('isSearchEnabled')) {
            this.$('.column-widget-search-box').focus();
          }
        });
      },

      onRemoveSearchClick() {
        this.send('onSearchTextChange', '');
        this.toggleProperty('isSearchEnabled');
        this.set('displayCount', false);
        this.$('.column-widget-search-button').focus();
      },

      onSingleRowAction(action) {
        const model = this.get('lastRowClicked');
        this.get('inlineRowMenu').actions.close();

        if (action.action) {
          action.action(model);
        }
      },

      onPlusIconClick() {
        const disableRightClick = this.get('workspace.reoptimizeInProgress');
        return !disableRightClick;
      },

      onTableActionClick(action, dropdown) {
        dropdown.actions.close();

        if (action.action) {
          action.action();
        }
      },

      onFilterButtonClick() {
        const columns = this.get('tableConfigColumns') || [];
        const workspace = this.get('workspace');
        const widget = this.get('widget');
        const displayName = `${this.config.title} Filters`; // close sidebar if _this_ widget's filter is currently open
        // @TODO: define and use unique keys rather than `displayName` for this

        if (displayName === workspace.get('topStateDisplayName')) {
          workspace.popState();
          return;
        }

        let topState = workspace.get('topState');

        while (topState === 'permissionsFilterColumnWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        workspace.pushState('permissionsFilterColumnWidget', {
          columns,
          widget,
          displayName
        });
      },

      onHeaderDoubleClick() {
        const maximizer = this.get('maximizer');

        if (maximizer.maximizedWidget === this.get('widget')) {
          maximizer.minimize();
          return;
        }

        maximizer.maximize(this.get('widget'));
      },

      doubleClick(event) {
        event.preventDefault();

        if (event.target.tagName === 'INPUT' && event.target.type === 'checkbox') {
          // allow user to check another row without moving selection
          return;
        }

        const ltCell = event.target.closest('.lt-cell');

        if (ltCell) {
          const row = ltCell.closest('.lt-row');
          const index = Array.from(row.children).indexOf(ltCell);
          const recordId = row.getAttribute('data-record-id');
          const tableRows = this.get('permissionsUsersTable.rows') || [];
          const clickedColumn = this.get('permissionsUsersTable.columns')[index];
          const clickedRow = tableRows.findBy('content.id', recordId);
          tableRows.setEach('selected', false);
          const scheduleLocked = clickedRow.get('record.scheduleLocked');

          if (!scheduleLocked || Ember.isNone(scheduleLocked)) {
            clickedRow.set('isEditing', true);
            clickedColumn.set('isEditing', true);
          }
        }
      }

    }
  });

  _exports.default = _default;
});