define("adept-iq/pods/components/dashboard-edit-as/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_NAME = 'Untitled';

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    classNames: ['dashboard-save-as'],
    name: null,
    displayName: Ember.computed.readOnly('workspace.topOptions.displayName'),
    workspaceName: Ember.computed.readOnly('workspace.topOptions.workspaceName'),
    isLoading: Ember.computed.oneWay('findUnusedNameTask.isRunning'),
    isRunning: Ember.computed.or('saveTask.isRunning', 'cancelTask.isRunning'),

    didInsertElement() {
      this._super(...arguments);

      this.get('findUnusedNameTask').perform().then(name => {
        this.set('name', name);
        Ember.run.schedule('afterRender', () => {
          this.$('.workspace-name-input').focus();
        });
      });

      this._onKeyDown = event => {
        Ember.run.schedule('afterRender', this, 'onKeyDown', event);
      };

      this.$().on('keydown', this._onKeyDown);
    },

    willDestroyElement() {
      this.$().off('keydown', this._onKeyDown);
      this._onKeyDown = null;

      this._super(...arguments);
    },

    findUnusedNameTask: (0, _emberConcurrency.task)(function* () {
      let baseName = this.get('workspace.dashboard.name');
      let version = 0;

      if (Ember.isPresent(baseName)) {
        // extract base name and version from names like "My Dashboard (3)"
        const regEx = /^(.*)\s+\((\d+)\)\s*$/;
        const matches = regEx.exec(baseName);

        if (matches) {
          baseName = matches[1];
          version = parseInt(matches[2], 10) + 1;
        }
      } else {
        baseName = DEFAULT_NAME;
      }

      const dashboards = yield this.get('store').findAll('dashboard');
      let name = version === 0 ? baseName : `${baseName} (${version})`;

      while (dashboards.findBy('name', name)) {
        version += 1;
        name = `${baseName} (${version})`;
      }

      return name;
    }).drop(),
    cancelTask: (0, _emberConcurrency.task)(function* () {
      yield this.get('workspace').popState('editWorkspaceAs');
    }).drop(),

    onKeyDown(event) {
      switch (event.key) {
        case 'Enter':
          {
            event.preventDefault();
            return this.get('saveTask').perform();
          }

        default:
          return null;
      }
    },

    actions: {
      onSaveClick() {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          tip: 'Would you like to Commit your changes',
          title: 'Workspace',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            if (this.get('isError')) {
              this.get('notifications').warning(`Failed to add driver break to route ${this.get('driverBreak.route.name')}`);
              this.onCloseClick();
              tooltip.reset();
              return false;
            }

            return tooltip.reset().finally(() => {
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            tooltip.reset();
            return;
          }
        });
      }

    }
  });

  _exports.default = _default;
});