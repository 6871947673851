define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/road-call/road-call-validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VALIDATIONS = void 0;
  const VALIDATIONS = {
    callDate: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Call Date',
      message: '{description} is required'
    })],
    roadCallCodeName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Code',
      message: '{description} is required'
    })] // Enable this validation when we will start getting Driver CDL number
    // driverCdlNumber: [
    //   validatePresence({
    //     presence: true,
    //     description: 'Driver CDL Number',
    //     message: '{description} is required'
    //   })
    // ]

  };
  _exports.VALIDATIONS = VALIDATIONS;
});