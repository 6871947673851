define("adept-iq/pods/components/iq-widgets/breakdown-replacement-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'route.name',
        editable: false
      }]
    }, {
      title: 'Add Replacement Route',
      fields: [{
        id: 'routeId',
        type: 'number',
        label: 'Route ID',
        valuePath: 'replacementRouteId'
      }, {
        id: 'routeType',
        type: 'enum',
        label: 'Route Type',
        valuePath: 'replacementRouteType',
        cellDesc: 'anchor',
        editable: true,
        hidden: false,
        readonlyCell: true,
        extra: {
          options: ['supervisor', 'rescue replacement', 'breakdown replacement'],
          allowMultiple: false
        }
      }, {
        id: 'breakdownPlace',
        type: 'breakdown-location',
        label: 'Breakdown Location',
        valuePath: 'breakdownPlace',
        modelName: 'place',
        isMultiType: true,
        extra: {
          aliasPath: 'address.content.alias',
          notesPath: 'address.content.notes',
          premisePath: 'address.content.premise',
          streetNumberPath: 'address.content.streetNumber',
          streetNamePath: 'address.content.streetName',
          localityPath: 'address.content.locality',
          regionPath: 'address.content.region',
          subRegionPath: 'address.content.subRegion',
          postalCodePath: 'address.content.postalCode',
          countryPath: 'address.content.country',
          tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
          useOptionRecord: true,
          streetAddressPath: 'address.content.streetAddress',
          locationsPath: 'address.locations.content.firstObject.latlng',
          freeformAddressPath: 'address.locations.freeformAddress'
        }
      }, {
        id: 'breakdownTime',
        type: 'time',
        label: 'Breakdown Time',
        readonlyCell: true,
        valuePath: 'breakdownTime'
      }, {
        id: 'replacementStartTime',
        type: 'datetimeflatpickr',
        label: 'Set Start Time',
        format: 'Y-m-d h:i K',
        valuePath: 'replacementStartTime'
      }, {
        id: 'replacementEndTime',
        type: 'datetimeflatpickr',
        label: 'Set End Time',
        format: 'Y-m-d h:i K',
        valuePath: 'replacementEndTime'
      }]
    }, {
      title: 'Assign Driver',
      fields: [{
        id: 'replacementDriver',
        type: 'driverenum',
        label: 'Driver ID',
        valuePath: 'replacementDriver',
        cellDesc: 'replacementDriver',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'driver',
          optionSearchPath: 'driverId',
          onlyAssignableDrivers: true
        }
      }, {
        id: 'driverName',
        type: 'text',
        label: 'Driver Name',
        readonlyCell: true,
        valuePath: 'replacementDriver.fullName'
      }, {
        id: 'shiftStartTime',
        type: 'text',
        label: 'Shift Start',
        readonlyCell: true,
        valuePath: 'replacementDriver.currentDriverAvailability.shiftStart'
      }, {
        id: 'shiftEndTime',
        type: 'text',
        label: 'Shift End',
        readonlyCell: true,
        valuePath: 'replacementDriver.currentDriverAvailability.shiftEnd'
      }]
    }, {
      title: 'Assign Vehicle',
      fields: [{
        id: 'replacementVehicle',
        type: 'vehicle-selection',
        label: '',
        valuePath: 'selectedVehicle',
        modelName: 'vehicle',
        isMultiType: true,
        extra: {
          refreshFlagPath: 'vehicleSelectionRefreshFlag',
          selectedVehiclePath: 'replacementVehicle'
        }
      }]
    }]
  };
  _exports.default = _default;
});