define("adept-iq/pods/components/side-drawer/scheduling-trip-transfer/component", ["exports", "ember-light-table", "ember-concurrency", "lodash", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/side-drawer/scheduling-trip-transfer/config", "adept-iq/mixins/async-schedule-operation"], function (_exports, _emberLightTable, _emberConcurrency, _lodash, _moment, _unwrapProxy, _config, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    // passed by the side drawer container component
    onShrinkClick: null,
    onCloseClick: null,
    currentState: null,
    solutionPreviewTable: null,
    isBrokerProvider: false,
    routeOverrideOptions: null,
    overrideSelectedRoute: null,
    overrideSelectedProvider: null,
    overrideSelectedPickOrder: null,
    overrideSelectedDropOrder: null,
    errorText: null,
    title: 'Transfer Trip',
    rowComponent: 'table/rows/colored-row',
    canShrink: Ember.computed.not('isSolutionsTaskRunning'),
    tripStops: null,
    //passed  by the workspace service
    trip: Ember.computed.readOnly('stackFrame.options.trip'),
    //Trips could be an array sometimes
    canSave: Ember.computed('overrideSelectedProvider', 'overrideSelectedPickOrder', 'overrideSelectedDropOrder', function () {
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
      const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');

      if (overrideSelectedProvider && overrideSelectedProvider.get('isBrokerProviderType') || overrideSelectedPickOrder && overrideSelectedDropOrder) {
        return true;
      }

      return false;
    }),
    currentRoute: Ember.computed('trip.route', function () {
      let route = '';
      route = this.get('trip.route');

      if (!route) {
        return this.get('noRoute');
      }

      return route;
    }).readOnly(),

    async populateRoutesList(scheduleId) {
      const scheduleRecord = await this.get('store').findRecord('schedule', scheduleId, {
        include: 'routes'
      });
      const routes = scheduleRecord.get('routes').toArray();
      return routes;
    },

    overrideRouteOptions: Ember.computed('currentRoute', 'overrideSelectedProvider', async function () {
      const overrideSelectedProvider = this.get('overrideSelectedProvider');
      const sourceRouteId = this.get('currentRoute.id');
      let sourceTripScheduleId = '';
      sourceTripScheduleId = this.get('currentRoute.schedule.id');
      const routes = await this.populateRoutesList(sourceTripScheduleId);

      if (Ember.isNone(routes)) {
        return [];
      }

      return (0, _lodash.sortBy)(routes.filter(route => {
        const routeScheduleId = route.get('schedule.id');
        const routeId = route.get('id');
        let sameProvider = false;

        if (!(0, _lodash.isEmpty)(overrideSelectedProvider)) {
          sameProvider = overrideSelectedProvider.id === route.get('provider.id');
        }

        const sameSchedule = routeScheduleId === sourceTripScheduleId;
        const differentId = routeId !== sourceRouteId;
        return sameSchedule && differentId && sameProvider;
      }), route => route.name);
    }),
    overridePickOrderOptions: Ember.computed('overrideSelectedRoute', function () {
      const trips = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.trips'));
      let routeOrdinals = [];

      if (!(0, _lodash.isEmpty)(trips)) {
        trips.forEach(trip => {
          const pick = (0, _unwrapProxy.unwrapProxy)(trip.get('pick'));
          const drop = (0, _unwrapProxy.unwrapProxy)(trip.get('drop'));
          routeOrdinals.push(pick.get('plannedRouteOrdinal'), drop.get('plannedRouteOrdinal'));
        });
        routeOrdinals = (0, _lodash.orderBy)(routeOrdinals, ordinal => {
          return ordinal;
        }, ['asc']);

        if (routeOrdinals.length === 0) {
          routeOrdinals.push(1);
        } else if (routeOrdinals.length > 0) {
          routeOrdinals.push(Math.max(...routeOrdinals) + 1);
        }

        return routeOrdinals;
      }

      return routeOrdinals;
    }),
    overrideDropOrderOptions: Ember.computed('overrideSelectedPickOrder', function () {
      const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
      const trips = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.trips'));
      let routeOrdinals = [];

      if (overrideSelectedPickOrder && !(0, _lodash.isEmpty)(trips)) {
        trips.forEach(trip => {
          const drop = (0, _unwrapProxy.unwrapProxy)(trip.get('drop'));
          const pick = (0, _unwrapProxy.unwrapProxy)(trip.get('pick'));
          routeOrdinals.push(drop.get('plannedRouteOrdinal'), pick.get('plannedRouteOrdinal'));
        });
        routeOrdinals = (0, _lodash.orderBy)(routeOrdinals, ordinal => {
          return ordinal;
        }, ['asc']);
        routeOrdinals = routeOrdinals.filter(ordinal => {
          if (ordinal > overrideSelectedPickOrder) {
            return ordinal;
          }
        });

        if (routeOrdinals.length > 0) {
          routeOrdinals.push(Math.max(...routeOrdinals) + 1);
        }

        if (routeOrdinals.length === 0) {
          return [overrideSelectedPickOrder + 1];
        }

        return routeOrdinals;
      }

      return routeOrdinals;
    }),
    overrideProviderOptions: Ember.computed('', function () {
      return this.get('store').peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider');
      });
    }),

    init() {
      this._super(...arguments);

      const solutionPreviewTable = new _emberLightTable.default(_config.solutionPreviewColumns);
      this.set('solutionPreviewTable', solutionPreviewTable);
    },

    didInsertElement() {
      this._super(...arguments); // side panel width just for trip transfer


      Ember.$('.side-drawer-modal').css('min-width', '600px');
      this.loadCurrentState();
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('overrideSelectedProvider', currentState.overrideSelectedProvider);
        this.set('overrideSelectedRoute', currentState.overrideSelectedRoute);
        this.set('overrideSelectedPickOrder', currentState.overrideSelectedPickOrder);
        this.set('overrideSelectedDropOrder', currentState.overrideSelectedDropOrder);
        this.set('solutionPreviewTable', currentState.solutionPreviewTable);
      }
    },

    onTransferTrip: (0, _emberConcurrency.task)(function* () {
      const schedule = this.get('overrideSelectedRoute.schedule');
      const routeId = this.get('overrideSelectedRoute.id');
      const tripId = this.get('trip.id');
      const pick = this.calculatePick();
      const {
        dropTime
      } = this.calculateDrop();
      let result;

      try {
        const operationData = {
          tripId,
          routeId,
          pickTime: pick.format(),
          dropTime: dropTime.format()
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripTransfer', options);

        if (!result) {
          throw new Error('Failed to transfer');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),
    waitListTrip: (0, _emberConcurrency.task)(function* () {
      const schedule = (0, _unwrapProxy.unwrapProxy)(this.get('trip.route.schedule'));
      const tripId = parseInt(this.get('trip.id'), 10);
      let result;

      try {
        const operationData = {
          tripId
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripWaitlist', options);

        if (!result) {
          throw new Error('Unable to return trip to original route');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),

    resetSolutionTableData() {
      // reset stops table data
      this.set('solutionPreviewTable.rows', []);
    },

    calculateDrop() {
      const tripList = this.get('tripStops');
      const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
      let dropStop, previousStop, previousStopIndex, dropStopIndex;
      const currentTrip = this.get('trip');

      if ((0, _lodash.isEmpty)(tripList)) {
        return {
          dropTime: (0, _moment.default)(currentTrip.get('drop.plannedEta')),
          previousStopIndex: 0,
          dropStopIndex: 1
        };
      }

      for (let i = 0; i < tripList.length; i++) {
        const trip = tripList[i];

        if (trip.plannedRouteOrdinal >= overrideSelectedDropOrder) {
          dropStopIndex = i;
          previousStopIndex = i - 1;
          previousStop = tripList[i - 1];
          dropStop = trip;
          break;
        }
      }

      if ((0, _lodash.isEmpty)(previousStop)) {
        return {
          dropTime: (0, _moment.default)(currentTrip.get('drop.plannedEta')),
          previousStopIndex: 0,
          dropStopIndex: 1
        };
      }

      const plannedEtaForTripStop = (0, _moment.default)(previousStop.plannedEta);
      const plannedEtaForpickStop = (0, _moment.default)(dropStop.plannedEta);
      const differencePlannedEta = Math.abs(plannedEtaForTripStop.diff(plannedEtaForpickStop, 'minutes')) / 2;
      plannedEtaForTripStop.add(differencePlannedEta, 'minutes');
      return {
        dropTime: (0, _moment.default)(plannedEtaForTripStop),
        previousStopIndex,
        dropStopIndex
      };
    },

    calculatePick() {
      const tripList = this.get('tripStops');
      const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
      const currentTrip = this.get('trip');
      let pickStop, previousStop;

      if ((0, _lodash.isEmpty)(tripList)) {
        return (0, _moment.default)(currentTrip.get('pick.plannedEta'));
      }

      for (let i = 0; i < tripList.length; i++) {
        const trip = tripList[i];

        if (trip.plannedRouteOrdinal >= overrideSelectedPickOrder) {
          previousStop = tripList[i - 1];
          pickStop = trip;
          break;
        }
      }

      if ((0, _lodash.isEmpty)(previousStop)) {
        return (0, _moment.default)(currentTrip.get('pick.plannedEta'));
      }

      const plannedEtaForTripStop = (0, _moment.default)(previousStop.plannedEta);
      const plannedEtaForpickStop = (0, _moment.default)(pickStop.plannedEta);
      const differencePlannedEta = Math.abs(plannedEtaForTripStop.diff(plannedEtaForpickStop, 'minutes')) / 2;
      plannedEtaForTripStop.add(differencePlannedEta, 'minutes');
      return (0, _moment.default)(plannedEtaForTripStop);
    },

    getPickAndDropDataForSolutionPreview(trip, tripList) {
      const drop = (0, _unwrapProxy.unwrapProxy)(trip.get('drop'));
      const pick = (0, _unwrapProxy.unwrapProxy)(trip.get('pick'));
      const rider = (0, _unwrapProxy.unwrapProxy)(trip.get('rider'));
      const pickData = {
        tripId: trip.get('tripId'),
        plannedRouteOrdinal: pick.get('plannedRouteOrdinal'),
        plannedEta: pick.get('plannedEta'),
        stopType: 'pick',
        computedPromiseTime: trip.get('promiseTime'),
        lastName: rider ? rider.get('lastName') : '',
        simpleAddress: trip.get('pick.segmentStop.place.address.simpleAddress'),
        locality: trip.get('pick.segmentStop.place.address.locality')
      };
      const dropData = {
        tripId: trip.get('tripId'),
        plannedRouteOrdinal: drop.get('plannedRouteOrdinal'),
        plannedEta: drop.get('plannedEta'),
        stopType: 'drop',
        computedPromiseTime: trip.get('promiseTime'),
        lastName: rider ? rider.get('lastName') : '',
        simpleAddress: trip.get('drop.segmentStop.place.address.simpleAddress'),
        locality: trip.get('drop.segmentStop.place.address.locality')
      };
      tripList.push(pickData, dropData);
    },

    setupSolutionPreview() {
      const trips = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.trips'));
      const routeBreaks = (0, _unwrapProxy.unwrapProxy)(this.get('overrideSelectedRoute.routeBreaks')) || [];
      let tripList = [];
      const currentTrip = this.get('trip');
      const currentTripRider = (0, _unwrapProxy.unwrapProxy)(currentTrip.get('rider'));
      const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
      const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
      trips.forEach(trip => {
        this.getPickAndDropDataForSolutionPreview(trip, tripList);
      });
      tripList = (0, _lodash.orderBy)(tripList, trip => {
        return trip.plannedRouteOrdinal;
      }, ['asc']);
      this.set('tripStops', tripList);
      const convertPickOrdinalToTimeStamp = this.calculatePick();
      const pickStop = (0, _lodash.findIndex)(tripList, stop => stop.plannedRouteOrdinal === overrideSelectedPickOrder);
      const newpickOne = {
        tripId: currentTrip.get('tripId'),
        plannedRouteOrdinal: overrideSelectedPickOrder,
        plannedEta: convertPickOrdinalToTimeStamp,
        stopType: 'pick',
        computedPromiseTime: currentTrip.get('promiseTime'),
        computedApptTime: currentTrip.get('segment.anchor') === 'drop' ? currentTrip.get('segment.leg.requestTime') : '',
        lastName: currentTripRider.get('lastName'),
        simpleAddress: currentTrip.get('pick.segmentStop.place.address.simpleAddress'),
        locality: currentTrip.get('pick.segmentStop.place.address.locality')
      };

      if (pickStop !== -1) {
        tripList.splice(pickStop, 0, newpickOne);
      } else {
        tripList.push(newpickOne);
      }

      this.changeOtherPlannedRouted(tripList, overrideSelectedPickOrder);
      this.set('tripStops', tripList);
      const {
        dropTime,
        dropStopIndex
      } = this.calculateDrop();
      const newDropOne = {
        tripId: currentTrip.get('tripId'),
        plannedRouteOrdinal: overrideSelectedDropOrder,
        plannedEta: dropTime,
        stopType: 'drop',
        computedPromiseTime: currentTrip.get('promiseTime'),
        computedApptTime: currentTrip.get('segment.anchor') === 'drop' ? currentTrip.get('segment.leg.requestTime') : '',
        lastName: currentTripRider.get('lastName'),
        simpleAddress: currentTrip.get('drop.segmentStop.place.address.simpleAddress'),
        locality: currentTrip.get('drop.segmentStop.place.address.locality')
      };
      routeBreaks.forEach(routeBreak => {
        tripList.push({
          stopType: routeBreak.get('type'),
          plannedEta: routeBreak.get('plannedEta')
        });
      });
      tripList.splice(dropStopIndex, 0, newDropOne);
      this.changeOtherPlannedRouted(tripList, overrideSelectedDropOrder);
      this.get('solutionPreviewTable').setRows(tripList);
      this.setStylesForPickAndDropRows(tripList);
    },

    getSortedTripsByRouteOrdinal(trips) {
      return (0, _lodash.orderBy)(trips, trip => {
        return trip.get('plannedRouteOrdinal');
      }, ['asc']);
    },

    changeOtherPlannedRouted(trips, order) {
      let isRouteOrdinalChange = false;
      trips.forEach((trip, index) => {
        if (isRouteOrdinalChange) {
          const nextTripStop = trips[index + 1];
          trip.plannedRouteOrdinal = nextTripStop ? nextTripStop.plannedRouteOrdinal : trip.plannedRouteOrdinal + 1;
        }

        if (trip.plannedRouteOrdinal === order) {
          isRouteOrdinalChange = true;
          return false;
        }
      });
    },

    buildPickAndDrops(trips, pickAndDropTrips) {
      trips.forEach(trip => {
        const drop = (0, _unwrapProxy.unwrapProxy)(trip.get('drop'));
        const pick = (0, _unwrapProxy.unwrapProxy)(trip.get('pick'));
        pickAndDropTrips.push(drop, pick);
      });
    },

    setStylesForPickAndDropRows(tripList) {
      const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
      const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
      const pick = (0, _lodash.find)(tripList, stop => stop.plannedRouteOrdinal === overrideSelectedPickOrder);
      const drop = (0, _lodash.find)(tripList, stop => stop.plannedRouteOrdinal === overrideSelectedDropOrder);
      pick.selectedOrdinalRow = true;
      drop.selectedOrdinalRow = true;
    },

    onTransferTripSave() {
      const overrideSelectedRoute = this.get('overrideSelectedRoute');
      let tip = '';
      let successMsg = '';
      let errorMsg = '';
      const title = 'Transfer Trip';
      const tripId = this.get('trip.tripId');
      const tooltip = this.get('tooltip');
      tip = `Are you sure you want to transfer trip ${tripId}?`;
      successMsg = `Trip ${tripId} was successfully transferred to route ${overrideSelectedRoute.get('name')} `;
      errorMsg = `Failed to transfer Trip ${tripId}`;
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('onTransferTrip').perform().then(() => {
            this.get('notifications').success(successMsg);
            return this.onCloseClick();
          }).catch(err => {
            // eslint-disable-next-line no-console
            console.warn(err);
            this.get('notifications').warning(errorMsg);
          }).finally(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        },
        hasOverlay: true
      });
    },

    onBrokerTypeTransferTripSave() {
      const tripId = this.get('trip.tripId');
      const tripInternalId = this.get('trip.id');
      const overrideSelectedProviderName = this.get('overrideSelectedProvider.name');
      const successMsg = `Trip ${tripId} successfully placed on waitlist`;
      const errorMsg = 'Unable to place trip on waitlist.';
      let tip = '';
      const title = 'Transfer Trip';
      const tooltip = this.get('tooltip');
      tip = `Are you sure you want to transfer trip ${tripId}?`;
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('waitListTrip').perform().then(async () => {
            const trip = await this.get('store').peekRecord('trip', tripInternalId);
            trip.set('currentProviderName', overrideSelectedProviderName);
            trip.set('status', '');
            await trip.save();
            this.get('notifications').success(successMsg);
            this.onCloseClick();
          }).catch(err => {
            // eslint-disable-next-line no-console
            console.warn(err);
            this.get('notifications').warning(errorMsg);
          }).finally(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          tooltip.reset();
        },
        hasOverlay: true
      });
    },

    actions: {
      onSaveClick() {
        const overrideSelectedProvider = this.get('overrideSelectedProvider');

        if (overrideSelectedProvider.get('isBrokerProviderType')) {
          this.onBrokerTypeTransferTripSave();
          return;
        }

        this.onTransferTripSave();
      },

      onRefreshClick() {
        this.set('overrideSelectedProvider', null);
        this.set('overrideSelectedRoute', null);
        this.set('overrideSelectedPickOrder', null);
        this.set('overrideSelectedDropOrder', null);
        this.resetSolutionTableData();
      },

      onShrinkClick() {
        let currentState = this.get('currentState'); // const bulkTripTransfer = this.get('bulkTripTransfer');

        const overrideSelectedProvider = this.get('overrideSelectedProvider');
        const overrideSelectedRoute = this.get('overrideSelectedRoute');
        const overrideSelectedPickOrder = this.get('overrideSelectedPickOrder');
        const overrideSelectedDropOrder = this.get('overrideSelectedDropOrder');
        const solutionPreviewTable = this.get('solutionPreviewTable'); // save current manual settings

        currentState = {
          overrideSelectedProvider,
          overrideSelectedRoute,
          overrideSelectedPickOrder,
          overrideSelectedDropOrder,
          solutionPreviewTable
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      },

      onChangeProvider(provider) {
        // clear selected route, pick order and drop order
        this.set('overrideSelectedRoute', null);
        this.set('overrideSelectedPickOrder', null);
        this.set('overrideSelectedDropOrder', null);
        this.resetSolutionTableData();
        this.set('overrideSelectedProvider', provider);
        this.set('isBrokerProvider', provider.get('isBrokerProviderType'));
      },

      onChangeRoute(route) {
        // clear selected pick order and drop order
        this.set('overrideSelectedPickOrder', null);
        this.set('overrideSelectedDropOrder', null);
        this.resetSolutionTableData();
        this.set('overrideSelectedRoute', route);
      },

      onChangePickOrder(order) {
        // clear selected drop order
        this.set('overrideSelectedDropOrder', null);
        this.resetSolutionTableData();
        this.set('overrideSelectedPickOrder', order);
      },

      onChangeDropOrder(order) {
        this.set('overrideSelectedDropOrder', order);
        this.resetSolutionTableData();
        this.setupSolutionPreview();
      }

    }
  });

  _exports.default = _default;
});