define("adept-iq/pods/components/side-drawer/edit-trip-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dropLocationFieldConfig = _exports.pickLocationFieldConfig = void 0;
  const pickLocationFieldConfig = {
    id: 'originaddresses',
    type: 'addresses',
    label: 'Pickup Address',
    valuePath: 'pickPlace',
    modelName: 'place',
    markerType: 'pick',
    extra: {
      aliasPath: 'address.alias',
      notesPath: 'address.notes',
      premisePath: 'address.premise',
      streetNumberPath: 'address.streetNumber',
      streetNamePath: 'address.streetName',
      localityPath: 'address.city',
      regionPath: 'address.region',
      subRegionPath: 'address.county',
      postalCodePath: 'address.postalCode',
      countryPath: 'address.country',
      freeformAddressPath: 'address.freeformAddress',
      tomtomFormattedAddressPath: 'address.tomtomFormattedAddress',
      useOptionRecord: true,
      streetAddressPath: 'address.streetAddress',
      addressType: 'pick'
    }
  };
  _exports.pickLocationFieldConfig = pickLocationFieldConfig;
  const dropLocationFieldConfig = {
    id: 'destinationaddresses',
    type: 'addresses',
    label: 'Pickup Address',
    valuePath: 'dropPlace',
    modelName: 'place',
    markerType: 'drop',
    extra: {
      aliasPath: 'address.alias',
      notesPath: 'address.notes',
      premisePath: 'address.premise',
      streetNumberPath: 'address.streetNumber',
      streetNamePath: 'address.streetName',
      localityPath: 'address.city',
      regionPath: 'address.region',
      subRegionPath: 'address.county',
      postalCodePath: 'address.postalCode',
      countryPath: 'address.country',
      freeformAddressPath: 'address.freeformAddress',
      tomtomFormattedAddressPath: 'address.tomtomFormattedAddress',
      useOptionRecord: true,
      streetAddressPath: 'address.streetAddress',
      addressType: 'drop'
    }
  };
  _exports.dropLocationFieldConfig = dropLocationFieldConfig;
});