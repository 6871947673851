define("adept-iq/pods/components/side-drawer/perform-stop/break/ui/component", ["exports", "adept-iq/pods/components/side-drawer/perform-stop/break/ui/config", "moment", "adept-iq/models/stop-point"], function (_exports, _config, _moment, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INTERVAL_DELAY = 60000;

  var _default = Ember.Component.extend({
    classNames: ['perform-break-drawer'],
    timeFormat: 'm-d-Y G:i K',
    shouldArrive: true,
    // pass in data to component
    stopPoint: null,
    driverBreak: null,
    errorText: null,
    actualDropTime: null,
    actualArriveTime: null,
    arrive: false,
    depart: false,
    isArrived: null,
    isNextDisabled: null,
    maxDate: null,
    fields: _config.default.fields,
    // progress interval every minute
    _interval: null,
    route: Ember.computed.readOnly('stopPoint.dispatchRoute.route'),
    routeVehicleDriver: Ember.computed.readOnly('route.dispatchRoute.latestRouteVehicleDriver'),
    address: Ember.computed.readOnly('driverBreak.place.address'),
    fixedBreakAddress: Ember.computed.readOnly('driverBreak.address'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    isLocationsDisabled: Ember.computed.equal('driverBreak.breakType.name', 'fixed'),
    lastPerformedStop: Ember.computed('dispatchRoute.orderedStops.@each.{arrive,depart,computedStatus}', function () {
      const stops = this.get('dispatchRoute.orderedStops');
      const order = [];
      if (Ember.isEmpty(stops)) return null;
      stops.filter(function (stop) {
        if (stop.get('depart') || stop.get('computedStatus') === _stopPoint.PERFORMED_STOP_STATUS || stop.get('computedStatus') === _stopPoint.ARRIVE_STOP_STATUS || stop.get('computedStatus') === _stopPoint.OFF_BREAK_STOP_STATUS) {
          order.push(stop);
        }
      });
      return order.pop();
    }),
    minTime: Ember.computed('lastPerformedStop.{arrive,depart,actualArriveTime,actualDepartTime,routeBreak.actualArriveTime,routeBreak.actualDepartTime}', function () {
      const prevStop = this.get('lastPerformedStop');
      let minTime = this.get('route.dispatchRoute.actualStartTimestamp') || this.get('route.plannedStartTime');
      if (!prevStop) return minTime;
      const actualArriveTime = prevStop.get('computedActualArriveTime'); // takes into account break stops

      const actualDepartTime = prevStop.get('computedActualDepartTime'); // takes into account break stops

      if (actualArriveTime) minTime = actualArriveTime;
      if (actualDepartTime) minTime = actualDepartTime;
      return minTime ? (0, _moment.default)(minTime).startOf('minute').toDate() : null; // we need something to separate stops
    }),

    init() {
      this._super(...arguments);

      this.set('maxDate', new Date()); // create a maxDate ticker that progresses by a minute

      this._interval = setInterval(() => {
        Ember.run.schedule('afterRender', () => {
          if (this.get('isDestroyed')) return;
          this.set('maxDate', new Date());
        });
      }, INTERVAL_DELAY);
      const setStopUIReference = this.get('setStopUIReference');

      if (setStopUIReference) {
        setStopUIReference(this);
      }
    },

    destroy() {
      clearInterval(this._interval);
      this._interval = null;
    },

    didInsertElement() {
      this.loadCurrentState();
    },

    setPickTTimeInArrivalTime() {
      const pickTime = this.get('stopPoint.routeBreak.actualDepartTime');
      const arriveTime = this.get('stopPoint.routeBreak.actualArriveTime');

      if ((0, _moment.default)(pickTime).isBefore(arriveTime, 'minutes')) {
        this.set('stopPoint.routeBreak.actualDepartTime', arriveTime);
      }
    },

    backUpCurrentState() {
      const {
        stopPoint,
        driverBreak,
        travelNeedOptions,
        travelNeedItems,
        arrive,
        depart,
        isArrived
      } = this;
      const odometerInMiles = stopPoint.get('odometerInMiles');
      const currentState = {
        driverBreak,
        travelNeedOptions,
        travelNeedItems,
        arrive,
        depart,
        isArrived,
        odometerInMiles
      };
      this.set('currentState', currentState);
    },

    loadCurrentState() {
      const currentState = this.get('currentState');
      const stopPoint = this.get('stopPoint');

      if (currentState) {
        const {
          odometerInMiles,
          driverBreak,
          travelNeedOptions,
          travelNeedItems,
          arrive,
          depart,
          isArrived
        } = currentState;
        stopPoint.set('odometerInMiles', odometerInMiles);
        this.setProperties({
          driverBreak,
          travelNeedOptions,
          travelNeedItems,
          arrive,
          depart,
          isArrived
        });
        this.set('currentState', null);
      }
    },

    actions: {
      onClickDepart(depart) {
        if (depart) {
          this.set('arrive', depart);
          this.set('depart', depart);
          this.set('isNextDisabled', false);
          return;
        }

        this.set('depart', depart);
        this.set('isNextDisabled', true);
      },

      onActualDropTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        this.set('stopPoint.routeBreak.actualDepartTime', momentTime.startOf('minute').toISOString());
        this.setPickTTimeInArrivalTime();
      },

      onActualArriveTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        this.set('stopPoint.routeBreak.actualArriveTime', momentTime.startOf('minute').toISOString());
        this.setPickTTimeInArrivalTime();
      },

      onCellValueChange(record, valuePath, value, options) {
        record.set(`${valuePath}.${options.valuePath}`, value);
      }

    }
  });

  _exports.default = _default;
});