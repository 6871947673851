define("adept-iq/pods/components/side-drawer/permissions-add-role/text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['role-name-input'],
    value: null,
    disabled: false,
    actions: {
      onInput(value) {
        this.get('onChange')(value.trim());
      }

    }
  });

  _exports.default = _default;
});