define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/driver/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "moment", "lodash", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/driver/config", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/driver/validation", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _moment, _lodash, _config, _validation, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['driver-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    addressConfig: _config.addressConfig,
    place: null,
    classificationOptions: null,
    // placeholders
    CDL_NUMBER_PLACEHOLDER: _placeholders.CDL_NUMBER_PLACEHOLDER,
    PROVIDER_PLACEHOLDER: _placeholders.PROVIDER_PLACEHOLDER,
    PHONE_NUMBER_EXT_PLACEHOLDER: _placeholders.PHONE_NUMBER_EXT_PLACEHOLDER,
    PHONE_NUMBER_PLACEHOLDER: _placeholders.PHONE_NUMBER_PLACEHOLDER,
    SOCIAL_SECURITY_NUMBER_PLACEHOLDER: _placeholders.SOCIAL_SECURITY_NUMBER_PLACEHOLDER,
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    // passed into view
    editAPI: null,
    selectedDriver: Ember.computed.readOnly('editAPI.selectedDriver'),
    hasPendingChanges: Ember.computed.gt('driverChangeSet.changes.length', 0),
    currentDate: (0, _moment.default)().startOf('day').toDate(),

    async init() {
      const store = this.get('store');
      const driverAddress = this.get('selectedDriver.address');
      const location = store.createRecord('location');
      const place = store.createRecord('place', {
        address: driverAddress,
        location
      });
      this.set('place', place);
      this.get('refreshTask').perform();

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onSaveDriverRecord = this.get('onSaveDriverRecord');
      const onValidateAction = this.get('validateDriver'); // normal functions

      const onUndoDriverRecord = this.get('onUndoDriverRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverRecord,
          newAction: null,
          deleteAction: null,
          editAction: null,
          undoAction: onUndoDriverRecord,
          onValidateAction
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      const place = (0, _unwrapProxy.unwrapProxy)(this.get('place'));
      const location = (0, _unwrapProxy.unwrapProxy)(this.get('place.location'));
      place.set('address', null);
      if (location) location.rollbackAttributes();

      if (place) {
        place.rollbackAttributes();
      }
    },

    driverSuspendedText: Ember.computed('driverChangeSet.driverSuspensions', function () {
      const driverSuspensions = this.get('driverChangeSet.driverSuspensions') || [];
      const suspensions = driverSuspensions.filter(suspension => suspension.get('isSuspended'));

      if (suspensions.length > 0) {
        return 'Driver IS suspended';
      }

      return 'Driver is NOT suspended';
    }),
    driverAmountOfViolationsText: Ember.computed('driverChangeSet.driverSuspensions', function () {
      const driverSuspensions = this.get('driverChangeSet.driverSuspensions') || [];
      const suspensions = driverSuspensions.filter(suspension => suspension.get('isSuspended'));
      const violationPts = suspensions.reduce((acu, suspension) => {
        const sum = acu + suspension.get('driverViolation.points');
        return sum;
      }, 0);
      return `Driver has ${violationPts} points`;
    }),
    driverChangeSet: Ember.computed('selectedDriver', function () {
      const driver = (0, _unwrapProxy.unwrapProxy)(this.get('selectedDriver'));
      return this.setDefaultProperties(driver);
    }),
    driverPhoneChangeSet: Ember.computed('driverChangeSet', function () {
      const driverChangeSet = this.get('driverChangeSet');
      const driverPhone = (0, _unwrapProxy.unwrapProxy)(driverChangeSet.get('phoneNumbers.firstObject'));
      return new _emberChangeset.default(driverPhone, (0, _emberChangesetValidations.default)(_validation.DRIVER_PHONE_VALIDATION), _validation.DRIVER_PHONE_VALIDATION);
    }),
    driverEmergencyContactChangeSet: Ember.computed('driverChangeSet', function () {
      const driverChangeSet = this.get('driverChangeSet');
      const driverEmergencyContact = (0, _unwrapProxy.unwrapProxy)(driverChangeSet.get('emergencyContacts.firstObject'));
      return new _emberChangeset.default(driverEmergencyContact, (0, _emberChangesetValidations.default)(_validation.DRIVER_EMERGENCY_CONTACT_VALIDATION, _validation.DRIVER_EMERGENCY_CONTACT_VALIDATION));
    }),
    driverLicenseChangeSet: Ember.computed('driverChangeSet', function () {
      const driverChangeSet = this.get('driverChangeSet');
      const driverLicense = (0, _unwrapProxy.unwrapProxy)(driverChangeSet.get('license'));
      return new _emberChangeset.default(driverLicense, (0, _emberChangesetValidations.default)(_validation.DRIVER_LICENSE_VALIDATION), _validation.DRIVER_LICENSE_VALIDATION);
    }),

    setDefaultProperties(record = null
    /* disableForms = true */
    ) {
      const store = this.get('store');
      let pendingDriverRecord = this.get('pendingDriverRecord');

      if (record) {
        pendingDriverRecord = record;
      } else if (!pendingDriverRecord) {
        pendingDriverRecord = store.createRecord('driver');
        this.set('pendingDriverRecord', pendingDriverRecord);
      }

      if (pendingDriverRecord.get('isNew')) {// pendingDriverRecord.setProperties({});
      }

      this.set('showValidations', false);
      return new _emberChangeset.default(pendingDriverRecord, (0, _emberChangesetValidations.default)(_validation.DRIVER_VALIDATION), _validation.DRIVER_VALIDATION);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedDriver = this.get('selectedDriver');
      const store = this.get('store');
      const classificationOptions = yield store.findAll('classification-name');
      this.set('classificationOptions', classificationOptions);
      const inspectCategories = yield store.findAll('inspection-category');
      this.set('inspectionCategories', inspectCategories);

      if (!selectedDriver.get('isNew')) {
        yield selectedDriver.reload();
      }

      const tableRef = this.get('tableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    onSaveDriverRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const pendingDriverRecord = this.get('driverChangeSet');
      const driverAddress = (0, _unwrapProxy.unwrapProxy)(pendingDriverRecord.get('address'));
      const driverPhoneChangeSet = this.get('driverPhoneChangeSet');
      const emergencyContactChangeSet = this.get('driverEmergencyContactChangeSet');
      const driverLicenseChangeSet = this.get('driverLicenseChangeSet'); // validation

      const isValidDriver = yield this.validateDriver.perform();

      if (isValidDriver) {
        try {
          yield pendingDriverRecord.save(); // save address if its present

          if (driverAddress.get('address')) {
            yield driverAddress.save();
          }

          if (driverPhoneChangeSet.get('isDirty') && driverPhoneChangeSet.get('isValid')) {
            yield driverPhoneChangeSet.save();
          }

          if (emergencyContactChangeSet.get('isDirty') && emergencyContactChangeSet.get('isValid')) {
            yield emergencyContactChangeSet.save();
          }

          if (driverLicenseChangeSet.get('isDirty') && driverLicenseChangeSet.get('isValid')) {
            yield driverLicenseChangeSet.save();
          }

          notifications.success('Successfully Saved Driver Changes');
        } catch (e) {
          notifications.warning('Failed to Save Driver Changes');
          console.warn(e); // eslint-disable-line
        }

        this.get('refreshTask').perform();
      }
    }),

    onUndoDriverRecord() {
      const driverChangeSet = this.get('driverChangeSet');
      const driverAddress = driverChangeSet.get('address');
      const driverPhoneChangeSet = this.get('driverPhoneChangeSet');
      const emergencyContactChangeSet = this.get('driverEmergencyContactChangeSet');
      const driverLicenseChangeSet = this.get('driverLicenseChangeSet');

      if (driverChangeSet) {
        driverChangeSet.rollback();
        driverPhoneChangeSet.rollback();
        emergencyContactChangeSet.rollback();
        driverLicenseChangeSet.rollback();
      }

      if (driverAddress) {
        driverAddress.setProperties({
          streetNumber: null,
          streetAddress: null,
          locality: null,
          region: null,
          postalCode: null
        });
      }
    },

    validateDriver: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverChangeSet = this.get('driverChangeSet');
      const driverPhoneChangeSet = this.get('driverPhoneChangeSet');
      const emergencyContactChangeSet = this.get('driverEmergencyContactChangeSet');
      const driverLicenseChangeSet = this.get('driverLicenseChangeSet');
      yield driverChangeSet.validate();
      yield driverPhoneChangeSet.validate();
      yield emergencyContactChangeSet.validate();
      yield driverLicenseChangeSet.validate();
      const driverValid = driverChangeSet.errors;
      const driverPhoneValid = driverPhoneChangeSet.errors;
      const emergencyContactValid = emergencyContactChangeSet.errors;
      const driverLicenseValid = driverLicenseChangeSet.errors;
      const errors = (0, _lodash.compact)([].concat(driverValid, driverPhoneValid, emergencyContactValid, driverLicenseValid));

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      return true;
    }),
    actions: {
      onPowerSelectChange(valuePath, selected) {
        this.set(valuePath, selected);
      },

      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onInputMaskValueChange(record, valuePath, useMaskVal, maskObj) {
        if (useMaskVal) record.set(valuePath, maskObj.masked.replace(/_/im, ''));else record.set(valuePath, maskObj.unmasked);
      },

      onPhoneValueChange(record, maskObj) {
        // match[0] = area code
        // match[1] = first part of 3 digit phone number
        // match[2] = second part of 4 digit phone number
        const match = maskObj.masked.replace(/_/, '').split('-');
        const phone = record;
        phone.set('areaCode', match[0]);
        phone.set('phoneNumber', match[1] + match[2]);
        phone.set('phone', match[1] + match[2]);
        phone.set('unformattedPhoneNumber', maskObj.unmasked);
      },

      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      },

      onCellValueChange(record, value, options) {
        record.set(`${options.valuePath}`, value);
      },

      onCheckboxChange(record, valuePath, event) {
        const checked = event.target.checked;
        record.set(valuePath, checked);
      }

    }
  });

  _exports.default = _default;
});