define("adept-iq/pods/components/side-drawer/system-config/places/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/side-drawer/system-config/places/config"], function (_exports, _emberConcurrency, _unwrapProxy, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['places'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    disableForm: true,
    isNewPlace: false,
    location: null,
    disableBtns: null,
    locationConfig: _config.default.fields,
    addressOptions: null,
    selectedPlaceTypeCategory: null,
    inValidName: false,
    addressPublicApi: null,
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    lat: Ember.computed('location.place.location.lat', 'selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      let lat = this.get('location.place.location.lat');

      if (selectedRow && Ember.isEmpty(lat)) {
        lat = selectedRow.get('location.lat');
      }

      return lat;
    }),
    lng: Ember.computed('location.place.location.lng', 'selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      let lng = this.get('location.place.location.lng');

      if (selectedRow && Ember.isEmpty(lng)) {
        lng = selectedRow.get('location.lng');
      }

      return lng;
    }),
    disableSavebtn: Ember.computed('disableForm', 'location.{name,address,placeTypeCategory}', function () {
      const disableForm = this.get('disableForm');

      if (disableForm) {
        return true;
      }

      const hasValues = !Ember.isEmpty(this.get('lat')) && !Ember.isEmpty(this.get('location.name')) && !Ember.isEmpty(this.get('location.placeTypeCategory'));
      return !hasValues;
    }),
    selectedRowChange: Ember.observer('selectedRow', function () {
      const selectedRow = this.get('selectedRow');
      this.setDefaultProperties(selectedRow);
      this.defaultSettings();
    }),

    init() {
      this._super(...arguments);

      this.setDefaultProperties();
      this.setupPlaceTypeCategoryList();
      this.defaultSettings();
    },

    setDefaultProperties(record = []) {
      const address = this.get('store').createRecord('address', {});
      const locationRecord = this.get('store').createRecord('location', {
        lat: '',
        lng: '',
        geoNode: ''
      });
      const placeFields = {
        name: '',
        placeTypeCategory: '',
        notes: '',
        place: Ember.Object.create({
          location: locationRecord,
          address
        })
      };
      this.set('location', Ember.Object.create(placeFields));

      if (!Ember.isEmpty(record)) {
        const location = this.get('location');
        location.setProperties({
          name: record.get('address.alias'),
          notes: record.get('address.notes'),
          placeTypeCategory: record.get('placeCategoryType.id'),
          'place.address': (0, _unwrapProxy.unwrapProxy)(record.get('address')),
          'place.location': (0, _unwrapProxy.unwrapProxy)(record.get('location'))
        });
        this.set('disableForm', true);
      }

      if (this.get('addressPublicApi')) {
        this.get('addressPublicApi').onValueChange(this.get('location.place'));
      }
    },

    defaultSettings() {
      const disableBtns = {
        new: false,
        edit: false,
        delete: false,
        save: true
      };
      this.set('disableBtns', disableBtns);
      this.set('inValidName', false);
    },

    resetErrors() {
      this.set('inValidName', false);
    },

    setupPlaceTypeCategoryList() {
      const placeTypeCategory = this.get('store').peekAll('place-category-type');
      const list = [];
      placeTypeCategory.forEach(item => list.push(item.name));
      this.set('placeTypeCategory', list);
    },

    async checkDuplicateAddressName() {
      //ignore duplicate name validate  when editing place
      if (!this.get('isNewPlace')) return false;
      const {
        name
      } = this.get('location');
      const params = {
        filter: `eq('alias','${name.trim()}')`
      };
      const records = await this.get('store').query('address', params);
      return records.length;
    },

    async validateName() {
      const name = this.get('location.name');
      const containsDuplicateName = await this.checkDuplicateAddressName(name);

      if (!containsDuplicateName) {
        this.set('inValidName', false);
        return true;
      }

      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'System Configuration',
        hasoverlay: true,
        tip: 'Name Already Exist',
        primaryActionText: 'OK',
        primaryAction: () => {
          this.set('inValidName', true);
          tooltip.reset();
        }
      });
      return false;
    },

    async save() {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const {
        name,
        placeTypeCategory,
        notes
      } = this.get('location');
      const address = this.get('location.place.address');
      const location = this.get('location.place.location');
      address.set('alias', name);
      address.set('notes', notes);

      if (this.get('isNewPlace')) {
        const place = Ember.Object.create({
          geocodingMethod: 'something'
        });
        const locationResponse = await locationAdapter.postLocation(location);
        const addressResponse = await addressAdapter.postAddress(address);
        await addressAdapter.postAddressLocation(locationResponse, addressResponse);
        return await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, placeTypeCategory);
      }

      const place = this.get('selectedRow');
      await locationAdapter.patchLocation(location);
      await addressAdapter.patchAddress(address);
      return await placeAdapter.patchPlace(place, address.get('id'), location.get('id'), placeTypeCategory);
    },

    delete: (0, _emberConcurrency.task)(function* () {
      const record = this.get('selectedRow');
      const notifications = this.get('notifications');

      try {
        record.deleteRecord();
        yield record.save();
        notifications.success('Record successfully deleted.');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (error) {
        notifications.warning('Record failed to delete.');
      }
    }).drop(),
    actions: {
      newPlaces() {
        this.get('tableRef').onUncheckAll();
        this.setDefaultProperties();
        this.set('isNewPlace', true);
        this.set('disableForm', false);
        this.set('disableBtns.delete', true);
        this.set('disableBtns.edit', true);
        this.set('disableBtns.new', true);
      },

      editPlaces() {
        const selectedRow = this.get('selectedRow');
        this.setDefaultProperties(selectedRow);
        this.set('isNewPlace', false);
        this.set('disableForm', false);
        this.set('disableBtns.delete', true);
        this.set('disableBtns.edit', true);
        this.set('disableBtns.new', true);
      },

      deletePlaces() {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'System Configuration',
          hasoverlay: true,
          tip: 'Are you sure you want to Delete these changes?',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('delete').perform().then(() => {
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',

          secondaryAction() {
            return tooltip.reset();
          }

        });
        this.resetErrors();
      },

      async savePlaces() {
        const validationResult = await this.validateName();

        if (validationResult) {
          const tooltip = this.get('tooltip');
          const notifications = this.get('notifications');
          tooltip.pushConfirmation({
            title: 'System Configuration',
            hasoverlay: true,
            tip: 'Are you sure you want to save these changes?',
            primaryActionText: 'Confirm',
            primaryAction: () => {
              tooltip.reset();
              return this.save().then(() => {
                notifications.success('Record successfully saved');
                this.get('tableRef').onUncheckAll();
                this.get('tableRef').refreshData();
                this.set('disableForm', true);
                this.setDefaultProperties();
              }).catch(error => {
                // eslint-disable-next-line no-console
                console.error(error);
                notifications.warning('Record failed to save');
              });
            },
            secondaryActionText: 'Cancel',

            secondaryAction() {
              tooltip.reset();
            }

          });
        }
      },

      onCellValueChange(record, valuePath, value, options) {
        record.set(`${valuePath}.${options.valuePath}`, value);
      },

      onInputValueChange(record, valuePath, value) {
        return record.set(valuePath, value);
      },

      registerAddressPublicApi(publicApi) {
        this.set('addressPublicApi', publicApi);
      }

    }
  });

  _exports.default = _default;
});