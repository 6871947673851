define("adept-iq/pods/components/iq-widgets/new-booking-form/booking-map/component", ["exports", "adept-iq/pods/components/generic-widgets/map-widget/component", "tomtom", "adept-iq/utils/zone-validation"], function (_exports, _component, _tomtom, _zoneValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-unused-vars */
  const pintPointIcon = {
    icon: _tomtom.default.L.icon({
      iconUrl: '/map-icons/icon-pinpoint.svg',
      iconSize: [48, 48],
      iconAnchor: [11.5, 11.5]
    }),
    keyboard: false,
    interactive: false
  };
  const arrowHeadLineConfig = {
    patterns: [{
      offset: 0,
      repeat: 0,
      symbol: _tomtom.default.L.Symbol.arrowHead({
        headAngle: -290,
        // flips arrow around so it looks right
        pixelSize: 16,
        polygon: true,
        pathOptions: {
          weight: 2,
          stroke: true,
          fill: true,
          fillColor: 'black',
          fillOpacity: 1,
          color: 'black',
          opacity: 1
        }
      })
    }]
  };
  const polyAndDecKeyCoordinates = [{
    path: '_parts',
    x1: 'x',
    y1: 'y'
  }, {
    path: '_paths',
    x1: 'lat',
    y1: 'lng'
  }];

  var _default = _component.default.extend(Ember.Evented, {
    booking: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    notifications: Ember.inject.service(),
    defaultPickMarker: null,
    zoom: 12,
    layerGroup: null,
    activeBookingSegments: null,
    allActivePlaces: null,

    init() {
      this._super(...arguments);

      this.setProperties({
        layerGroup: new _tomtom.default.L.LayerGroup()
      });
      this.set('availableZones', this.get('booking').getNYCTTravelNeeds());
      this.set('availableConfigZones', this.get('booking').getSysConfigTravelNeeds());
    },

    didInsertElement() {
      this._super(...arguments);

      this.activeGeocode();
      this.onChangeActiveAddress();
      this.onChangeActiveLegsInBooking();
      const editmodelwidth = Ember.$('.edit-widget-modal').width();
      Ember.$('.edit-widget-modal').width('25%');
      Ember.$('.booking-map-container').css({
        'width': 'calc(95% - ' + editmodelwidth + 'px)'
      }).css({
        'height': 'calc(100% - 20%)',
        'left': editmodelwidth + 'px)'
      });
    },

    activeGeocode: Ember.observer('booking.activeDragPlace', 'booking.addressSelectType', function () {
      const activeDragPlace = this.get('booking.activeDragPlace');
      const map = this.get('map');
      const addressSelectType = this.get('booking.addressSelectType');

      if (!activeDragPlace) {
        map.off('click');
        return;
      }

      const bookingService = this.get('booking');
      Ember.$('html,body,.tomtom-map').addClass('custom-cursor');
      map.on('click', async event => {
        try {
          const tomtomAddress = await bookingService.fetchAddressFotLatLng(event.latlng);

          if (!tomtomAddress) {
            this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressSelectType} address.`);
            this.drawMarkersAndPolylines();
            return;
          }

          const freeformAddress = tomtomAddress.address.freeformAddress;
          const latlng = event.latlng;
          const geoNode = await this.booking.getAddressGeoNode(freeformAddress, addressSelectType);

          if (!geoNode) {
            return;
          }

          const locationRecord = this.store.createRecord('location', {
            lat: parseFloat(latlng.lat.toFixed(5)),
            lng: parseFloat(latlng.lng.toFixed(5))
          });
          locationRecord.set('geoNode', geoNode.geonode);
          const activeDragAddress = activeDragPlace.get('address');
          bookingService.updateAddress(activeDragAddress, tomtomAddress);
          activeDragPlace.set('location', locationRecord);
          const lat = parseFloat(latlng.lat.toFixed(5));
          const lng = parseFloat(latlng.lng.toFixed(5));

          if (!(0, _zoneValidation.insideZone)([lat, lng])) {
            this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressSelectType} address.`);
            return;
          }

          const {
            zoneName,
            isInsideAvailZones
          } = this.get('booking').checkZoneForAddress(this.get('availableZones'), this.get('availableConfigZones'), lat, lng);

          if (isInsideAvailZones && !zoneName) {
            this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressSelectType} address.`);
            return;
          }

          if (zoneName) {
            activeDragAddress.set('zName', zoneName);
          }

          if (!activeDragAddress.get('zName')) {
            this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressSelectType} address.`);
            return;
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        } finally {
          Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
          bookingService.setActiveDragPlace(null);
          map.off('click');
        }
      });
    }),
    onChangeActiveLegsInBooking: Ember.observer('booking.activeLegsInBooking', 'booking.activeDragPlace', function () {
      this.drawMarkersAndPolylines();
    }),
    onChangeActiveAddress: Ember.observer('booking.activeAddress', function () {
      this.drawMarkersAndPolylines();
    }),

    drawMarkersAndPolylines() {
      if (!Ember.isPresent(this.get('map'))) {
        return;
      }

      this.get('layerGroup').clearLayers();
      const legsInBooking = this.booking.get('activeLegsInBooking') ? this.booking.get('activeLegsInBooking').toArray() : [];
      const allSegments = [];
      legsInBooking.forEach(leg => {
        const segments = leg.get('segments').toArray();
        const validSegments = segments.filter(segment => {
          return segment.get('pick.place.location.lat') && segment.get('drop.place.location.lat');
        });
        allSegments.push(...validSegments);
        segments.forEach(segment => {
          const legMarkers = [segment.get('pick.place.location'), segment.get('drop.place.location')];
          const validMarkers = legMarkers.filter(marker => {
            return marker.get('lat') && marker.get('lng');
          });
          validMarkers.forEach(marker => {
            this.drawMarker(marker);
          });
        });
      });
      this.drawPloylinePath(allSegments);
    },

    drawMarker(latLngObj) {
      const marker = _tomtom.default.L.marker([latLngObj.get('lat'), latLngObj.get('lng')], pintPointIcon);

      this.get('layerGroup').addLayer(marker).addTo(this.get('map'));
      this.get('map').setView(marker._latlng, 12);
    },

    drawPolyline(pick, drop) {
      // Drawing reversly to make arrow head correct
      const from = pick.get('place.location');
      const to = drop.get('place.location');

      const polyline = _tomtom.default.L.polyline([[to.get('lat'), to.get('lng')], [from.get('lat'), from.get('lng')]], {
        color: 'black',
        endOffset: '300'
      });

      const polylineDecorator = _tomtom.default.L.polylineDecorator(polyline, arrowHeadLineConfig);

      this.get('layerGroup').addLayer(polyline).addTo(this.get('map'));
      this.get('layerGroup').addLayer(polylineDecorator).addTo(this.get('map'));
      const polyAndDecorators = [polyline, polylineDecorator]; //set arrow head - NYAAR-14549

      this.get('layerGroup').removeLayer(polyline).addTo(this.get('map'));
      this.get('layerGroup').removeLayer(polylineDecorator).addTo(this.get('map'));
      this.shortenPolylineAndDecorator(polyAndDecorators);
      this.get('layerGroup').addLayer(polyline).addTo(this.get('map'));
      this.get('layerGroup').addLayer(polylineDecorator).addTo(this.get('map'));
    },

    shortenPolylineAndDecorator(polyAndDecorators, factor = 0.2) {
      const isParts = !polyAndDecorators.firstObject._parts || polyAndDecorators.firstObject._parts.length === 0;
      const isPaths = !polyAndDecorators.lastObject._paths || polyAndDecorators.lastObject._paths.length === 0;

      if (isParts || isPaths) {
        return;
      }

      polyAndDecKeyCoordinates.forEach((pdk, index) => {
        const firstCoordinate = polyAndDecorators[index][pdk.path][0][0];
        const secondCoordinate = polyAndDecorators[index][pdk.path][0][1];
        const x1 = firstCoordinate[pdk.x1];
        const y1 = firstCoordinate[pdk.y1];
        const x2 = secondCoordinate[pdk.x1];
        const y2 = secondCoordinate[pdk.y1];
        const newStartX = x1 + factor * (x2 - x1);
        const newStartY = y1 + factor * (y2 - y1);
        const endFactor = 1 - factor;
        const newEndX = x1 + endFactor * (x2 - x1);
        const newEndY = y1 + endFactor * (y2 - y1);
        polyAndDecorators[index][pdk.path][0][0][pdk.x1] = newStartX;
        polyAndDecorators[index][pdk.path][0][0][pdk.y1] = newStartY;
        polyAndDecorators[index][pdk.path][0][1][pdk.x1] = newEndX;
        polyAndDecorators[index][pdk.path][0][1][pdk.y1] = newEndY;
      });
    },

    drawPloylinePath(segments) {
      if (segments.length === 0) {
        return;
      }

      for (let i = 0; i < segments.length; i++) {
        this.drawPolyline(segments[i].get('pick'), segments[i].get('drop'));
      }
    },

    destroy() {
      this._super(...arguments);

      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
    }

  });

  _exports.default = _default;
});