define("adept-iq/pods/components/iq-widgets/vehicles-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/vehicles-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'vehicle',
    title: 'Vehicles',
    defaultSortId: 'otp',
    defaultSortAsc: false,
    // ember-react-widget properties
    widgetName: 'vehicleWidget',
    initialLimit: 5000,
    paginationLimit: 20,
    maxLimitByRouteSelection: 5,
    maxLimitWithoutRouteAssociation: 20,
    selectedRowLimit: 20,
    columns: [{
      id: 'name',
      index: 0,
      type: 'uuid',
      label: 'ID',
      valuePath: 'name',
      //missing
      valuePreview: '1',
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 45
    }, {
      id: 'typeName',
      index: 1,
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      mapValuePath: 'vehicleTypeName',
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 60
    }, {
      id: 'availability',
      index: 2,
      type: 'enum-date',
      label: 'Availabilities',
      valuePath: 'availability',
      mapValuePath: 'formattedAvailabilities',
      cellDesc: 'Availabilities',
      isMapLabelVisible: true,
      defaultWidth: 100,
      cellItem: 'AvailabilityCell'
    }, {
      id: 'noOfAmbulatorySeats',
      index: 4,
      type: 'number',
      label: 'Ambulatory seats',
      valuePath: 'noOfAmbulatorySeats',
      //computed with config data
      editable: true,
      defaultWidth: 60,
      highlightable: false
    }, {
      id: 'noOfWheelChairSeats',
      index: 5,
      type: 'number',
      label: 'Wheelchair spaces',
      valuePath: 'noOfWheelChairSeats',
      //computed with config data
      editable: true,
      defaultWidth: 60,
      highlightable: false
    }, {
      id: 'active',
      index: 6,
      type: 'boolean',
      label: 'Active',
      valuePath: 'active',
      editable: true,
      defaultWidth: 45
    }, {
      id: 'routeName',
      index: 7,
      type: 'uuid',
      label: 'Route Name',
      valuePath: 'routeName',
      mapValuePath: 'currentRoute.id',
      editable: true,
      searchable: true,
      hidden: true
    }, {
      id: 'driverId',
      index: 8,
      type: 'text',
      label: 'Driver Id',
      valuePath: 'driverId',
      mapValuePath: 'currentDriver.driverId',
      editable: true,
      isMapLabelVisible: true,
      defaultWidth: 45,
      searchable: true,
      hidden: true
    }, {
      id: 'driverName',
      index: 9,
      type: 'text',
      label: 'Driver Name',
      valuePath: 'driverName',
      mapValuePath: 'currentDriver.fullName',
      editable: true,
      searchable: true,
      hidden: true
    }, {
      id: 'garageStartAddress',
      index: 10,
      type: 'text',
      label: 'Starting Garage',
      valuePath: 'startingGarage',
      editable: true,
      hidden: true
    }, {
      id: 'garageEndAddress',
      index: 11,
      type: 'text',
      label: 'Ending Garage',
      valuePath: 'endingGarage',
      editable: true,
      hidden: true
    }, {
      id: 'driverAPPVersion',
      index: 13,
      type: 'text',
      label: 'Driver App Version',
      valuePath: 'driverAppVersion',
      editable: true,
      hidden: true
    }, {
      id: 'otp',
      index: 18,
      type: 'text',
      label: 'OTP',
      valuePath: 'otpStatus',
      mapValuePath: 'otpLabel',
      highlightable: true
    }, {
      id: 'provider',
      index: 19,
      type: 'string',
      label: 'Provider',
      valuePath: 'provider',
      mapValuePath: 'provider.name',
      valuePreview: 'MAG',
      hidden: true,
      searchable: true,
      highlightable: false
    }, {
      id: 'vehicleStatus',
      index: 20,
      type: 'string',
      label: 'Vehicle Status',
      valuePath: 'status',
      hidden: false,
      searchable: true,
      highlightable: false
    }, {
      id: 'gpsTimeStamp',
      index: 21,
      type: 'datetime',
      //missing
      label: 'Last GPS Ping Time',
      valuePath: 'lastGpsPingTime',
      mapValuePath: 'timestamp',
      format: 'MM-DD-YYYY HH:mm A',
      cellItem: 'DateLocalCellItem',
      valuePreview: '',
      defaultWidth: 110,
      hidden: false,
      highlightable: false,
      unAvailable: false
    }, {
      id: 'lat',
      index: 22,
      type: 'float',
      label: 'Last GPS Ping Lat',
      valuePath: 'lastGpsPingLat',
      mapValuePath: 'avlLocation.lat',
      valuePreview: '',
      hidden: false,
      highlightable: false
    }, {
      id: 'lng',
      index: 23,
      type: 'float',
      label: 'Last GPS Ping Lng',
      valuePath: 'lastGpsPingLng',
      mapValuePath: 'avlLocation.lng',
      valuePreview: '',
      hidden: false,
      highlightable: false
    }, {
      id: 'Revenue',
      index: 24,
      type: 'boolean',
      label: 'Revenue',
      valuePath: 'revenue',
      searchable: true,
      hidden: false,
      defaultWidth: 60
    }],
    traversal: {
      tripWidget: {
        links: [{
          type: 'stringEq',
          field: 'routePk',
          valuePath: 'routeId'
        }]
      },
      routeWidget: {
        links: [{
          type: 'stringEq',
          field: 'routePk',
          valuePath: 'routeId'
        }]
      },
      alertWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'vehicleId'
        }]
      },
      stopWidget: {
        links: [{
          type: 'eq',
          field: 'routePk',
          valuePath: 'routeId'
        }]
      },
      messageWidget: {
        links: [{
          type: 'eq',
          field: 'id',
          valuePath: 'vehicleId'
        }]
      }
    }
  };
  _exports.default = _default;
});