define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/tables/equipment/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'vehicle-equipment',
    title: 'Equipment',
    defaultSortId: 'id',
    defaultSortAsc: true,
    selectFirstRow: true,
    fetchModelData: true,
    columns: [{
      id: 'id',
      index: 0,
      type: 'string',
      label: 'ID',
      valuePath: 'id',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'specialized',
      index: 1,
      type: 'boolean',
      label: 'Specialized',
      valuePath: 'specialized',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'installDate',
      index: 2,
      type: 'date',
      label: 'Install Date',
      valuePath: 'installDate',
      defaultWidth: 70,
      valuePreview: '2020-05-15',
      format: 'MM/DD/YYYY',
      searchable: true
    }, {
      id: 'maintenanceLocationName',
      index: 3,
      type: 'string',
      label: 'Maintenance Location',
      valuePath: 'maintenanceLocationName.id',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'type',
      index: 4,
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'notes',
      index: 5,
      type: 'text',
      label: 'Notes',
      valuePath: 'notes',
      defaultWidth: 70,
      searchable: true
    }]
  };
  _exports.default = _default;
});