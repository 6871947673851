define("adept-iq/pods/components/iq-widgets/roles-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/roles-form-widget/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    async init() {
      this._super(...arguments);
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      const errors = [];
      records.forEach(record => {
        const name = record.get('name');

        if (Ember.isEmpty(name)) {
          valid = false;
          errors.push('Role Must have a Name');
        }

        record.set('displayName', name);
        record.set('status', 'active');
        record.set('roleName', name.replace(/\s+/, '-'));
      });
      this.set('editModal.errors', errors);
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        const lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          const records = this.get('editableRecords') || [];
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          this.get('service').apply();
        }
      },

      onCloseClick() {
        // destroy model that have not been saved
        const editableRecords = this.get('editableRecords') || [];
        editableRecords.forEach(currentRecord => {
          if (currentRecord.isNew && Ember.isNone(currentRecord.get('id'))) {
            currentRecord.destroyRecord();
          }
        });
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['sso-role']);

        this._super(...arguments);
      }

    }
  });

  _exports.default = _default;
});