define("adept-iq/pods/components/side-drawer/system-config/component", ["exports", "adept-iq/services/system-config"], function (_exports, _systemConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    systemConfig: Ember.inject.service(),
    workspace: Ember.inject.service(),
    user: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    title: 'System Configuration',
    componentName: 'systemConfigurations',
    // gets passed in by `side-drawer-container`
    onShrinkClick: null,
    isSearchEnabled: false,
    currentComponentPath: Ember.computed.alias('systemConfig.currentComponentPath'),
    isContractManagementAllowed: true,
    isRouteMasterAllowed: true,
    isProvidersAllowed: true,
    isNoShowCategoriesAllowed: true,
    isSearchEffective: Ember.computed.readOnly('systemConfig.isSearchEffective'),
    searchText: Ember.computed.alias('systemConfig.searchText'),
    rootNodes: Ember.computed.readOnly('systemConfig.rootNodes'),
    isProcessing: Ember.computed.readOnly('systemConfig.isProcessing'),
    isModified: Ember.computed.readOnly('systemConfig.isModified'),
    isError: Ember.computed.readOnly('systemConfig.isError'),
    isScheduler: Ember.computed.readOnly('user.isScheduler'),
    showRightPanel: Ember.computed.alias('systemConfig.showRightPanel'),

    init() {
      this._super(...arguments);

      this.configurePermission();
    },

    willDestroyElement() {
      this.set('systemConfig.searchText', null);
      this.set('showRightPanel', false);

      this._super(...arguments);
    },

    configurePermission() {
      const configPermissions = this.get('workspace.configPermissions');
      const isScheduler = this.get('isScheduler');

      if (!Ember.isNone(configPermissions) && !Ember.isNone(configPermissions.systemConfigFunctions)) {
        const systemConfigFunctions = configPermissions.systemConfigFunctions;
        let isContractManagementAllowed = systemConfigFunctions.indexOf('contractManagement') >= 0;
        let isRouteMasterAllowed = systemConfigFunctions.indexOf('routeMaster') >= 0;
        let isProvidersAllowed = systemConfigFunctions.indexOf('providers') >= 0;
        let isPlaceAllowed = systemConfigFunctions.indexOf('places') >= 0;

        if (isScheduler) {
          isContractManagementAllowed = this.get('permissionLayer').permInUserHash(_systemConfig.CONTRACT_MANAGEMENT_PERMID, null);
          isRouteMasterAllowed = this.get('permissionLayer').permInUserHash(_systemConfig.ROUTE_MASTER_PERMID, null);
          isProvidersAllowed = this.get('permissionLayer').permInUserHash(_systemConfig.PROVIDER_PERMID, null);
          isPlaceAllowed = this.get('permissionLayer').permInUserHash(_systemConfig.PLACES_PERMID, null);
        }

        this.set('isContractManagementAllowed', isContractManagementAllowed);
        this.set('isRouteMasterAllowed', isRouteMasterAllowed);
        this.set('isProvidersAllowed', isProvidersAllowed);
        this.set('isPlaceAllowed', isPlaceAllowed);
      }
    },

    focusSearchInput() {
      Ember.$('.system-config-search-input').focus();
    },

    actions: {
      onSaveClick() {
        this.get('systemConfig').save();
      },

      onRefreshClick() {
        const UNSAVED_CHANGES_CONFIRMATION = 'You have unsaved changes that will be discarded. Refresh?';
        /* eslint-disable no-alert */

        if (this.get('isModified') && !confirm(UNSAVED_CHANGES_CONFIRMATION)) {
          return;
        }
        /* eslint-enable no-alert */


        this.get('systemConfig').refresh();
      },

      onCloseClick() {
        const UNSAVED_CHANGES_CONFIRMATION = 'You have unsaved changes that will be discarded. Close Anyway?';
        /* eslint-disable no-alert */

        if (this.get('isModified') && !confirm(UNSAVED_CHANGES_CONFIRMATION)) {
          return;
        }
        /* eslint-enable no-alert */


        this.set('showRightPanel', false);
        this.set('systemConfig.searchText', null);
        this.get('systemConfig').refresh();
        this.onCloseClick();
      },

      onSearchClick() {
        const isSearchEnabled = this.get('isSearchEnabled');

        if (isSearchEnabled) {
          this.set('isSearchEnabled', false);
          return;
        }

        this.set('searchText', '');
        this.set('isSearchEnabled', true);
        Ember.run.scheduleOnce('afterRender', this, 'focusSearchInput');
      },

      showContractPanel() {
        this.setProperties({
          'showRightPanel': true,
          'currentComponentPath': 'contract-management'
        });
      },

      showRouteMasterPanel() {
        this.setProperties({
          'showRightPanel': true,
          'currentComponentPath': 'route-master'
        });
      },

      showProviderPanel() {
        this.setProperties({
          'showRightPanel': true,
          'currentComponentPath': 'providers'
        });
      },

      showPlacePanel() {
        this.setProperties({
          'showRightPanel': true,
          'currentComponentPath': 'places'
        });
      },

      onConfigShrinkClick() {
        this.set('showRightPanel', false);
        this.onShrinkClick();
      }

    }
  });

  _exports.default = _default;
});