define("adept-iq/utils/number", ["exports"], function (_exports) {
  'use strict';
  /**
   * checks  the value is  number  and number of digits
   * @param value
   * @param decimal
   * @returns {boolean}
   */

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateDecimalNumber = _exports.validateNumber = _exports.isBetween = _exports.isDecimalValue = void 0;

  const isDecimalValue = (value, decimal) => {
    const degitRange = decimal ? `{0,${decimal}}` : '{0}';
    const regexExpression = `^\\d+(\\.\\d${degitRange})?$`;
    const regex = new RegExp(regexExpression);
    return regex.test(value);
  };

  _exports.isDecimalValue = isDecimalValue;

  const isBetween = (number, min, max) => {
    return number >= min && number <= max;
  };

  _exports.isBetween = isBetween;

  const validateNumber = (value, validation) => {
    const number = Number(value);
    const {
      min,
      max
    } = validation;
    return isBetween(number, min, max);
  };

  _exports.validateNumber = validateNumber;

  const validateDecimalNumber = (value, validation) => {
    const {
      min,
      max,
      maxDecimalDigits
    } = validation;
    return isDecimalValue(value, maxDecimalDigits) && isBetween(Number(value), min, max);
  };

  _exports.validateDecimalNumber = validateDecimalNumber;
});