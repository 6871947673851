define("adept-iq/models/shift-break", ["exports", "ember-data", "adept-iq/models/break-type"], function (_exports, _emberData, _breakType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    requestTime: attr('date', {
      defaultValue: null
    }),
    promisedStart: attr('string', {
      defaultValue: '06:00:00'
    }),
    plannedDuration: attr('number', {
      defaultValue: 15
    }),
    // seconds
    minutesPlannedDuration: attr('number', {
      defaultValue: 15
    }),
    notes: attr('string'),
    breakType: belongsTo('break-type'),
    place: belongsTo('place'),
    routeTemplateAvailability: belongsTo('route-template-availability'),
    isFixedBreak: Ember.computed.equal('breakType.name', _breakType.FIXED_BREAK),
    isFloatingBreak: Ember.computed.equal('breakType.name', _breakType.FLOATING_BREAK)
  });

  _exports.default = _default;
});