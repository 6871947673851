define("adept-iq/adapters/address", ["exports", "adept-iq/adapters/-addressService", "adept-iq/config/api-urls"], function (_exports, _addressService, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _addressService.default.extend({
    ajax1: Ember.inject.service('ajax'),
    session: Ember.inject.service(),

    postAddress(address) {
      const session = this.get('session');
      const zoneName = address.get('zoneName.id');
      const json = {
        attributes: {
          alias: address.get('alias'),
          premise: address.get('premise'),
          streetNumber: address.get('streetNumber'),
          streetAddress: address.get('streetAddress'),
          locality: address.get('locality'),
          subLocality: address.get('subLocality'),
          subRegion: address.get('subRegion'),
          region: address.get('region'),
          postalCode: address.get('postalCode'),
          country: address.get('country'),
          notes: address.get('notes')
        },
        relationships: {
          zoneName: {
            data: {
              type: 'zoneName',
              id: zoneName
            }
          }
        },
        type: 'address'
      };
      return this.get('ajax1').post(_apiUrls.API.addressService.host + '/address', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    },

    patchAddress(address) {
      const session = this.get('session');
      const id = address.get('id');
      const json = {
        attributes: {
          alias: address.get('alias'),
          premise: address.get('premise'),
          streetNumber: address.get('streetNumber'),
          streetAddress: address.get('streetAddress'),
          locality: address.get('locality'),
          subLocality: address.get('subLocality'),
          subRegion: address.get('subRegion'),
          region: address.get('region'),
          postalCode: address.get('postalCode'),
          country: address.get('country'),
          notes: address.get('notes')
        },
        relationships: {
          zoneName: {
            data: {
              type: 'zoneName',
              id: address.get('zoneName.id')
            }
          }
        },
        type: 'address',
        id
      };
      return this.get('ajax1').patch(_apiUrls.API.addressService.host + '/address/' + id, {
        method: 'PATCH',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    },

    postAddressLocation(location, address) {
      const session = this.get('session');
      const json = {
        type: 'location',
        id: location.data.id
      };
      return this.get('ajax1').post(_apiUrls.API.addressService.host + `/address/${address.data.id}/relationships/locations`, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    }

  });

  _exports.default = _default;
});