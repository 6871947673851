define("adept-iq/models/perm-sso-user", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    userId: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    emailId: attr('string'),
    status: attr('string', {
      defaultValue: 'active'
    }),
    lastLoginAt: attr('date'),
    loginDetails: attr('object'),
    roles: attr('object'),
    providers: attr('object'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return `${this.get('firstName')} ${this.get('lastName')}`;
    }),
    calculatedStatus: Ember.computed('status', 'lastLoginAt', {
      get()
      /* key */
      {
        if (this.get('status') === 'active' && this.get('lastLoginAt') === null) {
          return 'pending';
        }

        return this.get('status');
      },

      set(key, value) {
        this.set('status', value);

        if (this.get('status') === 'active' && this.get('lastLoginAt') === null) {
          return 'pending';
        }

        return value;
      }

    }),
    roleNamesStr: Ember.computed('roles', function () {
      const roles = Ember.get(this, 'roles');

      if (!Ember.isEmpty(roles)) {
        const roleNameList = roles.map(role => role.displayName);
        return roleNameList.join(',');
      }

      return '';
    }),
    providerNamesStr: Ember.computed('providers', function () {
      const providers = Ember.get(this, 'providers');

      if (!Ember.isEmpty(providers)) {
        const roleNameList = providers.map(provider => provider.providerName);
        return roleNameList.join(',');
      }

      return '';
    }),
    userName: Ember.computed('loginDetails', function () {
      const loginData = Ember.get(this, 'loginDetails');
      if (Ember.isEmpty(loginData)) return '';
      const data = loginData.filterBy('userId', Ember.get(this, 'userId'));
      if (Ember.isEmpty(data)) return '';
      return data[0] && data[0].userName ? data[0].userName : '';
    }),
    passwordChangedDate: Ember.computed('loginDetails', function () {
      const loginData = Ember.get(this, 'loginDetails');
      if (Ember.isEmpty(loginData)) return '';
      const data = loginData.filterBy('userId', Ember.get(this, 'userId'));
      if (Ember.isEmpty(data)) return '';
      return data[0] && data[0].passwordUpdatedAt ? (0, _moment.default)(data[0].passwordUpdatedAt).format('MM/DD/YYYY') : '';
    }),
    formattedStatus: Ember.computed('calculatedStatus', function () {
      return this.get('calculatedStatus').charAt(0).toUpperCase() + this.get('calculatedStatus').substr(1);
    })
  });

  _exports.default = _default;
});