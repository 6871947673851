define("adept-iq/pods/components/side-drawer/system-config/modals/driver-master/component", ["exports", "ember-concurrency", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/models/break-type", "adept-iq/pods/components/side-drawer/system-config/modals/driver-master/config"], function (_exports, _emberConcurrency, _moment, _unwrapProxy, _breakType, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const extraBreakLengthOptions = {
    min: 1,
    max: 3
  };

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    // passed in attributes
    isDriverBreakModalEnabled: false,
    availability: null,
    breakTypes: null,
    // properties
    tableRef: null,
    searchText: null,
    selectedBreakType: null,
    undoShiftBreak: false,
    isSearchEnabled: false,
    disableForms: true,
    showValidations: false,
    disableBtns: null,
    hasOverlay: true,
    // default to true
    isTaskRunning: false,
    locationConfig: _config.locationFieldConfig,
    extraBreakLengthOptions,
    errorModel: null,
    selectedRow: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    selectedRecord: Ember.computed.readOnly('selectedRow.record'),
    isFixedBreak: Ember.computed.equal('selectedBreakType.name', _breakType.FIXED_BREAK),
    isFloatingBreak: Ember.computed.equal('selectedBreakType.name', _breakType.FLOATING_BREAK),
    shouldDisplayValidations: Ember.computed.or('showValidations').readOnly(),
    disableEditBtn: Ember.computed('selectedRecord', 'disableBtns.edit', function () {
      const edit = this.get('disableBtns.edit');
      const selectedRecord = this.get('selectedRecord');
      return !selectedRecord || edit;
    }),
    disableAddressSearch: Ember.computed('disableForms', 'isFloatingBreak', function () {
      const disableForms = this.get('disableForms');
      const isFloatingBreak = this.get('isFloatingBreak');
      return isFloatingBreak || disableForms;
    }),
    disableSaveBtn: Ember.computed('disableForms', 'shiftBreakChangeSet.{minutesPlannedDuration,requestTime,notes,breakType,place.address.freeformAddress}', function () {
      const disableForms = this.get('disableForms');
      const selectedRow = this.get('selectedRow');
      const isFixedBreak = this.get('isFixedBreak');
      const shiftBreakChangeSet = this.get('shiftBreakChangeSet');
      const breakType = (0, _unwrapProxy.unwrapProxy)(shiftBreakChangeSet.get('breakType'));
      const address = (0, _unwrapProxy.unwrapProxy)(shiftBreakChangeSet.get('place.address'));

      if (disableForms || !shiftBreakChangeSet) {
        return true;
      }

      const shiftBreakChanged = this.isShiftBreakChanged(shiftBreakChangeSet, selectedRow) || this.isBreakTypeChanged(breakType, selectedRow) || this.isPlaceChanged(address, isFixedBreak);
      return !shiftBreakChanged;
    }),

    isShiftBreakChanged(shiftBreakChangeSet, selectedRow) {
      const requestTime = shiftBreakChangeSet.get('requestTime');
      const minutesPlannedDuration = parseInt(shiftBreakChangeSet.get('minutesPlannedDuration'), 10);
      const notes = shiftBreakChangeSet.get('notes');

      if (!selectedRow) {
        if (requestTime || minutesPlannedDuration || notes) {
          return true;
        }
      } else if (requestTime !== selectedRow.get('requestTime') || minutesPlannedDuration !== parseInt(selectedRow.get('minutesPlannedDuration'), 10) || notes !== selectedRow.get('notes')) {
        return true;
      }

      return false;
    },

    isBreakTypeChanged(breakType, selectedRow) {
      const previousBreakType = selectedRow ? (0, _unwrapProxy.unwrapProxy)(selectedRow.get('breakType')) : this.get('defaultBreakType');
      return previousBreakType !== breakType;
    },

    isPlaceChanged(address, isFixedBreak) {
      if (address && isFixedBreak && Ember.isPresent(address.changedAttributes().freeformAddress)) {
        return true;
      }

      return false;
    },

    title: Ember.computed('availability.{routeTemplate.name,DOWSelected}', function () {
      const name = this.get('availability.routeTemplate.name');
      const DOW = this.get('availability.DOWSelected') || [];
      const DOWSelected = DOW.map(option => Ember.String.capitalize(option.day)).join(', ');
      const title = name ? `Driver Break(s) for Route ${name} - ${DOWSelected}` : `Driver Break(s) for New Route - ${DOWSelected}`;
      return title;
    }),
    shiftBreakChangeSet: Ember.computed('selectedRecord', 'undoShiftBreak', function () {
      const shiftBreak = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(shiftBreak);
    }),

    init() {
      this._super(...arguments);

      this.set('disableBtns', {
        new: false,
        save: true,
        edit: true,
        delete: true,
        undo: true
      });
      this.get('refreshTask').perform();
      const defaultBreakType = this.get('breakTypes').find(b => b.get('name') === _breakType.FLOATING_BREAK);
      this.set('defaultBreakType', defaultBreakType);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('dropdownActions', [{
        id: 'print',
        name: 'Download',
        action: () => {
          const tableRef = this.get('tableRef');
          tableRef.exportData();
        }
      }]);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {}),

    onCloseModal() {
      const isFloatingBreak = this.get('selectedRecord.isFloatingBreak');
      if (isFloatingBreak) this.clearPlace();
      this.set('isDriverBreakModalEnabled', false);
    },

    createPlaceRecord(perviousPlace) {
      const store = this.get('store'); // required type @TODO hacky might need to refactor for the future

      const placeCategoryType = store.peekAll('place-category-type').firstObject;
      const perviousAddress = perviousPlace ? (0, _unwrapProxy.unwrapProxy)(perviousPlace.get('address')) : null;
      const perviousLocation = perviousPlace ? (0, _unwrapProxy.unwrapProxy)(perviousPlace.get('location')) : null;
      let addressAttr = {};
      let locationAttr = {};

      if (perviousAddress) {
        addressAttr = perviousAddress.toJSON();
      }

      if (perviousLocation) {
        locationAttr = perviousLocation.toJSON();
      }

      const location = store.createRecord('location', locationAttr);
      const address = store.createRecord('address', addressAttr);
      const place = store.createRecord('place', {
        address: address,
        location: location,
        placeCategoryType
      });
      return place;
    },

    rollbackPlaceRecord(place) {
      if (place) {
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        if (address) address.rollbackAttributes();
        if (location) location.rollbackAttributes();
        place.rollbackAttributes();
      }
    },

    setDefaultProperties(record = null, disableForms = true) {
      const store = this.get('store');
      const modelClass = store.modelFor('shift-break');
      const floatBreakType = this.get('defaultBreakType');
      const fields = Array.from(modelClass.attributes.keys()).reduce((acu, key) => {
        acu[key] = null;
        return acu;
      }, {});
      fields.place = null;
      fields.breakType = floatBreakType;
      fields.requestTime = null;

      if (!Ember.isEmpty(record)) {
        Object.entries(fields).forEach(([key]) => {
          fields[key] = record.get(key);
        });
        fields.id = record.get('id');
        fields.place = this.createPlaceRecord((0, _unwrapProxy.unwrapProxy)(record.get('place'))); // relationship

        fields.breakType = (0, _unwrapProxy.unwrapProxy)(record.get('breakType')); // relationship
      }

      if (!(0, _unwrapProxy.unwrapProxy)(fields.place)) fields.place = this.createPlaceRecord(null);
      this.onBreakTypeChange((0, _unwrapProxy.unwrapProxy)(fields.breakType));

      if (record) {
        this.resetForm(disableForms);
        this.resetErrorModel();
      }

      return Ember.Object.create(fields);
    },

    resetForm(disableForms = true) {
      const disableBtns = {
        new: false,
        edit: false,
        delete: false,
        undo: true
      };
      Ember.run.scheduleOnce('afterRender', this, () => {
        this.set('disableBtns', disableBtns);
        this.set('disableForms', disableForms);
      });
    },

    onSaveShiftBreakTask: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      const availabilityChangeSet = this.get('availability');
      const availability = this.getAvailability(availabilityChangeSet);
      const shiftBreakChangeSet = this.get('shiftBreakChangeSet');
      const selectedRow = this.get('selectedRow');
      const selectedRecord = this.get('selectedRecord');
      const store = this.get('store');
      const shiftBreaks = availability.get('shiftBreaks');
      const isFloatingBreak = this.get('isFloatingBreak');
      let pendingRecord = this.get('pendingRecord');
      if (isFloatingBreak) this.clearPlace();
      const valid = this.validateRecord(shiftBreakChangeSet, availability);

      if (valid) {
        if (selectedRecord) {
          selectedRecord.setProperties(shiftBreakChangeSet);
          selectedRow.set('selected', false);
          pendingRecord = selectedRecord;
        } else if (!pendingRecord) {
          pendingRecord = store.createRecord('shift-break', shiftBreakChangeSet);
          this.set('pendingRecord', pendingRecord);
        } else {
          pendingRecord.setProperties(shiftBreakChangeSet);
        }

        pendingRecord.set('routeTemplateAvailability', availability);

        if (pendingRecord.get('isNew')) {
          shiftBreaks.pushObject(pendingRecord);
        }

        availabilityChangeSet.set('shiftBreaks', shiftBreaks);
        this.set('pendingRecord', null);
        this.resetForm();
        yield availabilityChangeSet.execute();
        yield Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      }
    }),

    //availabilityChangeSet.data will not return the availability model with latest changed attributes
    getAvailability(availabilityChangeSet) {
      const availability = availabilityChangeSet.get('data');
      const availabilitySnapshot = availabilityChangeSet.snapshot();
      const availabilitychagedAttr = availabilitySnapshot.changes;
      Object.entries(availabilitychagedAttr).forEach(([key]) => {
        availability.set(key, availabilitychagedAttr[key]);
      });
      return availability;
    },

    onDeleteShiftBreakTask: (0, _emberConcurrency.task)(function* () {
      const selectedRecord = this.get('selectedRecord');
      const tableRef = this.get('tableRef');
      const availabilityChangeSet = this.get('availability');

      if (selectedRecord) {
        selectedRecord.deleteRecord(); // mark the record as deleted, then in master save call save function to persist deletion

        const shiftBreaks = availabilityChangeSet.get('shiftBreaks');
        const deletedShiftBreaks = availabilityChangeSet.get('deletedShiftBreaks') || [];
        shiftBreaks.removeObject(selectedRecord);
        deletedShiftBreaks.pushObject(selectedRecord);
        availabilityChangeSet.set('shiftBreaks', shiftBreaks);
        availabilityChangeSet.set('deletedShiftBreaks', deletedShiftBreaks);
        yield Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
      }
    }),

    validateShiftBreak(errors, shiftBreakChangeSet, availability) {
      const shiftStartTime = this.getTransformedDate(availability.get('shiftStart'));
      const calcEndDate = this.getTransformedDate(availability.get('calcEndDate'));
      const requestTime = this.getTransformedDate(shiftBreakChangeSet.get('requestTime'));
      const minutesPlannedDuration = shiftBreakChangeSet.get('minutesPlannedDuration');
      const isFixedBreak = this.get('isFixedBreak');
      let validShiftBreakRecord = true;

      if (!minutesPlannedDuration) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Break Length cannot be blank'
        });
        this.set('errorModel.invalidLength', !validShiftBreakRecord);
      }

      if (!requestTime) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Start Time cannot be blank'
        });
        this.set('errorModel.invalidRequestTime', !validShiftBreakRecord);
      }

      if (!this.get('errorModel.invalidRequestTime') && !this.requestTimeInrange(requestTime, shiftStartTime, calcEndDate)) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Break time must be within route start and end times'
        });
        this.set('errorModel.invalidRequestTime', !validShiftBreakRecord);
      }

      if (isFixedBreak && Ember.isEmpty(shiftBreakChangeSet.get('place.location.latlng'))) {
        validShiftBreakRecord = false;
        errors.pushObject({
          'validation': 'Address cannot be blank'
        });
        this.set('errorModel.invalidAddress', !validShiftBreakRecord);
      }

      return validShiftBreakRecord;
    },

    // Checks if requestTime  is between dates in start and end.
    requestTimeInrange(requestTime, startDate, endDate) {
      return (0, _moment.default)(requestTime).isBetween(startDate, endDate);
    },

    getTransformedDate(date) {
      if (!date) return null;
      const recordDate = (0, _moment.default)(date);
      const momentTime = (0, _moment.default)(new Date(null));
      momentTime.set('hour', recordDate.get('hour'));
      momentTime.set('minute', recordDate.get('minute'));
      momentTime.set('second', recordDate.get('second'));
      return momentTime.toDate();
    },

    validateRecord(shiftBreakChangeSet, availability) {
      const tooltip = this.get('tooltip');
      const errors = [];
      this.resetErrorModel();
      const validShiftBreak = this.validateShiftBreak(errors, shiftBreakChangeSet, availability);

      if (!validShiftBreak) {
        tooltip.pushConfirmation({
          title: 'System Configuration',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
      }

      this.set('showValidations', !validShiftBreak);
      return validShiftBreak;
    },

    clearRecordAttributes(record) {
      const store = this.get('store');
      const modelClass = store.modelFor(record.constructor.modelName);
      Array.from(modelClass.attributes.keys()).forEach(key => {
        record.set(key, null);
      });
    },

    onBreakTypeChange(breakType) {
      if (breakType) {
        this.set('selectedBreakType', breakType);
      }
    },

    onDateChange(record, path, dateStr) {
      record.set(path, dateStr);
    },

    resetErrorModel() {
      const errorModel = {
        invalidRequestTime: false,
        invalidLength: false,
        invalidAddress: false,
        invalidNotes: false
      };
      this.set('errorModel', errorModel);
    },

    clearPlace() {
      const shiftBreakChangeSet = this.get('shiftBreakChangeSet');
      const place = (0, _unwrapProxy.unwrapProxy)(shiftBreakChangeSet.get('place'));
      const address = place ? (0, _unwrapProxy.unwrapProxy)(place.get('address')) : null;
      const location = place ? (0, _unwrapProxy.unwrapProxy)(place.get('location')) : null;
      if (place) this.clearRecordAttributes(place);
      if (address) this.clearRecordAttributes(address);
      if (location) this.clearRecordAttributes(location);
    },

    actions: {
      // header button functions
      newDriverBreak() {
        const disableBtns = {
          new: true,
          edit: true,
          delete: true,
          undo: true
        };
        const selectedRow = this.get('selectedRow');
        if (selectedRow) selectedRow.set('selected', false); // triggers cp and on save it should create a new record unless user selects a new record

        this.set('disableBtns', disableBtns);
        this.set('disableForms', false);
        this.resetErrorModel();
      },

      editDriverBreak() {
        const disableBtns = {
          new: true,
          edit: true,
          delete: true,
          undo: false
        };
        this.set('disableBtns', disableBtns);
        this.set('disableForms', false);
      },

      undoDriverBreak() {
        this.toggleProperty('undoShiftBreak');
        this.resetErrorModel();
      },

      saveDriverBreak() {
        this.get('onSaveShiftBreakTask').perform();
      },

      deleteDriverBreak() {
        this.get('onDeleteShiftBreakTask').perform();
      },

      onPrimaryActionButtonClick() {
        const selectedRecord = this.get('selectedRecord');

        if (selectedRecord) {
          this.get('queryModal.primaryActionTask').perform(selectedRecord).then(() => {
            this.onCloseModal();
          });
        }
      },

      onCloseModal() {
        this.onCloseModal();
      },

      onClickOverlayModal() {
        const hasOverlay = this.get('hasOverlay');

        if (hasOverlay) {
          return false;
        }

        this.get('queryModal').reset();
      },

      onHelp() {},

      toggleSearchInput() {
        this.toggleProperty('isSearchEnabled');
      },

      onSearchTextChange(searchText) {
        const previousSearchText = this.get('searchText');

        if (searchText === previousSearchText) {
          return;
        }

        const tableRef = this.get('tableRef');

        if (!tableRef) {
          return;
        }

        this.set('searchText', searchText);
        tableRef.onSearchTextChange(searchText);
      },

      onClearSearchText() {
        this.set('searchText', '');
        const tableRef = this.get('tableRef');

        if (!tableRef) {
          return;
        }

        tableRef.onClearSearchText();
      },

      onDropDownActionClick(action, dropdown) {
        dropdown.actions.close();
        if (action.action) action.action();
      },

      onDateChange(record, path, value) {
        this.onDateChange(record, path, value);
      },

      onCellValueChange(record, valuePath, value, options) {
        if (options.valuePath) {
          return record.set(`${valuePath}.${options.valuePath}`, value);
        }

        return record.set(valuePath, value);
      },

      onInputValueChange(record, valuePath, value) {
        return record.set(valuePath, value);
      },

      // power select event handler
      onBreakTypeChange(option) {
        this.set('shiftBreakChangeSet.breakType', option);
        this.onBreakTypeChange(option);
      }

    }
  });

  _exports.default = _default;
});