define("adept-iq/pods/components/iq-widgets/cancel-booking-form/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "adept-iq/config/api-urls"], function (_exports, _component, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['cancel-booking-form-side-drawer'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    booking: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    bookingId: null,
    tripIds: null,
    isProgressShown: false,
    editableSections: null,
    note: null,
    selectedCancelType: null,
    canSave: true,
    cancelStatus: null,

    init() {
      this._super(...arguments);

      const bookingID = this.get('editModal.editableRecords')[0].get('id');
      this.set('bookingId', bookingID);
      this.set('cancelStatus', this.get('editModal.editableRecords')[1]);
      const filteredTrips = this.get('booking.currentTrips').filter(trip => bookingID === trip.get('booking.id'));
      this.set('tripIds', filteredTrips.map(t => t.get('id')));
      this.set('editableSections', []);
      this.setCancelTypeInitialSelection();
    },

    setCancelTypeInitialSelection() {
      const cancelTypeOptions = this.store.peekAll('cancel-type');
      this.set('selectedCancelType', cancelTypeOptions.firstObject);
    },

    didInsertElement() {
      this._super(...arguments);
    },

    saveCancellation() {
      const booking = this.store.peekRecord('booking', this.get('bookingId'));
      const cancellation = booking.get('cancellation');
      const session = this.get('session');
      const prepareCancelData = {
        data: {
          links: {},
          meta: {},
          attributes: {
            notes: cancellation.get('notes')
          },
          relationships: {
            cancelTypeName: {
              data: {
                id: cancellation.get('cancelType.id'),
                type: 'cancelTypeName',
                links: {}
              },
              meta: {}
            }
          },
          id: this.get('bookingId'),
          type: 'cancellation'
        }
      };
      const json = JSON.stringify(prepareCancelData);
      this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking/' + this.get('bookingId') + '/cancellation', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      }).then(async () => {
        this.get('notifications').success(`BOOKING ID  ${this.get('bookingId')} WAS CANCELED SUCCESSFULLY`);
        const tripIdsClause = this.get('tripIds').map(r => `'${r}'`).join(',');
        const tripQuery = `in(id,(${tripIdsClause}))`;
        await this.store.query('trip', {
          filter: tripQuery
        });
        this.get('editModal').reset();
        this.get('editModal').close();
        booking.set('status', this.get('cancelStatus')); // todo: server is not returning status . temporary code
      }).catch(error => {
        this.get('notifications').warning(`FAILED TO CANCEL BOOKING ID  ${this.get('bookingId')} ${error.message}`);
        this.get('editModal').reset();
        this.get('editModal').close();
      });
    },

    actions: {
      onUndoClick() {
        this.setCancelTypeInitialSelection();
        this.set('note', null);
      },

      onSaveClick() {
        this.toggleProperty('isProgressShown');
      },

      onXButtonClick() {
        const booking = this.store.peekRecord('booking', this.get('bookingId'));
        booking.set('cancellation', null);
        this.toggleProperty('isProgressShown');
      },

      onCloseClick() {
        this.get('editModal').close();
      },

      onConfirmClick() {
        const booking = this.store.peekRecord('booking', this.get('bookingId'));
        const cancelType = this.get('selectedCancelType');
        const cancellation = this.store.createRecord('cancellation', {
          notes: this.get('note'),
          cancelType: cancelType
        });
        booking.set('cancellation', cancellation);
        this.saveCancellation();
      },

      onNoteChange(note) {
        this.set('note', note);
      },

      onSelectedCancelTypeChange(cancelType) {
        this.set('selectedCancelType', cancelType);
      }

    }
  });

  _exports.default = _default;
});