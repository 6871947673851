define("adept-iq/adapters/dashboard", ["exports", "adept-iq/adapters/application", "lodash"], function (_exports, _application, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_WORKSPACE_CATEGORIES = void 0;
  const DEFAULT_WORKSPACE_CATEGORIES = ['workspaces-playback', 'workspaces-permissions', 'workspaces-reconcile', 'workspaces-booking', 'workspaces-schedule', 'workspaces-dispatch', 'workspaces-roadsup'];
  _exports.DEFAULT_WORKSPACE_CATEGORIES = DEFAULT_WORKSPACE_CATEGORIES;
  const USER_WORKSPACE_CATEGORIES = ['workspaces-users'];
  const WORKSPACE_CATEGORIES = DEFAULT_WORKSPACE_CATEGORIES.concat(USER_WORKSPACE_CATEGORIES);

  var _default = _application.default.extend({
    findRecord(store, type, id
    /*, snapshot*/
    ) {
      return store.findRecord('cs-config-item', id).then(configItem => {
        return _lodash.default.cloneDeep(configItem.get('value'));
      });
    },

    findAll(store, type
    /*, sinceToken, snapshotRecordArray*/
    ) {
      return this.query(store, type, {
        categories: WORKSPACE_CATEGORIES
      });
    },

    query(store, type, query = {}
    /*, recordArray*/
    ) {
      const {
        categories = WORKSPACE_CATEGORIES
      } = query;
      delete query.categories;
      const promiseHash = categories.reduce((hash, category) => {
        hash[category] = this._fetchConfigItemsByCategory(store, category);
        return hash;
      }, {});
      return Ember.RSVP.hash(promiseHash).then(resultHash => {
        return categories.reduce((obj, category) => {
          resultHash[category].data.forEach(data => {
            obj.data.push(data);
          });
          Ember.makeArray(resultHash[category].included).forEach(data => {
            obj.included.push(data);
          });
          return obj;
        }, {
          data: [],
          included: []
        });
      });
    },

    createRecord(store, type, snapshot) {
      const {
        name,
        category
      } = snapshot.attributes();
      snapshot.id = `${category}/${this._keyForName(name)}`;
      const data = {};
      const serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      const record = store.createRecord('cs-config-item', {
        name: this._keyForName(name),
        displayName: name,
        category,
        type: 'json',
        value: data
      });
      return record.save().then(configItem => {
        return _lodash.default.cloneDeep(configItem.get('value'));
      }).catch(e => {
        console.log(e); //eslint-disable-line no-console

        record.rollbackAttributes();
      });
    },

    deleteRecord(store, type, snapshot) {
      const configItem = store.peekRecord('cs-config-item', snapshot.id);
      return configItem.destroyRecord().then(() => {
        // Avoid configItem model id not clear issue.
        configItem.unloadRecord(); // TODO: remove hack when ember data upgraded. https://github.com/emberjs/data/issues/4972

        store._removeFromIdMap(configItem._internalModel); // have to swallow promise return value so ED won't try to parse it


        return null;
      });
    },

    updateRecord(store, type, snapshot) {
      const serialized = {};
      const serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(serialized, type, snapshot, {
        includeId: true
      });
      const configItem = store.peekRecord('cs-config-item', snapshot.id);
      configItem.set('value', serialized);
      return configItem.save().then(() => {
        return serialized;
      });
    },

    _fetchConfigItemsByCategory(store, category) {
      return store.query('cs-config-item', {
        category
      }).then(configItems => {
        return {
          data: configItems.map(configItem => {
            const {
              data
            } = _lodash.default.cloneDeep(configItem.get('value')); // use the configItem's category so we know if it is a default item.


            data.attributes.category = configItem.category;
            return data;
          })
        };
      });
    },

    _keyForName(name) {
      // config service only accepts /[a-zA-Z0-9_]+/; note that this conversion
      // is not injective, so collisions are possible
      return escape(Ember.String.underscore(name)).replace(/%/g, '_');
    }

  });

  _exports.default = _default;
});