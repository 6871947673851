define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/equipment/component", ["exports", "moment", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/equipment/equipment-validation", "adept-iq/config/placeholders"], function (_exports, _moment, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _equipmentValidation, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fields = {
    specialized: '',
    installDate: '',
    type: ''
  };
  const dropDownFields = {
    maintenanceLocationName: ''
  };

  var _default = Ember.Component.extend({
    classNames: ['equipment'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Placeholders
    MAINTENANCE_LOCATION_PLACEHOLDER: _placeholders.MAINTENANCE_LOCATION_PLACEHOLDER,
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    TYPE_PLACEHOLDER: _placeholders.TYPE_PLACEHOLDER,
    disableBtns: null,
    refreshRecord: true,
    //To refresh on undoRecord and newRecord
    isNewRecord: false,
    disableForm: true,
    deleteBtn: true,
    showErrorMsg: false,
    timeFormat: 'Y-m-d h:i K',
    // passed into view
    editAPI: null,
    selectedVehicle: Ember.computed.readOnly('editAPI.selectedVehicle'),
    selectedRow: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedEquipmentRow: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    vehicleEquipment: Ember.computed('selectedRow', 'refreshRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRow'));
      return this.setDefaultProperties(record);
    }),
    hasTemplateChanged: Ember.computed('vehicleEquipment.isDirty', function () {
      const vehicleEquipment = this.get('vehicleEquipment');
      if (vehicleEquipment.get('isDirty')) return true;
      return false;
    }),

    init() {
      this._super(...arguments);

      this.set('timePlaceHolder', 'MM-DD-YYYY hh:mm');
      this.setupDropDownValues();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleEquipmentRecord = this.get('onSaveVehicleEquipmentRecord');
      const onNewVehicleEquipmentRecord = this.get('onNewVehicleEquipmentRecord').bind(this);
      const onEditVehicleEquipmentRecord = this.get('onEditVehicleEquipmentRecord').bind(this);
      const onDeleteVehicleEquipmentRecord = this.get('onDeleteVehicleEquipmentRecord');
      const onUndoVehicleEquipmentRecord = this.get('onUndoVehicleEquipmentRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleEquipmentRecord,
          newAction: onNewVehicleEquipmentRecord,
          deleteAction: onDeleteVehicleEquipmentRecord,
          editAction: onEditVehicleEquipmentRecord,
          undoAction: onUndoVehicleEquipmentRecord
        });
        this.buttonSettings();
      });
    },

    setDefaultProperties(record = []) {
      let equipmentRecord = record;

      if (Ember.isEmpty(equipmentRecord) && this.get('isNewRecord')) {
        const store = this.get('store');
        equipmentRecord = store.createRecord('vehicle-equipment');
        const today = (0, _moment.default)(new Date()).toISOString();
        equipmentRecord.set('installDate', today);
      } else {
        this.set('disableForm', true);
        Ember.run.scheduleOnce('afterRender', this, 'buttonSettings');
      }

      if (Ember.isEmpty(equipmentRecord)) {
        equipmentRecord = Ember.Object.create(fields, dropDownFields);
      }

      this.set('showErrorMsg', false);
      return new _emberChangeset.default(equipmentRecord, (0, _emberChangesetValidations.default)(_equipmentValidation.VALIDATIONS), _equipmentValidation.VALIDATIONS);
    },

    setupDropDownValues() {
      const store = this.get('store');
      const dropDownModels = ['maintenance-location-name'];
      dropDownModels.forEach(model => {
        const records = store.peekAll(model);
        this.set(model, records);
      });
    },

    buttonSettings(deleteBtn = false, editBtn = false, saveBtn = true, undoBtn = true, newBtn = false) {
      const editAPI = this.get('editAPI');
      editAPI.setProperties({
        deleteBtn,
        editBtn,
        saveBtn,
        undoBtn,
        newBtn
      });
    },

    rollbackRecord(record = null) {
      if (record && !record.get('isNew')) record.rollbackAttributes();
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const vehicleEquipment = this.get('vehicleEquipment');
      yield vehicleEquipment.validate();
      const errors = vehicleEquipment.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveVehicleEquipmentRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();

      if (isValid) {
        const notifications = this.get('notifications');
        const vehicleEquipmentRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleEquipment'));
        const isNewRecord = this.get('isNewRecord');
        const selectedVehicle = this.get('selectedVehicle');

        if (isNewRecord) {
          vehicleEquipmentRecord.set('vehicle', selectedVehicle);
        }

        try {
          yield vehicleEquipmentRecord.save();
          notifications.success('Record successfully saved');
          this.set('isNewRecord', false);
          this.get('tableRef').refreshData(); //to refresh table data

          this.buttonSettings();
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onEditVehicleEquipmentRecord() {
      this.set('disableForm', false);
      this.buttonSettings(true, true, false, false, true);
    },

    onDeleteVehicleEquipmentRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRow = this.get('selectedRow');
      const notifications = this.get('notifications');
      yield selectedRow.destroyRecord();

      try {
        notifications.success('Record successfully deleted');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (e) {
        notifications.warning('Record failed to delete');
        console.warn(e); // eslint-disable-line
      }
    }),

    onUndoVehicleEquipmentRecord() {
      this.toggleProperty('refreshRecord');
    },

    onNewVehicleEquipmentRecord() {
      this.set('isNewRecord', true);
      this.get('tableRef').onUncheckAll();
      this.set('disableForm', false);
      this.toggleProperty('refreshRecord');
      this.buttonSettings(true, true, false, false, true);
    },

    actions: {
      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      }

    }
  });

  _exports.default = _default;
});