define("adept-iq/pods/application/controller", ["exports", "adept-iq/utils/mobile", "adept-iq/config/environment"], function (_exports, _mobile, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // these are the top-level things that can focus can be rotated through
  const FOCUSABLE_WIDGET_SELECTOR = '.header-nav, .dashboard-widget'; // used to select first control to receive browser focus on rotation

  const INITIAL_CONTROL_SELECTOR = '.initial-focus-control';
  const GENERIC_CONTROL_SELECTOR = 'input, button, .ember-basic-dropdown-trigger';

  var _default = Ember.Controller.extend({
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    geocode: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    isWidgetMinified: Ember.computed.alias('maximizer.isWidgetMinified'),
    data: null,
    avlmLite: false,

    // remove this for avlm lite 2.1 and above
    init() {
      this._super(...arguments);

      this.set('isTouchDevice', _mobile.isTouchDevice);

      this._onKeyDown = event => {
        Ember.run.schedule('actions', this, 'onKeyDown', event);
      };

      document.body.addEventListener('keydown', this._onKeyDown);
      this.set('avlmLite', _environment.default.APP.avlmLite); // remove this for avlm lite 2.1 and above

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile) {
        Ember.$('body').addClass('mobile-view');
      } else {
        Ember.$('body').removeClass('mobile-view');
      }
    },

    destroy() {
      document.body.removeEventListener('keydown', this._onKeyDown);

      this._super(...arguments);
    },

    onKeyDown(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.get('geocode').deactivateGeocode();
      } // don't activate global shortcuts when editing a form


      if (event.target.tagName === 'INPUT') return; // shortcut for next widget

      if (event.key === '`' && !event.altKey && !event.ctrlKey) {
        event.preventDefault();
        this.rotateFocus();
      } // shortcut for previous widget


      if (event.key === '~' && !event.altKey && !event.ctrlKey) {
        event.preventDefault();
        this.rotateFocus(-1);
      }
    },

    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),
    isExported: Ember.computed.readOnly('workspace.isExported'),

    rotateFocus(offset = 1) {
      let nextElement;
      const focusables = Array.from(document.querySelectorAll(FOCUSABLE_WIDGET_SELECTOR));
      const closestFocusable = document.activeElement.closest(FOCUSABLE_WIDGET_SELECTOR);

      if (closestFocusable) {
        const index = focusables.indexOf(closestFocusable);
        const nextIndex = (index + offset + focusables.length) % focusables.length;
        nextElement = focusables[nextIndex];
      } else {
        nextElement = focusables[0];
      }

      if (nextElement) {
        const control = nextElement.querySelector(INITIAL_CONTROL_SELECTOR) || nextElement.querySelector(GENERIC_CONTROL_SELECTOR);

        if (control) {
          control.focus();
        }
      }
    }

  });

  _exports.default = _default;
});