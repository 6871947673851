define("adept-iq/pods/components/iq-widgets/permissions-users-widget/config", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const cellComponent = 'generic-components/table/cells/check-box';
  const columnComponent = 'generic-components/table/columns/check-box';
  const rowComponent = 'table/rows/action-row';
  var _default = {
    component: 'iq-widgets/users-widget',
    cellComponent,
    rowComponent,
    columnComponent,
    modelName: 'perm-sso-user',
    title: 'Users',
    columns: [{
      id: 'id',
      width: '30px',
      valuePath: 'id',
      resizable: false,
      unAvailable: true,
      cellComponent: 'iq-widgets/permissions-users-widget/table/cells/check-box',
      component: columnComponent
    }, {
      id: 'firstName',
      dataType: 'text',
      label: 'First Name',
      valuePath: 'firstName',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 105,
      width: '105px'
    }, {
      id: 'lastName',
      dataType: 'text',
      label: 'Last Name',
      valuePath: 'lastName',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 105,
      width: '105px'
    }, {
      id: 'calculatedStatus',
      dataType: 'text',
      label: 'Status',
      valuePath: 'calculatedStatus',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 85,
      width: '85px',
      format: function (valuePath) {
        return valuePath.charAt(0).toUpperCase() + valuePath.substr(1);
      }
    }, {
      id: 'roleNamesStr',
      dataType: 'text',
      label: 'Role',
      valuePath: 'roleNamesStr',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 170,
      width: '170px'
    }, {
      id: 'passwordChangedDate',
      dataType: 'time',
      label: 'Password Last Changed',
      valuePath: 'passwordChangedDate',
      searchable: true,
      resizable: true,
      hidden: false,
      minResizeWidth: 120,
      width: '120px'
    }, {
      id: 'userName',
      dataType: 'text',
      label: 'Username',
      valuePath: 'userName',
      searchable: true,
      resizable: true,
      hidden: true,
      minResizeWidth: 150,
      width: '150px'
    }, {
      id: 'providerNamesStr',
      dataType: 'text',
      label: 'Provider',
      valuePath: 'providerNamesStr',
      searchable: true,
      resizable: true,
      hidden: true,
      minResizeWidth: 90,
      width: '90px'
    }],
    filters: {
      text: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && item.toString().trim().toLowerCase() === values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'notEqualTo',
        label: '!=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && Ember.isEmpty(values[0]) || Ember.isEmpty(item) && !Ember.isEmpty(values[0]) || item.toString().trim().toLowerCase() !== values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'contains',
        label: 'Contains',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && item.toString().trim().toLowerCase().indexOf(values[0].toString().trim().toLowerCase()) !== -1;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (!Ember.isEmpty(item)) return false;
          return true;
        }
      }],
      uuid: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && item.toString().trim().toLowerCase() === values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'notEqualTo',
        label: '!=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && Ember.isEmpty(values[0]) || Ember.isEmpty(item) && !Ember.isEmpty(values[0]) || item.toString().trim().toLowerCase() !== values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'contains',
        label: 'Contains',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && item.toString().trim().toLowerCase().indexOf(values[0].toString().trim().toLowerCase()) !== -1;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (!Ember.isEmpty(item)) return false;
          return true;
        }
      }],
      number: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && parseFloat(item) === parseFloat(values[0]);
        }
      }, {
        id: 'notEqualTo',
        label: '!=',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && Ember.isEmpty(values[0]) || Ember.isEmpty(item) && !Ember.isEmpty(values[0]) || item.toString().trim().toLowerCase() !== values[0].toString().trim().toLowerCase();
        }
      }, {
        id: 'greaterThan',
        label: '>',
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && parseFloat(item) > parseFloat(values[0]);
        }
      }, {
        id: 'lessThan',
        label: '<',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && parseFloat(item) < parseFloat(values[0]);
        }
      }, {
        id: 'between',
        label: 'Between',
        arity: 2,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && !Ember.isEmpty(values[1]) && values[1] > values[0] && item <= values[1] && item >= values[0];
        }
      }, {
        id: 'contains',
        label: 'Contains',
        arity: 1,
        exp: function (item, values) {
          return !Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && item.toString().trim().toLowerCase().indexOf(values[0].toString().trim().toLowerCase()) !== -1;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (!Ember.isEmpty(item)) return false;
          return true;
        }
      }],
      time: [{
        id: 'equalTo',
        label: '=',
        arity: 1,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid()) return item === values[0];
          return false;
        }
      }, {
        id: 'notEqualTo',
        label: '!=',
        arity: 1,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid()) return item !== values[0];
          return false;
        }
      }, {
        id: 'after',
        label: 'After',
        arity: 1,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid()) return item > values[0];
          return false;
        }
      }, {
        id: 'before',
        label: 'Before',
        arity: 1,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid()) return item < values[0];
          return false;
        }
      }, {
        id: 'atOrBefore',
        label: 'At or Before',
        arity: 1,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid()) return item <= values[0];
          return false;
        }
      }, {
        id: 'atOrAfter',
        label: 'At or After',
        arity: 1,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid()) return item >= values[0];
          return false;
        }
      }, {
        id: 'between',
        label: 'Between',
        arity: 2,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && values[1] && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[1], 'MM/DD/YYYY', true).isValid()) return values[1] > values[0] && item <= values[1] && item >= values[0];
          return false;
        }
      }, {
        id: 'strictlyBetween',
        label: 'Strictly Between',
        arity: 2,
        exp: function (item, values) {
          if (!Ember.isEmpty(item) && !Ember.isEmpty(values[0]) && values[1] && (0, _moment.default)(item, 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[0], 'MM/DD/YYYY', true).isValid() && (0, _moment.default)(values[1], 'MM/DD/YYYY', true).isValid()) return values[1] > values[0] && item < values[1] && item > values[0];
          return false;
        }
      }, {
        id: 'empty',
        label: 'Empty',
        arity: 1,
        exp: function (item) {
          if (!Ember.isEmpty(item)) return false;
          return true;
        }
      }]
    }
  };
  _exports.default = _default;
});