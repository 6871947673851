define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/availability/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2gcOeO0I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n\"],[1,[27,\"generic-components/availabilities\",null,[[\"availablities\",\"availabilityRef\",\"modelName\",\"shouldDisplayValidations\"],[[23,[\"availablities\"]],[23,[\"availabilityRef\"]],[23,[\"modelName\"]],[23,[\"shouldDisplayValidations\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/availability/template.hbs"
    }
  });

  _exports.default = _default;
});