define("adept-iq/pods/components/reoptimize-modal/operation-views/adjust-driver-break/component", ["exports", "ember-concurrency", "adept-iq/mixins/async-schedule-operation"], function (_exports, _emberConcurrency, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    classNames: ['adjust-driver-break'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    isTaskRunning: Ember.computed.alias('adjustDriverBreak.isRunning'),
    // Passed In Attributes
    title: null,
    currentSchedule: null,
    tableRef: null,
    refreshData: null,
    checkedRows: Ember.computed.alias('tableRef.checkedRows'),
    disableAdjustDriverBreakButton: Ember.computed.lt('checkedRows.length', 1),

    init() {
      this._super(...arguments);
    },

    // be able to adjust from selected rows
    adjustDriverBreak: (0, _emberConcurrency.task)(function* (routes) {
      const currentSchedule = this.get('currentSchedule');
      const routeIds = routes.map(route => parseInt(route.get('id'), 10));
      let result;

      try {
        const options = {
          operationData: {
            'routes': routeIds
          }
        };
        result = yield this.createScheduleAsyncOperation.perform(currentSchedule, 'adjustDriverBreaks', options);

        if (result) {
          this.get('notifications').success('Driver breaks successfully adjusted');
          routes.forEach(record => {
            record.set('isAdjusted', true);
          });
        } else {
          this.get('notifications').warning('Driver breaks failed to be adjusted');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          this.get('notifications').warning('Driver breaks failed to be adjusted');
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),
    actions: {
      onAdjustDriverBreakClick() {
        const routes = this.get('checkedRows') || [];
        this.adjustDriverBreak.perform(routes);
      }

    }
  });

  _exports.default = _default;
});