define("adept-iq/pods/components/side-drawer/system-config/contract-management/table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'trim-slack-contract-management',
    title: 'Contract Management',
    defaultSortId: 'provider',
    defaultSortAsc: true,
    selectFirstRow: true,
    fetchModelData: true,
    // hideWidgetControls: true,
    columns: [{
      id: 'provider',
      index: 0,
      type: 'string',
      label: 'Provider',
      valuePath: 'providerName',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'stratRoute',
      index: 1,
      type: 'uuid',
      //adept 4 is no longer part of the system, route's will include characters
      label: 'Start Route',
      valuePath: 'startRouteName',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'endRoute',
      index: 2,
      type: 'uuid',
      // adept 4 is no longer part of the system, route's will include characters
      label: 'End Route',
      valuePath: 'endRouteName',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'trimSlackmin',
      index: 3,
      type: 'date',
      label: 'Adjust Mins',
      valuePath: 'adjustMins',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'trimslackMinRouteLength',
      index: 4,
      type: 'date',
      label: 'Min Route Length',
      valuePath: 'convertToHourMinutes',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'pullOutMinCap',
      index: 5,
      type: 'date',
      label: 'Pull Out Min Cap',
      valuePath: 'pullOutMinCap',
      defaultWidth: 100,
      searchable: true
    }, {
      id: 'pullOutMaxCap',
      index: 6,
      type: 'date',
      label: 'Pull Out Max Cap',
      valuePath: 'pullOutMaxCap',
      defaultWidth: 90,
      searchable: true
    }, {
      id: 'pullInMinCap',
      index: 7,
      type: 'date',
      label: 'Pull In Min Cap',
      valuePath: 'pullInMinCap',
      defaultWidth: 90,
      searchable: true
    }, {
      id: 'pullInMaxCap',
      index: 8,
      type: 'date',
      label: 'Pull In Max Cap',
      valuePath: 'pullInMaxCap',
      defaultWidth: 90,
      searchable: true
    }]
  };
  _exports.default = _default;
});