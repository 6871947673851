define("adept-iq/serializers/place", ["exports", "adept-iq/serializers/-address"], function (_exports, _address) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _address.default.extend({
    keyForAttribute: function (attr) {
      return attr;
    },

    serialize(snapshot, options) {
      const json = this._super(snapshot, options);

      if (!Ember.isNone(json.data.relationships)) {
        const placeCategoryTypeRelationship = json.data.relationships.placeCategoryType;
        const originSubscription = json.data.relationships.originSubscription;
        const destinationSubscription = json.data.relationships.destinationSubscription;

        if (placeCategoryTypeRelationship) {
          json.data.relationships.placeCategoryTypeName = {
            data: {
              type: 'placeCategoryTypeName',
              id: placeCategoryTypeRelationship.data.id
            }
          };
          delete json.data.relationships.placeCategoryType;
        }

        if (!Ember.isNone(originSubscription)) {
          delete json.data.relationships.originSubscription;
        }

        if (!Ember.isNone(destinationSubscription)) {
          delete json.data.relationships.destinationSubscription;
        }
      }

      return json;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    },

    normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
      const {
        modelName
      } = primaryModelClass; // eslint-disable-next-line no-param-reassign

      id = this.extractId(primaryModelClass, payload.data);

      if (store.hasRecordForId(modelName, id)) {
        const record = store.peekRecord(modelName, id);
        store.unloadRecord(record);
        return this._super(store, primaryModelClass, payload, id, requestType);
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.placeCategoryTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.placeCategoryType = {
          data: {
            type: 'place-category-type',
            id: resourceHash.attributes.placeCategoryTypeName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.placeCategoryTypeName;
      }

      if (resourceHash.relationships) {
        if (!Ember.isNone(resourceHash.relationships.placeCategoryTypeName)) {
          resourceHash.relationships.placeCategoryType = resourceHash.relationships.placeCategoryTypeName;
          resourceHash.relationships.placeCategoryType.data.type = 'place-category-type';
          delete resourceHash.relationships.placeCategoryTypeName;
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});