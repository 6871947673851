define("adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/component", ["exports", "ember-concurrency", "ember-light-table", "moment", "adept-iq/pods/components/iq-widgets/reconcile-search-mode-pop-up/config", "adept-iq/utils/format-function"], function (_exports, _emberConcurrency, _emberLightTable, _moment, _config, _formatFunction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    config: _config.default,
    addressTable: null,
    clientTable: null,
    clientTableSearch: null,
    driverTable: null,
    tripTable: null,
    vehicleTable: null,
    triggeredSearch: false,
    isDriverTableEmpty: false,
    triggeredSearchClientTrips: false,
    selectedSearchMode: null,
    cityOptions: null,
    addressOptions: null,
    tableHeader: 0,
    clientTripsTableHeader: 0,
    canEdit: false,
    isClientTableEmpty: false,
    canSearchInMode: Ember.computed('selectedClientId', 'selectedDriver', 'selectedTripId', 'selectedVehicle', 'selectedDateTimeRage', 'selectedLastName', 'selectedFirstName', function () {
      const searchMode = Ember.get(this, 'selectedSearchMode');
      const startDate = Ember.get(this, 'selectedDateTimeRage.startDate');
      const endDate = Ember.get(this, 'selectedDateTimeRage.endDate');
      const today = Ember.get(this, 'selectedDateTimeRage.today');
      let result = false;

      switch (searchMode) {
        case 'Address':
          if (startDate && endDate && startDate <= endDate && endDate <= today) result = true;
          break;

        case 'Client':
          if (Ember.get(this, 'selectedClientId') && Ember.get(this, 'selectedClientId') !== null || Ember.get(this, 'selectedLastName') && Ember.get(this, 'selectedLastName') !== null || Ember.get(this, 'selectedFirstName') && Ember.get(this, 'selectedFirstName') !== null) result = true;
          break;

        case 'Driver':
          if ((Ember.get(this, 'selectedDriver') || Ember.get(this, 'selectedCdl')) && startDate && endDate && startDate <= endDate && endDate <= today) result = true;
          break;

        case 'Trip':
          if (Ember.get(this, 'selectedTripId') && Ember.get(this, 'selectedTripId') !== null) result = true;
          break;

        case 'Vehicle':
          if (Ember.get(this, 'selectedVehicle') && startDate && endDate && startDate <= endDate && endDate <= today) result = true;
          break;

        default:
          break;
      }

      return result;
    }),
    canClearInMode: Ember.computed('selectedAddress', 'selectedCdl', 'selectedClientId', 'selectedDriver', 'selectedTripId', 'selectedVehicle', 'selectedDateTimeRage', 'selectedLastName', 'selectedFirstName', function () {
      const searchMode = Ember.get(this, 'selectedSearchMode');
      const startDate = Ember.get(this, 'selectedDateTimeRage.startDate');
      const endDate = Ember.get(this, 'selectedDateTimeRage.endDate');
      let result = false;

      switch (searchMode) {
        case 'Address':
          if (Ember.get(this, 'selectedAddress') || startDate || endDate) result = true;
          break;

        case 'Client':
          if (Ember.get(this, 'selectedClientId') || Ember.get(this, 'selectedLastName') || Ember.get(this, 'selectedFirstName')) result = true;
          break;

        case 'Driver':
          if (Ember.get(this, 'selectedDriver') || Ember.get(this, 'selectedCdl') || startDate || endDate) result = true;
          break;

        case 'Trip':
          if (Ember.get(this, 'selectedTripId')) result = true;
          break;

        case 'Vehicle':
          if (Ember.get(this, 'selectedVehicle') || startDate || endDate) result = true;
          break;

        default:
          break;
      }

      return result;
    }),
    canSelect: Ember.computed('routeId', function () {
      const routeId = Ember.get(this, 'routeId');
      if (routeId && routeId !== null) return true;
      return false;
    }),
    canSearchClientTrips: Ember.computed('selectedClientIdFromTable', 'selectedDateTimeRage', function () {
      const startDate = Ember.get(this, 'selectedDateTimeRage.startDate');
      const endDate = Ember.get(this, 'selectedDateTimeRage.endDate');
      const today = Ember.get(this, 'selectedDateTimeRage.today');
      let result = false;
      if (Ember.get(this, 'selectedClientIdFromTable') && Ember.get(this, 'selectedClientIdFromTable') !== null && startDate && endDate && startDate <= endDate && endDate <= today) result = true;
      return result;
    }),
    updateAddressTable: Ember.observer('searchByAddress', function () {
      const searchByAddress = Ember.get(this, 'searchByAddress');

      if (!Ember.isEmpty(searchByAddress)) {
        searchByAddress.map(trip => {
          trip.passenger.fullName = this.getFullName(trip.passenger);
        });
      }

      Ember.get(this, 'addressTable').setRows(searchByAddress);
      Ember.set(this, 'tableHeader', searchByAddress.length);
    }),
    updateClientTable: Ember.observer('searchByClients', function () {
      const searchByClients = Ember.get(this, 'searchByClients');
      Ember.get(this, 'clientTable').setRows(searchByClients);
      Ember.set(this, 'tableHeader', searchByClients.length);
    }),
    updateClientTripsTable: Ember.observer('searchByClientId', function () {
      const searchByClientId = Ember.get(this, 'searchByClientId');
      Ember.get(this, 'clientTableSearch').setRows(searchByClientId);
      Ember.set(this, 'clientTripsTableHeader', searchByClientId.length);
    }),
    updateTripTable: Ember.observer('searchByTrips', function () {
      const searchByTrips = Ember.get(this, 'searchByTrips');
      Ember.get(this, 'tripTable').setRows(searchByTrips);
      Ember.set(this, 'tableHeader', searchByTrips.length);
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'selectedDateTimeRage', {
        today: (0, _moment.default)().subtract(1, 'day').toISOString(),
        startforendtime: (0, _moment.default)().startOf('day').toISOString(),
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(7, 'years').subtract(2, 'days').format('MM-DD-YYYY'),
        startDate: null,
        endDate: null
      });
      this.setTableColumns();
    },

    setTableColumns() {
      const searchMode = Ember.get(this, 'selectedSearchMode');

      switch (searchMode) {
        case 'Address':
          Ember.set(this, 'addressTable', new _emberLightTable.default(this.config.addressTableColumns)); // this.getAllAddresses();

          break;

        case 'Client':
          Ember.set(this, 'clientTable', new _emberLightTable.default(this.config.clientTableColumns));
          Ember.set(this, 'clientTableSearch', new _emberLightTable.default(this.config.clientTableColumnsSearch));
          break;

        case 'Driver':
          Ember.set(this, 'driverTable', new _emberLightTable.default(this.config.driverAndVehicleTableColumns));
          this.getAllDrivers();
          break;

        case 'Trip':
          Ember.set(this, 'tripTable', new _emberLightTable.default(this.config.tripTableColumns));
          break;

        case 'Vehicle':
          Ember.set(this, 'vehicleTable', new _emberLightTable.default(this.config.driverAndVehicleTableColumns));
          this.getAllVehicles();
          break;

        default:
          break;
      }
    },

    getAllAddresses() {
      Ember.get(this, 'fetchAllAddresses').perform();
    },

    getAllDrivers() {
      Ember.get(this, 'fetchAllDrivers').perform();
    },

    getAllVehicles() {
      Ember.get(this, 'fetchAllVehicles').perform();
    },

    fetchAllAddresses: (0, _emberConcurrency.task)(function* () {
      const response = yield Ember.get(this, 'store').query(this.config.stop.model, {
        searchCities: true
      });
      this.setAddressOptions(response);
    }),
    fetchAllDrivers: (0, _emberConcurrency.task)(function* () {
      const response = yield Ember.get(this, 'store').query(this.config.driver.model, {});
      this.setDriverOptions(response);
    }),
    fetchAllVehicles: (0, _emberConcurrency.task)(function* () {
      const response = yield Ember.get(this, 'store').query(this.config.vehicle.model, {});
      this.setVehicleOptions(response);
    }),
    fetchAddressTask: (0, _emberConcurrency.task)(function* () {
      const address = Ember.get(this, 'selectedAddress');
      const selectedStartDate = Ember.get(this, 'selectedDateTimeRage.startDate');
      const selectedEndDate = Ember.get(this, 'selectedDateTimeRage.endDate');
      if (Ember.isNone(address) && Ember.isNone(selectedStartDate) && Ember.isNone(selectedEndDate)) return;
      Ember.get(this, 'addressTable').setRows([]);
      const dateRange = (0, _formatFunction.getDateRangeForSearch)(selectedStartDate, selectedEndDate);
      const queryOptions = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        address: address.fullAddress
      }; // if (address) queryOptions.address = address.name.replace('&', '%38');

      const events = yield Ember.get(this, 'store').query(this.config.trip.model, queryOptions);
      Ember.set(this, 'searchByAddress', events);
    }),
    fetchClientTask: (0, _emberConcurrency.task)(function* () {
      const passengerConfig = this.get('config.passenger');
      const ClientId = Ember.get(this, 'selectedClientId');
      const lastName = Ember.get(this, 'selectedLastName');
      const firstName = Ember.get(this, 'selectedFirstName');
      const queryOptions = {};

      if (!Ember.isNone(ClientId) || !Ember.isNone(lastName) || !Ember.isNone(firstName)) {
        Ember.get(this, 'clientTable').setRows([]);
        if (ClientId) queryOptions.passengerId = ClientId;
        if (lastName) queryOptions.lastName = lastName;
        if (firstName) queryOptions.firstName = firstName;
        const events = yield Ember.get(this, 'store').query(passengerConfig.model, queryOptions);
        Ember.set(this, 'searchByClients', events);

        if (Ember.isEmpty(events)) {
          Ember.set(this, 'isClientTableEmpty', true);
        } else {
          Ember.set(this, 'isClientTableEmpty', false);
        }
      }

      return;
    }),
    fetchClientTripsTask: (0, _emberConcurrency.task)(function* () {
      const tripConfig = this.get('config.trip');
      const selectedClientIdFromTable = Ember.get(this, 'selectedClientIdFromTable');
      const selectedStartDate = Ember.get(this, 'selectedDateTimeRage.startDate');
      const selectedEndDate = Ember.get(this, 'selectedDateTimeRage.endDate');
      if (Ember.isNone(selectedClientIdFromTable) && Ember.isNone(selectedStartDate) && Ember.isNone(selectedEndDate)) return;
      Ember.get(this, 'clientTableSearch').setRows([]);
      const dateRange = (0, _formatFunction.getDateRangeForSearch)(selectedStartDate, selectedEndDate);
      const queryOptions = {
        passengerId: selectedClientIdFromTable,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate
      };
      const events = yield Ember.get(this, 'store').query(tripConfig.model, queryOptions);
      Ember.set(this, 'searchByClientId', events);
    }),
    fetchTripTask: (0, _emberConcurrency.task)(function* () {
      const tripConfig = this.get('config.trip');
      const selectedTripId = Ember.get(this, 'selectedTripId');
      if (Ember.isNone(selectedTripId)) return;
      Ember.get(this, 'tripTable').setRows([]);
      const queryOptions = {
        tripId: selectedTripId
      };
      const events = yield Ember.get(this, 'store').query(tripConfig.model, queryOptions);
      Ember.set(this, 'searchByTrips', events);
    }),
    fetchRouteTask: (0, _emberConcurrency.task)(function* (byDriver) {
      const queryOptions = {};
      const selectedStartDate = Ember.get(this, 'selectedDateTimeRage.startDate');
      const selectedEndDate = Ember.get(this, 'selectedDateTimeRage.endDate');

      if (byDriver) {
        const driver = Ember.get(this, 'selectedDriver');
        const cdl = Ember.get(this, 'selectedCdl');
        if (Ember.isEmpty(driver) && Ember.isEmpty(cdl) || Ember.isEmpty(selectedStartDate) || Ember.isEmpty(selectedEndDate)) return;

        if (Ember.isEmpty(cdl)) {
          queryOptions.driverId = Ember.isEmpty(cdl) ? driver.driverId : null;
        } else {
          queryOptions.cdl = cdl;
        }
      } else {
        const vehicle = Ember.get(this, 'selectedVehicle');
        if (Ember.isEmpty(vehicle) || Ember.isEmpty(selectedStartDate) || Ember.isEmpty(selectedEndDate)) return;
        queryOptions.vehicleId = vehicle.vehicleId;
      }

      const dateRange = (0, _formatFunction.getDateRangeForSearch)(selectedStartDate, selectedEndDate);
      queryOptions.startDate = dateRange.startDate;
      queryOptions.endDate = dateRange.endDate;
      const response = yield Ember.get(this, 'store').query('reconcile-route-id', queryOptions);
      this.setVehicleDriverTableRows(byDriver, response);
    }),

    getFullName(passenger) {
      const str = Ember.get(passenger, 'lastName') + ', ' + Ember.get(passenger, 'firstName');
      return str ? str.toUpperCase() : '';
    },

    setVehicleOptions(vehicleResponse) {
      const options = [];
      if (Ember.isNone(vehicleResponse)) return [];
      vehicleResponse.map(vehicle => {
        options.push({
          id: vehicle.id,
          vehicleId: vehicle.vehicleId
        });
      });
      Ember.set(this, 'vehicleOptions', options.sortBy('id'));
    },

    setDriverOptions(driverResponse) {
      const options = [];
      if (Ember.isNone(driverResponse)) return [];
      driverResponse.map(driver => {
        options.push({
          id: driver.id,
          driverId: driver.driverId
        });
      });
      Ember.set(this, 'driverOptions', options.sortBy('id'));
    },

    setAddressOptions(stops) {
      const stopConfig = this.get('config.stop');
      const addressOptions = [];
      if (Ember.isNone(stops)) return [];
      stops.map(stop => {
        if (stop.get(stopConfig.indexPath.address) && !addressOptions.some(address => address.id === stop.get(stopConfig.indexPath.address))) {
          addressOptions.push({
            id: stop.get(stopConfig.indexPath.address),
            name: stop.get(stopConfig.indexPath.address)
          });
        }
      });
      Ember.set(this, 'addressOptions', addressOptions.sortBy('id'));
    },

    setVehicleDriverTableRows(isDriverResponse, response) {
      Ember.get(this, isDriverResponse ? 'driverTable' : 'vehicleTable').setRows(response);
      Ember.set(this, 'tableHeader', response.length);

      if (Ember.isEmpty(response)) {
        Ember.set(this, isDriverResponse ? 'isDriverTableEmpty' : 'isVehicleTableEmpty', true);
      } else {
        Ember.set(this, isDriverResponse ? 'isDriverTableEmpty' : 'isVehicleTableEmpty', false);
      }
    },

    updateSeachProperty(value, item) {
      Ember.set(this, item, value);
      this.notifyPropertyChange(item);
    },

    clearAll() {
      const searchMode = Ember.get(this, 'selectedSearchMode');
      Ember.set(this, 'routeId', null);
      Ember.set(this, 'selectedCdl', null);
      Ember.set(this, 'selectedLastName', null);
      Ember.set(this, 'selectedFirstName', null);
      Ember.set(this, 'selectedSearchedTrip', null);
      Ember.set(this, 'triggeredSearch', false);
      Ember.set(this, 'triggeredSearchClientTrips', false);
      Ember.set(this, 'tableHeader', 0);
      Ember.set(this, 'selectedDateTimeRage', {
        today: (0, _moment.default)().subtract(1, 'day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(7, 'years').subtract(1, 'day').format('MM-DD-YYYY'),
        startDate: null,
        endDate: null
      });
      this.notifyPropertyChange('selectedDateTimeRage');
      this.notifyPropertyChange('routeId');

      switch (searchMode) {
        case 'Address':
          Ember.set(this, 'selectedAddress', null);
          if (!Ember.isEmpty(this.get('searchByAddress'))) Ember.set(this, 'searchByAddress', []);
          break;

        case 'Client':
          Ember.set(this, 'selectedClientId', null);
          if (!Ember.isEmpty(this.get('searchByClients'))) Ember.set(this, 'searchByClients', []);
          if (!Ember.isEmpty(this.get('searchByClientId'))) Ember.set(this, 'searchByClientId', []);
          break;

        case 'Driver':
          Ember.set(this, 'selectedDriver', null);
          if (!Ember.isEmpty(this.get('searchByDriver'))) Ember.set(this, 'searchByDriver', []);
          Ember.set(this, 'isDriverTableEmpty', false);
          break;

        case 'Trip':
          Ember.set(this, 'selectedTripId', null);
          if (!Ember.isEmpty(this.get('searchByTrips'))) Ember.set(this, 'searchByTrips', []);
          break;

        case 'Vehicle':
          Ember.set(this, 'selectedVehicle', null);
          if (!Ember.isEmpty(this.get('searchByVehicle'))) Ember.set(this, 'searchByVehicle', []);
          Ember.set(this, 'isVehicleTableEmpty', false);
          break;

        default:
          break;
      }

      this.resetProperties();
    },

    filterTextInput(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
      return true;
    },

    showToolTip(title, tip) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title,
        tip,
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        },
        hasOverlay: true
      });
    },

    resetVehicleDriverOptions(isDriverOption) {
      Ember.set(this, isDriverOption ? 'isDriverTableEmpty' : 'isVehicleTableEmpty', false);
      Ember.get(this, isDriverOption ? 'driverTable' : 'vehicleTable').setRows([]);
      Ember.set(this, 'tableHeader', 0);
    },

    getRoutesByDriver() {
      this.resetVehicleDriverOptions(true);

      if (this.selectedDriver && this.selectedCdl) {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'Search',
          tip: 'Both Driver and CDL Number can not be supplied. Choose one or the other.',
          primaryActionText: 'OK',
          primaryAction: () => {
            tooltip.reset();
          },
          hasOverlay: true
        });
      } else {
        Ember.get(this, 'fetchRouteTask').perform(true);
      }
    },

    getRoutesByVehicle() {
      this.resetVehicleDriverOptions(false);
      Ember.get(this, 'fetchRouteTask').perform(false);
    },

    isTripTypeSearchMode() {
      const searchMode = Ember.get(this, 'selectedSearchMode');
      return searchMode === 'Address' || searchMode === 'Client' || searchMode === 'Trip';
    },

    resetProperties() {
      const searchMode = Ember.get(this, 'selectedSearchMode');

      switch (searchMode) {
        case 'Address':
          Ember.get(this, 'addressTable').setRows([]);
          break;

        case 'Client':
          Ember.get(this, 'clientTable').setRows([]);
          Ember.get(this, 'clientTableSearch').setRows([]);
          Ember.set(this, 'isClientTableEmpty', false);
          break;

        case 'Driver':
          Ember.get(this, 'driverTable').setRows([]);
          break;

        case 'Trip':
          Ember.get(this, 'tripTable').setRows([]);
          break;

        case 'Vehicle':
          Ember.get(this, 'vehicleTable').setRows([]);
          break;

        default:
          break;
      }
    },

    actions: {
      onSearchByModes() {
        const searchMode = Ember.get(this, 'selectedSearchMode');
        Ember.set(this, 'routeId', null);
        this.notifyPropertyChange('routeId');
        Ember.set(this, 'triggeredSearch', true);

        switch (searchMode) {
          case 'Address':
            Ember.get(this, 'fetchAddressTask').perform();
            break;

          case 'Client':
            Ember.get(this, 'fetchClientTask').perform();
            break;

          case 'Driver':
            this.getRoutesByDriver();
            break;

          case 'Trip':
            Ember.get(this, 'fetchTripTask').perform();
            break;

          case 'Vehicle':
            this.getRoutesByVehicle();
            break;

          default:
            break;
        }
      },

      onClearPopUp() {
        Ember.set(this, 'canEdit', false);
        this.clearAll();
      },

      onSearchByClient() {
        Ember.set(this, 'routeId', null);
        this.notifyPropertyChange('routeId');
        Ember.set(this, 'triggeredSearchClientTrips', true);
        Ember.get(this, 'fetchClientTripsTask').perform();
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
        this.notifyPropertyChange('selectedDateTimeRage');
      },

      onInputTextChange(valuePath, searchText) {
        Ember.run.debounce(this, 'updateSeachProperty', searchText, valuePath, 200);
      },

      onRowClick(row) {
        const searchMode = Ember.get(this, 'selectedSearchMode');
        let table = null;
        Ember.set(this, 'routeId', null);
        this.notifyPropertyChange('routeId');

        switch (searchMode) {
          case 'Address':
            table = 'addressTable';
            break;

          case 'Client':
            table = 'clientTableSearch';
            break;

          case 'Driver':
            table = 'driverTable';
            break;

          case 'Trip':
            table = 'tripTable';
            break;

          case 'Vehicle':
            table = 'vehicleTable';
            break;

          default:
            break;
        }

        const rows = this.get(table + '.rows.content');
        const selectedRow = rows.findBy('selected');
        if (selectedRow && row.get('id') === selectedRow.get('id')) return;
        rows.forEach(_row => {
          if (_row.get('id') === row.get('id')) {
            let route = {};
            let routeId, providerName, plannedStartTime, mode;

            if (this.isTripTypeSearchMode()) {
              route = _row.get('route'); // for trips payload

              routeId = route.routeId;
              providerName = route.providerName;
              plannedStartTime = route.plannedStartTime;
              mode = route.mode;
            } else {
              route = _row; // for route payload

              routeId = route.get('routeId');
              providerName = route.get('providerName');
              plannedStartTime = route.get('plannedStartTime');
              mode = route.get('mode');
            }

            _row.set('routeId', routeId);

            _row.set('providerName', providerName);

            _row.set('plannedStartTime', plannedStartTime);

            _row.set('mode', mode);

            Ember.set(this, 'routeId', routeId);
            Ember.set(this, 'selectedSearchedTrip', _row);
            this.notifyPropertyChange('routeId');
          }
        });
      },

      onRowClickSetClient(row) {
        Ember.set(this, 'canEdit', true);
        const rows = this.get('clientTable.rows.content');
        const selectedRow = rows.findBy('selected');
        if (selectedRow && row.get('id') === selectedRow.get('id')) return;
        rows.forEach(_row => {
          if (_row.get('id') === row.get('id')) {
            Ember.set(this, 'selectedClientIdFromTable', _row.get('passengerId'));
          }
        });
      },

      onSelectRouteClick() {
        const routeId = Ember.get(this, 'routeId');
        const recon = Ember.get(this, 'reconcile');
        const selectedSearchedTrip = Ember.get(this, 'selectedSearchedTrip');
        const reconcile = Ember.get(this, 'reconcile');
        Ember.set(this, 'reconcile.selectedSearchedTrip', selectedSearchedTrip);
        Ember.get(recon, 'fetchRouteData').perform(routeId);
        this.clearAll();
        Ember.set(this, 'selectedSearchMode', null);
        reconcile.toggleProperty('showSearchPopUp');
      },

      cancel() {
        this.clearAll();
        Ember.set(this, 'selectedSearchMode', null);
        const reconcile = Ember.get(this, 'reconcile');
        reconcile.toggleProperty('showSearchPopUp');
      },

      onChangeLocation(record) {
        Ember.set(this, 'selectedAddress', record);
      },

      onOptionsSelect(item, option) {
        this.resetProperties();
        Ember.set(this, item, option);
        this.notifyPropertyChange(item);
        Ember.set(this, 'tableHeader', 0);
      }

    }
  });

  _exports.default = _default;
});