define("adept-iq/adapters/trip", ["exports", "adept-iq/adapters/-ss-schedulingService", "adept-iq/config/api-urls"], function (_exports, _ssSchedulingService, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    iquxServiceHost: _apiUrls.API.iquxService.host,

    urlForFindRecord(id) {
      const model = 'trip';
      const iquxServiceHost = this.get('iquxServiceHost');
      const baseUrl = `${iquxServiceHost}/${model}/${id}`;
      return baseUrl;
    }

  });

  _exports.default = _default;
});