define("adept-iq/helpers/uppercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uppercase = uppercase;
  _exports.default = void 0;

  function uppercase(str) {
    const s = str[0].replace(/\s+/g, '-').toUpperCase();
    return s;
  }

  var _default = Ember.Helper.helper(uppercase);

  _exports.default = _default;
});