define("adept-iq/models/driver-violation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    description: attr('string'),
    points: attr('number'),
    incidentDate: attr({
      defaultValue: null
    }),
    licenseMonitorDate: attr({
      defaultValue: null
    }),
    convictionDate: attr({
      defaultValue: null
    }),
    convictionNotificationDate: attr({
      defaultValue: null
    }),
    driverLicense: belongsTo('driver-license'),
    driverSuspension: belongsTo('driver-suspension'),
    vehicle: belongsTo('vehicle')
  });

  _exports.default = _default;
});