define("adept-iq/pods/components/iq-widgets/routes-start-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/routes-start-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['routes-start-form-widget'],
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const currentTimestamp = (0, _moment.default)().toISOString();
        const actualStartTime = record.get('timestamp');
        const plannedEndTime = record.get('dispatchRoute.plannedEndTime');
        const tripStartTime = record.get('dispatchRoute.tripStartTime');
        record.set('odometer', null);

        if ((0, _moment.default)(currentTimestamp).isSameOrBefore(actualStartTime)) {
          this.get('editModal.errors').pushObject('Actual Start Time should be less than now.');
          valid = false;
        }

        if ((0, _moment.default)(plannedEndTime).isSameOrBefore(actualStartTime)) {
          this.get('editModal.errors').pushObject('Actual Start Time should be less than planned end time.');
          valid = false;
        }

        if ((0, _moment.default)(tripStartTime).isSameOrBefore(actualStartTime)) {
          this.get('editModal.errors').pushObject('Actual Start Time should be less than planned trip start time.');
          valid = false;
        }
      });
      return valid;
    },

    actions: {
      onUndoClick() {
        this.get('editModal').undoAll();
      },

      onApplyClick() {
        if (this.validateRecords()) {
          const title = this.get('title');
          this.get('service').apply(title);
        }
      }

    }
  });

  _exports.default = _default;
});