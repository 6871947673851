define("adept-iq/classes/map-contexts/workspaces/schedule", ["exports", "adept-iq/classes/map-contexts/layered", "adept-iq/classes/markers/agency", "adept-iq/classes/markers/stops/schedule-stop", "adept-iq/classes/markers/vehicles/iq", "adept-iq/classes/polylines/trips/iq", "adept-iq/classes/polylines/routes/planned/iq", "adept-iq/classes/polylines/routes/navigation/iq", "adept-iq/classes/polylines/routes/performed/iq", "adept-iq/classes/polylines/routes/scheduled/iq", "adept-iq/classes/polygons/zone", "adept-iq/classes/shapes/zone", "tomtom", "adept-iq/classes/map-contexts/map-configs/schedule-layers"], function (_exports, _layered, _agency, _scheduleStop, _iq, _iq2, _iq3, _iq4, _iq5, _iq6, _zone, _zone2, _tomtom, _scheduleLayers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _layered.default.extend({
    // must be passed on init
    activeContext: null,
    store: null,
    workspace: null,
    layersConfig: _scheduleLayers.default,
    vehicleCluster: _tomtom.default.L.markerClusterGroup({
      showCoverageOnHover: false
    }),
    polylineData: Ember.computed.readOnly('activeContext.topActiveContext.implicitData'),
    markerData: Ember.computed.readOnly('activeContext.topActiveContext.implicitData'),
    polygonData: Ember.computed.readOnly('activeContext.topActiveContext.implicitData'),
    shapeData: Ember.computed.readOnly('activeContext.topActiveContext.implicitData'),
    layeredRecords: Ember.computed('activeContext.topActiveContext.{implicitData,activeVehicles}', 'layers', function () {
      return this.get('layers').reduce((obj, layer) => {
        obj[layer.id] = this._recordsForLayer(layer);
        return obj;
      }, {});
    }),

    init() {
      this._super(...arguments);

      const store = this.get('store');

      this.focusActiveContext = e => {
        const activeContext = this.get('activeContext.topActiveContext'); // this `iq` object gets attached by the marker/polyline data join

        if (!e.target.iq) return;
        const {
          isActive,
          modelName,
          modelId
        } = e.target.iq;
        if (!isActive) return;
        const record = store.peekRecord(modelName, modelId);
        if (!record) return;
        activeContext.replaceCheckedRecordsByType([record]);
      };
    },

    destroy() {
      this.focusActiveContext = null;

      this._super(...arguments);
    },

    // handle dbl click events for markers
    markerObjectFor()
    /* record */
    {
      const marker = this._super(...arguments);

      if (!marker.get('_dblclickHandlerInitialized')) {
        marker.on('dblclick', this.focusActiveContext);
        marker.set('_dblclickHandlerInitialized', true);
      }

      return marker;
    },

    // handle dbl click events for polylines
    polylineObjectFor() {
      const polyline = this._super(...arguments);

      if (!polyline.get('_dblclickHandlerInitialized')) {
        polyline.on('dblclick', this.focusActiveContext);
        polyline.set('_dblclickHandlerInitialized', true);
      }

      return polyline;
    },

    _recordsForLayer(layer) {
      switch (layer.id) {
        case 'stops':
          return this._recordsForStopsLayer(layer);

        case 'vehicles':
          return this._recordsForVehicleLayer(layer);

        default:
          return this._recordsForOtherLayer(layer);
      }
    },

    _recordsForStopsLayer(layer) {
      const topActiveContext = this.get('activeContext.topActiveContext');

      const scheduleStops = this._recordsForOtherLayer(layer); // trip stops / route-breaks
      // if it is a trip active context
      // only include the pick and drop stops


      if (topActiveContext) {
        if (topActiveContext.tripActiveContext()) {
          return scheduleStops.filter(stop => {
            return ['pick', 'drop'].includes(stop.get('type'));
          });
        }
      }

      return scheduleStops;
    },

    _recordsForVehicleLayer(layer) {
      if (!layer.isVisible) return []; // we are constantly displaying all vehicles

      const structuredData = this.get('activeContext.topActiveContext.workspaceData'); // TODO: read `workspace-context` structured data instead of store

      const records = layer.isActive && structuredData ? structuredData[layer.modelName] : [];
      return records;
    },

    _recordsForOtherLayer(layer) {
      if (!layer.isVisible || !layer.isActive) return [];
      const modelNames = Ember.makeArray(layer.modelName);
      const structuredData = this.get('activeContext.topActiveContext.structuredMapData');
      const records = modelNames.reduce((acu, modelName) => {
        const data = structuredData ? structuredData[modelName] || [] : [];
        acu.push(...data);
        return acu;
      }, []);
      if (Ember.isEmpty(records)) return [];
      return records;
    },

    // used to create marker, polyline, etc objects for data join
    _polygonObjectFor(layer, layerType, record) {
      if (!this._polygonCache) {
        this._polygonCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let polygon = this._polygonCache[id];

      if (!polygon) {
        const polygonClass = this._polygonClassForLayer(layer);

        polygon = polygonClass.create({
          id,
          record
        });
        this._polygonCache[id] = polygon;
      }

      return polygon;
    },

    _markerObjectFor(layer, layerType, record) {
      if (!this._markerCache) {
        this._markerCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let marker = this._markerCache[id];

      if (!marker) {
        const markerClass = this._markerClassForLayer(layer);

        marker = markerClass.create({
          id,
          record
        });
        this._markerCache[id] = marker;
      }

      return marker;
    },

    _shapeObjectFor(layer, layerType, record) {
      if (!this._shapeCache) {
        this._shapeCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let shape = this._shapeCache[id];

      if (!shape) {
        const shapeClass = this._shapeClassForLayer(layer);

        shape = shapeClass.create({
          id,
          record
        });
        this._shapeCache[id] = shape;
      }

      return shape;
    },

    _polylineObjectFor(layer, layerType, record) {
      if (!this._polylineCache) {
        this._polylineCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let polyline = this._polylineCache[id];

      if (!polyline) {
        const polylineClass = this._polylineClassForLayer(layer, layerType);

        polyline = polylineClass.create({
          id,
          record,
          polylineService: this.get('polylineService')
        });
        this._polylineCache[id] = polyline;
      }

      return polyline;
    },

    _markerClassForLayer(layer) {
      switch (layer.id) {
        case 'agency':
          return _agency.default;

        case 'vehicles':
          return _iq.default;

        case 'stops':
          return _scheduleStop.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    },

    _polylineClassForLayer(layer, layerType) {
      switch (layer.id) {
        case 'routes':
          return this._routePolylineClassForLayerType(layerType);

        case 'trips':
          return _iq2.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    },

    _routePolylineClassForLayerType(layerType) {
      switch (layerType.id) {
        case 'planned':
          return _iq3.default;

        case 'navigation':
          return _iq4.default;

        case 'performed':
          return _iq5.default;

        case 'scheduled':
          return _iq6.default;

        default:
          throw new Error(`unhandled route layerType id '${layerType.id}'`);
      }
    },

    _shapeClassForLayer(layer) {
      switch (layer.id) {
        case 'zones':
          return _zone2.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    },

    _polygonClassForLayer(layer) {
      switch (layer.id) {
        case 'zones':
          return _zone.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    }

  });

  _exports.default = _default;
});