define("adept-iq/pods/components/reoptimize-modal/operation-tables/consolidate-routes-table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    modelName: 'route',
    title: 'Consolidate Routes',
    defaultSortId: 'provider',
    defaultSortAsc: true,
    fetchModelData: true,
    queryModelData: true,
    columns: [{
      id: 'provider',
      index: 0,
      type: 'text',
      label: 'Provider',
      valuePath: 'provider.id',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'Route',
      index: 1,
      type: 'uuid',
      // adept 4 is no longer part of the system, route's will include characters
      label: 'Route',
      valuePath: 'name',
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'shiftLength',
      index: 2,
      type: 'string',
      label: 'Shift Length',
      valuePath: 'shiftLength',
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'numberofTrips',
      index: 3,
      type: 'String',
      label: 'Trips',
      valuePath: 'noOfTrips',
      searchable: true,
      defaultWidth: 50
    }]
  };
  _exports.default = _default;
});