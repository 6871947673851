define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/vehicle/component", ["exports", "ember-concurrency", "moment", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/vehicle/validation", "adept-iq/utils/unwrapProxy", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _moment, _emberChangeset, _emberChangesetValidations, _validation, _unwrapProxy, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    classNames: ['vehicle-view'],
    // placeholders
    VEHICLE_ID_PLACEHOLDER: _placeholders.VEHICLE_ID_PLACEHOLDER,
    VEHICLE_STATUS_PLACEHOLDER: _placeholders.VEHICLE_STATUS_PLACEHOLDER,
    VEHICLE_TYPE_PLACEHOLDER: _placeholders.VEHICLE_TYPE_PLACEHOLDER,
    PROVIDER_PLACEHOLDER: _placeholders.PROVIDER_PLACEHOLDER,
    VEHICLE_MODEL_YEAR_PLACEHOLDER: _placeholders.VEHICLE_MODEL_YEAR_PLACEHOLDER,
    VEHICLE_LICENCE_PLACEHOLDER: _placeholders.VEHICLE_LICENCE_PLACEHOLDER,
    VEHICLE_START_GARAGE_PLACEHOLDER: _placeholders.VEHICLE_START_GARAGE_PLACEHOLDER,
    VEHICLE_MANUFACTURER_PLACEHOLDER: _placeholders.VEHICLE_MANUFACTURER_PLACEHOLDER,
    NOTES_PLACEHOLDER: _placeholders.NOTES_PLACEHOLDER,
    VEHICLE_END_GARAGE_PLACEHOLDER: _placeholders.VEHICLE_END_GARAGE_PLACEHOLDER,
    VEHICLE_LEASE_COMPANY_PLACEHOLDER: _placeholders.VEHICLE_LEASE_COMPANY_PLACEHOLDER,
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    VEHICLE_ENGINE_TYPE_PLACEHOLDER: _placeholders.VEHICLE_ENGINE_TYPE_PLACEHOLDER,
    VEHICLE_SITE_DEF_PLACEHOLDER: _placeholders.VEHICLE_SITE_DEF_PLACEHOLDER,
    VIN_PLACEHOLDER: _placeholders.VIN_PLACEHOLDER,
    WHEELCHAIR_SERIAL_NUMBER_PLACEHOLDER: _placeholders.WHEELCHAIR_SERIAL_NUMBER_PLACEHOLDER,
    vehicleChangeset: null,
    places: null,
    refreshRecord: true,
    showErrorMsg: false,
    vehicleRecord: Ember.computed('editAPI.selectedVehicle', 'refreshRecord', function () {
      const selectedVehicle = this.get('editAPI.selectedVehicle');
      return this.setDefaultProperties(selectedVehicle);
    }),

    init() {
      this._super(...arguments);

      this.setupDropDownValues();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveVehicleRecord = this.get('onSaveVehicleRecord');
      const onUndoVehicleRecord = this.get('onUndoVehicleRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveVehicleRecord,
          undoAction: onUndoVehicleRecord,
          undoBtn: false,
          saveBtn: false,
          newAction: false,
          deleteAction: false,
          editAction: false
        });
      });
    },

    setDefaultProperties(record = []) {
      this.set('showErrorMsg', false);
      this.set('duplicateName', false);
      return new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(_validation.VALIDATIONS), _validation.VALIDATIONS);
    },

    setupDropDownValues() {
      const store = this.get('store');
      const dropDownModels = ['provider', 'vehicle-status-name', 'vehicle-manufacturer-name', 'vehicle-type', 'lease-company'];
      dropDownModels.forEach(model => {
        const records = store.peekAll(model);
        this.set(model, records);
      });
      const places = store.peekAll('place').filter(record => record.get('placeCategoryType.name') === 'garage');
      this.set('places', places);
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const vehicleRecord = this.get('vehicleRecord');
      yield vehicleRecord.validate();
      const errors = vehicleRecord.errors;
      const name = vehicleRecord.get('name');
      this.set('duplicateName', false);

      if (vehicleRecord.get('isNew') && name) {
        const params = {
          filter: `eq('name','${name.trim()}')`
        };
        yield this.get('store').query('vehicle', params).then(records => {
          if (records.length) {
            this.set('duplicateName', true);
            errors.pushObject({
              validation: 'The Vehicle ID already exists. Please specify a different Vehicle ID'
            });
          }
        });
      }

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveVehicleRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();

      if (isValid) {
        const notifications = this.get('notifications');
        const vehicleRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleRecord'));

        try {
          yield vehicleRecord.save();
          notifications.success('Record successfully saved');
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }
      }
    }),

    onUndoVehicleRecord() {
      this.toggleProperty('refreshRecord');
    },

    actions: {
      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      }

    }
  });

  _exports.default = _default;
});