define("adept-iq/adapters/stop-point", ["exports", "adept-iq/adapters/-dispatch", "adept-iq/config/api-urls"], function (_exports, _dispatch, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    iquxServiceHost: _apiUrls.API.iquxService.host,

    // Adapted from https://github.com/emberjs/data/blob/v3.1.1/addon/-private/adapters/build-url-mixin.js#L89-L109
    urlForUpdateRecord(id, modelName) {
      let path;
      let url = [];
      const host = Ember.get(this, 'host');
      const prefix = this.urlPrefix();

      if (modelName) {
        path = this.pathForType(modelName);

        if (path) {
          url.push(path);
        }
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');

      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    },

    handleResponse(status, headers, payload, requestData) {
      let newStatus = status;
      let newPayload = payload;

      if (status === 400 && Ember.isPresent(payload.validation)) {
        newStatus = 422;
        newPayload = {
          errors: [{
            detail: payload.message
          }]
        };
      }

      return this._super(newStatus, headers, newPayload, requestData);
    },

    urlForFindRecord(id, modelName
    /*snapshot*/
    ) {
      const model = Ember.String.camelize(modelName);
      const iquxServiceHost = this.get('iquxServiceHost');
      const baseUrl = `${iquxServiceHost}/${model}/${id}`;
      return baseUrl;
    }

  });

  _exports.default = _default;
});