define("adept-iq/classes/work-queue", ["exports", "ember-concurrency", "adept-iq/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // use this chunk size until we have enough profiling data from completed jobs
  const INITIAL_CHUNK_SIZE = 100; // # of completed jobs required before switching to computed chunk size

  const MIN_SAMPLE_SIZE = 100; // for diagnostics only

  const COMPUTE_TIME = 1000.0 * _environment.default.work.cycleShare / _environment.default.work.fps;
  const DISPLAY_UPDATE_INTERVAL_TIME = 1000;

  var _default = Ember.Object.extend({
    name: null,
    perform: null,
    context: null,
    priority: 0,
    // smaller is more urgent
    isChunked: false,
    // if `true`, attempt work in bulk
    isElastic: false,
    // fills available time
    isGreedy: false,
    maxGreedySize: null,
    asyncTime: null,
    // adds a wait time for jobs that return immediately
    throttle: null,
    // do not run this more than once per N ms
    uniqBy: null,
    // de-dupe jobs by property name
    _lastRunDict: null,
    // record uniq job last run timestamp
    jobs: null,
    isDisabled: false,
    // override this to prevent jobs from running
    length: Ember.computed.readOnly('jobs.length'),
    isEmpty: Ember.computed.equal('length', 0),
    lastRunAt: null,
    // statistical analysis
    _stats: null,
    // only updates once per second
    displayLength: null,
    displayChunkSize: null,
    _interval: null,
    // gets set to true when this queue is cooling down
    isThrottled: false,
    _throttledTimer: null,

    init() {
      this._super(...arguments);

      this.set('jobs', []);
      this.set('_lastRunDict', {});
      this._stats = {
        count: 0,
        tSum: 0,
        ema: null
      }; // for diagnostics mode

      this._interval = setInterval(() => {
        Ember.run.schedule('afterRender', () => {
          if (this.get('isDestroyed')) return;

          const chunkSize = this._estimateChunkSize(COMPUTE_TIME);

          this.set('displayLength', this.get('length'));
          this.set('displayChunkSize', chunkSize);
        });
      }, DISPLAY_UPDATE_INTERVAL_TIME);
    },

    destroy() {
      this.get('jobs').clear();
      this.set('perform', null);
      this.set('context', null);
      clearInterval(this._interval);
      this._interval = null;
      this.set('displayLength', 0);
      this._stats = null;

      if (this.get('_throttledTimer')) {
        Ember.run.cancel(this.get('_throttledTimer'));
      }

      this._super(...arguments);
    },

    clearJobs() {
      this.get('jobs').clear();
    },

    pushJob(job) {
      this.get('jobs').pushObject(job);
    },

    unshiftJob(job) {
      this.get('jobs').unshiftObject(job);
    },

    pushJobs(jobs) {
      this.get('jobs').pushObjects(jobs);
    },

    mergeJob(job) {
      const uniqBy = this.get('uniqBy');
      const groupBy = this.get('groupBy'); // const duplicateBy = this.get('duplicateBy');

      const maxGroupSize = this.get('maxGroupSize') || 1000;
      const jobs = this.get('jobs');

      if (uniqBy) {
        const uniqueId = Ember.get(job, uniqBy);

        if (Ember.isNone(uniqueId)) {
          jobs.pushObject(job);
          return;
        }

        const payloads = Ember.get(job, groupBy);
        const oldJob = jobs.findBy(uniqBy, uniqueId);

        if (!oldJob) {
          jobs.pushObject(job);
          return;
        }

        const oldPayloads = Ember.get(oldJob, groupBy);
        const allPayloads = oldPayloads.concat(payloads);
        const newJob = {
          [uniqBy]: uniqueId,
          [groupBy]: allPayloads.slice(Math.max(allPayloads.length - maxGroupSize, 0))
        };
        jobs.removeObject(oldJob);
        jobs.pushObject(newJob);
      }
    },

    addJob(job) {
      const uniqBy = this.get('uniqBy');
      const jobs = this.get('jobs');
      const lastRunDict = this.get('_lastRunDict');

      if (uniqBy) {
        const uniqueId = Ember.get(job, uniqBy);

        if (Ember.isNone(uniqueId)) {
          jobs.pushObject(job);
          return;
        }

        let oldJob;

        do {
          oldJob = jobs.findBy(uniqBy, uniqueId);
          if (oldJob) jobs.removeObject(oldJob);
        } while (oldJob);

        if (!lastRunDict[uniqueId]) {
          lastRunDict[uniqueId] = new Date().getTime();
        }

        const oldJobLastRunAt = lastRunDict[uniqueId];

        const checkLastRunAt = sortedJob => {
          const jobId = Ember.get(sortedJob, uniqBy);
          const joblastRunAt = lastRunDict[jobId];
          return joblastRunAt && joblastRunAt > oldJobLastRunAt;
        };

        const index = jobs.findIndex(checkLastRunAt);

        if (index > 0) {
          jobs.insertAt(index - 1, job);
        } else {
          jobs.pushObject(job);
        }

        return;
      }

      this.get('jobs').addObject(job);
    },

    addJobs(jobs) {
      this.get('jobs').addObjects(jobs);
    },

    start(time = Infinity) {
      return this.get('_task').perform(time);
    },

    _task: (0, _emberConcurrency.task)(function* (time) {
      if (this.get('isThrottled')) return 0;
      const context = this.get('context');
      const perform = this.get('perform');
      const uniqBy = this.get('uniqBy');
      const lastRunDict = this.get('_lastRunDict');

      if (!perform) {
        const name = this.get('name');
        throw new Error(`queue ${name} has no perform function`);
      } // record time before we start work


      const tStart = new Date().getTime();
      let count = 0;
      this.set('lastRunAt', tStart);

      if (this.get('isGreedy')) {
        const maxGreedySize = this.get('maxGreedySize');
        const chunkSize = Math.min(this.get('jobs').length, maxGreedySize);
        const chunk = this.get('jobs').splice(0, chunkSize);

        if (uniqBy) {
          chunk.forEach(job => {
            const uniqueId = Ember.get(job, uniqBy);
            lastRunDict[uniqueId] = tStart;
          });
        } // splice doesn't trigger observers


        this.notifyPropertyChange('jobs');
        const result = perform.call(context, chunk);
        count += Ember.isNone(result) ? chunk.length : result;
      } else if (this.get('isChunked')) {
        const chunkSize = this._estimateChunkSize(time);

        const chunk = this.get('jobs').splice(0, chunkSize);

        if (uniqBy) {
          chunk.forEach(job => {
            const uniqueId = Ember.get(job, uniqBy);
            lastRunDict[uniqueId] = tStart;
          });
        } // splice doesn't trigger observers


        this.notifyPropertyChange('jobs');
        const result = perform.call(context, chunk);
        count += Ember.isNone(result) ? chunk.length : result;
      } else if (this.get('isElastic')) {
        const jobs = this.get('jobs');
        let tRemaining = tStart + time - new Date().getTime();
        let didShift;
        const minTime = this._stats.count < MIN_SAMPLE_SIZE ? 1 : this.getMinWorkTime();

        if (jobs.length > 0) {
          do {
            const job = jobs.shift();
            const result = perform.call(context, [job]);

            if (uniqBy) {
              const uniqueId = Ember.get(job, uniqBy);
              lastRunDict[uniqueId] = tStart;
            }

            count += Ember.isNone(result) ? 1 : result;
            didShift = true;
            tRemaining = tStart + time - new Date().getTime();
          } while (jobs.length > 0 && tRemaining > minTime);

          if (_environment.default.APP.DEBUG_QUEUES && tRemaining < 0) {
            console.warn(`${this.name} went over ${-tRemaining}ms on ${count} jobs (minTime ${minTime})`); // eslint-disable-line no-console
          }
        }

        if (didShift) this.notifyPropertyChange('jobs');
      } else {
        const job = this.get('jobs').shiftObject();
        const result = perform.call(context, [job]);

        if (uniqBy) {
          const uniqueId = Ember.get(job, uniqBy);
          lastRunDict[uniqueId] = tStart;
        }

        count += Ember.isNone(result) ? 1 : result;
      } // record time we finished work


      const tDone = new Date().getTime();
      const asyncTime = this.get('asyncTime');

      if (asyncTime) {
        // `asyncTime` is the minimum unit of time we should allocate for one
        // non-trival job; we only sleep if the total synchronous time was less
        // than the total async time for all non-trivial completed jobs
        const sleepTime = Math.max(asyncTime * count - (tDone - tStart), 0);

        if (sleepTime > 0) {
          yield (0, _emberConcurrency.timeout)(sleepTime);
        }
      } // update statistics


      const tTotal = tDone - tStart;
      this._stats.count += count;
      this._stats.tSum += tTotal;

      if (count > 0) {
        const avg = tTotal / count; // Use linear equation to avoid overhead on ema calculation

        const lambda = count / this._stats.count;
        this._stats.ema = Ember.isNone(this._stats.ema) ? avg : lambda * avg + (1 - lambda) * this._stats.ema;
      }

      const throttle = this.get('throttle'); // only pause queue if a throttle time is set _and_ we actually did work

      if (throttle && count > 0) {
        this.set('isThrottled', true); // reset throttled state at later time

        this.set('_throttledTimer', Ember.run.later(() => {
          this.set('isThrottled', false);
        }, throttle));
      }

      return count;
    }),

    getMinWorkTime() {
      if (this._stats.count < MIN_SAMPLE_SIZE) return 1;
      return Math.ceil(3 * this._stats.ema);
    },

    getStarvationTime() {
      const now = new Date().getTime();
      if (this.lastRunAt) return now - this.lastRunAt;
      return 0;
    },

    _estimateChunkSize(time) {
      if (this._stats.count < MIN_SAMPLE_SIZE) return INITIAL_CHUNK_SIZE;
      if (time === Infinity || !this._stats.tSum) return Infinity;
      const chunkSize = Math.floor(time / this._stats.ema);
      return Math.max(chunkSize, 1);
    }

  });

  _exports.default = _default;
});