define("adept-iq/pods/components/form-components/vehicle-selection/vehicle-row/component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // will travel need have enough capacity for this flex seat group
  function flexSeatHaveCapacity(travelNeed, flexSeat) {
    return Object.entries(travelNeed).every(([travelNeedTypeName, travelNeedCount]) => {
      const capacityCount = flexSeat[travelNeedTypeName] || 0;
      return capacityCount >= travelNeedCount;
    });
  }

  var _default = _ltRow.default.extend({
    classNameBindings: ['isUnderCapacity', 'isDisabled'],
    vehicle: Ember.computed.readOnly('row.vehicle'),
    isDisabled: Ember.computed.readOnly('vehicle.placeholder'),
    // we need to check maximum capacity per clusters of a route
    isUnderCapacity: Ember.computed('extra.dispatchRoute.clusters.@each.maxTravelNeedCounts', 'vehicle.capacityCounts', function () {
      const clusters = this.get('extra.dispatchRoute.clusters').toArray();
      const flexSeats = this.get('vehicle.capacityCounts.flexSeats');
      let haveCapcity = true;
      if (clusters.length < 1) return !haveCapcity; // have to check max travel needs for each cluster with all possible flex seats

      haveCapcity = clusters.every(cluster => {
        const travelNeed = cluster.get('maxTravelNeedCounts');
        let haveFlexCapcity = false;
        if (!flexSeats || flexSeats.length < 1) return true;
        if (!travelNeed) return true;
        haveFlexCapcity = flexSeats.some(flexSeat => {
          return flexSeatHaveCapacity(travelNeed, flexSeat);
        });
        return haveFlexCapcity;
      });
      return !haveCapcity;
    })
  });

  _exports.default = _default;
});