define("adept-iq/pods/components/iq-widgets/previous-bookings-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/previous-bookings-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'booking',
    defaultSortId: 'id',
    defaultSortAsc: false,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'Booking',
    columns: [{
      id: 'dateRequestTime',
      index: 0,
      type: 'text',
      isKey: true,
      label: 'Date / Request / Time',
      valuePath: 'requestTimeForDisplay',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 200,
      format: 'YYYY-MM-DD / / hh:mm A'
    }, {
      id: 'bookingId',
      index: 1,
      type: 'text',
      label: 'Booking ID',
      valuePath: 'id',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'status',
      index: 2,
      type: 'text',
      label: 'Status',
      valuePath: 'computedStatus',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 70,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'passengerId',
      index: 3,
      type: 'text',
      label: 'Passenger ID',
      valuePath: 'firstLeg.rider.riderId',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'firstName',
      index: 4,
      type: 'text',
      label: 'FirstName',
      valuePath: 'firstLeg.rider.firstName',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'lastName',
      index: 5,
      type: 'text',
      label: 'LastName',
      valuePath: 'firstLeg.rider.lastName',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 100,
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'pickAddress',
      index: 6,
      type: 'text',
      label: 'PickAddress',
      valuePath: 'firstSegmentInFistLeg.pick.place.address.fullAddress',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 100,
      validation: {
        type: 'date',
        minValue: '1900-01-01',
        maxValue: 'now'
      }
    }, {
      id: 'pickCity',
      index: 7,
      type: 'text',
      label: 'PickCity',
      valuePath: 'firstSegmentInFistLeg.pick.place.address.region',
      editable: true,
      searchable: true,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'isGroupBooking',
      index: 8,
      type: 'text',
      label: 'Group Booking',
      valuePath: 'isGroupBookingDisplayText',
      editable: false,
      searchable: false,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'groupId',
      index: 9,
      type: 'text',
      label: 'GroupID',
      valuePath: 'group.id',
      editable: false,
      searchable: false,
      hidden: false,
      defaultWidth: 100
    }]
  };
  _exports.default = _default;
});