define("adept-iq/serializers/driver", ["exports", "adept-iq/serializers/-driver"], function (_exports, _driver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _driver.default.extend({
    normalize(modelClass, resourceHash) {
      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};

      if (Ember.isPresent(resourceHash.attributes.providerName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.provider = {
          data: {
            type: 'provider',
            id: resourceHash.attributes.providerName
          }
        };
        resourceHash.relationships = relationships;
      } // hack for front-end, need backend to convert this relationship to be an object, not an array as that represents a hasMany


      if (Ember.isPresent(resourceHash.relationships.license)) {
        if (Ember.isArray(resourceHash.relationships.license.data)) {
          resourceHash.relationships.license = resourceHash.relationships.license.data[0];
        }
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {};

      if (Ember.isPresent(payload.data.attributes.provider)) {
        relationships.provider = {
          data: {
            type: 'provider',
            id: payload.data.attributes.provider
          }
        };
        delete payload.data.attributes.provider;
      }

      payload.data.relationships = relationships;
      return payload;
    }

  });

  _exports.default = _default;
});