define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/cellrenderer", ["exports", "@babel/runtime/helpers/esm/extends", "react", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/header-cell", "adept-iq/config/cell-item-types", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/extra-spacer-cell-item", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/tootip"], function (_exports, _extends2, _react, _headerCell, _cellItemTypes, _extraSpacerCellItem, _tootip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getCellItem = columnInfo => {
    let CellItem = _cellItemTypes.default.CellItem; // default React class

    if (columnInfo.cellItem) {
      CellItem = _cellItemTypes.default[columnInfo.cellItem];
    }

    return CellItem;
  };

  const CellRenderer = props => {
    const {
      columnIndex,
      rowIndex,
      columns,
      showGearIcon
    } = props;
    const actionableColumnIndex = showGearIcon ? 1 : 0; // for checkbox and gear

    const columnIncrementerValue = showGearIcon ? 2 : 1; // for checkbox and gear

    const isHeaderRow = rowIndex === 0;
    const actionableColumnCells = columnIndex <= actionableColumnIndex;
    const isLastCell = columns.length === columnIndex;
    const columnInfo = actionableColumnCells ? {} : columns[columnIndex - columnIncrementerValue];
    const CellItem = getCellItem(columnInfo);
    return _react.default.createElement("span", null, isHeaderRow && _react.default.createElement(_headerCell.default, (0, _extends2.default)({}, props, {
      columnInfo: columnInfo
    })), !isHeaderRow && _react.default.createElement(_tootip.default, props, _react.default.createElement(CellItem, (0, _extends2.default)({}, props, {
      columnInfo: columnInfo
    }))), isLastCell && _react.default.createElement(_extraSpacerCellItem.default, (0, _extends2.default)({}, props, {
      columnInfo: columnInfo
    })));
  };

  var _default = CellRenderer;
  _exports.default = _default;
});