define("adept-iq/adapters/lock", ["exports", "adept-iq/adapters/-dispatch", "adept-iq/config/api-urls"], function (_exports, _dispatch, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    // not using "ajax" so there won't be conflict with the base's ajax calls
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),

    doLockRoute(dispatchRoute, persist = false) {
      const ajax = this.get('ajaxService');
      const session = this.get('session');
      const notifications = this.get('notifications');
      const timeoutParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeLockTimeout');
      const routeLockTimeout = timeoutParam ? parseInt(timeoutParam.get('value'), 10) : 1800;
      const json = {
        data: {
          type: 'lock',
          id: dispatchRoute.get('id'),
          attributes: {
            entityId: dispatchRoute.get('id'),
            entityType: 'dispatchRoute',
            timeToLive: routeLockTimeout,
            persist: persist
          }
        }
      };
      return ajax.post(_apiUrls.API.dispatchService.host + '/lock', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      }).catch(function (error) {
        notifications.warning(`Error creating route lock, ${error.message}`);
      });
    },

    doUnlockRoute(dispatchRoute) {
      const ajax = this.get('ajaxService');
      const session = this.get('session');
      const notifications = this.get('notifications');
      const userId = session.data.authenticated.userId;
      const dispatchRouteLock = dispatchRoute.get('dispatchRouteLock.content');
      const user = this.get('store').peekRecord('sso-user', userId);

      if (!dispatchRoute.get('isLocked')) {
        return;
      }

      if (userId !== dispatchRouteLock.get('owner.id') && !user.get('isAdmin')) {
        notifications.info('You do not have permission to unlock this route.');
        return;
      }

      if (!Ember.isNone(dispatchRouteLock)) {
        ajax.delete(_apiUrls.API.dispatchService.host + '/lock/force/dispatchRoute/' + dispatchRouteLock.get('id'), {
          method: 'DELETE',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        }).catch(function (error) {
          notifications.warning(`Error removing route lock, ${error.message}`);
        });
      }
    }

  });

  _exports.default = _default;
});