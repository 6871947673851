define("adept-iq/pods/components/side-drawer/unperform-no-show/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy"], function (_exports, _emberConcurrency, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['perform-stop-drawer'],
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    // passed in by side-drawer-container component
    onShrinkClick: null,
    onCloseClick: null,
    noShowTime: null,
    noShowNotes: '',
    errorText: null,
    unperformTitle: 'Unperform No Show',
    unperformMessage: 'Unperforming Stop will NOT change Promise Time.',

    init() {
      this._super(...arguments);

      this.set('noShowTime', new Date());
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.get('refreshTask').perform();
    },

    stopPoint: readOnly('stackFrame.options.stopPoint'),
    title: readOnly('stackFrame.options.title'),
    trip: readOnly('stopPoint.trip'),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      this.set('noShowTime', new Date());
      this.set('noShowNotes', '');
      yield (0, _emberConcurrency.timeout)(30);
    }).keepLatest(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const stopPoint = (0, _unwrapProxy.unwrapProxy)(this.get('stopPoint'));
      const trip = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('trip'));
      const otherStopPoint = (0, _unwrapProxy.unwrapProxy)(trip.get('dropStopPoint'));
      const noShowTime = this.get('noShowTime');
      const noShowNotes = this.get('noShowNotes');
      const arriveTimestamp = stopPoint.get('actualArriveTime');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');

      if (stopPoint.get('isNoShow')) {
        const tripNoShowAdapter = store.adapterFor('trip-noshow');
        yield tripNoShowAdapter.deleteTripNoShow(trip);
        yield routeExecEventAdapter.createAcceptEvent(stopPoint, noShowTime, noShowNotes);

        if (otherStopPoint.get('isPerformed') || otherStopPoint.get('isNoShow')) {
          yield routeExecEventAdapter.createAcceptEvent(otherStopPoint, noShowTime, noShowNotes);
        } // if this stop point have been arrived before then change state back to arrive


        if (arriveTimestamp) {
          stopPoint.set('arriveTimestamp', arriveTimestamp);
          yield routeExecEventAdapter.createStopArriveEvent(stopPoint);
        }
      }
    }).drop(),
    actions: {
      noop() {
        return false;
      },

      onRefreshButtonClick() {
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        this.onCloseClick();
      },

      onPerformButtonClick() {
        // Reset error text
        this.set('errorText');
        const tip = 'Unperforming No Show';
        const message = 'Unperformed No Show';
        const title = 'Unperform';
        this.get('tooltip').pushConfirmation({
          tip: tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('saveTask').perform().then(() => {
              this.get('notifications').success(`${message} successfully`);
              this.get('tooltip').reset();
              return this.onCloseClick();
            }).catch(() => {
              this.get('notifications').warning(`${message} failed`);
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            this.get('tooltip').reset();
            return;
          }
        });
      }

    }
  });

  _exports.default = _default;
});