define("adept-iq/pods/components/side-drawer/perform-stop/all/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/utils/convertDistance", "moment", "lodash"], function (_exports, _emberConcurrency, _unwrapProxy, _convertDistance, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['perform-stop-drawer'],
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    // passed in by side-drawer-container component
    onShrinkClick: null,
    onCloseClick: null,
    componentName: 'performStop',
    currentStop: null,
    currentState: null,
    // passed in by stops widget
    stopPoints: Ember.computed.readOnly('workspace.topOptions.stopPoints'),
    isNextDisabled: Ember.computed.not('currentStop.depart'),
    currentStopPosition: Ember.computed('currentStop', function () {
      const stops = this.get('stopPoints');
      const currentStop = this.get('currentStop');
      return stops.indexOf(currentStop) + 1;
    }),
    currentStopIndex: Ember.computed('currentStopPosition', function () {
      return this.get('currentStopPosition') - 1;
    }),
    hasNextStop: Ember.computed('currentStop', 'stopPoints', function () {
      const stops = this.get('stopPoints');
      return stops.indexOf(this.get('currentStop')) < stops.length - 1;
    }),
    title: Ember.computed('currentStop.stopType', function () {
      const stopType = this.getWithDefault('currentStop.stopType', '');
      return `Perform ${Ember.String.capitalize(stopType)}`;
    }),
    canSave: Ember.computed('hasNextStop', function () {
      const hasNextStop = !this.get('hasNextStop');
      return hasNextStop;
    }),
    nextButtonDisabled: Ember.computed('isNextDisabled', 'hasNextStop', function () {
      const isNextDisabled = this.get('isNextDisabled');
      const hasNextStop = !this.get('hasNextStop');
      return isNextDisabled || hasNextStop;
    }),

    init() {
      this._super(...arguments);
    },

    loadCurrentState(stops) {
      const {
        currentStop
      } = this.get('currentState') || {};

      if (!(0, _lodash.isEmpty)(currentStop)) {
        this.set('currentStop', currentStop);
      } else {
        this.set('currentStop', stops.get('firstObject'));
      }
    },

    didInsertElement() {
      const stops = this.get('stopPoints') || [];
      const uniqueRouteIds = stops.map(stop => stop.get('dispatchRoute.route.name')).uniq();

      if (uniqueRouteIds.length > 1) {
        this.get('notifications').info('Please only select stops from the same route');
        this.onCloseClick();
      } else {
        this.set('routeId', uniqueRouteIds[0]);
      }

      this.loadCurrentState(stops);
    },

    willDestroyElement() {
      const stopPoints = this.get('stackFrame.options.stopPoints'); // make sure to clean up stop point execute function

      stopPoints.forEach(stop => {
        stop.rollback();
      });

      this._super(...arguments);
    },

    saveTask() {
      const notifications = this.get('notifications');
      const stopPoints = this.get('stopPoints');
      return stopPoints.reduce((acu, stopPoint) => {
        const stopType = Ember.String.capitalize(stopPoint.get('stopType'));

        if (stopPoint.get('arrive') || stopPoint.get('depart')) {
          return acu.then(() => this.get(`save${stopType}Task`).perform(stopPoint).catch(e => {
            if (!(0, _emberConcurrency.didCancel)(e)) {
              notifications.warning('Failed to Perform All Stops');
              throw e;
            }
          }));
        }

        return acu.then(() => Promise.resolve());
      }, Promise.resolve());
    },

    prepStopPointInSequence(stopPoint, stopContainer) {
      const trip = stopPoint.get('trip');
      const isArrived = stopContainer.get('isArrived');
      stopPoint.set('isArrived', isArrived);
      stopPoint.set('arrive', stopContainer.get('arrive'));
      stopPoint.set('depart', stopContainer.get('depart'));
      const actualArriveTime = stopContainer.get('actualArriveTime');
      const actualDepartTime = stopContainer.get('actualDepartTime');
      if (actualArriveTime) stopPoint.set('actualArriveTime', actualArriveTime);
      if (actualDepartTime) stopPoint.set('actualDepartTime', actualDepartTime);

      if (stopPoint.get('isBreak')) {
        stopPoint.set('routeBreak.actualArriveTime', actualArriveTime);
        stopPoint.set('routeBreak.actualDepartTime', actualDepartTime);
      }

      if (stopPoint.get('isPick')) {
        // Handle Travel Needs
        stopPoint.set('serviceAnimalCount', stopContainer.get('serviceAnimalCount'));
        stopPoint.set('pcaCount', stopContainer.get('pcaCount'));
        stopPoint.set('companionCount', stopContainer.get('companionCount'));
        stopPoint.set('travelNeeds', stopContainer.get('travelNeedItems').map(travelNeedItem => ({
          type: travelNeedItem.get('type'),
          cnt: travelNeedItem.get('count')
        })));
        stopPoint.set('travelNeedItems', stopContainer.get('travelNeedItems'));
        trip.set('fare', stopContainer.get('amount'));
        trip.set('fareName', stopContainer.get('selectedPaymentType'));
      }

      stopPoint.set('odometer', (0, _convertDistance.convertMilesToMeters)(stopPoint.get('odometerInMiles')));
      stopPoint.execute();
    },

    async processTripCapacityChange(travelNeedItems, stopPoint) {
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      const session = this.get('session');
      const route = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('route'));
      const trip = (0, _unwrapProxy.unwrapProxy)(stopPoint.get('trip'));
      const vehicle = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute.assignedVehicle'));
      const driver = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute.assignedDriver'));
      const dispatchSchedule = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute.dispatchSchedule'));
      const providerId = route.get('provider.id');
      let attributeChanges = false;
      travelNeedItems.forEach(item => {
        if (item.count !== item.oldCount) {
          attributeChanges = true;
        }
      });

      if (attributeChanges) {
        const details = `Capacity for Trip ${trip.tripId} was modified`;
        const eventLog = {
          type: 'event',
          attributes: {
            routeId: parseInt(route.get('id'), 10),
            tripId: parseInt(trip.get('id'), 10),
            stopPointId: stopPoint.get('id'),
            driverId: parseInt(driver.get('id'), 10),
            vehicleId: parseInt(vehicle.get('id'), 10),
            scheduleId: parseInt(dispatchSchedule.get('id'), 10),
            source: 'webApp',
            providerName: providerId,
            priority: 'low',
            eventType: 'TEE',
            actionType: 'capacityChange',
            userId: session.data.authenticated.userId,
            eventLog: {
              capacityChange: [{
                activity: 'Trip Capacity Change',
                loggingType: 'route',
                details: details
              }, {
                activity: 'Trip Capacity Change',
                loggingType: 'trip',
                details: details
              }]
            }
          }
        };
        await activityLogAdapter.createEventActivity(eventLog);
      }
    },

    savePickTask: (0, _emberConcurrency.task)(function* (stopPoint) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripPaymentAdapter = store.adapterFor('trip-payment');
      const trip = stopPoint.get('trip');
      const isArrived = stopPoint.get('isArrived');
      const travelNeedItems = stopPoint.get('travelNeedItems');

      if (stopPoint.get('arrive') && !isArrived) {
        yield routeExecEventAdapter.createStopArriveEvent(stopPoint);
      }

      if (stopPoint.get('depart')) {
        // added this activity log here because it was difficult to calculate
        // in avlm
        yield this.processTripCapacityChange(travelNeedItems, stopPoint);
        yield routeExecEventAdapter.createStopDepartEvent(stopPoint);
      }

      if (stopPoint.get('amount')) {
        yield tripPaymentAdapter.createTripPaymentEvent(trip);
      }
    }).enqueue(),
    saveDropTask: (0, _emberConcurrency.task)(function* (stopPoint) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const isArrived = stopPoint.get('isArrived');
      const savePromises = [];

      if (stopPoint.get('arrive') && !isArrived) {
        const arrive = yield routeExecEventAdapter.createStopArriveEvent(stopPoint);
        savePromises.push(arrive);
      }

      if (stopPoint.get('depart')) {
        const depart = yield routeExecEventAdapter.createStopDepartEvent(stopPoint);
        savePromises.push(depart);
      }

      return Ember.RSVP.all(savePromises);
    }).enqueue(),
    saveBreakTask: (0, _emberConcurrency.task)(function* (stopPoint) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const isArrived = stopPoint.get('isArrived');
      const savePromises = [];

      if (stopPoint.get('arrive') && !isArrived) {
        const arrive = yield routeExecEventAdapter.createStartBreakEvent(stopPoint);
        savePromises.push(arrive);
      }

      if (stopPoint.get('depart')) {
        const depart = yield routeExecEventAdapter.createEndBreakEvent(stopPoint);
        savePromises.push(depart);
      }

      return Ember.RSVP.all(savePromises);
    }).enqueue(),

    validateStopPoint() {
      const currentStopContainer = this.get('currentStopContainer');
      const currentInUseStop = (0, _unwrapProxy.unwrapProxy)(this.get('currentStop'));
      const route = this.get('currentStopContainer.route');
      const arrive = currentStopContainer.get('arrive');
      const depart = currentStopContainer.get('depart');
      const routeEndTime = route.get('plannedEndTime');
      const currentDateTime = (0, _moment.default)().format('YYYY-MM-DD HH:mm A');
      const arriveTime = currentInUseStop.get('actualArriveTime') || currentInUseStop.get('routeBreak.actualArriveTime');
      const departTime = currentInUseStop.get('actualDepartTime') || currentInUseStop.get('routeBreak.actualDepartTime');
      const momentArriveTime = (0, _moment.default)(arriveTime).startOf('minute');
      const momentDepartTime = (0, _moment.default)(departTime).startOf('minute');
      const actualStartTime = currentInUseStop.get('dispatchRoute.computedStartTime');
      const orderedStops = currentInUseStop.get('dispatchRoute.orderedStops');
      const routeOrdinal = currentInUseStop.get('routeOrdinal');
      const maxStopDepartTime = (0, _lodash.findLast)(orderedStops, stop => stop.get('isPerformed'));
      const currentStopType = Ember.String.capitalize(currentInUseStop.get('stopType')); // Reset error text

      currentStopContainer.set('errorText', null);

      if (!arrive && !depart) {
        if (currentStopType === 'Pick') {
          currentStopContainer.set('errorText', 'Please fill in either Actual Arrival Time or Actual Pick Time.');
        } else if (currentStopType === 'Drop') {
          currentStopContainer.set('errorText', 'Please fill in either Actual Arrival Time or Actual Drop Time.');
        } else {
          currentStopContainer.set('errorText', 'Please fill in either Actual Start or Actual End.');
        }

        return false;
      }

      if (momentArriveTime.isBefore(actualStartTime) || momentDepartTime.isBefore(actualStartTime)) {
        currentStopContainer.set('errorText', `Performed ${currentStopType} Stop Failed. The stop cannot be performed before the route's start time`);
        return false;
      }

      if (momentArriveTime.isAfter(currentDateTime) || momentDepartTime.isAfter(currentDateTime)) {
        currentStopContainer.set('errorText', `Performed ${currentStopType} Stop Failed. The stop cannot be performed after the previous current time.`);
        return false;
      }

      if (currentInUseStop.get('isPick') || currentInUseStop.get('isBreak')) {
        if (momentArriveTime.isAfter(routeEndTime) || momentDepartTime.isAfter(routeEndTime)) {
          currentStopContainer.set('errorText', "Performed Pick Stop Failed. The stop cannot be performed after the route's end time.");
          return false;
        }
      }

      if (currentInUseStop.get('isDrop')) {
        if (momentArriveTime.isAfter(routeEndTime) || momentDepartTime.isAfter(routeEndTime)) {
          this.get('notifications').warning('Stop performed after planned route end time.');
        }
      }

      if (currentInUseStop.get('isBreak')) {
        const coord = currentInUseStop.get('routeBreak.place.location');

        if (Ember.isNone(coord.get('lat')) || Ember.isNone(coord.get('lng'))) {
          currentStopContainer.set('errorText', 'Performed Break Stop Failed. Cannot perform break without location.');
          return false;
        }
      }

      if (routeOrdinal > 0 && maxStopDepartTime) {
        if (momentArriveTime.isBefore(maxStopDepartTime.get('actualDepartTime')) || momentDepartTime.isBefore(maxStopDepartTime.get('actualDepartTime'))) {
          currentStopContainer.set('errorText', `Performed ${currentStopType} Stop Failed. The stop cannot be performed before the previous Actual drop time.`);
          return false;
        }
      }

      return true;
    },

    actions: {
      onRefreshButtonClick() {
        const currentStopContainer = this.get('currentStopContainer');
        currentStopContainer.get('refreshTask').perform();
      },

      onShrinkClick() {
        const stopUIReference = this.get('stopUIReference');

        if (stopUIReference) {
          stopUIReference.backUpCurrentState();
        }

        const currentStop = this.get('currentStop');
        const currentState = this.get('currentState') || {};
        this.set('currentState', { ...currentState,
          currentStop
        });
        this.onShrinkClick();
      },

      onCloseButtonClick() {
        const tooltip = this.get('tooltip');
        const currentStopPosition = (0, _unwrapProxy.unwrapProxy)(this.get('currentStopPosition'));
        const currentStopContainer = this.get('currentStopContainer');
        const notifications = this.get('notifications');
        const close = this.onCloseClick;
        const saveTask = this.get('saveTask').bind(this);
        const title = 'Progress will be saved';

        if (currentStopPosition === 1 && !(currentStopContainer.get('arrive') || currentStopContainer.get('depart'))) {
          return close(); // the user hasn't made any changes and decided to close anyways
        }

        const validation = this.validateStopPoint();

        if (validation) {
          tooltip.pushConfirmation({
            tip: 'All stops completed will be performed and saved. Do you want to close this panel?',
            title,
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return saveTask().then(() => {
                notifications.success(`Performed ${currentStopPosition} Stops Successfully`);
              }).catch(e => {
                console.warn(e); // eslint-disable-line
              }).finally(() => {
                tooltip.reset();
                return close();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: () => {
              return tooltip.reset();
            }
          });
        }
      },

      onPerformButtonClick() {
        const tooltip = this.get('tooltip');
        const currentStopContainer = this.get('currentStopContainer');
        const currentInUseStop = (0, _unwrapProxy.unwrapProxy)(this.get('currentStop'));
        const notifications = this.get('notifications');
        const close = this.onCloseClick;
        const title = 'Perform Stop';
        const saveTask = this.get('saveTask').bind(this);
        const validation = this.validateStopPoint();

        if (validation) {
          this.prepStopPointInSequence(currentInUseStop, currentStopContainer);
          tooltip.pushConfirmation({
            tip: 'Perform all stops in the sequence?',
            title,
            hasOverlay: true,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return saveTask().then(() => {
                notifications.success('Performed All Stops Successfully');
                close();
              }).finally(() => {
                tooltip.reset();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: () => {
              return tooltip.reset();
            }
          });
        }
      },

      onSelectStop(stop) {
        stop.rollback(); // undo stop attributes back to square one

        this.set('currentStop', stop);
      },

      nextStop() {
        // will only prep all stop points before last stop point
        const currentStopContainer = this.get('currentStopContainer');
        const currentInUseStop = (0, _unwrapProxy.unwrapProxy)(this.get('currentStop'));
        const validation = this.validateStopPoint();

        if (validation) {
          this.prepStopPointInSequence(currentInUseStop, currentStopContainer);
          if (!this.get('hasNextStop')) return false;
          const stops = this.get('stopPoints');
          const currentStop = this.get('currentStop');
          const currentIndex = stops.indexOf(currentStop);
          const nextStop = stops[currentIndex + 1];
          this.set('currentStop', nextStop);
          this.set('isNextDisabled', true);
          this.set('currentStopContainer.arrive', false);
          this.set('currentStopContainer.depart', false);
        }
      },

      setStopContainerReference(stopContainerReference) {
        if (stopContainerReference) {
          this.set('currentStopContainer', stopContainerReference);
        }
      },

      setStopUIReference(stopUIReference) {
        if (stopUIReference) {
          this.set('stopUIReference', stopUIReference);
        }
      },

      getValueFromchildTravelNeeds(travelNeedItems) {
        this.set('travelNeedItems', travelNeedItems);
      }

    }
  });

  _exports.default = _default;
});