define("adept-iq/pods/components/iq-widgets/subscriptions-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/subscriptions-widget/config", "lodash", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _lodash, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    dragCoordinator: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['subscriptions-widget', 'data-test-subscriptions-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    editComponent: 'iq-widgets/subscriptions-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,

    saveRecordFunction(records) {
      this.set('editModal.errors', []);

      function saveDeleteAllTravelNeeds(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.canonicalState, objects.content.currentState) || []; // delete objects that have been removed from the array

        return Promise.all(deleteObjects.map(deleteObject => {
          deleteObject._record.deleteRecord();

          return deleteObject.save();
        })).then(() => {
          return Promise.all(objects.map(object => {
            object.save();
          }));
        });
      }

      function saveNewTravelNeedObjects(subscription, oldObjects, newObjects) {
        /*eslint-disable */
        return Promise.all(newObjects.map(newObject => {
          if (newObject.get('count') !== null && newObject.get('count') > 0) {
            newObject.set('subscription', subscription);
            newObject.save();
            return newObject;
          }
        }));
        /*eslint-enable */
      }

      function saveRecurrencePatterns(objects) {
        return Promise.all(objects.map(object => {
          object.save();
        }));
      }

      function saveExlusions(objects) {
        return Promise.all(objects.map(object => {
          if (!Ember.isNone(object.get('startDate')) && !Ember.isNone(object.get('endDate'))) {
            object.save();
          }
        }));
      }

      async function saveLocationObject(object) {
        await object.address.save();
        await object.location.save();
        return object.save();
      }

      return new Promise((resolve, reject) => {
        if (records) {
          records.forEach(record => {
            record.save().then(() => {
              saveLocationObject(record.origin.content).then(() => {
                saveLocationObject(record.destination.content).then(() => {
                  saveRecurrencePatterns(record.get('recurrencePatterns')).then(() => {
                    saveExlusions(record.get('exclusions')).then(() => {
                      saveDeleteAllTravelNeeds(record.get('subscriptionTravelNeeds')).then(() => {
                        saveNewTravelNeedObjects(record, record.get('subscriptionTravelNeeds'), record.get('newTravelNeeds')).then(() => {
                          record.set('isForceDirty', false);
                          this.get('activeContext.topActiveContext').get('refreshTableContent').perform([this.get('modelName')]);
                        });
                      });
                    });
                  });
                });
              });
            }).catch(e => {
              reject(e);
            });
          });
        }
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('singleActions', [{
        id: 'edit',
        permId: _mappedPermIds.default.editSubscriptions,
        name: 'Edit',
        action: model => {
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [model], this.get('saveRecordFunction').bind(this));
        }
      }]);
      this.set('bulkActions', [{
        name: 'Bulk Edit',
        action: () =>
        /* models */
        {}
      }]);
    },

    rowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly()
  });

  _exports.default = _default;
});