define("adept-iq/classes/active-contexts/booking/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.activeContextGraph = _exports.activeContextNodes = _exports.alertNode = _exports.avlmMessageNode = _exports.ssoRoleNode = _exports.ssoUserNode = _exports.bsSubscriptionNode = _exports.bsStopNode = _exports.bsSegmentNode = _exports.bsLegNode = _exports.bsBookingNode = _exports.ssTripNode = _exports.ssRouteNode = _exports.dsStopNode = _exports.dsClusterNode = _exports.dsRouteVehicleDriverNode = _exports.dsRouteNode = _exports.zoneNode = _exports.vehicleNode = _exports.driverNode = _exports.riderExternalNode = _exports.riderNode = void 0;
  // Rider Managament Related Nodes
  const riderNode = {
    id: 'rider',
    modelName: 'rider',
    links: [{
      type: 'hasMany',
      nodeId: 'bs-leg',
      path: 'legs'
    }],
    providerFilterKey: 'providers'
  };
  _exports.riderNode = riderNode;
  const riderExternalNode = {
    id: 'riderExternal',
    modelName: 'rider-external',
    links: []
  }; // Driver Related Nodes

  _exports.riderExternalNode = riderExternalNode;
  const driverNode = {
    id: 'driver',
    modelName: 'driver',
    links: [{
      type: 'belongsTo',
      nodeId: 'ds-route-vehicle-driver',
      path: 'latestRouteVehicleDriver'
    }],
    providerFilterKey: 'provider'
  }; // Vehicle Related Nodes

  _exports.driverNode = driverNode;
  const vehicleNode = {
    id: 'vehicle',
    modelName: 'vehicle',
    links: [{
      type: 'belongsTo',
      nodeId: 'ds-route-vehicle-driver',
      path: 'latestRouteVehicleDriver'
    }],
    providerFilterKey: 'provider'
  }; // Zone Related Nodes

  _exports.vehicleNode = vehicleNode;
  const zoneNode = {
    id: 'zone',
    modelName: 'zone',
    links: [],
    providerFilterKey: false
  }; // Dispatch Related Nodes
  // used to traverse local data model

  _exports.zoneNode = zoneNode;
  const dsRouteNode = {
    id: 'ds-route',
    modelName: 'dispatch-route',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'assignedVehicle'
    }, {
      type: 'belongsTo',
      nodeId: 'driver',
      path: 'assignedDriver'
    }, {
      type: 'belongsTo',
      nodeId: 'ss-route',
      path: 'route'
    }, {
      type: 'hasMany',
      nodeId: 'ds-stop',
      path: 'orderedStopsWithGaragesAndBreaks'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.dsRouteNode = dsRouteNode;
  const dsRouteVehicleDriverNode = {
    id: 'ds-route-vehicle-driver',
    modelName: 'route-vehicle-driver',
    links: [{
      type: 'belongsTo',
      nodeId: 'ds-route',
      path: 'route'
    }, {
      type: 'belongsTo',
      nodeId: 'driver',
      path: 'driver'
    }, {
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'vehicle'
    }],
    isActive: true,
    providerFilterKey: 'dispatchRoute.provider',
    leftTimeConstraint: 'dispatchRoute.tripStartTime',
    rightTimeConstraint: 'dispatchRoute.tripEndTime'
  };
  _exports.dsRouteVehicleDriverNode = dsRouteVehicleDriverNode;
  const dsClusterNode = {
    id: 'ds-cluster',
    modelName: 'cluster',
    links: [],
    isActive: true,
    providerFilterKey: 'dispatcherRoute.provider',
    leftTimeConstraint: 'dispatchRoute.tripStartTime',
    rightTimeConstraint: 'dispatchRoute.tripEndTime'
  };
  _exports.dsClusterNode = dsClusterNode;
  const dsStopNode = {
    id: 'ds-stop',
    modelName: 'stop-point',
    links: [{
      type: 'belongsTo',
      nodeId: 'ds-cluster',
      path: 'cluster'
    }, {
      type: 'belongsTo',
      nodeId: 'ss-trip',
      path: 'trip'
    }, {
      type: 'belongsTo',
      nodeId: 'ds-route',
      path: 'dispatchRoute'
    }],
    isActive: true,
    showUnAssignedProvider: true,
    providerFilterKey: 'computedProvider',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  }; // Scheduling Related Nodes

  _exports.dsStopNode = dsStopNode;
  const ssRouteNode = {
    id: 'ss-route',
    modelName: 'route',
    links: [{
      type: 'belongsTo',
      nodeId: 'ds-route',
      path: 'dispatchRoute'
    }, {
      type: 'hasMany',
      nodeId: 'avlm-message',
      path: 'cannedMessages'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    leftTimeConstraint: 'plannedStartTime',
    rightTimeConstraint: 'plannedEndTime'
  };
  _exports.ssRouteNode = ssRouteNode;
  const ssTripNode = {
    id: 'ss-trip',
    modelName: 'trip',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-segment',
      path: 'segment'
    }, {
      type: 'hasMany',
      nodeId: 'ds-stop',
      path: 'stopPoints'
    }],
    isActive: true,
    showUnAssignedProvider: true,
    providerFilterKey: 'tripProvider',
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime'
  }; // booking service related nodes

  _exports.ssTripNode = ssTripNode;
  const bsBookingNode = {
    id: 'bs-booking',
    modelName: 'booking',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-subscription',
      path: 'subscription'
    }, {
      type: 'hasMany',
      nodeId: 'ss-trip',
      path: 'trips'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsBookingNode = bsBookingNode;
  const bsLegNode = {
    id: 'bs-leg',
    modelName: 'leg',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-booking',
      path: 'booking'
    }, {
      type: 'belongsTo',
      nodeId: 'rider',
      path: 'rider'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsLegNode = bsLegNode;
  const bsSegmentNode = {
    id: 'bs-segment',
    modelName: 'segment',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-leg',
      path: 'leg'
    }, {
      type: 'hasMany',
      nodeId: 'bs-stop',
      path: 'stops'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsSegmentNode = bsSegmentNode;
  const bsStopNode = {
    id: 'bs-stop',
    modelName: 'segment-stop',
    links: [{
      type: 'belongsTo',
      nodeId: 'bs-segment',
      path: 'segment'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.bsStopNode = bsStopNode;
  const bsSubscriptionNode = {
    id: 'bs-subscription',
    modelName: 'subscription',
    links: [{
      type: 'hasMany',
      nodeId: 'bs-booking',
      path: 'bookings'
    }],
    providerFilterKey: false,
    isActive: false
  };
  _exports.bsSubscriptionNode = bsSubscriptionNode;
  const ssoUserNode = {
    id: 'sso-user',
    modelName: 'sso-user',
    links: [{
      type: 'hasMany',
      nodeId: 'sso-role',
      path: 'roles'
    }],
    providerFilterKey: false
  };
  _exports.ssoUserNode = ssoUserNode;
  const ssoRoleNode = {
    id: 'sso-role',
    modelName: 'sso-role',
    isActive: false,
    links: [{
      type: 'hasMany',
      nodeId: 'sso-user',
      path: 'users'
    }],
    providerFilterKey: false
  };
  _exports.ssoRoleNode = ssoRoleNode;
  const avlmMessageNode = {
    id: 'avlm-message',
    modelName: 'avlm-canned-message',
    links: [{
      type: 'belongsTo',
      nodeId: 'driver',
      path: 'driver'
    }, {
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'vehicle'
    }, {
      type: 'belongsTo',
      nodeId: 'ss-route',
      path: 'route'
    }],
    isActive: true,
    providerFilterKey: false,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime'
  };
  _exports.avlmMessageNode = avlmMessageNode;
  const alertNode = {
    id: 'avlm-alert',
    modelName: 'avlm-alert',
    links: [{
      type: 'belongsTo',
      nodeId: 'vehicle',
      path: 'vehicle'
    }, {
      type: 'belongsTo',
      nodeId: 'ss-route',
      path: 'route'
    }],
    isActive: true,
    providerFilterKey: 'provider',
    leftTimeConstraint: 'timestamp',
    rightTimeConstraint: 'timestamp'
  };
  _exports.alertNode = alertNode;
  const activeContextNodes = [// vehicle graph
  vehicleNode, // driver graph
  driverNode, // zone graph,
  zoneNode, // dispatch graph
  dsRouteNode, dsRouteVehicleDriverNode, dsClusterNode, dsStopNode, // scheduling graph
  ssTripNode, ssRouteNode, // rider management graph
  riderNode, riderExternalNode, // sign-on
  ssoUserNode, ssoRoleNode, // booking service graph
  bsBookingNode, bsLegNode, bsSegmentNode, bsStopNode, bsSubscriptionNode, // avlm graph
  avlmMessageNode, // alerts
  alertNode];
  _exports.activeContextNodes = activeContextNodes;
  const activeContextGraph = activeContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.activeContextGraph = activeContextGraph;
  var _default = activeContextGraph;
  _exports.default = _default;
});