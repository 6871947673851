define("adept-iq/serializers/address", ["exports", "adept-iq/serializers/-address"], function (_exports, _address) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _address.default.extend({
    attrs: {
      freeformAddress: {
        serialize: false
      }
    },

    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      const {
        zoneName
      } = json.data.relationships;
      zoneName.data.type = 'zoneName'; // TEMPORARY FIX FOR ROAD SUPERVISOR

      delete json.data.attributes.postOfficeBoxNumber;
      delete json.data.attributes.type;
      delete json.data.attributes.country;
      Object.keys(json.data.attributes).forEach(key => {
        if (Ember.isBlank(json.data.attributes[key])) {
          delete json.data.attributes[key];
        }
      });
      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships) && !Ember.isNone(resourceHash.relationships.zoneName)) {
        const zoneName = resourceHash.relationships.zoneName;
        zoneName.data.type = 'zone-type';
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});