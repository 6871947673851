define("adept-iq/serializers/trip-stop", ["exports", "adept-iq/serializers/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {}; // TODO: store stop-point correctly.

      if (Ember.isPresent(payload.data.attributes.provider)) {
        relationships.provider = {
          data: {
            type: 'provider',
            id: payload.data.attributes.provider
          }
        };
        delete payload.data.attributes.provider;
      }

      payload.data.relationships = relationships;
      return payload;
    }

  });

  _exports.default = _default;
});