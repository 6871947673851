define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/fuel-cap/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/fuel-cap/fuel-cap-validation", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _fuelCapValidation, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['vehicle-fuel-cap-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // placeholder
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    description: null,
    isNewRecord: false,
    editAPI: null,
    disableForm: true,
    refreshRecord: true,
    //To refresh on undoRecord and newRecord
    showErrorMsg: false,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    vehicleFuelCapRecord: Ember.computed('selectedRecord', 'refreshRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSavevehicleFuelCapRecord = this.get('onSavevehicleFuelCapRecord');
      const onNewvehicleFuelCapRecord = this.get('onNewvehicleFuelCapRecord').bind(this);
      const onEditvehicleFuelCapRecord = this.get('onEditvehicleFuelCapRecord').bind(this);
      const onDeletevehicleFuelCapRecord = this.get('onDeletevehicleFuelCapRecord');
      const onUndovehicleFuelCapRecord = this.get('onUndovehicleFuelCapRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSavevehicleFuelCapRecord,
          newAction: onNewvehicleFuelCapRecord,
          deleteAction: onDeletevehicleFuelCapRecord,
          editAction: onEditvehicleFuelCapRecord,
          undoAction: onUndovehicleFuelCapRecord
        });
        this.buttonSettings();
      });
    },

    setDefaultProperties(record = []) {
      const store = this.get('store');
      const isNewRecord = this.get('isNewRecord');
      let vehicleFuelCapObj = record;

      if (isNewRecord && Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-vehicle_fuel_caps',
          type: 'object'
        };
        vehicleFuelCapObj = store.createRecord('cs-config-item', data);
      } else if (Ember.isEmpty(record)) {
        vehicleFuelCapObj = Ember.Object.create({
          description: ''
        });
      }

      if (!isNewRecord) {
        Ember.run.scheduleOnce('afterRender', this, 'buttonSettings');
        this.set('disableForm', true);
      }

      this.set('showErrorMsg', false);
      return new _emberChangeset.default(vehicleFuelCapObj, (0, _emberChangesetValidations.default)(_fuelCapValidation.VALIDATIONS), _fuelCapValidation.VALIDATIONS);
    },

    buttonSettings(deleteBtn = false, editBtn = false, saveBtn = true, undoBtn = true, newBtn = false) {
      const editAPI = this.get('editAPI');
      editAPI.setProperties({
        deleteBtn,
        editBtn,
        saveBtn,
        undoBtn,
        newBtn
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const vehicleFuelCapRecord = this.get('vehicleFuelCapRecord');
      yield vehicleFuelCapRecord.validate();
      const errors = vehicleFuelCapRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSavevehicleFuelCapRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      if (!isValid) return;
      const notifications = this.get('notifications');
      const description = this.get('vehicleFuelCapRecord.description') || '';
      const value = {
        description: description
      };
      const isNewRecord = this.get('isNewRecord');
      let vehicleFuelCapRecord = null;

      if (isNewRecord) {
        vehicleFuelCapRecord = (0, _unwrapProxy.unwrapProxy)(this.get('vehicleFuelCapRecord'));
      } else {
        const id = this.get('vehicleFuelCapRecord.id');
        const store = this.get('store');
        const record = `config-System_Configuration-vehicle_fuel_caps/${id}`;
        vehicleFuelCapRecord = store.peekRecord('cs-config-item', record);
      }

      const name = description.replace(/\s/g, '');
      vehicleFuelCapRecord.set('name', name);
      vehicleFuelCapRecord.set('displayName', description);
      vehicleFuelCapRecord.set('description', description);
      vehicleFuelCapRecord.set('value', value);

      try {
        yield vehicleFuelCapRecord.save();
        notifications.success('Lookup record successfully saved.');
        this.set('isNewRecord', false);
        this.set('disableForm', true);
        this.get('tableRef').refreshData(); //to refresh table data

        this.buttonSettings();
      } catch (e) {
        notifications.warning('Lookup record failed to save.');
        console.warn(e); // eslint-disable-line
      }
    }).drop(),

    onEditvehicleFuelCapRecord() {
      this.set('disableForm', false);
      this.buttonSettings(true, true, false, false, true);
    },

    onUndovehicleFuelCapRecord() {
      this.toggleProperty('refreshRecord');
    },

    onNewvehicleFuelCapRecord() {
      this.set('isNewRecord', true);
      this.get('tableRef').onUncheckAll();
      this.set('disableForm', false);
      this.toggleProperty('refreshRecord');
      this.buttonSettings(true, true, false, false, true);
    },

    deleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRow = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      const notifications = this.get('notifications');
      const isNewRecord = this.get('isNewRecord');

      if (isNewRecord) {
        const vehicleFuelCapRecord = this.get('vehicleFuelCapRecord');
        vehicleFuelCapRecord.set('description', '');
      }

      yield selectedRow.destroyRecord();

      try {
        notifications.success('Lookup record successfully deleted.');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (e) {
        notifications.warning('Lookup record failed to delete.');
        console.warn(e); // eslint-disable-line
      }
    }).drop(),
    onDeletevehicleFuelCapRecord: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      yield tooltip.pushConfirmation({
        title: 'Look UP',
        hasoverlay: true,
        tip: 'Are you sure you want to delete this record?',
        primaryActionText: 'Confirm',
        primaryAction: () => {
          const store = this.get('store');
          const description = this.get('vehicleFuelCapRecord.description') || '';

          if (description) {
            const vehicle = store.peekAll('vehicle').filter(record => record.get('active') && record.get('vehicleFuelCaps.description') === description);

            if (vehicle.length) {
              tooltip.pushConfirmation({
                title: 'Look UP',
                hasoverlay: true,
                tip: `There are ${vehicle.length} active vehicle(s) with this Vehicle Fuel Cap description. You must either change the Vehicle Fuel Cap description on these vehicle(s) or delete these vehicle(s) prior to changing the Vehicle Fuel Cap description.`,
                primaryActionText: 'OK',

                primaryAction() {
                  tooltip.reset();
                }

              });
            }
          }

          return this.get('deleteRecord').perform().then(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        }

      });
    }).drop()
  });

  _exports.default = _default;
});