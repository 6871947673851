define("adept-iq/config/filter-types", ["exports", "adept-iq/utils/encoding", "adept-iq/utils/rql-generators", "adept-iq/utils/filter-functions", "adept-iq/utils/format-function"], function (_exports, _encoding, _rqlGenerators, _filterFunctions, _formatFunction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterTypesHash = _exports.filterTypes = _exports.dateAndTimeGt = _exports.dateAndTimeLte = _exports.timeStrEq = _exports.timeGte = _exports.timeGt = _exports.timeLte = _exports.timeLt = _exports.timeEq = _exports.timeNe = _exports.dateStrictlyBetween = _exports.dateBetween = _exports.dateStrNe = _exports.dateNe = _exports.dateStrEq = _exports.dateEq = _exports.dateGte = _exports.dateGt = _exports.dateLte = _exports.dateLt = _exports.integerEq = _exports.numberStrictlyBetween = _exports.numberBetween = _exports.numberGte = _exports.numberGt = _exports.numberLte = _exports.numberLt = _exports.numberNotEq = _exports.numberEq = _exports.booleanNe = _exports.booleanEq = _exports.enumContains = _exports.enumNotEq = _exports.enumEq = _exports.dateContainsGte = _exports.dateContainsLte = _exports.dateContains = _exports.stringContains = _exports.stringNotEq = _exports.stringEq = _exports.uuidIn = _exports.uuidEq = _exports.emptyFilter = _exports.nullFilter = void 0;
  const nullFilter = {
    id: 'null',
    label: '&nbsp;',
    arity: '*',
    isValue: () => true,
    parseValue: x => x,
    fn: _filterFunctions.nullFn,
    rql: _rqlGenerators.nullRQL
  };
  _exports.nullFilter = nullFilter;
  const emptyFilter = {
    id: 'empty',
    label: 'Empty',
    arity: 0,
    isValue: () => true,
    parseValue: x => x,
    fn: _filterFunctions.emptyFn,
    rql: _rqlGenerators.emptyRQL
  };
  _exports.emptyFilter = emptyFilter;
  const uuidEq = {
    id: 'uuidEq',
    label: 'Equals',
    arity: 1,
    isValue: _encoding.isNonEmptyString,
    parseValue: _encoding.parseString,
    fn: _filterFunctions.eqFn,
    rql: _rqlGenerators.eqRQL
  };
  _exports.uuidEq = uuidEq;
  const uuidIn = {
    id: 'uuidIn',
    label: 'In',
    arity: '+',
    isValue: _encoding.isNonEmptyString,
    parseValue: _encoding.parseString,
    fn: _filterFunctions.includesFn,
    rql: _rqlGenerators.inRQL
  };
  _exports.uuidIn = uuidIn;
  const stringEq = {
    id: 'stringEq',
    label: '=',
    arity: 1,
    isValue: _encoding.isNonEmptyString,
    parseValue: _encoding.parseString,
    fn: _filterFunctions.stringEqIFn,
    rql: _rqlGenerators.stringEqRQL
  };
  _exports.stringEq = stringEq;
  const stringNotEq = {
    id: 'stringNotEq',
    label: '&ne;',
    arity: 1,
    isValue: _encoding.isNonEmptyString,
    parseValue: _encoding.parseString,
    fn: _filterFunctions.stringNeIFn,
    rql: _rqlGenerators.stringNeRQL
  };
  _exports.stringNotEq = stringNotEq;
  const stringContains = {
    id: 'stringContains',
    label: 'Contains',
    arity: 1,
    isValue: _encoding.isNonEmptyString,
    parseValue: _encoding.parseString,
    fn: _filterFunctions.stringIncludesIFn,
    rql: _rqlGenerators.stringLikeRQL
  };
  _exports.stringContains = stringContains;
  const dateContains = {
    id: 'dateContains',
    label: 'Contains',
    airty: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseString,
    fn: _filterFunctions.dateIncludesIFn,
    rql: _rqlGenerators.dateEqRQL
  };
  _exports.dateContains = dateContains;
  const dateContainsLte = {
    id: 'dateContainsLte',
    label: 'At or Before',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseString,
    fn: _filterFunctions.dateIncludesLteFn,
    rql: _rqlGenerators.dateEqRQL
  };
  _exports.dateContainsLte = dateContainsLte;
  const dateContainsGte = {
    id: 'dateContainsGte',
    label: 'At or After',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseString,
    fn: _filterFunctions.dateIncludesGteFn,
    rql: _rqlGenerators.dateEqRQL
  };
  _exports.dateContainsGte = dateContainsGte;
  const enumEq = {
    id: 'enumEq',
    label: '=',
    arity: 1,
    isValue: _encoding.isNonEmptyString,
    parseValue: _encoding.parseString,
    fn: _filterFunctions.enumStEqIFn,
    rql: _rqlGenerators.stringLikeRQL
  };
  _exports.enumEq = enumEq;
  const enumNotEq = {
    id: 'enumNotEq',
    label: '&ne;',
    arity: 1,
    isValue: _encoding.isNonEmptyString,
    parseValue: _encoding.parseString,
    fn: _filterFunctions.enumStNtEqIFn,
    rql: _rqlGenerators.stringLikeRQL
  };
  _exports.enumNotEq = enumNotEq;
  const enumContains = {
    id: 'enumContains',
    label: 'Contains',
    arity: 1,
    isValue: _encoding.isNonEmptyString,
    parseValue: _encoding.parseString,
    fn: _filterFunctions.enumIncludesIFn,
    rql: _rqlGenerators.stringLikeRQL
  };
  _exports.enumContains = enumContains;
  const booleanEq = {
    id: 'booleanEq',
    label: 'Is',
    arity: 1,
    isValue: _encoding.isEncodedBoolean,
    parseValue: _encoding.parseBoolean,
    fn: _filterFunctions.eqFn,
    rql: _rqlGenerators.eqRQL // TODO: double check

  };
  _exports.booleanEq = booleanEq;
  const booleanNe = {
    id: 'booleanNe',
    label: 'Is not',
    arity: 1,
    isValue: _encoding.isEncodedBoolean,
    parseValue: _encoding.parseBoolean,
    fn: _filterFunctions.neFn,
    rql: _rqlGenerators.neRQL // TODO: double check

  };
  _exports.booleanNe = booleanNe;
  const numberEq = {
    id: 'numberEq',
    label: '=',
    arity: 1,
    isValue: _encoding.isEncodedNumber,
    parseValue: _encoding.parseNumber,
    fn: _filterFunctions.eqFn,
    rql: _rqlGenerators.eqRQL
  };
  _exports.numberEq = numberEq;
  const numberNotEq = {
    id: 'numberNotEq',
    label: '&ne;',
    arity: 1,
    isValue: _encoding.isEncodedNumber,
    parseValue: _encoding.parseNumber,
    fn: _filterFunctions.neFn,
    rql: _rqlGenerators.neRQL
  };
  _exports.numberNotEq = numberNotEq;
  const numberLt = {
    id: 'numberLt',
    label: '&lt;',
    arity: 1,
    isValue: _encoding.isEncodedNumber,
    parseValue: _encoding.parseNumber,
    fn: _filterFunctions.ltFn,
    rql: _rqlGenerators.ltRQL
  };
  _exports.numberLt = numberLt;
  const numberLte = {
    id: 'numberLte',
    label: '&le;',
    arity: 1,
    isValue: _encoding.isEncodedNumber,
    parseValue: _encoding.parseNumber,
    fn: _filterFunctions.lteFn,
    rql: _rqlGenerators.lteRQL
  };
  _exports.numberLte = numberLte;
  const numberGt = {
    id: 'numberGt',
    label: '&gt;',
    arity: 1,
    isValue: _encoding.isEncodedNumber,
    parseValue: _encoding.parseNumber,
    fn: _filterFunctions.gtFn,
    rql: _rqlGenerators.gtRQL
  };
  _exports.numberGt = numberGt;
  const numberGte = {
    id: 'numberGte',
    label: '&ge;',
    arity: 1,
    isValue: _encoding.isEncodedNumber,
    parseValue: _encoding.parseNumber,
    fn: _filterFunctions.gteFn,
    rql: _rqlGenerators.gteRQL
  };
  _exports.numberGte = numberGte;
  const numberBetween = {
    id: 'numberBetween',
    label: 'Between',
    arity: 2,
    isValue: _encoding.isEncodedNumber,
    parseValue: _encoding.parseNumber,
    fn: _filterFunctions.betweenFn,
    rql: _rqlGenerators.betweenRQL
  };
  _exports.numberBetween = numberBetween;
  const numberStrictlyBetween = {
    id: 'numberStrictlyBetween',
    label: 'Strictly Between',
    arity: 2,
    isValue: _encoding.isEncodedNumber,
    parseValue: _encoding.parseNumber,
    fn: _filterFunctions.strictlyBetweenFn,
    rql: _rqlGenerators.strictlyBetweenRQL
  };
  _exports.numberStrictlyBetween = numberStrictlyBetween;
  const integerEq = {
    id: 'integerEq',
    label: '=',
    arity: 1,
    isValue: _encoding.isEncodedNumber,
    parseValue: _encoding.parseInteger,
    fn: _filterFunctions.eqFn,
    rql: _rqlGenerators.eqRQL
  };
  /*
  date filter Types start
  */

  _exports.integerEq = integerEq;
  const dateLt = {
    id: 'dateLt',
    label: 'Before',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    fn: _filterFunctions.dateAndTimeLtFn,
    rql: _rqlGenerators.dateLtRQL
  };
  _exports.dateLt = dateLt;
  const dateLte = {
    id: 'dateLte',
    label: 'At or Before',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    fn: _filterFunctions.dateLteFn,
    rql: _rqlGenerators.dateLteRQL
  };
  _exports.dateLte = dateLte;
  const dateGt = {
    id: 'dateGt',
    label: 'After',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    fn: _filterFunctions.dateGtFn,
    rql: _rqlGenerators.dateGtRQL
  };
  _exports.dateGt = dateGt;
  const dateGte = {
    id: 'dateGte',
    label: 'At or After',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    fn: _filterFunctions.dateAndTimeGteFn,
    rql: _rqlGenerators.dateGteRQL
  };
  _exports.dateGte = dateGte;
  const dateEq = {
    id: 'dateEq',
    label: '=',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    fn: _filterFunctions.dateAndTimeEqFn,
    rql: _rqlGenerators.dateEqRQL
  };
  _exports.dateEq = dateEq;
  const dateStrEq = {
    id: 'dateStrEq',
    label: '=',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    fn: _filterFunctions.dateAndTimeStrEqFn,
    rql: _rqlGenerators.dateEqRQL
  };
  _exports.dateStrEq = dateStrEq;
  const dateNe = {
    id: 'dateNe',
    label: '&ne;',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    fn: _filterFunctions.dateAndTimeNeFn,
    rql: _rqlGenerators.dateNeRQL
  };
  _exports.dateNe = dateNe;
  const dateStrNe = {
    id: 'dateStrNe',
    label: '&ne;',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    fn: _filterFunctions.dateStrNeFn,
    rql: _rqlGenerators.dateNeRQL
  };
  _exports.dateStrNe = dateStrNe;
  const dateBetween = {
    id: 'dateBetween',
    label: 'Between',
    arity: 2,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    validateValues: ([t1, t2]) => t1.getTime() <= t2.getTime(),
    fn: _filterFunctions.dateBetweenFn,
    rql: _rqlGenerators.dateBetweenRQL
  };
  _exports.dateBetween = dateBetween;
  const dateStrictlyBetween = {
    id: 'dateStrictlyBetween',
    label: 'Strictly Between',
    arity: 2,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    validateValues: ([t1, t2]) => t1.getTime() <= t2.getTime(),
    fn: _filterFunctions.dateStrictlyBetweenFn,
    rql: _rqlGenerators.dateStrictlyBetweenRQL
  };
  /*
  date filter Types ends
  */

  /*
  time filter Types start
  */

  _exports.dateStrictlyBetween = dateStrictlyBetween;
  const timeNe = {
    id: 'timeNe',
    label: '&ne;',
    arity: 1,
    isValue: _encoding.isEncodedTime,
    parseValue: _encoding.parseTime,
    formatTargetValue: _formatFunction.formatTargetTimeValue,
    fn: _filterFunctions.dateAndTimeNeFn,
    rql: _rqlGenerators.timeNeRQL
  };
  _exports.timeNe = timeNe;
  const timeEq = {
    id: 'timeEq',
    label: '=',
    arity: 1,
    isValue: _encoding.isEncodedTime,
    parseValue: _encoding.parseTime,
    formatTargetValue: _formatFunction.formatTargetTimeValue,
    fn: _filterFunctions.dateAndTimeEqFn,
    rql: _rqlGenerators.timeEqRQL
  };
  _exports.timeEq = timeEq;
  const timeLt = {
    id: 'timeLt',
    label: 'Before',
    arity: 1,
    isValue: _encoding.isEncodedTime,
    parseValue: _encoding.parseTime,
    formatTargetValue: _formatFunction.formatTargetTimeValue,
    fn: _filterFunctions.dateAndTimeLtFn,
    rql: _rqlGenerators.timeLtRQL
  };
  _exports.timeLt = timeLt;
  const timeLte = {
    id: 'timeLte',
    label: 'At or Before',
    arity: 1,
    isValue: _encoding.isEncodedTime,
    parseValue: _encoding.parseTime,
    formatTargetValue: _formatFunction.formatTargetTimeValue,
    fn: _filterFunctions.dateAndTimeLteFn,
    rql: _rqlGenerators.timeLteRQL
  };
  _exports.timeLte = timeLte;
  const timeGt = {
    id: 'timeGt',
    label: 'After',
    arity: 1,
    isValue: _encoding.isEncodedTime,
    parseValue: _encoding.parseTime,
    formatTargetValue: _formatFunction.formatTargetTimeValue,
    fn: _filterFunctions.dateAndTimeGtFn,
    rql: _rqlGenerators.timeGtRQL
  };
  _exports.timeGt = timeGt;
  const timeGte = {
    id: 'timeGte',
    label: 'At or After',
    arity: 1,
    isValue: _encoding.isEncodedTime,
    parseValue: _encoding.parseTime,
    formatTargetValue: _formatFunction.formatTargetTimeValue,
    fn: _filterFunctions.dateAndTimeGteFn,
    rql: _rqlGenerators.timeGteRQL
  };
  _exports.timeGte = timeGte;
  const timeStrEq = {
    id: 'timeStrEq',
    label: '=',
    arity: 1,
    isValue: _encoding.isEncodedTime,
    parseValue: _encoding.parseTime,
    formatTargetValue: _formatFunction.formatTargetTimeValue,
    fn: _filterFunctions.dateAndTimeStrEqFn,
    rql: _rqlGenerators.timeEqRQL
  };
  /*
  time filter Types end
  */

  /*
  date and time filter Types start
  */

  _exports.timeStrEq = timeStrEq;
  const dateAndTimeLte = {
    id: 'dateAndTimeLte',
    label: 'At or Before',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    fn: _filterFunctions.dateAndTimeLteFn,
    rql: _rqlGenerators.dateLteRQL
  };
  _exports.dateAndTimeLte = dateAndTimeLte;
  const dateAndTimeGt = {
    id: 'dateAndTimeGt',
    label: 'After',
    arity: 1,
    isValue: _encoding.isEncodedDate,
    parseValue: _encoding.parseDate,
    fn: _filterFunctions.dateAndTimeGtFn,
    rql: _rqlGenerators.dateGtRQL
  };
  /*
  date and time filter Types end
  */

  _exports.dateAndTimeGt = dateAndTimeGt;
  const filterTypes = [nullFilter, emptyFilter, uuidEq, uuidIn, stringEq, stringNotEq, enumEq, enumNotEq, enumContains, dateContains, dateContainsGte, dateContainsLte, stringContains, booleanEq, booleanNe, numberEq, numberNotEq, numberLt, numberLte, numberGt, numberGte, numberBetween, integerEq, dateEq, dateNe, dateStrEq, dateStrNe, dateLt, dateLte, dateGt, dateGte, dateAndTimeLte, dateAndTimeGt, dateBetween, timeNe, timeEq, timeLt, timeLte, timeGt, timeGte, timeStrEq, dateStrictlyBetween, numberStrictlyBetween];
  _exports.filterTypes = filterTypes;
  const filterTypesHash = filterTypes.reduce((acc, filter) => {
    acc[filter.id] = filter;
    return acc;
  }, {});
  _exports.filterTypesHash = filterTypesHash;
});