define("adept-iq/pods/change-password/route", ["exports", "adept-iq/config/api-urls"], function (_exports, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    errors: null,
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('displayError', false);
    },

    setupController(controller
    /*, model*/
    ) {
      controller.set('data', {});
      controller.set('displayError', false);
    },

    actions: {
      passwordChange(password, newPassword, confirmPassword, errorArr) {
        if (errorArr.length === 0) {
          const ajax = this.get('ajax');
          const session = this.get('session');
          const {
            host
          } = _apiUrls.API.ssoService;
          const username = localStorage.getItem('currentUser');
          const userId = localStorage.getItem('currentUserId');
          const url = host + `/basicUser/${userId}`;
          const data = {
            username: username,
            password: newPassword,
            passwordUpdatedAt: new Date().toISOString()
          };
          return ajax.put(url, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: data
          }).then(() => {
            this.transitionTo('change-password-confirmation');
          }).catch(e => {
            if (e && e.status === 400) {
              this.set('displayError', true);
            }
          });
        } // this.set('controller.displayError', true);


        return this.set('controller.data.errors', errorArr);
      }

    }
  });

  _exports.default = _default;
});