define("adept-iq/pods/components/side-drawer/reconcile-filter-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    tagName: '',
    title: Ember.computed.readOnly('workspace.topOptions.displayName'),
    allVisbility: true,
    columns: Ember.computed('workspace.topOptions.columns', function () {
      return Ember.get(this, 'workspace.topOptions.columns');
    }),
    widget: Ember.computed.readOnly('workspace.topOptions.widget'),
    dataColumns: Ember.computed('columns.[]', function () {
      const columns = this.get('columns');
      return columns.map(column => {
        column.set('filterTypeId', null);
        return column;
      });
    }),
    isClearAllDisabled: Ember.computed('dataColumns.@each.{filterTypeId,filterValues}', function () {
      const dataColumns = this.get('dataColumns');
      const length = dataColumns.length;
      return dataColumns.filter(column => Ember.isEmpty(column.filterTypeId) && Ember.isEmpty(column.filterValues)).length === length;
    }),

    reOrderFilterSettings(columns) {
      this.get('widget').mergeState({
        columns
      });
      const tableColumns = this.get('columns') || []; // checkbox and spacer are always in the same location

      tableColumns[0].sortOrder = 0;
      tableColumns[tableColumns.length - 1].sortOrder = tableColumns.length - 1;
      tableColumns.forEach(column => {
        if (columns[column.id]) {
          column.sortOrder = columns[column.id].index + 1;
        }
      });
      tableColumns.sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });
      this.notifyPropertyChange('columns');
    },

    onFilterValueChange(column, index, event) {
      const id = column.get('id');
      const columns = Ember.get(this, 'columns');
      let filterValues = column.get('filterValues') || [];
      filterValues = filterValues.slice();
      filterValues[index] = event.target.value;
      columns[id] = {
        filterValues
      };
      this.get('widget').mergeState({
        columns
      });
    },

    actions: {
      onCloseClick() {
        const workspace = this.get('workspace'); // save any filter changes i.e columns reorders, visibility, and filter changes

        workspace.saveTempDashboardState();
        this.onCloseClick();
      },

      onReorderItems(tableColumns) {
        const columns = tableColumns.reduce((obj, column, index) => {
          const id = column.id;
          obj[id] = {
            index
          };
          return obj;
        }, {});
        this.reOrderFilterSettings(columns);
      },

      onClickVisibilityCheckbox(column, event) {
        const id = column.get('id');
        const columns = {};
        columns[id] = {
          hidden: !event.target.checked
        };

        if (columns[id].hidden) {
          columns[id].filterTypeId = 'null'; // this is actually the id of the null filter

          columns[id].filterValues = null;
        }

        this.get('widget').mergeState({
          columns
        });
      },

      onToggleAllVisibility(tableColumns) {
        const allVisbility = !this.get('allVisbility');
        this.set('allVisbility', allVisbility);
        const columns = tableColumns.reduce((obj, column) => {
          const id = column.id;
          obj[id] = {
            hidden: allVisbility
          };

          if (allVisbility) {
            obj[id].filterTypeId = 'null'; // this is actually the id of the null filter

            obj[id].filterValues = null;
          }

          return obj;
        }, {});
        Ember.run(() => this.get('widget').mergeState({
          columns
        }));
      },

      onSelectFilterType(column, filterType) {
        const id = column.get('id');
        const columns = Ember.get(this, 'columns') || [];
        columns[id] = {
          filterTypeId: filterType.id,
          filterType: filterType
        };
        this.get('widget').mergeState({
          columns
        });
      },

      onSearchChange(column, index, event) {
        Ember.run.debounce(this, 'onFilterValueChange', column, index, event, 300);
      },

      clearFilters() {
        const columnList = this.get('columns') || [];
        let columns = null;
        if (columnList.length !== 0) columns = columnList.map(column => {
          column.set('filterTypeId', null);
          column.set('filterValues', null);
          column.set('filterType', {
            arity: 1
          });
          return column;
        });
        Ember.set(this, 'columns', columns);
        this.get('widget').mergeState({
          columns
        });
      }

    }
  });

  _exports.default = _default;
});