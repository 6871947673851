define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/violation/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/violation/validation", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/violation/vehicle-search-config", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _moment, _unwrapProxy, _validation, _vehicleSearchConfig, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['driver-violation-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tableRef: null,
    disableForm: true,
    // placeholders
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    NOTES_PLACEHOLDER: _placeholders.NOTES_PLACEHOLDER,
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    // passed into view
    editAPI: null,
    selectedDriver: Ember.computed.readOnly('editAPI.selectedDriver'),
    selectedDriverLicense: Ember.computed.readOnly('selectedDriver.license'),
    selectedViolation: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),

    async init() {
      const driverLicense = this.get('selectedDriver.license');
      this.set('queryParams', {
        filter: `eq(driverLicenseId,${driverLicense.get('id')})`
      });
      this.get('refreshTask').perform();

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onSaveDriverRecord = this.get('onSaveDriverViolationRecord');
      const onDeleteDriverViolationRecord = this.get('onDeleteDriverViolationRecord');
      const onValidateAction = this.get('validateDriverViolation'); // normal functions

      const onUndoDriverRecord = this.get('onUndoDriverViolationRecord').bind(this);
      const onNewDriverViolationRecord = this.get('onNewDriverViolationRecord').bind(this);
      const onEditDriverViolationRecord = this.get('onEditDriverViolationRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverRecord,
          newAction: onNewDriverViolationRecord,
          deleteAction: onDeleteDriverViolationRecord,
          editAction: onEditDriverViolationRecord,
          undoAction: onUndoDriverRecord,
          onValidateAction
        });
        editAPI.buttonSettings();
      });
    },

    driverViolationChangeSet: Ember.computed('selectedViolation', function () {
      const Violation = (0, _unwrapProxy.unwrapProxy)(this.get('selectedViolation'));
      return this.setDefaultProperties(Violation);
    }),

    setDefaultProperties(record = null
    /* disableForms = true */
    ) {
      const store = this.get('store');
      const driverLicense = this.get('selectedDriverLicense');
      let pendingDriverViolation = this.get('pendingDriverViolation');

      if (record) {
        pendingDriverViolation = record;
      } else if (!pendingDriverViolation) {
        pendingDriverViolation = store.createRecord('driver-violation', {
          driverLicense
        });
        this.set('pendingDriverViolation', pendingDriverViolation);
      }

      if (pendingDriverViolation.get('isNew')) {
        pendingDriverViolation.setProperties({
          incidentDate: null,
          convictionDate: null,
          licenseMonitorDate: null,
          description: null,
          points: null,
          vehicle: null
        });
      }

      this.set('showValidations', false);
      return new _emberChangeset.default(pendingDriverViolation, (0, _emberChangesetValidations.default)(_validation.VALIDATION), _validation.VALIDATION);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedViolation = this.get('selectedViolation');

      if (selectedViolation) {
        if (!selectedViolation.get('isNew') && !selectedViolation.get('isDeleted')) {
          yield selectedViolation.reload();
        }
      }

      const tableRef = this.get('tableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    onSaveDriverViolationRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const selectedDriver = this.get('selectedDriver');
      const pendingDriverViolation = this.get('driverViolationChangeSet');
      const editAPI = this.get('editAPI'); // validation

      const isValidDriverViolation = yield this.validateDriverViolation.perform();

      if (isValidDriverViolation) {
        pendingDriverViolation.set('driver', selectedDriver);

        try {
          yield pendingDriverViolation.save();
          notifications.success('Successfully Saved Driver Changes');
          this.set('pendingDriverViolation', null);
          editAPI.buttonSettings();
          this.set('disableForm', true);
        } catch (e) {
          notifications.warning('Failed to Save Driver Changes');
          console.warn(e); // eslint-disable-line
        }

        this.get('refreshTask').perform();
      }
    }),

    onUndoDriverViolationRecord() {
      const driverViolationChangeSet = this.get('driverViolationChangeSet');

      if (driverViolationChangeSet) {
        driverViolationChangeSet.rollback();
      }
    },

    onNewDriverViolationRecord() {
      const selectedRow = this.get('selectedRow');
      if (selectedRow) selectedRow.set('selected', false);
      this.set('disableForm', false);
      this.get('editAPI').buttonSettings(true, true, true, false, false); //False to enable the button, TRUE to disable
    },

    onEditDriverViolationRecord() {
      const selectedRow = this.get('selectedRow');
      if (Ember.isEmpty(selectedRow)) return;
      this.set('disableForm', false);
      this.get('editAPI').buttonSettings(true, true, true, false, false);
    },

    onDeleteDriverViolationRecord: (0, _emberConcurrency.task)(function* () {
      const selectedViolation = this.get('selectedViolation');
      const notifications = this.get('notifications');
      const tooltip = this.get('tooltip');
      const refreshTask = this.get('refreshTask');
      if (!selectedViolation) return;
      yield tooltip.pushConfirmation({
        title: 'Manage Driver',
        tip: 'Are you sure you want to delete this record?',
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',

        async primaryAction() {
          try {
            await selectedViolation.destroyRecord();
            notifications.success('Record successfully deleted');
            refreshTask.perform();
            this.set('disableForm', true);
          } catch (e) {
            notifications.warning('Record failed to delete');
          }

          return tooltip.reset();
        }

      });
    }),
    validateDriverViolation: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverViolationChangeSet = this.get('driverViolationChangeSet');
      yield driverViolationChangeSet.validate();
      const {
        incidentDate,
        licenseMonitorDate,
        convictionDate,
        convictionNotificationDate
      } = driverViolationChangeSet.getProperties('incidentDate', 'licenseMonitorDate', 'convictionDate', 'convictionNotificationDate');

      if (licenseMonitorDate && incidentDate) {
        if ((0, _moment.default)(licenseMonitorDate).isSameOrBefore(incidentDate)) {
          driverViolationChangeSet.addError('licenseMonitorDate', {
            value: licenseMonitorDate,
            validation: 'License Monitor Date must be on or after the Incident Date.'
          });
        }
      }

      if (convictionDate && incidentDate) {
        if ((0, _moment.default)(convictionDate).isSameOrBefore(incidentDate)) {
          driverViolationChangeSet.addError('convictionDate', {
            value: convictionDate,
            validation: 'The Conviction Date must be on or after the Incident Date.'
          });
        }
      }

      if (convictionNotificationDate && convictionDate) {
        if ((0, _moment.default)(convictionNotificationDate).isSameOrBefore(convictionDate)) {
          driverViolationChangeSet.addError('convictionNotificationDate', {
            value: convictionNotificationDate,
            validation: 'The Conviction Notification Date must be on or after the Conviction Date. '
          });
        }
      }

      const driverViolationValid = driverViolationChangeSet.errors;
      const errors = driverViolationValid;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      return true;
    }),

    async onVehicleChange(vehicle, path = 'vehicle') {
      const driverViolationChangeSet = this.get('driverViolationChangeSet');
      driverViolationChangeSet.set(path, vehicle);
    },

    actions: {
      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      },

      openVehicleModal() {
        const onVehicleChange = this.get('onVehicleChange').bind(this);
        const searchVehicleObj = Ember.Object.create({
          title: 'Vehicles',
          hasOverlay: true,
          config: _vehicleSearchConfig.default,
          primaryActionText: 'Select',
          className: 'vehicle-search-modal',
          primaryAction: record => {
            onVehicleChange(record);
          }
        });
        this.set('modalAPI', searchVehicleObj);
      }

    }
  });

  _exports.default = _default;
});