define("adept-iq/pods/components/iq-widgets/message-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/message-widget/config", "adept-iq/pods/components/iq-widgets/message-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _avlmConfig, _environment, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['message-widget', 'data-test-message-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    config: _config.default,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);
    },

    didInsertElement() {
      this.loadCurrentState();

      this._super(...arguments);

      if (this.user.isRoadSupEnable()) {
        this.set('tableActions', [{
          id: 'sendMessage',
          permId: [_mappedPermIds.default.cannedMessageToDriver, _mappedPermIds.default.freeTypeMessageToDriver, _mappedPermIds.default.messageToDispatch],
          name: 'Send Message',
          action: () => {
            this.sendMessage();
          }
        }, {
          id: 'showAll',
          permId: _mappedPermIds.default.showAllMessage,
          name: 'Show All',
          action: () => {
            this.set('hiddenRowIds', []);
          }
        }]);
      } else {
        this.set('tableActions', [{
          id: 'showAll',
          permId: _mappedPermIds.default.showAllMessage,
          name: 'Show All',
          action: () => {
            this.set('hiddenRowIds', []);
          }
        }]);
      }

      this.set('bulkActions', [{
        id: 'readAll',
        permId: _mappedPermIds.default.readMessages,
        name: 'Read All',
        action: () => {
          const records = this.get('widgetService').getSelectedWidgetRecordsByModel(this.get('config.modelName'));
          const models = records.map(record => this.get('store').peekRecord('avlm-canned-message', record.id));
          const promises = models.map(model => {
            model.set('readTime', new Date());
            return model.save();
          });
          return Promise.all(promises);
        }
      }]);
      this.set('readAction', {
        id: 'read',
        permId: _mappedPermIds.default.readMessages,
        name: 'Read',
        action: model => {
          model.set('readTime', new Date());
          return model.save();
        }
      });
      this.set('hideAction', {
        id: 'hide',
        permId: _mappedPermIds.default.hideMessages,
        name: 'Hide',
        action: model => {
          const hiddenRowIds = this.get('hiddenRowIds').concat(model.get('id'));
          this.set('hiddenRowIds', hiddenRowIds);
          model.set('readTime', new Date());
          let currentState = this.get('workspace.currentState'); // save current manual settings

          currentState = {
            hiddenRowIds
          };
          this.set('workspace.currentState', currentState);
          return model.save();
        }
      });
    },

    loadCurrentState() {
      const currentState = this.get('workspace.currentState');

      if (currentState) {
        this.set('hiddenRowIds', currentState.hiddenRowIds);
      }
    },

    async sendMessage() {
      if (localStorage.getItem('dashboard-info')) {
        const dashboardInfo = localStorage.getItem('dashboard-info');
        const parsedDashboardInfo = JSON.parse(dashboardInfo);

        if (parsedDashboardInfo && parsedDashboardInfo.accessData) {
          const accessDataObj = parsedDashboardInfo.accessData;
          this.loggedRoadSupervisorVehicle = accessDataObj.vehicleId;
          this.loggedRoadSupervisorDriver = accessDataObj.driverId;
        }
      }

      const draftMessages = [this.get('store').createRecord('avlm-canned-message', {
        vehicleId: this.loggedRoadSupervisorVehicle,
        driverId: this.loggedRoadSupervisorDriver
      })];
      this.get('workspace').pushState('addNewMessage', {
        draftMessages
      });
    },

    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,status}', 'selectedWidgetRecord', function () {
      const hideAction = this.get('hideAction');
      const readAction = this.get('readAction');
      const row = this.getSelectedCoreModel();

      if (!row) {
        return [];
      } // @TODO: extract statuses to constants


      switch (row.get('status')) {
        case 'Received':
          if (row.get('readString') === 'Yes') {
            return [hideAction];
          }

          return [readAction, hideAction];

        case 'Sending':
        case 'Sent':
        case 'Deleted':
          return [hideAction];

        default:
          return [];
      }
    }),

    /**
     * Push all messages to Store
     */
    postHandleReceiveDataEventHandler() {
      const widgetService = this.get('widgetService');
      const records = this.get('widgetRecords');
      const widgetName = this.get('config.widgetName');
      widgetService.updateAllCoreEntityStoreAction(widgetName, records);
    },

    applyTransformationData(widgetRecords) {
      widgetRecords.forEach(widgetRecord => {
        if (!widgetRecord._transformed) {
          this.applyDriverIdTransformation(widgetRecord);
          widgetRecord._transformed = true;
        }
      });
      return widgetRecords;
    },

    getLocalSortColumns() {
      return ['status'];
    },

    actions: {}
  });

  _exports.default = _default;
});