define("adept-iq/pods/components/iq-widgets/routes-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/routes-widget/config", "adept-iq/pods/components/iq-widgets/routes-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/routes-widget/scheduleConfig", "adept-iq/config/environment", "adept-iq/utils/find-row-record", "adept-iq/config/api-urls", "adept-iq/utils/unwrapProxy", "adept-iq/config/mapped-permIds", "moment", "adept-iq/utils/rql-generators", "lodash", "adept-iq/utils/regex-functions", "adept-iq/models/route-template", "adept-iq/models/dispatch-route", "adept-iq/models/stop-point", "adept-iq/models/dashboard"], function (_exports, _component, _config, _avlmConfig, _scheduleConfig, _environment, _findRowRecord, _apiUrls, _unwrapProxy, _mappedPermIds, _moment, _rqlGenerators, _lodash, _regexFunctions, _routeTemplate, _dispatchRoute, _stopPoint, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-console */
  // display statuses
  const specialRouteHost = _apiUrls.API.dispatchService.host;
  const INCLUDES = ['clusters', 'vehicleRoutes', 'vehicleRoutes.driverShifts', 'vehicleRoutes.vehicle', 'vehicleRoutes.driverShifts.driver', 'vehicleRoutes.vehicle.vehicleType', 'driverBreaks', 'driverBreaks.driverBreakEvents', 'vehicleRoutes.vehicle.vehicleEvents'];

  var _default = _component.default.extend({
    classNames: ['routes-widget', 'data-test-routes-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    dragCoordinator: Ember.inject.service(),
    routeexecevent: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspace: Ember.inject.service(),
    booking: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    editComponent: 'iq-widgets/routes-form-widget',
    newComponent: 'iq-widgets/routes-widget/new',
    routesEndComponent: 'iq-widgets/routes-end-form-widget',
    routesStartComponent: 'iq-widgets/routes-start-form-widget',
    breakdownReplacementComponent: 'iq-widgets/breakdown-replacement-form-widget',
    driverBreakEditComponent: 'iq-widgets/driver-breaks-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            this.set('config', _scheduleConfig.default);
            break;

          case _dashboard.DISPATCH_ROLENAME:
          default:
            this.set('config', _config.default);
            break;
        }
      }

      this._super(...arguments);
    },

    saveRouteFunction(records) {
      const role = this.get('workspace.dashboardModel.role');

      if (role === _dashboard.DISPATCH_ROLENAME) {
        return this.saveRouteForDispatch(records);
      }

      return this.saveRouteForSchedule(records);
    },

    saveRouteForDispatch(records) {
      return Ember.RSVP.all(records.map(async dispatchRoute => {
        try {
          const route = (0, _unwrapProxy.unwrapProxy)(dispatchRoute.get('route'));
          const rdv = dispatchRoute.get('latestRouteVehicleDriver');
          await rdv.save();
          await route.save();
          this.get('notifications').success(`ROUTE ${dispatchRoute.get('route.name')} WAS EDITED SUCCESSFULLY`);
        } catch (error) {
          this.get('notifications').warning(`EDIT TO ROUTE ${dispatchRoute.get('route.name')} FAILED`);
        }
      }));
    },

    saveRouteForSchedule(records) {
      return Ember.RSVP.all(records.map(async route => {
        try {
          await route.save();
          this.get('notifications').success(`ROUTE ${route.get('name')} WAS EDITED SUCCESSFULLY`);
        } catch (error) {
          this.get('notifications').warning(`EDIT TO ROUTE ${route.get('name')} FAILED`);
        }
      }));
    },

    resetRouteFunction(records) {
      return Ember.RSVP.all(records.map(async record => {
        const route = (0, _unwrapProxy.unwrapProxy)(record.get('route'));
        const rdv = record.get('latestRouteVehicleDriver');

        if (rdv) {
          rdv.rollbackAttributes();
        }

        route.rollbackAttributes();
      }));
    },

    saveRouteStartRecordFunction(records) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return Ember.RSVP.all(records.map(record => {
        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a vehicle.'));
        }

        record.set('actualStartTimestamp', record.get('timestamp'));
        return routeExecEventAdapter.createStartRouteEvent(record).then(() =>
        /*response*/
        {
          record.set('isForceDirty', false);
          this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
          this.get('notifications').success('Route started successfully');
          return Ember.RSVP.Promise.resolve();
        }).catch(e => {
          this.get('notifications').warning('Start route failed');
          return Ember.RSVP.Promise.reject(e);
        });
      }));
    },

    confirmSaveRouteEndFunction(records) {
      const tooltip = this.get('tooltip');
      const title = 'End Route';
      let tip = 'The route end time selected is outside the configured limit of the planned route end time. Do you want to continue?';
      return Ember.RSVP.all(records.map(record => {
        const extensionExceeded = record.get('extensionExceeded');

        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a vehicle.'));
        }

        record.set('actualEndTimestamp', record.get('timestamp'));

        if (!extensionExceeded) {
          tip = 'Would you like to commit your changes?';
        }

        tooltip.pushConfirmation({
          tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.saveRouteEndRecordFunction(record);
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            record.set('actualEndTimestamp');
            return tooltip.reset();
          }
        });
      }));
    },

    confirmSaveUndoRouteStartFunction(route) {
      const tooltip = this.get('tooltip');
      const title = 'Undo Route Start';
      const tip = 'Would you like to undo route start?';
      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          await this.saveUndoRouteStartRecordFunction(route);
          return tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    saveUndoRouteStartRecordFunction(record) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return routeExecEventAdapter.createUndoStartRouteEvent(record).then(() =>
      /*response*/
      {
        record.set('isForceDirty', false);
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
        this.get('notifications').success('Started Route was undone successfully');
        return Ember.RSVP.Promise.resolve();
      }).catch(e => {
        this.get('notifications').warning('Failed to undo started route');
        return Ember.RSVP.Promise.reject(e);
      });
    },

    saveRouteEndRecordFunction(record) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return routeExecEventAdapter.createEndRouteEvent(record).then(() =>
      /*response*/
      {
        record.set('isForceDirty', false);
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
        this.get('notifications').success('Route completed successfully');
        this.get('editModal').close();
        return Ember.RSVP.Promise.resolve();
      }).catch(e => {
        this.get('notifications').warning('Failed to complete route');
        this.get('editModal').close();
        return Ember.RSVP.Promise.reject(e);
      });
    },

    // 1. Create a new place in the Address service that represents the breakdown location, address and place
    // 2. Mark the vehicle as broken down in the Transit Vehicle service.
    // 3. Create a replacement route in Transit Dispatch service
    // 4. Move trips from the broken down route to the replacement route. Optional
    // 5. undo stops that have arrive status
    saveBreakdownReplacementFunction(dispatchRoutes) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const dispatchRouteAdapter = store.adapterFor('dispatch-Route');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      this.set('editModal.errors', []);
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!Ember.isEmpty(dispatchRoutes)) {
          return dispatchRoutes.forEach(async dispatchRoute => {
            const vehicleBreakdown = (0, _unwrapProxy.unwrapProxy)(dispatchRoute.get('assignedVehicle.latestVehicleBreakdown'));
            const breakdownPlace = (0, _unwrapProxy.unwrapProxy)(vehicleBreakdown.get('place'));
            let activePicksAndDrops = [];
            let address = (0, _unwrapProxy.unwrapProxy)(breakdownPlace.get('address'));

            try {
              // save location for start garage and end garage
              const locationResponse = await locationAdapter.postLocation(breakdownPlace.get('location.content')); // temporary assignment of zone type until we figure out how to deal with zone types

              address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
              address = await addressAdapter.postAddress(breakdownPlace.get('address'));
              await addressAdapter.postAddressLocation(locationResponse, address);
              const newDispatchRoute = await dispatchRouteAdapter.postSpecialRoute(dispatchRoute, breakdownPlace);

              if (dispatchRoute.get('replacementMoveAllTrips')) {
                const stops = dispatchRoute.get('orderedStops'); // get all pick / drop stop points

                const pickOrDropStops = stops.filter(stop => stop.get('isPickOrDrop')) || []; // get all trips

                const trips = pickOrDropStops.filter(stop => stop.get('isPick')).map(stop => (0, _unwrapProxy.unwrapProxy)(stop.get('trip'))); // get all trips that are still scheduled and active

                const unperformedTrips = trips.filter(trip => !trip.get('isPickPerformed') && !trip.get('isNoShow')) || []; // get all active stops

                activePicksAndDrops = unperformedTrips.reduce((acu, trip) => {
                  const pickStopPoint = trip.get('pickStopPoint');
                  const dropStopPoint = trip.get('dropStopPoint');
                  acu.push(...[pickStopPoint, dropStopPoint]);
                  return acu;
                }, []);
                await dispatchRouteAdapter.transferAllTripsToRouteFunction(dispatchRoute, newDispatchRoute, activePicksAndDrops);
              } // give it a second to refresh the widgets because socket updates takes awhile to update
              // multiple records


              Ember.run.later(() => {
                // active picks that have Arrived status should be reversed
                activePicksAndDrops.forEach(stopPoint => {
                  if (stopPoint.get('stopType') === 'pick' && stopPoint.get('computedStatus') === _stopPoint.ARRIVE_STOP_STATUS) {
                    routeExecEventAdapter.createAcceptEvent(stopPoint);
                  }
                });
                this.get('activeContext.topActiveContext').refreshTableContent.perform('vehicle');
              }, 1000);
              this.get('notifications').success(`YOU REPLACED ROUTE ${dispatchRoute.get('route.name')} WITH ROUTE ${dispatchRoute.get('replacementRouteId')} SUCCESSFULLY`);
              return resolve(null);
            } catch (error) {
              this.get('notifications').warning(`ROUTE BREAKDOWN REPLACEMENT FOR ROUTE ${dispatchRoute.get('route.name')} FAILED`);
              reject(error);
            }
          });
        }
      });
    },

    saveDriverBreakFunction(records) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const routeBreakAdapter = store.adapterFor('route-break');
      const dispatchRouteAdapter = store.adapterFor('dispatch-route');
      this.set('editModal.errors', []);
      return records.reduce(async (acu, record) => {
        const requestTime = record.get('requestTime');
        const projectedEndDate = record.get('projectedEndDate');
        const place = (0, _unwrapProxy.unwrapProxy)(record.get('place'));
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        const locationExist = !Ember.isEmpty(location.get('lat'));
        let placeResponse;
        place.set('type', 'driverBreak');
        place.set('schedule', (0, _unwrapProxy.unwrapProxy)(record.get('schedule')));
        record.set('estimatedStart', requestTime);
        record.set('estimatedEnd', projectedEndDate); // temporary assignment of zone type until we figure out how to deal with
        // zone types

        address.set('zoneName', store.peekAll('zone-type').get('firstObject')); // creating new driver break

        try {
          if (locationExist) {
            record.set('place', place);
            const locationResponse = await locationAdapter.postLocation(location);
            const addressResponse = await addressAdapter.postAddress(address);
            await addressAdapter.postAddressLocation(locationResponse, addressResponse);
            placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
          }

          const placeId = Ember.isNone(placeResponse) ? '' : placeResponse.data.id;
          const routeBreak = await routeBreakAdapter.postRouteBreak(record, placeId);
          return acu.then(() => dispatchRouteAdapter.createDriverBreak((0, _unwrapProxy.unwrapProxy)(record.get('route.dispatchRoute')), routeBreak.data.attributes.plannedRouteOrdinal, routeBreak.data.id)).then(() => {
            this.get('notifications').success(`Successfully added driver break to route ${record.get('route.name')}`);
            this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route-break,stop-point']);
            return Ember.RSVP.Promise.resolve();
          });
        } catch (e) {
          this.get('notifications').warning(`Failed to add driver break to route ${record.get('route.name')}`);
          return Ember.RSVP.Promise.reject();
        }
      }, Ember.RSVP.Promise.resolve());
    },

    didInsertElement() {
      this._super(...arguments);

      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        this._initDispatchSingleActions();
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            this._initScheduleSingleActions();

            break;

          case _dashboard.DISPATCH_ROLENAME:
          default:
            this._initDispatchSingleActions();

            break;
        }
      }
    },

    dragRowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,status}', 'selectedWidgetRecord', function () {
      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        return this._dynamicAVLMActions();
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            return this._dynamicScheduleActions();

          case _dashboard.DISPATCH_ROLENAME:
          default:
            return this._dynamicDispatchActions();
        }
      }
    }),

    dragStart(event) {
      const row = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('dragRowGroup'));
      if (Ember.isBlank(row)) return;
      const dragId = Ember.guidFor(row);
      this.get('dragCoordinator').setSourceRow(dragId, row, 'routes');
      event.dataTransfer.setData('text', dragId);
    },

    dragEnd() {
      this.get('dragCoordinator').clearSourceRows();
    },

    saveRecordFunction(records) {
      return Ember.RSVP.all(records.map(record => {
        const {
          name,
          selectedProvider,
          selectedDriver,
          selectedVehicle,
          plannedStartTime,
          plannedEndTime
        } = record.getProperties('name', 'selectedProvider', 'selectedDriver', 'selectedVehicle', 'plannedStartTime', 'plannedEndTime');
        const ajax = this.get('ajax');
        const token = this.get('session.data.authenticated.token'); // const scheduleId = record.get('schedule.id');

        const vehicleId = selectedVehicle ? selectedVehicle.get('id') : null;
        const driverId = selectedDriver ? selectedDriver.get('id') : null;
        const providerNameId = selectedProvider ? selectedProvider.get('id') : null;
        const json = {
          data: {
            type: 'specialRoute',
            attributes: {
              name,
              routeType: 'supervisor',
              shiftStartTime: plannedStartTime,
              shiftEndTime: plannedEndTime
            },
            relationships: {
              dispatchSchedule: {
                data: {
                  id: '1',
                  // TODO: fetch this
                  type: 'dispatchSchedule'
                }
              },
              driver: {
                data: {
                  id: driverId,
                  type: 'driver'
                }
              },
              providerName: {
                data: {
                  id: providerNameId,
                  type: 'providerName'
                }
              },
              vehicle: {
                data: {
                  id: vehicleId,
                  type: 'vehicle'
                }
              }
            }
          }
        };
        return ajax.post(`${specialRouteHost}/special-route`, {
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: json
        }).then(() =>
        /* route */
        {// TODO: handle success
        }).catch(() =>
        /* error */
        {// TODO: show errors?
        });
      }));
    },

    async sendMessagesToRoutesAvlmLite(avlmRoutes) {
      const avlmRouteList = avlmRoutes ? avlmRoutes : [];
      const draftMessages = avlmRouteList.map(avlmRoute => {
        const driverId = avlmRoute.get('latestDriver') ? avlmRoute.get('latestDriver.id') : null;
        const vehicleId = avlmRoute.get('latestVehicle') ? avlmRoute.get('latestVehicle.callSign') : null;
        const routeId = avlmRoute.get('latestRouteVehicleDriver.route.id') ? avlmRoute.get('latestRouteVehicleDriver.route.id') : avlmRoute.get('id');

        if (!driverId) {
          this.get('notifications').warning(`Can not send message due to missing driver id on route id = ${routeId}`);
          return null;
        }

        avlmRoute.set('route', {
          id: routeId
        });
        avlmRoute.set('messageNr', '0'); // conflict due to merge r4 and r4.2.12
        //     this.get('workspace').pushState('routeActivityLog', {
        //       displayName: `Route ${routeName} Activity Log`,
        //       route: ENV.APP.avlmLite ? unwrapProxy(route) : unwrapProxy(route.get('route'))
        //     });
        //   },
        //   disableOnLocked: false,
        //   disableOnLockedRoute: false
        // });

        avlmRoute.set('avlmDriver', {
          id: driverId,
          endPointType: 'recipient',
          fullName: avlmRoute.get('latestDriver') ? avlmRoute.get('latestDriver.fullName') : null,
          badgeNumber: avlmRoute.get('latestDriver') ? avlmRoute.get('latestDriver.badgeNumber') : null
        });
        avlmRoute.set('avlmVehicle.content', {
          id: vehicleId
        });
        return avlmRoute;
      }).filter(route => !Ember.isNone(route));

      if (!Ember.isEmpty(draftMessages)) {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      }
    },

    sendMessagesToRoutes(dsRoutes, messageType) {
      const dsRouteList = dsRoutes ? dsRoutes : [];
      const draftMessages = dsRouteList.map(dsRoute => {
        const trueVehicle = (0, _unwrapProxy.unwrapProxy)(dsRoute.get('assignedVehicle'));
        const assignedDriver = trueVehicle.get('latestDriver');
        let badgeNumber = null;

        if (Ember.isNone(trueVehicle)) {
          return [];
        }

        const driverId = assignedDriver ? assignedDriver.get('id') : null;

        if (assignedDriver) {
          if (assignedDriver.get('badgeNumber')) {
            badgeNumber = assignedDriver.get('badgeNumber');
          } else {
            badgeNumber = assignedDriver.get('driver.badgeNumber');
          }
        }

        if (messageType === 'singleMessage' && !badgeNumber) {
          this.get('notifications').warning(`Can not send message due to missing driver id on vehicle id = ${trueVehicle.get('id')}`);
        }

        if (messageType === 'bulkMessage' && !badgeNumber) {
          this.get('notifications').warning('MESSAGE COULD NOT BE SENT TO SOME VEHICLES WHICH DO NOT HAVE DRIVER ASSOCIATED.');
        }

        trueVehicle.set('driver', {
          id: driverId,
          endPointType: 'recipient',
          fullName: trueVehicle.get('latestDriver.fullName'),
          badgeNumber: badgeNumber
        });
        const vehicleId = trueVehicle.get('id');
        const vehicleName = trueVehicle.get('name');
        trueVehicle.set('vehicle', {
          id: vehicleId,
          name: vehicleName
        });
        const routeId = trueVehicle.get('latestRouteVehicleDriver.route.id');
        const routeName = trueVehicle.get('latestRouteVehicleDriver.dispatchRoute.route.name');
        trueVehicle.set('route', {
          id: routeId,
          name: routeName
        });
        trueVehicle.set('messageNr', '0');

        if (_environment.default.APP.avlmLite) {
          trueVehicle.set('avlmDriver', {
            id: driverId,
            endPointType: 'recipient'
          });
          trueVehicle.set('avlmVehicle', {
            id: vehicleId
          });
        }

        return trueVehicle;
      });

      if (messageType === 'singleMessage' && draftMessages.firstObject.driver.badgeNumber) {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      } else if (messageType === 'bulkMessage') {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      }
    },

    deleteRoute(route) {
      const isCompleted = route.get('isCompleted');
      const isEmptyRoute = route.get('isEmptyRoute');

      if (isCompleted || !isEmptyRoute) {
        this.get('notifications').warning('Cannot delete trips that is not empty');
      } else {
        // delete endpoint does not exist in dispatch yet.
        route.deleteRecord();
        route.save();
      }
    },

    doScheduleLockRoute(route) {
      const session = this.get('session');
      const notifications = this.get('notifications');
      const timeoutParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeLockTimeout');
      const routeLockTimeout = timeoutParam ? parseInt(timeoutParam.get('value'), 10) : 1800;
      const json = {
        data: {
          type: 'lock',
          attributes: {
            entityType: 'route',
            entityId: route.get('id'),
            timeToLive: routeLockTimeout,
            persist: true
          }
        }
      };
      this.get('ajax').post(_apiUrls.API.schedulingService.host + '/lock', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      }).catch(function (error) {
        notifications.warning(`Error creating route lock, ${error.message}`);
      });
    },

    lockRouteConfirmation(dispatchRoute) {
      const lockAdapter = this.get('store').adapterFor('lock');
      const tooltip = this.get('tooltip');
      const tip = `Are you sure you want to lock Route ${dispatchRoute.get('route.name')}?`;
      const title = 'Lock Route';

      if (dispatchRoute.get('isLocked')) {
        return;
      }

      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          tooltip.reset();
          return lockAdapter.doLockRoute(dispatchRoute, true);
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    scheduleRouteLockConfirmation(route) {
      const tooltip = this.get('tooltip');
      const tip = `Are you sure you want to lock Route ${route.get('name')}?`;
      const title = 'Lock Route';

      if (route.get('isLocked')) {
        return;
      }

      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          tooltip.reset();
          return this.doScheduleLockRoute(route);
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    unLockRouteConfirmation(dispatchRoute) {
      const lockAdapter = this.get('store').adapterFor('lock');
      const tooltip = this.get('tooltip');
      const tip = `Route Unlocked ${dispatchRoute.get('route.name')}`;
      const title = 'Unlock Route';

      if (!dispatchRoute.get('isLocked')) {
        return;
      }

      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          tooltip.reset();
          return lockAdapter.doUnlockRoute(dispatchRoute);
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    scheduleRouteUnLockConfirmation(route) {
      const tooltip = this.get('tooltip');
      const tip = `Are you sure you want to unlock Route ${route.get('name')}?`;
      const title = 'Unlock Route';

      if (!route.get('isLocked')) {
        return;
      }

      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          tooltip.reset();
          return this.scheduleDoUnlockRoute(route);
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    scheduleDoUnlockRoute(route) {
      const session = this.get('session');
      const notifications = this.get('notifications');
      const userId = session.data.authenticated.userId;
      const scheduleRouteLock = route.get('scheduleRouteLock');
      const user = this.get('store').peekRecord('sso-user', userId);

      if (!scheduleRouteLock.get('isLocked')) {
        return;
      }

      if (userId !== scheduleRouteLock.get('owner.id') && !user.get('isAdmin')) {
        notifications.info('You do not have permission to unlock this route.');
        return;
      }

      if (!Ember.isNone(scheduleRouteLock)) {
        this.get('ajax').delete(_apiUrls.API.schedulingService.host + '/lock/force/route/' + route.get('id'), {
          method: 'DELETE',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        }).catch(function (error) {
          notifications.warning(`Error removing route lock, ${error.message}`);
        });
      }
    },

    _initDispatchSingleActions() {
      this.get('tableActions').unshiftObject({
        id: 'newRoute',
        permId: _mappedPermIds.default.createRoute,
        name: 'New Route',
        action: () => {
          const tripLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeTripLimit');
          const distanceLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeDistanceLimit');
          let tripLimit = 9;
          let distanceLimit = 99; // prepare getting values from config service for tripLimit and distanceLimit
          // Special Route creation does not accept these 2 new variables yet.

          if (!Ember.isNone(tripLimitParam) && !Ember.isNone(tripLimitParam.get('value')) && _regexFunctions.REGEX_NUMBER.test(tripLimitParam.get('value'))) {
            tripLimit = parseInt(tripLimitParam.get('value'), 10);
          }

          if (!Ember.isNone(distanceLimitParam) && !Ember.isNone(distanceLimitParam.get('value')) && _regexFunctions.REGEX_NUMBER.test(distanceLimitParam.get('value'))) {
            distanceLimit = parseInt(distanceLimitParam.get('value'), 10);
          }

          const newRouteModel = Ember.Object.create({
            routeDate: new Date(this.workspace.startDate),
            plannedStartTime: (0, _moment.default)().toDate(),
            plannedEndTime: (0, _moment.default)().endOf('day').toDate(),
            tripLimit: tripLimit,
            distanceLimit: distanceLimit
          });
          let minDate = null;
          minDate = new Date().getTime();
          this.get('workspace').pushState('addNewRoute', {
            displayName: 'Add Route',
            newRouteModel,
            minDate: (0, _moment.default)(minDate).toDate()
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      });
      this.set('assignVehicleDriver', {
        id: 'assignVehicleDriver',
        permId: _mappedPermIds.default.assignVehicleDriver,
        name: 'Assign Vehicle & Driver',
        action: dispatchRoute => {
          const store = this.get('store');
          const name = dispatchRoute.get('route.name');
          const routeVehicleDrivers = (0, _unwrapProxy.unwrapProxy)(dispatchRoute.get('routeVehicleDrivers')); // possibility of a route not having assigned driver and vehicle

          if (!routeVehicleDrivers.length || Ember.isNone(routeVehicleDrivers)) {
            const routeVehicleDriver = store.createRecord('route-vehicle-driver');
            dispatchRoute.get('routeVehicleDrivers').push(routeVehicleDriver);
          }

          this.get('workspace').pushState('assignVehicleDriverToRoute', {
            displayName: `Assign Vehicle & Driver to Route #${name}`,
            dispatchRoute
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      });
      this.set('sendMessage', {
        id: 'sendMessage',
        permId: _mappedPermIds.default.sendRouteMessage,
        name: 'Send Message',
        action: route => {
          if (_environment.default.APP.avlmLite) {
            this.sendMessagesToRoutesAvlmLite([route]);
          } else {
            this.sendMessagesToRoutes([route], 'singleMessage');
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('startRoute', {
        id: 'startRoute',
        permId: _mappedPermIds.default.startRoute,
        name: 'Start Route',
        model: 'vehicle-event',
        action: model => {
          const route = model.get('route');
          const routesStartComponent = this.get('routesStartComponent');
          const stops = model.get('orderedStopsWithBreaks'); //not sure if we ever have only 1 stop, or if its possible to have 0 stops

          const firstStopTime = stops !== null && stops.length > 0 ? (0, _moment.default)(stops[0].get('computedStartTime')) : null;
          const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-Route_parameters/routeMaxExtension');
          const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes

          const computedMinDate = (0, _moment.default)(model.get('plannedStartTime')).subtract(routeMaxExtension, 'minutes');

          const computedMaxDate = _moment.default.min([(0, _moment.default)(model.get('plannedEndTime')), (0, _moment.default)(firstStopTime), (0, _moment.default)()]);

          route.set('computedMinDate', computedMinDate);
          route.set('computedMaxDate', computedMaxDate);
          route.set('odometerInMiles', '');
          route.set('timestamp', new Date());
          this.get('editModal').open(routesStartComponent, [route], this.get('saveRouteStartRecordFunction').bind(this), null, null, {
            componentName: 'startRoute'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('undoStartRoute', {
        id: 'undoStartRoute',
        permId: _mappedPermIds.default.undoRouteStart,
        name: 'Undo Route Start',
        action: model => {
          const route = model.get('route');
          route.set('odometerInMiles', '');
          route.set('timestamp', new Date());
          this.confirmSaveUndoRouteStartFunction(route);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('endRoute', {
        id: 'endRoute',
        permId: _mappedPermIds.default.endRoute,
        name: 'End Route',
        action: model => {
          const route = model.get('route');
          const plannedEndTime = new Date();
          const routesEndComponent = this.get('routesEndComponent');
          route.set('odometerInMiles', '');
          route.set('timestamp', plannedEndTime);
          this.get('editModal').open(routesEndComponent, [route], null, null, this.get('confirmSaveRouteEndFunction').bind(this), {
            componentName: 'endRoute'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('activityLog', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLogRoute,
        name: 'Activity Log',
        action: model => {
          let routeName = model.get('route.name');

          if (_environment.default.APP.avlmLite) {
            routeName = model.get('trackingId');
          }

          this.get('workspace').pushState('routeActivityLog', {
            displayName: `Route ${routeName} Activity Log`,
            route: _environment.default.APP.avlmLite ? (0, _unwrapProxy.unwrapProxy)(model) : (0, _unwrapProxy.unwrapProxy)(model.get('route'))
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      this.set('deleteRoute', {
        id: 'deleteRoute',
        permId: _mappedPermIds.default.deleteRoute,
        name: 'Delete Route',
        model: 'vehicle-event',
        action: model => {
          this.deleteRoute(model);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('addBreakdownReplacement', {
        id: 'addBreakdownReplacement',
        permId: _mappedPermIds.default.createSameDayReplacement,
        name: 'Breakdown Replacement',
        action: async model => {
          const dispatchRoute = model;
          const breakdownReplacementComponent = this.get('breakdownReplacementComponent'); // setup new route information

          dispatchRoute.set('replacementRouteId', '');
          dispatchRoute.set('replacementStartTime', dispatchRoute.get('route.plannedStartTime'));
          dispatchRoute.set('replacementEndTime', dispatchRoute.get('route.plannedEndTime'));
          dispatchRoute.set('replacementRouteType', 'breakdown replacement');
          dispatchRoute.set('replacementDriver', null);
          dispatchRoute.set('replacementVehicle', null);
          dispatchRoute.set('replacementMoveAllTrips', true); // As this option had been removed from UI, set it to true as default.

          dispatchRoute.set('breakdownPlace', dispatchRoute.get('assignedVehicle.latestVehicleBreakdown.place'));
          this.get('editModal').open(breakdownReplacementComponent, [dispatchRoute], this.get('saveBreakdownReplacementFunction').bind(this), null, null, {
            componentName: 'breakdownReplacement'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('rescueRoute', {
        id: 'rescueRoute',
        permId: _mappedPermIds.default.reportRescue,
        name: 'Rescue',
        action: dsRoute => {
          this.get('workspace').pushState('rescueRoute', {
            dsRoute
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      });
      this.set('lockRoute', {
        id: 'lockRoute',
        permId: _mappedPermIds.default.lockUnlockRoute,
        name: 'Lock Route',
        action: model => {
          this.lockRouteConfirmation((0, _unwrapProxy.unwrapProxy)(model));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('unlockRoute', {
        id: 'unlockRoute',
        permIds: [_mappedPermIds.default.lockUnlockRoute, _mappedPermIds.default.unlockOtherUserLocks],
        name: 'Unlock Route',
        action: model => {
          this.unLockRouteConfirmation((0, _unwrapProxy.unwrapProxy)(model));
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      this.set('editRoute', {
        id: 'editRoute',
        permId: _mappedPermIds.default.editRoute,
        name: 'Edit',
        action: model => {
          const editComponent = this.get('editComponent');
          const rdv = model.get('latestRouteVehicleDriver');
          const stops = model.get('orderedStopsWithBreaks'); //not sure if we ever have only 1 stop, or if its possible to have 0 stops

          const firstStopTime = stops.length ? (0, _moment.default)(stops[0].get('computedStartTime')) : null;
          const lastStopTime = stops.length ? (0, _moment.default)(stops[stops.length - 1].get('computedStartTime')) : null; // eslint-disable-next-line no-unneeded-ternary

          const computedReadOnlyCell = model.get('route.actualStartTimestamp') ? true : false;
          const routeMaxTimeLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeMaxTimeLimit');
          const routeMaxTimeLimit = routeMaxTimeLimitParam ? parseInt(routeMaxTimeLimitParam.get('value'), 10) : 720; // minutes

          model.set('computedReadOnlyCell', computedReadOnlyCell);
          model.set('routeMaxTimeLimit', routeMaxTimeLimit);
          model.set('firstStopTime', firstStopTime);
          model.set('lastStopTime', lastStopTime);

          if (rdv) {
            rdv.set('originalPlannedStartTime', rdv.get('startTime'));
            rdv.set('originalPlannedEndTime', rdv.get('endTime'));
            this.get('editModal').open(editComponent, [model], this.get('saveRouteFunction').bind(this), this.get('resetRouteFunction').bind(this));
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('addDriverBreakAction', {
        id: 'addDriverBreak',
        permId: _mappedPermIds.default.reportBreakdown,
        name: 'Add Driver Break',
        action: model => {
          const store = this.get('store');
          const route = (0, _unwrapProxy.unwrapProxy)(model.get('route'));
          const trips = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
          let routeOrdinal = 1; // default routeOrdianl

          if (!Ember.isEmpty(route) || !Ember.isNone(route)) {
            let plannedStartTime = (0, _moment.default)(route.get('plannedStartTime'));
            const currentTime = (0, _moment.default)();

            if (currentTime.isAfter(plannedStartTime)) {
              plannedStartTime = currentTime;
            }

            if (route.get('dispatchRoute.orderedStopsWithBreaks')) {
              const lastStop = route.get('dispatchRoute.orderedStopsWithBreaks').lastObject;

              if (!Ember.isEmpty(lastStop)) {
                routeOrdinal = lastStop.get('routeOrdinal') + 1;
              }
            }

            const driverBreak = store.createRecord('route-break', {
              estimatedStart: plannedStartTime.toDate(),
              requestTime: plannedStartTime.toDate(),
              route: route,
              schedule: route.get('schedule'),
              notes: 'Driver Break',
              plannedRouteOrdinal: routeOrdinal,
              plannedDuration: 15,
              // This need to be seconds, but for UI to change it, we keep it mins for here
              state: 'planned',
              status: 'scheduled',
              promisedStart: plannedStartTime.toDate()
            });

            if (Ember.isNone(driverBreak.get('place.id'))) {
              const locationModel = store.createRecord('location');
              const addressModel = store.createRecord('address', {
                locations: [locationModel]
              });
              const placeModel = store.createRecord('place', {
                address: addressModel,
                location: locationModel
              });
              driverBreak.set('place', placeModel);
            }

            this.get('records').pushObject(driverBreak);

            if (Ember.isNone(driverBreak.get('stopPoint.id'))) {
              const stopPoint = store.createRecord('stop-point');
              driverBreak.set('stopPoint', stopPoint);
            }

            const saveDriverBreakFunction = this.get('saveDriverBreakFunction').bind(this);
            this.get('workspace').pushState('addBreak', {
              driverBreak,
              trips,
              saveDriverBreakFunction
            });
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('bulkActions', [{
        id: 'bulkMessage',
        permId: _mappedPermIds.default.sendRouteMessage,
        name: 'Bulk Message',
        action: routes => {
          if (_environment.default.APP.avlmLite) {
            this.sendMessagesToRoutesAvlmLite(routes.map(route => route));
          } else {
            this.sendMessagesToRoutes(routes, 'bulkMessage');
          }
        },
        disableOnLockedRoute: true
      }]);
    },

    _initScheduleSingleActions() {
      this.get('tableActions').unshiftObject({
        id: 'newRoute',
        permId: _mappedPermIds.default.createRoute,
        name: 'New Route',
        action: () => {
          const tripLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeTripLimit');
          const distanceLimitParam = this.store.peekRecord('cs-config-item', 'config-Route_parameters/routeDistanceLimit');
          let tripLimit = 9;
          let distanceLimit = 99; // prepare getting values from config service for tripLimit and distanceLimit
          // Special Route creation does not accept these 2 new variables yet.

          if (!Ember.isNone(tripLimitParam) && !Ember.isNone(tripLimitParam.get('value')) && _regexFunctions.REGEX_NUMBER.test(tripLimitParam.get('value'))) {
            tripLimit = parseInt(tripLimitParam.get('value'), 10);
          }

          if (!Ember.isNone(distanceLimitParam) && !Ember.isNone(distanceLimitParam.get('value')) && _regexFunctions.REGEX_NUMBER.test(distanceLimitParam.get('value'))) {
            distanceLimit = parseInt(distanceLimitParam.get('value'), 10);
          }

          const newRouteModel = Ember.Object.create({
            routeDate: new Date(this.workspace.startDate),
            plannedStartTime: (0, _moment.default)().toDate(),
            plannedEndTime: (0, _moment.default)().endOf('day').toDate(),
            tripLimit: tripLimit,
            distanceLimit: distanceLimit,
            type: _routeTemplate.ROUTE_TEMPLATE_TYPE_KEEPER,
            schedulingMode: _routeTemplate.AUTOMATIC_SCHEDULING_MODE,
            optimizationFlags: 3,
            affinities: []
          });
          const minDate = new Date().getTime();
          this.get('workspace').pushState('addeditScheduleRoute', {
            displayName: 'Add Route',
            routeModel: newRouteModel,
            minDate: (0, _moment.default)(minDate).toDate()
          }, {
            replaceSelf: true
          });
        }
      });
      this.set('activityLog', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLogRoute,
        name: 'Activity Log',
        action: model => {
          let routeName = model.get('name');

          if (_environment.default.APP.avlmLite) {
            routeName = model.get('trackingId');
          }

          this.get('workspace').pushState('routeActivityLog', {
            displayName: `Route ${routeName} Activity Log`,
            route: _environment.default.APP.avlmLite ? (0, _unwrapProxy.unwrapProxy)(model) : model
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      this.set('deleteRoute', {
        id: 'deleteRoute',
        permId: _mappedPermIds.default.deleteRoute,
        name: 'Delete Route',
        model: 'vehicle-event',
        action: model => {
          this.deleteRoute(model);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editScheduleRoute', {
        id: 'editRoute',
        permId: _mappedPermIds.default.editRoute,
        name: 'Edit',
        action: model => {
          const affinities = model.get('affinities');

          if (Ember.typeOf(affinities) === 'string') {
            model.set('affinities', JSON.parse(affinities));
          }

          this.get('workspace').pushState('addeditScheduleRoute', {
            displayName: `Edit Route ${model.get('name')}`,
            routeModel: model
          }, {
            replaceSelf: true
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('lockRoute', {
        id: 'lockRoute',
        permId: _mappedPermIds.default.lockUnlockRoute,
        name: 'Lock Route',
        action: model => {
          this.scheduleRouteLockConfirmation(model);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('unlockRoute', {
        id: 'unlockRoute',
        permId: _mappedPermIds.default.lockUnlockRoute,
        name: 'Unlock Route',
        action: model => {
          this.scheduleRouteUnLockConfirmation(model);
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      this.set('schedulingAddDriverBreakAction', {
        id: 'addDriverBreak',
        permId: _mappedPermIds.default.addBreak,
        name: 'Add Driver Break',
        action: async model => {
          const routeId = model.get('id');
          const route = await this.get('store').findRecord('route', routeId, {
            include: 'trips,routeBreaks'
          });
          setTimeout(() => {
            this.get('workspace').pushState('schedulingAddBreak', {
              route
            });
          }, 1000);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
    },

    _dynamicAVLMActions() {
      const sendMessage = this.get('sendMessage');
      const startRoute = this.get('startRoute');
      const endRoute = this.get('endRoute');
      const activityLog = this.get('activityLog');
      const addBreakdownReplacement = this.get('addBreakdownReplacement');
      const rescueRoute = this.get('rescueRoute');
      const lockRoute = this.get('lockRoute');
      const unlockRoute = this.get('unlockRoute');
      const editRoute = this.get('editRoute');
      const addDriverBreakAction = this.get('addDriverBreakAction');
      const row = this.getSelectedCoreModel();
      if (!row) return [];
      const userId = this.get('session').data.authenticated.userId;
      const assignVehicleDriver = this.get('assignVehicleDriver');
      const vehicle = row.get('assignedVehicle');
      const isLocked = row.get('isLocked');
      const isLockedByOtherUser = row.get('isLockedByOtherUser');
      const computedStatus = row.get('computedStatus');
      const record = (0, _unwrapProxy.unwrapProxy)(row);
      if (Ember.isNone(record)) return [];
      const allStopsPerformed = _environment.default.APP.avlmLite ? record.allStopsPerformedAvlmlite() : record.allStopsPerformed();
      const genericActions = [sendMessage, activityLog, editRoute];
      let user;

      if (userId) {
        user = this.get('store').peekRecord('sso-user', userId);
      } // only display the locked route action for "unlocked routes"


      if (!isLocked) {
        genericActions.push(lockRoute);
      } // Check if the route is locked.  If it is, then:
      //  * if the user is an admin, or is the user that locked the route, add the 'unlock' entry, but go ahead and
      //    get the rest of the actions
      //  * if the user is not the user that locked the route, or an admin, then just show the actions that are
      //    always allowed (sendMessage and activityLog)


      if (isLocked) {
        // should not show unlock if locked by another user and non-admin
        const isUserAdmin = user && user.get('isAdmin');

        if (isLockedByOtherUser && !isUserAdmin) {
          return [sendMessage, activityLog];
        } // User is an admin, or was the user that locked the route, allow unlock


        genericActions.push(unlockRoute);
      }

      if (vehicle && vehicle.get('isBroken')) {
        genericActions.push(addBreakdownReplacement);
      }

      if (allStopsPerformed && computedStatus !== _dispatchRoute.ROUTE_COMPLETED_STATUS) {
        genericActions.push(endRoute);
      }

      switch (computedStatus) {
        case _dispatchRoute.ROUTE_SIGNED_ON_STATUS:
          genericActions.push(...[startRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_STARTED_STATUS:
        case _dispatchRoute.ROUTE_IN_PROGRESS_STATUS:
        case _dispatchRoute.ROUTE_ON_BREAK_STATUS:
          genericActions.push(...[rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_COMPLETED_STATUS:
          break;

        case _dispatchRoute.ROUTE_AWAITING_STATUS:
          genericActions.push(...[assignVehicleDriver, startRoute, rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_EMERGENCY_STATUS:
          genericActions.push(...[assignVehicleDriver, startRoute, rescueRoute, addDriverBreakAction]);
          break;

        default:
          genericActions.push(...[assignVehicleDriver, rescueRoute, addDriverBreakAction]);
      }

      return genericActions;
    },

    _dynamicDispatchActions() {
      const sendMessage = this.get('sendMessage');
      const startRoute = this.get('startRoute');
      const undoStartRoute = this.get('undoStartRoute');
      const endRoute = this.get('endRoute');
      const activityLog = this.get('activityLog');
      const addBreakdownReplacement = this.get('addBreakdownReplacement');
      const rescueRoute = this.get('rescueRoute');
      const lockRoute = this.get('lockRoute');
      const unlockRoute = this.get('unlockRoute');
      const editRoute = this.get('editRoute');
      const addDriverBreakAction = this.get('addDriverBreakAction');
      const row = this.getSelectedCoreModel();
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      if (!row) return [];
      const assignVehicleDriver = this.get('assignVehicleDriver');
      const vehicle = row.get('assignedVehicle');
      const driver = row.get('assignedDriver.driverId');
      const isLocked = row.get('isLocked');
      const isLockedByOtherUser = row.get('isLockedByOtherUser');
      const routeStatus = selectedWidgetRecord.routeStatus;
      const routeEnded = [_dispatchRoute.ROUTE_ENDED_STATUS].includes(row.get('computedStatus')) || row.get('pullinGarageStop.isPerformed');
      const actualEndDateTime = selectedWidgetRecord.actualEndDateTime;
      const record = (0, _unwrapProxy.unwrapProxy)(row);
      const allStopsPerformed = _environment.default.APP.avlmLite ? record.allStopsPerformedAvlmlite() : record.allStopsPerformed();
      const genericActions = [activityLog];

      if (!routeEnded) {
        genericActions.push(editRoute);
      } // If status is no driver and no driverId is assigned then no send message.


      if (driver && !routeEnded) {
        genericActions.push(sendMessage);
      } // only display the locked route action for "unlocked routes"


      if (!isLocked && !routeEnded) {
        genericActions.push(lockRoute);
      } // Check if the route is locked.  If it is, then:
      //  * if the user is an admin, or is the user that locked the route, add the 'unlock' entry, but go ahead and
      //    get the rest of the actions
      //  * if the user is not the user that locked the route, or an admin, then just show the actions that are
      //    always allowed (sendMessage and activityLog)


      if (vehicle && vehicle.get('isBroken') && routeStatus === _dispatchRoute.ROUTE_BREAKDOWN_STATUS && !routeEnded) {
        genericActions.push(addBreakdownReplacement);
      }

      if (allStopsPerformed && routeStatus !== _dispatchRoute.ROUTE_COMPLETED_STATUS && !routeEnded) {
        genericActions.push(endRoute);
      }

      if (!allStopsPerformed && routeStatus !== _dispatchRoute.ROUTE_COMPLETED_STATUS && !routeEnded) {
        genericActions.push(editRoute);
      }

      if (allStopsPerformed && routeStatus === _dispatchRoute.ROUTE_ENDED_STATUS) {
        genericActions.splice(1);
      }

      switch (routeStatus) {
        case _dispatchRoute.ROUTE_SIGNED_ON_STATUS:
          genericActions.push(...[startRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_SIGNED_OFF_STATUS:
          // signed off status with an empty actualEndDateTime is just a temporary signed off
          // route is not done.
          if (Ember.isNone(actualEndDateTime)) {
            genericActions.push(...[rescueRoute, addDriverBreakAction]);
          }

          break;

        case _dispatchRoute.ROUTE_STARTED_STATUS:
          genericActions.push(...[undoStartRoute, rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_IN_PROGRESS_STATUS:
        case _dispatchRoute.ROUTE_ON_BREAK_STATUS:
          genericActions.push(...[rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_COMPLETED_STATUS:
        case _dispatchRoute.ROUTE_ENDED_STATUS:
          genericActions.push(...[sendMessage, activityLog, lockRoute]);
          break;

        case _dispatchRoute.ROUTE_NEW_STATUS:
          if (!record.get('assignedDriver.id') || !record.get('assignedVehicle.id')) {
            genericActions.push(...[assignVehicleDriver, rescueRoute, addDriverBreakAction]);
            break;
          }

        // eslint-disable-next-line no-fallthrough

        case _dispatchRoute.ROUTE_AWAITING_STATUS:
        case _dispatchRoute.ROUTE_DISPATCHED_STATUS:
          if (driver) {
            genericActions.push(...[assignVehicleDriver, startRoute, rescueRoute, addDriverBreakAction]);
          } else {
            genericActions.push(...[assignVehicleDriver, rescueRoute, addDriverBreakAction]);
          }

          break;

        case _dispatchRoute.ROUTE_EMERGENCY_STATUS:
          genericActions.push(...[rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_SCHEDULED_STATUS:
        default:
          genericActions.push(...[assignVehicleDriver, rescueRoute, addDriverBreakAction]);
      }

      if (isLocked) {
        const permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.unlockOtherUserLocks, null);

        if (!isLockedByOtherUser || isLockedByOtherUser && permGranted) {
          genericActions.push(unlockRoute);
        }
      }

      return genericActions;
    },

    _dynamicScheduleActions() {
      const activityLog = this.get('activityLog');
      const editRoute = this.get('editScheduleRoute');
      const lockRoute = this.get('lockRoute');
      const unlockRoute = this.get('unlockRoute');
      const schedulingAddDriverBreakAction = this.get('schedulingAddDriverBreakAction');
      const row = this.getSelectedCoreModel();
      if (!row) return [];
      const userId = this.get('session').data.authenticated.userId;
      const isLocked = row.get('record.isLocked');
      const isLockedByOtherUser = row.get('record.isLockedByOtherUser');
      const genericActions = [activityLog, editRoute, schedulingAddDriverBreakAction];
      let user;

      if (userId) {
        user = this.get('store').peekRecord('sso-user', userId);
      } // only display the locked route action for "unlocked routes"


      if (!isLocked) {
        genericActions.push(lockRoute);
      } // Check if the route is locked.  If it is, then:
      //  * if the user is an admin, or is the user that locked the route, add the 'unlock' entry, but go ahead and
      //    get the rest of the actions
      //  * if the user is not the user that locked the route, or an admin, then just show the actions that are
      //    always allowed (sendMessage and activityLog)


      if (isLocked) {
        // should not show unlock if locked by another user and non-admin
        const isUserAdmin = user && user.get('isAdmin');

        if (isLockedByOtherUser && !isUserAdmin) {
          return [activityLog];
        } // User is an admin, or was the user that locked the route, allow unlock


        genericActions.push(unlockRoute);
      }

      return genericActions;
    },

    // ember-react-table overwrites
    applyTransformationData(widgetRecords) {
      widgetRecords.forEach(widgetRecord => {
        if (!widgetRecord._transformed) {
          this.applyDriverIdTransformation(widgetRecord);
          this.applyOtpStatusTransformation(widgetRecord);
          this.applyActualDateTransformation(widgetRecord);
          this.applyPlannedBreakTransformation(widgetRecord);

          if (!_environment.default.APP.avlmLite) {
            this.applyDispatchRouteIdTransformation(widgetRecord);
            this.applyRoutePlanedTimeTransformation(widgetRecord);
          }

          widgetRecord._transformed = true;
        }
      });
      return widgetRecords;
    },

    applyDispatchRouteIdTransformation(widgetRecord) {
      if (!widgetRecord.routeId) {
        widgetRecord.routeId = widgetRecord.id;
      }

      if (widgetRecord.dispatchRoutePk) {
        widgetRecord.id = widgetRecord.dispatchRoutePk;
      }
    },

    applyRoutePlanedTimeTransformation(widgetRecord) {
      widgetRecord.plannedStartDateTime = widgetRecord.rvdStartTime || widgetRecord.plannedStartDateTime;
      widgetRecord.plannedEndDateTime = widgetRecord.rvdEndTime || widgetRecord.plannedEndDateTime;
    },

    applyPlannedBreakTransformation(widgetRecord) {
      if (widgetRecord.plannedBreaks && (0, _lodash.isObject)(widgetRecord.plannedBreaks)) {
        const objectLength = Object.keys(widgetRecord.plannedBreaks).length;
        widgetRecord.plannedBreaks = objectLength;
      }
    },

    applyActualDateTransformation(widgetRecord) {
      // Need to have full datetime to do timezone transform
      if (widgetRecord.actualStartDate && widgetRecord.actualStartTime) {
        widgetRecord.actualStartDate = widgetRecord.actualStartDate + ' ' + widgetRecord.actualStartTime;
      }

      if (widgetRecord.actualEndDate && widgetRecord.actualEndTime) {
        widgetRecord.actualEndDate = widgetRecord.actualEndDate + ' ' + widgetRecord.actualEndTime;
      }
    },

    calculateTimeQuery() {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const plannedStartDateQuery = (0, _rqlGenerators.dateGteFormatRQL)('plannedStartDate', [startTime], 'YYYY-MM-DD');
      const plannedEndDateQuery = (0, _rqlGenerators.dateLteFormatRQL)('plannedEndDate', [endTime], 'YYYY-MM-DD');
      const plannedStartTimeQuery = (0, _rqlGenerators.dateGteFormatRQL)('plannedStartTime', [startTime], 'HH:mm:ss.SSS');
      const plannedEndTimeQuery = (0, _rqlGenerators.dateLteFormatRQL)('plannedEndTime', [endTime], 'HH:mm:ss.SSS');
      const dateRangeFilterQuery = (0, _rqlGenerators.andRQL)(plannedStartDateQuery, plannedEndDateQuery);
      const timeRangeFilterQuery = (0, _rqlGenerators.andRQL)(plannedStartTimeQuery, plannedEndTimeQuery);
      const rangeFilterQuery = (0, _rqlGenerators.andRQL)(dateRangeFilterQuery, timeRangeFilterQuery);
      return rangeFilterQuery;
    },

    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) {
      let rowStyleHandler = '';

      if (currRowData.isLocked) {
        rowStyleHandler += ' is-locked';
      }

      if (columnIndex === 0) {
        if (currRowData.otpStatus === 'Late') {
          rowStyleHandler += ' otp-late-first-column';
        }

        if (currRowData.otpStatus === 'On Time') {
          rowStyleHandler += ' otp-on-time-first-column';
        }

        if (currRowData.otpStatus === 'In Danger') {
          rowStyleHandler += ' otp-in-danger-first-column';
        }
      }

      return rowStyleHandler;
    }

  });

  _exports.default = _default;
});