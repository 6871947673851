define("adept-iq/pods/components/iq-widgets/route-information-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "moment", "adept-iq/pods/components/iq-widgets/route-information-widget/config", "ember-concurrency", "adept-iq/config/mapped-permIds"], function (_exports, _component, _moment, _config, _emberConcurrency, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VERIFY_ROUTE = 1;
  const REOPEN_ROUTE = 2;
  const REPORT_BREAKDOWN = 3;
  const BREAKDOWN_REPLACEMENT = 4;
  const RESCUE = 5;

  var _default = _component.default.extend({
    interaction: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    editModal: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    session: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    serviceModeTaxiType: 'Taxi',
    classNames: ['reconcile-widget'],
    layoutName: 'components/iq-widgets/reconcile-widget',
    config: _config.default,
    reconcileRouteDetails: null,
    isUndoClicked: false,
    enableCursorEditing: false,
    enableCursorEditingIcons: false,
    collapseState: 'expanded',
    // Reset map and address picker
    isInitial: Ember.computed.equal('collapseState', 'expanded'),
    isCollapsed: Ember.computed.equal('collapseState', 'collapsed'),
    isExpanded: Ember.computed.equal('collapseState', 'expanded'),
    detailCollapseState: 'expanded',
    detailIsInitial: Ember.computed.equal('detailCollapseState', 'expanded'),
    detailIsCollapsed: Ember.computed.equal('detailCollapseState', 'collapsed'),
    detailIsExpanded: Ember.computed.equal('detailCollapseState', 'expanded'),
    isCompletedRoute: Ember.computed.alias('reconcile.isCompletedRoute'),
    isDeletedRoute: Ember.computed.alias('reconcile.isDeletedRoute'),
    routeId: Ember.computed.readOnly('reconcile.reconcileSelectedRouteId'),
    canEdit: Ember.computed.alias('enableEditing'),
    isEditStart: Ember.computed.alias('isEditingStart'),
    vehicleOptions: Ember.computed.alias('reconcile.reconcileVehicle'),
    driverOptions: Ember.computed.alias('reconcile.reconcileDriver'),
    reconcileTripDate: Ember.computed.alias('reconcile.reconcileDate'),
    isClosedRoute: Ember.computed.notEmpty('reconcileRouteDetails.closedDate'),
    reconcileVerifiedDate: Ember.computed('reconcileRouteDetails.verifiedDate', function () {
      const date = Ember.get(this, 'reconcileRouteDetails.verifiedDate');

      if (date) {
        return (0, _moment.default)(date).format('MM/DD/YYYY');
      }

      return '';
    }),
    routeTypeOptions: Ember.computed('routeActionTypeOptions', 'selectedRouteType', function () {
      return _config.default.routeTypes;
    }),
    routeActionTypeOptions: Ember.computed('selectedRouteActionType', function () {
      return _config.default.routeActionTypes;
    }),
    zoneOptions: Ember.computed('reconcileRouteDetails', 'routeStartZone', 'routeEndZone', function () {
      const zones = this.store.peekAll('zone');
      const zoneArray = [];
      zones.map(zone => {
        if (zone && zone.displayName) {
          zoneArray.push(zone.displayName);
        }
      });
      return zoneArray;
    }),
    isRescueRoute: Ember.computed('reconcileRouteDetails.type', function () {
      const type = Ember.get(this, 'reconcileRouteDetails.type');
      return type && type.toLocaleLowerCase() === 'rescue';
    }),
    isRescueReplacementRoute: Ember.computed('reconcileRouteDetails.type', function () {
      const type = Ember.get(this, 'reconcileRouteDetails.type');
      return type && type.toLocaleLowerCase() === 'rescue replacement';
    }),
    isBreakdownReplacementRoute: Ember.computed('reconcileRouteDetails.type', function () {
      const type = Ember.get(this, 'reconcileRouteDetails.type');
      return type && type.toLocaleLowerCase() === 'breakdown replacement';
    }),
    isBreakdownRoute: Ember.computed('reconcileRouteDetails.type', function () {
      const type = Ember.get(this, 'reconcileRouteDetails.type');
      return type && type.toLocaleLowerCase() === 'breakdown';
    }),
    isRegularRoute: Ember.computed('reconcileRouteDetails.type', function () {
      return !this.isRescueRoute && !this.isRescueReplacementRoute && !this.isBreakdownRoute && !this.isBreakdownReplacementRoute;
    }),
    enableDisableRouteActionOptions: Ember.observer('isCompletedRoute', 'isDeletedRoute', function () {
      const isCompleted = Ember.get(this, 'reconcile.isCompletedRoute');

      if (isCompleted) {
        Ember.set(this, 'enableEditing', false);
        Ember.set(this, 'enableCursorEditing', false);
      }

      this.enableDisableOption();
    }),
    updateReconcileRouteDetails: Ember.observer('reconcile.reconcileRouteDetails', 'reconcile.reconcileSelectedRouteId', function () {
      if (this.get('reconcile.reconcileMode') === this.serviceModeTaxiType || !this.get('reconcile.reconcileSelectedRouteId')) {
        this.resetProperties();
        return;
      }

      const routeInformation = this.get('reconcile.reconcileRouteDetails');
      Ember.set(this, 'selectedRouteActionType', null);

      if (!Ember.isEmpty(routeInformation)) {
        this.enableDisableEditing(false, !this.isCompletedRoute);
        this.updateProperties(routeInformation);
        Ember.set(this, 'reconcileRouteDetails', routeInformation);

        if (!routeInformation.isCompleted) {
          Ember.set(this, 'enableCursorEditing', true);
        }
      }

      this.enableDisableOption();
    }),
    routeSelectionPerformed: Ember.computed('reconcile.reconcileRouteDetails', function () {
      const routeData = this.get('reconcile.reconcileRouteDetails');
      if (routeData && routeData.data) return true;
      return false;
    }),

    init() {
      Ember.set(this, 'selectedRadioValue', 'start');
      Ember.set(this, 'enableEditing', false);
      Ember.set(this, 'isEditingStart', false);
      Ember.set(this, 'initialMapLayers', null);
      Ember.set(this, 'allEvents', null);

      this._super(...arguments);

      this.fetchRouteDataTask = this.get('reconcile.fetchRouteData');
      this.fetchRouteIdsTask = this.get('reconcile.fetchRouteIds');

      if (this.get('reconcile.reconcileRouteDetails')) {
        Ember.run.scheduleOnce('afterRender', this, 'updateReconcileRouteDetails');
      }

      this.configurePermission();
    },

    configurePermission() {
      Ember.set(this, 'isRouteVerificationAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.verifyRouteRecon, null));
      Ember.set(this, 'isRouteEditingAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editRouteInfoRecon, null));
      Ember.set(this, 'isRouteDeletingAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.deleteRouteRecon, null));
      Ember.set(this, 'isRescueReplacementAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createRescueReplacementRecon, null));
      Ember.set(this, 'isReportBreakdownAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.reportBreakdownRecon, null));
      Ember.set(this, 'isBreakdownReplacementAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createBreakdownReplacementRecon, null));
      Ember.set(this, 'isReopenRouteAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.reopenRouteRecon, null));
    },

    updateProperties(routeInformation) {
      if (Ember.isEmpty(routeInformation)) {
        return;
      }

      let routeType = {
        id: 1,
        name: 'Regular'
      };
      const routeTypeOptions = this.get('routeTypeOptions');
      routeTypeOptions.map(data => {
        if (data && routeInformation && routeInformation.get('type') && data.name.toLocaleLowerCase() === routeInformation.get('type').toLocaleLowerCase()) {
          routeType = data;
        }
      });
      const startAddress = routeInformation.get('start').get('address');
      const endAddress = routeInformation.get('end').get('address');
      const driver = routeInformation.get('driver');
      this.setProperties({
        routeKeeper: routeInformation.get('isKeeperRoute'),
        routeNotes: routeInformation.get('notes'),
        plannedStartTime: routeInformation.get('plannedStartTime') ? (0, _moment.default)(routeInformation.get('plannedStartTime')).format('hh:mm A') : '',
        plannedEndTime: routeInformation.get('plannedEndTime') ? (0, _moment.default)(routeInformation.get('plannedEndTime')).format('hh:mm A') : '',
        driverStartTime: driver.get('shiftStartTime') ? driver.get('shiftStartTime') : '',
        driverEndTime: driver.get('shiftEndTime') ? driver.get('shiftEndTime') : '',
        routeOdometerStart: routeInformation.get('odometerStart'),
        routeOdometerEnd: routeInformation.get('odometerEnd'),
        maxMilesPerHour: routeInformation.get('maxMilesPerHour'),
        maxTripsPerHour: routeInformation.get('maxTripsPerHour'),
        routeStartIdentifier: startAddress.get('identifier'),
        routeStartAddress: {
          freeformAddress: startAddress.get('formattedAddress'),
          lat: startAddress.get('lat'),
          lng: startAddress.get('lng')
        },
        routeStartZone: startAddress.get('zone'),
        routeStartNotes: startAddress.get('notes'),
        routeEndIdentifier: endAddress.get('identifier'),
        routeEndAddress: {
          freeformAddress: endAddress.get('formattedAddress'),
          lat: endAddress.get('lat'),
          lng: endAddress.get('lng')
        },
        routeEndZone: endAddress.get('zone'),
        routeEndNotes: endAddress.get('notes'),
        selectedDriver: driver,
        driverPhoneNumber: this.formattedPhoneNo(driver.get('phoneNumber')),
        selectedVehicle: routeInformation.get('vehicle'),
        selectedRouteType: routeType
      });
    },

    resetProperties() {
      this.setProperties({
        routeKeeper: null,
        routeNotes: null,
        plannedStartTime: null,
        plannedEndTime: null,
        driverStartTime: null,
        driverEndTime: null,
        routeOdometerStart: null,
        routeOdometerEnd: null,
        maxMilesPerHour: null,
        maxTripsPerHour: null,
        routeStartIdentifier: null,
        routeStartAddress: null,
        routeStartZone: null,
        routeStartNotes: null,
        routeEndIdentifier: null,
        routeEndAddress: null,
        routeEndZone: null,
        routeEndNotes: null,
        selectedDriver: null,
        driverPhoneNumber: null,
        selectedVehicle: null,
        selectedRouteType: null
      });
      Ember.set(this, 'selectedRadioValue', 'start');
      Ember.set(this, 'enableEditing', false);
      Ember.set(this, 'enableCursorEditing', false);
      Ember.set(this, 'initialMapLayers', null);
      Ember.set(this, 'allEvents', null);
      Ember.set(this, 'reconcileRouteDetails', null);
    },

    enableDisableOption() {
      const routeActionTypeArray = [];
      const options = Ember.get(this, 'routeActionTypeOptions');
      const isCompleted = Ember.get(this, 'reconcile.isCompletedRoute');
      options.map(opt => {
        const option = {
          disabled: false,
          name: opt.name,
          id: opt.id
        };

        switch (opt.id) {
          case VERIFY_ROUTE:
            option.disabled = isCompleted || !this.isRouteVerificationAllowed;
            break;

          case REOPEN_ROUTE:
            option.disabled = !isCompleted || this.isClosedRoute || !this.isReopenRouteAllowed;
            break;

          case RESCUE:
            option.disabled = !(!isCompleted && (this.isRegularRoute || this.isRescueReplacementRoute || this.isBreakdownRoute || this.isBreakdownReplacementRoute)) || !this.isRescueReplacementAllowed;
            break;

          case REPORT_BREAKDOWN:
            option.disabled = !(!isCompleted && (this.isRegularRoute || this.isRescueReplacementRoute || this.isRescueRoute)) || !this.isReportBreakdownAllowed;
            break;

          case BREAKDOWN_REPLACEMENT:
            option.disabled = !(!isCompleted && this.isBreakdownRoute) || !this.isBreakdownReplacementAllowed;
            break;

          default:
            break;
        }

        routeActionTypeArray.push(option);
      });
      Ember.set(this, 'routeActionTypeOptions', routeActionTypeArray);
    },

    showErrorToolTip(title, tip) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: title,
        tip: tip,
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        },
        hasOverlay: true
      });
    },

    showToolTip(title, tip, type) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: title,
        tip: tip,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          if (type === REOPEN_ROUTE) {
            return Ember.get(this, 'patchRouteTask').perform('reopen route').then(() => {
              this.send('reopenRouteSuccess');
              this.get('notifications').success('THIS ROUTE WAS SUCCESSFULLY REOPENED FOR EDITING');
              tooltip.reset();
            }).catch(error => {
              let message;

              switch (error.status) {
                case 400:
                  message = 'There was a problem with one of the fields. Please check over the form and try again.';
                  break;

                default:
                  message = 'There was a problem in reopen route, please try again.';
                  break;
              }

              this.get('notifications').warning(message);
              Ember.set(this, 'selectedRouteActionType', null);
              tooltip.reset();
            });
          }

          this.toggleProperty('reconcile.isLoading');
          return this.get('saveRouteInfomationTask').perform().then(() => {
            this.send('confirmSaveAction');
            this.toggleProperty('reconcile.isLoading');
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in save route, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            this.toggleProperty('reconcile.isLoading');
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          Ember.set(this, 'selectedRouteActionType', null);
          return tooltip.reset();
        },
        closeAction: () => {
          Ember.set(this, 'selectedRouteActionType', null);
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    enableDisableEditing(isEnable, isEnableCursorEditing) {
      Ember.set(this, 'enableCursorEditing', isEnableCursorEditing);
      Ember.set(this, 'enableEditing', isEnable);
      Ember.set(this, 'isEditingStart', isEnable);
    },

    getValidationError() {
      const odoStart = this.get('routeOdometerStart') ? parseInt(this.get('routeOdometerStart'), 10) : 0;
      const odoEnd = this.get('routeOdometerEnd') ? parseInt(this.get('routeOdometerEnd'), 10) : 0;

      if (odoStart > odoEnd) {
        return 'Odometer Start value cannot be greater then Odometer End value.';
      }

      return null;
    },

    handleVerifyRoute() {
      const isExists = Ember.get(this, 'reconcile.isTripExists');

      if (!isExists) {
        const title = 'Verify Route';
        const tip = 'A route containing only driver breaks or no trips on it cannot be verified. Please delete the route';
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title,
          tip,
          primaryActionText: 'OK',
          primaryAction: () => {
            Ember.set(this, 'selectedRouteActionType', null);
            this.enableDisableEditing(false, true);
            return tooltip.reset();
          },
          closeAction: () => {
            Ember.set(this, 'selectedRouteActionType', null);
            return tooltip.reset();
          },
          hasOverlay: true
        });
      } else {
        this.toggleProperty('reconcile.isLoading');
        Ember.get(this, 'getVerifyRouteTask').perform('verify route').then(() => {
          this.toggleProperty('reconcile.isLoading');
        }).catch(error => {
          let message;

          switch (error.status) {
            case 400:
              message = 'There was a problem with one of the fields. Please check over the form and try again.';
              break;

            default:
              message = 'There was a problem in verify route, please try again.';
              break;
          }

          this.get('notifications').warning(message);
          Ember.set(this, 'selectedRouteActionType', null);
          this.toggleProperty('reconcile.isLoading');
        });
      }
    },

    handleCompleteRoute() {
      const title = 'Route Information';
      const tip = 'The route was successfully verified. Do you want to close the route?';
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title,
        tip,
        primaryActionText: 'OK',
        primaryAction: () => {
          return Ember.get(this, 'patchRouteTask').perform('verify route').then(() => {
            this.send('completeRouteSuccess');
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in route complete, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            Ember.set(this, 'selectedRouteActionType', null);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          Ember.set(this, 'selectedRouteActionType', null);
          return tooltip.reset();
        },
        closeAction: () => {
          Ember.set(this, 'selectedRouteActionType', null);
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    closePopupHandler() {
      this.send('cancel');
    },

    setResetVerifyRouteOptions(status, errorMessages, callBackFun) {
      this.setProperties({
        verifyRouteStatus: status,
        'reconcile.verifyRouteErrors': errorMessages,
        'reconcile.closePopupHandler': callBackFun,
        unverifiedRouteDetails: errorMessages
      });
    },

    getVerifyRouteTask: (0, _emberConcurrency.task)(function* () {
      let routeData = null;
      const routeInfo = this.get('reconcileRouteDetails');
      const routeId = routeInfo.routeId;
      const verifyRouteData = yield this.get('store').query('reconcile-verify-route', {
        routeId: routeId
      });

      if (Ember.isEmpty(verifyRouteData)) {
        this.setResetVerifyRouteOptions(null, null, null);
        return;
      }

      verifyRouteData.map(item => {
        routeData = item.data;
      });

      if (routeData && routeData.isVerified === true) {
        this.setResetVerifyRouteOptions('verified', null, null);
        this.handleCompleteRoute();
      } else {
        this.setResetVerifyRouteOptions('unverified', routeData, this.closePopupHandler.bind(this));
      }
    }),
    saveRouteInfomationTask: (0, _emberConcurrency.task)(function* () {
      try {
        const routePayload = {
          id: this.get('reconcileRouteDetails').routeId,
          type: this.get('selectedRouteType').name,
          odometerStart: this.get('routeOdometerStart'),
          odometerEnd: this.get('routeOdometerEnd'),
          notes: this.get('routeNotes'),
          vehicle: {
            id: this.get('selectedVehicle').get('vehicleId')
          },
          driver: {
            id: this.get('selectedDriver').get('driverId'),
            phoneNumber: this.get('driverPhoneNumber')
          },
          start: {
            address: {
              formattedAddress: this.get('routeStartAddress').freeformAddress,
              zone: this.get('routeStartZone'),
              notes: this.get('routeStartNotes'),
              identifier: this.get('routeStartIdentifier'),
              lat: this.get('routeStartAddress').lat,
              lng: this.get('routeStartAddress').lng
            }
          },
          end: {
            address: {
              formattedAddress: this.get('routeEndAddress').freeformAddress,
              zone: this.get('routeEndZone'),
              notes: this.get('routeEndNotes'),
              identifier: this.get('routeEndIdentifier'),
              lat: this.get('routeStartAddress').lat,
              lng: this.get('routeStartAddress').lng
            }
          }
        };
        const editRouteAdapter = this.get('store').adapterFor('reconcile-route');
        yield editRouteAdapter.putRoute(routePayload, this.get('selectedRouteType').name);
      } catch (error) {
        throw error;
      }
    }),
    patchRouteTask: (0, _emberConcurrency.task)(function* (type) {
      const routeAdapter = this.get('store').adapterFor('reconcile-route');
      const routeDetails = this.get('reconcileRouteDetails');
      const payload = {
        id: routeDetails.routeId
      };

      if (type === 'delete route') {
        payload.isDeleted = true;
      } else if (type === 'verify route') {
        payload.isCompleted = true;
        payload.verifiedBy = this.session.data.authenticated.tokenInfo.firstName;
        payload.verifiedDate = (0, _moment.default)().toISOString();
        payload.userId = this.session.data.authenticated.userId;
      } else if (type === 'reopen route') {
        payload.isCompleted = false;
        payload.reopenedBy = this.session.data.authenticated.tokenInfo.firstName;
        payload.reopenedDate = (0, _moment.default)().toISOString();
        payload.userId = this.session.data.authenticated.userId;
      }

      yield routeAdapter.patchRoute(payload, type);
    }),

    formattedPhoneNo(phone) {
      if (!phone) {
        return;
      }

      const phoneNoStr = phone.toString().replace(/[^\d]/g, '');

      if (phoneNoStr.length === 10) {
        return phoneNoStr.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }

      return phoneNoStr;
    },

    updateRouteInformation() {
      Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
        'reconcile-trip': [],
        'reconcile-trip-stops': []
      });
      this.fetchRouteDataTask.perform();
    },

    updateRouteIdsInformation() {
      this.fetchRouteIdsTask.perform();
    },

    actions: {
      onChangeStartLocation(record) {
        this.set('routeStartAddress', record);
        this.enableDisableEditing(true, false);
      },

      onChangeEndLocation(record) {
        this.set('routeEndAddress', record);
        this.enableDisableEditing(true, false);
      },

      oninputTextChange(type, value) {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'enableCursorEditing', false);

        switch (type) {
          case _config.default.DRIVER_PHONE_NUMBER:
            Ember.set(this, 'driverPhoneNumber', this.formattedPhoneNo(value));
            break;

          case _config.default.ODOMETER_START:
            Ember.set(this, 'routeOdometerStart', value);
            break;

          case _config.default.ODOMETER_END:
            Ember.set(this, 'routeOdometerEnd', value);
            break;

          case _config.default.ROUTE_NOTES:
            Ember.set(this, 'routeNotes', value);
            break;

          case _config.default.ROUTE_START_IDENTIFIER:
            Ember.set(this, 'routeStartIdentifier', value);
            break;

          case _config.default.ROUTE_START_NOTES:
            Ember.set(this, 'routeStartNotes', value);
            break;

          case _config.default.ROUTE_END_IDENTIFIER:
            Ember.set(this, 'routeEndIdentifier', value);
            break;

          case _config.default.ROUTE_END_NOTES:
            Ember.set(this, 'routeEndNotes', value);
            break;

          default: // code block

        }
      },

      isNumber(keyCode) {
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
          return false;
        }

        return true;
      },

      onProviderOptionSelect(option) {
        Ember.set(this, 'selectedProvider', option);
        this.notifyPropertyChange('vehicleOptions');
        Ember.set(this, 'selectedRoute', null);
        Ember.set(this, 'selectedVehicle', null);
      },

      onRouteTypeSelect(option) {
        Ember.set(this, 'selectedRouteType', option);
        this.enableDisableEditing(true, false);
      },

      onRouteActionTypeSelect(option) {
        Ember.set(this, 'selectedRouteActionType', option);

        switch (option.id) {
          case VERIFY_ROUTE:
            this.handleVerifyRoute();
            break;

          case REOPEN_ROUTE:
            this.showToolTip('Route Information', 'Reopen this route to allow editing?', REOPEN_ROUTE);
            break;

          case BREAKDOWN_REPLACEMENT:
            this.get('workspace').pushState('reconcileReplacementBreakdown', {
              route: this.get('reconcileRouteDetails'),
              action: this.actions.closeDialogHandler.bind(this, option.id)
            });
            break;

          case REPORT_BREAKDOWN:
            this.get('workspace').pushState('reconcileVehicleBreakdown', {
              route: this.get('reconcileRouteDetails'),
              action: this.actions.closeDialogHandler.bind(this, option.id)
            });
            break;

          case RESCUE:
            this.get('workspace').pushState('rescueRouteReconcile', {
              route: this.get('reconcileRouteDetails'),
              action: this.actions.closeDialogHandler.bind(this, option.id)
            });
            break;

          default:
            break;
        }
      },

      onVehicleOptionSelect(option) {
        Ember.set(this, 'selectedVehicle', option);
        this.enableDisableEditing(true, false);
      },

      onDriverOptionSelect(option) {
        Ember.set(this, 'selectedDriver', option);
        Ember.set(this, 'driverPhoneNumber', this.formattedPhoneNo(option.phoneNumber));
        Ember.set(this, 'driverStartTime', option.get('shiftStartTime') ? option.get('shiftStartTime') : null);
        Ember.set(this, 'driverEndTime', option.get('shiftEndTime') ? option.get('shiftEndTime') : null);
        this.enableDisableEditing(true, false);
      },

      onChangeTime(valuePath, value) {
        this.enableDisableEditing(true, true);
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      },

      confirmSaveAction() {
        Ember.set(this, 'selectedRadioValue', 'start');
        Ember.set(this, 'saveDataPopup', '');
        Ember.set(this, 'enableCursorEditingIcons', false);
        this.get('notifications').success('ROUTE ' + this.get('reconcileRouteDetails').routeId + ' SAVED SUCCESSFULLY');
        this.enableDisableEditing(false, true);
        this.updateRouteInformation();
      },

      reopenRouteSuccess() {
        Ember.set(this, 'enableCursorEditing', true);
        Ember.set(this, 'selectedRouteActionType', null);
        const routeDetails = Ember.get(this, 'reconcile.reconcileRouteDetails');
        Ember.set(routeDetails, 'isCompleted', false);
      },

      deleteRouteSuccess() {
        this.resetProperties();
        const recon = Ember.get(this, 'reconcile');
        const routeDetails = Ember.get(recon, 'reconcileRouteDetails');
        Ember.set(routeDetails, 'isDeleted', true);
        Ember.get(recon, 'resetAndFetchRouteIds').perform();
      },

      completeRouteSuccess() {
        this.enableDisableEditing(false, false);
        this.setProperties({
          selectedRouteActionType: null,
          'reconcile.isCompletedRoute': true
        });
        this.updateRouteInformation();
      },

      cancel() {
        Ember.set(this, 'saveDataPopup', '');
        Ember.set(this, 'selectedRouteActionType', null);
        this.setResetVerifyRouteOptions(null, null, null);
        this.enableDisableEditing(false, true);
      },

      cancelAction() {
        Ember.set(this, 'enableEditing', false);
        this.setResetVerifyRouteOptions(null, null, null);
      },

      onEditButtonClick() {
        Ember.set(this, 'selectedRouteActionType', null);
        this.enableDisableEditing(true, false);
      },

      onSaveButtonClick() {
        const error = this.getValidationError();

        if (error) {
          this.showErrorToolTip('Error message', error);
        } else {
          this.showToolTip('Route Information', 'Do you want to save route information?', 'Save Route');
          Ember.set(this, 'workspace.activeContext.topActiveContext.resetMapFlag', true);
        }
      },

      onUndoButtonClick() {
        this.set('isUndoClicked', true);
        this.set('selectedRadioValue', 'start');
        this.enableDisableEditing(false, true);
        const routeInformation = Ember.get(this, 'reconcileRouteDetails');
        this.updateProperties(routeInformation);
        this.set('canReset', true);
      },

      onRadioButtonClick(val) {
        this.set('selectedRadioValue', val);
      },

      onDeleteButtonClick() {
        const isExists = Ember.get(this, 'reconcile.isTripExists');
        const title = 'Route Information';
        let tip = `Do you want to delete route ${this.get('reconcileRouteDetails').routeId}?`;
        const successMsg = `ROUTE ${this.get('reconcileRouteDetails').routeId} DELETED SUCCESSFULLY`;
        const tooltip = this.get('tooltip');

        if (isExists) {
          tip = 'You cannot delete this route. You must first move the existing trips associated with this route';
          tooltip.pushConfirmation({
            title,
            tip,
            primaryActionText: 'OK',
            primaryAction: () => {
              return tooltip.reset();
            },
            hasOverlay: true
          });
        } else {
          tooltip.pushConfirmation({
            title,
            tip,
            primaryActionText: 'Confirm',
            primaryAction: () => {
              return Ember.get(this, 'patchRouteTask').perform('delete route').then(() => {
                this.send('deleteRouteSuccess');
                this.get('notifications').success(successMsg);
                tooltip.reset();
              }).catch(error => {
                let message;

                switch (error.status) {
                  case 400:
                    message = 'There was a problem with one of the fields. Please check over the form and try again.';
                    break;

                  default:
                    message = 'There was a problem in delete route, please try again.';
                    break;
                }

                this.get('notifications').warning(message);
                tooltip.reset();
              });
            },
            secondaryActionText: 'Cancel',
            secondaryAction: () => {
              this.send('cancelAction');
              return tooltip.reset();
            },
            hasOverlay: true
          });
        }
      },

      onLocationHeaderClick() {
        const collapseState = this.get('collapseState');

        if (collapseState === 'collapsed') {
          this.set('collapseState', 'expanded');
        } else {
          this.set('collapseState', 'collapsed');
        }
      },

      onDetailsHeaderClick() {
        const detailCollapseState = this.get('detailCollapseState');

        if (detailCollapseState === 'collapsed') {
          this.set('detailCollapseState', 'expanded');
        } else {
          this.set('detailCollapseState', 'collapsed');
        }
      },

      closeDialogHandler(option, isSaved) {
        if (option === REPORT_BREAKDOWN) {
          Ember.set(this, 'workspace.activeContext.topActiveContext.resetLocation', true);
        }

        Ember.set(this, 'selectedRouteActionType', null);

        if (!isSaved) {
          return;
        }

        if (option === REPORT_BREAKDOWN) {
          this.updateRouteInformation();
          Ember.set(this, 'workspace.activeContext.topActiveContext.resetLocation', true);
        } else if (option === BREAKDOWN_REPLACEMENT || option === RESCUE) {
          this.updateRouteIdsInformation();
          this.updateRouteInformation();
        }
      },

      onZoneOptionsSelect(isStart, option) {
        this.enableDisableEditing(true, false);

        if (isStart) {
          Ember.set(this, 'routeStartZone', option);
        } else {
          Ember.set(this, 'routeEndZone', option);
        }
      }

    }
  });

  _exports.default = _default;
});