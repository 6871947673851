define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/base-cell-item", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BaseCellItem extends _react.default.PureComponent {
    constructor(props) {
      super(props);
    }

    getColumnValue(data, columnInfo, rowIndex, columnIndex) {
      const {
        showGearIcon
      } = this.props;
      const actionableColumnIndex = showGearIcon ? 1 : 0; // for checkbox and gear

      if (columnIndex <= actionableColumnIndex) return '';
      const {
        valuePath: propertyName
      } = columnInfo;
      const currRowData = this.getRowData(data, rowIndex);

      try {
        const columnValue = propertyName.split('.').reduce((a, b) => a[b], currRowData); // Dont display null or undefined values

        if (typeof columnValue === 'undefined') {
          return '';
        }

        if (typeof columnValue === 'boolean') {
          return columnValue ? 'true' : 'false';
        }

        return columnValue;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }

      return '';
    }

    getRowData(data, rowIndex) {
      const currRowData = data[rowIndex - 1];
      return currRowData;
    }

    getStylesClasses(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue) {
      // Each cell should have either one of the following classes - even-cell, odd-cell, selected-row
      let cellColorCSSClass = (rowIndex - 1) % 2 ? 'even-cell' : 'odd-cell';
      cellColorCSSClass = this.isRowSelected(rowIndex, selectedRowIndex) ? 'selected-row' : cellColorCSSClass;
      cellColorCSSClass = this.isColumnSelected(rowIndex, columnIndex, selectedRowIndex, selectedColumnIndex) ? 'selected-column' : cellColorCSSClass;
      const firstColumnCSS = columnIndex === 0 ? 'first-column' : '';
      const extraCellClasses = this.getCellClasses(data, columnInfo, rowIndex, columnIndex, columnValue);
      const classNames = `Cell ${cellColorCSSClass}  ${extraCellClasses}  ${firstColumnCSS}`;
      return classNames;
    }

    getCellClasses()
    /*data, columnInfo, rowIndex, columnIndex, columnValue*/
    {
      return '';
    }

    isRowSelected(rowIndex, selectedRowIndex) {
      return rowIndex === selectedRowIndex;
    }

    isColumnSelected(rowIndex, columnIndex, selectedRowIndex, selectedColumnIndex) {
      return rowIndex === selectedRowIndex && columnIndex === selectedColumnIndex;
    }

  }

  _exports.default = BaseCellItem;
});