define("adept-iq/services/reconcile", ["exports", "ember-concurrency", "moment", "adept-iq/utils/format-text-extension", "adept-iq/utils/format-function"], function (_exports, _emberConcurrency, _moment, _formatTextExtension, _formatFunction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcileRouteIds: null,
    reconcileRoute: null,
    reconcileSelectedRouteId: null,
    reconcileVehicle: null,
    reconcileDriver: null,
    reconcileDate: null,
    reconcileMode: null,
    reconcileProviderName: null,
    reconcileSelectedTripId: null,
    selectedSearchedTrip: null,
    showSearchPopUp: false,
    reconcileTaxiData: null,
    isRouteUpdated: false,
    isLoading: false,
    verifyRouteErrors: false,
    closePopupHandler: null,
    serviceModeTaxiType: 'Taxi',
    isDeletedRoute: Ember.computed.alias('reconcileRouteDetails.isDeleted'),
    isCompletedRoute: Ember.computed.alias('reconcileRouteDetails.isCompleted'),
    reconcilePassenger: Ember.computed.alias('reconcileTrip.passenger'),
    reconcilePrice: Ember.computed.alias('reconcileTrip.price'),
    reconcilePickupStop: Ember.computed('reconcileStops', function () {
      let pickupStop = {};
      const stops = Ember.get(this, 'reconcileStops');
      const pickupTypes = ['pickup', 'pick', 'p'];

      if (!Ember.isEmpty(stops)) {
        pickupStop = stops.filter(stop => stop.type && pickupTypes.includes(stop.type.toLowerCase()))[0];
      }

      return pickupStop;
    }),
    reconcileDropOffStop: Ember.computed('reconcileStops', function () {
      let dropOffStop = {};
      const stops = Ember.get(this, 'reconcileStops');
      const dropoffTypes = ['dropoff', 'drop', 'd'];

      if (!Ember.isEmpty(stops)) {
        dropOffStop = stops.filter(stop => stop.type && dropoffTypes.includes(stop.type.toLowerCase()))[0];
      }

      return dropOffStop;
    }),
    reconcilePickupAddress: Ember.computed('reconcileStops', function () {
      let pickupAddress = {};
      const stops = Ember.get(this, 'reconcileStops');
      const mode = Ember.get(this, 'reconcileMode');

      if (!Ember.isEmpty(stops)) {
        stops.map(stop => {
          if (stop.type === 'pickup') {
            if (mode === this.serviceModeTaxiType) {
              pickupAddress = stop.address;
            } else {
              pickupAddress = stop.get('address');
            }
          }
        });
      }

      return pickupAddress;
    }),
    reconcileDropOffAddress: Ember.computed('reconcileStops', function () {
      let dropOffAddress = {};
      const stops = Ember.get(this, 'reconcileStops');
      const mode = Ember.get(this, 'reconcileMode');

      if (!Ember.isEmpty(stops)) {
        stops.map(stop => {
          if (stop.type === 'dropoff') {
            if (mode === this.serviceModeTaxiType) {
              dropOffAddress = stop.address;
            } else {
              dropOffAddress = stop.get('address');
            }
          }
        });
      }

      return dropOffAddress;
    }),
    reconcileTrip: Ember.computed('reconcileSelectedTripId', function () {
      let tripData = {};
      const tripId = Ember.get(this, 'reconcileSelectedTripId');

      if (tripId) {
        const trips = Ember.get(this, 'reconcileTrips');

        if (!Ember.isEmpty(trips)) {
          tripData = trips.filter(trip => trip.tripId === tripId)[0];
        }
      }

      return tripData;
    }),
    reconcileStops: Ember.computed('reconcileTrip', function () {
      const trip = Ember.get(this, 'reconcileTrip');
      const stopDetails = [];
      const mode = Ember.get(this, 'reconcileMode');

      if (mode === this.serviceModeTaxiType) {
        if (!Ember.isEmpty(trip.stops)) {
          if (!Ember.isEmpty(trip.stops)) {
            trip.stops.map(stop => {
              stopDetails.push(stop);
            });
          }
        }

        return stopDetails;
      }

      if (!Ember.isEmpty(trip)) {
        const stops = Ember.get(this, 'store').peekAll('reconcile-stop').filter(stop => {
          return stop.tripId === trip.tripId;
        });

        if (!Ember.isEmpty(stops)) {
          stops.map(stop => {
            const addresses = Ember.get(this, 'store').peekAll('reconcile-address').filter(address => {
              return address.stopId === stop.stopId;
            })[0];
            stop.set('address', addresses);
            stopDetails.push(stop);
          });
        }
      }

      return stopDetails;
    }),
    reconcilePassengerDetails: Ember.computed('reconcileTrips', function () {
      const passengers = [];
      const trips = Ember.get(this, 'reconcileTrips');

      if (!Ember.isEmpty(trips)) {
        trips.map(trip => {
          if (trip && trip.passenger) {
            passengers.push(trip.passenger);
          }
        });
      }

      return passengers;
    }),
    reconcileTrips: Ember.computed('reconcileSelectedTripId', function () {
      const mode = Ember.get(this, 'reconcileMode');
      let trips = [];

      if (mode === this.serviceModeTaxiType) {
        const taxiData = Ember.get(this, 'reconcileTaxiData');

        if (!Ember.isEmpty(taxiData)) {
          taxiData.map(trip => {
            trip.set('passenger', trip.passenger);
            trip.set('price', trip.price);
            const stops = trip.stops;
            const stopDetails = [];

            if (!Ember.isEmpty(stops)) {
              stops.map(stop => {
                stopDetails.push(stop);
              });
              trip.set('stops', stopDetails);
            }
          });
          return taxiData;
        }
      } else {
        const selectedRouteId = Ember.get(this, 'reconcileSelectedRouteId');
        trips = Ember.get(this, 'store').peekAll('reconcile-trip').filter(trip => {
          return trip.routeId === selectedRouteId;
        });

        if (!Ember.isEmpty(trips)) {
          trips.map(trip => {
            const passengers = Ember.get(this, 'store').peekAll('reconcile-passenger').filter(passenger => {
              return passenger.passengerId === trip.passengerId;
            })[0];
            trip.set('passenger', passengers);
            const prices = Ember.get(this, 'store').peekAll('reconcile-price').filter(price => {
              return price.tripId === trip.tripId;
            })[0];
            trip.set('price', prices);
            const stops = Ember.get(this, 'store').peekAll('reconcile-stop').filter(stop => {
              return stop.tripId === trip.tripId;
            });
            const stopDetails = [];

            if (!Ember.isEmpty(stops)) {
              stops.map(stop => {
                const addresses = Ember.get(this, 'store').peekAll('reconcile-address').filter(address => {
                  return address.stopId === stop.stopId;
                })[0];
                stop.set('address', addresses);
                stopDetails.push(stop);
              });
              trip.set('stops', stopDetails);
            }
          });
          return trips;
        }
      }

      return [];
    }),
    isTripExists: Ember.computed('reconcileSelectedRouteId', function () {
      const selectedRouteId = Ember.get(this, 'reconcileSelectedRouteId');

      if (!selectedRouteId) {
        return false;
      }

      const trips = Ember.get(this, 'store').peekAll('reconcile-trip').filter(trip => {
        return trip.routeId === selectedRouteId;
      });
      return !Ember.isEmpty(trips);
    }),
    reconcileLateTrips: Ember.computed('reconcileHistoryRouteDetails', function () {
      const stopList = Ember.get(this, 'reconcileHistoryRouteDetails');
      const pendingStatusTypes = ['scheduled', 's'];
      const stopTypes = ['pickup', 'pick', 'p'];

      if (!Ember.isEmpty(stopList)) {
        const filteredData = stopList.filter(stop => !Ember.isEmpty(stop.tripId) && !Ember.isEmpty(stop.type) && stopTypes.includes(stop.type.toLowerCase()) && !Ember.isEmpty(stop.status) && pendingStatusTypes.includes(stop.status.toLowerCase()));
        filteredData.map(data => {
          data.otpCaption = parseInt(data.otpStatus, 10) === -1 ? 'Late' : 'Early';
        });
        return filteredData;
      }

      return [];
    }),
    reconcileRouteDetails: Ember.computed('isRouteUpdated', 'reconcileSelectedRouteId', 'updateRouteInformation', function () {
      const isUpdated = Ember.get(this, 'isRouteUpdated');
      const isOnlyUpdateRouteInfo = Ember.get(this, 'updateRouteInformation');

      if (!isUpdated && !isOnlyUpdateRouteInfo) {
        return [];
      }

      const selectedRouteId = Ember.get(this, 'reconcileSelectedRouteId');

      if (!selectedRouteId) {
        return [];
      }

      const route = Ember.get(this, 'store').peekRecord('reconcile-route', selectedRouteId);

      if (!route || !route.get('driverId') || !route.get('vehicleId')) {
        return [];
      }

      const driver = Ember.get(this, 'store').peekRecord('reconcile-driver', route.get('driverId'));
      const vehicle = Ember.get(this, 'store').peekRecord('reconcile-vehicle', route.get('vehicleId'));
      const start = Ember.get(this, 'store').peekRecord('reconcile-stop', 'GP' + selectedRouteId);
      const end = Ember.get(this, 'store').peekRecord('reconcile-stop', 'GD' + selectedRouteId);

      if (start) {
        const addresses = Ember.get(this, 'store').peekAll('reconcile-address').filter(address => {
          return address.stopId === start.stopId;
        })[0];
        start.set('address', addresses);
      }

      if (end) {
        const addresses = Ember.get(this, 'store').peekAll('reconcile-address').filter(address => {
          return address.stopId === end.stopId;
        })[0];
        end.set('address', addresses);
      }

      route.set('driver', driver);
      route.set('vehicle', vehicle);
      route.set('start', start);
      route.set('end', end);
      this.resetProperties();
      return route;
    }),
    reconcileHistoryRouteDetails: Ember.computed('isRouteUpdated', 'reconcileSelectedRouteId', function () {
      const isUpdated = Ember.get(this, 'isRouteUpdated');

      if (!isUpdated) {
        return;
      }

      const selectedRouteId = Ember.get(this, 'reconcileSelectedRouteId');
      const historyRouteRows = [];

      if (!selectedRouteId) {
        /* for taxi trips*/
        const taxiData = Ember.get(this, 'reconcileTaxiData');

        if (Ember.isEmpty(taxiData)) {
          return historyRouteRows;
        }

        taxiData.map(trip => {
          this.resetTravelNeedsOptions(trip);
          let travelNeeds = trip.travelNeeds;

          if (typeof travelNeeds === 'string') {
            travelNeeds = JSON.parse(travelNeeds);
          }

          if (travelNeeds) {
            travelNeeds.map(attribute => {
              this.setTravelNeedsToObj(attribute, trip);
            });
          }

          const stops = trip.stops;
          stops.map(stop => {
            stop.formattedEta = stop.eta ? (0, _moment.default)(stop.eta).format('MM/DD/YYYY HH:MM') : '';
            stop.formattedPromiseTime = stop.promiseTime ? (0, _moment.default)(stop.promiseTime).format('MM/DD/YYYY HH:MM') : '';
            stop.formattedActualArriveTime = stop.actualArriveTime ? (0, _moment.default)(stop.actualArriveTime).format('MM/DD/YYYY HH:MM') : '';
            stop.formattedActualDepartTime = stop.actualDepartTime ? (0, _moment.default)(stop.actualDepartTime).format('MM/DD/YYYY HH:MM') : '';
            stop.passenger = trip.get('passenger');
            this.setTravelNeedsNodeToObj(stop, trip);
            stop.formattedDate = stop.actualArriveTime ? (0, _moment.default)(stop.actualArriveTime).format('MM/DD/YYYY') : '';
            stop.address.lat = stop.address && stop.address.lat ? parseFloat(stop.address.lat).toFixed(2) : '';
            stop.address.lng = stop.address && stop.address.lat ? parseFloat(stop.address.lng).toFixed(2) : '';
            const taxiRowValues = this.getTaxiRowValues(trip, stop);
            historyRouteRows.push(taxiRowValues);
          });
        });
        return historyRouteRows;
      }

      const routeDetails = Ember.get(this, 'reconcileRouteDetails'); // set start garage

      const startGarage = Ember.get(this, 'store').peekRecord('reconcile-stop', 'GP' + selectedRouteId);
      let addresses = Ember.get(this, 'store').peekAll('reconcile-address').filter(address => {
        return address.stopId === startGarage.stopId;
      })[0];
      startGarage.set('address', addresses);
      const startGaragDetails = this.getRowValues(startGarage, 'Start Garage', routeDetails);

      if (startGaragDetails) {
        historyRouteRows.push(startGaragDetails);
      } // end start garage
      // set trips


      let stopDetails = null;
      const trips = Ember.get(this, 'store').peekAll('reconcile-trip').filter(trip => {
        return trip.routeId === selectedRouteId;
      });
      trips.map(trip => {
        const passengers = Ember.get(this, 'store').peekAll('reconcile-passenger').filter(passenger => {
          return parseInt(passenger.passengerId, 10) === parseInt(trip.passengerId, 10);
        })[0];
        const prices = Ember.get(this, 'store').peekAll('reconcile-price').filter(price => {
          return parseInt(price.tripId, 10) === parseInt(trip.tripId, 10);
        })[0];
        const stops = Ember.get(this, 'store').peekAll('reconcile-stop').filter(stop => {
          return parseInt(stop.tripId, 10) === parseInt(trip.tripId, 10);
        });
        this.resetTravelNeedsOptions(trip);

        if (trip.travelNeeds) {
          const usedAttr = trip.travelNeeds;

          if (usedAttr && typeof usedAttr === 'object') {
            usedAttr.map(attribute => {
              this.setTravelNeedsToObj(attribute, trip);
            });
          }
        }

        stops.map(stop => {
          if (stop) {
            addresses = Ember.get(this, 'store').peekAll('reconcile-address').filter(address => {
              return address.stopId === stop.stopId;
            })[0];
            stop.set('address', addresses);
            stop.set('passenger', passengers);
            stop.set('price', prices);
            stop.set('groupId', trip.groupId);
            this.setTravelNeedsNodeToObj(stop, trip);
            stopDetails = this.getRowValues(stop, 'Stop');

            if (stopDetails) {
              historyRouteRows.push(stopDetails);
            }
          }
        });
      }); //breaks

      const breaks = Ember.get(this, 'store').peekAll('reconcile-stop').filter(breakStop => {
        return breakStop.routeId === selectedRouteId && breakStop.type && breakStop.type.toLowerCase() === 'break';
      });

      if (breaks) {
        breaks.map(breakStop => {
          addresses = Ember.get(this, 'store').peekAll('reconcile-address').filter(address => {
            return address.stopId === breakStop.stopId;
          })[0];
          breakStop.set('address', addresses);
          stopDetails = this.getRowValues(breakStop, 'Break', routeDetails);

          if (stopDetails) {
            historyRouteRows.push(stopDetails);
          }
        });
      } // end trip
      // set end garage


      const endGarage = Ember.get(this, 'store').peekRecord('reconcile-stop', 'GD' + selectedRouteId);
      let endGarageDetails = null;
      addresses = Ember.get(this, 'store').peekAll('reconcile-address').filter(address => {
        return address.stopId === endGarage.id;
      })[0];
      endGarage.set('address', addresses);
      endGarageDetails = this.getRowValues(endGarage, 'End Garage', routeDetails);

      if (endGarageDetails) {
        historyRouteRows.push(endGarageDetails);
      } // end end garage


      return historyRouteRows;
    }),

    getTaxiRowValues(trip, stop) {
      let payload = null;
      const dropoffTypes = ['dropoff', 'drop', 'd'];
      const eta = stop.actualArriveTime; //stop.eta

      let stopTime = trip.pickupStopTime;
      let stopDetails = trip.pickupStopDetails;

      if (stop.type && dropoffTypes.includes(stop.type.toLowerCase())) {
        stopTime = trip.dropoffStopTime;
        stopDetails = trip.dropoffStopDetails;
      }

      payload = {
        stopId: stop.id,
        tripId: trip.tripId ? trip.tripId : null,
        groupId: trip.groupId ? trip.groupId : 0,
        clientName: trip.passenger ? trip.passenger.clientName : '',
        firstName: trip.passenger && trip.passenger.firstName ? trip.passenger.firstName.toUpperCase() : '',
        lastName: trip.passenger && trip.passenger.lastName ? trip.passenger.lastName.toUpperCase() : '',
        status: (0, _formatTextExtension.convertToCapitalize)(stop.status),
        type: stop.type,
        eta: eta ? (0, _moment.default)(eta).format('hh:mm A') : null,
        // stop.eta,
        etaDate: eta,
        target: stop.promiseTime ? (0, _moment.default)(stop.promiseTime).format('hh:mm A') : null,
        address: stop.address ? stop.address.formattedAddress : '',
        arriveTime: stop.actualArriveTime ? (0, _moment.default)(stop.actualArriveTime).format('hh:mm A') : null,
        arriveType: stop.arriveType ? stop.arriveType : null,
        performed: stop.performed ? stop.performed : null,
        ordinal: stop.ordinal,
        otpValue: stop.otpValue ? stop.otpValue : 0,
        // //editable lables
        actualTime: stop.actualArriveTime ? (0, _moment.default)(stop.actualArriveTime).format('hh:mm A') : null,
        actualArriveDateTime: stop.actualArriveTime ? stop.actualArriveTime : null,
        stopTime: stopTime ? stopTime : 0,
        actualDepartDateTime: stop.actualDepartTime ? stop.actualDepartTime : null,
        mileage: stopDetails.odometerArrival ? stopDetails.odometerArrival : 0,
        ambSeats: stop.ambSeats ? stop.ambSeats : 0,
        wcSeats: stop.wcSeats ? stop.wcSeats : 0,
        cmp: stop.cmp ? stop.cmp : 0,
        pca: stop.pca ? stop.pca : 0,
        fare: trip.price && trip.price.fare ? trip.price.fare : 0,
        otpStatus: stop.otpStatus && (stop.otpStatus.toLowerCase() === 'late' || parseInt(stop.otpStatus, 10) === -1) ? -1 : 0,
        loadTime: stop.loadTime ? stop.loadTime : 0,
        unloadTime: stop.unloadTime ? stop.unloadTime : 0,
        serviceAnimal: stop.serviceAnimal ? stop.serviceAnimal : 0,
        braces: !Ember.isEmpty(stop.braces) ? stop.braces : null,
        supportCane: !Ember.isEmpty(stop.supportCane) ? stop.supportCane : null,
        crutches: !Ember.isEmpty(stop.crutches) ? stop.crutches : null,
        doubleWheelchair: !Ember.isEmpty(stop.doubleWheelchair) ? stop.doubleWheelchair : null
      };
      return payload;
    },

    getRowValues(obj, stopType, routeDetails) {
      let payload = null;

      if (obj) {
        const address = obj.get('address');
        let actualStartTime = obj.get('actualArriveTime');
        const actualEndTime = obj.get('actualDepartTime');
        let target = obj.get('promiseTime');
        let tripId = obj.get('tripId');
        let routeId = obj.get('routeId');
        let odometer = obj.get('odometerArrival');
        let eta = actualStartTime; // obj.get('eta');

        let arriveTime = actualStartTime;
        let groupId = obj.get('groupId') ? obj.get('groupId') : 0;
        let status = obj.get('status');

        if (stopType === 'Start Garage') {
          actualStartTime = routeDetails.get('actualStartTime');
          routeId = routeDetails.get('routeId');
          odometer = routeDetails.get('odometerStart');
          target = routeDetails.get('plannedStartTime');
          eta = routeDetails.get('actualStartTime');
          arriveTime = routeDetails.get('actualStartTime');
          groupId = null;
          status = routeDetails.get('status');
        } else if (stopType === 'End Garage') {
          actualStartTime = routeDetails.get('actualEndTime');
          routeId = routeDetails.get('routeId');
          odometer = routeDetails.get('odometerEnd');
          target = routeDetails.get('plannedEndTime');
          eta = routeDetails.get('actualEndTime');
          arriveTime = routeDetails.get('actualEndTime');
          groupId = null;
          status = routeDetails.get('status');
        } else if (stopType === 'Break') {
          tripId = obj.id;
          routeId = routeDetails.get('routeId');
          groupId = null;
        }

        payload = {
          stopId: obj.get('stopId'),
          routeId: routeId,
          tripId: tripId ? tripId : null,
          groupId: groupId,
          clientName: obj.get('passenger') ? obj.get('passenger').clientName : '',
          firstName: obj.get('passenger') && obj.get('passenger').firstName ? obj.get('passenger').firstName.toUpperCase() : '',
          lastName: obj.get('passenger') && obj.get('passenger').lastName ? obj.get('passenger').lastName.toUpperCase() : '',
          status: (0, _formatTextExtension.convertToCapitalize)(status),
          type: obj.get('type'),
          eta: eta ? (0, _moment.default)(eta).format('hh:mm A') : null,
          etaDate: eta,
          target: target ? (0, _moment.default)(target).format('hh:mm A') : null,
          address: address.get('address'),
          arriveTime: arriveTime ? (0, _moment.default)(arriveTime).format('hh:mm A') : null,
          arriveType: obj.get('arriveType') ? obj.get('arriveType') : null,
          performed: obj.get('performed') ? obj.get('performed') : null,
          ordinal: obj.get('ordinal') ? obj.get('ordinal') : null,
          otpValue: obj.get('otpValue') ? obj.get('otpValue') : 0,
          //editable lables
          actualTime: actualStartTime ? (0, _moment.default)(actualStartTime).format('hh:mm A') : null,
          actualArriveDateTime: actualStartTime ? actualStartTime : null,
          stopTime: obj.get('stopTime') ? obj.get('stopTime') : 0,
          actualDepartDateTime: actualEndTime ? actualEndTime : null,
          mileage: odometer ? odometer : 0,
          ambSeats: obj.get('ambSeats') ? obj.get('ambSeats') : 0,
          wcSeats: obj.get('wcSeats') ? obj.get('wcSeats') : 0,
          cmp: obj.get('cmp') ? obj.get('cmp') : 0,
          pca: obj.get('pca') ? obj.get('pca') : 0,
          fare: obj.get('price') && obj.get('price').fare ? obj.get('price').fare : 0,
          otpStatus: obj.get('otpStatus') && (obj.get('otpStatus').toLowerCase() === 'late' || parseInt(obj.get('otpStatus'), 10) === -1) ? -1 : 0,
          loadTime: obj.get('loadTime') ? obj.get('loadTime') : 0,
          unloadTime: obj.get('unloadTime') ? obj.get('unloadTime') : 0,
          serviceAnimal: obj.get('serviceAnimal') ? obj.get('serviceAnimal') : 0,
          braces: !Ember.isEmpty(obj.get('braces')) ? obj.get('braces') : null,
          supportCane: !Ember.isEmpty(obj.get('supportCane')) ? obj.get('supportCane') : null,
          crutches: !Ember.isEmpty(obj.get('crutches')) ? obj.get('crutches') : null,
          doubleWheelchair: !Ember.isEmpty(obj.get('doubleWheelchair')) ? obj.get('doubleWheelchair') : null
        };
      }

      return payload;
    },

    unloadStore: (0, _emberConcurrency.task)(function* (modelName) {
      if (modelName) {
        yield Ember.get(this, 'store').unloadAll(modelName);
      } else {
        yield Ember.get(this, 'store').unloadAll('reconcile-route');
        yield Ember.get(this, 'store').unloadAll('reconcile-trip');
        yield Ember.get(this, 'store').unloadAll('reconcile-stop');
        yield Ember.get(this, 'store').unloadAll('reconcile-address');
        yield Ember.get(this, 'store').unloadAll('reconcile-passenger');
        yield Ember.get(this, 'store').unloadAll('reconcile-price');
        yield Ember.get(this, 'store').unloadAll('reconcile-used-attribute');
      }
    }),
    resetAndFetchRouteIds: (0, _emberConcurrency.task)(function* () {
      yield this.fetchRouteIds.perform();
      Ember.set(this, 'reconcileSelectedRouteId', null);
    }),
    fetchRouteIds: (0, _emberConcurrency.task)(function* (providerName, serviceMode, tripDate) {
      const routeIds = [];

      if (!providerName) {
        // eslint-disable-next-line no-param-reassign
        providerName = Ember.get(this, 'reconcileProviderName');
      }

      if (!serviceMode) {
        // eslint-disable-next-line no-param-reassign
        serviceMode = Ember.get(this, 'reconcileMode');
      }

      if (!tripDate) {
        // eslint-disable-next-line no-param-reassign
        tripDate = Ember.get(this, 'reconcileDate');
      }

      const dateRange = (0, _formatFunction.getDateRangeForSearch)(tripDate);
      const queryOptions = {
        providerName,
        serviceMode,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        all: true
      };

      try {
        this.get('fetchReconcileDriver').perform(providerName);
        this.get('fetchReconcileVehicle').perform(providerName);
        const routeIdsArray = yield Ember.get(this, 'store').query('reconcile-route-id', queryOptions);

        if (Ember.isEmpty(routeIdsArray)) {
          Ember.set(this, 'reconcileRouteIds', []);
          return;
        }

        routeIdsArray.map(data => {
          routeIds.push(data);
        });
      } catch (e) {
        let message;

        switch (e.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem in getting routes, please try again.';
            break;
        }

        this.get('notifications').warning(message);
      }

      Ember.set(this, 'reconcileRouteIds', routeIds);
      Ember.set(this, 'reconcileTaxiData', null);
    }),
    fetchRouteData: (0, _emberConcurrency.task)(function* (routeId) {
      if (!routeId) {
        // eslint-disable-next-line no-param-reassign
        routeId = Ember.get(this, 'reconcileSelectedRouteId');
      }

      Ember.set(this, 'isRouteUpdated', false);
      let routePayload = null;

      try {
        const routeData = yield Ember.get(this, 'store').query('reconcile-route', {
          routeId
        });

        if (Ember.isEmpty(routeData)) {
          Ember.set(this, 'reconcileRoute', []);
          Ember.set(this, 'isRouteUpdated', true);
          return;
        }

        routeData.map(data => {
          routePayload = data;
        });
      } catch (e) {
        let message;

        switch (e.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem in getting route details, please try again.';
            break;
        }

        this.get('notifications').warning(message);
      }

      Ember.set(this, 'reconcileTaxiData', null);
      Ember.set(this, 'reconcileSelectedRouteId', routeId);
      Ember.set(this, 'reconcileRoute', routePayload);
      Ember.set(this, 'isRouteUpdated', true);
    }),
    fetchTaxiData: (0, _emberConcurrency.task)(function* () {
      try {
        const taxiPayload = [];
        const serviceMode = Ember.get(this, 'reconcileMode').toLowerCase();
        const tripDate = Ember.get(this, 'reconcileDate');
        const dateRange = (0, _formatFunction.getDateRangeForSearch)(tripDate);
        const queryOptions = {
          serviceMode,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        };
        Ember.set(this, 'isRouteUpdated', false);
        const taxiData = yield Ember.get(this, 'store').query('reconcile-trip', queryOptions);

        if (Ember.isEmpty(taxiData)) {
          Ember.set(this, 'reconcileTaxiData', []);
          Ember.set(this, 'isRouteUpdated', true);
          return;
        }

        taxiData.map(data => {
          taxiPayload.push(data);
        });
        Ember.set(this, 'reconcileTaxiData', taxiPayload);
        Ember.set(this, 'isRouteUpdated', true);
      } catch (e) {
        let message;

        switch (e.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem in getting taxi trips, please try again.';
            break;
        }

        this.get('notifications').warning(message);
      }
    }),
    fetchReconcileVehicle: (0, _emberConcurrency.task)(function* (provider) {
      try {
        const vehicleIds = [];
        const vehicleArray = yield Ember.get(this, 'store').query('reconcile-vehicle', {
          providerName: provider
        });

        if (Ember.isEmpty(vehicleArray)) {
          Ember.set(this, 'reconcileVehicle', []);
          return;
        }

        vehicleArray.map(data => {
          vehicleIds.push(data);
        });
        Ember.set(this, 'reconcileVehicle', vehicleIds);
      } catch (e) {
        let message;

        switch (e.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem in getting vehicles, please try again.';
            break;
        }

        this.get('notifications').warning(message);
      }
    }),
    fetchReconcileDriver: (0, _emberConcurrency.task)(function* (provider) {
      try {
        const driverIds = [];
        const driverArray = yield Ember.get(this, 'store').query('reconcile-driver', {
          providerName: provider
        });

        if (Ember.isEmpty(driverArray)) {
          Ember.set(this, 'reconcileDriver', []);
          return;
        }

        driverArray.map(data => {
          driverIds.push(data);
        });
        Ember.set(this, 'reconcileDriver', driverIds);
      } catch (e) {
        let message;

        switch (e.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem in getting drivers, please try again.';
            break;
        }

        this.get('notifications').warning(message);
      }
    }),

    resetProperties() {
      Ember.set(this, 'updateRouteInformation', false);
    },

    closePopups() {
      if (this.get('workspace.stackDepth')) {
        this.get('workspace').popState();
      }
    },

    setTravelNeedsNodeToObj(source, destination) {
      if (source && destination) {
        if (Ember.get(this, 'reconcileMode') === this.serviceModeTaxiType) {
          source.ambSeats = destination.get('ambSeats');
          source.wcSeats = destination.get('wcSeats');
          source.cmp = destination.get('cmp');
          source.pca = destination.get('pca');
          source.serviceAnimal = destination.get('serviceAnimal');
          source.loadTime = destination.get('loadTime');
          source.unloadTime = destination.get('unloadTime');
          source.braces = destination.get('braces');
          source.supportCane = destination.get('supportCane');
          source.crutches = destination.get('crutches');
          source.doubleWheelchair = destination.get('doubleWheelchair');
        } else {
          source.set('pca', destination.get('pca'));
          source.set('cmp', destination.get('cmp'));
          source.set('wcSeats', destination.get('wcSeats'));
          source.set('ambSeats', destination.get('ambSeats'));
          source.set('serviceAnimal', destination.get('serviceAnimal'));
          source.set('loadTime', destination.get('loadTime'));
          source.set('unloadTime', destination.get('unloadTime'));
          source.set('braces', destination.get('braces'));
          source.set('supportCane', destination.get('supportCane'));
          source.set('crutches', destination.get('crutches'));
          source.set('doubleWheelchair', destination.get('doubleWheelchair'));
        }
      }
    },

    setTravelNeedsToObj(travelNeedsObj, destinationObj) {
      if (travelNeedsObj && destinationObj) {
        if (travelNeedsObj.name === 'ambulatory') {
          destinationObj.set('ambSeats', travelNeedsObj.cnt);
        }

        if (travelNeedsObj.name === 'wheelChair') {
          destinationObj.set('wcSeats', travelNeedsObj.cnt);
        }

        if (travelNeedsObj.name === 'companion') {
          destinationObj.set('cmp', travelNeedsObj.cnt);
        }

        if (travelNeedsObj.name === 'pca') {
          destinationObj.set('pca', travelNeedsObj.cnt);
        }

        if (travelNeedsObj.name === 'serviceAnimal') {
          destinationObj.set('serviceAnimal', travelNeedsObj.cnt);
        }

        if (travelNeedsObj.name === 'loadTime') {
          destinationObj.set('loadTime', travelNeedsObj.cnt);
        }

        if (travelNeedsObj.name === 'unloadTime') {
          destinationObj.set('unloadTime', travelNeedsObj.cnt);
        }

        if (travelNeedsObj.name === 'braces') {
          destinationObj.set('braces', travelNeedsObj.cnt);
        }

        if (travelNeedsObj.name === 'supportCane') {
          destinationObj.set('supportCane', travelNeedsObj.cnt);
        }

        if (travelNeedsObj.name === 'crutches') {
          destinationObj.set('crutches', travelNeedsObj.cnt);
        }

        if (travelNeedsObj.name === 'doubleWheelchair') {
          destinationObj.set('doubleWheelchair', travelNeedsObj.cnt);
        }
      }
    },

    resetTravelNeedsOptions(obj) {
      obj.set('ambSeats', 0);
      obj.set('wcSeats', 0);
      obj.set('cmp', 0);
      obj.set('pca', 0);
      obj.set('serviceAnimal', 0);
      obj.set('loadTime', 0);
      obj.set('unloadTime', 0);
      obj.set('braces', null);
      obj.set('supportCane', null);
      obj.set('crutches', null);
      obj.set('doubleWheelchair', null);
    }

  });

  _exports.default = _default;
});