define("adept-iq/models/booking", ["exports", "ember-data", "moment", "adept-iq/config/mapped-status"], function (_exports, _emberData, _moment, _mappedStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;
  const {
    attr
  } = _emberData.default;

  var _default = Model.extend({
    subscription: belongsTo('subscription'),
    legs: hasMany('leg'),
    cancellation: belongsTo('cancellation'),
    provider: belongsTo('provider'),
    group: belongsTo('group'),
    trips: hasMany('trip'),
    isCancelled: Ember.computed('cancellation', 'legs.[]', 'trips', 'subscription', function () {
      const cancellation = this.get('cancellation');

      if (cancellation && cancellation.get('id')) {
        return true;
      }

      return false;
    }),
    status: attr(),
    isSameDayBooking: Ember.computed('legs.[].requestTime', function () {
      const today = (0, _moment.default)().startOf('day');
      const endToday = (0, _moment.default)().endOf('day');

      if (!Ember.isEmpty(this.get('legs.firstObject.requestTime'))) {
        const legTime = (0, _moment.default)(this.get('legs').firstObject.get('requestTime'));
        return legTime.isBetween(today, endToday);
      }

      return null;
    }),
    firstLegRequestTime: Ember.computed('legs.[].requestTime', function () {
      if (!Ember.isEmpty(this.get('legs.firstObject.requestTime'))) {
        const legTime = (0, _moment.default)(this.get('legs').firstObject.get('requestTime'));
        return legTime;
      }

      return null;
    }),
    // format equipment per each leg
    equipments: Ember.computed('legs.[].equipments', function () {
      const legs = this.get('legs');
      const equipments = [];

      if (!Ember.isEmpty(legs)) {
        legs.forEach(leg => {
          const legEquipments = leg.get('equipments');
          equipments.push(...legEquipments);
        });
      }

      return equipments;
    }),
    tripStartTime: Ember.computed('legs.[]', 'trips.@each.routeTripStartTime', function () {
      const legs = this.get('legs');
      const trips = this.get('trips');
      let min = null;

      if (legs.firstObject) {
        min = legs.firstObject.get('requestTime').getTime();
      }

      if (trips.firstObject) {
        min = trips.firstObject.get('routeTripStartTime');
      }

      if (!Ember.isEmpty(legs)) {
        legs.forEach(leg => {
          min = Math.min(min, leg.get('requestTime').getTime());
        });
      }

      if (!Ember.isEmpty(trips)) {
        trips.forEach(trip => {
          const time = trip.get('routeTripStartTime');
          if (time) min = Math.min(min, time.getTime());
        });
      }

      return min ? new Date(min) : null;
    }),
    tripEndTime: Ember.computed('legs.[]', 'trips.@each.routeTripStartTime', function () {
      const legs = this.get('legs');
      const trips = this.get('trips');
      let max = null;

      if (legs.firstObject) {
        max = legs.firstObject.get('requestTime');
      }

      if (trips.firstObject) {
        max = trips.firstObject.get('routeTripEndTime');
      }

      if (!Ember.isEmpty(legs)) {
        legs.forEach(leg => {
          max = Math.max(max, leg.get('requestTime').getTime());
        });
      }

      if (!Ember.isEmpty(trips)) {
        trips.forEach(trip => {
          const time = trip.get('routeTripEndTime');
          if (time) max = Math.max(max, time.getTime());
        });
      }

      return max ? new Date(max) : null;
    }),
    firstLeg: Ember.computed('legs.[]', function () {
      return this.get('legs').firstObject;
    }),
    firstTrip: Ember.computed('trips.[]', function () {
      return this.get('trips').firstObject;
    }),
    firstSegmentInFistLeg: Ember.computed('firstLeg', function () {
      return this.get('firstLeg.segments').firstObject;
    }),
    requestTimeForDisplay: Ember.computed('firstLeg', function () {
      const firstLeg = this.get('firstLeg');
      const anchor = firstLeg.get('anchor');
      const requestTimeObject = firstLeg.get('requestTime');
      const requestDate = (0, _moment.default)(requestTimeObject).format('yyyy-MM-DD');
      const requestTime = (0, _moment.default)(requestTimeObject).format('hh:mm A');
      return `${requestDate} / ${anchor} / ${requestTime}`;
    }),
    isGroupBooking: Ember.computed('group', function () {
      if (this.get('group.id')) {
        return true;
      }

      return false;
    }),
    isGroupBookingDisplayText: Ember.computed('isGroupBooking', function () {
      if (this.get('isGroupBooking')) {
        return 'Yes';
      }

      return 'No';
    }),
    computedStatus: Ember.computed('status', function () {
      const result = _mappedStatus.convertBookingStatus[this.get('status')];

      return result ? result : this.get('status');
    })
  });

  _exports.default = _default;
});