define("adept-iq/serializers/cs-config-model", ["exports", "adept-iq/serializers/cs-config-item", "lodash"], function (_exports, _csConfigItem, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigItem.default.extend({
    // TODO: can we infer this?
    modelName: null,

    normalizeSingleResponse(store, primaryModelClass, payload, id
    /*, requestType */
    ) {
      const normalized = this._super(...arguments);

      const modelName = this.get('modelName');
      if (!modelName) return normalized;
      normalized.included = normalized.included || [];
      const attributes = Ember.get(normalized, 'data.attributes.value') ? _lodash.default.cloneDeep(normalized.data.attributes.value) : {}; // copy in `name` property from config item if not present in `value`

      attributes.name = attributes.name || normalized.data.attributes.name;
      const data = {
        id,
        type: modelName,
        attributes: this.normalizeAttributes(attributes, normalized.data)
      };

      if (primaryModelClass.modelName === modelName) {
        normalized.included.push(normalized.data);
        normalized.data = data;
      } else {
        normalized.included.push(data);
      }

      return normalized;
    },

    normalizeArrayResponse()
    /* store, primaryModelClass, payload, id, requestType */
    {
      const normalized = this._super(...arguments);

      const modelName = this.get('modelName');
      if (!modelName || !normalized.data) return normalized;
      normalized.included = normalized.included || [];
      normalized.data = normalized.data || [];
      normalized.included.push(...normalized.data);
      normalized.data = normalized.data.map(datum => {
        const modelId = datum.id.split('/').get('lastObject'); // because this is a config-model, it should have an _object_ value

        const attributes = Ember.get(datum, 'attributes.value') ? _lodash.default.cloneDeep(datum.attributes.value) : {}; // copy in `name` property from config item if not present in `value`

        attributes.name = attributes.name || datum.attributes.name;

        if (!datum.attributes.category === 'config-System_Configuration-travel_need_types') {
          attributes.displayName = datum.attributes.displayName;
        }

        return {
          id: modelId,
          type: modelName,
          attributes: this.normalizeAttributes(attributes, datum)
        };
      });
      return normalized;
    },

    // overload this in subclass to re-organize attributes
    normalizeAttributes(attributes
    /*, data */
    ) {
      return attributes;
    }

  });

  _exports.default = _default;
});