define("adept-iq/serializers/subscription-travel-need", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments); // json.data.type = 'riderTravelNeed';


      if (json.data && json.data.attributes) {
        delete json.data.attributes.travelNeedTypeName;
        delete json.data.attributes.passengerTypeName;
      }

      if (json.data && json.data.relationships) {
        const travelNeedTypeRelationship = json.data.relationships.travelNeedType;

        if (!Ember.isNone(travelNeedTypeRelationship)) {
          travelNeedTypeRelationship.data.type = 'travelNeedTypeName';
          json.data.relationships.travelNeedTypeName = json.data.relationships.travelNeedType;
          delete json.data.relationships.travelNeedType;
        }

        const passengerTypeRelationship = json.data.relationships.passengerType;

        if (!Ember.isNone(passengerTypeRelationship)) {
          passengerTypeRelationship.data.type = 'passengerTypeName';
          json.data.relationships.passengerTypeName = json.data.relationships.passengerType;
          delete json.data.relationships.passengerType;
        }
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes)) {
        if (!Ember.isNone(resourceHash.attributes.travelNeedTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.travelNeedType = {
            data: {
              type: 'travel-need-type',
              id: resourceHash.attributes.travelNeedTypeName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.travelNeedTypeName;
        }

        if (!Ember.isNone(resourceHash.attributes.passengerTypeName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.passengerType = {
            data: {
              type: 'passenger-type',
              id: resourceHash.attributes.passengerTypeName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.passengerTypeName;
        }
      }

      if (!Ember.isNone(resourceHash.relationships.travelNeedTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.travelNeedType = {
          data: {
            type: 'travel-need-type',
            id: resourceHash.relationships.travelNeedTypeName.data.id
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.relationships.travelNeedTypeName;
      }

      if (!Ember.isNone(resourceHash.relationships.passengerTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.passengerType = {
          data: {
            type: 'passenger-type',
            id: resourceHash.relationships.passengerTypeName.data.id
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.relationships.passengerTypeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});