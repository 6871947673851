define("adept-iq/pods/components/generic-components/number-format/component", ["exports", "adept-iq/utils/regex-functions"], function (_exports, _regexFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['ember-text-field-holder'],
    value: null,
    disabled: false,
    extra: null,
    placeholder: null,
    oldVal: null,
    numberFormat: _regexFunctions.REGEX_NUMBER,
    removedNumberFormat: _regexFunctions.REGEX_REMOVE_DECIMAL_FORMAT,
    min: readOnly('extra.min'),
    max: readOnly('extra.max'),
    isFloat: readOnly('extra.isFloat'),
    onChange: null,
    digitPrecision: readOnly('extra.digitPrecision'),

    didInsertElement() {
      this._super(...arguments);

      const isFloat = this.get('isFloat');

      if (isFloat) {
        this.set('numberFormat', _regexFunctions.REGEX_FLOAT);
        this.set('removedNumberFormat', _regexFunctions.REGEX_REMOVE_TEXT_FORMAT);
      }
    },

    willDestroy() {
      this.set('oldVal', null);

      this._super(...arguments);
    },

    actions: {
      onInput(value) {
        let val = value;
        const onChange = this.get('onChange');
        const numFormat = this.get('numberFormat');
        const removedNumberFormat = this.get('removedNumberFormat');
        const numVal = parseInt(value, 10);
        const oldVal = this.get('oldVal');
        const min = this.get('min');
        const max = this.get('max');
        const digitPrecision = this.get('digitPrecision');
        const isFloat = this.get('isFloat'); // Automatic removal of the character when the user enters  wrong input other than numbers, so no need for old value

        if (!numFormat.test(value)) {
          if (onChange) {
            this.get('onChange')(value.replace(removedNumberFormat, ''));
          }

          return this.notifyPropertyChange('value');
        } // Reset input value to prior value unless changed value matches format.


        if (min && numVal < min) {
          if (oldVal && onChange) {
            this.get('onChange')(oldVal);
          }

          return this.notifyPropertyChange('value');
        }

        if (isFloat && digitPrecision) {
          if (val.includes('.')) {
            const decimalValues = val.split('.'); // Prevents entering more than one decimal point('.')

            if (decimalValues.length > 2) {
              const index = val.indexOf('.') + 1;
              val = val.substr(0, index);
              this.get('onChange')(val);
              return this.notifyPropertyChange('value');
            }

            const decimalValue = decimalValues[1];
            decimalValues[1] = decimalValue.slice(0, 2);
            val = decimalValues.join('.');

            if (decimalValue.length > digitPrecision) {
              this.get('onChange')(val);
              return this.notifyPropertyChange('value');
            }
          }
        }

        if (value.length > max) {
          if (oldVal && onChange) {
            this.get('onChange')(oldVal);
          }

          return this.notifyPropertyChange('value');
        }

        if (isNaN(numVal)) {
          this.set('oldVal', '');
          this.get('onChange')('');
          return '';
        }

        if (onChange) {
          this.get('onChange')(value);
        }

        this.set('oldVal', value);
        return value;
      }

    }
  });

  _exports.default = _default;
});