define("adept-iq/serializers/cs-config-item", ["exports", "adept-iq/serializers/-cs-configService"], function (_exports, _csConfigService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line no-undef
  const _ = require("lodash");

  const JSON_REGEX = /^\s*[{[]/;
  const OPTIONAL_ATTRS = ['description', 'unit', 'type'];
  const CATEGORY_DELIMITER = '-';
  const BOOLEAN_TYPE = 'Boolean';
  const TRUE_STRING = 'Yes';
  const FALSE_STRING = 'No';
  const logger = {
    warn: console.warn // eslint-disable-line no-console

  }; // you must register any model that is serialized to a config item here

  const modelNameForCategory = {
    'config-Scheduling-schedule_configurations': 'schedule-configuration',
    'config-Scheduling-service_windows': 'service-window',
    'config-System_Configuration-Activity_Log_Types-trip': 'trip-activity-log-type',
    'config-Activity_Log_Types-vehicle': 'vehicle-activity-log-type',
    'config-System_Configuration-Activity_Log_Types-route': 'route-activity-log-type',
    'config-Activity_Log_Types-user': 'user-activity-log-type',
    'config-System_Configuration-break_types': 'break-type',
    'config-System_Configuration-cancel_types': 'cancel-type',
    'config-System_Configuration-eligibility_types': 'eligibility-type',
    'config-System_Configuration-fare_types': 'fare-type',
    'config-System_Configuration-no_show_reason_codes': 'no-show-reason-code',
    'config-System_Configuration-passenger_types': 'passenger-type',
    'config-System_Configuration-place_category_types': 'place-category-type',
    'config-System_Configuration-providers': 'provider',
    'config-System_Configuration-travel_need_types': 'travel-need-type',
    'config-System_Configuration-vehicle_capacity_configs': 'vehicle-capacity-config',
    'config-System_Configuration-vehicle_capacity_types': 'vehicle-capacity-type',
    'config-System_Configuration-vehicle_types': 'vehicle-type',
    'config-System_Configuration-breakdown_types': 'breakdown-type',
    'config-System_Configuration-zones': 'zone-type',
    'config-System_Configuration-rider_place_types': 'rider-place-type',
    'config-System_Configuration-provider_types': 'provider-type',
    'config-System_Configuration-driver_physical_types': 'driver-physical-type',
    'config-System_Configuration-driver_positions': 'driver-position',
    'config-System_Configuration-driver_statuses': 'driver-statuses',
    'config-System_Configuration-vehicle_inspector_categories': 'inspection-category',
    'config-System_Configuration-vehicle_maint_types': 'maintenance-type-name',
    'config-System_Configuration-vehicle_maint_statuses': 'maintenance-status-name',
    'config-System_Configuration-vehicle_maint_locations': 'maintenance-location-name',
    'config-System_Configuration-vehicle_problem_identified': 'problem-identified-name',
    'config-System_Configuration-vehicle_inspectors': 'inspection-provider-name',
    'config-System_Configuration-CDL_classifications': 'classification-name',
    'config-System_Configuration-vehicle_road_call_codes': 'road-call-code-name',
    'config-System_Configuration-typeOfDate': 'exam-type-name',
    'config-System_Configuration-vehicle_manufacturers': 'vehicle-manufacturer-name',
    'config-System_Configuration-vehicle_statuses': 'vehicle-status-name',
    'config-System_Configuration-vehicle_lease_companies': 'lease-company',
    'config-System_Configuration-termination_reasons': 'driver-termination-reason',
    'config-System_Configuration-vehicle_body_types': 'vehicle-body-type',
    'config-System_Configuration-vehicle_fuel_caps': 'vehicle-fuel-cap',
    'config-System_Configuration-vehicle_chassis': 'vehicle-chassis',
    'config-System_Configuration-vehicle_body_widths': 'vehicle-body-width',
    'config-System_Configuration-no_show_reason_categories': 'no-show-reason-category',
    'config-Zip_Codes': 'zip-code'
  }; // merges arr2 onto arr1 in place

  const mergeRecordsInPlace = (arr1, arr2) => {
    const lookup = {};
    arr1.forEach(a => {
      lookup[`${a.type}-${a.id}`] = true;
    });
    arr2.forEach(b => {
      const id = `${b.type}-${b.id}`;
      if (lookup[id]) return;
      lookup[id] = true;
      arr1.push(b);
    });
    return arr1;
  };

  var _default = _csConfigService.default.extend({
    fixedRouteEngine: Ember.inject.service(),
    widget: Ember.inject.service(),
    attrs: {
      // do not list `type` or `value` here; these are need in `serialize()`
      category: {
        serialize: false
      },
      configCategory: {
        serialize: false
      },
      fieldConstraint: {
        serialize: false
      }
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      // this occurs when the client specifically asks for cs-config-items
      //   i.e. `store.findAll('cs-config-item')`
      // in this case, we need to substitute the correct serializer for any
      // config-items that correspond to config-models (e.g. vehicly-type) in IQUX
      if (primaryModelClass.modelName === 'cs-config-item') {
        const normalized = {
          data: [],
          included: []
        }; // split payloads into sets by config-model name

        const separatedData = Ember.makeArray(payload.data).reduce((obj, datum) => {
          const [categoryId] = datum.id.split('/');
          const modelName = modelNameForCategory[categoryId] || 'cs-config-item';

          if (modelName) {
            obj[modelName] = obj[modelName] || [];
            obj[modelName].push(datum);
          }

          return obj;
        }, {});
        Object.entries(separatedData).forEach(([modelName, dataPayloads]) => {
          if (modelName !== 'cs-config-item') {
            // these config items back IQUX models (e.g. vehicle-type)
            const serializer = store.serializerFor(modelName);
            const modelClass = store.modelFor(modelName);
            const normed = serializer.normalizeArrayResponse(store, modelClass, {
              data: dataPayloads
            }, id, requestType);
            normed.data = normed.data || [];
            normed.included = normed.included || []; // because we used a specific serializer, the models will be in "data"
            // and the config-items will be in "included"; we need to swap these
            // because the client requested records of type `cs-config-item`

            mergeRecordsInPlace(normalized.data, normed.included.filterBy('type', 'cs-config-item'));
            mergeRecordsInPlace(normalized.included, normed.data);
            mergeRecordsInPlace(normalized.included, normed.included.rejectBy('type', 'cs-config-item'));
            return;
          } // these are just generic config-items, so process them normally


          const normed = this._super(store, primaryModelClass, {
            data: dataPayloads
          }, id, requestType);

          const augmented = this._addCategoriesToArrayPayload(normed);

          mergeRecordsInPlace(normalized.data, augmented.data);
          mergeRecordsInPlace(normalized.included, augmented.included);
        });
        return normalized;
      } // if execution reaches here, it is usually because a config-model (e.g.
      // vehicle-type) serializer called `this._super(...)`


      const normalized = this._super(...arguments);

      const augmented = this._addCategoriesToArrayPayload(normalized);

      return augmented;
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      if (primaryModelClass.modelName === 'cs-config-item' && requestType !== 'createRecord') {
        const currentId = payload.data.id;
        const [categoryId, modelId] = currentId.split('/');
        const modelName = modelNameForCategory[categoryId]; // try to substitute model-specific serializer

        if (modelName) {
          const serializer = store.serializerFor(modelName);
          const modelClass = store.modelFor(modelName); // by invoking the specific serializer, the specific model will be "data"

          const normalized = serializer.normalizeSingleResponse(store, modelClass, payload, modelId, requestType); // swap so that the generic config item is the "data" and the specific
          // model is in "included"

          const configItem = normalized.included.find(hash => {
            return hash.id === currentId && hash.type === 'cs-config-item';
          });
          normalized.included.removeObject(configItem);
          normalized.included.push(normalized.data);
          normalized.data = configItem;
          return normalized;
        }
      } // process as a normal config-item


      const normalized = this._super(...arguments);

      const augmented = this._addCategoriesToSinglePayload(normalized);

      return augmented;
    },

    normalize() {
      const normalized = this._super(...arguments); // extract category and name from id


      const {
        id
      } = normalized.data;
      const segments = id.split('/');
      const name = segments[segments.length - 1];
      const category = segments.slice(0, segments.length - 1).join('/');
      normalized.data.attributes.name = name;
      normalized.data.attributes.category = category;
      const {
        type,
        value
      } = normalized.data.attributes;

      if (Ember.typeOf(value) === 'string' && JSON_REGEX.test(value)) {
        try {
          normalized.data.attributes.value = JSON.parse(value);
        } catch (err) {
          logger.warn('failed to parse JSON-like value', value);
          normalized.data.attributes.value = {}; // empty hash gracefully protect ui component
        }
      } // booleans are encoded as "Yes" or "No"


      if (type === BOOLEAN_TYPE) {
        normalized.data.attributes.value = value === TRUE_STRING;
      }

      return normalized;
    },

    serialize()
    /* snapshot, options */
    {
      const serialized = this._super(...arguments);

      OPTIONAL_ATTRS.forEach(attr => {
        if (Ember.isEmpty(serialized.data.attributes[attr])) {
          delete serialized.data.attributes[attr];
        }
      });
      const {
        name,
        displayName,
        type,
        value
      } = serialized.data.attributes;

      if (!name) {
        const camelizedName = Ember.String.camelize(displayName);
        serialized.data.attributes.name = camelizedName.replace(/[_\W]+/g, '_');
      }

      if (type === BOOLEAN_TYPE) {
        serialized.data.attributes.value = value ? TRUE_STRING : FALSE_STRING;
      } else if (Ember.typeOf(value) !== 'string') {
        // values must always be strings
        serialized.data.attributes.value = JSON.stringify(value);
      } // API doesn't accept relationships hash at all


      delete serialized.data.relationships;
      return serialized;
    },

    _addCategoriesToArrayPayload(payload) {
      payload.data = payload.data || [];
      payload.included = payload.included || [];
      payload.data.forEach(datum => {
        const categoryPayloads = this._buildCategoryPayloads(datum);

        mergeRecordsInPlace(payload.included, categoryPayloads);
        datum.relationships = datum.relationships || {};
        datum.relationships.configCategory = {
          data: {
            id: categoryPayloads[categoryPayloads.length - 1].id,
            type: 'cs-config-category'
          }
        };
      });
      return payload;
    },

    _addCategoriesToSinglePayload(payload) {
      payload.included = payload.included || [];

      const categoryPayloads = this._buildCategoryPayloads(payload.data);

      mergeRecordsInPlace(payload.included, categoryPayloads);
      payload.data = payload.data || {};
      payload.data.relationships = payload.data.relationships || {};
      payload.data.relationships.configCategory = {
        data: {
          id: categoryPayloads[categoryPayloads.length - 1].id,
          type: 'cs-config-category'
        }
      };
      return payload;
    },

    _buildCategoryPayloads(payload) {
      payload.attributes = payload.attributes || {};
      payload.attributes.category = payload.attributes.category || '';
      const segments = payload.attributes.category.split(CATEGORY_DELIMITER); // the last payload will be the most specific

      return segments.map((segment, i) => {
        const newPayload = {
          id: segments.slice(0, i + 1).join(CATEGORY_DELIMITER),
          type: 'cs-config-category',
          attributes: {
            name: segment,
            depth: i
          },
          relationships: {}
        };

        if (i > 0) {
          newPayload.relationships.parent = {
            data: {
              id: segments.slice(0, i).join(CATEGORY_DELIMITER),
              type: 'cs-config-category'
            }
          };
        }

        return newPayload;
      });
    },

    normalizeQueryResponse(store, clazz, payload) {
      if (this.get('widget.isConfigLoaded')) {
        const fixedRouteParams = this.get('fixedRouteEngine.fixedRouteEngineParams');
        if (_.isEmpty(fixedRouteParams)) return this._super(store, clazz, payload);
        payload.data.forEach(datum => {
          const [categoryId] = datum.id.split('/');
          const fixedRouteEngineId = 'config-Fixed_Route_Engine_Parameters';

          if (categoryId === fixedRouteEngineId && !Ember.isEmpty(fixedRouteParams)) {
            const FRESParams = fixedRouteParams[datum.attributes.displayName];
            datum.attributes.value = FRESParams.value;
            datum.attributes.defaultValue = FRESParams.defaultValue;
          }
        });
      }

      return this._super(store, clazz, payload);
    }

  });

  _exports.default = _default;
});