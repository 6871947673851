define("adept-iq/pods/components/workspace-navbar-reconcile/component", ["exports", "adept-iq/pods/components/workspace-navbar-reconcile/stub", "moment", "adept-iq/config/mapped-permIds"], function (_exports, _stub, _moment, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SERVICE_MODE_PARATRANSIT = 'Paratransit / Broker';

  var _default = Ember.Component.extend({
    classNames: ['workspace-navbar'],
    workspace: Ember.inject.service(),
    store: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    timeFormat: 'm-d-Y',
    serviceModeTaxiType: 'Taxi',
    serviceModeEnabled: Ember.computed.empty('reconcile.reconcileDate'),
    disableAddRouteOption: Ember.computed.empty('selectedProvider'),
    providerOptionEnabled: Ember.computed('selectedServiceMode', function () {
      const selectedServiceMode = this.get('selectedServiceMode');
      return !!(Ember.isEmpty(selectedServiceMode) || selectedServiceMode === this.serviceModeTaxiType);
    }),
    routeOptionEnabled: Ember.computed.empty('selectedProvider'),
    providerOptions: Ember.computed('selectedProvider', function () {
      return this.get('store').peekAll('provider');
    }),
    serviceModeOptions: Ember.computed('selectedServiceMode', function () {
      return _stub.default.serviceModes;
    }),
    routeOptions: Ember.computed('reconcile.reconcileRouteIds', function () {
      const routeIds = this.get('reconcile.reconcileRouteIds');
      return Ember.isEmpty(routeIds) ? [] : routeIds;
    }),
    searchOptions: Ember.computed('selectedSearchMode', function () {
      const searchModes = _stub.default.searchOptions;
      return this.enableDisableOption(searchModes);
    }),

    enableDisableOption(options) {
      const optionArray = [];
      options.map(opt => {
        const option = {
          disabled: false,
          name: opt
        };

        switch (opt) {
          case 'Client':
            option.disabled = !this.isSearchByClientAllowed;
            break;

          case 'Driver':
            option.disabled = !this.isSearchByDriverAllowed;
            break;

          case 'Vehicle':
            option.disabled = !this.isSearchByVehicleAllowed;
            break;

          case 'Address':
            option.disabled = !this.isSearchByAddressAllowed;
            break;

          case 'Trip':
            option.disabled = !this.isSearchByTripAllowed;
            break;

          default:
            break;
        }

        optionArray.push(option);
      });
      return optionArray;
    },

    updatePanel: Ember.observer('reconcile.selectedSearchedTrip', function () {
      const selectedRowData = Ember.get(this, 'reconcile.selectedSearchedTrip');
      Ember.set(this, 'selectedSearchMode', null);

      if (selectedRowData) {
        this.send('onChangeTripDate', 'selectedDateTimeRage.date', [(0, _moment.default)(selectedRowData.get('plannedStartTime')).format('MM-DD-YYYY')]);
        this.send('onServiceModeOptionSelect', SERVICE_MODE_PARATRANSIT); //selectedRowData.get('mode')

        this.send('onProviderOptionSelect', {
          id: selectedRowData.get('providerName')
        });
        this.send('onRouteOptionSelect', {
          routeId: selectedRowData.get('routeId')
        });
      }
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'selectedDateTimeRage', {
        today: (0, _moment.default)().subtract(1, 'day').format('MM-DD-YYYY'),
        date: null,
        starttime: null,
        endtime: null,
        startforendtime: (0, _moment.default)().startOf('day').toISOString(),
        startofday: (0, _moment.default)().startOf('day').toISOString(),
        endofday: (0, _moment.default)().endOf('day').toISOString(),
        endDateSelection: (0, _moment.default)().subtract(7, 'years').format('MM-DD-YYYY')
      });
      this.configurePermission();
    },

    configurePermission() {
      Ember.set(this, 'isAddNewRouteAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.addNewRouteRecon, null));
      Ember.set(this, 'isCloseDateAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.closeEditDateRecon, null));
      Ember.set(this, 'isReopenDateAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.reopenEditDateRecon, null));
      Ember.set(this, 'isSearchByClientAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByClientRecon, null));
      Ember.set(this, 'isSearchByDriverAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByDriverRecon, null));
      Ember.set(this, 'isSearchByVehicleAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByVehicleRecon, null));
      Ember.set(this, 'isSearchByAddressAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByAddrRecon, null));
      Ember.set(this, 'isSearchByTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.searchByTripRecon, null));
    },

    didInsertElement() {
      this._super(...arguments);
    },

    callbackHandler(optionId) {
      if (optionId === 'reconcileAddNewRoute') {
        Ember.set(this, 'workspace.activeContext.topActiveContext.resetMapFlag', true);
      }
    },

    resetTripFromMap() {
      Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
        'reconcile-trip': [],
        'reconcile-trip-stops': []
      });
    },

    actions: {
      onSearchOptionSelect(option) {
        const reconcile = Ember.get(this, 'reconcile');
        Ember.set(this, 'selectedSearchMode', option.name);
        this.reconcile.closePopups();
        reconcile.toggleProperty('showSearchPopUp');
      },

      onServiceModeOptionSelect(option) {
        this.resetTripFromMap();
        this.setProperties({
          selectedServiceMode: option,
          'reconcile.reconcileMode': option,
          selectedSearchMode: null,
          selectedRoute: null,
          'reconcile.reconcileRouteIds': null,
          'reconcile.reconcileRoute': null,
          'reconcile.reconcileSelectedRouteId': null,
          selectedProvider: null,
          'reconcile.reconcileProviderName': null
        });
        this.reconcile.closePopups();

        if (option === this.serviceModeTaxiType) {
          Ember.get(this, 'reconcile.fetchTaxiData').perform();
        }
      },

      onProviderOptionSelect(option) {
        this.resetTripFromMap();
        this.setProperties({
          selectedProvider: option,
          'reconcile.reconcileProviderName': option.id,
          selectedSearchMode: null,
          selectedRoute: null,
          'reconcile.reconcileRouteIds': null,
          'reconcile.reconcileRoute': null,
          'reconcile.reconcileSelectedRouteId': null
        });
        this.reconcile.closePopups();
        const provider = Ember.get(this, 'selectedProvider');
        const serviceMode = Ember.get(this, 'selectedServiceMode');
        const selectedDate = Ember.get(this, 'selectedDateTimeRage');

        if (provider.id && serviceMode && selectedDate.date) {
          Ember.get(this, 'reconcile.fetchRouteIds').perform(provider.id, serviceMode, (0, _moment.default)(selectedDate.date).format('MM-DD-YYYY'));
        }
      },

      onRouteOptionSelect(option) {
        this.resetTripFromMap();
        Ember.set(this, 'selectedRoute', option);
        Ember.set(this, 'selectedSearchMode', null);
        this.reconcile.closePopups();
        Ember.get(this, 'reconcile.fetchRouteData').perform(option.routeId);
        const selectedRowData = Ember.get(this, 'reconcile.selectedSearchedTrip');

        if (selectedRowData && option.routeId !== selectedRowData.get('routeId')) {
          Ember.set(this, 'reconcile.selectedSearchedTrip', null);
        }
      },

      onChangeTripDate(valuePath, value) {
        this.resetTripFromMap();
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
        this.setProperties({
          'reconcile.reconcileDate': (0, _moment.default)(value[0]).format('MM-DD-YYYY'),
          selectedSearchMode: null,
          selectedRoute: null,
          'reconcile.reconcileRouteIds': null,
          'reconcile.reconcileRoute': null,
          'reconcile.reconcileSelectedRouteId': null,
          selectedProvider: null,
          'reconcile.reconcileProviderName': null,
          selectedServiceMode: null,
          'reconcile.reconcileMode': null
        });
        this.reconcile.closePopups();
      },

      onAddRouteButtonClick() {
        const provider = Ember.get(this, 'selectedProvider') ? Ember.get(this, 'selectedProvider') : '';
        const routeId = Ember.get(this, 'selectedRoute') ? Ember.get(this, 'selectedRoute').routeId : '';

        if (provider) {
          this.get('workspace').pushState('reconcileAddNewRoute', {
            provider,
            routeId,
            action: this.callbackHandler.bind(this, 'reconcileAddNewRoute')
          });
        }
      },

      reopenDatesClick() {
        this.get('workspace').pushState('reconcileOpenDates', {});
      },

      closeDatesClick() {
        this.get('workspace').pushState('reconcileCloseDates', {});
      },

      closePopupHandler() {
        if (this.reconcile.closePopupHandler) {
          this.reconcile.closePopupHandler();
        }
      }

    }
  });

  _exports.default = _default;
});