define("adept-iq/pods/components/side-drawer/cancel-trip-form/component", ["exports", "adept-iq/pods/components/side-drawer/cancel-trip-form/config", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/config/api-urls", "adept-iq/mixins/async-schedule-operation", "adept-iq/mixins/async-booking-operation", "adept-iq/pods/components/side-drawer/cancel-trip-form/validations"], function (_exports, _config, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _apiUrls, _asyncScheduleOperation, _asyncBookingOperation, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, _asyncBookingOperation.default, {
    editableSections: null,
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    ajax: Ember.inject.service(),
    routeexecevent: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    errors: null,
    currentState: null,
    onShrinkClick: null,
    trip: Ember.computed.alias('workspace.topOptions.trip'),
    tripId: Ember.computed.alias('workspace.topOptions.tripId'),
    segment: Ember.computed.readOnly('workspace.topOptions.segment'),
    booking: Ember.computed.readOnly('workspace.topOptions.booking'),
    title: Ember.computed.readOnly('workspace.topOptions.displayName'),
    componentName: 'cancelTrip',

    setDefaultProperties(record = null) {
      const pendingCancellationRecord = record;

      if (pendingCancellationRecord.get('isNew')) {
        pendingCancellationRecord.setProperties({
          cancelType: null,
          notes: null
        });
      }

      return new _emberChangeset.default(pendingCancellationRecord, (0, _emberChangesetValidations.default)(_validations.default), _validations.default);
    },

    init() {
      this._super(...arguments);

      const segment = this.get('segment');
      const sectionId = this.get('sectionId');

      const editableSections = _config.default.editableSections.filter(section => sectionId === section.id && section.id !== 'readOnly');

      this.set('segment.tripId', this.get('tripId'));
      this.set('editableSections', editableSections);
      this.set('segmentChangeSet', this.setDefaultProperties(segment));
    },

    didInsertElement() {
      this.loadCurrentState();
    },

    loadCurrentState() {
      const currentState = this.get('currentState');

      if (currentState) {
        this.set('segmentChangeSet.cancelType', currentState.segment.get('cancelType'));
        this.set('segmentChangeSet.cancelNotes', currentState.segment.get('cancelNotes'));
      }
    },

    saveCancelRecordFunction(records) {
      const notifications = this.get('notifications');
      const topActiveContext = this.get('activeContext.topActiveContext');
      return new Promise((resolve, reject) => {
        if (!Ember.isEmpty(records)) {
          records.forEach(record => {
            return this.callCancelTripApi(record).then(() =>
            /*response*/
            {
              record.set('isForceDirty', false);
              notifications.success('Trip successfully canceled');
              topActiveContext.get('refreshTableContent').perform(['route']);
              resolve();
            }).catch(function (error) {
              notifications.warning('Failed to cancel Trip');
              reject(error);
            });
          });
        }
      });
    },

    callCancelTripApi(record) {
      if (this.get('workspace.isScheduleDashboard')) {
        return this.performScheduleBookingCancellation.perform(record);
      }

      return this.performDispatchTripCancellation(record);
    },

    performScheduleBookingCancellation: (0, _emberConcurrency.task)(function* (record) {
      const routeExecEvent = this.get('routeexecevent');
      const booking = record.get('leg.booking');
      const cancelData = routeExecEvent.cancelData();
      const operationData = routeExecEvent.prepareTripCancelData(cancelData, record);

      try {
        const options = {
          operationData,
          outputResult: true
        };
        const {
          isJobSuccess,
          results
        } = yield this.createBookingAsyncOperation.perform(booking, 'bookingCancellation', options);

        if (!isJobSuccess) {
          throw new Error(results);
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve();
    }).drop(),

    performDispatchTripCancellation(record) {
      const routeExecEvent = this.get('routeexecevent');
      const bookingId = record.get('leg.booking.id');
      const cancelData = routeExecEvent.cancelData();
      const prepareCancelData = routeExecEvent.prepareTripCancelData(cancelData, record);
      const json = JSON.stringify(prepareCancelData);
      const session = this.get('session');
      return this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking/' + bookingId + '/cancellation', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    onSaveClick() {
      const saveCancelRecordFunction = this.get('saveCancelRecordFunction').bind(this);
      const tooltip = this.get('tooltip');
      const segment = (0, _unwrapProxy.unwrapProxy)(this.get('segmentChangeSet'));
      const tripId = this.get('tripId');
      const onCloseClick = this.onCloseClick;

      if (this.validateRecords([segment])) {
        tooltip.pushConfirmation({
          tip: `Are you sure you want to cancel trip ${tripId}?`,
          title: 'Cancel Trip',
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return saveCancelRecordFunction([segment]).then(() => {
              onCloseClick();
            }).catch(e => {
              tooltip.reset();
              console.warn(e); //eslint-disable-line
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            tooltip.reset();
          }
        });
      }
    },

    validateRecords(records) {
      let valid = true;
      this.set('errors', []);
      records.forEach(record => {
        record.validate();

        if (record.get('isInvalid')) {
          valid = false;
          const errors = record.get('errors');
          this.set('errors', errors);
        }
      });
      return valid;
    },

    onUndoClick(records) {
      records.forEach(record => {
        record.rollback();
      });
    },

    actions: {
      onCloseClick() {
        this.onCloseClick();
      },

      onUndoClick() {
        const segmentChangeSet = Ember.makeArray(this.get('segmentChangeSet'));
        this.onUndoClick(segmentChangeSet);
      },

      onValueChange(valuePath, options) {
        const segmentChangeSet = this.get('segmentChangeSet');
        const value = options;
        segmentChangeSet.set(valuePath, value);
      },

      onShrinkClick() {
        let currentState = this.get('currentState');
        const segment = (0, _unwrapProxy.unwrapProxy)(this.get('segmentChangeSet')); // save current manual settings

        currentState = {
          segment
        };
        this.set('currentState', currentState);
        this.onShrinkClick();
      },

      onSaveClick() {
        return this.onSaveClick();
      }

    }
  });

  _exports.default = _default;
});