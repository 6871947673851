define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/19a-review/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/19a-review/validation"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tableRef: null,
    disableForm: true,
    selectedRecord: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),
    providerOptions: Ember.computed('', function () {
      return this.get('store').peekAll('provider').filter(provider => {
        return provider.get('userCanAccessProvider');
      });
    }),
    typeOfDateOptions: Ember.computed('', function () {
      return this.get('store').peekAll('exam-type-name');
    }),
    driverReviewChangeSet: Ember.computed('selectedRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onDeleteRecord = this.get('onDeleteRecord');
      const onSaveRecord = this.get('onSaveRecord'); // normal functions

      const onUndoRecord = this.get('onUndoRecord').bind(this);
      const onNewRecord = this.get('onNewRecord').bind(this);
      const onEditRecord = this.get('onEditRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRecord,
          newAction: onNewRecord,
          deleteAction: onDeleteRecord,
          editAction: onEditRecord,
          undoAction: onUndoRecord,
          onValidateAction: null
        });
      });
      editAPI.buttonSettings();
    },

    setDefaultProperties(record = null) {
      const store = this.get('store');
      const editAPI = this.get('editAPI');
      let pendingDriverReviewRecord = this.get('pendingDriverReviewRecord');

      if (record) {
        pendingDriverReviewRecord = record;
      } else if (!pendingDriverReviewRecord) {
        pendingDriverReviewRecord = store.createRecord('driver-19-a-review');
        this.set('pendingDriverReviewRecord', pendingDriverReviewRecord);
      }

      if (pendingDriverReviewRecord.get('isNew')) {
        pendingDriverReviewRecord.setProperties({
          provider: '',
          examTypeName: '',
          examDate: new Date(),
          driver: editAPI.selectedDriver
        });
      }

      return new _emberChangeset.default(pendingDriverReviewRecord, (0, _emberChangesetValidations.default)(_validation.DRIVER_REVIEW_VALIDATION), _validation.DRIVER_REVIEW_VALIDATION);
    },

    validateDriverReview: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverReviewChangeSet = this.get('driverReviewChangeSet');
      yield driverReviewChangeSet.validate();
      const errors = driverReviewChangeSet.errors;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Driver 19A Review',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      return true;
    }),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedDriverReview = this.get('selectedRecord');
      const tableRef = this.get('tableRef');

      if (selectedDriverReview) {
        if (!selectedDriverReview.get('isNew') && !selectedDriverReview.get('isDeleted')) {
          yield selectedDriverReview.reload();
        }
      }

      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    onSaveRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const driverReviewChangeSet = this.get('driverReviewChangeSet');
      const editAPI = this.get('editAPI'); // validation

      const isValidDriverReview = yield this.validateDriverReview.perform();

      if (isValidDriverReview) {
        try {
          yield driverReviewChangeSet.save();
          notifications.success('Successfully record saved');
          editAPI.buttonSettings();
          this.set('disableForm', true);
        } catch (e) {
          notifications.warning('Failed to Save the records');
          console.warn(e); // eslint-disable-line
        }

        this.get('refreshTask').perform();
      }
    }),
    onDeleteRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const selectedRecord = this.get('selectedRecord');

      try {
        yield selectedRecord.destroyRecord();
        notifications.success('record successfully deleted');
        this.get('refreshTask').perform();
      } catch (e) {
        notifications.warning('Failed to delete record');
        console.warn(e); // eslint-disable-line
      }
    }),

    onUndoRecord() {
      const driverReviewChangeSet = this.get('driverReviewChangeSet');

      if (driverReviewChangeSet) {
        driverReviewChangeSet.rollback();
      }
    },

    onNewRecord() {
      const selectedRow = this.get('selectedRow');
      const editAPI = this.get('editAPI');
      if (selectedRow) selectedRow.set('selected', false);
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    },

    onEditRecord() {
      const selectedRow = this.get('selectedRow');
      const editAPI = this.get('editAPI');
      if (Ember.isEmpty(selectedRow)) return;
      this.set('disableForm', false);
      editAPI.buttonSettings(true, true, true, false, false);
    }

  });

  _exports.default = _default;
});