define("adept-iq/pods/components/generic-components/tab-group/tab-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // passed in from parent component
    // link-text name
    link: null,
    disabled: false,
    // pass wizard and step name
    wizard: null,
    transitionName: null,
    isActive: Ember.computed('wizard.currentStep', 'transitionName', function () {
      const w = this.get('wizard');
      const transitionName = this.get('transitionName');
      return w.currentStep === transitionName;
    }),
    actions: {
      onclick() {
        const w = this.get('wizard');
        const onTabClick = this.get('onTabClick');
        const transitionName = this.get('transitionName');

        if (onTabClick) {
          onTabClick(w.currentStep);
        }

        w['transition-to'](transitionName);
      }

    }
  });

  _exports.default = _default;
});