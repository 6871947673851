define("adept-iq/pods/components/side-drawer/reconcile-unperform-no-show/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['reconcile-widget-base'],
    onCloseClick: null,
    tripNote: '',
    tripDetails: Ember.computed.alias('reconcile.reconcileTrip'),
    tripDate: Ember.computed.alias('reconcile.reconcileDate'),
    title: Ember.computed.readOnly('workspace.topOptions.title'),
    callBack: Ember.computed.readOnly('workspace.topOptions.action'),

    init() {
      this._super(...arguments);

      this.set('selectedDateTimeRage', {
        starttime: (0, _moment.default)(new Date()).toISOString('hh:mm a')
      });
    },

    saveUnperformNoShowTripTask: (0, _emberConcurrency.task)(function* () {
      const date = (0, _moment.default)(this.tripDate).format('MM-DD-YYYY');
      const startTime = (0, _moment.default)(this.get('selectedDateTimeRage').starttime).format('hh:mm a');
      const stDt = (0, _moment.default)(date + ' ' + startTime);

      try {
        const store = this.get('store');
        const reconcileTripAdapter = store.adapterFor('reconcile-trip');
        const payload = {
          tripId: this.tripDetails.tripId,
          date: stDt.toISOString(),
          note: this.tripNote,
          userId: this.session.data.authenticated.userId
        };
        yield reconcileTripAdapter.patchTrip(payload, 'unperform noshow trip');
      } catch (error) {
        throw error;
      }
    }),

    showToolTip() {
      const tooltip = this.get('tooltip');
      const title = 'Unperform No Show Trip';
      const tip = `Unperform No show trip ${this.tripDetails.tripId}`;
      const successMsg = `NO ShOWED TRIP ${this.tripDetails.tripId} WAS SUCCESSFULLY UNPERFORMED`;
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          return Ember.get(this, 'saveUnperformNoShowTripTask').perform().then(() => {
            this.send('cancel');
            this.get('notifications').success(successMsg);
            this.get('workspace').popState('reconcileUnPerformNoShow');
            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in unperform no show trip, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    actions: {
      onSaveClick() {
        this.showToolTip();
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toISOString());
      }

    }
  });

  _exports.default = _default;
});