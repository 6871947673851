define("adept-iq/models/leg-travel-need", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    count: attr('number'),
    leg: belongsTo('leg'),
    travelNeedType: belongsTo('travel-need-type'),
    passengerType: belongsTo('passenger-type'),
    isAmbulatory: Ember.computed.equal('travelNeedType.name', 'ambulatory'),
    isServiceAnimal: Ember.computed.equal('travelNeedType.name', 'serviceAnimal'),
    isClient: Ember.computed.equal('passengerType.name', 'client'),
    isPca: Ember.computed.equal('passengerType.name', 'pca'),
    isCompanion: Ember.computed.equal('passengerType.name', 'companion'),
    isEquipment: Ember.computed('travelNeedType.name', function () {
      return !this.get('travelNeedType.isServiceAnimal') && this.get('passengerType.isClient');
    }),
    isExtraPassenger: Ember.computed('travelNeedType.name', 'passengerType', function () {
      return this.get('travelNeedType.isServiceAnimal') || this.get('passengerType.isPca') || this.get('passengerType.isCompanion');
    }),
    isWheelchair: Ember.computed('travelNeedType.{isWheelchair,isWideWheelchair,name}', function () {
      const isWheelchair = this.get('travelNeedType.isWheelchair');
      const isWideWheelchair = this.get('travelNeedType.isWideWheelchair');
      return isWheelchair || isWideWheelchair;
    }),
    basicTravelNeedType: Ember.computed('travelNeedType.name', function () {
      const name = Ember.get(this, 'travelNeedType.name');
      return name === 'ambulatory' || name === 'wideAmbulatory' || name === 'wheelchair' || name === 'wideWheelchair';
    }),
    // travelNeedTypeName should be travelNeedType.name. It is unique. do not use displayName
    travelNeedTypeName: Ember.computed.readOnly('travelNeedType.name'),
    passengerTypeName: Ember.computed.readOnly('passengerType.name'),
    travelNeedTypeNameUppercase: Ember.computed('travelNeedTypeName', function () {
      return this.get('travelNeedTypeName') ? this.get('travelNeedTypeName').toUpperCase() : null;
    })
  });

  _exports.default = _default;
});