define("adept-iq/serializers/dispatch-route", ["exports", "adept-iq/serializers/-dispatch", "@mapbox/polyline"], function (_exports, _dispatch, _polyline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dispatch.default.extend({
    normalize(typeClass, resourceHash) {
      // de-code polylines; for `navigationPolyline`, this is done by an
      // ember-data transform, but the nested structure here means we have to
      // do it manually
      if (resourceHash.attributes) {
        // Handles error if attributes is undefined
        const {
          stopPolylines
        } = resourceHash.attributes;

        if (stopPolylines) {
          stopPolylines.forEach(obj => {
            if (!obj.polyline) return;
            obj.polyline = _polyline.default.decode(obj.polyline);
          });
        }

        if (!Ember.isNone(resourceHash.attributes.providerName)) {
          const relationships = resourceHash.relationships ? resourceHash.relationships : {};
          relationships.provider = {
            data: {
              type: 'provider',
              id: resourceHash.attributes.providerName
            }
          };
          resourceHash.relationships = relationships;
          delete resourceHash.attributes.providerName;
        }
      }

      const normalized = this._super(...arguments);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {}; // Roadsup probably won't open routesWidget so it is not transformed.

      const routeId = payload.data.attributes._transformed ? payload.data.attributes.routeId : payload.data.attributes.id;

      if (Ember.isPresent(payload.data.attributes.otp) || Ember.isPresent(payload.data.attributes.actualStartDateTime) || Ember.isPresent(payload.data.attributes.actualEndDateTime)) {
        const route = {
          type: 'route',
          id: routeId,
          attributes: {
            otpValue: payload.data.attributes.otp,
            otp: payload.data.attributes.otpStatus
          }
        };

        if (payload.data.attributes.actualStartDateTime) {
          route.attributes.actualStartTimestamp = payload.data.attributes.actualStartDateTime;
        }

        if (payload.data.attributes.actualEndDateTime) {
          route.attributes.actualEndTimestamp = payload.data.attributes.actualEndDateTime;
        }

        payload.included = payload.included || [];
        payload.included.push(route);
      }

      if (Ember.isPresent(payload.data.attributes.polyline)) {
        const navigationPolyline = {
          type: 'polyline',
          id: routeId,
          attributes: {
            coords: payload.data.attributes.polyline
          },
          relationships: {
            route: {
              data: {
                type: 'route',
                id: routeId
              }
            }
          }
        };
        payload.included = payload.included || [];
        payload.included.push(navigationPolyline);
      }

      if (Ember.isPresent(payload.data.attributes.lock)) {
        const dispatchRouteLock = {
          type: 'lock',
          id: payload.data.attributes.dispatchRoutePk,
          attributes: {
            entityType: 'dispatchRoute',
            entityId: payload.data.attributes.dispatchRoutePk,
            timeToLive: payload.data.attributes.lock.ttl,
            expirationTime: payload.data.attributes.lock.expiredAt,
            status: 'locked',
            persist: payload.data.attributes.lock.persist
          },
          relationships: {
            dispatchRoute: {
              data: {
                type: 'dispatchRoute',
                id: payload.data.attributes.dispatchRoutePk
              }
            },
            owner: {
              data: {
                type: 'sso-user',
                id: payload.data.attributes.lock.user
              }
            }
          }
        };
        relationships.dispatchRouteLock = {
          data: {
            type: 'lock',
            id: payload.data.attributes.dispatchRoutePk
          }
        };
        payload.included = payload.included || [];
        payload.included.push(dispatchRouteLock);
      } else if (Ember.isPresent(payload.data.attributes.isLocked) && !payload.data.attributes.isLocked) {
        const dispatchRouteLock = {
          type: 'lock',
          id: payload.data.attributes.dispatchRoutePk,
          attributes: {
            entityType: 'dispatchRoute',
            entityId: payload.data.attributes.dispatchRoutePk,
            status: 'unlocked'
          },
          relationships: {
            dispatchRoute: {
              data: {
                type: 'dispatchRoute',
                id: payload.data.attributes.dispatchRoutePk
              }
            }
          }
        };
        relationships.dispatchRouteLock = {
          data: {
            type: 'lock',
            id: payload.data.attributes.dispatchRoutePk
          }
        };
        payload.included = payload.included || [];
        payload.included.push(dispatchRouteLock);
      }

      payload.data.relationships = relationships; // TODO: we need to store navigation polyline from widgetRecord

      return payload;
    }

  });

  _exports.default = _default;
});