define("adept-iq/models/driver-license", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    hasMany,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    license: attr('string'),
    issueDate: attr({
      defaultValue: null
    }),
    expiryDate: attr({
      defaultValue: null
    }),
    driver: belongsTo('driver'),
    violations: hasMany('driver-violation'),
    classificationName: belongsTo('classification-name')
  });

  _exports.default = _default;
});