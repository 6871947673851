define("adept-iq/pods/components/side-drawer/add-edit-schedule-route/component", ["exports", "ember-concurrency", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/mixins/fetchAssignableVehicleDrivers", "adept-iq/models/route-template", "adept-iq/models/zone"], function (_exports, _emberConcurrency, _moment, _unwrapProxy, _fetchAssignableVehicleDrivers, _routeTemplate, _zone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NUMBER_FORMAT = /^\d*\.*\d*$/;
  const REMOVE_TEXT_FORMAT = /[^0-9.]+/;
  const extraTripLimitOptions = {
    min: 1,
    max: 1,
    isFloat: true
  };
  const extraDistanceLimitOptions = {
    min: 1,
    max: 2,
    isFloat: true
  };

  var _default = Ember.Component.extend(_fetchAssignableVehicleDrivers.default, {
    tagName: '',
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    vehicles: null,
    providers: null,
    schedules: null,
    selectedVehicle: null,
    scheduleOption: null,
    hasAllSelectedValues: Ember.computed.and('routeName', 'selectedProvider', 'selectedSchedule', 'startTime', 'endTime'),
    extraDistanceLimitOptions,
    extraTripLimitOptions,
    onShrinkClick: null,
    onCloseClick: null,
    usePlaceHolderTask: true,
    // used by fetchVehicle Mixin
    timeFormat: 'm-d-Y h:i K',
    route: Ember.computed.alias('stackFrame.options.routeModel'),
    displayName: Ember.computed.alias('stackFrame.options.displayName'),
    startTime: Ember.computed.alias('route.plannedStartTime'),
    endTime: Ember.computed.alias('route.plannedEndTime'),
    routeName: Ember.computed.alias('route.name'),
    type: Ember.computed.alias('route.type'),
    schedulingMode: Ember.computed.alias('route.schedulingMode'),
    optimizationFlags: Ember.computed.alias('route.optimizationFlags'),
    tripLimit: Ember.computed.alias('route.tripLimit'),
    distanceLimit: Ember.computed.alias('route.distanceLimit'),
    affinities: Ember.computed.alias('route.affinities'),
    isKeeperType: Ember.computed.equal('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_KEEPER),
    isAdjustBreak: true,
    isConsolidateRoute: true,
    availableVehicles: Ember.computed.readOnly('vehicles'),
    date: Ember.computed.readOnly('stackFrame.options.minDate'),
    zoneTableRef: null,
    selectedSchedule: Ember.computed.alias('workspace.currentSchedule'),
    provider: Ember.computed.readOnly('selectedProvider'),
    // used by fetchVehicle Mixin
    selectedProvider: Ember.computed({
      set(key, value) {
        this.set('selectedVehicle');
        return value;
      }

    }),
    isEditMode: Ember.computed.notEmpty('route.id'),

    init() {
      this._super();

      this.set('timePlaceHolder', 'YYYY-MM-DD HH:MM');
      this.set('schedulingModeList', [_routeTemplate.MANUAL_SCHEDULING_MODE, _routeTemplate.AUTOMATIC_SCHEDULING_MODE]);
      this.set('affinityTypes', [_zone.AFFINITY_PREFERRED, _zone.AFFINITY_NORMAL, _zone.AFFINITY_LAST_RESORT, _zone.AFFINITY_NO_SERVICE]);
      const store = this.get('store');
      const zones = store.peekAll('zone');
      this.set('availableZones', zones);
      this.set('selectedProvider', this.get('route.provider'));
      this.set('selectedVehicle', this.get('route.vehicle'));
      this.set('isAdjustBreak', _routeTemplate.ADJUST_BREAK_FLAG & this.get('optimizationFlags'));
      this.set('isConsolidateRoute', _routeTemplate.CONSOLIDATE_ROUTE_FLAG & this.get('optimizationFlags'));
      this.setupHeaderActions();
    },

    didInsertElement() {
      this.loadOptionsTask.perform();
    },

    loadOptionsTask: (0, _emberConcurrency.task)(function* () {
      yield this.fetchPlaceHolderVehiclesTask.perform();
      yield this.fetchProvidersTask.perform();
    }),
    fetchProvidersTask: (0, _emberConcurrency.task)(function* () {
      const providers = yield this.store.findAll('provider');
      this.set('providers', providers);
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const {
        selectedProvider,
        selectedVehicle,
        selectedSchedule,
        route
      } = this.getProperties('selectedProvider', 'selectedVehicle', 'selectedSchedule', 'route');
      const store = this.get('store');
      const routeAdapter = store.adapterFor('route');
      route.set('vehicle', selectedVehicle);
      route.set('provider', selectedProvider);
      route.set('schedule', (0, _unwrapProxy.unwrapProxy)(selectedSchedule));

      try {
        if (this.get('isEditMode')) {
          return yield routeAdapter.updateScheduleRoute(route);
        }

        return yield routeAdapter.createScheduleRoute(route);
      } catch (e) {
        throw e;
      }
    }),

    resetSelections() {
      this.setProperties({
        routeName: null,
        selectedVehicle: null,
        selectedSchedule: null,
        startTime: null,
        endTime: null,
        selectedProvider: null,
        errorMessage: null
      });
    },

    configureOptimizationFlags(checked, bitFlag) {
      const optimizationFlags = this.get('optimizationFlags');

      if (checked) {
        this.set('optimizationFlags', optimizationFlags | bitFlag); // turn bit on
      } else {
        this.set('optimizationFlags', optimizationFlags & ~bitFlag); // turn bit off
      }
    },

    setupHeaderActions() {
      this.set('onRefreshClick', this.get('onRefreshClick').bind(this));
      this.set('onUndoClick', this.get('onUndoClick').bind(this));

      if (this.get('isEditMode')) {
        this.set('onRefreshClick', null);
      }

      if (!this.get('isEditMode')) {
        this.set('onUndoClick', null);
      }
    },

    onRefreshClick() {
      this.loadOptionsTask.perform();
      this.resetSelections();
    },

    onUndoClick() {
      this.get('route').rollbackAttributes();
      this.set('selectedProvider', this.get('route.provider'));
      this.set('selectedVehicle', this.get('route.vehicle'));
      this.set('isAdjustBreak', _routeTemplate.ADJUST_BREAK_FLAG & this.get('route.optimizationFlags'));
      this.set('isConsolidateRoute', _routeTemplate.CONSOLIDATE_ROUTE_FLAG & this.get('route.optimizationFlags'));
      this.set('selectedVehicle', this.get('route.vehicle'));
      this.set('endTime', this.get('route.plannedEndTime'));
      this.set('affinities', JSON.parse(this.get('route.affinities')));
    },

    actions: {
      onRouteNameInput(name) {
        if (!NUMBER_FORMAT.test(this.get('routeName'))) {
          this.set('routeName', name.replace(REMOVE_TEXT_FORMAT, ''));
          return this.notifyPropertyChange('routeName');
        }

        this.set('routeName', name);
      },

      onChangeTime(valuePath, value) {
        const momentTime = (0, _moment.default)(value[0]);
        this.set(valuePath, momentTime.toDate());
      },

      onIsKeeperTypeChange(event) {
        const isChecked = event.target.checked;

        if (isChecked) {
          this.set('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_KEEPER);
        } else {
          this.set('type', _routeTemplate.ROUTE_TEMPLATE_TYPE_REGULAR);
        }
      },

      onAdjustDriverBreakCheckBoxClick(event) {
        const checked = event.target.checked;
        this.configureOptimizationFlags(checked, _routeTemplate.ADJUST_BREAK_FLAG);
      },

      onConsolidateCheckBoxClick(event) {
        const checked = event.target.checked;
        this.configureOptimizationFlags(checked, _routeTemplate.CONSOLIDATE_ROUTE_FLAG);
      },

      onAffinitySelect(option) {
        const availableZones = this.get('availableZones');
        const zones = this.get('zoneTableRef.checkedRows');
        const zoneIds = availableZones.map(zone => parseInt(zone.get('id'), 10));
        const affinities = this.get('affinities') || [];
        const maxZoneId = Math.max(...zoneIds, affinities.length);
        const newSize = Math.max(maxZoneId, affinities.length);
        const newAffinities = Array(newSize).fill(0);
        this.set('selectedAffinityType', option);
        zones.setEach('affinityValue', option.val); // configured affinities to contain all imaginable zone changes

        affinities.forEach((aff, index) => {
          newAffinities[index] = aff;
        });
        zones.forEach(zone => {
          //affinities index start from [0-16] and zone Ids starts from 1-17
          const zoneId = zone.get('zoneId') - 1;
          newAffinities[zoneId] = option.val;
        });
        this.set('affinities', newAffinities);
      },

      onLimitInputChange(path, value) {
        this.set(path, value);
      },

      save() {
        const hasAllSelectedValues = this.get('hasAllSelectedValues');

        if (hasAllSelectedValues) {
          // reset error message
          this.set('errorMessage');
        } else {
          // show error message, missing properties
          this.set('errorMessage', 'Please fill out all of the fields before saving.');
          return false;
        }

        const tooltip = this.get('tooltip');
        const saveTask = this.get('saveTask');
        const routeName = this.get('routeName');
        const notifications = this.get('notifications');
        const title = this.get('isEditMode') ? `Edit Route ${routeName}` : 'Add New Route';
        const tip = this.get('isEditMode') ? `Edit route ID ${routeName}` : `Create route ID ${routeName}`;
        const successMsg = this.get('isEditMode') ? `Successfully updated new route ${routeName}` : `Successfully created new route ${routeName}`;
        const failureMsg = this.get('isEditMode') ? 'There was a problem updating the route, please try again.' : 'There was a problem creating the route, please try again.';
        tooltip.pushConfirmation({
          tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return saveTask.perform().then(() => {
              this.onCloseClick();
              notifications.success(successMsg);
              tooltip.reset();
            }).catch(error => {
              let message;

              switch (error.status) {
                case 400:
                  message = 'There was a problem with one of the fields. Please check over the form and try again.';
                  break;

                case 409:
                  message = `The route name ${this.get('routeName')} is already taken, please try another.`;
                  break;

                default:
                  message = failureMsg; // eslint-disable-next-line no-console

                  console.error(error);
                  break;
              }

              this.set('errorMessage', message);
              notifications.warning(message);
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => tooltip.reset()
        });
      },

      onCloseClick() {
        this._super(...arguments);

        if (this.get('isEditMode')) {
          this.get('route').rollbackAttributes();
        }

        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});