define("adept-iq/pods/components/iq-widgets/new-booking-form/leg-form/component", ["exports", "adept-iq/utils/zone-validation", "moment", "adept-iq/utils/unwrapProxy"], function (_exports, _zoneValidation, _moment, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['leg-form'],
    anchorTypeOptions: null,
    serviceTypeOptions: null,
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    booking: Ember.inject.service(),
    editModal: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    maxDate: null,
    minDate: null,
    isFirstLeg: false,
    markerType: null,
    disablePick: false,
    availableZones: null,
    availableConfigZones: null,
    assesmentCenterList: null,
    rideshareTypeOptions: null,
    isNotFirstLeg: Ember.computed('isFirstLeg', function () {
      return !this.get('isFirstLeg');
    }),
    canShowRemoveButton: Ember.computed('isFirstLeg', 'leg.purpose', 'isReturnTrip', function () {
      return this.get('isReturnTrip') || this.get('isNotFirstLeg');
    }),
    legSegmentDrop: Ember.observer('booking.activeDragPlace', function () {
      const addressSelectType = this.booking.get('addressSelectType');

      if (addressSelectType === 'dropoff') {
        this.get('syncPickAndDrop')();
      }
    }),

    // we should only show upto day  after tomorrow for booking
    setMaxDate() {
      const today = new Date();
      const maxDate = new Date(today);
      maxDate.setDate(maxDate.getDate() + 2);
      maxDate.setHours(23, 59, 59);
      this.set('maxDate', maxDate);
      this.set('minDate', new Date().setHours(0, 0, 0, 0));
    },

    anchorObserver: Ember.observer('leg.anchor', 'legIndex', function () {
      if (this.get('leg.anchor') === 'Appt' || this.get('legIndex') === 0) {
        this.set('isFirstLeg', true);
      } else {
        this.set('isFirstLeg', false);
      }
    }),

    init() {
      this._super(...arguments);

      this.setProperties({
        anchorTypeOptions: [{
          id: 'pick',
          name: 'Pick'
        }, {
          id: 'drop',
          name: 'Appt'
        }],
        serviceTypeOptions: this.store.peekAll('service-window'),
        rideshareTypeOptions: [{
          id: 0,
          name: 'Allowed'
        }, {
          id: 2,
          name: 'Not Allowed'
        }]
      });
      this.setAssesmentCenterList();
      this.set('availableZones', this.get('booking').getNYCTTravelNeeds());
      this.set('availableConfigZones', this.get('booking').getSysConfigTravelNeeds());
      this.setMaxDate();
      this.isFirstLegValidation();
    },

    setAssesmentCenterList() {
      const places = this.store.peekAll('place'); //filter by certification_location

      const placeCategories = places.filter(place => {
        return place.get('placeCategoryType.id') === 'certification_location';
      }); // mutate lat, lng freeformAddress

      const assesmentCenterList = placeCategories.map(element => {
        const address = (0, _unwrapProxy.unwrapProxy)(element.get('address'));
        address.set('lat', address.get('position.lat'));
        address.set('lng', address.get('position.lng'));
        address.set('freeformAddress', address.get('address.address.freeformAddress'));
        return address;
      });
      this.set('assesmentCenterList', assesmentCenterList);
    },

    selectedTime: Ember.computed('leg.requestTime', function () {
      return this.get('leg.requestTime');
    }),

    didInsertElement() {
      this._super(...arguments);
    },

    isFirstLegValidation() {
      if (this.get('legIndex') === 0 && this.get('leg.purpose') !== 'return') {
        this.set('isFirstLeg', true);
        this.set('disablePick', true);
      } else {
        this.set('isFirstLeg', false);
        this.set('disablePick', false);
      }
    },

    validateAddress(addressSearchResult, addressType) {
      const streetNumber = addressSearchResult.get('streetNumber');
      const streetAddress = addressSearchResult.get('streetAddress');
      const postalCode = addressSearchResult.get('postalCode');
      const lat = addressSearchResult.get('lat');
      const lng = addressSearchResult.get('lng');

      if (lat === 0 || lng === 0) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return false;
      }

      if (Ember.isNone(streetNumber) || Ember.isNone(streetAddress) || Ember.isNone(postalCode)) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return false;
      }

      if (Ember.isEmpty(streetNumber) || Ember.isEmpty(streetAddress) || Ember.isEmpty(postalCode)) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return false;
      }

      return true;
    },

    async updateLocationForAddressSelect(address, close, selectType) {
      this.set('booking.canSave', true);
      const segment = selectType === 'pick' ? this.get('leg.segments.firstObject') : this.get('leg.segments.lastObject');
      const addressType = selectType === 'pick' ? `${selectType}up` : `${selectType}off`;

      if (!address) {
        const emptyAddress = this.store.createRecord('address');
        const emptyLocation = this.store.createRecord('location');
        const place = this.store.createRecord('place', {
          address: emptyAddress,
          location: emptyLocation
        });
        segment.set(`${selectType}.place`, place);
        return;
      }

      if (!this.validateAddress(address, addressType)) {
        return;
      }

      const lat = address.get('lat');
      const lng = address.get('lng');

      if (!(0, _zoneValidation.insideZone)([lat, lng])) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return;
      }

      this.get('workspace').set('isGlobalSpinnerVisible', true);
      const freeformAddress = address.get('freeformAddress');
      const geoNode = await this.booking.getAddressGeoNode(freeformAddress, addressType);

      if (!geoNode) {
        return;
      }

      const {
        zoneName,
        isInsideAvailZones
      } = this.get('booking').checkZoneForAddress(this.get('availableZones'), this.get('availableConfigZones'), lat, lng);

      if (isInsideAvailZones && !zoneName) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return;
      }

      if (zoneName) {
        address.set('zName', zoneName);
      }

      if (!address.get('zName')) {
        this.get('booking').showErrorMessage(`Unknown Address. Please enter a valid ${addressType} address.`);
        return;
      }

      const locationRecord = this.store.createRecord('location', {
        lat,
        lng
      });
      locationRecord.set('geoNode', geoNode.geonode);
      const place = segment.get(`${selectType}.place`);
      place.set('location', locationRecord);
      place.set('address', address);
      if (selectType === 'drop') this.get('syncPickAndDrop')();
      this.booking.setActiveAddress(address);
      if (close) close();
      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    actions: {
      onDateChange(value) {
        const rTime = this.get('leg.requestTime');
        const currentDateAndTime = (0, _moment.default)(rTime);
        const selectedDate = (0, _moment.default)(value[0]);
        selectedDate.hour(currentDateAndTime.hour());
        selectedDate.minutes(currentDateAndTime.minutes());
        selectedDate.seconds(currentDateAndTime.seconds());
        const selectedDateObjectWithTime = selectedDate.toDate();
        this.set('leg.requestTime', selectedDateObjectWithTime);
        const legIndexWithinTotalLegs = this.get('booking.activeBooking.legs').indexOf(this.get('leg'));
        this.get('validateRequestDateTime')(this.get('leg'));

        if (legIndexWithinTotalLegs === 0) {
          this.get('onFirstLegDateChange')();
        }
      },

      onTimeChange(value) {
        const rTime = this.get('leg.requestTime');
        const currentDateAndTime = (0, _moment.default)(rTime);
        const selectedDate = (0, _moment.default)(value);
        const currentYear = currentDateAndTime.year();
        const currentMonth = currentDateAndTime.month();
        const currentDay = currentDateAndTime.date();
        selectedDate.year(currentYear);
        selectedDate.month(currentMonth);
        selectedDate.date(currentDay);
        const selectedDateObjectWithTime = selectedDate.toDate();
        this.set('leg.requestTime', selectedDateObjectWithTime);
        this.get('validateRequestDateTime')(this.get('leg'));
      },

      onChangeServiceType(selectedType) {
        this.set('leg.serviceWindow', selectedType);
      },

      onPickAddressSelect(address, close) {
        this.updateLocationForAddressSelect(address, close, 'pick');
      },

      onDropAddressSelect(address, close) {
        this.updateLocationForAddressSelect(address, close, 'drop');
      },

      onPickOnMap(place, selectType) {
        const activeDragPlace = this.booking.get('activeDragPlace');

        if (!(0, _unwrapProxy.unwrapProxy)(place.get('address'))) {
          this.get('booking').setEmptyAddressLocation(place);
        }

        if (!activeDragPlace) {
          this.booking.set('activeDragPlace', place);
        }

        this.booking.set('addressSelectType', selectType);
      },

      toggleCalendar() {
        this.calflatpickrRef.toggle();
      },

      toggleTime() {
        this.timeflatpickrRef.toggle();
      },

      onLegRemoveClick(leg) {
        this.get('onLegRemoveClick')(leg);
      },

      updateFare() {
        this.get('updateFare')();
      }

    }
  });

  _exports.default = _default;
});