define("adept-iq/models/vehicle-dot-inspection", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    inspectionDate: attr({
      defaultValue: null
    }),
    nextInspectionDate: attr({
      defaultValue: null
    }),
    failedDot: attr('string'),
    oosDate: attr({
      defaultValue: null
    }),
    reinspection: attr('boolean', {
      defaultValue: false
    }),
    notes: attr('string'),
    vehicle: belongsTo('vehicle'),
    provider: belongsTo('provider'),
    inspectionProvider: belongsTo('inspection-provider-name'),
    inspectionCategory: belongsTo('inspection-category'),
    providerName: Ember.computed.readOnly('provider.name'),
    inspectionProviderName: Ember.computed.readOnly('inspectionProvider.name'),
    inspectionCategoryName: Ember.computed.readOnly('inspectionCategory.name')
  });

  _exports.default = _default;
});