define("adept-iq/models/vehicle-maintenance", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    scheduled: attr('boolean', {
      defaultValue: true
    }),
    mileage: attr('string'),
    serviceDate: attr({
      defaultValue: ''
    }),
    accepted: attr('boolean', {
      defaultValue: false
    }),
    acceptedDate: attr({
      defaultValue: ''
    }),
    maintenanceDate: attr({
      defaultValue: ''
    }),
    notes: attr('string'),
    comments: attr('string'),
    maintenanceLocationName: belongsTo('maintenance-location-name'),
    vehicle: belongsTo('vehicle'),
    maintenanceTypeName: belongsTo('maintenance-type-name'),
    maintenanceStatusName: belongsTo('maintenance-status-name'),
    inspectionProviderName: belongsTo('inspection-provider-name'),
    problemIdentifiedName: belongsTo('problem-identified-name')
  });

  _exports.default = _default;
});