define("adept-iq/utils/datetime-filters", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildDateFilter = buildDateFilter;
  _exports.buildTimeFilter = buildTimeFilter;

  const dateContainsTime = givenDate => (0, _moment.default)(givenDate).format('hh:mm') !== '12:00';

  const diffFromUTC = givenDate => {
    const utcDate = new Date(_moment.default.utc(givenDate).format('l LT'));
    return (0, _moment.default)(givenDate).diff(utcDate);
  };

  function dateBetweenForEqualAndNotEqual(filternode) {
    const givenDate = filternode.local.values[0];
    const isTimeGiven = dateContainsTime(givenDate);
    const diff = diffFromUTC(givenDate);

    if (isTimeGiven) {
      const startDate = (0, _moment.default)(givenDate).subtract(1, 'minutes').subtract(diff).toDate();
      const endDate = (0, _moment.default)(givenDate).add(1, 'minutes').subtract(diff).toDate();
      filternode.local.values[0] = startDate;
      filternode.local.values[1] = endDate;
    } else {
      const startDay = (0, _moment.default)(givenDate).startOf('day').subtract(diff).toDate();
      const endDay = (0, _moment.default)(givenDate).endOf('day').subtract(diff).toDate();
      filternode.local.values[0] = startDay;
      filternode.local.values[1] = endDay;
    }
  }

  function dateEqualFilter(filternode) {
    filternode.type = 'dateBetween';
    dateBetweenForEqualAndNotEqual(filternode);
  }

  function dateNotEqualFilter(filternode) {
    filternode.type = 'dateNotBetween';
    dateBetweenForEqualAndNotEqual(filternode);
  }

  function dateLessThanConverter(filternode) {
    const givenDate = filternode.local.values[0];
    const isTimeGiven = dateContainsTime(givenDate);
    const diff = diffFromUTC(givenDate);

    if (isTimeGiven) {
      filternode.local.values[0] = (0, _moment.default)(givenDate).subtract(1, 'minutes').subtract(diff).toDate();
    } else {
      filternode.local.values[0] = (0, _moment.default)(givenDate).startOf('day').subtract(diff).toDate();
    }
  }

  function dateLessThanEqualConverter(filternode) {
    const givenDate = filternode.local.values[0];
    const isTimeGiven = dateContainsTime(givenDate);
    const diff = diffFromUTC(givenDate);

    if (isTimeGiven) {
      filternode.local.values[0] = (0, _moment.default)(givenDate).add(1, 'minutes').subtract(diff).toDate();
    } else {
      filternode.local.values[0] = (0, _moment.default)(givenDate).endOf('day').subtract(diff).toDate();
    }
  }

  function dateGreaterThanConverter(filternode) {
    const givenDate = filternode.local.values[0];
    const isTimeGiven = dateContainsTime(givenDate);
    const diff = diffFromUTC(givenDate);

    if (isTimeGiven) {
      filternode.local.values[0] = (0, _moment.default)(givenDate).add(1, 'minutes').subtract(diff).toDate();
    } else {
      filternode.local.values[0] = (0, _moment.default)(givenDate).endOf('day').subtract(diff).toDate();
    }
  }

  function dateBetweenThanConverter(filternode) {
    const givenDate = filternode.local.values[0];
    const isTimeGiven = dateContainsTime(givenDate);
    const diff = diffFromUTC(givenDate);

    if (isTimeGiven) {
      const startDate = (0, _moment.default)(filternode.local.values[0]).subtract(1, 'minutes').subtract(diff).toDate();
      const endDate = (0, _moment.default)(filternode.local.values[1]).add(1, 'minutes').subtract(diff).toDate();
      filternode.local.values[0] = startDate;
      filternode.local.values[1] = endDate;
    } else {
      const startDay = (0, _moment.default)(filternode.local.values[0]).startOf('day').subtract(diff).toDate();
      const endDay = (0, _moment.default)(filternode.local.values[1]).endOf('day').subtract(diff).toDate();
      filternode.local.values[0] = startDay;
      filternode.local.values[1] = endDay;
    }
  }

  function defaultDateConverter(filternode) {
    const utcValues = filternode.local.values.map(givenDate => {
      const utcDate = new Date(_moment.default.utc(givenDate).format('l LT'));
      const diff = (0, _moment.default)(givenDate).diff(utcDate);
      return (0, _moment.default)(givenDate).subtract(diff).toDate();
    });
    filternode.local.values = utcValues;
    filternode.rql.values = utcValues;
  }

  function buildDateFilter(filternode) {
    switch (filternode.type) {
      case 'dateStrEq':
        return dateEqualFilter(filternode);

      case 'dateNe':
        return dateNotEqualFilter(filternode);

      case 'dateLt':
        return dateLessThanConverter(filternode);

      case 'dateLte':
        return dateLessThanEqualConverter(filternode);

      case 'dateGt':
      case 'datGte':
        return dateGreaterThanConverter(filternode);

      case 'dateBetween':
        return dateBetweenThanConverter(filternode);

      default:
        return defaultDateConverter(filternode);
    }
  }

  function buildTimeFilter(filternode) {
    switch (filternode.type) {
      case 'timeStrEq':
        return dateEqualFilter(filternode);

      case 'timeNe':
        return dateNotEqualFilter(filternode);

      case 'timeLt':
        return dateLessThanConverter(filternode);

      case 'timeLte':
        return dateLessThanEqualConverter(filternode);

      case 'timeGt':
      case 'timeGte':
        return dateGreaterThanConverter(filternode);

      case 'timeBetween':
        return dateBetweenThanConverter(filternode);

      default:
        return defaultDateConverter(filternode);
    }
  }
});