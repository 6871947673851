define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/termination-reason/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "ember-changeset", "ember-changeset-validations", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/termination-reason/termination-reason-validation", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _unwrapProxy, _emberChangeset, _emberChangesetValidations, _terminationReasonValidation, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['driver-termination-reason-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // placeholder
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    tableRef: null,
    description: null,
    isNewRecord: false,
    editAPI: null,
    disableForm: true,
    refreshRecord: true,
    //To refresh on undoRecord and newRecord
    showErrorMsg: false,
    selectedRecord: Ember.computed.readOnly('tableRef.table.selectedRows.firstObject'),
    terminationRecord: Ember.computed('selectedRecord', 'refreshRecord', function () {
      const record = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      return this.setDefaultProperties(record);
    }),

    async init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveDriverTerminationRecord = this.get('onSaveDriverTerminationRecord');
      const onNewDriverTerminationRecord = this.get('onNewDriverTerminationRecord').bind(this);
      const onEditDriverTerminationRecord = this.get('onEditDriverTerminationRecord').bind(this);
      const onDeleteDriverTerminationRecord = this.get('onDeleteDriverTerminationRecord');
      const onUndoDriverTerminationRecord = this.get('onUndoDriverTerminationRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverTerminationRecord,
          newAction: onNewDriverTerminationRecord,
          deleteAction: onDeleteDriverTerminationRecord,
          editAction: onEditDriverTerminationRecord,
          undoAction: onUndoDriverTerminationRecord
        });
        this.buttonSettings();
      });
    },

    setDefaultProperties(record = []) {
      const store = this.get('store');
      const isNewRecord = this.get('isNewRecord');
      let terminationObj = record;

      if (isNewRecord && Ember.isEmpty(record)) {
        const data = {
          category: 'config-System_Configuration-termination_reasons',
          type: 'object'
        };
        terminationObj = store.createRecord('cs-config-item', data);
      } else if (Ember.isEmpty(record)) {
        terminationObj = Ember.Object.create({
          description: ''
        });
      }

      if (!isNewRecord) {
        Ember.run.scheduleOnce('afterRender', this, 'buttonSettings');
        this.set('disableForm', true);
      }

      this.set('showErrorMsg', false);
      return new _emberChangeset.default(terminationObj, (0, _emberChangesetValidations.default)(_terminationReasonValidation.VALIDATIONS), _terminationReasonValidation.VALIDATIONS);
    },

    buttonSettings(deleteBtn = false, editBtn = false, saveBtn = true, undoBtn = true, newBtn = false) {
      const editAPI = this.get('editAPI');
      editAPI.setProperties({
        deleteBtn,
        editBtn,
        saveBtn,
        undoBtn,
        newBtn
      });
    },

    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const terminationRecord = this.get('terminationRecord');
      yield terminationRecord.validate();
      const errors = terminationRecord.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveDriverTerminationRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();
      if (!isValid) return;
      const notifications = this.get('notifications');
      const description = this.get('terminationRecord.description') || '';
      const value = {
        description: description
      };
      const isNewRecord = this.get('isNewRecord');
      let terminationRecord = null;

      if (isNewRecord) {
        terminationRecord = (0, _unwrapProxy.unwrapProxy)(this.get('terminationRecord'));
      } else {
        const id = this.get('terminationRecord.id');
        const store = this.get('store');
        const record = `config-System_Configuration-termination_reasons/${id}`;
        terminationRecord = store.peekRecord('cs-config-item', record);
      }

      const name = description.replace(/\s/g, '');
      terminationRecord.set('name', name);
      terminationRecord.set('displayName', description);
      terminationRecord.set('description', description);
      terminationRecord.set('value', value);

      try {
        yield terminationRecord.save();
        notifications.success('Lookup record successfully saved.');
        this.set('isNewRecord', false);
        this.set('disableForm', true);
        this.get('tableRef').refreshData(); //to refresh table data

        this.buttonSettings();
      } catch (e) {
        notifications.warning('Lookup record failed to save.');
        console.warn(e); // eslint-disable-line
      }
    }).drop(),

    onEditDriverTerminationRecord() {
      this.set('disableForm', false);
      this.buttonSettings(true, true, false, false, true);
    },

    onUndoDriverTerminationRecord() {
      this.toggleProperty('refreshRecord');
    },

    onNewDriverTerminationRecord() {
      this.set('isNewRecord', true);
      this.get('tableRef').onUncheckAll();
      this.set('disableForm', false);
      this.toggleProperty('refreshRecord');
      this.buttonSettings(true, true, false, false, true);
    },

    deleteRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRow = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRecord'));
      const notifications = this.get('notifications');
      const isNewRecord = this.get('isNewRecord');

      if (isNewRecord) {
        const terminationRecord = this.get('terminationRecord');
        terminationRecord.set('description', '');
      }

      yield selectedRow.destroyRecord();

      try {
        notifications.success('Lookup record successfully deleted.');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (e) {
        notifications.warning('Lookup record failed to delete.');
        console.warn(e); // eslint-disable-line
      }
    }).drop(),
    onDeleteDriverTerminationRecord: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      yield tooltip.pushConfirmation({
        title: 'Look UP',
        hasoverlay: true,
        tip: 'Are you sure you want to delete this record?',
        primaryActionText: 'Confirm',
        primaryAction: () => {
          const store = this.get('store');
          const description = this.get('terminationRecord.description') || '';

          if (description) {
            const driver = store.peekAll('driver').filter(record => record.get('active') && record.get('driverTerminationReason.description') === description);

            if (driver.length) {
              tooltip.pushConfirmation({
                title: 'Look UP',
                hasoverlay: true,
                tip: `There are ${driver.length} active driver(s) with this Termination Reason description. You must either change the Termination Reason description on these driver(s) or delete these driver(s) prior to changing the Termination Reason description.`,
                primaryActionText: 'OK',

                primaryAction() {
                  tooltip.reset();
                }

              });
            }
          }

          return this.get('deleteRecord').perform().then(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        }

      });
    }).drop()
  });

  _exports.default = _default;
});