define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/tables/driver/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['driver-table'],
    config: _config.default,
    store: Ember.inject.service(),
    paginationEnabled: true,
    hasPendingChanges: false,
    enableWarningRowChangeAlert: false,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    rollbackRecord(record) {
      if (this.get('usingRadioRowComponent')) {
        if (record) {
          record.rollbackAttributes();
          const availabilities = record.get('sortedAvailabilities') || [];
          const shiftBreaks = availabilities.reduce((acu, availability) => {
            const breaks = availability.get('shiftBreaks').toArray() || [];
            acu.pushObjects(breaks);
            return acu;
          }, []);
          availabilities.forEach(availability => {
            if (!availability.get('isNew')) availability.rollbackAttributes();
          });
          shiftBreaks.forEach(shift => {
            if (!shift.get('isNew')) shift.rollbackAttributes();
          });
        }
      }
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        return records.filter(record => !record.get('isNew'));
      });
    }

  });

  _exports.default = _default;
});