define("adept-iq/models/driver-suspension", ["exports", "ember-data", "adept-iq/utils/unwrapProxy", "moment"], function (_exports, _emberData, _unwrapProxy, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    description: attr('string'),
    notes: attr('string', {
      defaultValue: ''
    }),
    startDate: attr({
      defaultValue: null
    }),
    endDate: attr({
      defaultValue: null
    }),
    driverViolation: belongsTo('driver-violation'),
    driver: belongsTo('driver'),
    hasViolation: Ember.computed('driverViolation', function () {
      const driverViolation = (0, _unwrapProxy.unwrapProxy)(this.get('driverViolation'));
      return Ember.isPresent(driverViolation);
    }),
    isSuspended: Ember.computed('startDate', 'endDate', function () {
      const curDate = (0, _moment.default)();
      const startDate = this.get('startDate');
      const endDate = this.get('endDate');

      if (startDate && endDate) {
        const momentStart = (0, _moment.default)(startDate);
        const momentEnd = (0, _moment.default)(endDate);
        return curDate.isBetween(momentStart, momentEnd);
      }

      return false;
    })
  });

  _exports.default = _default;
});