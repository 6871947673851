define("adept-iq/services/polyline", ["exports", "lodash", "ember-concurrency", "adept-iq/config/api-urls", "@mapbox/polyline", "adept-iq/mixins/async-schedule-operation"], function (_exports, _lodash, _emberConcurrency, _apiUrls, _polyline, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_CONCURRENCY = 2;

  var _default = Ember.Service.extend(_asyncScheduleOperation.default, {
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    async getPerformedPolyline(routeId) {
      const session = this.get('session');
      return await this.get('ajax').request(_apiUrls.API.avlmService.host + '/performed-polyline?routeId=' + routeId, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(response => {
        return response.data;
      });
    },

    fetchPerformedPolyline: (0, _emberConcurrency.task)(function* (routeId, dispatchRouteId) {
      const store = this.get('store');
      let stopPoints = [];
      yield this.getPerformedPolyline(routeId).then(response => {
        stopPoints = response;
      });
      if (Ember.isEmpty(stopPoints)) return [];
      const coords = stopPoints.reduce((arr, stopPoint) => {
        const points = stopPoint.polylineArray;
        if (Ember.isEmpty(points)) return arr;
        const perturbedPoints = [points.lat, points.lng]; // should flatten to single line

        arr.push(perturbedPoints);
        return arr;
      }, []);
      const payload = {
        data: {
          id: `polyline-${routeId}`,
          type: 'polyline',
          attributes: {
            coords
          }
        },
        included: [{
          id: dispatchRouteId,
          type: 'dispatch-route',
          relationships: {
            performedPolyline: {
              data: {
                id: `polyline-${routeId}`,
                type: 'polyline'
              }
            }
          }
        }]
      };
      store.push(payload);
    }).enqueue().maxConcurrency(MAX_CONCURRENCY),

    getScheduledPolyline(id) {
      const session = this.get('session');
      return this.get('ajax').request(_apiUrls.API.schedulingService.host + `/route/${id}?refreshPolyline= true`, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(response => {
        return JSON.parse(response.results);
      });
    },

    deserialize(serialized) {
      if (Ember.isEmpty(serialized)) return null;
      return _polyline.default.decode(serialized);
    },

    fetchScheduledPolyline: (0, _emberConcurrency.task)(function* (route) {
      const store = this.get('store');
      const routeId = route.get('id');
      const points = yield this.fetchRoutePolyLineTask.perform(route);
      const coords = this.deserialize(points); // regarding this bug (NYAAR-14584)

      (0, _lodash.forEach)(coords, (cord, index) => {
        const xCord = cord[0];
        const yCord = cord[1];

        if (xCord === 0 && yCord === 0) {
          coords.splice(index, 1);
          return false;
        }
      }); // eslint-disable-next-line no-console

      console.log('coords'.toUpperCase(), coords);
      const payload = {
        data: {
          id: `schedule-${routeId}`,
          type: 'schedule-polyline',
          attributes: {
            coords
          }
        },
        included: [{
          id: routeId,
          type: 'route',
          relationships: {
            scheduledPolyline: {
              data: {
                id: `schedule-${routeId}`,
                type: 'schedule-polyline'
              }
            }
          }
        }]
      };
      store.push(payload);
    }).enqueue().maxConcurrency(MAX_CONCURRENCY),
    fetchRoutePolyLineTask: (0, _emberConcurrency.task)(function* (route) {
      const schedule = route.get('schedule');
      const routeId = route.get('id');
      let plannedPolyline;

      try {
        const operationData = {
          routeId
        };
        const options = {
          operationData,
          outputResult: true
        };
        const {
          isJobSuccess,
          results
        } = yield this.createScheduleAsyncOperation.perform(schedule, 'routePolyline', options);

        if (isJobSuccess) {
          const parsedResults = JSON.parse(results);
          plannedPolyline = parsedResults.plannedPolyline;
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(plannedPolyline);
    }).drop()
  });

  _exports.default = _default;
});