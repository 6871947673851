define("adept-iq/pods/components/iq-widgets/booking-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "adept-iq/config/notifications", "adept-iq/config/mapped-permIds"], function (_exports, _component, _notifications, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['booking-dashboard'],
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    workspace: Ember.inject.service(),
    booking: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    searchedRiders: null,
    selectableRiders: null,
    passengerId: null,
    passengerFirstName: null,
    passengerLastName: null,
    passengerBookingTable: null,
    previousBookings: null,
    previousTrips: null,
    previousSubscriptions: null,
    disabledId: false,
    disabledFirstName: false,
    disabledLastName: false,
    disabledPhoneNumber: false,
    showEligibilityRestrictions: false,
    riderIdConditionsMap: null,
    ridersWithConditionalEligibility: null,
    filterRiders: null,
    canCreateSubscription: null,
    passengerPhoneNumber: null,
    availableZones: null,
    showOnlyActiveExternalRiders: true,
    newBookingComponent: 'iq-widgets/new-booking-form',
    subscriptionComponent: 'iq-widgets/subscription-form',
    isFirstTab: false,

    init() {
      this._super(...arguments);

      this.setProperties({
        searchedRiders: Ember.A(),
        previousBookings: [],
        previousSubscriptions: [],
        previousTrips: []
      });
      this.systemConfig.refresh();
      this.resetAllPreviousBookingsTripsSubscriptionAndRiders();
      this.set('canCreateSubscription', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createSubscription, null));
      const availableZones = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-NYCT-zones';
      });
      this.set('availableZones', availableZones);
      this.onPassengerWidgetRowClick = this.onPassengerWidgetRowClick.bind(this);
      this.setPassengerBookingTable = this.setPassengerBookingTable.bind(this);
      this.onGroupBookingConfirm = this.onGroupBookingConfirm.bind(this);
      this.proceedBooking = this.proceedBooking.bind(this);
      this.onEligibleWarningOK = this.onEligibleWarningOK.bind(this);
    },

    didInsertElement() {
      this._super(...arguments); // install keyboard listener


      this._onKeyDown = event => {
        Ember.run.schedule('actions', this, 'onKeyDown', event);
      };

      document.addEventListener('keydown', this._onKeyDown);
    },

    willDestroyElement() {
      document.removeEventListener('keydown', this._onKeyDown);
    },

    onKeyDown(event) {
      const element = document.activeElement.tagName;
      const el = document.activeElement;
      const inputElement = document.getElementsByTagName('input');
      const buttonElement = document.getElementsByTagName('button');

      if (event.key === 'Tab') {
        if (!this.isFirstTab && element === 'BODY') {
          event.preventDefault();
          inputElement[0].focus();
          this.set('isFirstTab', true);
        }
      }

      if (event.key === 'Enter') {
        if (el.getAttribute('name') === 'passenger-id' || el.getAttribute('name') === 'first-name' || el.getAttribute('name') === 'last-name' || el.getAttribute('name') === 'phone-number' || buttonElement[1].innerHTML === 'Search') {
          if ((el.getAttribute('type') === 'text' || buttonElement[1].innerHTML === 'Search') && !(el.getAttribute('type') === 'checkbox')) {
            if (el.getAttribute('name') === 'phone-number') {
              if (!Ember.isBlank(this.get('passengerFirstName')) && this.get('passengerFirstName').length >= 1) {
                this.searchPassenger();
              } else if (!Ember.isBlank(this.get('passengerLastName')) && this.get('passengerLastName').length >= 1) {
                this.searchPassenger();
              } else if (!Ember.isBlank(this.get('passengerPhoneNumber')) && this.get('passengerPhoneNumber').length >= 1) {
                this.searchPassenger();
              } else {
                this.get('notifications').warning('A full 10-digit phone number is required in order to search.');
              }
            } else {
              this.searchPassenger();
            }
          }
        }
      }
    },

    bookingRecords: Ember.observer('booking.currentBookings.[]', 'booking.currentBookings', function () {
      const currentBookings = this.get('booking.currentBookings');
      const sortedBookings = this.sortBookings(currentBookings);
      this.set('previousBookings', sortedBookings);
    }),
    tripRecords: Ember.observer('booking.currentTrips.[]', 'booking.currentTrips', 'booking.filteredCurrentTrips.[]', 'booking.filteredCurrentTrips', function () {
      const currentTrips = this.get('booking.filteredCurrentTrips');
      this.set('previousTrips', currentTrips);
    }),
    onSubscriptionRecordsChange: Ember.observer('booking.currentSubscriptions.[]', 'booking.currentSubscriptions', function () {
      const currentSubscriptions = this.get('booking.currentSubscriptions');
      this.set('previousSubscriptions', currentSubscriptions);
    }),
    selectableRidersChange: Ember.observer('searchedRiders', 'showOnlyActiveExternalRiders', function () {
      const showOnlyActiveExternalRiders = this.get('showOnlyActiveExternalRiders');
      const searchedRiders = this.get('searchedRiders');
      const selectedRiders = this.get('booking.selectedRiders');
      const collectedRiders = Ember.A();
      collectedRiders.pushObjects(selectedRiders);
      const collectedRidersIds = collectedRiders.map(r => r.get('id'));

      if (showOnlyActiveExternalRiders) {
        searchedRiders.forEach(rider => {
          if ((rider.get('status') === 'ACTIVE' || rider.get('status') === 'PENDING') && !collectedRidersIds.includes(rider.get('id'))) {
            collectedRiders.pushObject(rider);
          }
        });
        this.set('selectableRiders', collectedRiders);
      } else {
        searchedRiders.forEach(rider => {
          if (!collectedRidersIds.includes(rider.get('id'))) {
            collectedRiders.pushObject(rider);
          }
        }); // dont send same reference to selected riders. so not sending directly

        this.set('selectableRiders', collectedRiders);
      }

      const passengerBookingTable = this.get('passengerBookingTable');
      passengerBookingTable.get('rows').forEach(row => {
        if (collectedRidersIds.includes(row.get('id'))) {
          row.set('selected', true);
          row.set('isChecked', true);
        }
      });
    }),
    canDoBooking: Ember.computed('booking.selectedRiders.[]', function () {
      const canDoBooking = this.get('permissionLayer').permInUserHash(null, [_mappedPermIds.default.createFutureBookings, _mappedPermIds.default.createSameDayBookings]);

      if (!canDoBooking) {
        return false;
      }

      return this.get('booking.selectedRiders').length > 0;
    }),
    canDoSubscription: Ember.computed('booking.selectedRiders.[]', 'canCreateSubscription', function () {
      const canCreateSub = this.get('canCreateSubscription');

      if (this.get('booking.selectedRiders').length > 1) {
        return false;
      } else if (this.get('booking.selectedRiders').length > 0) {
        return true && canCreateSub;
      }
    }),
    isSearchControllDisabled: Ember.computed('passengerId', 'passengerFirstName', 'passengerLastName', 'passengerPhoneNumber', function () {
      const passengerId = this.get('passengerId');
      const passengerFirstName = this.get('passengerFirstName');
      const passengerLastName = this.get('passengerLastName');
      const passengerPhoneNumber = this.get('passengerPhoneNumber');

      if (!Ember.isBlank(passengerFirstName) && passengerFirstName.length >= 1 || !Ember.isBlank(passengerLastName) && passengerLastName.length >= 1 || !Ember.isBlank(passengerId) || !Ember.isBlank(passengerPhoneNumber)) {
        return false;
      }

      return true;
    }),

    updateSearchResultsWithSelectedRiders() {
      const selectedRiders = this.get('booking.selectedRiders');
      this.set('searchedRiders', selectedRiders);
    },

    setPassengerBookingTable(tableInstance) {
      this.set('passengerBookingTable', tableInstance);
    },

    sortBookings(bookings) {
      return bookings.sortBy('id').reverse();
    },

    async onPassengerWidgetRowClick() {
      const [bookings, trips] = await this.get('apiContext').loadBookingsForSelectedExternalRiders();
      const subscriptions = await this.get('apiContext').loadSubscriptionsForSelectedExternalRiders();
      this.set('booking.currentBookings', bookings);
      this.set('booking.currentTrips', trips);
      this.set('booking.currentSubscriptions', subscriptions);
    },

    showGroupBookingWarning() {
      this.get('tooltip').pushConfirmation({
        title: 'Create Booking',
        tip: 'Do you want to create a group booking?',
        primaryActionText: 'Confirm',
        primaryAction: this.onGroupBookingConfirm,
        secondaryActionText: 'Cancel',
        hasOverlay: true,
        secondaryAction: () => {
          this.get('tooltip').reset();
        }
      });
    },

    async onGroupBookingConfirm() {
      const isBookingEditMode = false;
      const newBookingComponent = this.get('newBookingComponent');
      await this.get('booking').fetchAllSelectedRidersForTravelNeeds();
      await this.get('booking').fetchAndStoreEligibilityForAllSelectedRiders();
      const booking = await this.get('booking').createBookingRecord(true);
      this.set('booking.activeBooking', booking);
      this.get('editModal').open(newBookingComponent, [isBookingEditMode, this.get('booking.selectedRiders.firstObject'), booking]);
      this.get('tooltip').reset();
    },

    async proceedBooking() {
      const isBookingEditMode = false;
      const newBookingComponent = this.get('newBookingComponent');

      if (this.get('booking.noOfSelectedRiders') > 1) {
        this.showGroupBookingWarning();
      } else {
        const booking = await this.get('booking').createBookingRecord(false);
        this.set('booking.activeBooking', booking);
        this.get('editModal').open(newBookingComponent, [isBookingEditMode, this.get('booking.selectedRiders.firstObject'), booking]);
      }
    },

    async onEligibleWarningOK() {
      this.set('showEligibilityRestrictions', false);
      await this.proceedBooking();
    },

    // reset when we switch back from scheduling workspace to booking workspace
    resetAllPreviousBookingsTripsSubscriptionAndRiders() {
      this.get('booking').clearSelectedRiders();
      this.set('booking.currentBookings', []);
      this.set('booking.filteredCurrentTrips', []);
      this.set('booking.currentSubscriptions', []);
    },

    async searchPassenger() {
      this.set('booking.hasMoreExternalRiders', false);
      const passengerId = this.get('passengerId');
      const passengerFirstName = this.get('passengerFirstName');
      const passengerLastName = this.get('passengerLastName');
      const passengerPhoneNumber = this.get('passengerPhoneNumber');
      const isShowActiveRider = this.get('showOnlyActiveExternalRiders');

      try {
        await this.get('activeContext').set('activeContexts', []);
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        let riders;
        const regex = new RegExp('^\\d{10}$');

        if (!Ember.isBlank(passengerId)) {
          riders = await this.store.find('rider-external', passengerId);
        } else {
          const params = {
            firstName: passengerFirstName,
            lastName: passengerLastName,
            phoneNumber: passengerPhoneNumber,
            activeOnly: isShowActiveRider
          };

          if (passengerPhoneNumber !== null && passengerPhoneNumber !== '' && !regex.test(passengerPhoneNumber)) {
            this.get('notifications').warning('A full 10-digit phone number is required in order to search.');
          } else {
            riders = await this.store.query('rider-external', params);
          }
        }

        this.get('workspace').set('isGlobalSpinnerVisible', false);

        if (Ember.isBlank(riders) || Ember.isEmpty(riders)) {
          this.set('searchedRiders', []);

          if (passengerPhoneNumber !== null && passengerPhoneNumber !== '' && !regex.test(passengerPhoneNumber)) {
            return;
          }

          this.get('notifications').info(_notifications.default.BOOKING.NO_MATCHES);
          return;
        }

        if (this.get('booking.hasMoreExternalRiders')) {
          this.get('notifications').warning('Too many matching results were found. Displaying the first 300 results. Please refine the search parameters');
          this.set('searchedRiders', riders.slice(0, 300));
          return;
        }

        riders = riders.length ? riders : [riders];
        this.set('searchedRiders', riders);
      } catch (e) {
        this.set('searchedRiders', []);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('notifications').info(_notifications.default.BOOKING.SEARCH_ERROR);
      }
    },

    actions: {
      async onCreateBookingClick() {
        const selectedRidersWithConditionalEligibility = this.get('booking.selectedRidersWithConditionalEligibility');

        if (selectedRidersWithConditionalEligibility.length > 0) {
          this.set('showEligibilityRestrictions', true);
          this.set('riderIdConditionsMap', this.get('booking').getRiderIdToConditionsMap({}));
          this.set('ridersWithConditionalEligibility', selectedRidersWithConditionalEligibility);
        } else {
          await this.proceedBooking();
        }
      },

      async onCreateSubscriptionClick() {
        const isBookingEditMode = false;
        const newBookingComponent = this.get('subscriptionComponent');
        const booking = await this.get('booking').createBookingRecord(false);
        this.set('booking.activeBooking', booking);
        this.get('editModal').open(newBookingComponent, [isBookingEditMode, this.get('booking.selectedRiders.firstObject'), booking]);
      },

      handleKeyUp() {
        const passengerId = this.get('passengerId');
        const passengerFirstName = this.get('passengerFirstName');
        const passengerLastName = this.get('passengerLastName');
        this.set('booking.hasMoreExternalRiders', false);

        if (!Ember.isBlank(passengerId)) {
          if (!this.get('disabledFirstName')) this.set('disabledFirstName', true);
          if (!this.get('disabledLastName')) this.set('disabledLastName', true);
          if (!this.get('disabledPhoneNumber')) this.set('disabledPhoneNumber', true);
        } else {
          if (this.get('disabledFirstName')) this.set('disabledFirstName', false);
          if (this.get('disabledLastName')) this.set('disabledLastName', false);
          if (this.get('disabledPhoneNumber')) this.set('disabledPhoneNumber', false);

          if (!Ember.isBlank(passengerFirstName) || !Ember.isBlank(passengerLastName)) {
            if (!this.get('disabledId')) this.set('disabledId', true);
          } else if (this.get('disabledId')) this.set('disabledId', false);
        }
      },

      async onSearch() {
        await this.searchPassenger();
      },

      onClearAll() {
        this.updateSearchResultsWithSelectedRiders();
        this.set('passengerId', null);
        this.set('passengerFirstName', null);
        this.set('passengerLastName', null);
        this.set('passengerPhoneNumber', null);
        this.set('previousBookings', []);
        this.set('previousSubscriptions', []);
        this.set('previousTrips', []);
        this.set('booking.currentBookings', []);
        this.set('booking.currentSubscriptions', []);
        this.set('disabledId', false);
        this.set('disabledFirstName', false);
        this.set('disabledLastName', false);
        this.set('booking.hasMoreExternalRiders', false);
      },

      async onEligibleWarningClose() {
        this.set('showEligibilityRestrictions', false);
      }

    }
  });

  _exports.default = _default;
});