define("adept-iq/serializers/rider", ["exports", "adept-iq/serializers/-rms-riderManagement", "moment"], function (_exports, _rmsRiderManagement, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rmsRiderManagement.default.extend({
    attrs: {
      riderId: {
        serialize: false
      },
      externalId: {
        serialize: false
      }
    },

    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      if (!Ember.isNone(json.data.attributes.dateOfBirth)) {
        json.data.attributes.dateOfBirth = (0, _moment.default)(json.data.attributes.dateOfBirth).format('YYYY-MM-DD');
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.dateOfBirth)) {
        resourceHash.attributes.dateOfBirth = (0, _moment.default)(resourceHash.attributes.dateOfBirth).format();
      }

      if (resourceHash.relationships && resourceHash.relationships.phoneNumbers) {
        resourceHash.relationships.riderPhoneNumbers = resourceHash.relationships.phoneNumbers;
        delete resourceHash.relationships.phoneNumbers;
      }

      return this._super(...arguments);
    },

    normalizeResponse(store, primaryModelClass, payload) {
      // convert these types name because of changes to api services
      // names.
      if (!Ember.isNone(payload.included) && !Ember.isNone(payload.included.length)) {
        for (let i = 0; i < payload.included.length; i++) {
          const include = payload.included[i];

          if (include.type === 'travelNeedTypeName') {
            include.type = 'travelNeedType';
          }

          if (include.type === 'passengerTypeName') {
            include.type = 'passengerType';
          }

          if (include.type === 'eligibilityTypeName') {
            include.type = 'eligibilityType';
          }

          if (include.type === 'riderPlaceTypeName') {
            include.type = 'riderPlaceType';
          }
        }
      }

      this._super(...arguments);

      return payload;
    }

  });

  _exports.default = _default;
});