define("adept-iq/pods/components/iq-widgets/subscription-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/subscription-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'subscription',
    defaultSortId: 'id',
    defaultSortAsc: false,
    checkBoxColumn: false,
    isFormControls: true,
    title: 'Subscriptions',
    columns: [{
      id: 'id',
      type: 'uuid',
      label: 'ID',
      valuePath: 'id',
      searchable: true,
      defaultWidth: 60,
      hidden: true
    }, {
      id: 'requestedTime',
      index: 0,
      type: 'text',
      label: 'Request/Time',
      valuePath: 'requestTimeForDisplay',
      editable: false,
      hidden: false,
      format: 'hh:mm A',
      defaultWidth: 100
    }, {
      id: 'externalId',
      index: 1,
      type: 'text',
      label: 'Subscription ID',
      valuePath: 'id',
      editable: true,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'groupId',
      index: 2,
      type: 'text',
      label: 'Group ID',
      valuePath: 'group.groupId',
      editable: true,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'status',
      index: 3,
      type: 'text',
      label: 'Status',
      valuePath: 'computedStatus',
      editable: true,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'riderId',
      index: 4,
      type: 'text',
      label: 'Passenger ID',
      valuePath: 'rider.riderId',
      editable: true,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'riderFirstName',
      index: 5,
      type: 'text',
      label: 'First Name',
      valuePath: 'rider.firstName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'riderLastName',
      index: 6,
      type: 'text',
      label: 'Last Name',
      valuePath: 'rider.lastName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100
    }, {
      id: 'pickupaddress',
      index: 7,
      type: 'text',
      label: 'Pick Address ',
      valuePath: 'origin.address.fullAddress',
      editable: true,
      hidden: false,
      defaultWidth: 200
    }, {
      id: 'pickCity',
      index: 8,
      type: 'text',
      label: 'Pick City ',
      valuePath: 'origin.address.region',
      editable: true,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'Start Date',
      index: 9,
      type: 'datetime',
      label: 'Start Date',
      valuePath: 'startDate',
      editable: true,
      format: 'MM/DD/YYYY',
      defaultWidth: 100
    }, {
      id: 'datetime',
      index: 10,
      type: 'datetime',
      label: 'End Date',
      valuePath: 'endDate',
      editable: false,
      searchable: false,
      hidden: false,
      format: 'MM/DD/YYYY',
      defaultWidth: 100
    }]
  };
  _exports.default = _default;
});