define("adept-iq/pods/components/side-drawer/perform-stop/break/container/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STOP_ONBREAK_STATUS = 'On break';

  var _default = Ember.Component.extend({
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    // passed in by side-drawer-container component
    onShrinkClick: null,
    onCloseClick: null,
    stopPoint: null,
    driverBreak: null,
    arrive: false,
    depart: false,
    shouldArrive: true,
    route: Ember.computed.readOnly('stopPoint.dispatchRoute.route'),
    routeVehicleDriver: Ember.computed.readOnly('route.dispatchRoute.latestRouteVehicleDriver'),
    address: Ember.computed.readOnly('driverBreak.place.address'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    actualArriveTime: Ember.computed('stopPoint.routeBreak.actualArriveTime', function () {
      const arriveTime = this.get('stopPoint.routeBreak.actualArriveTime');

      if (Ember.isNone(arriveTime)) {
        return (0, _moment.default)().startOf('minute').toDate();
      }

      return arriveTime;
    }),
    actualDepartTime: Ember.computed('stopPoint.{actualDepartTime,actualArriveTime}', 'actualArriveTime', function () {
      const departTime = this.get('stopPoint.actualDepartTime');
      const curDate = this.get('actualArriveTime');

      if (Ember.isNone(departTime)) {
        return curDate;
      }

      return departTime;
    }),

    setupDriverBreak(stopPoint) {
      const driverBreak = this.store.peekRecord('route-break', stopPoint.get('routeBreak.id'));
      const breakType = driverBreak.get('breakType.content');
      let place = driverBreak.get('place.content');

      if (breakType.get('name') === 'floating' && Ember.isEmpty(place)) {
        const origin = this.store.createRecord('location');
        const address = this.store.createRecord('address', {
          type: 'vehicle',
          locations: [origin]
        });
        place = this.store.createRecord('place', {
          address: address,
          location: origin
        });
        driverBreak.set('place', place);
      }

      this.set('driverBreak', driverBreak);
    },

    init() {
      this._super(...arguments);

      const stopPoint = this.get('stopPoint');
      this.setupDriverBreak(stopPoint);
      this.set('arrive', this.get('isArrived'));
    },

    isArrived: Ember.computed('stopPoint.computedStatus', function () {
      const status = this.get('stopPoint.computedStatus');

      if (status) {
        return status === STOP_ONBREAK_STATUS;
      }

      return false;
    }),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      const stopPoint = this.get('stopPoint');
      const actualArriveTime = stopPoint.get('actualArriveTime');
      const actualDepartTime = stopPoint.get('actualDepartTime');
      const curDate = (0, _moment.default)().startOf('minute').toDate();
      stopPoint.rollback();

      if (Ember.isEmpty(actualArriveTime)) {
        stopPoint.set('actualArriveTime', curDate);
      }

      if (Ember.isEmpty(actualDepartTime)) {
        stopPoint.set('actualDepartTime', curDate);
      }

      const isArrived = this.get('isArrived');
      stopPoint.set('odometer');
      this.set('arrive', isArrived);
      this.set('depart', false);
      yield this.setupDriverBreak(stopPoint);
    }).keepLatest(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const stopPoint = this.get('stopPoint');
      const isArrived = this.get('isArrived');
      const savePromises = [];
      stopPoint.set('odometer', null);

      if (this.get('arrive') && !isArrived) {
        const arrive = yield routeExecEventAdapter.createStartBreakEvent(stopPoint);
        savePromises.push(arrive);
      }

      if (this.get('depart')) {
        const depart = yield routeExecEventAdapter.createEndBreakEvent(stopPoint);
        savePromises.push(depart);
      }

      return Ember.RSVP.all(savePromises);
    }).drop(),

    didInsertElement() {
      const setStopContainerReference = this.get('setStopContainerReference');
      const stopPoint = this.get('stopPoint');
      this.setupDriverBreak(stopPoint);

      if (setStopContainerReference) {
        setStopContainerReference(this);
      }
    },

    actions: {
      onRefreshButtonClick() {
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        this.onCloseClick();
      },

      onPerformButtonClick() {
        const tooltip = this.get('tooltip');
        const route = this.get('route');
        const stopPoint = this.get('stopPoint');
        const arrive = this.get('arrive');
        const depart = this.get('depart');
        const routeEndTime = route.get('plannedEndTime');
        const arriveTime = (0, _moment.default)(this.get('actualArriveTime'));
        const departTime = (0, _moment.default)(this.get('actualDepartTime'));
        const actualStartTime = route.get('dispatchRoute.computedStartTime');
        const routeOrdinal = stopPoint.get('routeOrdinal');
        const routeStops = route.get('dispatchRoute.orderedStopsWithGaragesAndBreaks');
        const lastPerformedStop = routeStops.filter(stop => stop.isPerformed).get('lastObject');
        const currentDateTime = (0, _moment.default)();
        const title = 'Perform Break';
        let messageText = '';
        let tip = ''; // Reset error text

        this.set('errorText');
        this.set('stopPoint.actualArriveTime', this.get('actualArriveTime'));
        this.set('stopPoint.actualDepartTime', this.get('actualDepartTime'));
        this.set('stopPoint.routeBreak.actualArriveTime', this.get('actualArriveTime'));
        this.set('stopPoint.routeBreak.actualDepartTime', this.get('actualDepartTime'));

        if (arrive && depart) {
          messageText = 'Performed break';
          tip = 'Perform break?';
        } else if (arrive) {
          messageText = 'Started break';
          tip = 'Start break?';
        } else if (depart) {
          messageText = 'Ended break';
          tip = 'End break?';
        } else {
          this.set('errorText', 'Please fill in either Actual Start or Actual End.');
          return false;
        }

        if (arriveTime.isBefore(actualStartTime) || departTime.isBefore(actualStartTime)) {
          this.set('errorText', "Performed Break Stop Failed. The stop cannot be performed before the route's start time");
          return false;
        }

        if (arriveTime.isAfter(routeEndTime) || departTime.isAfter(routeEndTime)) {
          this.set('errorText', "Performed Break Stop Failed. The stop cannot be performed after the route's end time.");
          return false;
        }

        if (arriveTime.isAfter(currentDateTime) || departTime.isAfter(currentDateTime)) {
          this.set('errorText', 'Performed Break Stop Failed. The stop cannot be performed after the current time.');
          return false;
        }

        if (routeOrdinal > 0 && lastPerformedStop && !lastPerformedStop.get('isNoShow')) {
          if (arriveTime.isBefore(lastPerformedStop.get('actualDepartTime')) || departTime.isBefore(lastPerformedStop.get('actualDepartTime'))) {
            this.set('errorText', 'Performed Break Stop Failed. The stop cannot be performed before the previous Actual drop time.');
            return false;
          }
        }

        const coord = this.get('stopPoint.routeBreak.place.location');

        if (Ember.isNone(coord.get('lat')) || Ember.isNone(coord.get('lng'))) {
          this.set('errorText', 'Performed Break Stop Failed. Cannot perform break without location.');
          return false;
        }

        tooltip.pushConfirmation({
          tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('saveTask').perform().then(() => {
              this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['stop-point']);
              this.get('notifications').success(`${messageText} successfully`);
              this.onCloseClick();
            }).catch(response => {
              // clear odometer in stop point model
              stopPoint.set('odometer');
              const payloadMessage = Ember.get(response, 'payload.message') ? JSON.parse(Ember.get(response, 'payload.message')) : null;
              messageText = messageText + ' failed';

              if (response.status === 400) {
                if (payloadMessage.errorDetail.includes('cannot be in state arrived unless all previous stops are performed') || payloadMessage.errorDetail.includes('with status arrived cannot come after')) {
                  messageText = 'Performed Break Failed. You cannot perform this break until previous stops are performed';
                }
              }

              this.get('notifications').warning(`${messageText}`);
            }).finally(() => {
              tooltip.reset();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      }

    }
  });

  _exports.default = _default;
});