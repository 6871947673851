define("adept-iq/services/workspace-context", ["exports", "adept-iq/config/environment", "adept-iq/classes/work-queue"], function (_exports, _environment, _workQueue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // refresh widgets at least this often (if fresh data available)
  const MIN_RELOAD_INTERVAL = 5000;
  const LOCAL_STORAGE_KEY = 'dashboard-info';

  var _default = Ember.Service.extend(Ember.Evented, {
    session: Ember.inject.service(),
    map: Ember.inject.service(),
    socket: Ember.inject.service(),
    store: Ember.inject.service(),
    work: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    user: Ember.inject.service(),
    providerNames: Ember.computed.readOnly('session.data.authenticated.tokenInfo.providerNames'),
    // work queues
    normalizeQueue: null,
    storeQueue: null,
    reloadQueue: null,
    nodeQueue: null,
    // internal data structures
    _structuredWorkspace: null,
    _selectedProviders: null,
    // all providers are selected in dropdown
    _allProviders: true,
    // keeps track of records that have been (or are pending) deletion
    _deletedLookup: null,
    // keeps track of model types that may have been loaded or unloaded; used to
    // refresh widgets
    _dirtyModelNames: null,
    // Serializer Dict
    _serializerDict: null,

    init() {
      this._super(...arguments);

      this.set('_deletedLookup', {});
      this.set('_dirtyModelNames', []);
      this.set('_serializerDict', {}); // this queue handles ember data normalization

      const normalizeQueueOptions = Object.assign({}, _environment.default.work['wc-normalize'], {
        name: 'wc-normalize',
        perform: jobs => {
          jobs.forEach(job => {
            this._processNormalizeJob(job);
          });
        }
      });

      const normalizeQueue = _workQueue.default.create(normalizeQueueOptions);

      this.get('work').registerQueue(normalizeQueue);
      this.set('normalizeQueue', normalizeQueue); // this queue handles pushing records into ember store

      const storeQueueOptions = Object.assign({}, _environment.default.work['wc-store'], {
        name: 'wc-store',
        perform: jobs => {
          jobs.forEach(job => {
            this._processStoreJob(job);
          });
        }
      });

      const storeQueue = _workQueue.default.create(storeQueueOptions);

      this.get('work').registerQueue(storeQueue);
      this.set('storeQueue', storeQueue); // this queue handles notifying the UI for refresh

      const reloadQueueOptions = Object.assign({}, _environment.default.work['wc-reload'], {
        name: 'wc-reload',
        workspace: this.get('workspace'),
        perform: jobs => {
          jobs.forEach(job => this._processReloadJob(job));
        }
      });

      const reloadQueue = _workQueue.default.extend({
        isDisabled: false
      }).create(reloadQueueOptions);

      this.get('work').registerQueue(reloadQueue);
      this.set('reloadQueue', reloadQueue);
      const nodeQueueOptions = Object.assign({}, _environment.default.work['wc-node'], {
        name: 'wc-node',
        perform: jobs => {
          jobs.forEach(job => {
            this._processNodeJob(job);
          });
        }
      });

      const nodeQueue = _workQueue.default.extend({
        isDisabled: false
      }).create(nodeQueueOptions);

      this.get('work').registerQueue(nodeQueue);
      this.set('nodeQueue', nodeQueue);
      this.loadAPIData(); // activates observer and triggers initial load

      this.onDatesChange();
    },

    destroy() {
      ['storeQueue', 'normalizeQueue', 'reloadQueue', 'nodeQueue'].forEach(queueName => {
        const queue = this.get(queueName);
        this.get('work').unregisterQueue(queue);
        queue.destroy();
      });
      this.set('_deletedLookup', null);
      this.get('_dirtyModelNames').clear();
      this.set('_structuredWorkspace', null);

      this._super(...arguments);
    },

    // flattened for easy binding & iteration
    workspaceData: Ember.computed('_structuredWorkspace', function () {
      const data = this.get('_structuredWorkspace');
      return Object.entries(data).reduce((arr, [modelName, records]) => {
        records.forEach(record => {
          arr.push({
            modelName,
            record
          });
        });
        return arr;
      }, []);
    }),
    onDatesChange: Ember.observer('workspace.{startDate,endDate}', 'activeContext.topActiveContext', function () {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const topActiveContext = this.get('activeContext.topActiveContext');
      const isScheduleDashboard = this.get('workspace.isScheduleDashboard');
      if (!startDate || !endDate || !topActiveContext) return;
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();

      if (startTime < endTime) {
        Ember.run.scheduleOnce('afterRender', this, 'resetAndConnect');
      } // workspace dates change need to refresh the metric widgets


      if (isScheduleDashboard) {
        Ember.run.scheduleOnce('afterRender', this, 'refreshMetricWidget');
      }
    }),
    onWorkspaceChange: Ember.observer('_structuredWorkspace', function () {
      Ember.run.scheduleOnce('afterRender', this, 'refreshMap');
      Ember.run.scheduleOnce('afterRender', this, 'refreshWidgets');
    }),

    resetAndConnect() {
      this.get('reloadQueue').addJob('reload'); // reconnect to sockets

      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      (true && !(startTime < endTime) && Ember.assert('workspace startTime must predate endTime', startTime < endTime)); // this will wait for any sync topics before resolving

      this.get('socket').connect({
        startDate,
        endDate
      });
    },

    refreshMetricWidget() {
      const refreshTableContent = this.get('activeContext.topActiveContext.refreshTableContent');
      refreshTableContent.perform(['metric']);
    },

    async setupProviderList() {
      if (Ember.isEmpty(this.get('providersList'))) {
        const providers = await this.get('store').findAll('provider');
        const activeProviders = providers.filter(provider => {
          return !Ember.isEmpty(provider.get('name')) && !Ember.isEmpty(provider.get('id')) && provider.get('userCanAccessProvider');
        });
        this.set('providersList', activeProviders);
      }

      this.setSelectedProviders();
    },

    setSelectedProviders() {
      const activeSelectedProviders = this.getCurrentProviderSelection();
      this.set('_selectedProviders', activeSelectedProviders);
    },

    getCurrentProviderSelection() {
      const isRoadSupEnable = this.user.isRoadSupEnable();
      const dashboardInfo = localStorage.getItem(LOCAL_STORAGE_KEY);
      const providers = this.get('providersList') || [];

      if (_environment.default.APP.avlmLite) {
        if (isRoadSupEnable && dashboardInfo) {
          const parsedDashboardInfo = JSON.parse(dashboardInfo);

          if (parsedDashboardInfo && parsedDashboardInfo.accessData) {
            const accessDataObj = parsedDashboardInfo.accessData;
            const provider = this.store.peekRecord('provider', accessDataObj.provider);

            if (!provider) {
              const newProvider = this.store.createRecord('provider', {
                id: accessDataObj.provider,
                name: accessDataObj.provider,
                isChecked: true
              });
              providers.push(newProvider);
            }
          }
        }
      }

      return providers.filter(record => {
        if (isRoadSupEnable && (this.providerNames.includes('ALL') || this.providerNames.includes(record.id))) return true;
        if (this._allProviders) return true;
        return record.isChecked;
      });
    },

    // loads static data at application start
    loadAPIData() {
      const store = this.get('store');
      return Ember.RSVP.all([store.findAll('cs-config-category'), store.findAll('cs-config-item'), store.findAll('sso-role'), store.findAll('route-template'), // TODO: remove this for R4
      store.findAll('zone'), store.findAll('route'), store.findAll('dispatch-route'), store.findAll('stop-point')]);
    },

    pushPayloads(payloads) {
      payloads.forEach((payload, i) => {
        this.pushPayload(payload, i === payloads.length - 1);
      });
    },

    pushPayload(payload, start = true) {
      const flattenedPayloads = this._flattenPayload(payload);

      flattenedPayloads.forEach(flattenedPayload => {
        if (_environment.default.APP.ENABLE_HIGH_PRIORITY_MODELS) {
          if (_environment.default.APP.HIGH_PRIORITY_MODELS[flattenedPayload.data.type]) {
            this.get('normalizeQueue').unshiftJob({
              payload: flattenedPayload
            });
          } else {
            this.get('normalizeQueue').pushJob({
              payload: flattenedPayload
            });
          }
        } else {
          this.get('normalizeQueue').pushJob({
            payload: flattenedPayload
          });
        }
      });

      if (start) {
        this.get('work').start();
      }
    },

    removeRecord(modelName, id) {
      // this may not actually exist yet
      const record = this.get('store').peekRecord(modelName, id);
      const storeQueue = this.get('storeQueue');
      storeQueue.pushJob({
        action: 'remove',
        modelName,
        id,
        record
      });
      this.get('work').start();
    },

    manualStorePayload(payload) {
      const store = this.get('store');

      const normalizedPayload = this._normalizePayload(payload);

      const completedPayload = this._completePayload(normalizedPayload);

      store.pushPayload(completedPayload);
    },

    async manualReloadContext() {
      this._processReloadJob();

      const nodeQueue = this.get('nodeQueue');
      return nodeQueue.start();
    },

    manualReloadContextByModel(modelName) {
      const activeContextNodes = this.get('activeContext.topActiveContext.nodes') || [];
      this.get('nodeQueue').addJob({
        node: activeContextNodes.findBy('modelName', modelName)
      });
      this.get('work').start();
    },

    refreshMap() {
      this.get('map.mapContexts').forEach(mapContext => {
        mapContext.trigger('refresh');
      });
    },

    refreshWidgets() {
      const activeContextNodes = this.get('activeContext.topActiveContext.nodes') || [];
      const dirtyModelNames = this.get('_dirtyModelNames').slice(); // expand dirty model names

      const expandedModelNames = dirtyModelNames.reduce((arr, modelName) => {
        arr.addObject(modelName);
        const sourceNode = activeContextNodes.findBy('modelName', modelName);
        if (!sourceNode) return arr;
        return arr;
      }, []);
      this.get('_dirtyModelNames').clear();
      this.trigger('change', expandedModelNames);
    },

    _processNormalizeJob(job) {
      const storeQueue = this.get('storeQueue');

      const normalizedPayload = this._normalizePayload(job.payload);

      if (!normalizedPayload) {
        return;
      }

      if (_environment.default.APP.ENABLE_HIGH_PRIORITY_MODELS) {
        if (_environment.default.APP.HIGH_PRIORITY_MODELS[normalizedPayload.data.type]) {
          storeQueue.unshiftJob({
            action: 'push',
            payload: normalizedPayload
          });
        } else {
          storeQueue.pushJob({
            action: 'push',
            payload: normalizedPayload
          });
        }
      } else {
        storeQueue.pushJob({
          action: 'push',
          payload: normalizedPayload
        });
      }
    },

    _processStoreJob(job) {
      const store = this.get('store');
      const dirtyModelNames = this.get('_dirtyModelNames');

      switch (job.action) {
        case 'push':
          {
            // complete at last second in case related entities have been loaded
            if (job.payload.data.id !== '0') {
              const completedPayload = this._completePayload(job.payload); // because it does not process `included` array the same way


              try {
                store.pushPayload(completedPayload);
              } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e, completedPayload);
              }

              dirtyModelNames.addObject(job.payload.data.type);
            }

            break;
          }

        case 'remove':
          {
            const record = job.record || store.peekRecord(job.modelName, job.id);

            if (record && !record.get('isRemoved')) {
              // this allow for faster filtering later
              const key = `${record.constructor.modelName}:${record.get('id')}`;
              record.set('isRemoved', true); // mark record as deleted so it will be excluded from widget refreshes

              this._deletedLookup[key] = true;
              Ember.run.scheduleOnce('afterRender', this, () => {
                // deleted payload comes twice, only attempt unload once
                if (!this._deletedLookup[key]) {
                  // WATCHOUT for updating ember source these internal apis might be depreciated in future versions of EMBER
                  // transitioning to deleted.save state, ember internal docs says this should update all other record associated belongsTo / hasMany
                  record.get('_internalModel').transitionTo('deleted.saved'); // deleting the record client requires these two statements

                  record.unloadRecord();

                  store._removeFromIdMap(record._internalModel);
                }
              });
            }

            dirtyModelNames.addObject(job.modelName);
            break;
          }

        default:
          break;
      }

      const t = new Date();
      const reloadQueue = this.get('reloadQueue');
      const lastReloadAt = reloadQueue.get('lastRunAt'); // run when both queues clear, or at least every MIN_RELOAD_INTERVAL ms

      if (this.get('storeQueue.length') === 0 && this.get('normalizeQueue.length') === 0 && this.get('reloadQueue.length') === 0) {
        reloadQueue.addJob('reload');
      } else if ((!lastReloadAt || t - lastReloadAt > MIN_RELOAD_INTERVAL) && this.get('reloadQueue.length') === 0) {
        reloadQueue.addJob('reload');
      }
    },

    _processReloadJob() {
      const activeContextNodes = this.get('activeContext.topActiveContext.nodes') || [];
      const isRoadSupEnable = this.user.isRoadSupEnable();
      const dashboardInfo = localStorage.getItem(LOCAL_STORAGE_KEY);
      const providers = this.get('providersList') || []; // FIXME: this should be done once elsewhere

      if (_environment.default.APP.avlmLite) {
        if (isRoadSupEnable && dashboardInfo) {
          const parsedDashboardInfo = JSON.parse(dashboardInfo);

          if (parsedDashboardInfo && parsedDashboardInfo.accessData) {
            const accessDataObj = parsedDashboardInfo.accessData;
            const provider = this.store.peekRecord('provider', accessDataObj.provider);

            if (!provider) {
              const newProvider = this.store.createRecord('provider', {
                id: accessDataObj.provider,
                name: accessDataObj.provider,
                isChecked: true
              });
              providers.push(newProvider);
            }
          }
        }
      }

      this.set('_selectedProviders', providers.filter(record => {
        if (isRoadSupEnable && (this.providerNames.includes('ALL') || this.providerNames.includes(record.id))) return true;
        if (this._allProviders) return true;
        return record.isChecked;
      }));
      const providerDict = providers.reduce((acu, item) => {
        acu[item.id] = item.isChecked;
        return acu;
      }, {});
      this.set('_providerDict', providerDict);

      if (this.get('workspace.isRoadSupervisorModeEnabled')) {
        // When in navigation mode, only update avlm-message node.
        this.get('nodeQueue').addJob({
          node: activeContextNodes.findBy('id', 'avlm-message'),
          forceRefresh: false
        });
        return;
      }

      activeContextNodes.forEach((node, i) => {
        // trigger map & widget refresh after processing last node of this reload
        const forceRefresh = i === activeContextNodes.length - 1;
        this.get('nodeQueue').addJob({
          node,
          forceRefresh
        });
      });
    },

    _filterRecordsBySchedule(peekedRecords, node) {
      const isScheduleDashboard = this.get('workspace.isScheduleDashboard');
      const currentSchedule = this.get('workspace.currentSchedule');
      const scheduleFilterKey = node.scheduleFilterKey || false;
      if (!isScheduleDashboard || !currentSchedule || !scheduleFilterKey) return peekedRecords;
      return peekedRecords.filter(record => {
        const schedules = Ember.makeArray(record.get(scheduleFilterKey));
        if (Ember.isEmpty(schedules)) return false;
        return schedules.some(schedule => parseInt(schedule.get('id'), 10) === parseInt(currentSchedule.get('id'), 10));
      });
    },

    _filterRecordsByProvider(node) {
      // Perfomance mode do not need provider filters
      const store = this.get('store');
      const peekedRecords = store.peekAll(node.modelName).toArray() || [];
      return peekedRecords;
    },

    _processNodeJob(job) {
      const {
        node,
        forceRefresh
      } = job;
      const obj = this.get('_structuredWorkspace');
      if (!obj) return {}; // structured workspace hasn't been made yet till active context bindings occur

      let peekedRecords = this._filterRecordsByProvider(node);

      peekedRecords = this._filterRecordsBySchedule(peekedRecords, node);
      /* eslint-enable no-continue */

      obj[node.modelName] = obj[node.modelName] || [];
      obj[node.modelName].splice(0, obj[node.modelName].length, ...peekedRecords);

      if (forceRefresh) {
        // only trigger observers if specifically instructed
        this.notifyPropertyChange('_structuredWorkspace');
      }
    },

    _isRecordRemoved(record) {
      switch (record.constructor.modelName) {
        case 'avlm-trip':
          return record.get('status') === 'X';

        case 'avlm-stop-point':
          return record.get('status') === 'R';

        case 'avlm-alert':
          return record.get('state') === 'completed';

        default:
          return record.get('isRemoved');
      }
    },

    // recursively unpacks included payloads into a flat array
    _flattenPayload(payload) {
      const q = [payload];
      const flattened = [];

      const handleIncludedPayload = data => {
        if (data.included) {
          // non-standard, but does happen in IQUX
          delete data.included;
          q.push({
            data: data.included
          });
        } else {
          q.push({
            data
          });
        }
      };

      while (q.length) {
        const p = q[q.length - 1];

        if (p.included) {
          p.included.forEach(handleIncludedPayload);
          delete p.included;
        } else {
          flattened.push(p);
          q.pop();
        }
      }

      return flattened;
    },

    // processes a payload with its serializers' `normalize()` method
    _normalizePayload(payload) {
      const store = this.get('store');
      const serializerDict = this.get('_serializerDict');

      if (!serializerDict[payload.data.type]) {
        try {
          const modelClass = store.modelFor(payload.data.type);
          const serializer = store.serializerFor(payload.data.type);

          if (!modelClass || !serializer) {
            console.error('no modelClass or serializer for ' + payload.data.id); //eslint-disable-line no-console
          }

          serializerDict[payload.data.type] = {
            modelClass,
            serializer
          };
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(payload, e);
        }
      } // use normalizeResponse because some included part we need to handle


      if (serializerDict[payload.data.type].serializer.normalizeWidgetRecord) {
        try {
          return serializerDict[payload.data.type].serializer.normalizeWidgetRecord(store, serializerDict[payload.data.type].modelClass, payload, payload.data.id, 'createRecord');
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e, payload);
        }
      }
    },

    // adds placeholder models to payload's `included` hash for any related
    // models that don't exist in store yet
    _completePayload(payload) {
      const store = this.get('store');
      if (!payload.data) return payload;
      payload.included = payload.included || []; // if included already contains data don't generate payload.

      const includeDict = payload.included.reduce((dict, inc) => {
        dict[inc.type] = true;
        return dict;
      }, {});

      if (payload.data.relationships === {}) {
        delete payload.data.relationships;
      }

      if (payload.data.relationships) {
        Object.values(payload.data.relationships).forEach(rel => {
          if (Array.isArray(rel.data)) {
            rel.data.forEach(data => {
              if (!store.peekRecord(data.type, data.id) || data.id !== '0') {
                // sso-user need to query from sso services
                // vehicle-type & provider from config service
                if (data.type !== 'sso-user' && data.type !== 'vehicle-type' && data.type !== 'provider' && !includeDict[data.type]) {
                  payload.included.push(data);
                }
              }
            });
          } else if (rel.data) {
            if (!store.peekRecord(rel.data.type, rel.data.id) || rel.data.id !== '0') {
              // sso-user need to query from sso services
              // vehicle-type & provider from config service
              if (rel.data.type !== 'sso-user' && rel.data.type !== 'vehicle-type' && rel.data.type !== 'provider' && !includeDict[rel.data.type]) {
                payload.included.push(rel.data);
              }
            }
          }
        });
      }

      if (Ember.isEmpty(payload.included)) {
        delete payload.included;
      }

      return payload;
    }

  });

  _exports.default = _default;
});