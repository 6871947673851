define("adept-iq/utils/vehicleCapacity", ["exports", "adept-iq/models/travel-need-type"], function (_exports, _travelNeedType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notEnoughSeatsTripInRoute = _exports.currentStopCapacity = _exports.isUnderCapacity = _exports.notEnoughCapacity = void 0;

  const notEnoughCapacity = (travelNeedCounts, capacityCounts) => {
    if (!capacityCounts) return false;
    return Object.entries(travelNeedCounts).some(([travelNeedTypeName, travelNeedCount]) => {
      const capacityCount = capacityCounts[travelNeedTypeName] || 0;
      return capacityCount < travelNeedCount;
    });
  }; // will travel need have enough capacity for this flex seat group


  _exports.notEnoughCapacity = notEnoughCapacity;

  function flexSeatHaveCapacity(travelNeed, flexSeat) {
    return Object.entries(travelNeed).every(([travelNeedTypeName, travelNeedCount]) => {
      const capacityCount = flexSeat[travelNeedTypeName] || 0;
      return capacityCount >= travelNeedCount;
    });
  }

  const isUnderCapacity = (dispatchRoute, vehicleCapacityCounts) => {
    const clusters = dispatchRoute.get('clusters').toArray();
    const flexSeats = vehicleCapacityCounts.flexSeats;
    let haveCapcity = true;
    if (clusters.length < 1) return !haveCapcity; // have to check max travel needs for each cluster with all possible flex seats

    haveCapcity = clusters.every(cluster => {
      const travelNeed = cluster.get('maxTravelNeedCounts');
      let haveFlexCapcity = false;
      if (!travelNeed || !flexSeats || flexSeats.length < 1) return true;
      haveFlexCapcity = flexSeats.some(flexSeat => {
        return flexSeatHaveCapacity(travelNeed, flexSeat);
      });
      return haveFlexCapcity;
    });
    return !haveCapcity;
  }; // returns the current capacity available before stopPoint


  _exports.isUnderCapacity = isUnderCapacity;

  const currentStopCapacity = (stopPoint, dispatchRoute, vehicle) => {
    const orderedStops = dispatchRoute.get('orderedStops');
    const capacityCounts = vehicle ? vehicle.get('capacityCounts') : {};
    const currentTravelNeedCapacity = {
      ambulatory: capacityCounts.ambulatory || 0,
      wheelchair: capacityCounts.wheelchair || 0
    };

    for (const stop of orderedStops) {
      if (stop.id === stopPoint.get('id')) {
        break;
      }

      const tripTravelNeedCounts = stop.get('trip.travelNeedCounts');

      if (stop.get('isPick')) {
        currentTravelNeedCapacity.ambulatory -= tripTravelNeedCounts.ambulatory;
        currentTravelNeedCapacity.wheelchair -= tripTravelNeedCounts.wheelchair;
      } else if (stop.get('isDrop')) {
        currentTravelNeedCapacity.ambulatory += tripTravelNeedCounts.ambulatory;
        currentTravelNeedCapacity.wheelchair += tripTravelNeedCounts.wheelchair;
      }
    }

    return currentTravelNeedCapacity;
  }; // return trips not having enough seats given new travelNeedItems for stopPoint


  _exports.currentStopCapacity = currentStopCapacity;

  const notEnoughSeatsTripInRoute = (travelNeedItems, stopPoint, dispatchRoute, vehicle) => {
    const ambulatoryTravelNeedItem = travelNeedItems.find(item => item.type === _travelNeedType.AMBULATORY_TRAVELNEED_TYPE);
    const wheelchairTravelNeedItem = travelNeedItems.find(item => item.type === _travelNeedType.WHEELCHAIR_TRAVELNEED_TYPE);
    const orderedStops = dispatchRoute.get('orderedStops');
    const capacityCounts = vehicle ? vehicle.get('capacityCounts') : {};
    const currentTripId = stopPoint.get('trip.tripId');
    const currentTravelNeedCapacity = {
      ambulatory: capacityCounts.ambulatory || 0,
      wheelchair: capacityCounts.wheelchair || 0
    };
    const tripsInDanger = [];

    for (const stop of orderedStops) {
      const tripTravelNeedCounts = stop.get('trip.travelNeedCounts');
      const stopTripId = stop.get('trip.tripId');

      if (stopTripId === currentTripId) {
        if (stop.get('isPick')) {
          currentTravelNeedCapacity.ambulatory -= ambulatoryTravelNeedItem.count;
          currentTravelNeedCapacity.wheelchair -= wheelchairTravelNeedItem.count;
        } else if (stop.get('isDrop')) {
          currentTravelNeedCapacity.ambulatory += ambulatoryTravelNeedItem.count;
          currentTravelNeedCapacity.wheelchair += wheelchairTravelNeedItem.count;
        }
      } else if (stopTripId !== currentTripId) {
        if (stop.get('isPick')) {
          currentTravelNeedCapacity.ambulatory -= tripTravelNeedCounts.ambulatory;
          currentTravelNeedCapacity.wheelchair -= tripTravelNeedCounts.wheelchair;
        } else if (stop.get('isDrop')) {
          currentTravelNeedCapacity.ambulatory += tripTravelNeedCounts.ambulatory;
          currentTravelNeedCapacity.wheelchair += tripTravelNeedCounts.wheelchair;
        }
      }

      if (stop.get('isPick')) {
        if (currentTravelNeedCapacity.ambulatory < 0 || currentTravelNeedCapacity.wheelchair < 0) {
          tripsInDanger.pushObject(stopTripId);
        }
      }
    }

    return tripsInDanger;
  };

  _exports.notEnoughSeatsTripInRoute = notEnoughSeatsTripInRoute;
});