define("adept-iq/pods/components/side-drawer/unperform-drop-stop/component", ["exports", "ember-concurrency", "adept-iq/config/environment", "adept-iq/models/stop-point"], function (_exports, _emberConcurrency, _environment, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['perform-stop-drawer'],
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    timeFormat: _environment.default.dateTimeFormat.timeFlatPickr,
    // passed in by side-drawer-container component
    onShrinkClick: null,
    onCloseClick: null,
    errorText: null,
    unperformTitle: 'Unperform',
    unperformMessage: 'Unperforming Stop will NOT change Appointment Time.',

    didReceiveAttrs() {
      this._super(...arguments);

      this.get('refreshTask').perform();
    },

    stopPoint: Ember.computed.readOnly('stackFrame.options.stopPoint'),
    title: Ember.computed.readOnly('stackFrame.options.title'),
    trip: Ember.computed.readOnly('stopPoint.trip'),
    route: Ember.computed.readOnly('stopPoint.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    routeVehicleDriver: Ember.computed.readOnly('route.dispatchRoute.latestRouteVehicleDriver'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    isArrived: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.ARRIVE_STOP_STATUS),
    isPerformed: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.PERFORMED_STOP_STATUS),
    canSave: Ember.computed('arrive', 'depart', function () {
      return this.get('arrive') || this.get('depart');
    }),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      // TODO: should this be a no-op?
      const isArrived = this.get('isArrived');
      const isPerformed = this.get('isPerformed');

      if (isArrived) {
        this.set('arrive', isArrived);
      } else if (isPerformed) {
        this.set('arrive', isPerformed);
        this.set('depart', isPerformed);
      }

      yield (0, _emberConcurrency.timeout)(300);
    }).keepLatest(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const stopPoint = this.get('stopPoint');
      const routeExecEventAdapter = store.adapterFor('route-exec-event'); // if user checks the arrive then it also means
      // that the depart is also checked. Send accept
      // to remove both arrive and depart in AVLM

      if (this.get('arrive')) {
        yield routeExecEventAdapter.createAcceptEvent(stopPoint);
      } else if (this.get('depart')) {
        yield routeExecEventAdapter.createAcceptEvent(stopPoint);
        let arriveTimestamp = stopPoint.get('actualArriveTime');

        if (Ember.isNone(arriveTimestamp)) {
          arriveTimestamp = stopPoint.get('actualDepartTime');
        }

        stopPoint.set('arriveTimestamp', arriveTimestamp); // sending another arrive event will unperform the depart
        // for this stop

        yield routeExecEventAdapter.createStopArriveEvent(stopPoint);
      }
    }).drop(),
    actions: {
      noop() {
        return false;
      },

      onRefreshButtonClick() {
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        this.onCloseClick();
      },

      onPerformButtonClick() {
        // Reset error text
        this.set('errorText');
        const title = 'Unperform Drop Stop';
        this.get('tooltip').pushConfirmation({
          tip: 'Unperform Drop stop?',
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('saveTask').perform().then(() => {
              this.get('notifications').success('Unperformed stop successful');
              this.get('tooltip').reset();
              return this.onCloseClick();
            }).catch(() => {
              this.get('notifications').warning('Unperformed stop failed');
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            this.get('tooltip').reset();
            return;
          }
        });
      }

    }
  });

  _exports.default = _default;
});