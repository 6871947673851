define("adept-iq/mixins/async-schedule-operation", ["exports", "ember-concurrency", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const scheduleHost = _apiUrls.API.schedulingService.host;
  const TIMEOUT_DELAY = 10000; // 10 second tries

  var _default = Ember.Mixin.create({
    timeout: TIMEOUT_DELAY,

    init() {
      this._super(...arguments);

      const ajax = this.get('ajax');
      const session = this.get('session');
      (true && !(Ember.isPresent(ajax)) && Ember.assert('mixin requires components, routes, controllers to provide a ajax service', Ember.isPresent(ajax)));
      (true && !(Ember.isPresent(session)) && Ember.assert('mixin requires components, routes, controllers to provide a session service', Ember.isPresent(session)));
    },

    /**
     * @param schedule,
     * @param operation -> 'import or 'export'
     * @param options -> { operationData, outputResult }
     */
    createScheduleAsyncOperation: (0, _emberConcurrency.task)(function* (schedule, operation, options) {
      const session = this.get('session');
      const op = {
        'data': {
          'type': 'scheduleAsyncOperation',
          'attributes': {
            'operationType': operation
          },
          'relationships': {
            'schedule': {
              'data': {
                'type': 'schedule',
                'id': schedule.get('id')
              }
            }
          }
        }
      };
      const {
        operationData,
        outputResult
      } = options || {};

      if (operationData) {
        op.data.attributes.operationData = operationData;
      }

      const job = yield this.get('ajax').post(scheduleHost + '/schedule-async-operation', {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: op
      });
      if (!job) return false;
      return yield this.get('asyncOperationState').perform(job, outputResult);
    }).enqueue(),
    pollScheduleOperationState: (0, _emberConcurrency.task)(function* () {
      const session = this.get('session');
      const jobs = yield this.get('ajax').request(scheduleHost + '/schedule-async-operation/?sort=id', {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
      if (!jobs && !jobs.data.length) return false;
      const job = jobs.data[jobs.data.length - 1];
      if (!job) return false;
      const data = {
        data: {
          id: job.id,
          attributes: job.attributes,
          relationships: job.relationships
        }
      };
      return yield this.get('asyncOperationState').perform(data, true);
    }).enqueue(),

    /**
     * @param outputResult => boolean
     */
    asyncOperationState: (0, _emberConcurrency.task)(function* (job, outputResult) {
      const session = this.get('session');
      const workspace = this.get('workspace');
      if (job.data.attributes.progress && workspace) workspace.set('progressValue', job.data.attributes.progress);
      if (job.data.attributes.operationType && workspace) workspace.set('operationType', job.data.attributes.operationType);

      while (job.data.attributes.state !== 'success' && job.data.attributes.state !== 'failure') {
        const delay = this.get('timeout');
        yield (0, _emberConcurrency.timeout)(delay);

        try {
          // eslint-disable-next-line no-param-reassign
          job = yield this.get('ajax').request(scheduleHost + '/schedule-async-operation/' + job.data.id, {
            method: 'GET',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          });
          if (job.data.attributes.progress && workspace) workspace.set('progressValue', job.data.attributes.progress);
          if (job.data.attributes.operationType && workspace) workspace.set('operationType', job.data.attributes.operationType);
        } catch (e) {
          throw e;
        }
      }

      const isJobSuccess = job.data.attributes.state === 'success';

      if (outputResult) {
        return { ...job.data.attributes,
          isJobSuccess
        };
      }

      return isJobSuccess;
    }).drop()
  });

  _exports.default = _default;
});