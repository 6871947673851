define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/suspension/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/suspension/validation", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/suspension/driver-violation-search-config", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _moment, _unwrapProxy, _validation, _driverViolationSearchConfig, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['driver-suspension-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tableRef: null,
    disableForm: true,
    // placeholders
    US_DATE_PLACEHOLDER: _placeholders.US_DATE_PLACEHOLDER,
    NOTES_PLACEHOLDER: _placeholders.NOTES_PLACEHOLDER,
    DESCRIPTION_PLACEHOLDER: _placeholders.DESCRIPTION_PLACEHOLDER,
    // passed into view
    editAPI: null,
    selectedDriver: Ember.computed.readOnly('editAPI.selectedDriver'),
    selectedSuspension: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),

    async init() {
      this.get('refreshTask').perform();

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onSaveDriverRecord = this.get('onSaveDriverSuspensionRecord');
      const onDeleteDriverSuspensionRecord = this.get('onDeleteDriverSuspensionRecord');
      const onValidateAction = this.get('validateDriverSuspension'); // normal functions

      const onUndoDriverRecord = this.get('onUndoDriverSuspensionRecord').bind(this);
      const onNewDriverSuspensionRecord = this.get('onNewDriverSuspensionRecord').bind(this);
      const onEditDriverSuspensionRecord = this.get('onEditDriverSuspensionRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverRecord,
          newAction: onNewDriverSuspensionRecord,
          deleteAction: onDeleteDriverSuspensionRecord,
          editAction: onEditDriverSuspensionRecord,
          undoAction: onUndoDriverRecord,
          onValidateAction
        });
        editAPI.buttonSettings();
      });
    },

    driverSuspensionChangeSet: Ember.computed('selectedSuspension', function () {
      const suspension = (0, _unwrapProxy.unwrapProxy)(this.get('selectedSuspension'));
      return this.setDefaultProperties(suspension);
    }),
    driverViolationIncidentDate: Ember.computed('driverSuspensionChangeSet.driverViolation.incidentDate', function () {
      const incidentDate = this.get('driverSuspensionChangeSet.driverViolation.incidentDate');
      return incidentDate ? incidentDate : null;
    }),

    setDefaultProperties(record = null
    /* disableForms = true */
    ) {
      const store = this.get('store');
      let pendingDriverSuspensionRecord = this.get('pendingDriverSuspensionRecord');

      if (record) {
        pendingDriverSuspensionRecord = record;
      } else if (!pendingDriverSuspensionRecord) {
        pendingDriverSuspensionRecord = store.createRecord('driver-suspension');
        this.set('pendingDriverSuspensionRecord', pendingDriverSuspensionRecord);
      }

      if (pendingDriverSuspensionRecord.get('isNew')) {
        pendingDriverSuspensionRecord.setProperties({
          startDate: null,
          endDate: null,
          description: null,
          driverViolation: null,
          notes: null
        });
      }

      this.set('showValidations', false);
      return new _emberChangeset.default(pendingDriverSuspensionRecord, (0, _emberChangesetValidations.default)(_validation.DRIVER_SUSPENSION_VALIDATION), _validation.DRIVER_SUSPENSION_VALIDATION);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedSuspension = this.get('selectedSuspension');

      if (selectedSuspension) {
        if (!selectedSuspension.get('isNew') && !selectedSuspension.get('isDeleted')) {
          yield selectedSuspension.reload();
        }
      }

      const tableRef = this.get('tableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    onSaveDriverSuspensionRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const selectedDriver = this.get('selectedDriver');
      const pendingDriverSuspensionRecord = this.get('driverSuspensionChangeSet');
      const editAPI = this.get('editAPI'); // validation

      const isValidDriverSuspension = yield this.validateDriverSuspension.perform();

      if (isValidDriverSuspension) {
        pendingDriverSuspensionRecord.set('driver', selectedDriver);

        try {
          yield pendingDriverSuspensionRecord.save();
          notifications.success('Successfully Saved Driver Changes');
          this.set('pendingDriverSuspensionRecord', null);
          editAPI.buttonSettings();
        } catch (e) {
          notifications.warning('Failed to Save Driver Changes');
          console.warn(e); // eslint-disable-line
        }

        this.get('refreshTask').perform();
      }
    }),

    onUndoDriverSuspensionRecord() {
      const driverSuspensionChangeSet = this.get('driverSuspensionChangeSet');

      if (driverSuspensionChangeSet) {
        driverSuspensionChangeSet.rollback();
      }
    },

    onNewDriverSuspensionRecord() {
      const selectedRow = this.get('selectedRow');
      if (selectedRow) selectedRow.set('selected', false);
      this.set('disableForm', false);
      this.get('editAPI').buttonSettings(true, true, true, false, false); //False to enable the button, TRUE to disable
    },

    onEditDriverSuspensionRecord() {
      const selectedRow = this.get('selectedRow');
      if (Ember.isEmpty(selectedRow)) return;
      this.set('disableForm', false);
      this.get('editAPI').buttonSettings(true, true, true, false, false);
    },

    onDeleteDriverSuspensionRecord: (0, _emberConcurrency.task)(function* () {
      const selectedSuspension = this.get('selectedSuspension');
      const notifications = this.get('notifications');
      const tooltip = this.get('tooltip');
      const refreshTask = this.get('refreshTask');
      if (!selectedSuspension) return;
      yield tooltip.pushConfirmation({
        title: 'Manage Driver',
        tip: 'Are you sure you want to delete this record?',
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',

        async primaryAction() {
          try {
            await selectedSuspension.destroyRecord();
            notifications.success('Record successfully deleted');
            refreshTask.perform();
          } catch (e) {
            notifications.warning('Record failed to delete');
          }

          return tooltip.reset();
        }

      });
    }),
    validateDriverSuspension: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverSuspensionChangeSet = this.get('driverSuspensionChangeSet');
      const driverViolation = (0, _unwrapProxy.unwrapProxy)(driverSuspensionChangeSet.get('driverViolation'));
      yield driverSuspensionChangeSet.validate();
      const startDate = driverSuspensionChangeSet.get('startDate');
      const endDate = driverSuspensionChangeSet.get('endDate');

      if (driverViolation) {
        const incidentDate = driverViolation.get('incidentDate');

        if ((0, _moment.default)(incidentDate).isSameOrBefore(startDate)) {
          driverSuspensionChangeSet.addError('startDate', {
            value: incidentDate,
            validation: 'The suspension\'s Start Date must be equal to or after the associated violation\'s Incident Date'
          });
        }
      }

      if (startDate && endDate) {
        if ((0, _moment.default)(endDate).isSameOrBefore(startDate)) {
          driverSuspensionChangeSet.addError('endDate', {
            value: endDate,
            validation: 'End Date must be after Start Date'
          });
        }
      }

      const driverSuspensionValid = driverSuspensionChangeSet.errors;
      const errors = driverSuspensionValid;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      return true;
    }),

    async onViolationChange(violation, path = 'driverViolation') {
      const driverSuspensionChangeSet = this.get('driverSuspensionChangeSet');
      driverSuspensionChangeSet.set(path, violation);
    },

    actions: {
      onInputValueChange(record, valuePath, value) {
        record.set(valuePath, value);
      },

      onDateValueChange(record, valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        record.set(valuePath, isoDate);
      },

      openViolationModal() {
        const onVehicleChange = this.get('onViolationChange').bind(this);
        const selectedDriver = this.get('selectedDriver');
        const driverLicense = this.get('selectedDriver.license');
        const searchVehicleObj = Ember.Object.create({
          title: `Driver ${selectedDriver.get('driverId')} Violations`,
          queryParams: {
            filter: `eq(driverLicenseId,${driverLicense.get('id')})`
          },
          hasOverlay: true,
          config: _driverViolationSearchConfig.default,
          primaryActionText: 'Select',
          className: 'driver-violation-search',
          primaryAction: record => {
            onVehicleChange(record);
          }
        });
        this.set('modalAPI', searchVehicleObj);
      }

    }
  });

  _exports.default = _default;
});