define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/grid-context-menu", ["exports", "react"], function (_exports, _react) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class GridContextMenu extends _react.default.Component {
    constructor(props) {
      super(props);
      this.contextRef = _react.default.createRef();
    }

    onContextMenuClick(event) {
      const index = event.target.getAttribute('index');
      const menuItem = this.props.menusItems[index];
      event.preventDefault();

      if (menuItem) {
        const {
          onMenuItemClick
        } = this.props;
        return onMenuItemClick && onMenuItemClick(menuItem);
      }
    }

    renderMenu() {
      const {
        menusItems,
        positionX,
        positionY
      } = this.props;
      var positionStyle = {
        'position': 'absolute',
        'left': `${positionX}px`,
        'top': `${positionY}px`
      };
      return _react.default.createElement("div", {
        className: "global-dropdown-context",
        onClick: this.onContextMenuClick.bind(this),
        style: positionStyle
      }, menusItems.map((item, index
      /*, arr*/
      ) => {
        return _react.default.createElement("div", {
          key: index,
          className: "menu-option",
          index: index
        }, item.name);
      }));
    }

    render() {
      return _react.default.createElement("div", {
        id: "global-dropdown"
      }, this.props.visible ? this.renderMenu() : null);
    }

  }

  var _default = GridContextMenu;
  _exports.default = _default;
});