define("adept-iq/models/driver-health-provider", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    siteId: attr('string'),
    driverId: attr('string'),
    driverPositionId: attr('string'),
    lastPhysicalDateType: attr('string'),
    bloodPressure: attr('string'),
    diabetes: attr(),
    // bit field
    highBloodPressure: attr(),
    // bit field
    correctiveLenses: attr(),
    // bit field
    pointsReductionTraining: attr('date'),
    classroomTrainingDate: attr('date'),
    refresherTrainingDate: attr('date'),
    sensitivityTrainingDate: attr('date'),
    firstAidTrainingDate: attr('date'),
    lastPhysicalDate: attr('date'),
    nextPhysicalDate: attr('date'),
    lastDiabetesCheckDate: attr('date'),
    nextDiabetesCheckDate: attr('date'),
    lastBPDate: attr('date'),
    nextBPDate: attr('date'),
    prevPhysicalDate: attr('date'),
    prevBloodPressureDate: attr('date'),
    prevDiabetesCheckDate: attr('date'),
    driver: belongsTo('driver'),
    provider: belongsTo('provider')
  });

  _exports.default = _default;
});