define("adept-iq/pods/components/iq-widgets/stops-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/stops-widget',
    rowComponent: 'iq-widgets/stops-widget/stops-row',
    modelName: 'stop-point',
    defaultSortId: 'tripId',
    defaultSortAsc: false,
    title: 'Stops',
    // ember-react-widget properties
    widgetName: 'stopWidget',
    selectedRowLimit: 100,
    maxLimitByRouteSelection: 5,
    maxLimitWithoutRouteAssociation: 20,
    columns: [{
      id: 'id',
      index: 18,
      type: 'uuid',
      label: 'ID',
      valuePath: 'id',
      valuePreview: '1',
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 60,
      hidden: true
    }, {
      id: 'type',
      index: 0,
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      mapValuePath: 'stopType',
      valuePreview: 'Pick',
      searchable: true,
      isMapLabelVisible: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'tripId',
      index: 1,
      type: 'uuid',
      label: 'Trip ID',
      valuePath: 'tripId',
      mapValuePath: 'trip.tripId',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'riderLastName',
      index: 2,
      type: 'text',
      label: 'Passenger Last Name',
      valuePath: 'passengerLastName',
      mapValuePath: 'trip.segment.leg.rider.lastName',
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderFirstName',
      index: 3,
      type: 'text',
      label: 'Passenger First Name',
      valuePath: 'passengerFirstName',
      mapValuePath: 'trip.segment.leg.rider.firstName',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'riderPhone',
      index: 16,
      type: 'enum',
      label: 'Passenger Phone Number',
      valuePath: 'passengerPhoneNumber',
      mapValuePath: 'trip.segment.leg.rider.formattedPhoneNumbers',
      defaultWidth: 60,
      hidden: true
    }, {
      id: 'riderId',
      index: 17,
      type: 'uuid',
      label: 'Passenger ID',
      valuePath: 'passengerId',
      mapValuePath: 'trip.segment.leg.rider.riderId',
      searchable: true,
      defaultWidth: 60,
      hidden: true
    }, {
      id: 'status',
      index: 7,
      type: 'text',
      label: 'Status',
      valuePath: 'status',
      mapValuePath: 'computedStatus',
      valuePreview: 'Planned',
      editable: false,
      defaultWidth: 60,
      searchable: true,
      hidden: true
    }, {
      id: 'OTPstatus',
      index: 4,
      type: 'text',
      label: 'OTP Status',
      valuePath: 'otpStatus',
      defaultWidth: 40,
      highlightable: true,
      searchable: true,
      cellItem: 'OTPValueCellItem'
    }, {
      id: 'ETA',
      index: 5,
      type: 'time',
      label: 'ETA',
      valuePath: 'eta',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 40,
      highlightable: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'promisedTime',
      index: 13,
      type: 'time',
      label: 'Promise Time',
      valuePath: 'pickPromiseTime',
      mapValuePath: 'computedPromiseTime',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 50,
      hidden: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'requestedTime',
      index: 6,
      type: 'time',
      label: 'Appointment Time',
      valuePath: 'appointmentTime',
      mapValuePath: 'computedApptTime',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 70,
      hidden: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'actualArrivalTime',
      index: 8,
      type: 'time',
      label: 'Actual Arrive Timestamp',
      valuePath: 'actualArriveTimestamp',
      editable: true,
      hidden: true,
      format: 'MM/DD/YYYY HH:mm',
      defaultWidth: 150,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'actualDepartTime',
      index: 9,
      type: 'time',
      label: 'Actual Depart Timestamp',
      valuePath: 'actualDepartTimestamp',
      editable: true,
      hidden: true,
      format: 'MM/DD/YYYY HH:mm',
      defaultWidth: 150,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'plannedDepartTime',
      index: 14,
      type: 'time',
      label: 'Planned Depart Time',
      valuePath: 'plannedDepartTime',
      mapValuePath: 'departTime',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 130,
      hidden: true,
      cellItem: 'TimeLocalCellItem'
    }, {
      id: 'routeName',
      index: 10,
      type: 'uuid',
      label: 'Route Name',
      valuePath: 'routeName',
      valuePreview: '40',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'notes',
      index: 25,
      type: 'text',
      label: 'Notes',
      valuePath: 'notes',
      editable: true,
      searchable: true,
      defaultWidth: 100,
      hidden: true
    }, {
      id: 'address',
      index: 11,
      type: 'text',
      label: 'Address ',
      valuePath: 'address',
      mapValuePath: 'place.address.readableAddress',
      searchable: true,
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'date',
      index: 12,
      type: 'date',
      label: 'Date',
      valuePath: 'date',
      mapValuePath: 'stopDate',
      format: 'MM/DD/YYYY',
      defaultWidth: 70,
      hidden: true,
      cellItem: 'DateCellItem'
    }, {
      id: 'city',
      type: 'text',
      label: 'City',
      valuePath: 'city',
      mapValuePath: 'place.address.locality',
      editable: true,
      searchable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'odometer',
      index: 26,
      type: 'number',
      label: 'Odometer on Arrival',
      valuePath: 'odometerOnArrival',
      editable: true,
      hidden: true
    }, {
      id: 'serviceWindow',
      index: 15,
      type: 'text',
      label: 'Service Window',
      valuePath: 'serviceWindow',
      mapValuePath: 'serviceWindow.name',
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'BreakStartTime',
      index: 19,
      type: 'time',
      label: 'Planned Break Start Time',
      valuePath: 'routeBreakEstimatedStart',
      //missing
      editable: true,
      format: 'HH:mm',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'BreakEndTime',
      index: 20,
      type: 'time',
      label: 'Planned Break End Time',
      valuePath: 'routeBreakEstimatedEnd',
      //missing
      editable: true,
      format: 'HH:mm',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'BreakType',
      index: 22,
      type: 'text',
      label: 'Break Type',
      valuePath: 'breakType',
      //missing
      mapValuePath: 'routeBreak.breakType.name',
      valuePreview: 'Floating',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'onboardCount',
      index: 24,
      type: 'number',
      label: 'Onboard Count',
      valuePath: 'onboardCount',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'stopBreakOrd',
      type: 'number',
      label: 'Stop Break Ord',
      valuePath: 'ordinal',
      mapValuePath: 'routeOrdinal',
      searchable: false,
      editable: false,
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'otp',
      index: 27,
      type: 'number',
      label: 'OTP',
      valuePath: 'otpWithSign',
      customSortPath: 'otp',
      hidden: true,
      highlightable: true
    }, {
      id: 'arriveLat',
      index: 28,
      type: 'string',
      label: 'Actual Arrive Lat',
      valuePath: 'actualArriveLat',
      hidden: true,
      highlightable: false
    }, {
      id: 'arriveLng',
      index: 29,
      type: 'string',
      label: 'Actual Arrive Lng',
      valuePath: 'actualArriveLng',
      hidden: true,
      highlightable: false
    }, {
      id: 'departLat',
      index: 30,
      type: 'string',
      label: 'Actual Depart Lat',
      valuePath: 'actualDepartLat',
      hidden: true,
      highlightable: false
    }, {
      id: 'departLng',
      index: 31,
      type: 'string',
      label: 'Actual Depart Lng',
      valuePath: 'actualDepartLng',
      hidden: true,
      highlightable: false
    }, {
      id: 'Slack Time',
      index: 32,
      type: 'number',
      label: 'Slack Minutes',
      valuePath: 'slack',
      mapValuePath: 'slackTimeToSeconds',
      editable: true,
      defaultWidth: 50,
      hidden: true
    }],
    traversal: {
      tripWidget: {
        links: [{
          type: 'eq',
          field: 'tripPk',
          valuePath: 'id'
        }]
      },
      routeWidget: {
        links: [{
          type: 'eq',
          field: 'routeId',
          valuePath: 'routeId'
        }]
      },
      alertWidget: {
        links: [{
          type: 'eq',
          field: 'stopPointId',
          valuePath: 'stopId'
        }, {
          type: 'eq',
          field: 'tripPk',
          valuePath: 'tripPk'
        }, {
          type: 'stringEq',
          field: 'routeName',
          valuePath: 'routeName'
        }]
      },
      vehicleWidget: {
        links: [{
          type: 'stringEq',
          field: 'routeName',
          valuePath: 'routeName'
        }]
      },
      messageWidget: {
        links: [{
          type: 'eq',
          field: 'tripPk',
          valuePath: 'tripId'
        }, {
          type: 'stringEq',
          field: 'routeName',
          valuePath: 'routeName'
        }]
      },
      riderWidget: {
        links: [{
          type: 'eq',
          field: 'passengerPk',
          valuePath: 'id'
        }]
      }
    },
    excluded: {
      routeWidget: {
        conflicts: [{
          widget: 'tripWidget',
          srcField: 'routeId',
          targetField: 'routeId'
        }]
      }
    },
    implicit: {
      conditions: [{
        type: 'stringNeRQL',
        field: 'type',
        value: ['deletedBreak']
      }, {
        type: 'neRQL',
        field: 'scheduleType',
        value: ['booking']
      }, {
        type: 'stringEq',
        field: 'scheduleStatus',
        value: ['exported']
      }]
    },
    // stopPointId stop tripStipId like 'Sxxxx' and routeBreakId like 'Bxxxx'
    transform: {
      id: 'stopPointId'
    }
  };
  _exports.default = _default;
});