define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/header-cell", ["exports", "react", "react-draggable"], function (_exports, _react, _reactDraggable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getHeaderValue = (columnInfo, columnIndex) => {
    if (columnIndex === 0) return '';
    const {
      label: columnValue
    } = columnInfo;
    return columnValue;
  };

  const HeaderCheckBox = ({
    chkBtnAllClicked,
    checkBtnAllClickHandler,
    isDisabled
  }) => {
    return _react.default.createElement("input", {
      className: 'cell-checkbox',
      type: "checkbox",
      checked: chkBtnAllClicked,
      disabled: isDisabled,
      onChange: event => {
        checkBtnAllClickHandler(event.target.checked);
      }
    });
  };

  const HeaderCell = ({
    style,
    columnIndex,
    headerCellClickHandler,
    resizeRow,
    resizing,
    resizeStop,
    checkBtnAllClickHandler,
    chkBtnAllClicked,
    // eslint-disable-line no-unused-vars
    sortAsc,
    sortId,
    columnInfo,
    checkedRowIds,
    isFurtherSelectionAllowed,
    showGearIcon // eslint-disable-line no-unused-vars

  }) => {
    const columnValue = getHeaderValue(columnInfo, columnIndex);
    const firstColumnCSS = columnIndex === 0 ? 'first-column' : '';
    const headerCellStyle = { ...style
    };
    headerCellStyle.cursor = 'pointer';

    if (resizing) {
      headerCellStyle.cursor = 'col-resize';
    }

    const sortClassName = sortAsc ? 'fa fa-fw fa-sort-asc' : 'fa fa-fw fa-sort-desc';
    const isSortable = columnInfo.id === sortId;
    const isDisabled = checkedRowIds.length === 0;
    const deselectBtnAllClicked = checkedRowIds.length > 0;
    const actionableColumnIndex = showGearIcon ? 1 : 0; // for checkbox and gear

    return _react.default.createElement("div", {
      role: "none",
      className: `HeaderCell ${firstColumnCSS}`,
      style: headerCellStyle
    }, columnIndex === 0 && _react.default.createElement(HeaderCheckBox, {
      checkBtnAllClickHandler: checkBtnAllClickHandler,
      chkBtnAllClicked: deselectBtnAllClicked,
      isDisabled: isDisabled
    }), showGearIcon && columnIndex === 1 && _react.default.createElement("span", null), columnIndex > actionableColumnIndex && _react.default.createElement("div", {
      className: 'column-label',
      onClick: () => headerCellClickHandler(columnInfo, columnIndex)
    }, _react.default.createElement("span", null, columnIndex > actionableColumnIndex && columnValue)), _react.default.createElement("div", {
      className: "column-controls"
    }, isSortable && _react.default.createElement("i", {
      className: sortClassName
    })), columnIndex > actionableColumnIndex && _react.default.createElement(_reactDraggable.default, {
      axis: "x",
      defaultClassName: "DragHandle",
      defaultClassNameDragging: "DragHandleActive",
      onDrag: (event, {
        deltaX
      }) => resizeRow({
        columnIndex,
        deltaX
      }),
      onStop: (event, {
        deltaX
      }) => resizeStop({
        columnIndex,
        deltaX
      }),
      position: {
        x: 0
      },
      zIndex: 999
    }, _react.default.createElement("div", {
      className: "DragHandleIcon"
    })));
  };

  var _default = HeaderCell;
  _exports.default = _default;
});