define("adept-iq/pods/components/iq-widgets/subscription-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/subscription-widget/config", "adept-iq/pods/components/iq-widgets/subscription-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _avlmConfig, _environment, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['subscription-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    booking: Ember.inject.service(),
    editModal: Ember.inject.service(),
    canShowHeader: true,
    config: _config.default,
    defaultIncludes: INCLUDES,
    editAction: null,
    deleteAction: null,
    subscriptionComponent: 'iq-widgets/subscription-form',
    deleteSubscriptionComponent: 'iq-widgets/delete-subscriptions-form',
    cancelSubscriptionComponent: 'iq-widgets/cancel-subscription-trips',

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);

      this.onSubscriptionEdit = this.onSubscriptionEdit.bind(this);
      this.get('tableColumns').popObject();
      this.get('tableColumns').lastObject.resizable = false;
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('editAction', {
        id: 'edit',
        name: 'Edit Subscription',
        permId: [_mappedPermIds.default.editSubscription],
        action: this.onSubscriptionEdit
      });
      this.set('deleteAction', {
        id: 'delete',
        name: 'Delete Subscription',
        permId: _mappedPermIds.default.deleteSubscription,
        action: model => {
          const deleteSubscriptionComponent = this.get('deleteSubscriptionComponent');
          this.get('editModal').open(deleteSubscriptionComponent, [model]);
        }
      });
      this.set('cancelAction', {
        id: 'cancel',
        name: 'Cancel Subscription Trips',
        permId: _mappedPermIds.default.cancelFuture,
        action: model => {
          const cancelSubscriptionsComponent = this.get('cancelSubscriptionComponent');
          this.get('editModal').open(cancelSubscriptionsComponent, [model]);
        }
      });
    },

    didReceiveAttrs() {
      this._super(...arguments);

      setTimeout(() => {
        this.selectAndDeselectTableRows(false);
      }, 100);
    },

    async onSubscriptionEdit(subscription) {
      this.get('workspace').set('isGlobalSpinnerVisible', true);

      try {
        const travelNeedNames = subscription.get('subscriptionTravelNeeds').map(tn => tn.get('travelNeedType.id'));
        const isDuplicated = new Set(travelNeedNames).size !== travelNeedNames.length;

        if (isDuplicated) {
          const travelNeedsBySubIdQuery = `in(subscriptionId,(${subscription.get('id')}))`;
          const subTravelNeeds = await this.get('store').query('subscription-travel-need', {
            filter: travelNeedsBySubIdQuery
          });
          subscription.set('subscriptionTravelNeeds', subTravelNeeds);
        }

        if (!subscription.get('exclusions.firstObject')) {
          const exclusion = this.get('store').createRecord('subscription-exclusion');
          subscription.set('exclusions', [exclusion]);
        }

        if (subscription.get('exclusions.firstObject.startDate') && subscription.get('exclusions.firstObject.endDate')) {
          subscription.set('exclusions.firstObject.suspend', true);
        }

        const pickPlace = subscription.get('origin');
        const dropPlace = subscription.get('destination');
        const pickZName = pickPlace.get('address.zoneName.id');
        const dropZName = dropPlace.get('address.zoneName.id');
        pickPlace.set('address.zName', pickZName);
        dropPlace.set('address.zName', dropZName);
        const leg = this.store.createRecord('leg', {
          requestTime: subscription.get('requestTime'),
          anchor: subscription.get('anchor'),
          purpose: subscription.get('purpose'),
          notes: subscription.get('legNotes'),
          serviceWindow: subscription.get('serviceWindow')
        });
        const segment = this.store.createRecord('segment', {
          anchor: subscription.get('anchor'),
          fare: subscription.get('fare'),
          fareType: subscription.get('fareType'),
          pick: this.store.createRecord('segment-stop', {
            type: 'pick',
            place: pickPlace
          }),
          drop: this.store.createRecord('segment-stop', {
            type: 'drop',
            place: dropPlace
          }),
          leg: leg
        });
        const dummyBookingForOpeningForm = this.store.createRecord('booking', {
          provider: subscription.get('provider'),
          subscription: subscription
        });
        leg.set('segments', [segment]);
        dummyBookingForOpeningForm.set('legs', [leg]);
        const isBookingEditMode = true;
        const newBookingComponent = this.get('subscriptionComponent');
        const riderId = this.get('booking.selectedRiders.firstObject.id');
        await this.store.queryRecord('rider-external', riderId);
        this.set('booking.activeBooking', dummyBookingForOpeningForm);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('editModal').open(newBookingComponent, [isBookingEditMode, this.get('booking.selectedRiders.firstObject'), dummyBookingForOpeningForm]);
      } catch (e) {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('notifications').warning('error while editing subscription' + e.message);
      }
    },

    selectAndDeselectTableRows(isRow) {
      const subscriptionWidgetTableRows = this.get('table.rows');
      subscriptionWidgetTableRows.setEach('selected', isRow);
      subscriptionWidgetTableRows.setEach('isChecked', isRow);
    },

    filterTripsForselectedSubscriptions() {
      const subscriptionRows = this.get('table.rows');
      const selectedSubsciptions = subscriptionRows.filter(row => row.get('isChecked'));
      const selectedSubscriptionIds = selectedSubsciptions.map(sub => sub.get('id'));
      const subscriptionBookings = this.get('booking.currentBookings').filter(booking => selectedSubscriptionIds.some(subscriptionId => subscriptionId === booking.get('subscription.id')));
      const selectedBookingIds = subscriptionBookings.map(booking => booking.get('id'));
      const filteredTrips = this.get('booking.currentTrips').filter(trip => selectedBookingIds.some(bookingId => bookingId === trip.get('booking.id')));
      this.set('booking.filteredCurrentTrips', filteredTrips);
    },

    actions: {
      onRowClick() {
        this.filterTripsForselectedSubscriptions();
      },

      onRowRightClick(row) {
        if (row.get('status') === 'cancelled') {
          this.set('dynamicSingleActions', [this.get('deleteAction')]);
        } else {
          this.set('dynamicSingleActions', [this.get('editAction'), this.get('deleteAction'), this.get('cancelAction')]);
        }

        this._super(...arguments);
      },

      onCheckAll() {
        const isRowHeadChecked = true;
        this.selectAndDeselectTableRows(isRowHeadChecked);
      },

      onUncheckAll() {
        const isRowHeadUnchecked = false;
        this.selectAndDeselectTableRows(isRowHeadUnchecked);
      }

    }
  });

  _exports.default = _default;
});