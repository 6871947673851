define("adept-iq/pods/components/side-drawer/system-config/no-show-reason-categories/table/component", ["exports", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/side-drawer/system-config/no-show-reason-categories/table/config"], function (_exports, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['no-show-reason-table'],
    config: _config.default,
    paginationEnabled: true,
    enableWarningRowChangeAlert: true,
    configType: null,

    init() {
      this._super(...arguments);
    },

    async didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    // if any one of rows is selected, hasPendingChanges method will be executed
    hasPendingChanges() {
      const noShowReasonFormChanges = this.get('noShowReasonFormChanges') || false;
      return noShowReasonFormChanges;
    }

  });

  _exports.default = _default;
});