define("adept-iq/pods/components/iq-widgets/new-booking-form/booking-details/trip-alternative/component", ["exports", "ember-light-table", "adept-iq/config/itinerary-action", "adept-iq/config/icon-paths", "moment", "lodash"], function (_exports, _emberLightTable, _itineraryAction, _iconPaths, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActionIconMap = {
    'taxi': _iconPaths.default.requestdetails.taxiicon,
    'paratransit': _iconPaths.default.requestdetails.paratransiticon
  };

  var _default = Ember.Component.extend({
    classNames: ['trip-alternative'],
    booking: Ember.inject.service(),
    table: null,
    canShowHeader: true,
    isSearchEnabled: false,
    isSearchAllowed: true,
    // set for demo
    isFilterAllowed: true,
    // set for demo
    isCellEditAllowed: false,
    model: Ember.computed('selectedTravelMode', function () {
      const selectedTravelMode = this.get('selectedTravelMode');

      if (selectedTravelMode === 'itp') {
        return this.getItpSegments();
      } // taxi or paratransist


      const actionIcon = ActionIconMap[selectedTravelMode];
      const firstSegment = this.get('tripSegments.firstObject');
      const lastSegment = this.get('tripSegments.lastObject');
      const requestTime = this.get('tripBooking.requestTime');
      const arrivalTime = this.get('booking').getArrivalTime(this.get('legIndex'), this.get('tripBooking'));
      const formattedStartTime = (0, _moment.default)(requestTime).format('hh:mm A');
      const formattedArrivalTime = (0, _moment.default)(arrivalTime).format('hh:mm A');
      const segment = {
        'icon': actionIcon,
        'action': (0, _lodash.startCase)(selectedTravelMode),
        'from': firstSegment.pickAddress,
        'startTime': formattedStartTime,
        'to': lastSegment.dropAddress,
        'arriveTime': formattedArrivalTime,
        'promiseTime': ''
      };
      return [segment];
    }),
    columns: Ember.computed('selectedTravelMode', function () {
      return [{
        valuePath: 'icon',
        width: '55px',
        sortable: false,
        cellComponent: 'table/cells/icon-cell',
        resizable: true
      }, {
        label: 'Action',
        valuePath: 'action',
        width: '100px',
        sortable: false,
        cellComponent: 'table/cells/base-cell',
        resizable: true
      }, {
        label: 'From',
        valuePath: 'from',
        width: '150px',
        cellComponent: 'table/cells/base-cell',
        resizable: true
      }, {
        label: 'Start Time',
        valuePath: 'startTime',
        width: '100px',
        cellComponent: 'table/cells/base-cell',
        resizable: true
      }, {
        label: 'To',
        valuePath: 'to',
        width: '100px',
        cellComponent: 'table/cells/base-cell',
        resizable: true
      }, {
        label: 'Arrive Time',
        valuePath: 'arriveTime',
        width: '100px',
        cellComponent: 'table/cells/base-cell',
        resizable: true
      }, {
        label: 'Promise Time',
        valuePath: 'promiseTime',
        width: '100px',
        cellComponent: 'table/cells/base-cell',
        resizable: true
      }];
    }),
    onTravelModeChange: Ember.observer('selectedTravelMode', function () {
      if (!this.get('selectedTravelMode')) {
        return;
      }

      const segments = this.get('model');
      this.get('table').setRows(segments);
    }),

    init() {
      this._super(...arguments);

      const table = new _emberLightTable.default(this.get('columns'), this.get('model'), {
        enableSync: false
      });
      this.set('table', table);
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('.table-base-cell').each(function () {
        const cellContent = Ember.$(this).text();

        if (cellContent.indexOf('Unable to generate Promise Time') > -1) {
          Ember.$(this).css({
            'color': 'red'
          });
        }
      });
    },

    getItpSegments() {
      const itineraryLastIndex = this.get('itinerarySegments').length - 1;
      const firstSegment = this.get('tripSegments.firstObject');
      const lastSegment = this.get('tripSegments.lastObject');
      const cangetPromiseTime = this.get('booking').canGetPromiseTimeforItpsegment(this.get('legIndex'));
      const promiseTime = cangetPromiseTime ? this.get('booking').getPromiseTimeforItpsegment(this.get('legIndex')) : 'Unable to generate Promise Time';
      return this.get('itinerarySegments').map((itinerarySegment, index) => {
        const startAddress = index === 0 ? firstSegment.pickAddress : itinerarySegment.start.description;
        const endAddress = index === itineraryLastIndex ? lastSegment.dropAddress : itinerarySegment.end.description;
        const {
          action,
          icon
        } = _itineraryAction.ITINERARY_ACTION_MAP[itinerarySegment.action];
        const description = itinerarySegment.description;
        return {
          'icon': icon,
          'action': action === 'bus' ? `${(0, _lodash.startCase)(action)} - ${description}` : (0, _lodash.startCase)(action),
          'from': startAddress,
          'startTime': (0, _moment.default)(itinerarySegment.start.time).format('hh:mm A'),
          'to': endAddress,
          'arriveTime': (0, _moment.default)(itinerarySegment.end.time).format('hh:mm A'),
          'promiseTime': action === 'paratransit' ? promiseTime : ''
        };
      });
    }

  });

  _exports.default = _default;
});