define("adept-iq/pods/components/form-components/text-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LMbO3fCI",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"text-area-container\"],[9],[0,\"\\n\"],[1,[27,\"textarea\",null,[[\"rows\",\"disabled\",\"placeholder\",\"maxlength\",\"value\",\"focus-out\"],[\"3\",[27,\"readonly\",[[23,[\"disabled\"]]],null],[23,[\"placeholder\"]],[23,[\"maxLength\"]],[23,[\"value\"]],[27,\"action\",[[22,0,[]],\"onInput\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showHint\"]]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"hint\"],[9],[7,\"span\"],[9],[1,[21,\"valueLength\"],false],[0,\"/\"],[1,[21,\"maxLength\"],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/form-components/text-area/template.hbs"
    }
  });

  _exports.default = _default;
});