define("adept-iq/pods/components/action-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);
    },

    didRender() {
      this._super(...arguments);

      this.$('.ember-modal-dialog').draggable();
    },

    actions: {
      onPrimaryActionButtonClick() {
        this.get('tooltip.primaryActionTask').perform();
      },

      onSecondaryActionButtonClick() {
        this.get('tooltip.secondaryActionTask').perform();
      },

      onThirdActionButtonClick() {
        this.get('tooltip.thirdActionTask').perform();
      },

      onCloseTooltipModal() {
        this.get('tooltip.closeActionTask').perform();
        this.get('tooltip').reset();
      },

      onClickOverlayTooltipModal() {
        const hasOverlay = this.get('tooltip.hasOverlay');

        if (hasOverlay) {
          return false;
        }

        this.get('tooltip').reset();
      }

    }
  });

  _exports.default = _default;
});