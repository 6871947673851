define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/availability/component", ["exports", "ember-concurrency", "lodash", "ember-changeset-cp-validations", "ember-changeset"], function (_exports, _emberConcurrency, _lodash, _emberChangesetCpValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['availability'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    editAPI: null,
    resetRecords: true,
    modelName: 'driver-availability',
    availablities: Ember.computed('editAPI.selectedDriver.driverAvailabilities', 'resetRecords', function () {
      const driverAvailabilities = this.get('editAPI.selectedDriver.driverAvailabilities') || [];
      return this.configureDriverAvailabilities(driverAvailabilities);
    }),

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveAvailabilityRecord = this.get('onSaveAvailabilityRecord');
      const onUndoAvailabilityRecord = this.get('onUndoAvailabilityRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveAvailabilityRecord,
          undoAction: onUndoAvailabilityRecord,
          undoBtn: false,
          saveBtn: false,
          newAction: false,
          deleteAction: false,
          editAction: false
        });
      });
    },

    configureDriverAvailabilities(availabilities) {
      const changesets = availabilities.map(availability => {
        const {
          validateFn,
          validationMap
        } = (0, _emberChangesetCpValidations.buildChangeset)(availability);
        return new _emberChangeset.default(availability, validateFn, validationMap);
      });
      return changesets;
    },

    saveRecords: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const availablities = this.get('availablities') || [];

      try {
        yield availablities.forEach(availability => {
          if (!availability.get('isDeleted') && availability.get('isNew')) return;
          this.get('onSave').perform(availability);
        });
        notifications.success('Record successfully saved');
        this.set('shouldDisplayValidations', false);
      } catch (e) {
        notifications.warning('Record failed to save');
        console.warn(e); // eslint-disable-line
      }
    }),
    onSave: (0, _emberConcurrency.task)(function* (availability) {
      const calcEndDate = availability.get('calcEndDate');
      availability.setProperties({
        shiftEnd: calcEndDate
      });
      yield availability.save();
    }),

    onUndoAvailabilityRecord() {
      const store = this.get('store');
      const availablities = store.peekAll(this.get('modelName')) || [];
      availablities.forEach(availability => {
        availability.rollbackAttributes();
      });
      this.toggleProperty('resetRecords');
      Ember.run.next(() => {
        this.get('availabilityRef').resetAvailability();
      });
    },

    validate: (0, _emberConcurrency.task)(function* (availability) {
      yield availability.validate();
    }),
    onSaveAvailabilityRecord: (0, _emberConcurrency.task)(function* () {
      const availablities = this.get('availablities');
      const tooltip = this.get('tooltip');
      let errors = [];
      yield availablities.forEach(availability => {
        this.get('validate').perform(availability);
      });
      Ember.run.next(() => {
        availablities.forEach(availability => {
          if (availability.errors.length > 0 && !availability.get('isDeleted')) {
            errors.pushObjects(availability.errors);
          }
        });
        errors = (0, _lodash.uniqBy)(errors, error => error.key);

        if (errors.length) {
          this.set('shouldDisplayValidations', true);
          tooltip.pushConfirmation({
            title: 'Manage Driver',
            tip: '',
            errors,
            primaryActionText: 'OK',

            primaryAction() {
              return tooltip.popConfirmation();
            }

          });
        } else {
          this.get('saveRecords').perform();
        }
      });
    })
  });

  _exports.default = _default;
});