define("adept-iq/classes/active-contexts/dispatch/dispatch", ["exports", "lodash", "adept-iq/classes/active-contexts/base/base", "ember-concurrency", "adept-iq/classes/active-contexts/dispatch/graph", "adept-iq/classes/active-contexts/dispatch/map-graph", "adept-iq/utils/flattenData"], function (_exports, _lodash, _base, _emberConcurrency, _graph, _mapGraph, _flattenData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    // overridden values based on extending sub classes
    // computed.readOnly('workspaceContext._structuredWorkspace')
    graph: null,
    nodes: null,
    emptyImplicitHash: null,
    workspaceData: Ember.computed.readOnly('workspaceContext._structuredWorkspace'),
    consumedModelNames: Ember.computed('workspace.dashboardInstance.widgets.[]', function () {
      const widgets = this.get('workspace.dashboardInstance.widgets');

      const widgetModels = _lodash.default.reduce(widgets, (acc, widget) => _lodash.default.union(acc, widget.get('models')), []);

      return widgetModels;
    }),
    // structured data sets (grouped by model name)
    structuredActiveData: Ember.computed('workspaceData', 'checkedItems.[]', function () {
      try {
        const workspaceData = this.get('workspaceData') || {};
        const checkedItems = this.get('checkedItems') || [];
        return this._computeActiveContext(workspaceData, checkedItems);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Exception occured while refreshing Workspace Data in structuredActiveData' + e);
      }
    }),
    structuredImplicitData: Ember.computed('workspaceData', 'checkedItems.[]', function () {
      try {
        const workspaceData = this.get('workspaceData') || {};
        const checkedItems = this.get('checkedItems') || [];
        return this._computeImplicitContext(workspaceData, checkedItems);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Exception occured while refreshing Workspace Data in structuredImplicitData' + e);
      }
    }),
    // flattened data sets for easy binding & interation
    activeData: Ember.computed('structuredActiveData', function () {
      const structuredActiveData = this.get('structuredActiveData');
      return (0, _flattenData.flattenData)(structuredActiveData);
    }),
    activeVehicles: Ember.computed('workspaceData', function () {
      const workspaceData = this.get('workspaceData');
      if (!workspaceData) return [];
      return workspaceData.vehicle;
    }),
    // map-context uses to construct layered records
    structuredMapData: Ember.computed('workspaceData', 'checkedItems.[]', function () {
      const workspaceData = this.get('workspaceData') || {};
      const checkedItems = this.get('checkedItems') || [];
      return this._computeMapContext(workspaceData, checkedItems);
    }),
    // map-context binds to this
    implicitData: Ember.computed('structuredMapData', function () {
      const structuredMapData = this.get('structuredMapData');
      return (0, _flattenData.flattenData)(structuredMapData);
    }),
    hashImplicitData: Ember.computed('structuredImplicitData', function () {
      const structuredImplicitData = this.get('structuredImplicitData');
      const hash = this.get('emptyImplicitHash');

      _lodash.default.forOwn(structuredImplicitData, (value, key) => {
        hash[key] = {};
        value.forEach(v => {
          const id = v.get('id');
          hash[key][id] = true;
        });
      });

      return hash;
    }),
    // bound to computed property to implicitMarkers
    implicitStops: Ember.computed('implicitData', function () {
      const implicitData = Ember.makeArray(this.get('implicitData'));
      return implicitData.filterBy('modelName', 'stop-point').mapBy('record');
    }),
    // bound to computed property to implicitMarkers
    implicitVehicles: Ember.computed('workspaceData', 'implicitData', function () {
      const implicitData = Ember.makeArray(this.get('implicitData'));
      const vehicles = implicitData.filterBy('modelName', 'vehicle').mapBy('record');
      const implicitVehicles = vehicles;
      return implicitVehicles;
    }),
    // bind implicitContext property to boundary
    implicitMarkers: Ember.computed('implicitVehicles.[]', 'implicitStops.[]', function () {
      const implicitStops = this.get('implicitStops');
      const implicitVehicles = this.get('implicitVehicles');
      return implicitStops.concat(implicitVehicles);
    }),

    tripActiveContext() {
      return this.get('checkedItems').some(record => {
        return record.modelName === 'trip';
      });
    },

    init() {
      this._super(...arguments);

      this.set('checkedItems', []);
      this.set('refreshedModelNames', []);
      this.set('mapGraph', _mapGraph.mapContextGraph);
      this.set('mapNodes', _mapGraph.mapContextNodes);
      this.set('graph', _graph.activeContextGraph);
      this.set('nodes', _graph.activeContextNodes);

      const nodes = _graph.activeContextNodes.reduce((acu, node) => {
        acu[node.modelName] = {};
        return acu;
      }, {});

      this.set('emptyImplicitHash', nodes);
      this.startRefreshQueue();
    },

    destroy() {
      this._super(...arguments);
    },

    willDestroyElement() {
      if (this.get('setWidgetRecordsTask.isRunning')) {
        this.get('setWidgetRecordsTask').cancelAll();
      }
    },

    // Overwrite base clearCheckedItems
    clearCheckedItems() {
      const checkedItems = this.get('checkedItems');

      for (const checkedItem of checkedItems) {
        this.toggleHighLight(checkedItem.record, false);
      }

      this.set('checkedItems', []);
      this.get('widget').deselectAllWidgets();
      this.notifyPropertyChange('implicitData');
      this.refreshAll();
    },

    setWidgetRecordsTask: (0, _emberConcurrency.task)(function* (widgetRecords, widgetModelName, coreModelArgName, widgetInstanceId, checked) {
      yield this.setWidgetRecords(widgetRecords, widgetModelName, coreModelArgName, widgetInstanceId, checked);
    }).enqueue(),

    async setWidgetRecords(widgetRecords, widgetModelName, coreModelArgName, widgetInstanceId, checked) {
      let checkedItems = this.get('checkedItems');

      try {
        const store = this.get('store');

        for (const widgetRecord of widgetRecords) {
          const coreModelName = this.getCoreModelNameByWidgetRecord(coreModelArgName, widgetRecord);

          if (checked) {
            await this.get('coreEntityPopulator').populateEntityGraph(widgetModelName, widgetRecord, {});
            this.get('workspaceContext').manualReloadContext();
            const storeRecord = store.peekRecord(coreModelName, widgetRecord.id);

            if (storeRecord) {
              if (coreModelName === 'avlm-alert') {
                const vehicleRecord = store.peekRecord('avlm-vehicle', widgetRecord.vehicleId);
                this.toggleHighLight(vehicleRecord, checked);
              }

              if (coreModelName === 'avlm-trip') {
                const pickStopPointRecord = store.peekRecord('avlm-stop-point', widgetRecord.pickStopPointId);
                const dropStopPointRecord = store.peekRecord('avlm-stop-point', widgetRecord.dropStopPointId);
                this.toggleHighLight(pickStopPointRecord, checked);
                this.toggleHighLight(dropStopPointRecord, checked);
              }

              this.toggleHighLight(storeRecord, checked); // if record being updated is already in the checked items, we don't want to add it again

              const foundItem = checkedItems.find(item => item.modelName === coreModelName && item.record.id === storeRecord.id);

              if (!foundItem) {
                checkedItems.push({
                  modelName: coreModelName,
                  record: storeRecord
                });
              }
            }
          } else {
            const storeRecord = store.peekRecord(coreModelName, widgetRecord.id);

            if (storeRecord) {
              if (coreModelName === 'avlm-alert') {
                const vehicleRecord = store.peekRecord('avlm-vehicle', widgetRecord.vehicleId);
                this.toggleHighLight(vehicleRecord, checked);
              }

              if (coreModelName === 'avlm-trip') {
                const pickStopPointRecord = store.peekRecord('avlm-stop-point', widgetRecord.pickStopPointId);
                const dropStopPointRecord = store.peekRecord('avlm-stop-point', widgetRecord.dropStopPointId);
                this.toggleHighLight(pickStopPointRecord, checked);
                this.toggleHighLight(dropStopPointRecord, checked);
              }

              this.toggleHighLight(storeRecord, checked);
            }

            checkedItems = checkedItems.filter(data => !(data.modelName === coreModelName && storeRecord && data.record.id === storeRecord.id));
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error occurred while populating data for the selected widget-record ' + e);
      }

      this.set('checkedItems', checkedItems.concat([]));

      if (checked) {
        this.get('widget').addTraversalFilter(widgetInstanceId, widgetModelName, widgetRecords);
      } else {
        this.get('widget').removeTraversalFilter(widgetInstanceId, widgetModelName, widgetRecords);
      }

      this.get('widget').updateUserSelection();
    },

    getCoreModelNameByWidgetRecord(coreModelName, widgetRecord) {
      if (coreModelName === 'avlm-canned-message' && widgetRecord.priority === 'E') {
        return 'avlm-emergency-message';
      }

      return coreModelName;
    },

    getCoreModelNameByStoreRecord(coreModelName, storeRecord) {
      // The storeRecord not match the coreModelName
      if (coreModelName === 'avlm-canned-message' && storeRecord.modelName === 'avlm-emergency-message') {
        return 'avlm-emergency-message';
      }

      return coreModelName;
    }

  });

  _exports.default = _default;
});