define("adept-iq/pods/components/change-password-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CURRENT_PASSWORD_NOT_MATCHED = 'Current password is entered correctly';
  const PASSWORD__SHOULD_CONTAIN_ATLEAST_ONE_CAPITAL_LETTER = 'Password contains at least 1 capital letter';
  const PASSWORD_SHOULD_CONTAIN_ATLEAST_ONE_NUMBER = 'Password contains at least 1 number';
  const PASSWORD_DO_NOT_HAVE_MINIMUM_LENGTH = 'Password contains at least 8 characters';
  const POSSWORDS_DO_NOT_MATCH = 'Two password entries match';
  const EASY_GUESS_PASS = 'Passwords must not include common, easy to guess values';

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    errors: null,
    password: null,
    newPassword: null,
    confirmPassword: null,
    isValid: Ember.computed('password', 'newPassword', 'confirmPassword', function () {
      const password = this.get('password');
      const newPassword = this.get('newPassword');
      const confirmPassword = this.get('confirmPassword');
      return Ember.isPresent(password) && Ember.isPresent(newPassword) && Ember.isPresent(confirmPassword);
    }),

    init() {
      this._super(...arguments);
    },

    actions: {
      redirectToLogin() {
        window.location.assign('/require-new-password');
      },

      onSubmit(password, newPassword, confirmPassword, e) {
        const errorArr = [];
        const matchSimplePassword = /password|abc|123/i;

        if (password !== localStorage.currentPassword) {
          errorArr.push(CURRENT_PASSWORD_NOT_MATCHED);
        }

        if (/[A-Z]+/.test(newPassword) !== true) {
          errorArr.push(PASSWORD__SHOULD_CONTAIN_ATLEAST_ONE_CAPITAL_LETTER);
        }

        if (/\d/.test(newPassword) !== true) {
          errorArr.push(PASSWORD_SHOULD_CONTAIN_ATLEAST_ONE_NUMBER);
        }

        if (newPassword.length < 8) {
          errorArr.push(PASSWORD_DO_NOT_HAVE_MINIMUM_LENGTH);
        }

        if (newPassword !== confirmPassword) {
          errorArr.push(POSSWORDS_DO_NOT_MATCH);
        }

        if (matchSimplePassword.exec(confirmPassword)) {
          errorArr.push(EASY_GUESS_PASS);
        }

        this.set('errorArr', errorArr);
        e.preventDefault();
        this.get('onSubmit')(password, newPassword, confirmPassword, errorArr);
      }

    }
  });

  _exports.default = _default;
});