define("adept-iq/pods/components/iq-widgets/new-booking-form/service-needs-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['service-needs-form', 'travel-needs-form'],
    filteredServiceNeedFromConfig: null,
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const configItems = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-service_needs_types';
      });
      const filteredConfigs = [];

      if (this.get('serviceNeeds').length > 0) {
        this.get('serviceNeeds').forEach(serviceNeed => {
          configItems.filter(items => {
            if (serviceNeed.toLowerCase() === items.displayName.toLowerCase()) {
              filteredConfigs.pushObject(items);
            }
          });
        });
      }

      this.set('filteredServiceNeedFromConfig', filteredConfigs);
    },

    actions: {
      onAddServicelNeed() {
        this.get('serviceNeeds').pushObject('');
      },

      onRemoveServicelNeed(serviceNeed) {
        this.get('serviceNeeds').removeObject(serviceNeed);
      }

    }
  });

  _exports.default = _default;
});