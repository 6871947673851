define("adept-iq/models/reconcile-trip", ["exports", "ember-data", "moment", "adept-iq/utils/format-text-extension"], function (_exports, _emberData, _moment, _formatTextExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  const pickupTypes = ['pickup', 'pick', 'p'];
  const dropoffTypes = ['dropoff', 'drop', 'd'];

  var _default = Model.extend({
    tripId: attr('number'),
    groupId: attr('number'),
    externalTripId: attr('number'),
    promiseTime: attr('date'),
    status: attr('string'),
    otpValue: attr('number'),
    otpStatus: attr('string'),
    routeId: attr('string'),
    providerName: attr('string'),
    requestTime: attr('date'),
    serviceStartTime: attr('date'),
    serviceEndTime: attr('date'),
    travelNeeds: attr('object'),
    plannedTripMiles: attr('number'),
    actualTripMiles: attr('number'),
    statusDate: attr('date'),
    createdAt: attr('date'),
    statusReason: attr('string'),
    statusNotes: attr('string'),
    anchor: attr('string'),
    passenger: attr('object'),
    price: attr('object'),
    stops: attr('object'),
    route: attr('object'),
    type: attr('string'),
    serviceMode: attr('string'),
    passengerId: attr('number'),
    passengerNotes: attr('string'),
    driverNotes: attr('string'),
    noshowDate: attr('date'),
    previousRouteName: attr('string'),
    noshowTime: Ember.computed('noshowDate', function () {
      const dt = Ember.get(this, 'noshowDate');
      return dt ? (0, _moment.default)(dt).format('MM/DD/YYYY HH:MM A') : '';
    }),
    tripDate: Ember.computed('serviceStartTime', function () {
      const dt = Ember.get(this, 'serviceStartTime');
      return dt ? (0, _moment.default)(dt).format('MM-DD-YYYY') : '';
    }),
    formattedPromiseTime: Ember.computed('promiseTime', function () {
      const dt = Ember.get(this, 'promiseTime');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    }),
    formattedRequestTime: Ember.computed('requestTime', function () {
      const dt = Ember.get(this, 'requestTime');
      return dt ? (0, _moment.default)(dt).format('hh:mm A') : '';
    }),
    formattedCreatedDate: Ember.computed('createdAt', function () {
      const dt = Ember.get(this, 'createdAt');
      return dt ? (0, _moment.default)(dt).format('MM/DD/YYYY HH:MM A') : '';
    }),
    formattedStatus: Ember.computed('status', function () {
      const s = Ember.get(this, 'status');
      return s ? (0, _formatTextExtension.convertToCapitalize)(s) : '';
    }),
    computedStatus: Ember.computed('status', function () {
      const s = Ember.get(this, 'status');
      return s;
    }),
    formattedServiceStartTime: Ember.computed('serviceStartTime', function () {
      const dt = Ember.get(this, 'serviceStartTime');
      return dt ? (0, _moment.default)(dt).format('MM/DD/YYYY HH:MM A') : '';
    }),
    formattedServiceEndTime: Ember.computed('serviceEndTime', function () {
      const dt = Ember.get(this, 'serviceEndTime');
      return dt ? (0, _moment.default)(dt).format('MM/DD/YYYY HH:MM A') : '';
    }),
    statusLabel: Ember.computed('status', function () {
      const status = Ember.get(this, 'status');
      let statusLabel = '';

      switch (status.toLowerCase()) {
        case 'active':
        case 'a':
          statusLabel = 'Active';
          break;

        case 'noshow':
        case 'n':
          statusLabel = 'No Show';
          break;

        case 'completed':
        case 'c':
          statusLabel = 'Completed';
          break;

        case 'cancel':
        case 'cancelled':
        case 'x':
          statusLabel = 'Cancelled';
          break;

        case 'driver arrived':
        case 'r':
          statusLabel = 'Driver Arrived';
          break;

        case 'passenger on board':
        case 'p':
          statusLabel = 'Passenger on board';
          break;

        case 'on time':
        case 'o':
          statusLabel = 'On time';
          break;

        case 'danger':
        case 'in danger':
        case 'd':
          statusLabel = 'In danger';
          break;

        case 'late':
        case 'l':
          statusLabel = 'Late';
          break;

        case 'early':
        case 'e':
          statusLabel = 'Early';
          break;

        case 'scheduled':
        case 's':
          statusLabel = 'Scheduled';
          break;

        case 'waitListed':
        case 'wl':
          statusLabel = 'Waitlisted';
          break;

        default:
          statusLabel = status;
          break;
      }

      return statusLabel;
    }),
    polyline: Ember.computed('stops', function () {
      const stops = Ember.get(this, 'stops');
      let pickLat = null;
      let pickLng = null;
      let dropLat = null;
      let dropLng = null;
      stops.map(item => {
        if (item.type === 'dropoff' && item.address) {
          if (item.address.lat && item.address.lng && item.address.lat.toString() && item.address.lng.toString()) {
            dropLat = item.address.lat.toString();
            dropLng = item.address.lng.toString();
          }
        } else if (item.address.lat && item.address.lng && item.type === 'pickup' && item.address) {
          if (item.address.lat.toString() && item.address.lng.toString()) {
            pickLat = item.address.lat.toString();
            pickLng = item.address.lng.toString();
          }
        }
      });

      if ([pickLat, pickLng, dropLat, dropLng].any(Ember.isNone)) {
        return null;
      } // Should display like P----->D, but decorator(>) can only set on start point, so we need to make D as start point


      return [[dropLat, dropLng], [pickLat, pickLng]];
    }),
    travelRequirements: Ember.computed('travelNeeds', function () {
      const travelNeeds = Ember.get(this, 'travelNeeds');
      const result = {};

      if (!Ember.isEmpty(travelNeeds)) {
        JSON.parse(travelNeeds).map(item => {
          result[item.name] = item.cnt;
        });
      }

      return result;
    }),
    stopDetails: Ember.computed('stops', function () {
      const stops = Ember.get(this, 'stops');
      const result = {
        pick: null,
        drop: null
      };

      if (!Ember.isEmpty(stops)) {
        stops.map(stop => {
          if (stop.type && dropoffTypes.includes(stop.type.toLowerCase())) {
            result.drop = stop;
          } else if (stop.type && pickupTypes.includes(stop.type.toLowerCase())) {
            result.pick = stop;
          }
        });
      }

      return result;
    }),
    pickStopAddressDetails: Ember.computed('pickupStopDetails', function () {
      return Ember.get(this, 'pickupStopDetails.address');
    }),
    dropStopAddressDetails: Ember.computed('dropoffStopDetails', function () {
      return Ember.get(this, 'dropoffStopDetails.address');
    }),
    pickStopFormattedCity: Ember.computed('pickStopAddressDetails', function () {
      const a = Ember.get(this, 'pickStopAddressDetails');
      const c = Ember.get(a, 'city');

      if (Ember.isEmpty(c)) {
        const add = Ember.get(a, 'formattedAddress');

        if (!Ember.isEmpty(add)) {
          const addStr = (0, _formatTextExtension.convertToPascalCase)(add);
          const addArray = addStr.split(',');
          return addArray[addArray.length - 2];
        }
      }

      return (0, _formatTextExtension.convertToPascalCase)(c);
    }),
    dropStopFormattedCity: Ember.computed('dropStopAddressDetails', function () {
      const a = Ember.get(this, 'dropStopAddressDetails');
      const c = Ember.get(a, 'city');

      if (Ember.isEmpty(c)) {
        const add = Ember.get(a, 'formattedAddress');

        if (!Ember.isEmpty(add)) {
          const addStr = (0, _formatTextExtension.convertToPascalCase)(add);
          const addArray = addStr.split(',');
          return addArray[addArray.length - 2];
        }
      }

      return (0, _formatTextExtension.convertToPascalCase)(c);
    }),
    pickupStopDetails: Ember.computed.alias('stopDetails.pick'),
    dropoffStopDetails: Ember.computed.alias('stopDetails.drop'),
    pickupStopTime: Ember.computed('pickupStopDetails', function () {
      const startTime = Ember.get(this, 'pickupStopDetails.actualArriveTime');
      const endTime = Ember.get(this, 'pickupStopDetails.actualDepartTime');
      let mins = 0;

      if (startTime && endTime && _moment.default.utc(startTime).isBefore(_moment.default.utc(endTime))) {
        mins = _moment.default.utc((0, _moment.default)(endTime, 'HH:mm:ss').diff((0, _moment.default)(startTime, 'HH:mm:ss'))).format('mm');
      }

      return mins;
    }),
    dropoffStopTime: Ember.computed('dropoffStopDetails', function () {
      const startTime = Ember.get(this, 'dropoffStopDetails.actualArriveTime');
      const endTime = Ember.get(this, 'dropoffStopDetails.actualDepartTime');
      let mins = 0;

      if (startTime && endTime && _moment.default.utc(startTime).isBefore(_moment.default.utc(endTime))) {
        mins = _moment.default.utc((0, _moment.default)(endTime, 'HH:mm:ss').diff((0, _moment.default)(startTime, 'HH:mm:ss'))).format('mm');
      }

      return mins;
    }),
    formattedPickupPromiseTime: Ember.computed('pickupStopDetails', function () {
      const pickupStop = Ember.get(this, 'pickupStopDetails');
      return pickupStop && pickupStop.promiseTime ? (0, _moment.default)(pickupStop.promiseTime).format('hh:mm A') : '';
    }),
    formattedDropoffPromiseTime: Ember.computed('dropoffStopDetails', function () {
      const dropoffStop = Ember.get(this, 'dropoffStopDetails');
      return dropoffStop && dropoffStop.promiseTime ? (0, _moment.default)(dropoffStop.promiseTime).format('hh:mm A') : '';
    }),
    formattedPickupActualArriveTime: Ember.computed('pickupStopDetails', function () {
      const pickupStop = Ember.get(this, 'pickupStopDetails');
      return pickupStop && pickupStop.actualArriveTime ? (0, _moment.default)(pickupStop.actualArriveTime).format('hh:mm A') : '';
    }),
    formattedDropoffActualArriveTime: Ember.computed('dropoffStopDetails', function () {
      const dropoffStop = Ember.get(this, 'dropoffStopDetails');
      return dropoffStop && dropoffStop.actualArriveTime ? (0, _moment.default)(dropoffStop.actualArriveTime).format('hh:mm A') : '';
    }),
    formattedPickupActualDepartTime: Ember.computed('pickupStopDetails', function () {
      const pickupStop = Ember.get(this, 'pickupStopDetails');
      return pickupStop && pickupStop.actualDepartTime ? (0, _moment.default)(pickupStop.actualDepartTime).format('hh:mm A') : '';
    }),
    formattedDropoffActualDepartTime: Ember.computed('dropoffStopDetails', function () {
      const dropoffStop = Ember.get(this, 'dropoffStopDetails');
      return dropoffStop && dropoffStop.actualDepartTime ? (0, _moment.default)(dropoffStop.actualDepartTime).format('hh:mm A') : '';
    }),
    otpValueWithSign: Ember.computed('otpStatus', 'otpValue', function () {
      const otpStatus = Ember.get(this, 'otpStatus');
      const otpValue = Ember.get(this, 'otpValue');
      const msgStr = parseInt(otpStatus, 10) === -1 ? 'Late' : 'Early';

      if (!otpValue) {
        return msgStr;
      }

      return msgStr + (parseInt(otpStatus, 10) === -1 ? ' +' : ' -') + otpValue + (otpValue > 1 ? ' mins' : ' min') + ' over OTP';
    }),
    formattedTravelNeeds: Ember.computed('travelNeeds', function () {
      const travelNeeds = [];
      let equipments = this.get('travelNeeds');

      if (!Ember.isEmpty(equipments)) {
        if (typeof equipments === 'string') {
          equipments = JSON.parse(equipments);
        }

        equipments.forEach(function (item) {
          const count = item.cnt;
          const name = item.name;
          const text = (0, _formatTextExtension.formatTextExtension)('valueItem', `${count} ${name}`);
          travelNeeds.push(text);
        });
      }

      travelNeeds.push((0, _formatTextExtension.formatTextExtension)('mainItem', ''));
      return {
        values: travelNeeds
      };
    }) // Relationship bindings

    /*passenger: belongsTo('reconcile-passenger'),
    price: belongsTo('reconcile-price'),
    stops: hasMany('reconcile-stop'),
    driver: belongsTo('reconcile-driver'),
    route: belongsTo('reconcile-route')*/

  });

  _exports.default = _default;
});