define("adept-iq/pods/components/side-drawer/permissions-add-user/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    errorMessages: null,
    userDetails: null,
    title: 'Select Providers',
    callBack: Ember.computed.readOnly('workspace.topOptions.action'),
    userRecords: Ember.computed.readOnly('workspace.topOptions.userRecords'),
    hasOneProvider: Ember.computed.lte('providersList.length', 1),
    isSaveEnabled: Ember.computed.and('userDetails.{firstName,lastName,userName,password}', 'selectedStatus'),
    isUndoEnabled: Ember.computed.or('userDetails.{firstName,lastName,emailId,userName,password}', 'selectedStatus', 'selectedRoles'),
    canSave: Ember.computed('isSaveEnabled', 'title', function () {
      return Ember.get(this, 'isSaveEnabled') && Ember.get(this, 'title') !== 'Select Providers';
    }),
    canUndo: Ember.computed('isUndoEnabled', 'title', function () {
      return Ember.get(this, 'isUndoEnabled') || Ember.get(this, 'title') !== 'Select Providers';
    }),
    statusOptions: Ember.computed('selectedStatus', function () {
      return ['Active', 'Inactive'];
    }),
    roleOptions: Ember.computed('selectedRoles', function () {
      const roles = [];
      const rolesList = Ember.get(this, 'store').peekAll('perm-sso-role');
      rolesList.map(role => {
        if (role.id) {
          roles.push(role);
        }
      });
      return roles;
    }),

    init() {
      this._super(...arguments);

      this.resetUserProperties();
      this.fillProviders();
      this.updateTitle();
    },

    didInsertElement() {
      this._super(...arguments);
    },

    resetUserProperties() {
      this.setProperties({
        userDetails: {
          firstName: '',
          lastName: '',
          emailId: '',
          userName: '',
          password: ''
        },
        errorMessages: [],
        selectedRoles: null,
        changedProviders: null,
        selectedStatus: null,
        title: 'Select Providers'
      });
      this.checkUncheckAllProviders(false);
    },

    checkUncheckAllProviders(isChecked) {
      const providerList = Ember.get(this, 'providersList');
      if (Ember.isEmpty(providerList)) return;
      providerList.map(provider => {
        provider.set('isChecked', isChecked);
      });
    },

    async fillProviders() {
      const providers = [];
      const adaptor = 'perm-sso-user';
      const permUserAdapter = this.get('store').adapterFor(adaptor);
      const providersList = await permUserAdapter.getSSOProvider();
      if (Ember.isEmpty(providersList)) return;
      providersList.map(provider => {
        providers.push(Ember.Object.create({
          id: provider.id,
          providerName: provider.provider === 'ALL' ? 'Select All' : provider.provider,
          isChecked: false
        }));
      });
      this.set('providersList', providers);
      this.updateTitle();
    },

    isValid() {
      let isValid = true;
      Ember.set(this, 'errorMessages', []);

      if (!Ember.isEmpty(this.userDetails.password)) {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,20}$/;

        if (!passwordRegex.test(this.userDetails.password)) {
          this.errorMessages.push('Password should contain at least 1 uppercase, 1 lowercase, 1 digit and 1 special character');
          isValid = false;
          return;
        }

        const matchSimplePassword = /password|abc|123/i;

        if (matchSimplePassword.exec(this.userDetails.password)) {
          this.errorMessages.push('Passwords must not include common, easy to guess values');
          isValid = false;
          return;
        }
      }

      if (!Ember.isEmpty(this.userDetails.emailId)) {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (!emailRegex.test(this.userDetails.emailId)) {
          this.errorMessages.push('Please enter valid email id');
          isValid = false;
        }
      } else if (this.userDetails && this.get('userRecords')) {
        this.get('userRecords').map(data => {
          if (data && data.userName === this.userDetails.userName) {
            this.errorMessages.push('* Duplicated username');
            isValid = false;
            return;
          }

          return;
        });
      }

      return isValid;
    },

    saveUser() {
      return Ember.get(this, 'saveUserTask').perform().then(() => {
        this.send('cancel');
        this.get('notifications').success('Added New User Successfully');
        this.get('workspace').popState('permissionsAddNewUser');
      }).catch(error => {
        let message;

        switch (error.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem in creating new user, please try again.';
            break;
        }

        this.errorMessages.push(message);
        this.get('notifications').warning(message);
      });
    },

    saveUserTask: (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const userAdapter = store.adapterFor('perm-sso-user');
        const payload = {
          firstName: this.userDetails.firstName,
          lastName: this.userDetails.lastName,
          // emailId: this.userDetails.emailId,
          status: this.selectedStatus ? this.selectedStatus.toLowerCase() : 'active',
          roles: this.selectedRoles ? this.selectedRoles.map(role => role.roleName) : [],
          username: this.userDetails.userName,
          password: this.userDetails.password,
          providers: [0]
        };
        yield userAdapter.postUser(payload);
      } catch (error) {
        throw error;
      }
    }),

    getSelectedProviders() {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return [];
      const results = this.get('providersList').filterBy('isChecked', true);
      return results.map(p => {
        if (p.providerName !== 'All Providers') {
          return p.id;
        }
      });
    },

    updateTitle() {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return;
      const results = this.get('providersList').filterBy('isChecked', true);

      if (results.length === 0) {
        this.set('title', 'Select Providers');
      } else if (results.length === 1) {
        if (results[0].providerName === 'Select All') {
          this.set('title', 'All Providers');
        } else {
          this.set('title', results[0].providerName);
        }
      } else if (results.length > 1 && results.length < this.providersList.length) {
        this.set('title', 'Multiple');
      } else if (results.length === this.providersList.length) {
        this.set('title', 'All Providers');
      }
    },

    handleCheckUncheckSelectAll() {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return;
      let results = providersList.filterBy('isChecked', false);

      if (results.length === 1 && results[0].providerName === 'Select All') {
        results[0].set('isChecked', true);
      } else {
        results = providersList.filterBy('providerName', 'Select All');
        results[0].set('isChecked', false);
      }
    },

    handleCheckUncheckProviders(provider, isChecked) {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return;

      if (provider.providerName === 'Select All') {
        providersList.map(item => {
          item.set('isChecked', isChecked);
        });
      } else {
        providersList.map(item => {
          if (provider.id === item.id) {
            item.set('isChecked', isChecked);
          }
        });
        this.handleCheckUncheckSelectAll();
      }

      this.updateTitle();
    },

    toggleSelect() {
      this.toggleProperty('isOpen');
    },

    showToolTip() {
      const tooltip = this.get('tooltip');
      const tip = 'Do you want to add a new user?';
      tooltip.pushConfirmation({
        title: 'Add User',
        tip,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return Ember.get(this, 'saveUserTask').perform().then(() => {
            this.send('cancel');
            this.get('notifications').success('Added New User Successfully');
            this.get('workspace').popState('permissionsAddNewUser');
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in creating new user, please try again.';
                break;
            }

            this.errorMessages.push(message);
            this.get('notifications').warning(message);
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    actions: {
      onChangeStatus(option) {
        Ember.set(this, 'selectedStatus', option);
      },

      onChangeRole(option) {
        Ember.set(this, 'selectedRoles', option);
      },

      onUndoClick() {
        this.resetUserProperties();
      },

      onSaveClick() {
        if (this.isValid()) {
          Ember.set(this, 'errorMessages', []);
          this.showToolTip();
        }
      },

      onCloseClick() {},

      onToggleSelect() {
        this.toggleSelect();
      },

      onOpenProvider() {
        this.toggleSelect();
      },

      onCloseProvider() {
        this.toggleSelect();
      },

      onProviderCheckboxClick(provider, event) {
        Ember.set(this, 'changedProviders', true);
        this.handleCheckUncheckProviders(provider, event.target.checked);
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      }

    }
  });

  _exports.default = _default;
});