define("adept-iq/models/leg", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    requestTime: attr('date'),
    anchor: attr('string'),
    purpose: attr('string'),
    notes: attr('string'),
    brokerEligible: attr('boolean'),
    rider: belongsTo('rider', {
      async: true
    }),
    booking: belongsTo('booking'),
    serviceWindow: belongsTo('service-window'),
    segments: hasMany('segment'),
    legTravelNeeds: hasMany('leg-travel-need'),
    riderNotes: Ember.computed.alias('rider.notes'),
    tripStartTime: Ember.computed.alias('booking.tripStartTime'),
    tripEndTime: Ember.computed.alias('booking.tripEndTime'),
    equipments: Ember.computed('legTravelNeeds.[]', function () {
      const legTravelNeeds = Ember.get(this, 'legTravelNeeds');
      const equipments = [];

      if (!Ember.isEmpty(legTravelNeeds)) {
        legTravelNeeds.forEach(travelNeed => {
          if (travelNeed.get('isEquipment')) {
            equipments.push(travelNeed);
          }
        });
      }

      return equipments;
    }),
    extraPassengers: Ember.computed.filterBy('legTravelNeeds', 'isExtraPassenger', true),
    legEquipments: Ember.computed('leg', function () {
      const legTravelNeeds = Ember.get(this, 'leg.legTravelNeeds');
      const equipments = legTravelNeeds.get('equipments');
      return equipments ? equipments : null;
    }),
    legExtraPassengers: Ember.computed('leg', function () {
      const legTravelNeeds = Ember.get(this, 'leg.legTravelNeeds');
      const extraPassengers = legTravelNeeds.get('extraPassengers');
      return extraPassengers ? extraPassengers : null;
    }),
    segment: Ember.computed('segments', function () {
      const segments = this.get('segments');

      if (segments) {
        return segments.firstObject;
      }

      return null;
    }),
    noOfTravelNeeds: Ember.computed('legTravelNeeds.[]', function () {
      const noOfTravelNeeds = Ember.get(this, 'legTravelNeeds');

      if (!Ember.isEmpty(noOfTravelNeeds)) {
        return noOfTravelNeeds.length;
      }

      return 0;
    }),
    sortedLegTravelNeeds: Ember.computed('legTravelNeeds', function () {
      const travelNeeds = Ember.get(this, 'legTravelNeeds');

      if (!Ember.isEmpty(travelNeeds)) {
        return travelNeeds.sortBy('travelNeedType.name');
      }

      return null;
    }),
    travelNeedCounts: Ember.computed('legTravelNeeds.@each.{travelNeedTypeName,count}', function () {
      const initialCounts = {
        wheelchair: 0,
        wideWheelchair: 0,
        ambulatory: 0,
        wideAmbulatory: 0,
        serviceAnimal: 0
      };
      const legTravelNeeds = this.get('legTravelNeeds');
      if (Ember.isEmpty(legTravelNeeds)) return null;
      return legTravelNeeds.reduce((obj, legTravelNeed) => {
        const travelNeedTypeName = legTravelNeed.get('travelNeedTypeName');
        const count = legTravelNeed.get('count') || 0;
        obj[travelNeedTypeName] = obj[travelNeedTypeName] + count;
        return obj;
      }, initialCounts);
    }),
    legOrdinal: Ember.computed('segment.legOrdinal', function () {
      return this.get('segment.legOrdinal');
    })
  });

  _exports.default = _default;
});