define("adept-iq/pods/components/workspace-navbar/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    or,
    equal
  } = Ember.computed;
  const DEFAULT_TERM = 'default';
  const BOOKING_TIMELINE_CONFIG_PATH = 'config-Book_Trips/Future_Booking_Timeline';

  var _default = Ember.Component.extend({
    classNames: ['workspace-navbar'],
    classNameBindings: ['reoptimizeInProgress', 'isExported'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    timeFormat: 'm-d-Y',
    schedules: null,
    scheduleDate: null,
    isReoptimizeOpen: false,
    minScheduleDate: null,
    isScheduleDashboard: readOnly('workspace.isScheduleDashboard'),
    isDispatchDashboard: readOnly('workspace.isDispatchDashboard'),
    isBookingDashboard: readOnly('workspace.isBookingDashboard'),
    currentSchedule: readOnly('workspace.currentSchedule'),
    isExported: readOnly('workspace.isExported'),
    operationType: readOnly('workspace.operationType'),
    reoptimizeInProgress: or('workspace.reoptimizeInProgress'),
    disableReoptimizeBtn: or('isExported', 'reoptimizeInProgress'),
    isExporting: equal('operationType', 'export'),
    isBrokerExporting: equal('operationType', 'brokerExport'),

    init() {
      this._super(...arguments);

      this.set('minScheduleDate', (0, _moment.default)().startOf('day').add(1, 'day').toISOString());
    },

    didInsertElement() {
      this._super(...arguments);

      this.refreshTask.perform();
    },

    workspaceName: Ember.computed('workspace.dashboardModel.{category,role,name}', function () {
      const dashboard = this.get('workspace.dashboardModel');

      if (dashboard) {
        const dashboardRole = dashboard.get('role');
        const dashboardName = dashboard.get('name');
        const category = dashboard.get('category');

        if (category && category.includes(DEFAULT_TERM)) {
          return `${dashboardRole} [default]`;
        } else if (dashboardName && dashboardRole) return `${dashboardRole} - ${dashboardName}`;

        return dashboardRole;
      }

      return null;
    }),
    // in used by release schedule / reoptimize
    inProgressTitle: Ember.computed('reoptimizeInProgress', 'isExported', 'isExporting', 'isBrokerExporting', function () {
      const isExported = this.get('isExported');
      const isExporting = this.get('isExporting');
      const isBrokerExporting = this.get('isBrokerExporting');
      if (isExporting || isBrokerExporting) return 'RELEASE IN PROGRESS';else if (isExported) return '';
      return 'REOPTIMIZATION IN PROGRESS';
    }),
    // in used by release schedule / reoptimize
    progress: Ember.computed('workspace.progressValue', function () {
      const progressValue = this.get('workspace.progressValue');

      if (progressValue) {
        // reoptimize progress value should not greater than 100
        return progressValue > 100 ? 100 : progressValue;
      }

      return 0;
    }),
    maxScheduleDate: Ember.computed('bookingWindow.value', function () {
      const bookingWindowVal = parseInt(this.get('bookingWindow.value'), 10);
      return bookingWindowVal ? (0, _moment.default)().endOf('day').add(bookingWindowVal, 'days').toDate() : null;
    }),
    refreshTask: (0, _emberConcurrency.task)(function* () {
      // incase today ever stretches into the next day, this will update once the user moves to that date
      // thus today -> tomorrow, and booking window will also slide forward
      this.get('fetchBookingWindow').perform();
      this.get('fetchSchedulesTask').perform();
      this.set('minScheduleDate', (0, _moment.default)().startOf('day').add(1, 'day').toISOString());
      yield;
    }),
    fetchBookingWindow: (0, _emberConcurrency.task)(function* () {
      const config = yield this.store.findRecord('cs-config-item', BOOKING_TIMELINE_CONFIG_PATH);

      if (config) {
        this.set('bookingWindow', config);
      }
    }),
    fetchSchedulesTask: (0, _emberConcurrency.task)(function* () {
      const filterQuery = 'eq(type,\'primary\')';
      let schedules = yield this.store.query('schedule', {
        filter: filterQuery
      });

      if (schedules) {
        schedules = schedules.sortBy('end');
      }

      this.set('schedules', schedules);
    }),

    async reInitializeStore() {
      const store = this.store;
      const workspace = this.get('workspace');
      const topActiveContext = this.get('activeContext.topActiveContext');
      const notifications = this.get('notifications'); // clear active context traversal

      Ember.run.scheduleOnce('actions', topActiveContext, 'reset'); // dump all widget data

      topActiveContext.trigger('clear');
      await notifications.get('monitorForEmergencyTask').cancelAll(); // dump ember store

      await Ember.run.scheduleOnce('destroy', store, 'init');
      await Ember.run.next(() => {
        store.findAll('cs-config-category');
        store.findAll('cs-config-item');
        store.findAll('route-template');
        store.findAll('cs-config-permission').then(configPermissions => {
          return workspace.set('configPermissions', configPermissions.get('firstObject.value'));
        });
        notifications.get('monitorForEmergencyTask').perform();
      });
    },

    async updateSchedulingWorkspaceTimeRange(date) {
      const momentDate = (0, _moment.default)(date);
      await this.reInitializeStore();
      await Ember.run.next(() => {
        this.workspace.setProperties({
          startDate: momentDate.startOf('day').toDate(),
          endDate: momentDate.endOf('day').toDate()
        });
        this.refreshTask.perform();
      });
    },

    actions: {
      onUpdateTimeRangeScheduleWorkspace(selectedDate) {
        this.updateSchedulingWorkspaceTimeRange(selectedDate);
      },

      openReoptimizeModal() {
        const currentSchedule = this.get('currentSchedule');
        this.get('workspace').pushState('reoptimize', {
          currentSchedule
        });
      }

    }
  });

  _exports.default = _default;
});