define("adept-iq/pods/components/form-components/quick-filters/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    classNames: ['column-widget-filter'],
    classNameBindings: ['isActive'],
    isActive: false,

    init() {
      this._super(...arguments);

      this.filter.quickFilterComponent = this;
    },

    didInsertElement() {
      this._super(...arguments);

      const newlyCreatedQuickFilter = this.get('workspace.newlyCreatedQuickFilter');
      const filter = this.get('filter');

      if (Ember.isPresent(newlyCreatedQuickFilter) && filter.id === newlyCreatedQuickFilter.id) {
        this.toggleFilter();
        this.set('workspace.newlyCreatedQuickFilter', null);
      }
    },

    onFilterChanged: Ember.observer('isActive', function () {
      this.set('filter.active', this.get('isActive'));
    }),

    toggleFilter() {
      const active = this.get('isActive');
      this.clearFilters();

      if (!active) {
        this.set('isActive', true);
      }

      this.get('onRefresh')();
    },

    clearFilters() {
      const columnList = this.get('tableColumns') || [];
      const quickFilters = this.get('quickFilters') || [];
      const columns = columnList.reduce((obj, column) => {
        obj[column.id] = {
          filterTypeId: 'null',
          // this is actually the id of the null filter
          filterValues: null
        };
        return obj;
      }, {});
      quickFilters.forEach(filter => {
        const active = filter.quickFilterComponent.get('isActive');

        if (active) {
          const quickFilterComponent = filter.quickFilterComponent;
          quickFilterComponent.set('isActive', false);
        }
      });
      this.get('widget').mergeState({
        columns
      });
    },

    removeQuickFilter() {
      const currentId = this.get('filter.id');
      const widgetTypeId = this.get('widget.typeId');
      const widgetStates = { ...this.get('workspace.widgetStates')
      };
      const widgetState = widgetStates[widgetTypeId] || {};
      const quickFilters = widgetState.quickFilters || []; // remove from local copy

      _lodash.default.remove(quickFilters, filter => {
        return filter.id === currentId;
      });

      this.set('workspace.widgetStates', widgetStates); // remove from dashboard model

      const dashboardWidgetStates = { ...this.get('workspace.dashboardModel.widgetStates')
      };
      const dashboardWidgetState = dashboardWidgetStates[widgetTypeId] || {};
      const dashboardQuickFilters = dashboardWidgetState.quickFilters || [];

      _lodash.default.remove(dashboardQuickFilters, filter => {
        return filter.id === currentId;
      });

      this.set('workspace.dashboardModel.widgetStates', dashboardWidgetStates);
    },

    actions: {
      onRemoveFilterClick() {
        const tooltip = this.get('tooltip');
        const filterName = this.get('filter.displayName');
        tooltip.pushConfirmation({
          title: 'Manage Filter',
          tip: `Are you sure you want to delete the ${filterName} filter?`,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            this.removeQuickFilter();
            return tooltip.reset();
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          }
        });
      },

      onFilterApplyAction() {
        this.toggleFilter();
      }

    }
  });

  _exports.default = _default;
});