define("adept-iq/pods/components/generic-widgets/ember-react-table/classes/gridwidget", ["exports", "@babel/runtime/helpers/esm/extends", "react", "react-dom", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/cellrenderer", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/grid-context-menu", "react-virtualized", "react-sortable-hoc", "adept-iq/pods/components/generic-widgets/ember-react-table/classes/row-range-renderer"], function (_exports, _extends2, _react, _reactDom, _cellrenderer, _gridContextMenu, _reactVirtualized, _reactSortableHoc, _rowRangeRenderer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RenderInBody = void 0;

  /* eslint-disable prefer-const */

  /* eslint-disable no-unused-vars */

  /* eslint-disable no-unused-expressions */
  const createRef = _react.default.createRef;
  const AutoSizer = _reactVirtualized.default.AutoSizer;
  const MultiGrid = _reactVirtualized.default.MultiGrid;
  const Grid = _reactVirtualized.default.Grid;
  const InfiniteLoader = _reactVirtualized.default.InfiniteLoader;
  const ArrowKeyStepper = _reactVirtualized.default.ArrowKeyStepper;

  const SortableMultiGrid = _reactSortableHoc.default.sortableContainer(MultiGrid, {
    withRef: true
  });

  const Row = ({
    children,
    style
  }) => _react.default.createElement("div", {
    className: "SortableMultiGrid__Row",
    style: style
  }, children);

  const SortableRow = _reactSortableHoc.default.sortableElement(Row);

  const arrayMove = _reactSortableHoc.default.arrayMove;
  const sortableRowRenderer = (0, _rowRangeRenderer.rowRangeRenderer)(props => _react.default.createElement(SortableRow, (0, _extends2.default)({
    key: props.rowIndex
  }, props, {
    index: props.rowIndex
  })));
  const fixdRowRenderer = (0, _rowRangeRenderer.rowRangeRenderer)(props => _react.default.createElement(Row, (0, _extends2.default)({
    key: props.rowIndex
  }, props, {
    index: props.rowIndex
  })));
  const DEFAULT_COLUMN_WIDTH = 100; // ToDO

  const CHECKBOX_COLUMN_WIDTH = 25; // ToDO

  const GEARCOG_COLUMN_WIDTH = 35; // ToDO

  const MIN_COLUMN_WIDTH = 50;
  const BATCH_SIZE = 20;
  const initialContextMenuState = {
    rowIndex: -1,
    //
    columnIndex: -1,
    positionX: 0,
    positionY: 0,
    visible: false
  };

  class RenderInBody extends _react.default.PureComponent {
    constructor(props) {
      super(props);
      this.el = document.createElement('div');
      this.el.style.display = 'contents'; // The <div> is a necessary container for our content, but it should not affect our layout. Only works in some browsers, but generally doesn't matter since this is at the end anyway. Feel free to delete this line.
    }

    componentDidMount() {
      document.body.appendChild(this.el);
    }

    componentWillUnmount() {
      document.body.removeChild(this.el);
    }

    render() {
      return _reactDom.default.createPortal(this.props.children, this.el);
    }

  }

  _exports.RenderInBody = RenderInBody;

  class GridWidget extends _react.default.Component {
    constructor(props) {
      super(props);
      const {
        config,
        visibleColumns,
        widgetState,
        modelName,
        isMaximized
      } = props;
      const widgetStateSortedColumn = config.columns.filter(cfg => cfg.id === widgetState.sortId);
      let sortId = config.defaultSortId || 'id';

      if (!Ember.isEmpty(widgetStateSortedColumn)) {
        sortId = widgetStateSortedColumn[0].id;
      }

      this.state = {
        data: [],
        loading: false,
        columns: visibleColumns,
        columnwidths: {},
        resizing: false,
        selectedRowLimit: config.selectedRowLimit,
        sortId: sortId,
        sortAsc: widgetState.sortAsc || config.defaultSortAsc || true,
        reordering: false,
        contextMenuState: initialContextMenuState,
        checkedRowIds: props.checkedRowIds,
        disabledRowIds: props.disabledRowIds,
        hiddenRowIds: props.hiddenRowIds,
        isFurtherSelectionAllowed: props.isFurtherSelectionAllowed,
        selectedRowIndex: -1,
        selectedColumnIndex: -1,
        width: props.width,
        height: props.height,
        widgetInstanceId: props.widgetInstanceId,
        showGearIcon: props.showGearIcon,
        useTabletWidth: props.useTabletWidth,
        isMaximized
      };
      this.config = config;
      this.domNode = null;
      this.multiGridRef = null;
      this.infiniteLoaderRef = null;
      this.headerCellClickHandler = this.headerCellClickHandler.bind(this);
      this.resizeRow = this.resizeRow.bind(this);
      this.getColumnWidth = this.getColumnWidth.bind(this);
      this._cellRenderer = this._cellRenderer.bind(this);
      this._rowRenderer = this._rowRenderer.bind(this);
      this._getSortableContainer = this._getSortableContainer.bind(this);
      this.resizeStop = this.resizeStop.bind(this);
      this.reorderRows = this.reorderRows.bind(this);
      this.onContextMenuClick = this.onContextMenuClick.bind(this);
      this.onTableCellClick = this.onTableCellClick.bind(this);
      this.menuItemCallback = this.menuItemCallback.bind(this);
      this._infiniteLoaderChildFunction = this._infiniteLoaderChildFunction.bind(this);
      this._onSectionRendered = this._onSectionRendered.bind(this);
      this.isRowLoaded = this.isRowLoaded.bind(this);
      this.checkBtnClickHandler = this.checkBtnClickHandler.bind(this);
      this.checkBtnAllClickHandler = this.checkBtnAllClickHandler.bind(this);
      this._selectCell = this._selectCell.bind(this);
      this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
      this.documentClickHandler = this.documentClickHandler.bind(this);
      this.loadMoreRowsHandler = this.loadMoreRowsHandler.bind(this);
      this.cellRightClickForTablet = this.cellRightClickForTablet.bind(this);
      this.documentRightClickHandler = this.documentRightClickHandler.bind(this);
      this.init();
    }

    init() {
      let columnIncrementerValue = 1;
      const {
        widgetState
      } = this.props;
      const {
        columnwidths,
        columns,
        showGearIcon,
        useTabletWidth
      } = this.state;
      columnwidths[0] = CHECKBOX_COLUMN_WIDTH;

      if (showGearIcon) {
        columnwidths[1] = GEARCOG_COLUMN_WIDTH;
        columnIncrementerValue = 2;
      }

      columns.forEach((column, index) => {
        const savedWidth = widgetState.columns && widgetState.columns[column.id] ? widgetState.columns[column.id].width : null;
        const configWidth = useTabletWidth && column.defaultTabletWidth ? column.defaultTabletWidth : column.defaultWidth;
        const defaultWidth = savedWidth || configWidth;
        columnwidths[index + columnIncrementerValue] = defaultWidth || DEFAULT_COLUMN_WIDTH;
      });
    }

    onKeyPressHandler(event) {
      if (event.keyCode === 32) {
        // space key
        this.checkBtnClickHandler(this.state.selectedRowIndex);
        event.preventDefault();
      }
    }

    componentDidMount() {}

    componentWillUnmount() {}

    documentClickHandler(event) {
      if (this.isClickedOutsideContainer(event)) {
        this.hideContextMenu();
      }
    }

    documentRightClickHandler(event) {
      this.hideContextMenu();
    } // check mouse is clicked outside the widget container


    isClickedOutsideContainer(event) {
      const $target = Ember.$(event.target);
      return !$target.closest('.global-dropdown-context').length;
    }

    loadMoreRowsHandler({
      startIndex,
      stopIndex
    }) {
      const {
        onLazyLoader
      } = this.props;
      const {
        sortId,
        sortAsc
      } = this.state;
      const pageSize = stopIndex - startIndex + 1;
      const offset = startIndex;

      if (offset > pageSize) {
        onLazyLoader(offset, pageSize, sortId, sortAsc);
      }
    }

    menuItemCallback({
      name,
      action
    }) {
      this.hideContextMenu();
      const {
        contextMenuState,
        data,
        checkedRowIds
      } = this.state;
      const {
        fetchModelbyWidgetRecord,
        fetchModelsbyCheckedRowIds
      } = this.props;
      const {
        rowIndex
      } = contextMenuState;

      if (checkedRowIds.length > 1) {
        const selectedModels = fetchModelsbyCheckedRowIds(checkedRowIds);
        action && action(selectedModels);
      } else {
        const selectedRowData = data.length ? this.getRowData(data, rowIndex) : null;
        const selectedModel = fetchModelbyWidgetRecord(selectedRowData);
        action && action(selectedModel);
      }
    }

    onContextMenuClick(event, rowIndex, columnIndex) {
      event.stopPropagation();
      this.setState({
        selectedRowIndex: rowIndex,
        selectedColumnIndex: columnIndex
      }, () => {
        this.multiGridRef.forceUpdateGrids();
      });
      const {
        onRowRightClick
      } = this.props;
      if (!onRowRightClick) return;
      const parentContainerCssClass = `.${GridWidget.containerCssClass}`;
      const widgetContainerOffset = Ember.$(event.target).closest(parentContainerCssClass).offset(); // const positionX = window.pageXOffset + event.clientX - widgetContainerOffset.left;
      // const positionY = window.pageYOffset + event.clientY - widgetContainerOffset.top + 50;

      const positionX = window.pageXOffset + event.clientX;
      const positionY = window.pageYOffset + event.clientY;
      const {
        data
      } = this.state;
      const selectedRowData = data.length ? this.getRowData(data, rowIndex) : null;
      onRowRightClick(selectedRowData).then(menusItems => {
        this.updateContextMenuState({
          rowIndex,
          columnIndex,
          positionX,
          positionY,
          menusItems,
          visible: true
        }); // Avoid duplicate event lister, so remove it before add a new one.

        document.removeEventListener('click', this.documentClickHandler, false);
        document.addEventListener('click', this.documentClickHandler);
        document.removeEventListener('contextmenu', this.documentRightClickHandler, false);
        document.addEventListener('contextmenu', this.documentRightClickHandler);
      });
    }

    updateContextMenuState(updatedState) {
      const contextMenuState = { ...this.state.contextMenuState,
        ...updatedState
      };
      this.setState({
        contextMenuState
      }, () => {
        this.multiGridRef.forceUpdateGrids();
      });
    }

    onTableCellClick(selectedRowIndex, selectedColumnIndex) {
      this.setState({
        selectedRowIndex,
        selectedColumnIndex
      }, () => this.multiGridRef.forceUpdateGrids());
      this.hideContextMenu();
    }

    hideContextMenu() {
      if (this.state.contextMenuState !== initialContextMenuState) {
        this.setState({
          contextMenuState: initialContextMenuState
        }, () => {
          this.multiGridRef.forceUpdateGrids();
          document.removeEventListener('click', this.documentClickHandler, false);
          document.removeEventListener('contextmenu', this.documentRightClickHandler, false);
        });
      }
    }
    /**
     * This is the method that will be called by components using
     * this Widget (Since the external system is non-react we cant rerender by passing the data as props
     * @param data
     */


    updateWidgetData(widgetData) {
      const {
        hiddenRowIds
      } = this.state;
      const data = widgetData.filter(({
        id
      }) => !hiddenRowIds.includes(id));
      this.setState({
        data
      }, () => {
        this.multiGridRef && this.multiGridRef.forceUpdateGrids();
      });
    }

    updateVisibleColumns(visibleColumns) {
      const columnwidths = this.calculateColumnWidth(visibleColumns);
      this.setState({
        columns: visibleColumns,
        columnwidths
      }, () => {
        this.multiGridRef && this.multiGridRef.forceUpdateGrids();
      });
    }

    calculateColumnWidth(visibleColumns) {
      let columnIncrementerValue = 1;
      let {
        columnwidths,
        showGearIcon,
        useTabletWidth
      } = this.state;
      const {
        widgetState
      } = this.props;
      const calculatedColumnWidthsCount = Object.keys(columnwidths).length;
      const visibleColumnWidthCount = visibleColumns.length + (showGearIcon ? 2 : 1);

      if (calculatedColumnWidthsCount !== visibleColumnWidthCount) {
        columnwidths = {};
      } // update columns width


      columnwidths[0] = CHECKBOX_COLUMN_WIDTH;

      if (showGearIcon) {
        columnwidths[1] = GEARCOG_COLUMN_WIDTH;
        columnIncrementerValue = 2;
      }

      visibleColumns.forEach((column, index) => {
        const savedWidth = widgetState.columns && widgetState.columns[column.id] ? widgetState.columns[column.id].width : null;
        const configWidth = useTabletWidth && column.defaultTabletWidth ? column.defaultTabletWidth : column.defaultWidth;
        const defaultWidth = savedWidth || configWidth || DEFAULT_COLUMN_WIDTH;
        columnwidths[index + columnIncrementerValue] = columnwidths[index + columnIncrementerValue] || defaultWidth;
      });
      return columnwidths;
    }

    updateSelectionDataAndVisibility(widgetData, checkedRowIds, disabledRowIds, isFurtherSelectionAllowed, hiddenRowIds, visibleColumns) {
      const data = widgetData.filter(({
        id
      }) => !hiddenRowIds.includes(id));
      const columnwidths = this.calculateColumnWidth(visibleColumns);
      const updatedState = {
        data,
        columns: visibleColumns,
        columnwidths,
        checkedRowIds: checkedRowIds,
        disabledRowIds: disabledRowIds,
        isFurtherSelectionAllowed: isFurtherSelectionAllowed,
        hiddenRowIds
      };
      this.setState(updatedState, () => {
        this.multiGridRef && this.multiGridRef.recomputeGridSize() && this.multiGridRef.forceUpdateGrids();
      });
    }

    resizeRow({
      columnIndex,
      deltaX
    }) {
      this.setState(prevState => {
        const {
          columnwidths,
          columns,
          showGearIcon
        } = prevState;
        let columnWidth = columnwidths[columnIndex];
        columnWidth = Math.max(MIN_COLUMN_WIDTH, columnWidth + deltaX);
        columnwidths[columnIndex] = columnWidth; // update dashboard widget state

        const index = showGearIcon ? columnIndex - 2 : columnIndex - 1;
        const columnId = columns[index].id;
        const {
          onWidgetStateUpdateHandler
        } = this.props;
        const _columns = {};
        _columns[columnId] = {
          width: columnWidth
        };
        onWidgetStateUpdateHandler({
          columns: _columns
        });
        return {
          columnwidths,
          resizing: true
        };
      }, () => {
        this.multiGridRef.recomputeGridSize({
          columnIndex
        });
      });
    }

    resizeStop() {
      this.setState({
        resizing: false
      }, () => {
        this.multiGridRef.forceUpdateGrids();
      });
    }

    reorderRows(oldIndex, newIndex) {
      const {
        rowReorderHandler
      } = this.props;
      const cache = [...this.state.data];
      this.setState({
        data: arrayMove(this.state.data, oldIndex, newIndex),
        cache
      }, () => {
        this.multiGridRef.forceUpdateGrids();
        rowReorderHandler(oldIndex, newIndex);
      });
    }

    rollbackCache() {
      const {
        cache
      } = this.state;

      if (cache) {
        // eslint-disable-next-line no-undefined
        this.setState({
          data: cache,
          cache: undefined
        }, () => {
          this.multiGridRef.forceUpdateGrids();
        });
      }
    }

    headerCellClickHandler(columnInfo) {
      let {
        sortId: prevSortId,
        sortAsc
      } = this.state;
      let sortId = columnInfo.id; //set ascending or decending flag

      if (sortId === prevSortId) {
        sortAsc = !sortAsc;
      } else {
        sortAsc = true;
      }

      this.setState({
        sortAsc,
        sortId
      }, () => {
        this.multiGridRef.forceUpdateGrids();
      });
      const {
        onSortClickHandler
      } = this.props;
      onSortClickHandler && onSortClickHandler(sortId, sortAsc, this.state.data.length);
    }

    checkBtnClickHandler(rowIndex, selected) {
      let {
        data
      } = this.state;
      let selectedRowData = this.getRowData(data, rowIndex);
      const {
        rowCheckBtnSelectHandler,
        rowCheckBtnUnSelectHandler
      } = this.props;

      if (selected) {
        rowCheckBtnSelectHandler(selectedRowData);
      } else {
        rowCheckBtnUnSelectHandler(selectedRowData);
      }
    }

    checkBtnAllClickHandler(value) {
      const {
        data
      } = this.state;
      const {
        onUnCheckAllHandler
      } = this.props; //call ember component's callback

      onUnCheckAllHandler();
    }

    isRowLoaded({
      index
    }) {
      const {
        data
      } = this.state;
      const isloaded = !!data[index];
      return isloaded;
    }

    _selectCell({
      scrollToColumn,
      scrollToRow
    }) {
      this.setState({
        selectedRowIndex: scrollToRow,
        selectedColumnIndex: scrollToColumn
      }, () => {
        this.multiGridRef.forceUpdateGrids();
      });
    } //give some space for sorting indicator


    getColumnWidth({
      index
    }) {
      const {
        columnwidths,
        width
      } = this.state;
      const sum = Object.keys(columnwidths).reduce((_sum, i) => _sum + columnwidths[i], 0);

      if (index === Object.keys(columnwidths).length - 1 && sum < width) {
        return columnwidths[index] + width - sum; //modify right-most cell width
      }

      return columnwidths[index];
    }

    updateDimension(width, height) {
      this.setState({
        width,
        height
      });
    }

    updateWidgetState(widgetState) {
      const {
        config
      } = this.props;
      const widgetStateSortedColumn = config.columns.filter(cfg => cfg.id === widgetState.sortId);

      if (!Ember.isEmpty(widgetStateSortedColumn)) {
        const sortId = widgetStateSortedColumn[0].id;
        const sortAsc = widgetState.sortAsc;
        const reordering = widgetState.reordering || false;
        this.setState({
          sortId,
          sortAsc,
          reordering
        });
      }
    }

    updateRowItemSelection(checkedRowIds, disabledRowIds, isFurtherSelectionAllowed, hiddenRowIds) {
      this.setState({
        checkedRowIds: checkedRowIds,
        disabledRowIds: disabledRowIds,
        isFurtherSelectionAllowed: isFurtherSelectionAllowed,
        hiddenRowIds
      });
    }

    cellRightClickForTablet(event, rowIndex, columnIndex) {
      event.stopPropagation();
      this.setState({
        selectedRowIndex: rowIndex,
        selectedColumnIndex: columnIndex
      }, () => {
        this.multiGridRef.forceUpdateGrids();
      });
      const {
        onRowRightClick
      } = this.props;
      if (!onRowRightClick) return;
      const parentContainerCssClass = `.${GridWidget.containerCssClass}`;
      const widgetContainerOffset = Ember.$(event.target).closest(parentContainerCssClass).offset(); // const positionX = window.pageXOffset + event.clientX - widgetContainerOffset.left;
      // const positionY = window.pageYOffset + event.clientY - widgetContainerOffset.top + 50;

      const positionX = window.pageXOffset + event.clientX;
      const positionY = window.pageYOffset + event.clientY;
      const {
        data
      } = this.state;
      const selectedRowData = data.length ? this.getRowData(data, rowIndex) : null;
      onRowRightClick(selectedRowData).then(menusItems => {
        this.updateContextMenuState({
          rowIndex,
          columnIndex,
          positionX,
          positionY,
          menusItems,
          visible: true
        }); // Avoid duplicate event lister, so remove it before add a new one.

        document.removeEventListener('click', this.documentClickHandler, false);
        document.addEventListener('click', this.documentClickHandler);
        document.removeEventListener('contextmenu', this.documentRightClickHandler, false);
        document.addEventListener('contextmenu', this.documentRightClickHandler);
      });
    }

    getRowData(data, rowIndex) {
      return data[rowIndex - 1];
    }

    _onSectionRendered({
      rowStartIndex,
      rowStopIndex
    }) {
      const startIndex = rowStartIndex;
      const stopIndex = rowStopIndex;

      this._onRowsRendered({
        startIndex,
        stopIndex
      });
    }

    _isRowSortable(index) {
      return index >= 0;
    }

    _getSortableContainer() {
      this.domNode = _reactDom.default.findDOMNode(this);
      return this.domNode;
    }

    _rowRenderer(props) {
      if (props.parent === this.multiGridRef._bottomRightGrid && this.state.reordering) {
        return sortableRowRenderer(props);
      }

      const children = fixdRowRenderer(props);
      return children;
    }

    _cellRenderer(props) {
      const {
        key,
        isScrolling
      } = props; // Added rowStyleHandler which gets passed through {...props}

      if (isScrolling) {
        this.hideContextMenu();
      }

      return _react.default.createElement(_cellrenderer.default, (0, _extends2.default)({
        cellKey: key
      }, props, this.state, {
        config: this.config,
        resizeStop: this.resizeStop,
        onContextMenuClick: this.onContextMenuClick,
        resizeRow: this.resizeRow,
        onTableCellClick: this.onTableCellClick,
        headerCellClickHandler: this.headerCellClickHandler,
        checkBtnClickHandler: this.checkBtnClickHandler,
        checkBtnAllClickHandler: this.checkBtnAllClickHandler,
        cellRightClickForTablet: this.cellRightClickForTablet
      }));
    }

    renderMultiGridWithNoData() {
      const {
        columns,
        showGearIcon
      } = this.state;
      const rowCount = 1;
      const columnIncrementerValue = showGearIcon ? 2 : 1;
      return _react.default.createElement(AutoSizer, null, ({
        width,
        height
      }) => _react.default.createElement(MultiGrid, {
        ref: ref => {
          this.multiGridRef = ref;
        },
        cellRenderer: this._cellRenderer,
        columnWidth: this.getColumnWidth,
        columnCount: columns.length + columnIncrementerValue,
        rowHeight: 23,
        rowCount: rowCount,
        height: height,
        width: width
      }));
    }

    renderMultiGrid(registerChild, scrollToColumn, scrollToRow, onSectionRendered) {
      const {
        data,
        columns,
        showGearIcon
      } = this.state;
      const columnIncrementerValue = showGearIcon ? 2 : 1;
      const rowCount = data.length + 1;
      const fixedRowCount = 1;
      let {
        rowStyleHandler
      } = this.props;
      return _react.default.createElement(AutoSizer, null, ({
        width,
        height
      }) => _react.default.createElement(SortableMultiGrid, {
        ref: sortableGrid => {
          if (sortableGrid) {
            const multiGrid = sortableGrid.getWrappedInstance();
            this.multiGridRef = multiGrid;
            registerChild(multiGrid);
          }
        },
        onSortEnd: ({
          oldIndex,
          newIndex
        }) => {
          this.reorderRows(oldIndex, newIndex);
        },
        getContainer: this._getSortableContainer,
        onSectionRendered: onSectionRendered,
        fixedRowCount: fixedRowCount,
        fixedColumnCount: 0,
        cellRangeRenderer: this._rowRenderer,
        cellRenderer: ({
          columnIndex,
          key,
          rowIndex,
          style,
          isScrolling
        }) => this._cellRenderer({
          columnIndex,
          key,
          rowIndex,
          scrollToColumn,
          scrollToRow,
          style,
          rowStyleHandler,
          isScrolling
        }),
        columnWidth: this.getColumnWidth,
        columnCount: columns.length + columnIncrementerValue,
        rowHeight: 23,
        rowCount: rowCount,
        height: height,
        width: width
      }));
    }

    _infiniteLoaderChildFunction({
      onRowsRendered,
      registerChild,
      scrollToRow,
      scrollToColumn,
      onSectionRendered
    }) {
      this._onRowsRendered = onRowsRendered;
      return this.renderMultiGrid(registerChild, scrollToColumn, scrollToRow, onSectionRendered);
    }

    renderMultiGridWithArrowStepper() {
      const {
        selectedColumnIndex: scrollToColumn,
        selectedRowIndex: scrollToRow,
        columns,
        data,
        showGearIcon
      } = this.state;

      if (!data.length) {
        return this.renderMultiGridWithNoData();
      }

      const {
        config
      } = this.props;
      const batchSize = config.paginationLimit || BATCH_SIZE;
      const rowCount = data.length + batchSize;
      const columnIncrementerValue = showGearIcon ? 2 : 1;
      return _react.default.createElement(ArrowKeyStepper, {
        className: 'arrow-key-stepper',
        columnCount: columns.length + columnIncrementerValue,
        isControlled: false,
        onScrollToChange: this._selectCell,
        mode: 'cells',
        rowCount: rowCount,
        scrollToColumn: scrollToColumn,
        scrollToRow: scrollToRow
      }, () => _react.default.createElement(InfiniteLoader, {
        isRowLoaded: this.isRowLoaded,
        loadMoreRows: this.loadMoreRowsHandler,
        rowCount: rowCount,
        minimumBatchSize: batchSize,
        threshold: batchSize,
        ref: ref => {
          this.infiniteLoaderRef = ref;
        }
      }, ({
        onRowsRendered,
        registerChild
      }) => this._infiniteLoaderChildFunction({
        onRowsRendered,
        registerChild,
        scrollToColumn,
        scrollToRow,
        onSectionRendered: this._onSectionRendered
      })));
    }

    render() {
      const {
        contextMenuState,
        data,
        columns,
        columnwidths,
        useTabletWidth
      } = this.state;
      const {
        menusItems
      } = contextMenuState;
      const containerStyle = {
        width: `${this.state.width}px`,
        height: `${this.state.height}px`
      };
      let widgetInstanceId = this.state.widgetInstanceId;
      let headerColumnTotalWidth = 0;
      columns.forEach((column, index) => {
        const defaultWidth = useTabletWidth && column.defaultTabletWidth ? column.defaultTabletWidth : column.defaultWidth;
        const columnWidth = defaultWidth || columnwidths[index];
        headerColumnTotalWidth += columnWidth;
      });
      const widgetClassName = !data.length ? `${GridWidget.containerCssClass} empty-widget` : GridWidget.containerCssClass;
      const containerClassNames = headerColumnTotalWidth < this.state.width ? `${widgetClassName} small-widget` : widgetClassName;
      return _react.default.createElement("div", null, _react.default.createElement("div", {
        id: `${widgetInstanceId}-container`,
        ref: containerRef => {
          this.containerRef = containerRef;
        },
        className: containerClassNames,
        onKeyDown: this.onKeyPressHandler,
        style: containerStyle
      }, this.renderMultiGridWithArrowStepper()), menusItems && menusItems.length && _react.default.createElement(RenderInBody, null, _react.default.createElement(_gridContextMenu.default, (0, _extends2.default)({
        onMenuItemClick: this.menuItemCallback
      }, contextMenuState))));
    }

  }

  GridWidget.containerCssClass = 'widget-container';
  var _default = GridWidget;
  _exports.default = _default;
});