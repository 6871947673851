define("adept-iq/pods/components/side-drawer/reconcile-add-new-route/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Add Route',
      fields: [{
        id: 'routeId',
        type: 'text',
        label: 'Route ID',
        // valuePath: 'cancelType',
        // cellDesc: 'cancelType',
        editable: true
      }, {
        id: 'date',
        type: 'text',
        editable: true,
        label: 'Date' // valuePath: 'cancelNotes'

      }, {
        id: 'startTime',
        type: 'text',
        editable: true,
        label: 'Start Time' // valuePath: 'cancelNotes'

      }, {
        id: 'endTime',
        type: 'text',
        editable: true,
        label: 'End Time' // valuePath: 'cancelNotes'

      }, {
        id: 'provider',
        type: 'enum',
        editable: true,
        label: 'Provider',
        // valuePath: 'cancelNotes',
        hidden: false
      }]
    }, {
      title: 'Vehicle Information',
      fields: [{
        id: 'vehicleId',
        label: 'Vehicle ID',
        // valuePath: 'leg.rider.firstName',
        editable: true,
        type: 'enum'
      }, {
        id: 'vehicleType',
        label: 'Vehicle Type',
        // valuePath: 'leg.rider.lastName',
        editable: true,
        type: 'text'
      }, {
        id: 'wideWheelchair',
        label: 'Wide Wheelchair',
        // valuePath: 'id',
        editable: true,
        type: 'text'
      }, {
        id: 'wheelchairSeats',
        label: 'Wheelchair seats',
        // valuePath: 'formattedPromiseTime',
        editable: true,
        type: 'text'
      }, {
        id: 'ambulatorySeats',
        label: 'Ambulatory seats',
        // valuePath: 'pick.place.address.simpleAddress',
        editable: true,
        type: 'text'
      }, {
        id: 'flexSeats',
        label: 'Flex seats',
        // valuePath: 'drop.place.address.simpleAddress',
        editable: true,
        type: 'text'
      }]
    }, {
      title: 'Driver Information',
      fields: [{
        id: 'driverId',
        label: 'Driver ID',
        type: 'enum',
        // valuePath: 'leg.rider.firstName',
        editable: true
      }, {
        id: 'driverName',
        label: 'Driver Name',
        type: 'text',
        // valuePath: 'leg.rider.lastName',
        editable: true
      }, {
        id: 'driverPhote',
        label: 'Driver Photo',
        type: 'text',
        // valuePath: 'id',
        editable: true
      }, {
        id: 'driverStartTime',
        label: 'Driver Start Time',
        type: 'text',
        // valuePath: 'formattedPromiseTime',
        editable: true
      }, {
        id: 'driverEndTime',
        label: 'Driver End Time',
        type: 'text',
        // valuePath: 'pick.place.address.simpleAddress',
        editable: true
      }]
    }, {
      title: 'Location',
      fields: [{
        id: 'identifier',
        label: 'Identifier',
        type: 'text',
        // valuePath: 'leg.rider.firstName',
        editable: true
      }, {
        id: 'address',
        label: 'Address',
        type: 'enum',
        // valuePath: 'leg.rider.lastName',
        editable: true
      }, {
        id: 'zone',
        label: 'Zone',
        type: 'enum',
        // valuePath: 'id',
        editable: true
      }, {
        id: 'notes',
        label: 'Notes',
        type: 'text',
        // valuePath: 'formattedPromiseTime',
        editable: true
      }]
    }]
  };
  _exports.default = _default;
});