define("adept-iq/pods/components/side-drawer/permissions-edit-user/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    errorMessages: null,
    userDetails: null,
    selectedRoles: null,
    title: '',
    callBack: Ember.computed.readOnly('workspace.topOptions.action'),
    currentUser: Ember.computed.readOnly('workspace.topOptions.user'),
    hasOneProvider: Ember.computed.lte('providersList.length', 1),
    canUndo: Ember.computed.alias('isEditingStart'),
    userRecords: Ember.computed.readOnly('workspace.topOptions.userRecords'),
    // isSaveEnabled: computed.and('userDetails.{firstName,lastName,username,password}', 'selectedStatus', 'selectedRoles'),
    // canSave: computed('isSaveEnabled', 'title', 'isEditingStart', function() {
    //   return get(this, 'isSaveEnabled') && get(this, 'title') !== 'Select Providers' && get(this, 'isEditingStart');
    // }),
    canSave: Ember.computed.alias('isEditingStart'),
    statusOptions: Ember.computed('selectedStatus', function () {
      return ['Active', 'Inactive'];
    }),
    roleOptions: Ember.computed('selectedRoles', function () {
      const roles = [];
      const rolesList = Ember.get(this, 'store').peekAll('perm-sso-role');
      rolesList.map(role => {
        let disabled = false;

        if (this.get('selectedRoles')) {
          this.get('selectedRoles').map(data => {
            if (data && data.displayName === role.displayName) {
              disabled = true;
            }
          });
        }

        if (role.id) {
          roles.push({
            id: role.id,
            roleName: role.roleName,
            displayName: role.displayName,
            disabled: disabled
          });
        }
      });
      return roles;
    }),

    init() {
      this._super(...arguments);

      this.resetUserProperties();
    },

    didInsertElement() {
      this._super(...arguments);
    },

    resetUserProperties() {
      this.setProperties({
        userDetails: {
          firstName: this.currentUser.get('firstName'),
          lastName: this.currentUser.get('lastName'),
          emailId: this.currentUser.get('emailId'),
          userName: this.currentUser.get('userName'),
          password: this.currentUser.get('password'),
          providerName: this.currentUser.get('providers')[0].providerName
        },
        errorMessages: [],
        isEditingStart: null,
        selectedRoles: this.getDefaultRoles(),
        selectedStatus: Ember.String.capitalize(this.currentUser.calculatedStatus)
      });

      if (this.userDetails.providerName === 'ALL') {
        this.userDetails.providerName = 'All Providers';
      }

      this.fillProviders();
    },

    getDefaultRoles() {
      const roles = [];
      const rolesList = this.currentUser.roles;
      if (Ember.isEmpty(rolesList)) return null;
      rolesList.map(role => {
        roles.push({
          id: role.id,
          roleName: role.roleName,
          displayName: role.displayName
        });
      });
      return roles;
    },

    setDefaultProviders() {
      const defaultProviders = this.currentUser.providers;
      if (Ember.isEmpty(defaultProviders)) return null;
      const providerList = Ember.get(this, 'providersList');
      if (Ember.isEmpty(providerList)) return;
      defaultProviders.map(provider => {
        const pList = providerList.findBy('id', provider.id);
        if (Ember.isEmpty(pList)) return;
        pList[0].set('isChecked', true);
      });
      const results = providerList.filterBy('isChecked', false);

      if (results.length === 1 && results[0].id === 'selectAll') {
        results[0].set('isChecked', true);
      }
    },

    async fillProviders() {
      const providers = [];
      const adaptor = 'perm-sso-user';
      const permUserAdapter = this.get('store').adapterFor(adaptor);
      const providersList = await permUserAdapter.getSSOProvider();
      if (Ember.isEmpty(providersList)) return;
      providersList.map(provider => {
        providers.push(Ember.Object.create({
          id: provider.id,
          providerName: provider.provider === 'ALL' ? 'Select All' : provider.provider,
          isChecked: true
        }));
      });
      this.set('providersList', providers);
      this.setDefaultProviders();
      this.updateTitle();
    },

    compareArray(_arr1, _arr2) {
      if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
        return false;
      } // .concat() to not mutate arguments


      const arr1 = _arr1.concat().sort();

      const arr2 = _arr2.concat().sort();

      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }

      return true;
    },

    isValid() {
      let isValid = true;
      const matchSimplePassword = /password|abc|123/i;
      Ember.set(this, 'errorMessages', []);

      if (Ember.isEmpty(this.userDetails.firstName)) {
        this.errorMessages.push('Please enter first name');
        isValid = false;
      }

      if (Ember.isEmpty(this.userDetails.lastName)) {
        this.errorMessages.push('Please enter last name');
        isValid = false;
      } // Open once allowed to edit all this
      // if (isEmpty(this.userDetails.userName)) {
      //   this.errorMessages.push('Please enter user name');
      //   isValid = false;
      // }
      // if (isEmpty(this.userDetails.password)) {
      //   this.errorMessages.push('Please enter password');
      //   isValid = false;
      // }
      // if (isEmpty(this.title) || this.title === 'Select Providers') {
      //   this.errorMessages.push('Please enter providers');
      //   isValid = false;
      // }
      // if (isEmpty(this.selectedRoles)) {
      //   this.errorMessages.push('Please enter roles');
      //   isValid = false;
      // }


      if (Ember.isEmpty(this.selectedStatus)) {
        this.errorMessages.push('Please enter status');
        isValid = false;
      }

      if (!Ember.isEmpty(this.userDetails.emailId)) {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (!emailRegex.test(this.userDetails.emailId)) {
          this.errorMessages.push('Please enter valid email id');
          isValid = false;
        }
      } else if (this.userDetails && this.get('userRecords')) {
        this.get('userRecords').map(data => {
          if (data && data.userName === this.userDetails.userName && this.currentUser.userId !== data.userId) {
            this.errorMessages.push('* Duplicated username');
            isValid = false;
            return;
          }

          return;
        });
      }

      if (matchSimplePassword.exec(this.userDetails.password)) {
        this.errorMessages.push('Passwords must not include common, easy to guess values');
        isValid = false;
        return;
      }

      return isValid;
    },

    saveUser() {
      return Ember.get(this, 'editUserTask').perform().then(() => {
        this.send('cancel');
        this.get('notifications').success('Edited user Successfully');
        this.get('workspace').popState('permissionsEditUser');
      }).catch(error => {
        let message;

        switch (error.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem in edit user, please try again.';
            break;
        }

        this.errorMessages.push(message);
        this.get('notifications').warning(message);
      });
    },

    getSelectedProviders() {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return [];
      const results = this.get('providersList').filterBy('isChecked', true);
      return results.map(p => {
        if (p.id !== 'selectAll') {
          return p.id;
        }
      });
    },

    updateTitle() {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return;
      const results = this.get('providersList').filterBy('isChecked', true);

      if (results.length === 0) {
        this.set('title', 'Select Providers');
      } else if (results.length === 1) {
        if (results[0].providerName === 'Select All') {
          this.set('title', 'All Providers');
        } else {
          this.set('title', results[0].providerName);
        }
      } else if (results.length > 1 && results.length < this.providersList.length) {
        this.set('title', 'Multiple');
      } else if (results.length === this.providersList.length) {
        this.set('title', 'All Providers');
      }
    },

    handleCheckUncheckSelectAll() {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return;
      let results = providersList.filterBy('isChecked', false);

      if (results.length === 1 && results[0].providerName === 'Select All') {
        results[0].set('isChecked', true);
      } else {
        results = providersList.filterBy('providerName', 'Select All');
        results[0].set('isChecked', false);
      }
    },

    handleCheckUncheckProviders(provider, isChecked) {
      const providersList = this.get('providersList');
      if (Ember.isEmpty(providersList)) return;

      if (provider.providerName === 'Select All') {
        providersList.map(item => {
          item.set('isChecked', isChecked);
        });
      } else {
        providersList.map(item => {
          if (provider.id === item.id) {
            item.set('isChecked', isChecked);
          }
        });
        this.handleCheckUncheckSelectAll();
      }

      this.updateTitle();
    },

    toggleSelect() {
      this.toggleProperty('isOpen');
    },

    getSelectedRoleIds() {
      if (Ember.isEmpty(this.selectedRoles)) return;
      return this.selectedRoles.map(role => role.roleName);
    },

    getRoleIdsByRoleList(roleList) {
      return roleList.map(role => role.roleName);
    },

    getSelectedStatus() {
      return this.selectedStatus ? this.selectedStatus.toLowerCase() : this.currentUser.calculatedStatus.toLowerCase();
    },

    editUserTask: (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const userAdapter = store.adapterFor('perm-sso-user');

        if (this.userDetails.firstName !== this.currentUser.firstName || this.userDetails.lastName !== this.currentUser.lastName) {
          const payload = {
            firstName: this.userDetails.firstName,
            lastName: this.userDetails.lastName // username: this.userDetails.userName,
            // password: this.userDetails.password,
            // providers: [0]

          };
          yield userAdapter.patchUser(payload, this.currentUser.userId);
        } // edit user username and password


        if (this.userDetails.userName !== this.currentUser.userName || this.userDetails.password !== this.currentUser.password) {
          const payload = {
            username: this.userDetails.userName,
            password: this.userDetails.password
          };
          yield userAdapter.updateUsernameAndPassword(payload, this.currentUser.userId);
        } // edit user providers


        const providersList = this.get('providersList');

        if (!Ember.isEmpty(providersList)) {
          for (let i = 0; i < providersList.length; i++) {
            if (providersList[i].isChecked === true) {
              yield userAdapter.updateProviders(this.currentUser.userId, providersList[i].id);
            }
          }
        }

        if (this.selectedStatus && this.selectedStatus.toLowerCase() !== this.currentUser.calculatedStatus) {
          //edit user status
          yield userAdapter.updateStatusByUser(this.currentUser.userId, this.getSelectedStatus()); //end
        } // edit user roles


        const currentUserRoles = yield userAdapter.getRolesByUser(this.currentUser.userId);
        if (Ember.isEmpty(currentUserRoles)) return;
        const roleIds = this.getSelectedRoleIds();
        const differenceInRoles = this.compareArray(this.getRoleIdsByRoleList(currentUserRoles.UserRoleRoles), roleIds);

        if (!differenceInRoles) {
          const removeRoles = _lodash.default.difference(currentUserRoles.UserRoleRoles, roleIds) || [];
          const addRoles = _lodash.default.difference(roleIds, currentUserRoles.UserRoleRoles) || [];

          if (!Ember.isEmpty(removeRoles)) {
            for (let i = 0; i < removeRoles.length; i++) {
              yield userAdapter.deleteRolesByUser(this.currentUser.userId, removeRoles[i].id);
            }
          }

          if (!Ember.isEmpty(addRoles)) {
            for (let i = 0; i < addRoles.length; i++) {
              yield userAdapter.updateRolesByUser(this.currentUser.userId, addRoles[i]);
            }
          } //end

        }
      } catch (error) {
        throw error;
      }
    }),
    actions: {
      onChangeStatus(option) {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'selectedStatus', option);
      },

      onChangeRole(option) {
        Ember.set(this, 'isEditingStart', true);
        Ember.set(this, 'selectedRoles', option);
      },

      onUndoClick() {
        this.resetUserProperties();
      },

      onInputChange(option, value) {
        Ember.set(this, 'isEditingStart', true);

        switch (option) {
          case 'firstName':
            Ember.set(this, 'userDetails.firstName', value);
            break;

          case 'lastName':
            Ember.set(this, 'userDetails.lastName', value);
            break;

          case 'emailId':
            Ember.set(this, 'userDetails.emailId', value);
            break;

          case 'userName':
            Ember.set(this, 'userDetails.userName', value);
            break;

          case 'password':
            Ember.set(this, 'userDetails.password', value);
            break;

          default:
            break;
        }
      },

      onSaveClick() {
        if (this.isValid()) {
          Ember.set(this, 'errorMessages', []);
          this.saveUser();
        }
      },

      onCloseClick() {},

      onToggleSelect() {
        this.toggleSelect();
      },

      onOpenProvider() {
        this.toggleSelect();
      },

      onCloseProvider() {
        this.toggleSelect();
      },

      onProviderCheckboxClick(provider, event) {
        Ember.set(this, 'isEditingStart', true);
        this.handleCheckUncheckProviders(provider, event.target.checked);
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      }

    }
  });

  _exports.default = _default;
});