define("adept-iq/classes/rules/eligibility-rules/all-conditions-pass-rule", ["exports", "adept-iq/classes/rules/base-rule", "adept-iq/classes/rules/rule-result-type", "adept-iq/models/cs-config-category", "lodash", "moment"], function (_exports, _baseRule, _ruleResultType, _csConfigCategory, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AllConditionsPassRule = void 0;
  /// EXTREME HEAT, EXTREME COLD, and INTER-BOROUGH eligibility conditions are evaluated at this step
  const LIST_CONDITIONS_TO_CHECK = [_csConfigCategory.ELIGIBILITY_CONDITIONS.EXTREME_COLD, _csConfigCategory.ELIGIBILITY_CONDITIONS.EXTREME_HEAT];

  class AllConditionsPassRule extends _baseRule.default {
    constructor() {
      super();
      this.conditionPredicateMap = {
        [_csConfigCategory.ELIGIBILITY_CONDITIONS.EXTREME_COLD]: this.checkExtremeColdCondition.bind(this),
        [_csConfigCategory.ELIGIBILITY_CONDITIONS.EXTREME_HEAT]: this.checkExtremeHotCondition.bind(this)
      };
    }

    async doEvaluate(ruleContext) {
      const {
        bookingService,
        store,
        systemConfigService
      } = ruleContext;
      let weatherData;

      try {
        weatherData = await bookingService.fetchWeatherForecastData();
      } catch (error) {
        ruleContext.errorInfo = {
          message: 'Failed to fetch weather api',
          error
        };
        return _ruleResultType.RESULT_TYPE_ERROR;
      }

      const {
        value: itpMinWalkDistConfig
      } = store.peekRecord('cs-config-item', 'config-System_Configuration-ITP_Configurations/ITP_MinimumWalkingDistance');
      const conditionConfigList = systemConfigService.findConfigByCategory(_csConfigCategory.CONFIG_ELIGIBILITY_CONDITIONS);
      const selectedRiders = bookingService.get('selectedRiders');
      const options = {
        store,
        bookingService,
        weatherData,
        itpMinWalkDistConfig,
        conditionConfigList
      }; // it stores the each rider's condition result

      ruleContext.riderConditionsResultMap = this.evaluateConditionsForRiders(selectedRiders, options); // check all rider pass the all conditions

      const allConditionsPass = selectedRiders.every(rider => {
        const riderConditionMap = ruleContext.riderConditionsResultMap[rider.get('id')];
        const distanceConditions = this.getRiderDistanceConditions(rider);
        const hasDistanceCondition = distanceConditions.length > 0; // rider's doesnt have conditional eligibility , ignore and  pass the result

        if (!riderConditionMap && !hasDistanceCondition) return true; // rider's doesnt have conditional eligibility , but has the distance condition,    fail the result

        if (!riderConditionMap && hasDistanceCondition) return false;
        return Object.values(riderConditionMap).every(value => value);
      });
      if (allConditionsPass) return _ruleResultType.RESULT_TYPE_YES;
      return _ruleResultType.RESULT_TYPE_NO;
    }
    /**
     *
     * @param selectedRiders
     * @param options
     */


    evaluateConditionsForRiders(selectedRiders, options) {
      const riderConditionsMap = {};
      selectedRiders.forEach(rider => {
        const allConditions = this.getRiderConditions(rider); //rider doesnt have conditions, we dont need to evaluate conditions

        if (allConditions.length === 0) {
          return;
        }

        const conditionsToValidate = allConditions.filter(condition => {
          return LIST_CONDITIONS_TO_CHECK.includes(condition);
        });
        const conditionResultMap = this.evaluateAllConditions(rider, conditionsToValidate, options);
        riderConditionsMap[rider.get('id')] = conditionResultMap;
      });
      return riderConditionsMap;
    }

    evaluateAllConditions(rider, allConditions, options) {
      const conditionResultMap = {};
      allConditions.forEach(condition => {
        const result = true;
        const configItem = this.getConditionConfig(condition, options.conditionConfigList);

        if (!configItem) {
          conditionResultMap[condition] = result;
          return;
        }

        const conditionPredicate = this.conditionPredicateMap[condition];
        conditionResultMap[condition] = conditionPredicate(rider, configItem, options);
      });
      return conditionResultMap;
    }

    getConditionConfig(condition, conditionConfigList) {
      const configItem = conditionConfigList.find(config => {
        const value = config.value;
        const conditionName = value.name || value.Name;
        return conditionName.trim().toLowerCase() === condition.toLowerCase();
      });
      return configItem;
    }

    checkExtremeColdCondition(rider, configItem, {
      bookingService,
      weatherData
    }) {
      const configuredValue = this.getConfiguredValue(configItem);
      const activeBooking = bookingService.get('activeBooking');
      const {
        periods
      } = weatherData.properties;
      const legs = activeBooking.get('legs');
      const result = legs.every(leg => {
        const travelTime = (0, _moment.default)(leg.get('requestTime'));
        const matchedTemp = periods.find(period => {
          return travelTime.isBetween((0, _moment.default)(period.startTime), (0, _moment.default)(period.endTime));
        });
        const temperature = matchedTemp && matchedTemp.temperature; // in farenheit

        const tempInCelsius = this.convertToCelsius(temperature); // if current weather is less than configured temperature, cold condition  is passed

        if (tempInCelsius < parseInt(configuredValue, 10)) {
          return true;
        }

        return false;
      });
      return result;
    }

    checkExtremeHotCondition(rider, configItem, {
      bookingService,
      weatherData
    }) {
      const configuredValue = this.getConfiguredValue(configItem);
      const activeBooking = bookingService.get('activeBooking');
      const {
        periods
      } = weatherData.properties;
      const legs = activeBooking.get('legs');
      const result = legs.every(leg => {
        const travelTime = (0, _moment.default)(leg.get('requestTime'));
        const matchedTemp = periods.find(period => {
          return travelTime.isBetween((0, _moment.default)(period.startTime), (0, _moment.default)(period.endTime));
        });
        const temperature = matchedTemp && matchedTemp.temperature;
        const tempInCelsius = this.convertToCelsius(temperature);

        if (tempInCelsius > parseInt(configuredValue, 10)) {
          return true;
        }

        return false;
      });
      return result;
    }

    convertToCelsius(temperature) {
      return (temperature - 32) * 5 / 9;
    }

    getConfiguredValue(configItem) {
      const configuredValueObj = configItem.value;
      const configuredValue = configuredValueObj['Configured value'] || configuredValueObj.configuredValue;
      return parseInt(configuredValue, 10);
    }

    getRiderConditions(rider) {
      const allConditions = (0, _lodash.flatten)(rider.get('eligibility.categories').map(category => category.conditions || []));
      return allConditions;
    }

    getRiderDistanceConditions(rider) {
      const allConditions = this.getRiderConditions(rider);
      const distanceConditions = allConditions.filter(condition => condition.toUpperCase().includes('BLOCKS') || condition.toUpperCase().includes('STAIRS'));
      return distanceConditions;
    }

  }

  _exports.AllConditionsPassRule = AllConditionsPassRule;
  AllConditionsPassRule.RULE_NAME = 'AllConditionsPassRule';
});