define("adept-iq/utils/rql-generators", ["exports", "moment", "adept-iq/utils/encoding"], function (_exports, _moment, _encoding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateStrictlyBetweenRQL = _exports.dateBetweenRQL = _exports.timeGteRQL = _exports.timeNeRQL = _exports.timeLtRQL = _exports.timeLteRQL = _exports.timeGtRQL = _exports.timeEqRQL = _exports.dateNeRQL = _exports.dateEqRQL = _exports.dateLteRQL = _exports.dateLtRQL = _exports.dateGteRQL = _exports.dateGtRQL = _exports.stringBetweenRQL = _exports.stringLikeRQL = _exports.stringGtRQL = _exports.stringLtRQL = _exports.stringNeRQL = _exports.stringEqRQL = _exports.inRQL = _exports.strictlyBetweenRQL = _exports.betweenRQL = _exports.gteRQL = _exports.gtRQL = _exports.lteRQL = _exports.ltRQL = _exports.neRQL = _exports.eqRQL = _exports.emptyRQL = _exports.nullRQL = _exports.orRQL = _exports.andRQL = void 0;

  // compound RQL generators
  const andRQL = (...args) => `and(${args.join(',')})`;

  _exports.andRQL = andRQL;

  const orRQL = (...args) => `or(${args.join(',')})`; // elementary RQL generators


  _exports.orRQL = orRQL;

  const nullRQL = () => null;

  _exports.nullRQL = nullRQL;

  const emptyRQL = p => `eq(${p},null)`;

  _exports.emptyRQL = emptyRQL;

  const eqRQL = (p, [v]) => `eq(${p},${v})`;

  _exports.eqRQL = eqRQL;

  const neRQL = (p, [v]) => `ne(${p},${v})`;

  _exports.neRQL = neRQL;

  const ltRQL = (p, [v]) => `lt(${p},${v})`;

  _exports.ltRQL = ltRQL;

  const lteRQL = (p, [v]) => `le(${p},${v})`;

  _exports.lteRQL = lteRQL;

  const gtRQL = (p, [v]) => `gt(${p},${v})`;

  _exports.gtRQL = gtRQL;

  const gteRQL = (p, [v]) => `ge(${p},${v})`;

  _exports.gteRQL = gteRQL;

  const betweenRQL = (p, [v, w]) => andRQL(gteRQL(p, [v]), lteRQL(p, [w]));

  _exports.betweenRQL = betweenRQL;

  const strictlyBetweenRQL = (p, [v, w]) => {
    return andRQL(gtRQL(p, [v]), ltRQL(p, [w]));
  };

  _exports.strictlyBetweenRQL = strictlyBetweenRQL;

  const inRQL = (p, values) => {
    return `in(${p},(${values.join(',')}))`;
  }; // quoted values! API will break if types don't match


  _exports.inRQL = inRQL;

  const stringEqRQL = (p, [v]) => `eq(${p},'${v}')`;

  _exports.stringEqRQL = stringEqRQL;

  const stringNeRQL = (p, [v]) => `ne(${p},'${v}')`;

  _exports.stringNeRQL = stringNeRQL;

  const stringLtRQL = (p, [v]) => `lt(${p},'${v}')`;

  _exports.stringLtRQL = stringLtRQL;

  const stringGtRQL = (p, [v]) => `gt(${p},'${v}')`;

  _exports.stringGtRQL = stringGtRQL;

  const stringLikeRQL = (p, [v]) => `ilike(${p},'%${v}%')`; // need %...%


  _exports.stringLikeRQL = stringLikeRQL;

  const stringBetweenRQL = (p, [v, w]) => {
    return andRQL(stringGtRQL(p, [v]), stringLtRQL(p, [w]));
  };

  _exports.stringBetweenRQL = stringBetweenRQL;

  const dateGtRQL = (p, [v]) => `gt(${p},'${(0, _moment.default)(v).format()}')`;

  _exports.dateGtRQL = dateGtRQL;

  const dateGteRQL = (p, [v]) => `ge(${p},'${(0, _moment.default)(v).format()}')`;

  _exports.dateGteRQL = dateGteRQL;

  const dateLtRQL = (p, [v]) => `lt(${p},'${(0, _moment.default)(v).format()}')`;

  _exports.dateLtRQL = dateLtRQL;

  const dateLteRQL = (p, [v]) => `le(${p},'${(0, _moment.default)(v).format()}')`;

  _exports.dateLteRQL = dateLteRQL;

  const dateEqRQL = (p, [v]) => `eq(${p},'${(0, _moment.default)(v).format()}')`;

  _exports.dateEqRQL = dateEqRQL;

  const dateNeRQL = (p, [v]) => `ne(${p},'${(0, _moment.default)(v).format()}')`;

  _exports.dateNeRQL = dateNeRQL;

  const timeEqRQL = (p, [v]) => `eq(${p},'${(0, _moment.default)(v, _encoding.TIME_PARSING_FORMATS).format()}')`;

  _exports.timeEqRQL = timeEqRQL;

  const timeGtRQL = (p, [v]) => `gt(${p},'${(0, _moment.default)(v, _encoding.TIME_PARSING_FORMATS).format()}')`;

  _exports.timeGtRQL = timeGtRQL;

  const timeLteRQL = (p, [v]) => `le(${p},'${(0, _moment.default)(v, _encoding.TIME_PARSING_FORMATS).format()}')`;

  _exports.timeLteRQL = timeLteRQL;

  const timeLtRQL = (p, [v]) => `lt(${p},'${(0, _moment.default)(v, _encoding.TIME_PARSING_FORMATS).format()}')`;

  _exports.timeLtRQL = timeLtRQL;

  const timeNeRQL = (p, [v]) => `ne(${p},'${(0, _moment.default)(v, _encoding.TIME_PARSING_FORMATS).format()}')`;

  _exports.timeNeRQL = timeNeRQL;

  const timeGteRQL = (p, [v]) => `ge(${p},'${(0, _moment.default)(v, _encoding.TIME_PARSING_FORMATS).format()}')`;

  _exports.timeGteRQL = timeGteRQL;

  const dateBetweenRQL = (p, [v, w]) => {
    return andRQL(dateGteRQL(p, [v]), dateLteRQL(p, [w]));
  };

  _exports.dateBetweenRQL = dateBetweenRQL;

  const dateStrictlyBetweenRQL = (p, [v, w]) => {
    return andRQL(dateGtRQL(p, [v]), dateLtRQL(p, [w]));
  };

  _exports.dateStrictlyBetweenRQL = dateStrictlyBetweenRQL;
});