define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/driver/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DRIVER_EMERGENCY_CONTACT_VALIDATION = _exports.DRIVER_LICENSE_VALIDATION = _exports.DRIVER_PHONE_VALIDATION = _exports.DRIVER_VALIDATION = void 0;
  const DRIVER_VALIDATION = {
    firstName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'First Name',
      message: '{description} can not be blank'
    })],
    lastName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Last Name',
      message: '{description} can not be blank'
    })],
    ssn: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Social Security Number',
      message: '{description} can not be blank'
    }), (0, _validators.validateLength)({
      is: 9,
      description: 'Social Security Number',
      message: '{description} can not be incomplete'
    })],
    birthDate: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Date of Birth',
      message: '{description} can not be blank'
    })]
  };
  _exports.DRIVER_VALIDATION = DRIVER_VALIDATION;
  const DRIVER_PHONE_VALIDATION = {
    unformattedPhoneNumber: [(0, _validators.validateLength)({
      is: 10,
      allowBlank: true,
      description: 'Phone Number',
      message: '{description} can not be incomplete'
    })]
  };
  _exports.DRIVER_PHONE_VALIDATION = DRIVER_PHONE_VALIDATION;
  const DRIVER_LICENSE_VALIDATION = {
    license: [(0, _validators.validatePresence)({
      presence: true,
      description: 'License',
      message: '{description} can not be blank'
    }), (0, _validators.validateLength)({
      is: 12,
      description: 'License',
      message: '{description} can not be incomplete'
    })],
    classificationName: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Classification',
      message: '{description} can not be blank'
    })]
  };
  _exports.DRIVER_LICENSE_VALIDATION = DRIVER_LICENSE_VALIDATION;
  const DRIVER_EMERGENCY_CONTACT_VALIDATION = {
    unformattedPhoneNumber: [(0, _validators.validateLength)({
      is: 10,
      allowBlank: true,
      description: 'Phone Number',
      message: '{description} can not be incomplete'
    })]
  };
  _exports.DRIVER_EMERGENCY_CONTACT_VALIDATION = DRIVER_EMERGENCY_CONTACT_VALIDATION;
});