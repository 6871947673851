define("adept-iq/classes/rules/trip-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ITP_TRIP = _exports.MANUAL_TRIP = _exports.DEMAND_TRIP = _exports.SUBSCRIPTION_TRIP = void 0;
  const SUBSCRIPTION_TRIP = 0;
  _exports.SUBSCRIPTION_TRIP = SUBSCRIPTION_TRIP;
  const DEMAND_TRIP = 1;
  _exports.DEMAND_TRIP = DEMAND_TRIP;
  const MANUAL_TRIP = 2;
  _exports.MANUAL_TRIP = MANUAL_TRIP;
  const ITP_TRIP = 3;
  _exports.ITP_TRIP = ITP_TRIP;
});