define("adept-iq/pods/components/iq-widgets/history-routes-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "adept-iq/pods/components/iq-widgets/history-routes-widget/config", "ember-light-table", "adept-iq/config/mapped-permIds", "moment", "ember-concurrency", "tomtom"], function (_exports, _component, _config, _emberLightTable, _mappedPermIds, _moment, _emberConcurrency, _tomtom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const toPixels = x => `${x}px`;

  var _default = _component.default.extend({
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    reconcile: Ember.inject.service(),
    session: Ember.inject.service(),
    mapService: Ember.inject.service('map'),
    activeContext: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    classNames: ['reconcile-widget'],
    config: _config.default,
    contextMenuContainer: null,
    contextMenuContainerPosition: null,
    contextMenuOptions: null,
    lastRowClicked: null,
    isEditClicked: false,
    fetchRouteDataTask: null,
    fetchTaxiDataTask: null,
    resetAndFetchRouteIdsTask: null,
    reconcileRouteDetails: null,
    isColumnEditorSettings: false,
    isCloseSearchPassenger: false,
    isUnscheduledTrips: false,
    editableColumnList: null,
    noresultFound: false,
    scrollToOffset: 0,
    contextMenuHeight: 350,
    serviceModeTaxiType: 'Taxi',
    tableConfigColumns: null,
    currentSortId: Ember.computed.alias('config.defaultSortId'),
    currentSortAsc: Ember.computed.alias('config.defaultSortAsc'),
    map: Ember.computed.readOnly('workspace.activeContext.topActiveContext.map'),
    isCompletedRoute: Ember.computed.alias('reconcile.isCompletedRoute'),
    routeId: Ember.computed.readOnly('reconcile.reconcileSelectedRouteId'),
    reconcileTripDate: Ember.computed.readOnly('reconcile.reconcileDate'),
    reconcileHistoryRowData: Ember.computed.alias('reconcile.reconcileHistoryRouteDetails'),
    isFilterAllowed: Ember.computed.bool('workspace.configPermissions.map.filterAllowed'),
    canEdit: Ember.computed('lastRowClicked', 'editableColumnList', function () {
      const columnList = Ember.get(this, 'editableColumnList');
      let editableColumns = [];

      if (!Ember.isEmpty(columnList)) {
        editableColumns = columnList.filterBy('isEditable', true);
      }

      return this.lastRowClicked && !this.isCompletedRoute && editableColumns.length;
    }),
    canSave: Ember.computed('historyRoutesEditTable.columns.@each.editingStart', function () {
      const columns = Ember.get(this, 'historyRoutesEditTable.columns');
      let editedColumns = [];

      if (!Ember.isEmpty(columns)) {
        editedColumns = columns.filterBy('editingStart', true);
      }

      return editedColumns.length;
    }),
    isFiltered: Ember.computed('historyRoutesEditTable.columns.@each.{isFiltered}', function () {
      return this.get('historyRoutesEditTable.columns').isAny('isFiltered');
    }),
    title: Ember.computed('reconcileRouteDetails', function () {
      const routeDetails = Ember.get(this, 'reconcileRouteDetails');

      if (routeDetails && routeDetails.routeId && this.reconcileTripDate) {
        return `Route ${routeDetails.routeId} on ${(0, _moment.default)(this.reconcileTripDate).format('MM/DD/YYYY')}`;
      }

      return 'Route';
    }),
    onWidgetStateChange: Ember.observer('widget.state', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateConfigColumns');
    }),
    updateReconcileHistoryRouteDetails: Ember.observer('reconcile.reconcileHistoryRouteDetails', 'reconcile.reconcileTaxiData', 'reconcile.reconcileSelectedRouteId', 'currentSortAsc', 'currentSortId', function () {
      if (this.get('reconcile.reconcileMode') !== this.serviceModeTaxiType && !this.get('reconcile.reconcileSelectedRouteId') || this.get('reconcile.reconcileMode') === this.serviceModeTaxiType && Ember.isEmpty(Ember.get(this, 'reconcile.reconcileTaxiData'))) {
        this.resetTable(this.get('reconcile.reconcileMode') === this.serviceModeTaxiType && Ember.isEmpty(Ember.get(this, 'reconcile.reconcileTaxiData')));
        return;
      }

      this.resetProperties();
      const historyRouteDetails = Ember.get(this, 'reconcile.reconcileHistoryRouteDetails');

      if (Ember.isEmpty(historyRouteDetails)) {
        return;
      }

      const routeDetails = Ember.get(this, 'reconcile.reconcileRouteDetails');
      const sortKey = Ember.get(this, 'currentSortId');
      const currentSortAsc = Ember.get(this, 'currentSortAsc');
      this.set('reconcileRouteDetails', routeDetails);
      const sortedList = currentSortAsc ? this.sortList(historyRouteDetails, sortKey) : this.sortList(historyRouteDetails, sortKey).reverse();
      this.get('historyRoutesTable').setRows(sortedList);
      this.get('historyRoutesEditTable').setRows(sortedList);
      this.refreshData();
      this.maintainTripSelection();
    }),

    init() {
      this._super(...arguments); //assign table and populate data in it


      this.configurePermissions();
      this.createRouteTable();
      this.createEditRouteTable();
      this.updateColumnsForFilter();
      this.fetchRouteDataTask = this.get('reconcile.fetchRouteData');
      this.fetchTaxiDataTask = this.get('reconcile.fetchTaxiData');
      this.resetAndFetchRouteIdsTask = this.get('reconcile.resetAndFetchRouteIds');

      if (this.get('reconcile.reconcileHistoryRouteDetails')) {
        Ember.run.scheduleOnce('afterRender', this, 'updateReconcileHistoryRouteDetails');
      }
    },

    configurePermissions() {
      this.set('isTransferTripToRouteAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.transferTripRecon, null));
      this.set('isEditTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editTripInfoRecon, null));
      this.set('isCancelTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.cancelTripRecon, null));
      this.set('isNoshowTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.noShowTripRecon, null));
      this.set('isUnperformNoshowTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.unperformNoShowRecon, null));
      this.set('isBreakAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.insertBreakRecon, null));
      this.set('isTransferTripToTaxiAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.transferTripToTaxiRecon, null));
      this.set('isScheduledTripAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.scheduleTripsRecon, null));
      this.set('isEditColumnAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.editColumnRecon, null));
      this.set('isViewUnscheduledTripsAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.viewUnscheduledTripsRecon, null));
      this.set('isAddNewTripsAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.addNewTripRecon, null));
      this.set('isRouteActivityLogAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.activityLogRoute, null));
      this.set('isTripActivityLogAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.activityLog, null));
      this.set('isSortingAllowed', this.get('permissionLayer').permInUserHash(_mappedPermIds.default.sortRouteGridRecon, null));
    },

    sortList(tripList, key) {
      return tripList.sort(function (a, b) {
        if (key === 'eta') {
          // eslint-disable-next-line no-nested-ternary
          return (0, _moment.default)(a.etaDate) > (0, _moment.default)(b.etaDate) ? 1 : (0, _moment.default)(b.etaDate) > (0, _moment.default)(a.etaDate) ? -1 : 0;
        } // eslint-disable-next-line no-nested-ternary


        return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
      });
    },

    resetProperties() {
      this.setProperties({
        reconcileRouteDetails: null,
        lastRowClicked: null,
        isEditClicked: null,
        noresultFound: false
      });
    },

    resetTable(isSearchMessageVisible) {
      this.set('reconcileRouteDetails', null);
      this.get('historyRoutesTable').setRows([]);
      this.get('historyRoutesEditTable').setRows([]);

      if (isSearchMessageVisible) {
        if (Ember.get(this, 'historyRoutesTable').isEmpty) {
          this.set('noresultFound', true);
        }

        if (Ember.get(this, 'historyRoutesEditTable').isEmpty) {
          this.set('noresultFound', true);
        }
      }
    },

    maintainTripSelection() {
      const selectedSearchedTrip = Ember.get(this, 'reconcile.selectedSearchedTrip');
      const rows = this.get('historyRoutesTable.rows.content');

      if (selectedSearchedTrip) {
        rows.forEach(_row => {
          if (_row.get('tripId') === selectedSearchedTrip.get('tripId') && _row.get('type') === 'pickup') {
            this.send('onRowClick', _row);
          }
        });
      }
    },

    selectRow(stopId) {
      if (!stopId) {
        return;
      }

      const rows = this.get('historyRoutesTable.rows');
      const previousSelectedRow = rows.filter(row => row.content.stopId === stopId)[0];

      if (previousSelectedRow) {
        this.send('onRowClick', previousSelectedRow);
      }
    },

    saveTripTransferToTaxiTask: (0, _emberConcurrency.task)(function* () {
      const provider = Ember.get(this, 'reconcile.reconcileProviderName');
      const tripDetails = Ember.get(this, 'lastRowClicked');
      const store = this.get('store');
      const tripAdapter = store.adapterFor('reconcile-trip');
      const payload = {
        routeId: null,
        serviceMode: this.serviceModeTaxiType.toLowerCase(),
        tripId: tripDetails.tripId,
        providerName: provider.id,
        userId: this.session.data.authenticated.userId
      };

      try {
        return yield tripAdapter.putTrip(payload, 'transfer trip');
      } catch (e) {
        throw e;
      }
    }),

    createRouteTable() {
      const routeTableconfig = this.config.historyRoutesEditTable.map(column => {
        column.hidden = true;
        return column;
      });
      const configHistoryRoutesTable = this.config.historyRoutesColumns.concat(routeTableconfig);
      const historyRoutesTable = new _emberLightTable.default(configHistoryRoutesTable);
      this.set('historyRoutesTable', historyRoutesTable);
      this.config.historyRoutesEditTable.map(column => {
        column.hidden = false;
        return column;
      });
      this.setSortingPropsToRouteTable();
    },

    setSortingPropsToRouteTable() {
      const routeTableColumns = this.get('historyRoutesTable.columns');
      routeTableColumns.forEach(column => {
        if (column.valuePath === Ember.get(this, 'currentSortId')) {
          column.set('sorted', true);
          column.set('ascending', this.config.defaultSortAsc);
        }
      });
    },

    createEditRouteTable() {
      const configured = this.config.historyRoutesColumns.filter(column => column.label);
      const editbableTableconfig = configured.map(column => {
        column.hidden = true;
        return column;
      });
      const configHistoryRoutesEditTable = this.config.historyRoutesEditTable.concat(editbableTableconfig);
      const historyRoutesEditTable = new _emberLightTable.default(configHistoryRoutesEditTable);
      this.set('historyRoutesEditTable', historyRoutesEditTable);
      this.config.historyRoutesColumns.map(column => {
        if (column.label) column.hidden = false;
        return column;
      });
      this.setSortingPropsToEditRouteTable();
      this.setEditablePropsToEditRouteTable();
    },

    setSortingPropsToEditRouteTable() {
      const editRouteTableColumns = this.get('historyRoutesEditTable.columns');
      editRouteTableColumns.forEach(column => {
        if (column.valuePath === Ember.get(this, 'currentSortId')) {
          column.set('sorted', true);
          column.set('ascending', this.config.defaultSortAsc);
        }
      });
    },

    setEditablePropsToEditRouteTable() {
      if (Ember.isEmpty(this.editableColumnList)) {
        this.set('editableColumnList', []);
      }

      const editRouteTableColumns = this.get('historyRoutesEditTable.columns');
      editRouteTableColumns.map(column => {
        column.set('isEditable', true);
        this.editableColumnList.push({
          label: column.label,
          isEditable: column.isEditable
        });
      });
    },

    updateColumnsForFilter() {
      const columns = [];
      const tableColumns = this.config.historyRoutesColumns.concat(this.config.historyRoutesEditTable);

      if (!Ember.isEmpty(tableColumns)) {
        tableColumns.forEach(column => {
          if (!column.unAvailable) {
            const col = Ember.Object.create(column);
            col.set('filterTypes', this.config.filters[column.dataType] || []);
            columns.push(col);
          }
        });
      }

      Ember.set(this, 'tableConfigColumns', columns); // const filterColumnList = this.config.historyRoutesColumns.concat(this.config.historyRoutesEditTable);
      // filterColumnList.forEach((columnObj) => {
      //   const columns = get(this, 'historyRoutesEditTable.columns');
      //   columns.map((column) => {
      //     if (column.valuePath === columnObj.id) {
      //       column.config = columnObj;
      //     }
      //   });
      // });
    },

    getExactColumn(id) {
      let column = null;
      let fromEdit = true;
      const editColumns = Ember.get(this, 'historyRoutesEditTable.columns');
      const nonEditcolumns = Ember.get(this, 'historyRoutesTable.columns');
      column = editColumns.filter(col => col.valuePath === id)[0];

      if (!column) {
        column = nonEditcolumns.filter(col => col.valuePath === id)[0];
        fromEdit = false;
      }

      return {
        fromEdit,
        column
      };
    },

    getEditTableColumn(configColumn) {
      let column = null;
      const editConfigColumns = this.config.historyRoutesEditTable;
      const editConfigColumn = editConfigColumns.filter(configCol => configCol.valuePath === configColumn.id)[0];

      if (editConfigColumn) {
        const editColumns = Ember.get(this, 'historyRoutesEditTable.columns');
        column = editColumns.filter(col => col.valuePath === configColumn.id)[0];
      }

      return column;
    },

    getNonEditTableColumn(configColumn) {
      let column = null;
      const nonEditConfigColumns = this.config.historyRoutesColumns;
      const nonEditConfigColumn = nonEditConfigColumns.filter(configCol => configCol.valuePath === configColumn.id)[0];

      if (nonEditConfigColumn) {
        const nonEditColumns = Ember.get(this, 'historyRoutesTable.columns');
        column = nonEditColumns.filter(col => col.valuePath === configColumn.id)[0];
      }

      return column;
    },

    setPropToActualColumn(configColumn, options) {
      let column = this.getEditTableColumn(configColumn);

      if (!column) {
        column = this.getNonEditTableColumn(configColumn);
      }

      if (column) {
        if (options.isHiddenProp) {
          column.set('hidden', configColumn.hidden);
        } else if (options.isWidthProp) {
          column.set('width', configColumn.width);
        }
      }

      return column;
    },

    updateConfigColumns() {
      const state = this.get('widget.state') || {};
      const columns = this.get('tableConfigColumns') || [];
      let shouldResize = false;
      const scrollLeft = this.getScrollLeft();
      const columnsHash = state.columns || {};
      Object.entries(columnsHash).forEach(([id, columnState]) => {
        const column = columns.findBy('id', id);

        if (!column) {
          return;
        }

        if (Ember.isPresent(columnState.hidden)) {
          column.set('hidden', columnState.hidden);
          this.setPropToActualColumn(column, {
            isHiddenProp: true
          });
          shouldResize = true;
        }

        const wasFiltered = column.get('isFiltered');
        ['filterTypeId', 'filterValues', 'filterType'].forEach(prop => {
          // allow null value to overwrite
          if (!columnState.hasOwnProperty(prop)) {
            return;
          }

          column.set(prop, columnState[prop]);
        });

        if (wasFiltered || column.get('isFiltered')) {
          Ember.run.scheduleOnce('afterRender', this, 'refreshData');
        }

        if (Ember.isPresent(columnState.width)) {
          column.set('width', toPixels(columnState.width));
          this.setPropToActualColumn(column, {
            isWidthProp: true
          });
          shouldResize = true;
        }
      });

      if (shouldResize) {
        // resize at end of _next_ loop so that all changes are applied
        Ember.run.next(() => {
          if (Ember.isPresent(scrollLeft)) {
            // wait an additional loop before restoring scroll position
            Ember.run.next(() => {
              Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
            });
          }
        });
      }

      Ember.run.debounce(this, 'refreshData', 250);
    },

    setScrollLeft(scrollLeft) {
      if (!this.$('.reconcile-widget-table-body')) {
        return;
      }

      this.$('.reconcile-widget-table-body').scrollLeft(scrollLeft);
    },

    getScrollLeft() {
      const body = this.$('.reconcile-widget-table-body');

      if (body) {
        return body.scrollLeft();
      }

      return 0;
    },

    getFilteredColumns(columns) {
      return columns.filter(column => {
        if (!Ember.isEmpty(column.filterType)) {
          if (column.filterType.id === 'empty') {
            return true;
          } else if (!Ember.isEmpty(column.filterValues) && column.filterValues.filter(item => !Ember.isEmpty(item)).length) {
            return true;
          }
        }

        return false;
      });
    },

    refreshData() {
      const columns = this.get('tableConfigColumns') || [];
      const tableData = this.get('reconcileHistoryRowData') || [];
      const resultModel = [];

      if (!Ember.isEmpty(tableData)) {
        const searchColumns = this.getFilteredColumns(columns);
        tableData.map(item => {
          if (Ember.isEmpty(searchColumns)) resultModel.push(item);else {
            let bool = true;
            searchColumns.forEach(column => {
              if (column.filterType && column.filterType.exp && column.filterType.exp(item[column.valuePath], column.filterValues)) return;
              bool = false;
            });
            if (bool) resultModel.push(item);
          }
        });

        if (resultModel) {
          const editTable = Ember.get(this, 'historyRoutesEditTable');
          const nonEditTable = Ember.get(this, 'historyRoutesTable');
          const rows = [...new Set(resultModel)];
          editTable.setRowsSynced(rows.toArray());
          nonEditTable.setRowsSynced(rows.toArray());
        }

        this.set('noresultFound', false);

        if (Ember.get(this, 'historyRoutesTable').isEmpty) {
          this.set('noresultFound', true);
        }

        if (Ember.get(this, 'historyRoutesEditTable').isEmpty) {
          this.set('noresultFound', true);
        }
      }
    },

    isValidOption(optionId) {
      switch (optionId) {
        case 'reconcileTripTransferToRoute':
          return this.isTransferTripToRouteAllowed;

        case 'reconcileTripTransferToTaxi':
          return this.isTransferTripToTaxiAllowed;

        case 'reconcileCancelTrip':
          return this.isCancelTripAllowed;

        case 'reconcileNoShowTrip':
          return this.isNoshowTripAllowed;

        case 'reconcileUnPerformNoShow':
          return this.isUnperformNoshowTripAllowed;

        case 'reconcileEditAdditionalTripInfo':
          return this.isEditTripAllowed;

        case 'reconcileTripLog':
          return this.isTripActivityLogAllowed;

        case 'reconcileAddNewTrip':
          return this.isAddNewTripsAllowed;

        case 'reconcileAddBreak':
          return this.isBreakAllowed;

        case 'reconcileUnscheduledTrips':
          return this.isViewUnscheduledTripsAllowed;

        case 'reconcileShowRouteLog':
          return this.isRouteActivityLogAllowed;

        case 'reconcileColumnEditor':
          return this.isEditColumnAllowed;

        default:
          break;
      }

      return false;
    },

    getEnableDisableOptionFlag(optionId, rowData) {
      if (!rowData) {
        return true;
      }

      let isEnabled = false;

      if (this.get('reconcile.reconcileMode') === this.serviceModeTaxiType) {
        return this.isValidOption(optionId) && (optionId === 'reconcileCancelTrip' || optionId === 'reconcileEditAdditionalTripInfo' || optionId === 'reconcileTripLog');
      } // For normal trips


      switch (rowData.type.toLowerCase()) {
        case 'startgarage':
        case 'endgarage':
        case 'break':
          isEnabled = this.isValidOption(optionId) && (this.isCompletedRoute && optionId === 'reconcileShowRouteLog' || !this.isCompletedRoute && (optionId === 'reconcileShowRouteLog' || optionId === 'reconcileAddNewTrip' || optionId === 'reconcileAddBreak' || optionId === 'reconcileUnscheduledTrips' || optionId === 'reconcileShowRouteLog' || optionId === 'reconcileColumnEditor'));
          break;

        case 'pickup':
        case 'dropoff':
          isEnabled = this.isValidOption(optionId) && (this.isCompletedRoute && (optionId === 'reconcileTripLog' || optionId === 'reconcileShowRouteLog') || !this.isCompletedRoute && (optionId === 'reconcileTripTransferToRoute' || optionId === 'reconcileTripTransferToTaxi' || rowData.status && rowData.status.toLowerCase() !== 'noshow' && optionId === 'reconcileCancelTrip' || rowData.status && rowData.status.toLowerCase() !== 'noshow' && optionId === 'reconcileNoShowTrip' || rowData.status && rowData.status.toLowerCase() === 'noshow' && optionId === 'reconcileUnPerformNoShow' || optionId === 'reconcileWaitlistTrip' || optionId === 'reconcileEditAdditionalTripInfo' || optionId === 'reconcileTripLog' || optionId === 'reconcileAddNewTrip' || optionId === 'reconcileAddBreak' || optionId === 'reconcileUnscheduledTrips' || optionId === 'reconcileShowRouteLog' || optionId === 'reconcileColumnEditor'));
          break;

        default:
          break;
      }

      return isEnabled;
    },

    createContextMenu(rowData) {
      if (!rowData) {
        return;
      }

      this.set('contextMenuOptions', []);
      this.get('config').contextMenuOptionsList.map(option => {
        this.get('contextMenuOptions').push(this.createContextMenuOptions(option.id, option.caption, option.dialogId, option.dialogTitle, rowData));
      });
    },

    createContextMenuOptions(optionId, optionCaption, dialogId, dialogTitle, rowData) {
      const disable = !this.getEnableDisableOptionFlag(optionId, rowData);
      let action = null;

      if (!disable) {
        action = trip => {
          Ember.set(this, 'contextMenuHandler', this.contextMenuCallback.bind(this, optionId));

          if (optionId === 'reconcileTripTransferToTaxi') {
            this.handleTranserTripToTaxi();
          } else if (optionId === 'reconcileAddNewTrip') {
            this.toggleProperty('isCloseSearchPassenger');
          } else if (optionId === 'reconcileColumnEditor') {
            this.toggleProperty('isColumnEditorSettings');
          } else if (optionId === 'reconcileUnscheduledTrips') {
            this.toggleProperty('isUnscheduledTrips');
          } else {
            const recon = Ember.get(this, 'reconcile');
            recon.set('reconcileSelectedTripId', trip.tripId);
            this.get('workspace').pushState(optionId, {
              trip,
              title: dialogTitle,
              route: Ember.get(this, 'reconcileRouteDetails'),
              action: this.contextMenuHandler
            });
          }
        };
      }

      return {
        id: optionId,
        name: optionCaption,
        permId: dialogId,
        disable,
        action
      };
    },

    updateHistoryRouteWidget(stopId, isValid) {
      Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
        'reconcile-trip': [],
        'reconcile-trip-stops': []
      });

      if (this.get('reconcile.reconcileMode') === this.serviceModeTaxiType) {
        this.fetchTaxiDataTask.perform().then(() => {
          if (stopId) {
            this.toggleProperty('reconcile.isLoading');

            if (isValid) {
              this.get('notifications').success('SUCCESSFULLY CHANGED VALUES');
            }

            this.selectRow(stopId);
          }
        });
        return;
      }

      this.fetchRouteDataTask.perform(this.routeId).then(() => {
        if (stopId) {
          this.toggleProperty('reconcile.isLoading');

          if (isValid) {
            this.get('notifications').success('SUCCESSFULLY CHANGED VALUES');
          }

          this.selectRow(stopId);
        }
      }).catch(error => {
        let message;

        switch (error.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem is getting updated route, please try again.';
            break;
        }

        this.get('notifications').warning(message);

        if (this.get('reconcile.isLoading')) {
          this.toggleProperty('reconcile.isLoading');
        }
      });
    },

    contextMenuCallback(optionId, isSaved) {
      Ember.set(this, 'reconcile.reconcileSelectedTripId', null);

      if (optionId === 'reconcileAddNewTrip') {
        Ember.set(this, 'workspace.activeContext.topActiveContext.resetLocation', true);
      }

      if (optionId === 'reconcileEditAdditionalTripInfo' || optionId === 'reconcileAddBreak') {
        Ember.set(this, 'workspace.activeContext.topActiveContext.resetMapFlag', true);
      }

      if (!isSaved || optionId === 'reconcileTripLog' || optionId === 'reconcileShowRouteLog') {
        return;
      }

      this.updateHistoryRouteWidget();
    },

    findTableRowIndex(table, row) {
      let index = 0;

      for (const tableRow of table.rows.content) {
        if (tableRow === row) {
          return index;
        }

        index++;
      }
    },

    handleTranserTripToTaxi() {
      const tripDetails = Ember.get(this, 'lastRowClicked');
      this.showToolTip('Transfer Trip to Taxi', 'Transfer Trip ' + tripDetails.tripId + ' from Route ' + tripDetails.routeId + ' to Taxi', 'TRIP TRANSFER ' + tripDetails.tripId + '  TO TAXI WAS SUCCESSFUL');
    },

    showToolTip(title, tip, successMsg) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveTripTransferToTaxiTask').perform().then(() => {
            this.removeTaxiTrip();
            tooltip.reset();
            this.get('notifications').success(successMsg);
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem creating the route, please try again.';
                break;
            }

            this.set('errorMessage', message);
            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    isValidColumnForEditing(row, column) {
      if (!row) {
        return false;
      }

      let isValid = true;
      const rowData = row.content;
      const columnType = column.get('valuePath');

      switch (rowData.type.toLowerCase()) {
        case 'pickup':
        case 'dropoff':
          if (columnType === 'ambSeats' || columnType === 'fare' || columnType === 'pca' && !rowData.pca) {
            isValid = false;
          }

          break;

        case 'startgarage':
        case 'endgarage':
          if (columnType === 'stopTime' || columnType === 'otpStatus' || columnType === 'ambSeats' || columnType === 'wcSeats' || columnType === 'cmp' || columnType === 'pca' || columnType === 'fare') {
            isValid = false;
          }

          break;

        case 'break':
          if (columnType === 'ambSeats' || columnType === 'wcSeats' || columnType === 'cmp' || columnType === 'pca' || columnType === 'fare') {
            isValid = false;
          }

          break;

        default:
          break;
      }

      return isValid;
    },

    enableDisableTableRows(isEnable) {
      const rows = this.get('historyRoutesEditTable.rows');
      rows.map(row => {
        row.set('disabled', !isEnable);
      });
    },

    manageRowEditing() {
      const rows = this.get('historyRoutesEditTable.rows');
      const row = rows.findBy('selected');

      if (row) {
        const columns = this.get('historyRoutesEditTable.columns');
        columns.map(column => {
          const valuePath = column.get('valuePath');
          const value = row.get(valuePath);
          column.set('oldValue', value);

          if (column.get('isEditable') && this.isValidColumnForEditing(row, column)) {
            row.set('isEditing', true);
            row.set('columns', columns);
            column.set('isEditing', true);
            this.set('isEditClicked', true);
          }
        });
      }

      if (this.isEditClicked) {
        this.enableDisableTableRows(false);
      }
    },

    enableDisableEditing(row, isEnable) {
      const columns = row.get('columns');
      columns.map(column => {
        column.set('isEditing', isEnable);

        if (!isEnable) {
          column.set('editingStart', isEnable);
        }
      });
      row.set('isEditing', isEnable);
    },

    handleSaveTrip() {
      const rows = this.get('historyRoutesEditTable.rows');
      const row = rows.findBy('selected');
      let isValueChanged = false;

      if (row) {
        const rowData = row.get('content');
        const columns = row.get('columns');
        columns.map(column => {
          const newValue = column.get('newValue');
          const oldValue = column.get('oldValue');
          const valuePath = column.get('valuePath');

          if (newValue && oldValue !== newValue && column.get('isEditable')) {
            row.set(valuePath, newValue);
            isValueChanged = true;

            if (valuePath === 'actualTime') {
              row.set('eta', newValue);
              row.set('arriveTime', newValue);
            }
          }
        });
        this.enableDisableEditing(row, false);
        this.enableDisableTableRows(true);

        if (isValueChanged) {
          this.toggleProperty('reconcile.isLoading');
          Ember.get(this, 'saveTripDetailsTask').perform(rowData).then(isValid => {
            this.updateHistoryRouteWidget(rowData.stopId, isValid);
          });
        }
      }

      this.set('isEditClicked', false);
    },

    // Create playload for map polyline and marker!!!
    // Later to be updated with relation model
    mapTrip(tripId) {
      const tripDetail = {
        eventPoints: [],
        addresses: {
          pick: null,
          drop: null
        }
      };
      Ember.set(this, 'workspace.activeContext.topActiveContext.implicitMarkers', []);
      Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', []);
      if (Ember.isEmpty(tripId)) return Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
        'reconcile-trip': [],
        'reconcile-trip-stops': []
      });
      const trip = Ember.get(this, 'store').peekRecord('reconcile-trip', tripId);
      let stops;
      const taxiStops = [];
      const eventBounds = [];

      if (this.get('reconcile.reconcileMode') === this.serviceModeTaxiType) {
        stops = trip.stops;
      } else {
        stops = Ember.get(this, 'store').peekAll('reconcile-stop').filter(stop => stop.tripId === tripId);
      }

      if (!Ember.isEmpty(stops)) {
        stops.map(stop => {
          let addresses;

          if (this.get('reconcile.reconcileMode') === this.serviceModeTaxiType) {
            const stopTaxiData = Ember.Object.create(stop);
            taxiStops.push(stopTaxiData);
            addresses = stopTaxiData.address;
            const type = stopTaxiData.type === 'dropoff' ? 'drop' : 'pick';
            tripDetail.addresses[type] = stopTaxiData.address;
            stopTaxiData.set('address', addresses);
            stopTaxiData.set('stopType', type);
            stopTaxiData.set('mapId', type);
            stopTaxiData.set('tripId', trip.tripId);

            if (addresses.lat && addresses.lng) {
              tripDetail.eventPoints.push([addresses.lat.toString(), addresses.lng.toString()]);
              eventBounds.push({
                lat: addresses.lat.toString(),
                lng: addresses.lng.toString()
              });
              stopTaxiData.set('lat', addresses.lat.toString());
              stopTaxiData.set('lng', addresses.lng.toString());
            }
          } else {
            addresses = Ember.get(this, 'store').peekAll('reconcile-address');
            addresses.filter(address => {
              if (address.stopId === stop.stopId) {
                tripDetail.addresses[stop.stopType] = address;
                stop.set('address', address);

                if (address.lat && address.lng) {
                  tripDetail.eventPoints.push([address.lat.toString(), address.lng.toString()]);
                  eventBounds.push({
                    lat: address.lat.toString(),
                    lng: address.lng.toString()
                  });
                  stop.set('lat', address.lat.toString());
                  stop.set('lng', address.lng.toString());
                }
              }
            });
          }
        });

        if (tripDetail.eventPoints.length !== 0) {
          const map = this.get('map');

          const bounds = _tomtom.default.L.latLngBounds(tripDetail.eventPoints);

          if (bounds) {
            map.fitBounds(bounds);
          }

          Ember.set(this, 'workspace.activeContext.topActiveContext.implicitMarkers', eventBounds);
          trip.set('addresses', tripDetail.addresses);
          trip.set('providerName', Ember.get(this, 'reconcileRouteDetails.providerName'));
          const pickAddress = tripDetail.addresses.pick;
          const dropAddress = tripDetail.addresses.drop;
          trip.set('polyline', [[dropAddress.lat, dropAddress.lng], [pickAddress.lat, pickAddress.lng]]);
          return Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
            'reconcile-trip': [trip],
            'reconcile-trip-stops': !Ember.isEmpty(taxiStops) ? taxiStops : stops
          });
        }
      }

      return Ember.set(this, 'workspace.activeContext.topActiveContext.structuredMapData', {
        'reconcile-trip': [],
        'reconcile-trip-stops': []
      });
    },

    calculateActualDepartTime(data) {
      let actualDepartDateTime = data.actualArriveDateTime;

      if (data.actualTime) {
        if (!actualDepartDateTime) {
          const tripDate = (0, _moment.default)(this.reconcileTripDate).format('MM-DD-YYYY');
          actualDepartDateTime = (0, _moment.default)(tripDate + ' ' + data.actualTime);
        } else {
          const actualDate = (0, _moment.default)(actualDepartDateTime).format('MM-DD-YYYY');
          actualDepartDateTime = (0, _moment.default)(actualDate + ' ' + data.actualTime);
        }

        const actualDepartDate = (0, _moment.default)(actualDepartDateTime).format('MM-DD-YYYY');
        const startTime = (0, _moment.default)(actualDepartDateTime).format('hh:mm A');
        const stopMins = data.stopTime;
        const endTime = (0, _moment.default)(startTime, 'h:mm A').add(stopMins, 'minutes').format('hh:mm A');
        return (0, _moment.default)(actualDepartDate + ' ' + endTime).toISOString();
      }

      return null;
    },

    saveTripDetailsTask: (0, _emberConcurrency.task)(function* (data) {
      if (!data.type) {
        return;
      }

      const payload = {
        id: data.routeId
      };
      const actualDate = data.actualArriveDateTime ? (0, _moment.default)(data.actualArriveDateTime).format('MM-DD-YYYY') : (0, _moment.default)(this.reconcileTripDate).format('MM-DD-YYYY'); // let isStartGarageUpdated = false;
      // let isEndGarageUpdated = false;

      if (data.type.toLowerCase() === 'startgarage') {
        payload.actualStartTime = (0, _moment.default)(actualDate + ' ' + data.actualTime).toISOString(); // payload.plannedStartTime = moment(stopDate + ' ' + data.stopTime).toISOString();

        payload.odometerStart = parseInt(data.mileage, 10);
        payload.otpStatus = parseInt(data.otpStatus, 10); // isStartGarageUpdated = true;
      } else if (data.type.toLowerCase() === 'endgarage') {
        payload.actualEndTime = (0, _moment.default)(actualDate + ' ' + data.actualTime).toISOString(); // payload.plannedEndTime = moment(stopDate + ' ' + data.stopTime).toISOString();

        payload.odometerEnd = parseInt(data.mileage, 10);
        payload.otpStatus = parseInt(data.otpStatus, 10); // isEndGarageUpdated = true;
      } else {
        const actualDepartDate = this.calculateActualDepartTime(data);

        if (data.type.toLowerCase() === 'break') {
          payload.breaks = [];
          payload.breaks.push({
            stopId: data.stopId,
            actualArriveTime: (0, _moment.default)(actualDate + ' ' + data.actualTime).toISOString(),
            actualDepartTime: actualDepartDate,
            odometer: parseInt(data.mileage, 10),
            otpStatus: parseInt(data.otpStatus, 10)
          });
        } else {
          const travelNeeds = [];
          const stops = [];
          const trips = [];
          travelNeeds.push({
            name: 'ambulatory',
            cnt: parseInt(data.ambSeats, 10) > 0 ? parseInt(data.ambSeats, 10) : 0
          });
          travelNeeds.push({
            name: 'wheelChair',
            cnt: parseInt(data.wcSeats, 10) > 0 ? parseInt(data.wcSeats, 10) : 0
          });
          travelNeeds.push({
            name: 'companion',
            cnt: parseInt(data.cmp, 10) > 0 ? parseInt(data.cmp, 10) : 0
          });
          travelNeeds.push({
            name: 'pca',
            cnt: parseInt(data.pca, 10) > 0 ? parseInt(data.pca, 10) : 0
          });
          travelNeeds.push({
            name: 'serviceAnimal',
            cnt: parseInt(data.serviceAnimal, 10) > 0 ? parseInt(data.serviceAnimal, 10) : 0
          });
          travelNeeds.push({
            name: 'loadTime',
            cnt: parseInt(data.loadTime, 10) > 0 ? parseInt(data.loadTime, 10) : 0
          });
          travelNeeds.push({
            name: 'unloadTime',
            cnt: parseInt(data.unloadTime, 10) > 0 ? parseInt(data.unloadTime, 10) : 0
          });

          if (!Ember.isEmpty(data.braces)) {
            travelNeeds.push({
              name: 'braces',
              cnt: 0
            });
          }

          if (!Ember.isEmpty(data.supportCane)) {
            travelNeeds.push({
              name: 'supportCane',
              cnt: 0
            });
          }

          if (!Ember.isEmpty(data.crutches, 10)) {
            travelNeeds.push({
              name: 'crutches',
              cnt: 0
            });
          }

          if (!Ember.isEmpty(data.doubleWheelchair)) {
            travelNeeds.push({
              name: 'doubleWheelchair',
              cnt: 0
            });
          }

          stops.push({
            stopId: data.stopId,
            actualArriveTime: (0, _moment.default)(actualDate + ' ' + data.actualTime).toISOString(),
            actualDepartTime: actualDepartDate,
            odometer: parseInt(data.mileage, 10),
            otpStatus: parseInt(data.otpStatus, 10)
          });
          trips.push({
            tripId: data.tripId,
            fare: data.fare,
            otpStatus: parseInt(data.otpStatus, 10),
            travelNeeds,
            stops
          });
          payload.trips = trips;
        }
      }

      try {
        if (!data.routeId) {
          const reconcileTripAdapter = this.store.adapterFor('reconcile-trip');
          yield reconcileTripAdapter.patchTrip(payload.trips[0], 'update trip');
        } else {
          const reconcileRouteAdapter = this.store.adapterFor('reconcile-route');
          return yield reconcileRouteAdapter.patchRoute(payload, 'update route').then(() => {// if (isStartGarageUpdated || isEndGarageUpdated) {
            //   this.updateStoreForGarage(data.routeId, payload, isStartGarageUpdated);
            // }
          });
        }

        return true;
      } catch (error) {
        let message;

        switch (error.status) {
          case 400:
            message = 'There was a problem with one of the fields. Please check over the form and try again.';
            break;

          default:
            message = 'There was a problem updating the route, please try again.';
            break;
        }

        this.get('notifications').warning(message); // this.toggleProperty('reconcile.isLoading');

        return false;
      }
    }),

    updateStoreForGarage(routeId, payload, isStartGarageUpdated) {
      // update start garage
      const record = this.store.peekRecord('reconcile-route', routeId);

      if (isStartGarageUpdated) {
        record.set('actualStartTime', payload.actualStartTime); // record.set('plannedStartTime', payload.plannedStartTime);

        record.set('odometerStart', payload.odometerStart);
        record.set('otpStatus', payload.otpStatus);
      } else {
        record.set('actualEndTime', payload.actualEndTime); // record.set('plannedEndTime', payload.plannedEndTime);

        record.set('odometerEnd', payload.odometerEnd);
        record.set('otpStatus', payload.otpStatus);
      }

      Ember.set(this, 'reconcile.updateRouteInformation', true);
    },

    getValidationError() {
      const rows = this.get('historyRoutesEditTable.rows');
      const row = rows.findBy('selected');

      if (row) {
        let actualTime, stopTime, odometer, ambSeats, wcSeats, cmp, pca, fare, otpStatus;
        const columns = row.get('columns');
        columns.map(column => {
          const oldValue = column.get('oldValue');
          const newValue = column.get('newValue');
          const valuePath = column.get('valuePath');
          const finalValue = !oldValue && newValue ? newValue : oldValue;

          switch (valuePath) {
            case 'actualTime':
              actualTime = finalValue;
              break;

            case 'stopTime':
              stopTime = finalValue;
              break;

            case 'mileage':
              odometer = finalValue;
              break;

            case 'ambSeats':
              ambSeats = finalValue;
              break;

            case 'wcSeats':
              wcSeats = finalValue;
              break;

            case 'cmp':
              cmp = finalValue;
              break;

            case 'pca':
              pca = finalValue;
              break;

            case 'fare':
              fare = finalValue;
              break;

            case 'otpStatus':
              otpStatus = finalValue;
              break;

            default:
              break;
          }
        });
        const rowData = Ember.get(row, 'content');

        if (stopTime && !Number(stopTime) && Number(stopTime) !== 0) {
          return 'Stop time is not valid';
        }

        if (stopTime && !actualTime) {
          return 'You can not edit Stop Time if Actual Time is empty';
        }

        if (odometer && !Number(odometer) && Number(odometer) !== 0) {
          return 'Mileage is not valid';
        }

        if (!(rowData.type && (rowData.type.toLowerCase() === 'startgarage' || rowData.type.toLowerCase() === 'endgarage' || rowData.type.toLowerCase() === 'break'))) {
          if (ambSeats && !Number(ambSeats) && Number(ambSeats) !== 0) {
            return 'AmbSeats is not valid';
          }

          if (wcSeats && !Number(wcSeats) && Number(wcSeats) !== 0) {
            return 'WcSeats is not valid';
          }

          if (cmp && !Number(cmp) && Number(cmp) !== 0) {
            return 'CMP is not valid';
          }

          if (pca && !Number(pca) && Number(pca) !== 0) {
            return 'PCA is not valid';
          }

          if (fare && !Number(fare) && Number(fare) !== 0) {
            return 'Fare is not valid';
          }

          if (otpStatus && !Number(otpStatus) && Number(otpStatus) !== 0) {
            return 'Otp status is not valid';
          }
        }
      }

      return null;
    },

    showErrorToolTip(title, tip) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: title,
        tip: tip,
        primaryActionText: 'OK',
        primaryAction: () => {
          tooltip.reset();
        },
        hasOverlay: true
      });
    },

    removeTaxiTrip() {
      const tripDetails = Ember.get(this, 'lastRowClicked');
      const record = this.store.peekRecord('reconcile-trip', tripDetails.tripId);
      this.store.unloadRecord(record);
      this.contextMenuCallback('reconcileTripTransferToTaxi', true);
    },

    unselectRow() {
      const editTableRows = this.get('historyRoutesEditTable.rows');
      const nonEditTableRows = this.get('historyRoutesTable.rows');
      const editableRow = editTableRows.findBy('selected');
      const nonEditableRow = nonEditTableRows.findBy('selected');

      if (editableRow) {
        editableRow.set('selected', null);
      }

      if (nonEditableRow) {
        nonEditableRow.set('selected', null);
      }

      Ember.set(this, 'lastRowClicked', null);
    },

    actions: {
      onColumnEditorSaveClick(columnEditorList) {
        const columns = this.get('historyRoutesEditTable.columns');
        columns.map(column => {
          columnEditorList.map(item => {
            if (item.label === column.label) {
              column.set('isEditable', item.isEditable);
            }
          });
        });
        this.set('editableColumnList', columnEditorList);
        this.send('onColumnEditorCloseClick');
      },

      onColumnEditorCloseClick() {
        this.toggleProperty('isColumnEditorSettings');
      },

      onSearchPassengerCloseClick() {
        this.toggleProperty('isCloseSearchPassenger');
      },

      onUnscheduledTripsCloseClick() {
        this.toggleProperty('isUnscheduledTrips');
      },

      setContextMenuContainer(dropdown) {
        this.set('contextMenuContainer', dropdown);
      },

      onContextMenuRowClick(action) {
        const model = this.get('lastRowClicked');
        this.get('contextMenuContainer').actions.close();

        if (action.action) {
          action.action(model);
        }
      },

      onRowClick(row) {
        if (this.get('isEditClicked')) {
          return;
        }

        let index = 0;
        let selectedRow2 = null;
        const rows = this.get('historyRoutesTable.rows');
        let selectedRow = rows.findBy('selected');
        if (row === selectedRow) return;

        if (selectedRow) {
          index = this.findTableRowIndex(this.get('historyRoutesTable'), selectedRow);

          if (Ember.isEmpty(index)) {
            index = this.findTableRowIndex(this.get('historyRoutesEditTable'), selectedRow);
            selectedRow2 = this.get('historyRoutesTable.rows').content[index];
          } else {
            selectedRow2 = this.get('historyRoutesEditTable.rows').content[index];
          }

          selectedRow.set('selected', false);
          selectedRow2.set('selected', false);
        }

        index = this.findTableRowIndex(this.get('historyRoutesTable'), row);

        if (Ember.isEmpty(index)) {
          index = this.findTableRowIndex(this.get('historyRoutesEditTable'), row);
          selectedRow = this.get('historyRoutesTable.rows').content[index];
        } else {
          selectedRow = this.get('historyRoutesEditTable.rows').content[index];
        }

        this.mapTrip(row.content.tripId);
        row.set('selected', true);
        selectedRow.set('selected', true);
        Ember.set(this, 'lastRowClicked', row.get('content'));
      },

      onEditRowRightClick(row, event) {
        event.preventDefault();
      },

      onRowRightClick(row, event) {
        if (this.get('isEditClicked')) {
          return;
        }

        let rows = this.get('historyRoutesTable.rows');
        let selectedRow = rows.findBy('selected');

        if (selectedRow !== row) {
          rows = this.get('historyRoutesEditTable.rows');
          selectedRow = rows.findBy('selected');

          if (selectedRow !== row) {
            return;
          }
        }

        this.createContextMenu(row.content);
        let top = event.clientY;

        if (top + this.contextMenuHeight > Ember.$(window).height()) {
          top -= top + this.contextMenuHeight - Ember.$(window).height();
        }

        this.set('contextMenuContainerPosition', () => {
          return {
            style: {
              top: top,
              left: event.clientX
            }
          };
        });
        this.set('lastRowClicked', row.get('content'));
        this.get('contextMenuContainer').actions.open();
      },

      onEditClick() {
        if (this.get('isEditClicked') || this.isCompletedRoute) {
          return;
        }

        this.manageRowEditing();
      },

      onUndoClick() {
        if (!this.get('isEditClicked') || this.isCompletedRoute) {
          return;
        }

        const rows = this.get('historyRoutesEditTable.rows');
        const row = rows.findBy('selected');

        if (row) {
          const columns = row.get('columns');
          columns.map(column => {
            // if (column.get('isEditable')) {
            const oldValue = column.get('oldValue');
            const valuePath = column.get('valuePath');

            if (oldValue) {
              row.set(valuePath, oldValue);
            } // }

          });
          this.enableDisableEditing(row, false);
          this.enableDisableTableRows(true);
          this.set('isEditClicked', false); // this.manageRowEditing();
        }
      },

      onSaveClick() {
        if (this.isCompletedRoute) {
          return;
        } //save all the edit cells into the models


        const error = this.getValidationError();

        if (error) {
          this.showErrorToolTip('Error message', error);
        } else {
          this.handleSaveTrip();
        }
      },

      onColumnClick(column) {
        if (!this.isSortingAllowed) {
          return false;
        }

        const previousSortKey = Ember.get(this, 'currentSortId');
        const sortColumn = column.get('valuePath');

        if (sortColumn === previousSortKey) {
          column.set('ascending', false);
          this.toggleProperty('currentSortAsc');
          return;
        }

        const columns = this.get('historyRoutesEditTable.columns');
        const routesColumns = this.get('historyRoutesTable.columns');
        columns.forEach(col => {
          if (col.valuePath === sortColumn) {
            col.set('sorted', true);
            column.set('ascending', true);
          } else if (col.valuePath === previousSortKey) {
            col.set('sorted', false);
          }
        });
        routesColumns.forEach(col => {
          if (col.valuePath === sortColumn) {
            col.set('sorted', true);
            column.set('ascending', true);
          } else if (col.valuePath === previousSortKey) {
            col.set('sorted', false);
          }
        });
        Ember.set(this, 'currentSortId', sortColumn);
        Ember.set(this, 'currentSortAsc', true);
      },

      onScroll(offset) {
        Ember.set(this, 'scrollToOffset', offset);
        event.preventDefault();
      },

      onFilterButtonClick() {
        if (this.get('isEditClicked')) {
          return;
        }

        this.unselectRow();
        const columns = this.get('tableConfigColumns') || [];
        const workspace = this.get('workspace');
        const widget = this.get('widget');
        const displayName = `${this.config.title} Filters`; // close sidebar if _this_ widget's filter is currently open
        // @TODO: define and use unique keys rather than `displayName` for this

        if (displayName === workspace.get('topStateDisplayName')) {
          workspace.popState();
          return;
        }

        let topState = workspace.get('topState');

        while (topState === 'reconcileFilterColumnWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        workspace.pushState('reconcileFilterColumnWidget', {
          columns,
          widget,
          displayName
        });
      }

    }
  });

  _exports.default = _default;
});