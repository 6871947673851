define("adept-iq/pods/components/side-drawer/no-show-form/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      id: 'deny',
      title: 'Details',
      fields: [{
        id: 'note',
        type: 'text-area',
        editable: false,
        label: 'Note',
        valuePath: 'noshowDetails'
      }]
    }, {
      id: 'approve',
      title: 'Details',
      fields: [{
        id: 'noShowReason',
        type: 'text',
        label: 'Type',
        valuePath: 'noshowReason.name',
        editable: false
      }, {
        id: 'note',
        type: 'text-area',
        editable: true,
        label: 'Note',
        valuePath: 'noshowDetails'
      }]
    }]
  };
  _exports.default = _default;
});