define("adept-iq/pods/components/iq-widgets/routes-end-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/routes-end-form-widget/config", "adept-iq/config/mapped-permIds", "moment"], function (_exports, _component, _config, _mappedPermIds, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['routes-end-form-widget'],
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    store: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),

    allStopsPerformed(dispatchRoute) {
      let orderedStops = dispatchRoute.get('orderedStops');
      if (!orderedStops) return false;
      orderedStops = orderedStops.filterBy('isPickOrDrop'); // empty route

      if (!orderedStops || orderedStops.length === 0) return true;
      const allStopsPerformed = orderedStops.every(stop => stop.get('isPerformed') || stop.get('isNoShow'));
      return allStopsPerformed;
    },

    validateRecords() {
      const canUserSetRouteLimit = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.limitRoute);
      const routeMaxParam = this.get('store').peekRecord('cs-config-item', 'config-Route_parameters/routeMaxExtension');
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const plannedStartTime = (0, _moment.default)(record.get('dispatchRoute.plannedStartTime'));
        const plannedEndTime = (0, _moment.default)(record.get('dispatchRoute.plannedEndTime'));
        const actualStartTime = (0, _moment.default)(record.get('actualStartTimestamp'));
        const actualEndTime = (0, _moment.default)(record.get('timestamp'));
        const routeMaxExtension = routeMaxParam ? parseInt(routeMaxParam.get('value'), 10) : 60; // minutes
        //extensionUsed is a negative number if actualStartTime is before plannedStartTime (early start consuming extension time)

        const extensionUsed = Math.min(0, actualStartTime.diff(plannedStartTime, 'minutes'));
        const extensionRemaining = routeMaxExtension + extensionUsed;
        const extensionExceeded = actualEndTime.diff(plannedEndTime, 'minutes') > extensionRemaining;
        const lastPerformedStopTime = (0, _moment.default)(record.get('dispatchRoute.lastDropStop.computedActualDepartTime'));
        const allStopsPerformed = this.allStopsPerformed(record.get('dispatchRoute'));
        record.set('extensionExceeded');

        if (lastPerformedStopTime.isSameOrAfter(actualEndTime)) {
          this.get('editModal.errors').pushObject('The route end time must be after the time of last performed stop');
          valid = false;
        } // if we have the permission, we don't show the warning, instead the confirm tooltip will be altered in routes-widget


        if (extensionExceeded && !canUserSetRouteLimit) {
          this.get('editModal.errors').pushObject(`The route end time may not exceed the planned Route End time by more than ${extensionRemaining} minutes.`);
          valid = false;
        }

        if (!allStopsPerformed) {
          this.get('editModal.errors').pushObject('Route end time cannot be set until all stops performed');
          valid = false;
        }

        record.set('odometer', null); // extension exceeded and user have permission to overide

        if (extensionExceeded && canUserSetRouteLimit) record.set('extensionExceeded', extensionExceeded);
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {},

      onApplyClick() {
        if (this.validateRecords()) {
          const title = this.get('title');
          this.get('service').apply(title);
        }
      }

    }
  });

  _exports.default = _default;
});