define("adept-iq/pods/components/iq-widgets/subscription-form/passenger-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedRiders: null,
    booking: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('selectedRiders', this.get('booking.selectedRiders'));
    },

    actions: {
      onNumberChange(value) {
        const phoneArray = value.trim().split('-');
        this.get('onNumberChange')(phoneArray);
      }

    }
  });

  _exports.default = _default;
});