define("adept-iq/utils/sorts", ["exports", "adept-iq/config/comparison-functions"], function (_exports, _comparisonFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildCompareFunction = buildCompareFunction;

  function buildCompareFunction(params) {
    return (a, b) => {
      const cmp = Ember.makeArray(params).reduce((acc, {
        path,
        asc,
        compare
      }) => {
        if (acc !== 0) return acc;
        const sign = asc ? 1 : -1;
        const aValue = a.get ? a.get(path) : a[path];
        const bValue = b.get ? b.get(path) : b[path];
        return sign * compare(aValue, bValue);
      }, 0); // tie breaker uses `guidFor`

      if (cmp === 0) {
        return (0, _comparisonFunctions.nullCompare)(a, b);
      }

      return cmp;
    };
  }
});