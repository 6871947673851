define("adept-iq/pods/components/reoptimize-modal/operation-views/release-routes/component", ["exports", "ember-concurrency", "adept-iq/mixins/async-schedule-operation"], function (_exports, _emberConcurrency, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    classNames: ['release-routes'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    isTaskRunning: Ember.computed.oneWay('releaseRoutes.isRunning'),
    // Passed In Attributes
    title: null,
    currentSchedule: null,
    tableRef: null,
    refreshData: null,

    init() {
      this._super(...arguments);
    },

    // be able to adjust from selected rows
    releaseRoutes: (0, _emberConcurrency.task)(function* () {
      const schedule = this.get('currentSchedule');
      const notifications = this.get('notifications');
      const workspace = this.get('workspace');

      try {
        workspace.set('reoptimizeInProgress', true);
        workspace.set('triggeredReoptimize', true);
        let res = yield this.createScheduleAsyncOperation.perform(schedule, 'export');
        const operationData = {
          'query': 'scheduled'
        };
        const options = {
          operationData
        };
        res = res ? yield this.createScheduleAsyncOperation.perform(schedule, 'brokerExport', options) : false;
        workspace.set('reoptimizeInProgress', false);
        workspace.set('triggeredReoptimize', false);

        if (res) {
          notifications.success('Routes successfully released.');
          workspace.popState('reoptimize');
        } else {
          notifications.warning('Routes failed to release.');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          // re-throw the non-cancelation error
          notifications.warning('Routes failed to release.');
          throw e;
        }
      } //after the releaseRoutes reset the  workspace operationType
      finally {
        workspace.set('operationType', null);
      }
    }).drop(),
    actions: {
      onReleaseRoutesClick() {
        const tooltip = this.get('tooltip');
        tooltip.pushConfirmation({
          title: 'Release Routes',
          hasOverlay: true,
          tip: 'Are you sure you want to release the routes? Once released, the routes will be unavailable for editing in Scheduling and dispatchers will be allowed to work with the routes.',
          primaryActionText: 'Confirm',
          primaryAction: () => {
            tooltip.reset();
            return this.releaseRoutes.perform();
          },
          secondaryActionText: 'Cancel',

          secondaryAction() {
            tooltip.reset();
          }

        });
      }

    }
  });

  _exports.default = _default;
});