define("adept-iq/pods/components/reoptimize-modal/operation-views/remove-driver-break/component", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/mixins/async-schedule-operation"], function (_exports, _emberConcurrency, _unwrapProxy, _asyncScheduleOperation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const REMOVE_DRIVER_BREAK_FAILED = 'Failed';

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    classNames: ['remove-driver-break'],
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    // Passed In Attributes
    title: null,
    currentSchedule: null,
    tableRef: null,
    toggleDisableActions: null,
    isTaskRunning: Ember.computed.or('removeSelectedRouteDriverBreaks.isRunning', 'hypoRemoveBreakTask.isRunning'),
    checkedRows: Ember.computed.readOnly('tableRef.checkedRows'),
    hasRowsSelected: Ember.computed.lt('checkedRows.length', 1),
    disableApplyButton: Ember.computed.or('hasRowsSelected', 'isTaskRunning'),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('currentAPITask', this.get('removeSelectedRouteDriverBreaks'));
      this.hypoRemoveBreakTask.perform();
    },

    hypoRemoveBreakTask: (0, _emberConcurrency.task)(function* () {
      const tableRef = this.get('tableRef');
      const currentSchedule = this.get('currentSchedule');
      const store = this.get('store');

      try {
        yield store.findAll('route-break');
        const {
          isJobSuccess,
          results
        } = yield this.createScheduleAsyncOperation.perform(currentSchedule, 'routeRemoveBreaks', {
          outputResult: true
        });

        if (isJobSuccess) {
          const result = JSON.parse(results);
          result.shortRoutes.forEach(shortRoute => {
            const route = store.peekRecord('route', shortRoute.routeId);

            if (route) {
              route.setProperties({
                adjustedStartTime: shortRoute.adjustedStartTime,
                adjustedEndTime: shortRoute.adjustedEndTime,
                isDriverBreakRemoved: false
              });
            }
          });
          if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
        } else {
          throw new Error(results);
        }
      } catch (e) {
        this.get('notifications').warning('Failed to retrieve short routes');
      }
    }),

    /**
     *remove route-breaks to a selected routes
     *
     */
    removeSelectedRouteDriverBreaks: (0, _emberConcurrency.task)(function* (routes) {
      const tableRef = this.get('tableRef');
      const currentSchedule = this.get('currentSchedule');
      const routeBreaks = this.getRouteBreaks(routes);
      let failedRouteBreaksCount = 0;
      yield routeBreaks.reduce(async (previousPromise, driverBreak) => {
        const routeBreakId = Number(driverBreak.get('id'));
        const operationData = {
          routeBreakId
        };
        const options = {
          operationData,
          outputResult: true
        };

        try {
          const {
            isJobSuccess
          } = await this.createScheduleAsyncOperation.perform(currentSchedule, 'cancelRouteBreak', options);

          if (!isJobSuccess) {
            throw new Error();
          } else {
            return Promise.resolve();
          }
        } catch (e) {
          ++failedRouteBreaksCount;
          const route = (0, _unwrapProxy.unwrapProxy)(driverBreak.content.firstObject.route);
          route.set('result', REMOVE_DRIVER_BREAK_FAILED);
          return Promise.reject();
        }
      }, Promise.resolve()).then(() => {
        this.get('notifications').success('Driver breaks successfully removed');
      }).catch(() => {
        this.get('notifications').warning(`${failedRouteBreaksCount} Failed to remove Driver breaks`);
      }).finally(() => {
        routes.filter(r => Ember.isEmpty(r.result)).forEach(route => route.set('isDriverBreakRemoved', true));
        tableRef.refreshData(); // driver breaks removed or failed re-fetch  the records

        tableRef.onUncheckAll();
      });
    }).drop(),

    getRouteBreaks(routes) {
      return routes.reduce((acu, route) => {
        const breaks = route.get('routeBreaks') ? route.get('routeBreaks').toArray() : [];
        acu.push(...breaks);
        return acu;
      }, []);
    },

    actions: {
      onRemoveClick() {
        const routes = this.get('checkedRows') || [];
        this.removeSelectedRouteDriverBreaks.perform(routes);
      }

    }
  });

  _exports.default = _default;
});