define("adept-iq/pods/components/iq-widgets/permissions-users-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "adept-iq/pods/components/iq-widgets/permissions-users-widget/config", "adept-iq/config/mapped-permIds", "ember-concurrency", "ember-light-table", "papaparse", "moment"], function (_exports, _component, _config, _mappedPermIds, _emberConcurrency, _emberLightTable, _papaparse, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const toPixels = x => `${x}px`;

  var _default = _component.default.extend({
    classNames: ['column-widget'],
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    notifications: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    config: _config.default,
    isSearchAllowed: true,
    tableActions: null,
    allowedTableActions: null,
    downloadAction: null,
    singleActions: null,
    bulkActions: null,
    useBulkActions: Ember.computed.gt('checkedRowsCount', 1),
    showBulkActions: Ember.computed.and('useBulkActions', 'isRowInCheckedRows'),
    showSingleActions: Ember.computed.equal('showBulkActions', false),
    disableRightClick: Ember.computed.or('workspace.{isExported,reoptimizeInProgress}'),
    checkedRowsCount: Ember.computed('selectedUsers', function () {
      return this.get('selectedUsers') ? this.get('selectedUsers').length : 0;
    }),
    checkedItemsCount: Ember.computed.alias('checkedRowsCount'),
    selectedUsers: Ember.computed.alias('permissionsUsersTable.selectedRows.[]'),
    isRowInCheckedRows: Ember.computed('lastRowClicked', 'selectedUsers', function () {
      const lastRowClicked = this.get('lastRowClicked');
      const users = this.get('selectedUsers');

      if (!lastRowClicked) {
        return false;
      }

      return users.some(userRow => userRow.get('content.id') === lastRowClicked.get('id'));
    }),
    isFiltered: Ember.computed('permissionsUsersTable.columns.@each.{isFiltered}', function () {
      return this.get('permissionsUsersTable.columns').isAny('isFiltered');
    }),
    checkedItems: Ember.observer('checkedItemsCount', 'activeContext.topActiveContext.deselectAll', function () {
      if (this.get('activeContext.topActiveContext.deselectAll')) {
        this.handleCheckUncheckAll(false);
        this.set('activeContext.topActiveContext.deselectAll', false);
        return;
      }

      if (!this.get('selectedUsers').length) {
        const rows = this.get('permissionsUsersTable.rows');
        rows.forEach(_row => {
          if (_row && _row.get('isChecked') === true) {
            this.get('selectedUsers').push(_row);
          }
        });
      }

      this.set('activeContext.topActiveContext.checkedItems', this.get('selectedUsers'));
    }),
    onWidgetStateChange: Ember.observer('widget.state', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateConfigColumns');
    }),

    init() {
      this._super(...arguments);

      this.setupTable();
      this.setupDefaultTableAction();
      this.configureSearchFilterPermission();
      this.getUsers();
    },

    didInsertElement() {
      this.createContextMenu();
    },

    setupTable() {
      const permissionsUsersTable = new _emberLightTable.default(this.config.columns);
      Ember.set(this, 'permissionsUsersTable', permissionsUsersTable);
      this.updateColumnsForFilter();
      this.setupSearchableColumns();
    },

    updateColumnsForFilter() {
      const columns = [];
      const tableColumns = this.config.columns;

      if (!Ember.isEmpty(tableColumns)) {
        tableColumns.forEach(column => {
          if (!column.unAvailable) {
            const col = Ember.Object.create(column);
            col.set('filterTypes', this.config.filters[column.dataType] || []);
            columns.push(col);
          }
        });
      }

      Ember.set(this, 'tableConfigColumns', columns);
    },

    setupSearchableColumns() {
      const searchableColumns = [];
      this.config.columns.map(item => {
        searchableColumns.push(item.valuePath);
      });
      Ember.set(this, 'searchableColumns', searchableColumns);
    },

    getUsers() {
      Ember.get(this, 'fetchUsersTask').perform();
    },

    setupDefaultTableAction() {
      const downloadAction = {
        id: 'print',
        permIds: [_mappedPermIds.default.downloadUsers],
        name: 'Download',
        action: () => {
          this.exportData();
        }
      };
      this.set('downloadAction', downloadAction); // eslint-disable-next-line new-cap

      this.set('tableActions', Ember.A([downloadAction]));
      this.set('allowedTableActions', this.get('tableActions'));
    },

    configureSearchFilterPermission() {
      const configPermissions = this.get('workspace.configPermissions');
      const widgetTypeId = this.get('widget.typeId');

      if (!Ember.isEmpty(configPermissions)) {
        const configPermission = configPermissions[widgetTypeId];

        if (!Ember.isEmpty(configPermission.searchAllowed)) {
          this.set('isSearchAllowed', configPermission.searchAllowed);
        }

        if (!Ember.isEmpty(configPermission.filterAllowed)) {
          this.set('isFilterAllowed', configPermission.filterAllowed);
        }
      }
    },

    createContextMenu() {
      this.set('tableActions', this.createTableActions());
      this.set('singleActions', this.createSingleActions());
      this.set('bulkActions', this.createBulkActions());
    },

    isLoggedInUserSelected() {
      let isExists = false;
      this.selectedUsers.map(user => {
        if (user.content.id === this.session.data.authenticated.userId) {
          isExists = true;
        }
      });
      return isExists;
    },

    handleUserLogout() {
      if (this.isLoggedInUserSelected()) {
        this.get('session').invalidate();
      }
    },

    contextMenuHandler(optionId, isSaved) {
      if (isSaved) {
        if (this.isLoggedInUserSelected()) {
          this.handleUserLogout();
        } else {
          this.unloadSelectedUsers();
          this.getUsers();
        }
      }

      if (!optionId) {
        return;
      }
    },

    createTableActions() {
      return [{
        id: 'permissionsAddNewUser',
        permId: _mappedPermIds.default.newUser,
        name: 'Add User',
        action: () => {
          this.get('workspace').pushState('permissionsAddNewUser', {
            action: this.contextMenuHandler.bind(this, 'permissionsAddNewUser'),
            userRecords: this.get('records')
          });
        }
      }, {
        id: 'print',
        permIds: _mappedPermIds.default.downloadUsers,
        name: 'Download CSV',
        action: () => {
          this.exportData();
        }
      }];
    },

    createSingleActions() {
      return [{
        id: 'permissionsEditUser',
        permId: _mappedPermIds.default.editUser,
        name: 'Edit User',
        action: user => {
          this.get('workspace').pushState('permissionsEditUser', {
            user,
            action: this.contextMenuHandler.bind(this, 'permissionsEditUser'),
            userRecords: this.get('records')
          });
        }
      }, {
        id: 'delete',
        permId: _mappedPermIds.default.deleteUser,
        name: 'Delete User',
        action: () => {
          this.showToolTip(true);
        }
      }];
    },

    createBulkActions() {
      return [{
        id: 'bulkDelete',
        permId: _mappedPermIds.default.deleteUser,
        name: 'Bulk Delete Users',
        action: () => {
          this.showToolTip(false);
        }
      }];
    },

    exportData() {
      const table = this.get('permissionsUsersTable');
      const columns = table.get('columns') || [];
      const rows = table.get('rows') || [];
      const fields = columns.reduce((arr, column) => {
        const id = column.get('id');

        if (['checkbox', 'spacer', 'radio', 'id'].includes(id)) {
          return arr;
        }

        const label = column.get('label');
        arr.push(label);
        return arr;
      }, []);
      const data = rows.map(row => {
        return columns.reduce((arr, column) => {
          const id = column.get('id');

          if (id !== 'id') {
            if (['checkbox', 'spacer', 'radio'].includes(id)) {
              return arr;
            }

            const valuePath = column.get('valuePath');
            const value = row.get(valuePath);
            arr.push(value);
          }

          return arr;
        }, []);
      });

      const csv = _papaparse.default.unparse({
        fields,
        data
      }, {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"'
      });

      const link = document.createElement('a');
      const timestamp = (0, _moment.default)().format('YYYY-MM-DD_HH-MM-SS');
      const {
        title
      } = this.get('config');
      const filename = `${timestamp}_${title}.csv`;
      const blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      });
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    filterUserData(searchText) {
      const tableName = this.get('permissionsUsersTable');

      if (!Ember.isEmpty(tableName)) {
        const table = tableName;
        const tableColumns = Ember.get(this, 'searchableColumns');
        const search = searchText !== null ? searchText.trim().toLowerCase().split('=') : [];
        const resultModel = [];
        this.get('records').map(item => {
          if (Ember.isEmpty(searchText)) resultModel.push(item);

          if (search && search.length > 1 && search[1] && search[1] !== '' && tableColumns.join(',').toLowerCase().includes(search[0].trim().toLowerCase().replace(' ', ''))) {
            const colm = tableColumns.map(column => column.toLowerCase());
            const index = colm.indexOf(search[0].trim().toLowerCase().replace(' ', ''));
            if (index !== -1 && item[tableColumns[index]] && item[tableColumns[index]].toString().toLowerCase().indexOf(search[1].trim().toLowerCase()) !== -1) resultModel.push(item);
          } else if (search.length === 1) tableColumns.filter(column => {
            if (item[column] && item[column].toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
              resultModel.push(item);
              return;
            }
          });
        });

        if (resultModel) {
          const rows = [...new Set(resultModel)];
          table.setRowsSynced(rows.toArray());
        }

        const rows = this.get('permissionsUsersTable.rows');
        rows.forEach(_row => {
          if (_row && _row.get('isChecked') === true) {
            _row.set('selected', true);
          }
        });
      }
    },

    showToolTip(isSingleUser) {
      const tooltip = this.get('tooltip');
      const title = isSingleUser ? 'Delete User' : 'Bulk Delete Users';
      const tip = isSingleUser ? 'Do you want to delete this user?' : 'Do you want to bulk delete users?';
      tooltip.pushConfirmation({
        tip,
        title,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          return Ember.get(this, 'deleteUserTask').perform().then(isDeleted => {
            if (isDeleted) {
              this.deleteUserCallback();
              this.get('notifications').success(isSingleUser ? 'DELETED USER SUCCESSFULLY' : 'BULK DELETED USERS SUCCESSFULLY');
            }

            tooltip.reset();
          }).catch(error => {
            let message;

            switch (error.status) {
              case 400:
                message = 'There was a problem with one of the fields. Please check over the form and try again.';
                break;

              default:
                message = 'There was a problem in delete user, please try again.';
                break;
            }

            this.get('notifications').warning(message);
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        },
        hasOverlay: true
      });
    },

    unloadUser(id) {
      const record = this.store.peekRecord('perm-sso-user', id);
      this.store.unloadRecord(record);
    },

    unloadSelectedUsers() {
      this.selectedUsers.map(userRow => {
        this.unloadUser(userRow.content.id);
      });
    },

    deleteUserCallback() {
      if (this.isLoggedInUserSelected()) {
        this.handleUserLogout();
      } else {
        this.unloadSelectedUsers();
        this.getUsers();
      }
    },

    updateConfigColumns() {
      const state = this.get('widget.state') || {};
      const columns = this.get('tableConfigColumns') || [];
      let shouldResize = false;
      const scrollLeft = this.getScrollLeft();
      const columnsHash = state.columns || {};
      Object.entries(columnsHash).forEach(([id, columnState]) => {
        const column = columns.findBy('id', id);

        if (!column) {
          return;
        }

        if (Ember.isPresent(columnState.hidden)) {
          column.set('hidden', columnState.hidden);
          const actualColumn = this.getUserTableColumn(column);

          if (actualColumn) {
            actualColumn.set('hidden', columnState.hidden);
          }

          shouldResize = true;
        }

        const wasFiltered = column.get('isFiltered');
        ['filterTypeId', 'filterValues', 'filterType'].forEach(prop => {
          // allow null value to overwrite
          if (!columnState.hasOwnProperty(prop)) {
            return;
          }

          column.set(prop, columnState[prop]);
        });

        if (wasFiltered || column.get('isFiltered')) {
          Ember.run.scheduleOnce('afterRender', this, 'refreshUsers');
        }

        if (Ember.isPresent(columnState.width)) {
          column.set('width', toPixels(columnState.width));
          const actualColumn = this.getUserTableColumn(column);

          if (actualColumn) {
            actualColumn.set('width', toPixels(columnState.width));
          }

          shouldResize = true;
        }
      });

      if (shouldResize) {
        // resize at end of _next_ loop so that all changes are applied
        Ember.run.next(() => {
          if (Ember.isPresent(scrollLeft)) {
            // wait an additional loop before restoring scroll position
            Ember.run.next(() => {
              Ember.run.scheduleOnce('afterRender', this, 'setScrollLeft', scrollLeft);
            });
          }
        });
      }

      Ember.run.debounce(this, 'refreshUsers', 250);
    },

    getUserTableColumn(configColumn) {
      let column = null;
      const configColumns = this.config.columns;
      const configTableColumn = configColumns.filter(configCol => configCol.valuePath === configColumn.id)[0];

      if (configTableColumn) {
        const tableColumns = Ember.get(this, 'permissionsUsersTable.columns');
        column = tableColumns.filter(col => col.valuePath === configColumn.id)[0];
      }

      return column;
    },

    getScrollLeft() {
      const body = this.$('.column-widget-body');

      if (body) {
        return body.scrollLeft();
      }

      return 0;
    },

    setScrollLeft(scrollLeft) {
      if (!this.$('.column-widget-body')) {
        return;
      }

      this.$('.column-widget-body').scrollLeft(scrollLeft);
    },

    getFilteredColumns(columns) {
      return columns.filter(column => {
        if (!Ember.isEmpty(column.filterType)) {
          if (column.filterType.id === 'empty') {
            return true;
          } else if (!Ember.isEmpty(column.filterValues) && column.filterValues.filter(item => !Ember.isEmpty(item)).length) {
            return true;
          }
        }

        return false;
      });
    },

    refreshUsers() {
      const columns = this.get('tableConfigColumns') || [];
      const tableData = this.get('records') || [];
      const resultModel = [];

      if (columns && columns.length === 1 && columns[0].hidden === true) {
        const rows = [];
        const userTable = Ember.get(this, 'permissionsUsersTable');
        userTable.setRowsSynced(rows);
        return;
      }

      if (!Ember.isEmpty(tableData)) {
        const searchColumns = this.getFilteredColumns(columns);
        tableData.map(item => {
          if (Ember.isEmpty(searchColumns)) resultModel.push(item);else {
            let bool = true;
            searchColumns.forEach(column => {
              if (column.filterType && column.filterType.exp && column.filterType.exp(item[column.valuePath], column.filterValues)) return;
              bool = false;
            });
            if (bool) resultModel.push(item);
          }
        });

        if (resultModel) {
          const userTable = Ember.get(this, 'permissionsUsersTable');
          const rows = [...new Set(resultModel)];
          userTable.setRowsSynced(rows.toArray());
        }

        const rows = this.get('permissionsUsersTable.rows');
        rows.forEach(_row => {
          if (_row && _row.get('isChecked') === true) {
            _row.set('selected', true);
          }
        });
      }
    },

    handleCheckUncheckAll(isChecked) {
      const rows = this.get('permissionsUsersTable.rows');
      rows.setEach('selected', isChecked);
      rows.setEach('isChecked', isChecked);
    },

    deleteUserTask: (0, _emberConcurrency.task)(function* () {
      try {
        const store = this.get('store');
        const userAdapter = store.adapterFor('perm-sso-user');

        if (!Ember.isEmpty(this.selectedUsers)) {
          for (let i = 0; i < this.selectedUsers.length; i++) {
            yield userAdapter.deleteUser(this.selectedUsers[i].content.id);
          }

          return true;
        }

        return false;
      } catch (error) {
        throw error;
      }
    }),
    fetchUsersTask: (0, _emberConcurrency.task)(function* () {
      const users = [];
      const usersData = yield this.get('store').query('perm-sso-user', {});
      Ember.set(this, 'records', users);
      Ember.get(this, 'permissionsUsersTable').setRows(users);

      if (!Ember.isEmpty(usersData)) {
        usersData.map(user => {
          if (user.providerNamesStr && user.providerNamesStr.includes('ALL')) {
            user.set('providerNamesStr', 'ALL');
          }

          user.set('isChecked', false);
          users.push(user);
        });
        Ember.set(this, 'records', users);
        Ember.get(this, 'permissionsUsersTable').setRows(users);
      }
    }).restartable(),
    actions: {
      onScroll() {},

      onColumnClick() {},

      onCheckAll() {
        this.handleCheckUncheckAll(true);
      },

      onUncheckAll() {
        this.handleCheckUncheckAll(false);
      },

      onRowClick(row) {
        this.set('lastRowClicked', row.get('content'));
        const rows = this.get('permissionsUsersTable.rows');

        if (row.get('content') && row.get('content.isCheckboxChecked')) {
          row.set('isCheckboxChecked', false);
          rows.forEach(_row => {
            if (_row.get('id') === row.get('id')) {
              if (_row.get('selected')) {
                _row.set('selected', true);

                _row.set('isChecked', true);
              } else {
                _row.set('selected', false);

                _row.set('isChecked', false);
              }
            }
          });
        } else {
          rows.forEach(_row => {
            if (_row.get('id') === row.get('id')) {
              if (_row.get('selected')) {
                _row.set('isChecked', false);

                _row.set('selected', false);
              } else {
                _row.set('selected', true);

                _row.set('isChecked', true);
              }
            }
          });
        }
      },

      onRowRightClick(row, event) {
        const disableRightClick = this.get('disableRightClick');
        if (disableRightClick) return; // disable right row click for iq-widgets when a user runs schedule generation or release routes.

        this.set('inlineRowMenuPosition', () => {
          return {
            style: {
              top: event.clientY,
              left: event.clientX
            }
          };
        });
        this.set('lastRowClicked', row.get('content'));

        if (row.get('content.isChecked')) {
          this.inlineRowMenu.actions.open();
        }
      },

      setInlineRowMenu(dropdown) {
        this.set('inlineRowMenu', dropdown);
      },

      setPlusIconMenu(dropdown) {
        this.set('plusIconMenu', dropdown);
      },

      onSingleRowAction(action) {
        const model = this.get('lastRowClicked');
        this.inlineRowMenu.actions.close();

        if (action.action) {
          action.action(model);
        }
      },

      onBulkRowAction(action) {
        const models = [];
        this.selectedUsers.map(row => {
          models.push(row.get('content'));
        });
        this.inlineRowMenu.actions.close();

        if (action.action) {
          action.action(models);
        }
      },

      onSearchButtonClick() {
        // this.send('onSearchTextChange', '');
        this.toggleProperty('isSearchEnabled');
        Ember.run.schedule('afterRender', () => {
          if (this.get('isSearchEnabled')) {
            this.$('.column-widget-search-box').focus();
          }
        });
      },

      onRemoveSearchClick() {
        this.send('onSearchTextChange', '');
        this.toggleProperty('isSearchEnabled');
        this.$('.column-widget-search-button').focus();
      },

      onSearchTextChange(searchText) {
        Ember.run.debounce(this, 'filterUserData', searchText, 300);
      },

      onPlusIconClick() {
        const disableRightClick = this.get('workspace.reoptimizeInProgress');
        return !disableRightClick;
      },

      onTableActionClick(action, dropdown) {
        dropdown.actions.close();

        if (action.action) {
          action.action();
        }
      },

      onFilterButtonClick() {
        const columns = this.get('tableConfigColumns') || [];
        const workspace = this.get('workspace');
        const widget = this.get('widget');
        const displayName = `${this.config.title} Filters`; // close sidebar if _this_ widget's filter is currently open
        // @TODO: define and use unique keys rather than `displayName` for this

        if (displayName === workspace.get('topStateDisplayName')) {
          workspace.popState();
          return;
        }

        let topState = workspace.get('topState');

        while (topState === 'permissionsFilterColumnWidget') {
          workspace.popState();
          topState = workspace.get('topState');
        }

        workspace.pushState('permissionsFilterColumnWidget', {
          columns,
          widget,
          displayName
        });
      },

      onHeaderDoubleClick() {
        const tableRows = this.get('permissionsUsersTable.rows') || [];
        tableRows.setEach('selected', false);
        tableRows.setEach('isChecked', false);
        this.get('permissionsUsersTable').setRowsSynced(tableRows.toArray());
        const maximizer = this.get('maximizer');

        if (maximizer.maximizedWidget === this.get('widget')) {
          maximizer.minimize();
          return;
        }

        maximizer.maximize(this.get('widget'));
      },

      doubleClick(event) {
        event.preventDefault();

        if (event.target.tagName === 'INPUT' && event.target.type === 'checkbox') {
          // allow user to check another row without moving selection
          return;
        }

        const ltCell = event.target.closest('.lt-cell');

        if (ltCell) {
          const row = ltCell.closest('.lt-row');
          const index = Array.from(row.children).indexOf(ltCell);
          const recordId = row.getAttribute('data-record-id');
          const tableRows = this.get('permissionsUsersTable.rows') || [];
          const clickedColumn = this.get('permissionsUsersTable.columns')[index];
          const clickedRow = tableRows.findBy('content.id', recordId);
          tableRows.setEach('selected', false);
          const scheduleLocked = clickedRow.get('record.scheduleLocked');

          if (!scheduleLocked || Ember.isNone(scheduleLocked)) {
            clickedRow.set('isEditing', true);
            clickedColumn.set('isEditing', true);
          }
        }
      }

    }
  });

  _exports.default = _default;
});