define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/suspension/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DRIVER_SUSPENSION_VALIDATION = void 0;
  const DRIVER_SUSPENSION_VALIDATION = {
    description: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Description',
      message: '{description} can not be blank'
    })],
    startDate: [(0, _validators.validatePresence)({
      presence: true,
      description: 'Start Date',
      message: '{description} can not be blank'
    })],
    endDate: [(0, _validators.validatePresence)({
      presence: true,
      description: 'End Date',
      message: '{description} can not be blank'
    })]
  };
  _exports.DRIVER_SUSPENSION_VALIDATION = DRIVER_SUSPENSION_VALIDATION;
});