define("adept-iq/serializers/route", ["exports", "adept-iq/serializers/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    attrs: {
      otp: {
        serialize: false
      },
      otpValue: {
        serialize: false
      },
      odometer: {
        serialize: false
      },
      actualStartTimestamp: {
        serialize: false
      },
      actualEndTimestamp: {
        serialize: false
      }
    },

    serialize()
    /*snapshot, options*/
    {
      const json = this._super(...arguments); // because we modify status coming in from route-exec-event
      // scheduling service only accepts normal or breakdown
      // we don't want to PATCH/PUT the status. This is controlled by
      // the service
      // we don't want to PATCH the isAdjusted.


      if (json.data.id && json.data.attributes) {
        delete json.data.attributes.status;
        delete json.data.attributes.isAdjusted;
        delete json.data.attributes.hasRouteTemplateAdjusted;
        delete json.data.attributes.hasRouteTemplateConsolidated;
        delete json.data.attributes.isDriverBreakRemoved;
      } // affinites must be converted into numbers to be valid
      // safest conversion is using JSON.parsed for "[1,2,3]" -> [1,2,3]


      if (json.data.attributes.affinities) {
        json.data.attributes.affinities = JSON.parse(json.data.attributes.affinities);
      }

      if (json.data && json.data.relationships) {
        delete json.data.relationships.dispatchRoute;
        delete json.data.relationships.provider;
        delete json.data.relationships.etaRoute;
        delete json.data.relationships.navigationPolyline;
        delete json.data.relationships.scheduledPolyline;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.relationships) && !Ember.isNone(resourceHash.relationships.providerName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.provider = {
          data: {
            type: 'provider',
            id: resourceHash.relationships.providerName.data.id
          }
        };
        resourceHash.relationships = relationships;
      }

      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.providerName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.provider = {
          data: {
            type: 'provider',
            id: resourceHash.attributes.providerName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.providerName;
      }

      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.routeTemplateId)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.routeTemplate = {
          data: {
            type: 'route-template',
            id: resourceHash.attributes.routeTemplateId
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.routeTemplateId;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeWidgetRecord(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      const relationships = payload.data.relationships ? payload.data.relationships : {};
      payload.data.relationships = relationships; // TODO: we need to store navigation polyline from widgetRecord

      return payload;
    }

  });

  _exports.default = _default;
});