define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/road-call/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "moment", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/road-call/road-call-validation", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/road-call/road-call-route-search-config", "adept-iq/config/api-urls", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/vehicle/edit-views/road-call/road-call-driver-search-config", "adept-iq/config/placeholders"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _moment, _roadCallValidation, _roadCallRouteSearchConfig, _apiUrls, _roadCallDriverSearchConfig, _placeholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fields = {
    callDate: '',
    mileage: '',
    notes: '',
    newVehicleId: ''
  };
  const dropDownFields = {
    roadCallCodeName: '',
    driver: '',
    route: ''
  };

  var _default = Ember.Component.extend({
    classNames: ['road-call-view'],
    store: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    disableBtns: null,
    minFixAccepted: null,
    minFixDate: null,
    requestTime: null,
    startTime: null,
    vehicleInspector: null,
    maintenanceStatus: null,
    maintenanceType: null,
    maintenanceLocation: null,
    ProblemIdentified: null,
    selectedVal: null,
    maxLength: null,
    refresh: true,
    showErrorMsg: false,
    isNewRecord: false,
    disableForm: true,
    newVehicleInput: false,
    deleteBtn: true,
    timeFormat: 'm/d/Y',
    // vehicle Road Call Placeholders
    callDatePlaceholder: _placeholders.US_DATE_PLACEHOLDER,
    codePlaceholder: _placeholders.ROAD_CALL_CODE_PLACEHOLDER,
    codeDescriptionPlaceholder: _placeholders.ROAD_CALL_CODE_DESCRIPTION_PLACEHOLDER,
    driverIdPlaceholder: _placeholders.DRIVER_ID_PLACEHOLDER,
    driverLastNamePlaceholder: _placeholders.DRIVER_LAST_NAME_PLACEHOLDER,
    driverFirstNamePlaceholder: _placeholders.DRIVER_FIRST_NAME_PLACEHOLDER,
    driverCdlNumberPlaceholder: _placeholders.DRIVER_CDL_PLACEHOLDER,
    routeIdPlaceholder: _placeholders.ROUTE_ID_PLACEHOLDER,
    mileagePlaceholder: _placeholders.MILEAGE_PLACEHOLDER,
    notesPlaceholder: _placeholders.NOTES_PLACEHOLDER,
    newVehiclePlaceholder: _placeholders.ROAD_CALL_NEW_VEHICLE_PLACEHOLDER,
    // Passed into the table view
    editAPI: null,
    selectedVehicle: Ember.computed.alias('editAPI.selectedVehicle'),
    selectedRoadCall: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),

    async init() {
      this._super(...arguments);

      this.setupDropDownValues();
      this.refreshTask.perform();
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI');
      const onSaveRoadCallRecord = this.get('onSaveRoadCallRecord');
      const onNewRoadCallRecord = this.get('onNewRoadCallRecord').bind(this);
      const onEditRoadCallRecord = this.get('onEditRoadCallRecord').bind(this);
      const onDeleteRoadCallRecord = this.get('onDeleteRoadCallRecord');
      const onUndoRoadCallRecord = this.get('onUndoRoadCallRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveRoadCallRecord,
          newAction: onNewRoadCallRecord,
          deleteAction: onDeleteRoadCallRecord,
          editAction: onEditRoadCallRecord,
          undoAction: onUndoRoadCallRecord
        });
      });
      this.set('newVehicleInput', false);
      this.roadCallbuttonSettings();
    },

    roadCallbuttonSettings(deleteBtn = false, newBtn = false, editBtn = false, undoBtn = true, saveBtn = true) {
      const editAPI = this.get('editAPI');
      editAPI.setProperties({
        deleteBtn,
        editBtn,
        saveBtn,
        undoBtn,
        newBtn
      });
    },

    roadCallChangeSet: Ember.computed('selectedRoadCall', 'refresh', function () {
      const roadCall = (0, _unwrapProxy.unwrapProxy)(this.get('selectedRoadCall'));

      if (roadCall && roadCall.get('callDate')) {
        this.selectiveDateChange(roadCall);
      }

      return this.setDefaultProperties(roadCall);
    }),
    disableRouteButton: Ember.computed('roadCallChangeSet.{callDate}', function () {
      const callDate = this.get('roadCallChangeSet.callDate');

      if (Ember.isEmpty(callDate)) {
        return true;
      }
    }),
    disableNewRoadCallButton: Ember.computed('roadCallChangeSet.{newVehicleId}', function () {
      const vehicleId = this.get('roadCallChangeSet.newVehicleId');

      if (Ember.isEmpty(vehicleId)) {
        return true;
      }
    }),

    async selectiveDateChange(roadCallChangeSet) {
      const roadCallDate = roadCallChangeSet.get('callDate');
      const requestCallDate = (0, _moment.default)(roadCallDate).format('MM/DD/YYYY');
      roadCallChangeSet.set('callDate', requestCallDate);
    },

    setDefaultProperties(record = []) {
      const store = this.get('store');
      let vehicleRoadCallRecord = record;

      if (Ember.isEmpty(vehicleRoadCallRecord) && this.get('isNewRecord')) {
        vehicleRoadCallRecord = store.createRecord('vehicle-road-call');
        this.set('vehicleRoadCallRecord', vehicleRoadCallRecord);
      } else {
        Ember.run.scheduleOnce('afterRender', this, 'roadCallbuttonSettings');
      }

      if (Ember.isEmpty(vehicleRoadCallRecord)) {
        vehicleRoadCallRecord = Ember.Object.create(fields, dropDownFields);
      }

      this.set('showErrorMsg', false);
      return new _emberChangeset.default(vehicleRoadCallRecord, (0, _emberChangesetValidations.default)(_roadCallValidation.VALIDATIONS), _roadCallValidation.VALIDATIONS);
    },

    setupDropDownValues() {
      const store = this.get('store');
      const dropDownModels = ['road-call-code-name'];
      dropDownModels.forEach(model => {
        const records = store.peekAll(model);
        this.set(model, records);
      });
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedVehicle = this.get('selectedVehicle');

      if (!selectedVehicle.get('isNew')) {
        yield selectedVehicle.reload();
      }

      const tableRef = this.get('tableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    validate: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const roadCallChangeSet = this.get('roadCallChangeSet');
      yield roadCallChangeSet.validate();
      const errors = roadCallChangeSet.errors;

      if (errors.length > 0) {
        this.set('showErrorMsg', true);
        tooltip.pushConfirmation({
          title: 'Manage Vehicle',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      this.set('showErrorMsg', false);
      return true;
    }),
    onSaveRoadCallRecord: (0, _emberConcurrency.task)(function* () {
      // validation
      const isValid = yield this.validate.perform();

      if (isValid) {
        const notifications = this.get('notifications');
        const vehicleRoadCallRecord = (0, _unwrapProxy.unwrapProxy)(this.get('roadCallChangeSet'));
        const selectedVehicle = this.get('selectedVehicle');
        const isNewRecord = this.get('isNewRecord');

        if (isNewRecord) {
          vehicleRoadCallRecord.set('vehicle', selectedVehicle);
        }

        try {
          yield vehicleRoadCallRecord.save();
          notifications.success('Successfully Saved Vehicle Road Call Record');
          this.set('isNewRecord', false);
          this.get('tableRef').refreshData();
          this.roadCallbuttonSettings();
        } catch (e) {
          notifications.warning('Failed to Save Vehicle ROad Call Record');
          console.warn(e); // eslint-disable-line

          this.get('tableRef').refreshData();
        }
      }

      this.get('refreshTask').perform();
    }),

    onNewRoadCallRecord() {
      this.set('isNewRecord', true);
      this.get('tableRef').onUncheckAll();
      this.set('disableForm', false);
      this.toggleProperty('refresh');
      this.get('refreshTask').perform();
      this.roadCallbuttonSettings(true, true, true, false, false); //False to enable the button, TRUE to disable
    },

    onDeleteRoadCallRecord: (0, _emberConcurrency.task)(function* () {
      const selectedRoadCall = this.get('selectedRoadCall');
      const notifications = this.get('notifications');

      try {
        yield selectedRoadCall.destroyRecord();
        notifications.success('Vehicle Road Call Record successfully deleted');
        this.get('tableRef').refreshData(); //to refresh table data
      } catch (e) {
        notifications.warning('Vehicle Road Call Record failed to delete');
      }

      this.get('refreshTask').perform();
    }),

    onEditRoadCallRecord() {
      this.set('disableForm', false);
      this.roadCallbuttonSettings(true, true, true, false, false);
    },

    onUndoRoadCallRecord() {
      const roadCallChangeSet = this.get('roadCallChangeSet');

      if (roadCallChangeSet) {
        roadCallChangeSet.rollback();
      }
    },

    async selectiveIdChange(roadCallChangeSet, route, path) {
      roadCallChangeSet.set(path, route);
      roadCallChangeSet.execute();
    },

    actions: {
      onPowerSelectChange(valuePath, selected) {
        this.set(valuePath, selected);
      },

      onInputValueChange(valuePath, value) {
        this.set(valuePath, value);
      },

      findNewVehicleRoadCall() {
        const notifications = this.get('notifications');
        const roadCallChangeSet = this.get('roadCallChangeSet');
        const newVehicleId = roadCallChangeSet.get('newVehicleId');
        const session = this.get('session');
        const self = this;
        const store = this.get('store');
        return this.get('ajax').post(`${_apiUrls.API.vehicleService.host}/vehicle?filter=eq(name,'${newVehicleId}')`, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        }).then(response => {
          if (response.data.length !== 0) {
            const selectedVehicle = self.get('selectedVehicle');
            const editAPI = self.get('editAPI');
            const newVehicle = store.peekRecord('vehicle', response.data[0].id);
            const newVehicleName = newVehicle.get('name');
            const panelTitle = `Edit vehicle ${newVehicleName}`;
            editAPI.set('title', panelTitle);
            self.set('selectedVehicle', (0, _unwrapProxy.unwrapProxy)(newVehicle));
            self.refreshTask.perform();
          } else {
            notifications.warning('Vehicle ID does not exist.');
          }
        }).catch(function (e) {
          notifications.warning('Invalid Vehicle ID.');
          console.warn(e); // eslint-disable-line
        });
      },

      openRouteModal(roadCallDate) {
        const selectiveIdChange = this.get('selectiveIdChange').bind(this);
        const roadCallChangeSet = this.get('roadCallChangeSet');
        const callDate = roadCallChangeSet.get('callDate');
        const path = 'route';
        const routeIdObject = Ember.Object.create({
          title: 'Route ID',
          queryParams: {
            filter: `or(ge(plannedStartTime,'${callDate}'),le(plannedEndTime,'${callDate}'))`
          },
          hasOverlay: true,
          config: _roadCallRouteSearchConfig.default,
          primaryActionText: 'Select',
          className: 'route-id',
          primaryAction: record => {
            selectiveIdChange(roadCallDate, record, path);
          }
        });
        this.set('modalAPI', routeIdObject);
      },

      openDriverModal(roadCall) {
        const selectiveIdChange = this.get('selectiveIdChange').bind(this);
        const driverLicense = this.get('selectedDriver.driverLicense');
        const path = 'driver';
        const routeIdObject = Ember.Object.create({
          title: 'Find Driver',
          hasOverlay: true,
          config: _roadCallDriverSearchConfig.default,
          primaryActionText: 'Select',
          className: 'driver-id',
          primaryAction: record => {
            selectiveIdChange(roadCall, record, path);
          }
        });
        this.set('modalAPI', routeIdObject);
      },

      onDateValueChange(valuePath, value) {
        const isoDate = (0, _moment.default)(value).toISOString();
        this.set(valuePath, isoDate);
      }

    }
  });

  _exports.default = _default;
});