define("adept-iq/serializers/perm-sso-role", ["exports", "adept-iq/serializers/-sso-ssoService"], function (_exports, _ssoSsoService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssoSsoService.default.extend({
    attrs: {
      permissions: {
        serialize: false
      }
    },

    serialize(snapshot, options = {}) {
      options.includeId = true;

      const json = this._super(snapshot, options);

      return json;
    },

    normalizeResponse: function (store, primaryModelClass, payload) {
      const currentPayload = {
        data: []
      };
      const data = payload.length ? payload : [payload];

      for (let i = 0; i < data.length; i++) {
        currentPayload.data.push({
          id: data[i].roleName,
          type: 'perm-sso-role',
          attributes: data[i]
        });
      }

      return currentPayload;
    },

    modelNameFromPayloadKey(key) {
      if (key === 'perm-sso-role') {
        return 'perm-sso-role';
      }

      return this._super(key);
    }

  });

  _exports.default = _default;
});