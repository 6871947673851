define("adept-iq/pods/components/reoptimize-modal/operation-tables/consolidate-routes-table/component", ["exports", "adept-iq/mixins/active-context-binding", "adept-iq/pods/components/generic-components/table/component", "adept-iq/pods/components/reoptimize-modal/operation-tables/consolidate-routes-table/config"], function (_exports, _activeContextBinding, _component, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_activeContextBinding.default, {
    classNames: ['consolidate-routes-table'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    config: _config.default,
    currentSchedule: null,
    queryParams: null,
    defaultCheckboxRowSelected: true,

    init() {
      this._super(...arguments);

      const currentScheduleId = this.get('workspace.currentSchedule.id');
      this.set('queryParams', {
        filter: `and(or(eq(optimizationFlags,2),eq(optimizationFlags,3)),eq(scheduleId,${currentScheduleId}))`
      });
    },

    didInsertElement() {
      this._super(...arguments);

      const table = this.get('table');

      if (table) {
        this.set('tableRef', this);
      }
    },

    willDestroyElement() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});