define("adept-iq/pods/components/side-drawer/edit-trip-widget/component", ["exports", "adept-iq/utils/unwrapProxy", "adept-iq/utils/location-validation", "adept-iq/pods/components/side-drawer/edit-trip-widget/config", "ember-concurrency", "adept-iq/mixins/async-schedule-operation", "ember-changeset"], function (_exports, _unwrapProxy, _locationValidation, _config, _emberConcurrency, _asyncScheduleOperation, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ANCHOR_OPTIONS = ['pick', 'drop'];
  const SCHEDULING_MODE_OPTIONS = ['automatic', 'manual'];
  const LOCATION_PATH = 'latlng';
  const PICK = 'pick';

  var _default = Ember.Component.extend(_asyncScheduleOperation.default, {
    tooltip: Ember.inject.service(),
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    geocode: Ember.inject.service(),
    title: 'Edit Trip',
    anchorOptions: ANCHOR_OPTIONS,
    schedulingModeOptions: SCHEDULING_MODE_OPTIONS,
    fareTypeOptions: null,
    pickLocationFieldConfig: _config.pickLocationFieldConfig,
    dropLocationFieldConfig: _config.dropLocationFieldConfig,
    classNames: ['form-widget'],
    isSearchEnabled: false,
    searchText: '',
    errors: null,
    record: null,
    trip: Ember.computed.readOnly('stackFrame.options.trip'),
    previousPickPlace: null,
    previousDropPlace: null,
    maxXYDelta: null,
    isInvalidAddressSelected: null,
    canSave: false,

    init() {
      this._super(...arguments);

      this.setDefaultProperties(this.get('trip'));
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.$('.side-drawer-modal').css('min-width', '470px');
    },

    //this method used to clone the address in place model
    createPlaceRecord(place) {
      const store = this.get('store');
      const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      const addressAttr = address.toJSON();
      const placeAttr = place.toJSON(); //const location = store.createRecord('location',);

      const clonedAddress = store.createRecord('address', addressAttr);
      const clonePlace = store.createRecord('place', placeAttr);
      clonedAddress.set('freeformAddress', clonedAddress.get('fullAddress'));
      clonePlace.set('address', clonedAddress); // if clone the location it will update the location in map

      clonePlace.set('location', place.get('location'));
      return new _emberChangeset.default(clonePlace);
    },

    async setDefaultProperties(trip) {
      const store = this.get('store');
      const unwrapPickPlace = (0, _unwrapProxy.unwrapProxy)(trip.get('pick.segmentStop.place'));
      const unwrapDropPlace = (0, _unwrapProxy.unwrapProxy)(trip.get('drop.segmentStop.place'));
      const pickLocation = (0, _unwrapProxy.unwrapProxy)(unwrapPickPlace.get('location'));
      const dropLocation = (0, _unwrapProxy.unwrapProxy)(unwrapDropPlace.get('location'));
      const pickPlace = this.createPlaceRecord(unwrapPickPlace);
      const dropPlace = this.createPlaceRecord(unwrapDropPlace);
      this.set('previousPickPlace', unwrapPickPlace);
      this.set('previousDropPlace', unwrapDropPlace); //set fare type

      const fareTypes = store.peekAll('fare-type');
      this.set('fareTypeOptions', fareTypes); //set maxXYDelta

      const tripMaxXYDelta = this.get('store').peekRecord('cs-config-item', 'config-Scheduling_parms/MODTRIP_MAX_XY_DELTA');
      const maxXYDelta = tripMaxXYDelta ? parseFloat(tripMaxXYDelta.get('value')) : 0;
      this.set('maxXYDelta', maxXYDelta);
      this.set('pickSegmentStopPlace', unwrapPickPlace);
      this.set('dropSegmentStopPlace', unwrapDropPlace); //create emberObject with trip attribute values

      const record = Ember.Object.create({
        driverNotes: trip.get('segment.leg.notes'),
        tripId: trip.get('tripId'),
        riderId: trip.get('segment.leg.rider.riderId'),
        passengerNotes: trip.get('segment.leg.rider.notes'),
        fare: trip.get('segment.fare'),
        requestTime: trip.get('segment.leg.requestTime'),
        anchor: trip.get('segment.anchor'),
        schedulingMode: trip.get('schedulingMode'),
        fareType: trip.get('segment.fareType'),
        pickPlace: pickPlace,
        dropPlace: dropPlace,
        disabledSchedulingMode: !(trip.get('status').toLowerCase() === 'scheduled')
      });
      this.set('record', record);

      if (!pickLocation.get('geoNode')) {
        await this.getGeoNode(unwrapPickPlace);
      }

      if (!dropLocation.get('geoNode')) {
        await this.getGeoNode(unwrapDropPlace);
      }

      setTimeout(() => {
        this.set('canSave', true);
      }, 2000);
    },

    validateRecord() {
      let valid = true;
      const record = this.get('record');
      this.set('errors', []);
      const requestedTime = record.get('requestTime');

      if (Ember.isNone(requestedTime)) {
        valid = false;
        this.get('errors').pushObject('Date should be there');
      }

      if (Ember.isEmpty(record.get('pickPlace.location.latlng'))) {
        valid = false;
        this.get('errors').pushObject('Pickup address should be there');
      } else if (Ember.isEmpty(record.get('pickPlace.location.geoNode'))) {
        valid = false;
        this.get('errors').pushObject('Pickup address must be a valid mailing address');
      }

      if (Ember.isEmpty(record.get('dropPlace.location.latlng'))) {
        valid = false;
        this.get('errors').pushObject('Drop address should be there');
      } else if (Ember.isEmpty(record.get('dropPlace.location.geoNode'))) {
        valid = false;
        this.get('errors').pushObject('Drop address must be a valid mailing address');
      }

      return valid;
    },

    updatePlaceInTrip: (0, _emberConcurrency.task)(function* (trip, pickPlaceId, dropPlaceId) {
      const schedule = trip.get('route.schedule');
      const tripId = trip.get('id');
      let result;

      try {
        const operationData = {
          tripId,
          pickPlaceId,
          dropPlaceId
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripPlaceUpdate', options);

        if (!result) {
          throw new Error('Failed to update place');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),

    async savePlaceObject(place) {
      if (!place.get('isForceDirty')) {
        return place;
      }

      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      const location = (0, _unwrapProxy.unwrapProxy)(place.get('location')); // TODO: We need to deal with how zone name is populated

      if (Ember.isNone(address.get('zoneName.id'))) {
        address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
      }

      const locationResponse = await locationAdapter.postLocation(location);
      const addressResponse = await addressAdapter.postAddress(address);
      const placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
      const createdLocation = await store.findRecord('location', locationResponse.data.id);
      const createdAddress = await store.findRecord('address', addressResponse.data.id);
      const createdPlace = await store.findRecord('place', placeResponse.data.id);
      createdPlace.set('location', createdLocation);
      createdPlace.set('address', createdAddress);
      return createdPlace;
    },

    async getSegmentStopRecord(segmentStopId) {
      const store = this.get('store');
      await store.findRecord('segment-stop', segmentStopId);
      const segmentStopRecord = await store.peekRecord('segment-stop', segmentStopId);
      return segmentStopRecord;
    },

    async createNewPlace(record) {
      const pickSegmentStop = (0, _unwrapProxy.unwrapProxy)(record.get('pick.segmentStop'));
      const dropSegmentStop = (0, _unwrapProxy.unwrapProxy)(record.get('drop.segmentStop'));
      const pickPlace = (0, _unwrapProxy.unwrapProxy)(pickSegmentStop.get('place'));
      const dropPlace = (0, _unwrapProxy.unwrapProxy)(dropSegmentStop.get('place'));
      const segmentStopPick = await this.getSegmentStopRecord(pickSegmentStop.get('id'));
      const segmentStopDrop = await this.getSegmentStopRecord(dropSegmentStop.get('id'));
      pickSegmentStop.set('dwell', segmentStopPick.get('dwell'));
      dropSegmentStop.set('dwell', segmentStopDrop.get('dwell')); //if both pick and drop address are not changed no need to update

      if (!pickPlace.get('isForceDirty') && !dropPlace.get('isForceDirty')) {
        return Promise.resolve();
      } // create new pick and drop place


      const newPickPlace = await this.savePlaceObject(pickPlace);
      const newDropPlace = await this.savePlaceObject(dropPlace); //set created  place in pick and drop

      this.set('pickSegmentStopPlace', newPickPlace);
      this.set('dropSegmentStopPlace', newDropPlace);
      return Promise.resolve();
    },

    saveNewAddressObject(record) {
      return this.createNewPlace(record);
    },

    async updatePlaceInTripRecord(record) {
      const pickSegmentStop = (0, _unwrapProxy.unwrapProxy)(record.get('pick.segmentStop'));
      const dropSegmentStop = (0, _unwrapProxy.unwrapProxy)(record.get('drop.segmentStop'));
      const pickPlace = (0, _unwrapProxy.unwrapProxy)(pickSegmentStop.get('place'));
      const dropPlace = (0, _unwrapProxy.unwrapProxy)(dropSegmentStop.get('place')); //if both pick and drop address are not changed no need to update

      if (!pickPlace.get('isForceDirty') && !dropPlace.get('isForceDirty')) {
        return Promise.resolve();
      }

      const pickSegmentStopPlace = this.get('pickSegmentStopPlace');
      const dropSegmentStopPlace = this.get('dropSegmentStopPlace');
      pickSegmentStop.set('place', pickSegmentStopPlace);
      dropSegmentStop.set('place', dropSegmentStopPlace);
      await (0, _unwrapProxy.unwrapProxy)(record.get('pick.segmentStop')).save();
      await (0, _unwrapProxy.unwrapProxy)(record.get('drop.segmentStop')).save();
      return this.updatePlaceInTrip.perform(record, pickSegmentStopPlace.get('id'), dropSegmentStopPlace.get('id'));
    },

    //save edit trip details
    saveTask: (0, _emberConcurrency.task)(function* () {
      this.copyRecordDataToTrip();
      const record = this.get('trip'); // while PATCH the trip for edit we don't need currentProviderName attribute

      record.set('currentProviderName', '');

      try {
        yield this.saveNewAddressObject(record);
        yield (0, _unwrapProxy.unwrapProxy)(record.get('segment.leg.rider')).save();
        yield (0, _unwrapProxy.unwrapProxy)(record.get('segment.leg')).save();
        yield (0, _unwrapProxy.unwrapProxy)(record.get('segment')).save();
        yield this.updatePlaceInTripRecord(record);
        return record.save();
      } catch (e) {
        throw e;
      }
    }),

    copyRecordDataToTrip() {
      const trip = this.get('trip');
      const record = this.get('record');
      const pickPlace = record.get('pickPlace');
      const dropPlace = record.get('dropPlace');
      trip.set('segment.leg.notes', record.get('driverNotes'));
      trip.set('segment.leg.rider.notes', record.get('passengerNotes'));
      trip.set('segment.fare', record.get('fare'));
      trip.set('segment.anchor', record.get('anchor'));
      trip.set('schedulingMode', record.get('schedulingMode'));
      trip.set('segment.fareType', record.get('fareType'));

      if (pickPlace.get('isForceDirty')) {
        trip.set('pick.segmentStop.place.address', pickPlace.get('address'));
        trip.set('pick.segmentStop.place.isForceDirty', pickPlace.get('isForceDirty'));
      }

      if (dropPlace.get('isForceDirty')) {
        trip.set('drop.segmentStop.place.address', dropPlace.get('address'));
        trip.set('drop.segmentStop.place.isForceDirty', dropPlace.get('isForceDirty'));
      }
    },

    getGeoNode(place) {
      const geocode = this.get('geocode');
      const address = place.get('address');
      geocode.getGeonode(address.get('fullAddress')).then(result => {
        if (!Ember.isNone(result)) {
          place.set('location.geoNode', result.geonode);
        } else {
          this.get('notifications').warning(`CAN'T GET GEONODE FOR ${address.get('fullAddress')}`);
        }
      });
    },

    actions: {
      onSaveClick() {
        const tooltip = this.get('tooltip');
        const notifications = this.get('notifications');
        const saveTask = this.get('saveTask');
        const tripId = this.get('trip.tripId');
        if (!this.validateRecord()) return; //copy record data to trip

        tooltip.pushConfirmation({
          tip: 'Would you like to commit your changes?',
          title: 'Edit Trip',
          hasOverlay: true,
          primaryActionText: 'Commit',
          primaryAction: () => {
            return saveTask.perform().then(() => {
              this.onCloseClick();
              notifications.success(`Trip ${tripId} was edited successfully`);
              tooltip.reset();
            }).catch(error => {
              notifications.warning(`Edit to Trip ${tripId} Failed`);
              tooltip.reset(); // eslint-disable-next-line no-console

              console.log(error);
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => tooltip.reset()
        });
      },

      onRefreshClick() {},

      onSearchClick() {
        const isSearchEnabled = this.get('isSearchEnabled');

        if (isSearchEnabled) {
          this.set('isSearchEnabled', false);
          return;
        }

        this.set('searchText', '');
        this.set('isSearchEnabled', true);
      },

      onSelectDropDown(record, path, value) {
        record.set(path, value);
      },

      // overriding the parent method and validate addresses
      onCellValueChange(record, valuePath, value, options) {
        if (options && options.valuePath === LOCATION_PATH && options.addressTypeOption) {
          const pickPlace = this.get('previousPickPlace');
          const dropPlace = this.get('previousDropPlace');
          const pickPlacePosition = pickPlace.get('address.position');
          const dropPlacePosition = dropPlace.get('address.position');
          const maxXYDelta = this.get('maxXYDelta');
          const previousLocation = options.addressTypeOption === PICK ? pickPlacePosition : dropPlacePosition;
          const distance = (0, _locationValidation.getDistanceBetweenFromLatLon)(previousLocation.lat, previousLocation.lng, value.lat, value.lon);
          this.set('isInvalidAddressSelected', false);

          if (distance > maxXYDelta) {
            this.get('notifications').warning('New location is too far away from original location');
            this.set('isInvalidAddressSelected', true);
          }
        }

        if (this.get('isInvalidAddressSelected')) {
          return;
        }

        record.set(`${valuePath}.isForceDirty`, true);
        return record.set(`${valuePath}.${options.valuePath}`, value);
      },

      onCloseClick() {
        this._super(...arguments);

        const trip = this.get('trip');
        const pickLocation = (0, _unwrapProxy.unwrapProxy)(trip.get('pick.segmentStop.place.location'));
        const dropLocation = (0, _unwrapProxy.unwrapProxy)(trip.get('drop.segmentStop.place.location'));

        if (pickLocation) {
          pickLocation.rollbackAttributes();
        }

        if (dropLocation) {
          dropLocation.rollbackAttributes();
        }

        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});