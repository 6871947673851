define("adept-iq/pods/components/iq-widgets/stops-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/stops-widget/config", "adept-iq/pods/components/iq-widgets/stops-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/stops-widget/scheduleConfig", "lodash", "adept-iq/config/environment", "adept-iq/utils/find-row-record", "ember-changeset", "adept-iq/config/api-urls", "moment", "adept-iq/utils/unwrapProxy", "adept-iq/config/mapped-permIds", "adept-iq/utils/rql-generators", "adept-iq/models/dispatch-route", "adept-iq/models/stop-point", "adept-iq/models/dashboard"], function (_exports, _component, _config, _avlmConfig, _scheduleConfig, _lodash, _environment, _findRowRecord, _emberChangeset, _apiUrls, _moment, _unwrapProxy, _mappedPermIds, _rqlGenerators, _dispatchRoute, _stopPoint, _dashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = ['clusters', 'vehicleRoutes', 'vehicleRoutes.driverShifts', 'vehicleRoutes.vehicle', 'vehicleRoutes.driverShifts.driver', 'vehicleRoutes.vehicle.vehicleType', 'driverBreaks', 'driverBreaks.driverBreakEvents', 'vehicleRoutes.vehicle.vehicleEvents', 'clusters.trips', 'clusters.trips.stops'];

  var _default = _component.default.extend({
    classNames: ['stops-widget'],
    classNameBindings: ['isReordering'],
    editModal: Ember.inject.service(),
    dragCoordinator: Ember.inject.service(),
    stopsReorder: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    routeexecevent: Ember.inject.service(),
    user: Ember.inject.service(),
    getCoreModelNameByWidgetRecord: Ember.computed('activeContext.topActiveContext.getCoreModelNameByWidgetRecord', function () {
      const func = this.get('activeContext.topActiveContext.getCoreModelNameByWidgetRecord');
      return func;
    }),
    editComponent: 'iq-widgets/stops-form-widget',
    driverBreakEditComponent: 'iq-widgets/driver-breaks-form-widget',
    vehicleEventComponent: 'iq-widgets/vehicle-event-form-widget',
    noShowComponent: 'iq-widgets/noshow-event-stop-form-widget',
    routesEndComponent: 'iq-widgets/routes-end-form-widget',
    routesStartComponent: 'iq-widgets/routes-start-form-widget',
    cancelTripComponent: 'iq-widgets/cancel-type-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,
    sourceRowId: null,
    dragTarget: null,
    isReordering: Ember.computed.alias('stopsReorder.reorderInProgress'),
    // Check if we still need this after iqux-perf merged
    // onReorderChange: observer('stopsReorder.reorderInProgress', function() {
    //   run.scheduleOnce('afterRender', this, 'refreshData');
    // }),
    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,computedStatus}', 'selectedWidgetRecord', function () {
      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        return this._dynamicAVLMActions();
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            return this._dynamicScheduleActions();

          case _dashboard.DISPATCH_ROLENAME:
          default:
            return this._dynamicDispatchActions();
        }
      }
    }),
    dragRowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    dragDirection: Ember.computed('dragTarget', 'sourceRowId', function () {
      const targetRow = this.get('dragTarget');

      if (targetRow) {
        const rows = this.get('dragRowGroup');
        const targetIdx = rows.indexOf((0, _findRowRecord.findRowRecordByElement)(targetRow, rows));
        const sourceIdx = rows.indexOf(rows.findBy('rowId', this.get('sourceRowId')));
        return sourceIdx - targetIdx;
      }

      return null;
    }).readOnly(),
    dragOverClass: Ember.computed('dragDirection', function () {
      const direction = this.get('dragDirection') < 0 ? 'below' : 'above';
      return ` drag-target-${direction} drag-target`;
    }),

    init() {
      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            this.set('config', _scheduleConfig.default);
            break;

          case _dashboard.DISPATCH_ROLENAME:
          default:
            this.set('config', _config.default);
            break;
        }
      }

      this._super(...arguments);
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        // if it is a trip active context
        // only include the pick and drop stops
        if (this.get('activeContext.topActiveContext').tripActiveContext()) {
          return records.filter(record => {
            return ['pick', 'drop'].includes(record.get('stopType'));
          });
        }

        return records;
      });
    },

    cursorWait() {
      Ember.$('html,body').css('cursor', 'wait');
    },

    cursorDefault() {
      Ember.$('html,body').css('cursor', 'default');
    },

    saveCancelRecordFunction(records) {
      /*eslint-disable */
      const me = this;
      /*eslint-enable */

      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!Ember.isEmpty(records)) {
          records.forEach(record => {
            const bookingId = record.get('leg.booking.id');
            const cancelData = this.get('routeexecevent').cancelData();
            const prepareCancelData = this.get('routeexecevent').prepareTripCancelData(cancelData, record);
            const json = JSON.stringify(prepareCancelData);
            const session = this.get('session');
            return this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking/' + bookingId + '/cancellation', {
              method: 'POST',
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${session.data.authenticated.token}`
              },
              data: json
            }).then(() =>
            /*response*/
            {
              record.set('isForceDirty', false);
              me.get('notifications').success('Trip successfully canceled');
              me.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
              resolve();
            }).catch(function (error) {
              me.get('notifications').warning('Failed to cancel Trip');
              reject(error);
            });
          });
        }
      });
    },

    saveRouteStartRecordFunction(records) {
      return Ember.RSVP.all(records.map(record => {
        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a vehicle.'));
        }

        record.set('actualStartTimestamp', record.get('timestamp'));
        const routeStartData = this.get('routeexecevent').routeStartData();
        const preparedRouteStartData = this.get('routeexecevent').prepareRouteStartData(routeStartData, record);
        const json = JSON.stringify(preparedRouteStartData);
        const session = this.get('session');
        return this.get('ajax').post(_apiUrls.API.avlmService.host + '/route-exec-event', {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          data: json
        }).then(() =>
        /*response*/
        {
          record.set('isForceDirty', false);
          this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
          this.get('notifications').success('Start route successful');
        }).catch(() => {
          this.get('notifications').warning('Start route failed');
        });
      }));
    },

    confirmSaveRouteEndFunction(records) {
      const tooltip = this.get('tooltip');
      const title = 'End Route';
      let tip = 'The route end time selected is outside the configured limit of the planned route end time. Do you want to continue?';
      return Ember.RSVP.all(records.map(record => {
        const extensionExceeded = record.get('extensionExceeded');

        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a vehicle.'));
        }

        record.set('actualEndTimestamp', record.get('timestamp'));

        if (!extensionExceeded) {
          tip = 'Would you like to commit your changes?';
        }

        tooltip.pushConfirmation({
          tip,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.saveRouteEndRecordFunction(record);
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            record.set('actualEndTimestamp');
            return tooltip.reset();
          }
        });
      }));
    },

    saveRouteEndRecordFunction(record) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return routeExecEventAdapter.createEndRouteEvent(record).then(() =>
      /*response*/
      {
        record.set('isForceDirty', false);
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
        this.get('notifications').success('Route completed successfully');
        this.get('editModal').close();
        return Ember.RSVP.Promise.resolve();
      }).catch(e => {
        this.get('notifications').warning('Failed to complete route');
        this.get('editModal').close();
        return Ember.RSVP.Promise.reject(e);
      });
    },

    saveDriverBreakFunction(records) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const routeBreakAdapter = store.adapterFor('route-break');
      const dispatchRouteAdapter = store.adapterFor('dispatch-route');
      this.set('editModal.errors', []);
      return records.reduce(async (acu, record) => {
        const requestTime = record.get('requestTime');
        const projectedEndDate = record.get('projectedEndDate');
        const place = (0, _unwrapProxy.unwrapProxy)(record.get('place'));
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        const locationExist = !Ember.isEmpty(location.get('lat'));
        let placeResponse;
        place.set('type', 'driverBreak');
        place.set('schedule', (0, _unwrapProxy.unwrapProxy)(record.get('schedule')));
        record.set('estimatedStart', requestTime);
        record.set('estimatedEnd', projectedEndDate); // temporary assignment of zone type until we figure out how to deal with
        // zone types

        address.set('zoneName', store.peekAll('zone-type').get('firstObject')); // creating new driver break

        if (record.get('isNew')) {
          try {
            if (locationExist) {
              record.set('place', place);
              const locationResponse = await locationAdapter.postLocation(location);
              const addressResponse = await addressAdapter.postAddress(address);
              await addressAdapter.postAddressLocation(locationResponse, addressResponse);
              placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
            }

            const placeId = Ember.isNone(placeResponse) ? '' : placeResponse.data.id;
            const routeBreak = await routeBreakAdapter.postRouteBreak(record, placeId);
            return acu.then(() => dispatchRouteAdapter.createDriverBreak((0, _unwrapProxy.unwrapProxy)(record.get('route.dispatchRoute')), routeBreak.data.attributes.plannedRouteOrdinal, routeBreak.data.id)).then(() => {
              this.get('notifications').success(`Successfully added driver break to route ${record.get('route.name')}`);
              this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['stop-point']);
              return Ember.RSVP.Promise.resolve();
            });
          } catch (e) {
            this.get('notifications').warning(`Failed to add driver break to route ${record.get('route.name')}`);
            return Ember.RSVP.Promise.reject();
          }
        } // patching driver break


        try {
          let placeId;

          if (locationExist) {
            if (!Ember.isEmpty(place.id)) {
              await place.save();
              await location.save();
              await address.save();
              placeId = place.get('id');
            } else {
              const locationResponse = await locationAdapter.postLocation(location);
              const addressResponse = await addressAdapter.postAddress(address);
              await addressAdapter.postAddressLocation(locationResponse, addressResponse);
              placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
              placeId = Ember.isNone(placeResponse) ? '' : placeResponse.data.id;
            }
          }

          return routeBreakAdapter.patchRouteBreak(record, placeId).then(() => {
            this.get('notifications').success(`Successfully added driver break to route ${record.get('route.name')}`);
            return Ember.RSVP.Promise.resolve();
          });
        } catch (e) {
          this.get('notifications').warning(`Failed to add driver break to route ${record.get('route.name')}`);
          return Ember.RSVP.Promise.reject();
        }
      }, Ember.RSVP.Promise.resolve());
    },

    saveNoShowRecordFunction(records) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripNoShowAdapter = store.adapterFor('trip-noshow');
      const notifications = this.get('notifications');
      return records.reduce((acu, record) => {
        const dsStop = (0, _unwrapProxy.unwrapProxy)(record);
        const noshow = (0, _unwrapProxy.unwrapProxy)(dsStop.get('noShow'));
        const noshowReason = (0, _unwrapProxy.unwrapProxy)(noshow.get('noShowReasonCode'));
        const trip = (0, _unwrapProxy.unwrapProxy)(dsStop.get('trip'));
        trip.set('noshowDetails', noshow.get('notes'));
        return acu.then(() => routeExecEventAdapter.createStopNoShowEvent(record)).then(() => tripNoShowAdapter.createTripNoShow(trip, noshowReason)).then(() => {
          notifications.success(`Successfully No Showed Trip ${dsStop.get('trip.tripId')}`);
        }).catch(e => {
          notifications.warning(`Failed to No Show Trip ${dsStop.get('trip.tripId')}`);
          throw new Error(e);
        });
      }, Ember.RSVP.Promise.resolve());
    },

    confirmSaveUndoRouteEndFunction(route) {
      const tooltip = this.get('tooltip');
      const title = 'Undo Route End';
      const tip = 'Would you like to undo route end?';
      tooltip.pushConfirmation({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: async () => {
          await this.saveUndoRouteEndRecordFunction(route);
          return tooltip.reset();
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    saveUndoRouteEndRecordFunction(record) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return routeExecEventAdapter.createUndoEndRouteEvent(record).then(() =>
      /*response*/
      {
        record.set('isForceDirty', false);
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
        this.get('notifications').success('Undid Route end successfully');
        return Ember.RSVP.Promise.resolve();
      }).catch(e => {
        this.get('notifications').warning('Failed to undo ended route');
        return Ember.RSVP.Promise.reject(e);
      });
    },

    reorderTripInRoute(dispatchRoute, tripId, pickOrdinal, dropOrdinal, sourceStop, targetStop) {
      /*eslint-disable */
      const me = this;
      /*eslint-enable */

      return new Ember.RSVP.Promise((resolve, reject) => {
        const session = this.get('session');
        const reorderUrl = `/dispatch-route/${dispatchRoute.id}/tripTransfer/${tripId}?pickOrdinal=${pickOrdinal}&dropOrdinal=${dropOrdinal}`;
        const targetStopType = targetStop.get('stopType');
        const sourceStopType = sourceStop.get('stopType'); // change pointer to working so user knows

        me.cursorWait();
        return this.get('ajax').post(_apiUrls.API.dispatchService.host + reorderUrl, {
          method: 'PUT',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        }).then(() =>
        /*response*/
        {
          // switch pointer back to default
          me.cursorDefault();
          me.get('stopsReorder').stopReorder();

          if (targetStopType === 'pullinGarage') {
            me.get('notifications').success(`Successfully moved Trip ${sourceStop.get('trip.tripId')} ${sourceStopType} stop to the bottom`);
          } else if (targetStopType === 'pulloutGarage') {
            me.get('notifications').success(`Successfully moved Trip ${sourceStop.get('trip.tripId')} ${sourceStopType} stop to the top`);
          } else {
            me.get('notifications').success(`Successfully swapped Trip ${sourceStop.get('trip.tripId')} ${sourceStopType} stop with Trip ${targetStop.get('trip.tripId')} ${targetStopType} stop`);
          }

          me.get('coreEntityPopulator').populateRtsRoute(dispatchRoute.get('route.id'), {});
        }).catch(function (error) {
          let message = '';

          if (!Ember.isNone(error) && !Ember.isNone(error.payload) && !Ember.isNone(error.payload.message)) {
            message = error.payload.message;
          } // switch pointer back to default


          me.cursorDefault();
          me.get('stopsReorder').stopReorder();
          me.get('notifications').warning(`Failed to Reorder Trip. ${message}`);
          reject(error);
        });
      });
    },

    reorderBreakInRoute(dispatchRoute, driverBreak, breakOrdinal) {
      // eslint-disable-next-line consistent-this
      const me = this;
      const session = this.get('session');
      const reorderUrl = `/dispatch-route/${dispatchRoute.id}/break/${driverBreak.get('routeBreak.id')}?ordinal=${breakOrdinal}`; // change pointer to working so user knows

      me.cursorWait();
      return this.get('ajax').post(_apiUrls.API.dispatchService.host + reorderUrl, {
        method: 'PUT',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(() =>
      /*response*/
      {
        // switch pointer back to default
        me.cursorDefault();
        me.get('stopsReorder').stopReorder();
        me.get('notifications').success(`Successfully reordered Driver Break ${driverBreak.get('id')}`);
        me.get('coreEntityPopulator').populateRtsRoute(dispatchRoute.get('route.id'), {});
      }).catch(function (error) {
        let message = '';

        if (!Ember.isNone(error) && !Ember.isNone(error.payload) && !Ember.isNone(error.payload.message)) {
          message = error.payload.message;
        } // switch pointer back to default


        me.cursorDefault();
        me.get('stopsReorder').stopReorder();
        me.get('notifications').warning(`Failed to Reorder Driver Break. ${message}`);
      });
    },

    validateReorder(sourceType, sourceRowRecord, targetRowRecord, pickOrdinal, dropOrdinal) {
      if (sourceType !== 'break' && sourceRowRecord.get('computedStatus') !== _stopPoint.PENDING_STOP_STATUS) {
        this.get('notifications').warning('Cannot move stop because trip have already been started.');
        return false;
      }

      if (sourceType === 'break' && (sourceRowRecord.get('computedStatus') === _stopPoint.ON_BREAK_STOP_STATUS || sourceRowRecord.get('computedStatus') === 'OffBreak')) {
        this.get('notifications').warning('Cannot move break because it have already been started.');
        return false;
      }

      if (targetRowRecord.get('stopType') !== 'break' && sourceRowRecord.get('computedStatus') !== _stopPoint.PENDING_STOP_STATUS && targetRowRecord.get('computedStatus') !== _stopPoint.PENDING_STOP_STATUS) {
        this.get('notifications').warning('Cannot move stop before a trip that have already been started.');
        return false;
      }

      if (targetRowRecord.get('stopType') === 'break' && (targetRowRecord.get('computedStatus') === _stopPoint.ON_BREAK_STOP_STATUS || targetRowRecord.get('computedStatus') === 'OffBreak')) {
        this.get('notifications').warning('Cannot move stop before a break that have already been started.');
        return false;
      }

      if (sourceType !== 'break' && dropOrdinal <= pickOrdinal) {
        this.get('notifications').warning('Drop-off cannot be before the Pickup');
        return false;
      }

      return true;
    },

    doReorder(sourceRowRecord, targetRowRecord) {
      const tooltip = this.get('tooltip');
      const sourceType = sourceRowRecord.get('stopType');
      const targetType = targetRowRecord.get('stopType');
      const title = 'Reordering Stops';
      let dispatchRoute = null;
      let tripId = null;
      let pickOrdinal = null;
      let dropOrdinal = null;
      let breakOrdinal = null;

      if (sourceType === 'pulloutGarage' || sourceType === 'pullinGarage' || targetType === 'pulloutGarage' || targetType === 'pullinGarage' || sourceRowRecord.get('routeOrdinal') === targetRowRecord.get('routeOrdinal')) {
        return false;
      }

      if (sourceType === 'drop') {
        dispatchRoute = sourceRowRecord.get('dispatchRoute');
        tripId = sourceRowRecord.get('trip.id');
        pickOrdinal = sourceRowRecord.get('trip.pick.stopPoint.routeOrdinal');
        dropOrdinal = targetRowRecord.get('routeOrdinal');
      } else if (sourceType === 'pick') {
        dispatchRoute = sourceRowRecord.get('dispatchRoute');
        tripId = sourceRowRecord.get('trip.id');
        pickOrdinal = targetRowRecord.get('routeOrdinal');
        dropOrdinal = sourceRowRecord.get('trip.drop.stopPoint.routeOrdinal');
      } else if (sourceType === 'break') {
        dispatchRoute = sourceRowRecord.get('dispatchRoute');
        breakOrdinal = parseInt(targetRowRecord.get('routeOrdinal'), 10);
      } else {
        this.get('notifications').warning('Invalid Source');
        return false;
      }

      let confirmMessage = `Move ${sourceRowRecord.get('stopType')} stop of Trip ${sourceRowRecord.get('trip.tripId')} to position ${targetRowRecord.get('routeOrdinal')}`;

      if (sourceRowRecord.get('stopType') === 'break') {
        confirmMessage = `Move ${sourceRowRecord.get('stopType')} to position ${targetRowRecord.get('routeOrdinal')}`;
      } // confirm


      this.get('stopsReorder').toggleHiddenFlag();
      tooltip.pushConfirmation({
        tip: confirmMessage,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          if (!this.validateReorder(sourceType, sourceRowRecord, targetRowRecord, pickOrdinal, dropOrdinal)) {
            this.get('stopsReorder').stopReorder();
            this.get('stopsReorder').toggleHiddenFlag();
            tooltip.reset();
            return false;
          }

          if (sourceType === 'break') {
            this.reorderBreakInRoute(dispatchRoute, sourceRowRecord, breakOrdinal);
          } else {
            this.reorderTripInRoute(dispatchRoute, tripId, pickOrdinal, dropOrdinal, (0, _unwrapProxy.unwrapProxy)(sourceRowRecord), (0, _unwrapProxy.unwrapProxy)(targetRowRecord));
          }

          this.get('stopsReorder').toggleHiddenFlag();
          tooltip.reset();
          return true;
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          // This function only works for ember-react-widget
          this.rollbackCache();
          this.get('stopsReorder').toggleHiddenFlag();
          tooltip.reset();
        }
      });
    },

    // generic function to append the stop type to an action label
    appendStopTypeToActionLabel(action, stopPoint) {
      switch (action.id) {
        case 'unperform':
          {
            this.set('unPerformAction.name', 'Unperform');

            if (stopPoint.get('isNoShow')) {
              this.set('unPerformAction.name', 'Unperform No Show');
            }

            break;
          }

        default:
      }
    },

    actions: {
      onColumnClick()
      /*column, event*/
      {
        if (this.get('stopsReorder').reorderInProgress) return;

        this._super(...arguments);
      },

      onSearchButtonClick() {
        if (this.get('stopsReorder').reorderInProgress) return;

        this._super(...arguments);
      },

      onFilterButtonClick() {
        if (this.get('stopsReorder').reorderInProgress) return;

        this._super(...arguments);
      },

      async onRowRightClick()
      /*row, event*/
      {
        if (this.get('stopsReorder').reorderInProgress) return;
        return this._super(...arguments);
      },

      rowReorderHandler(oldIndex, newIndex) {
        const widgetRecords = this.get('widgetRecords');
        const coreModelName = this.get('config.modelName');
        const sourceRowId = widgetRecords[oldIndex].id;
        const sourceRowRecord = this.get('store').peekRecord(coreModelName, sourceRowId);
        const targetRowId = widgetRecords[newIndex].id;
        const targetRowRecord = this.get('store').peekRecord(coreModelName, targetRowId);
        this.doReorder(sourceRowRecord, targetRowRecord);
      }

    },

    didInsertElement() {
      this._super(...arguments);

      const role = this.get('workspace.dashboardModel.role');

      if (_environment.default.APP.avlmLite) {
        this._initDispatchSingleActions();
      }

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            this._initScheduleSingleActions();

            break;

          case _dashboard.DISPATCH_ROLENAME:
          default:
            this._initDispatchSingleActions();

            break;
        }
      }
    },

    dragStart(event) {
      const row = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('dragRowGroup'));
      if (Ember.isBlank(row)) return;
      this.set('sourceRowId', row.get('rowId'));
      const dragId = Ember.guidFor(row);
      this.get('dragCoordinator').setSourceRow(dragId, row, 'stops');
      event.dataTransfer.setData('text', dragId);
    },

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.set('dragTarget', rowElement);
      this.$(`#${rowElement.id}`).addClass(this.get('dragOverClass'));
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
    },

    drop(event) {
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
      const table = this.get('table');
      const rows = this.get('dragRowGroup'); // let sourceRowRecord = rows.findBy('rowId', this.get('sourceRowId'));
      // // User might drop elements that are not Stops widget rows.
      // if (isBlank(sourceRowRecord)) {
      //   return;
      // }

      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      const targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, rows);

      const _rows = rows.toArray();

      const targetRowIdx = _rows.indexOf(targetRowRecord) + (this.get('dragDirection') < 0 ? 0 : 0);
      event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
      event.stopPropagation();
      table.notifyPropertyChange('rows');

      _rows.removeObject(sourceRowRecord);

      _rows.insertAt(targetRowIdx, sourceRowRecord);

      const result = this.doReorder(sourceRowRecord, targetRowRecord);

      if (result) {
        rows.setObjects(_rows);
      }

      table.notifyPropertyChange('rows');
    },

    dragEnd() {
      this.set('sourceRowId', null);
      this.set('dragTarget', null);
      this.get('dragCoordinator').clearSourceRows();
    },

    _initDispatchSingleActions() {
      this.set('noShowAction', {
        id: 'noShow',
        permId: _mappedPermIds.default.noShowTrip,
        name: 'No Show',
        action: model => {
          const store = this.get('store');
          const noShowReasonCodes = this.get('store').peekAll('no-show-reason-category'); // sort all no show reason code by the promise time start/end

          const sortedNoShowReasonCode = noShowReasonCodes.toArray().sort(function (a, b) {
            const aMinStartTime = Ember.isPresent(a.get('minutesFromPromiseTimeStart')) ? parseInt(a.get('minutesFromPromiseTimeStart'), 10) : 999;
            const aMinEndTime = Ember.isPresent(a.get('minutesFromPromiseTimeEnd')) ? parseInt(a.get('minutesFromPromiseTimeEnd'), 10) : 999;
            const bMinStartTime = Ember.isPresent(b.get('minutesFromPromiseTimeStart')) ? parseInt(b.get('minutesFromPromiseTimeStart'), 10) : 999;
            const bMinEndTime = Ember.isPresent(b.get('minutesFromPromiseTimeEnd')) ? parseInt(b.get('minutesFromPromiseTimeEnd'), 10) : 999;

            if (aMinStartTime > bMinStartTime && aMinEndTime > bMinEndTime) {
              return 1;
            } else if (aMinStartTime < bMinStartTime && aMinEndTime < bMinEndTime) {
              return -1;
            }

            return 0;
          }); // calculate the duration for current time and stop's ETA

          const actualArriveTime = model.get('actualArriveTime') ? (0, _moment.default)(model.get('actualArriveTime')) : (0, _moment.default)();
          const promisedTime = (0, _moment.default)(model.get('promisedTime'));

          const minDurations = _moment.default.duration(actualArriveTime.diff(promisedTime)).asMinutes();

          let calculatedNoShowReason = sortedNoShowReasonCode.find(code => {
            const aMinStartTime = Ember.isPresent(code.get('minutesFromPromiseTimeStart')) ? parseInt(code.get('minutesFromPromiseTimeStart'), 10) : 999;
            const aMinEndTime = Ember.isPresent(code.get('minutesFromPromiseTimeEnd')) ? parseInt(code.get('minutesFromPromiseTimeEnd'), 10) : 999;

            if (minDurations >= aMinStartTime && minDurations <= aMinEndTime) {
              return true;
            }

            return false;
          });

          if (!Ember.isPresent(calculatedNoShowReason)) {
            // if it's really early then pick one with the biggest range
            if (minDurations > 0) {
              calculatedNoShowReason = sortedNoShowReasonCode.lastObject;
            } else {
              calculatedNoShowReason = sortedNoShowReasonCode.firstObject;
            }
          }

          model.set('noShow', store.createRecord('no-show', {
            noShowReasonCode: calculatedNoShowReason,
            timestamp: new Date()
          }));

          if (!Ember.isPresent(calculatedNoShowReason.get('minutesFromPromiseTimeEnd'))) {
            model.set('noShowDropDownReadonly', false);
          } else {
            model.set('noShowDropDownReadonly', true);
          }

          const noShowComponent = this.get('noShowComponent');
          this.get('editModal').open(noShowComponent, [model], this.get('saveNoShowRecordFunction').bind(this), null, null, {
            title: 'No Show Stop'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('removeAction', {
        id: 'remove',
        name: 'Remove',
        action: () =>
        /* model */
        {},
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editAction', {
        id: 'edit',
        name: 'Edit',
        action: () =>
        /* model */
        {},
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('cancelAction', {
        id: 'cancelTrip',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: model => {
          const cancelTripComponent = this.get('cancelTripComponent');
          const trip = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
          const booking = (0, _unwrapProxy.unwrapProxy)(trip.get('booking'));
          this.get('editModal').open(cancelTripComponent, [booking.get('legs.firstObject.segments.firstObject')], this.get('saveCancelRecordFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('addDriverBreakAction', {
        id: 'addDriverBreak',
        permId: _mappedPermIds.default.addBreak,
        name: 'Add Driver Break',
        action: model => {
          const store = this.get('store');
          const route = (0, _unwrapProxy.unwrapProxy)(model.get('dispatchRoute.route'));

          if (!Ember.isEmpty(route) || !Ember.isNone(route)) {
            let plannedStartTime = (0, _moment.default)(route.get('plannedStartTime'));
            const currentTime = (0, _moment.default)();

            if (currentTime.isAfter(plannedStartTime)) {
              plannedStartTime = currentTime;
            }

            const lastStop = route.get('dispatchRoute.orderedStopsWithBreaks').lastObject;
            const trips = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
            const driverBreak = store.createRecord('route-break', {
              estimatedStart: plannedStartTime.toDate(),
              requestTime: plannedStartTime.toDate(),
              route: route,
              schedule: route.get('schedule'),
              notes: '',
              plannedRouteOrdinal: lastStop.get('routeOrdinal') + 1,
              plannedDuration: 15,
              state: 'planned',
              status: 'scheduled',
              promisedStart: plannedStartTime.toDate()
            });

            if (Ember.isNone(driverBreak.get('place.id'))) {
              const locationModel = store.createRecord('location');
              const addressModel = store.createRecord('address', {
                locations: [locationModel]
              });
              const placeModel = store.createRecord('place', {
                address: addressModel,
                location: locationModel
              });
              driverBreak.set('place', placeModel);
            }

            if (Ember.isNone(driverBreak.get('stopPoint.id'))) {
              const stopPoint = store.createRecord('stop-point');
              driverBreak.set('stopPoint', stopPoint);
            }

            const saveDriverBreakFunction = this.get('saveDriverBreakFunction').bind(this);
            this.get('workspace').pushState('addBreak', {
              driverBreak,
              trips,
              saveDriverBreakFunction
            });
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('delDriverBreakAction', {
        id: 'deleteDriverBreak',
        permId: _mappedPermIds.default.deleteBreak,
        name: 'Remove Driver Break',
        action: model => {
          const dispatchRouteAdapter = this.get('store').adapterFor('dispatch-route');
          const routeBreak = model.get('routeBreak');
          const routeBreakId = routeBreak.get('id');
          const dispatchRouteId = routeBreak.get('route.dispatchRoute.id');
          const tooltip = this.get('tooltip');
          const tip = 'Are you sure you want to Remove Driver Break';
          const title = 'Remove Driver Break';
          tooltip.pushConfirmation({
            tip,
            title,
            hasOverlay: true,
            primaryActionText: 'Confirm',
            secondaryActionText: 'Cancel',
            primaryAction: () => {
              return dispatchRouteAdapter.removeDriverBreak(dispatchRouteId, routeBreakId).then(() => {
                const record = this.store.peekRecord('stop-point', model.get('id'));
                this.store.unloadRecord(record);
                this.get('notifications').success(`Successfully removed driver break B${routeBreakId} from route ${routeBreak.get('route.name')}`);
              }).catch(() => {
                this.get('notifications').warning(`Failed to remove driver break B${routeBreakId}`);
              }).finally(() => {
                tooltip.reset();
              });
            },

            secondaryAction() {
              tooltip.reset();
              return;
            }

          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editDriverBreakAction', {
        id: 'editDriverBreak',
        permId: _mappedPermIds.default.editBreak,
        name: 'Edit',
        action: model => {
          const store = this.get('store');
          const driverBreak = (0, _unwrapProxy.unwrapProxy)(model.get('routeBreak'));
          const estimatedStart = (0, _moment.default)(Ember.get(driverBreak, 'estimatedStart'));
          const estimatedEnd = (0, _moment.default)(Ember.get(driverBreak, 'estimatedEnd'));
          driverBreak.set('plannedDuration', estimatedEnd.diff(estimatedStart, 'minutes'));

          if (Ember.isNone(driverBreak.get('place.id'))) {
            const locationModel = store.createRecord('location');
            const addressModel = store.createRecord('address', {
              locations: [locationModel]
            });
            const placeModel = store.createRecord('place', {
              address: addressModel,
              location: locationModel
            });
            driverBreak.set('place', placeModel);
          } else {
            driverBreak.set('place.address.freeformAddress', driverBreak.get('place.address.fullAddress'));
          }

          this.get('records').pushObject(driverBreak);
          const editComponent = this.get('driverBreakEditComponent');
          this.get('editModal').open(editComponent, [driverBreak], this.get('saveDriverBreakFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('reorderStopsAction', {
        id: 'reorderStops',
        permId: _mappedPermIds.default.reorderStops,
        name: 'Reorder Stops',
        action: () =>
        /*model*/
        {
          const widget = this.get('widget');
          const sortByStr = 'stopBreakOrd'; // route ordinal

          this.get('stopsReorder').startReorder(widget, sortByStr);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('navigateToAction', {
        id: 'navigateTo',
        permId: _mappedPermIds.default.navigateStop,
        name: 'Navigate To',
        action: model => {
          const tooltip = this.get('tooltip');
          const fullAddress = _environment.default.APP.avlmLite ? model.get('place.fullAddress') : model.get('place.address.fullAddress');
          const selectedWidgetRecord = this.get('selectedWidgetRecord');
          const recordAddress = `${selectedWidgetRecord.address || ''}, ${selectedWidgetRecord.city || ''}`.trim();
          const displayAddress = recordAddress || fullAddress;
          tooltip.pushConfirmation({
            title: 'Navigate To',
            tip: 'Navigate To ' + displayAddress,
            primaryActionText: 'Yes',
            secondaryActionText: 'No',
            secondaryAction: () => {
              tooltip.reset();
            },
            primaryAction: () => {
              tooltip.reset();
              this.set('loading', true);
              this.roadSupervisor.enter().finally(() => this.set('loading', false));
              this.get('roadSupervisor').navigateToStop(model);
            }
          });
        },
        disableOnLockedRoute: true
      });
      this.set('performAction', {
        id: 'perform',
        permIds: [_mappedPermIds.default.markStopArrived, _mappedPermIds.default.markStopDeparted],
        name: 'Perform',
        action: model => {
          const dispatchRoute = model.get('dispatchRoute');
          const unperformedIQStops = dispatchRoute.findUnperformedStopsInSequence(model);
          const changesetIQStops = unperformedIQStops.map(stop => new _emberChangeset.default(stop));
          const changesetModel = new _emberChangeset.default(model);

          if (unperformedIQStops.length > 1) {
            this.get('workspace').pushState('performAllStop', {
              stopPoints: changesetIQStops
            }, {
              replaceSelf: true
            });
            return;
          }

          switch (model.get('stopType')) {
            case 'pick':
              {
                this.get('workspace').pushState('performPickStop', {
                  stopPoint: changesetModel
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'drop':
              {
                this.get('workspace').pushState('performDropStop', {
                  stopPoint: changesetModel
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'pulloutGarage':
              {
                const route = model.get('dispatchRoute.route');
                const routesStartComponent = this.get('routesStartComponent');
                route.set('odometer', '');
                route.set('timestamp', new Date());
                this.get('editModal').open(routesStartComponent, [route], this.get('saveRouteStartRecordFunction').bind(this));
                break;
              }

            case 'pullinGarage':
              {
                const route = model.get('dispatchRoute.route');
                const routesEndComponent = this.get('routesEndComponent');
                route.set('odometerInMiles', '');
                route.set('odometer', '');
                route.set('timestamp', new Date());
                this.get('editModal').open(routesEndComponent, [route], null, null, this.get('confirmSaveRouteEndFunction').bind(this));
                break;
              }

            case 'break':
              {
                this.get('workspace').pushState('performDriverBreak', {
                  stopPoint: changesetModel
                }, {
                  replaceSelf: true
                });
                break;
              }

            default:
              break;
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('unPerformAction', {
        id: 'unperform',
        permId: _mappedPermIds.default.unperformStop,
        name: 'Unperform',
        action: model => {
          const workspace = this.get('workspace');
          let stopType = model.get('stopType');
          let title = `Unperform ${Ember.String.capitalize(stopType)} Stop`;

          if (model.get('isNoShow')) {
            const trip = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
            stopType = trip.get('pickStopPoint.stopType'); // eslint-disable-next-line no-param-reassign

            model = (0, _unwrapProxy.unwrapProxy)(trip.get('pickStopPoint'));
            title = 'Unperform No Show';
            workspace.pushState('unPerformNoShow', {
              stopPoint: model,
              title
            }, {
              replaceSelf: true
            });
            return;
          }

          if (stopType === 'break') {
            title = 'Unperform Break';
          }

          switch (stopType) {
            case 'pick':
              {
                workspace.pushState('unPerformPickStop', {
                  stopPoint: model,
                  title
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'drop':
              {
                workspace.pushState('unPerformDropStop', {
                  stopPoint: model,
                  title
                }, {
                  replaceSelf: true
                });
                break;
              }

            case 'break':
              {
                workspace.pushState('unPerformBreak', {
                  stopPoint: model,
                  title
                }, {
                  replaceSelf: true
                });
                break;
              }

            default:
              break;
          }
        },
        disableOnLockedRoute: true
      });
      this.set('undoEndRoute', {
        id: 'undoEndRoute',
        permId: _mappedPermIds.default.undoRouteEnd,
        name: 'Undo Route End',
        action: model => {
          const route = model.get('route');
          route.set('odometerInMiles', '');
          route.set('timestamp', new Date());
          this.confirmSaveUndoRouteEndFunction(route);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('bulkActions', [{
        id: 'bulkEdit',
        permId: _mappedPermIds.default.editStop,
        name: 'Bulk Edit',
        action: models => {
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, models);
        },
        disableOnLockedRoute: true
      }]);
    },

    _initScheduleSingleActions() {
      this.set('cancelAction', {
        id: 'cancelTrip',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: model => {
          const cancelTripComponent = this.get('cancelTripComponent');
          const trip = (0, _unwrapProxy.unwrapProxy)(model.get('trip'));
          const booking = (0, _unwrapProxy.unwrapProxy)(trip.get('booking'));
          this.get('editModal').open(cancelTripComponent, [booking.get('legs.firstObject.segments.firstObject')], this.get('saveCancelRecordFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('schedulingAddDriverBreakAction', {
        id: 'addDriverBreak',
        permId: _mappedPermIds.default.addBreak,
        name: 'Add Driver Break',
        action: model => {
          const route = (0, _unwrapProxy.unwrapProxy)(model.get('route'));
          this.get('workspace').pushState('schedulingAddBreak', {
            route
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
    },

    _dynamicAVLMActions() {
      const noshowAction = this.get('noShowAction');
      const breakEditAction = this.get('editDriverBreakAction');
      const breakAddAction = this.get('addDriverBreakAction');
      const breakDelAction = this.get('delDriverBreakAction');
      const reorderAction = this.get('reorderStopsAction');
      const navigateToAction = this.get('navigateToAction');
      const performAction = this.get('performAction');
      const unperformAction = this.get('unPerformAction');
      const row = this.getSelectedCoreModel();
      const record = row;
      const stopType = row ? row.get('stopType') : null;
      if (!row) return [];

      if (this.get('stopsReorder.reorderInProgress')) {
        return [];
      }

      this.appendStopTypeToActionLabel(unperformAction, record); // there's an off chance the avlm-route-exec event won't update the route status to started / in-progress to keep no-show available

      let routeStarted = [_dispatchRoute.ROUTE_IN_PROGRESS_STATUS, _dispatchRoute.ROUTE_STARTED_STATUS, _dispatchRoute.ROUTE_AWAITING_STATUS].includes(row.get('dispatchRoute.computedStatus'));
      let route = record.get('dispatchRoute') || record.get('avlmCluster.route');
      let routeChecked = false;
      let isPerformedTripStops = false;

      if (route) {
        const model = route;
        routeChecked = this.get('activeContext.topActiveContext').isRecordChecked(model);
      }

      const multiRecordsChecked = this.get('activeContext.topActiveContext').isMultiRecordsChecked(route);
      const isTimelineSpanMultipleDays = this.get('workspace.isTimelineSpanMultipleDays');
      let reorderEligible = false;

      if (routeChecked && !multiRecordsChecked && !isTimelineSpanMultipleDays) {
        reorderEligible = true;
      }

      const isRoleSupervisor = this.user.isRoadSupEnable(); //Update isPerformedTripStops flag and Check whether any stop is performed after current stop.

      if (row.get('computedStatus') && !Ember.isEmpty(route) && !Ember.isEmpty(route.get('orderedStopsWithGaragesAndBreaks'))) {
        const allStops = route.get('orderedStopsWithGaragesAndBreaks');
        isPerformedTripStops = allStops.some(item => item.get('routeOrdinal') > row.get('routeOrdinal') && item.get('isPerformedOrBeingPerformed'));
      }

      switch (stopType) {
        case 'pick':
          {
            switch (row.get('status')) {
              case 'Arrive':
                {
                  if (isRoleSupervisor) {
                    return [breakAddAction, noshowAction, performAction, navigateToAction];
                  }

                  return [breakAddAction, noshowAction, performAction];
                }

              case 'Depart':
                {
                  if (isRoleSupervisor) {
                    if (isPerformedTripStops) {
                      return [breakAddAction, navigateToAction];
                    }

                    return [breakAddAction, navigateToAction, unperformAction];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [breakAddAction, unperformAction];
                }

              case 'Scheduled':
                {
                  if (row.get('actualArrivalTime') === null || Ember.isNone(row.get('actualArrivalTime'))) {
                    return [breakAddAction];
                  }

                  return [breakAddAction];
                }

              case 'Cancelled':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              case 'No Show':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [unperformAction, breakAddAction];
                }

              case 'Pending':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              default:
                {
                  if (routeStarted) {
                    return [breakAddAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      if (routeStarted) {
                        return [breakAddAction, performAction];
                      }

                      return [breakAddAction, reorderAction, performAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, performAction];
                  }

                  return [performAction];
                }
            }
          }

        case 'drop':
          {
            switch (row.get('status')) {
              case 'Arrive':
                {
                  if (isRoleSupervisor) {
                    return [breakAddAction, performAction, navigateToAction];
                  }

                  return [breakAddAction, performAction];
                }

              case 'Depart':
                {
                  if (isRoleSupervisor) {
                    if (isPerformedTripStops) {
                      return [breakAddAction, navigateToAction];
                    }

                    return [breakAddAction, navigateToAction, unperformAction];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [breakAddAction, unperformAction];
                }

              case 'Cancelled':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              case 'No Show':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [unperformAction, breakAddAction];
                }

              case 'Pending':
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              default:
                {
                  if (routeStarted) {
                    return [breakAddAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      if (routeStarted) {
                        return [breakAddAction, performAction];
                      }

                      return [breakAddAction, reorderAction, performAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, performAction];
                  }

                  return [];
                }
            }
          }

        case 'break':
          {
            routeStarted = ['In Progress', 'Started'].includes(row.get('dispatchRoute.computedStatus'));
            route = row.get('dispatchRoute');

            switch (row.get('routeBreak.status')) {
              case 'OnBreak':
                return [breakAddAction, performAction];

              case 'OffBreak':
                return [breakAddAction];

              default:
                {
                  if (routeStarted) {
                    return [breakAddAction, breakDelAction, breakEditAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (routeChecked && !multiRecordsChecked && !routeStarted) {
                      return [breakAddAction, breakDelAction, breakEditAction, reorderAction, performAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction, performAction];
                  }

                  return [];
                }
            }
          }

        case 'pulloutGarage':
          {
            routeStarted = ['In Progress', 'Started'].includes(row.get('dispatchRoute.computedStatus'));

            if (isRoleSupervisor) {
              return [breakAddAction, navigateToAction];
            }

            if (!routeStarted) {
              return [breakAddAction, performAction];
            }

            return [breakAddAction];
          }

        case 'pullinGarage':
          {
            routeStarted = ['In Progress', 'Started'].includes(row.get('dispatchRoute.computedStatus'));

            if (isRoleSupervisor) {
              return [breakAddAction, navigateToAction];
            }

            if (routeStarted) {
              return [breakAddAction, performAction];
            }

            return [breakAddAction];
          }

        case 'deployStop':
          {
            if (isRoleSupervisor) {
              return [navigateToAction];
            }

            return [];
          }

        default:
          {
            return [];
          }
      }
    },

    _dynamicDispatchActions() {
      const noshowAction = this.get('noShowAction');
      const breakEditAction = this.get('editDriverBreakAction');
      const breakAddAction = this.get('addDriverBreakAction');
      const cancelAction = this.get('cancelAction');
      const breakDelAction = this.get('delDriverBreakAction');
      const reorderAction = this.get('reorderStopsAction');
      const navigateToAction = this.get('navigateToAction');
      const performAction = this.get('performAction');
      const unperformAction = this.get('unPerformAction');
      const undoEndRoute = this.get('undoEndRoute');
      const row = this.getSelectedCoreModel();
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      if (!row) return [];
      const record = row;
      const stopType = record.get('stopType') || selectedWidgetRecord.type;

      if (this.get('stopsReorder.reorderInProgress')) {
        return [];
      }

      this.appendStopTypeToActionLabel(unperformAction, record);
      let route = record.get('dispatchRoute') || record.get('avlmCluster.route');
      let routeStarted = [_dispatchRoute.ROUTE_IN_PROGRESS_STATUS, _dispatchRoute.ROUTE_STARTED_STATUS, _dispatchRoute.ROUTE_AWAITING_STATUS, _dispatchRoute.ROUTE_ON_BREAK_STATUS, _dispatchRoute.ROUTE_SIGNED_OFF_STATUS].includes(row.get('dispatchRoute.computedStatus'));
      const routeEnded = [_dispatchRoute.ROUTE_ENDED_STATUS].includes(row.get('dispatchRoute.computedStatus')) || row.get('dispatchRoute.pullinGarageStop.isPerformed');
      let routeChecked = false;
      let isPerformedTripStops = false;

      if (route) {
        const model = route;
        routeChecked = this.get('activeContext.topActiveContext').isRecordChecked(model);
      }

      const multiRecordsChecked = this.get('activeContext.topActiveContext').isMultiRecordsChecked(route);
      let reorderEligible = false;

      if (routeChecked && !multiRecordsChecked) {
        reorderEligible = true;
      }

      const isRoleSupervisor = this.user.isRoadSupEnable(); //Update isPerformedTripStops flag and Check whether any stop is performed after current stop.

      if (row.get('computedStatus') && !Ember.isEmpty(route) && !Ember.isEmpty(route.get('orderedStopsWithGaragesAndBreaks'))) {
        const allStops = route.get('orderedStopsWithGaragesAndBreaks');
        isPerformedTripStops = allStops.some(item => item.get('routeOrdinal') > row.get('routeOrdinal') && item.get('isPerformedOrBeingPerformed') && !item.get('isNoShow'));
      }

      switch (stopType) {
        case 'pick':
          {
            switch (selectedWidgetRecord.status) {
              case _stopPoint.ARRIVE_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [breakAddAction, noshowAction, performAction, navigateToAction];
                  }

                  return [breakAddAction, noshowAction, performAction];
                }

              case _stopPoint.PERFORMED_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    if (isPerformedTripStops) {
                      return [breakAddAction, navigateToAction];
                    }

                    return [breakAddAction, navigateToAction, unperformAction];
                  }

                  if (routeEnded) {
                    return [];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [breakAddAction, unperformAction];
                }

              case _stopPoint.SCHEDULED_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  if (row.get('actualArrivalTime') === null || Ember.isNone(row.get('actualArrivalTime'))) {
                    return [breakAddAction];
                  }

                  return [breakAddAction];
                }

              case _stopPoint.CANCELLED_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              case _stopPoint.NOSHOW_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  if (routeEnded) {
                    return [];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [unperformAction, breakAddAction];
                }

              case _stopPoint.PENDING_STOP_STATUS:
                {
                  if (routeStarted) {
                    if (reorderEligible) {
                      return [breakAddAction, performAction, reorderAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      return routeStarted ? [breakAddAction] : [breakAddAction, reorderAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, cancelAction];
                  }

                  if (isRoleSupervisor) {
                    return [navigateToAction, performAction];
                  }

                  return [];
                }

              default:
                {
                  // const nextUnperformedStop = row.get('dispatchRoute.firstUnperformedStop');
                  if (routeStarted) {
                    if (reorderEligible) {
                      return [breakAddAction, performAction, reorderAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      return routeStarted ? [breakAddAction] : [breakAddAction, reorderAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, cancelAction];
                  }

                  if (isRoleSupervisor) {
                    return [navigateToAction, performAction];
                  }

                  return [];
                }
            }
          }

        case 'drop':
          {
            switch (selectedWidgetRecord.status) {
              case _stopPoint.ARRIVE_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [breakAddAction, performAction, navigateToAction];
                  }

                  return [breakAddAction, performAction];
                }

              case _stopPoint.PERFORMED_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    if (isPerformedTripStops) {
                      return [breakAddAction, navigateToAction];
                    }

                    return [breakAddAction, navigateToAction, unperformAction];
                  }

                  if (routeEnded) {
                    return [];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [breakAddAction, unperformAction];
                }

              case _stopPoint.CANCELLED_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              case _stopPoint.NOSHOW_STOP_STATUS:
                {
                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  if (routeEnded) {
                    return [];
                  }

                  if (isPerformedTripStops) {
                    return [breakAddAction];
                  }

                  return [breakAddAction];
                }

              case _stopPoint.PENDING_STOP_STATUS:
                {
                  if (routeStarted) {
                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    if (reorderEligible) {
                      return [breakAddAction, performAction, reorderAction];
                    }

                    return [breakAddAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      return routeStarted ? [breakAddAction] : [breakAddAction, reorderAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, cancelAction];
                  }

                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }

              default:
                {
                  if (routeStarted) {
                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    if (reorderEligible) {
                      return [breakAddAction, performAction, reorderAction];
                    }

                    return [breakAddAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (reorderEligible) {
                      return routeStarted ? [breakAddAction] : [breakAddAction, reorderAction];
                    }

                    if (isRoleSupervisor) {
                      return [navigateToAction];
                    }

                    return [breakAddAction, cancelAction];
                  }

                  if (isRoleSupervisor) {
                    return [navigateToAction];
                  }

                  return [];
                }
            }
          }

        case 'break':
          {
            routeStarted = row.get('dispatchRoute.hasStarted');
            route = row.get('dispatchRoute');

            switch (row.get('status')) {
              case _stopPoint.ON_BREAK_STOP_STATUS:
                if (isPerformedTripStops) {
                  return [breakAddAction, performAction];
                }

                return [breakAddAction, performAction, unperformAction];

              case _stopPoint.OFF_BREAK_STOP_STATUS:
                if (routeEnded) {
                  return [];
                }

                if (isPerformedTripStops) {
                  return [breakAddAction];
                }

                return [breakAddAction, unperformAction];

              case _stopPoint.PENDING_STOP_STATUS:
                {
                  if (routeStarted) {
                    if (reorderEligible) {
                      return [breakAddAction, breakDelAction, breakEditAction, performAction, reorderAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (routeChecked && !multiRecordsChecked && !routeStarted && reorderEligible) {
                      return [breakAddAction, breakDelAction, breakEditAction, reorderAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction];
                  }

                  return [];
                }

              default:
                {
                  if (routeStarted) {
                    if (reorderEligible) {
                      return [breakAddAction, breakDelAction, breakEditAction, performAction, reorderAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction, performAction];
                  }

                  if (!Ember.isNone(route)) {
                    if (routeChecked && !multiRecordsChecked && !routeStarted) {
                      return [breakAddAction, breakDelAction, breakEditAction, reorderAction];
                    }

                    return [breakAddAction, breakDelAction, breakEditAction];
                  }

                  return [];
                }
            }
          }

        case 'pulloutGarage':
          {
            routeStarted = row.get('dispatchRoute.hasStarted');

            if (isRoleSupervisor) {
              return [breakAddAction, navigateToAction];
            }

            if (!routeStarted) {
              return [breakAddAction, performAction];
            }

            if (routeEnded) {
              return [];
            }

            return [breakAddAction];
          }

        case 'pullinGarage':
          {
            routeStarted = row.get('dispatchRoute.hasStarted');
            const allStopsPerformed = row.get('dispatchRoute').allStopsPerformed();

            if (isRoleSupervisor) {
              return [breakAddAction, navigateToAction];
            }

            if (routeStarted) {
              if (routeEnded || row.get('computedStatus') === _stopPoint.PULLED_IN_STOP_STATUS) {
                return [undoEndRoute];
              }

              if (allStopsPerformed) {
                return [breakAddAction, performAction];
              }

              return [breakAddAction];
            }

            return [breakAddAction];
          }

        default:
          {
            if (isRoleSupervisor) {
              return [navigateToAction];
            }

            return [];
          }
      }
    },

    _dynamicScheduleActions() {
      const row = this.getSelectedCoreModel();
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      if (!row) return [];
      const record = row;
      const stopType = record.get('stopType') || selectedWidgetRecord.type;

      switch (stopType) {
        case 'pick':
        case 'drop':
          {
            const trip = (0, _unwrapProxy.unwrapProxy)(record.get('trip'));
            const tripStatus = `${trip.get('computedStatus')}`.toLowerCase();
            return this.getScheduleTripActions(tripStatus);
          }

        case 'break':
          {
            return [];
          }

        default:
          return [];
      }
    },

    getScheduleTripActions: function (tripStatus) {
      const cancelTripAction = this.get('cancelAction');
      const schedulingAddDriverBreakAction = this.get('schedulingAddDriverBreakAction');

      switch (tripStatus) {
        //trip status is completed,canceled and no show restrict cancel-trip and add-driver-break actions
        case 'completed':
        case 'canceled':
        case 'no show':
          return [];
        //trip status is illegal show cancel-trip action

        case 'illegal':
          {
            return [cancelTripAction];
          }

        default:
          return [cancelTripAction, schedulingAddDriverBreakAction];
      }
    },

    calculateTimeQuery() {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const dateRangeFilterQuery = (0, _rqlGenerators.dateBetweenRQLUsingFormat)('promiseTime', [startTime, endTime], 'YYYY-MM-DDTHH:mm:ss.SSS');
      return dateRangeFilterQuery;
    },

    // server dependency table overwrites
    applyTransformationData(widgetRecords) {
      const isScheduleDashboard = this.get('workspace.isScheduleDashboard');
      widgetRecords.forEach(widgetRecord => {
        if (!widgetRecord._transformed) {
          this.applyOtpStatusTransformation(widgetRecord);
          this.applyStopTypeTransformation(widgetRecord);
          this.applyOdometerTransformation(widgetRecord);
          this.applySlackTimeTransformation(widgetRecord);

          if (_environment.default.APP.avlmLite) {
            this.applyStopIdTransformation(widgetRecord);
          } else if (isScheduleDashboard) {
            this.applyOrdinalTransformation(widgetRecords);
            this.applyScheduleStopIdTransformation(widgetRecord);
            this.applyCoreModelTransformation(widgetRecord);
          } else {
            this.applyStopPointPkTransformation(widgetRecord);
          }

          widgetRecord._transformed = true;
        }
      });
      return widgetRecords;
    },

    applyCoreModelTransformation(widgetRecord) {
      const coreModelName = this.get('config.modelName');

      if (this.get('getCoreModelNameByWidgetRecord')) {
        widgetRecord.coreModel = this.get('getCoreModelNameByWidgetRecord')(coreModelName, widgetRecord);
      }
    },

    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) {
      let rowStyleHandler = '';

      if (currRowData.isLocked) {
        rowStyleHandler += ' is-locked';
      }

      if (columnIndex === 0) {
        if (currRowData.otpStatus === 'Late') {
          rowStyleHandler += ' otp-late-first-column';
        }

        if (currRowData.otpStatus === 'On Time') {
          rowStyleHandler += ' otp-on-time-first-column';
        }

        if (currRowData.otpStatus === 'In Danger') {
          rowStyleHandler += ' otp-in-danger-first-column';
        }
      }

      return rowStyleHandler;
    },

    canFurtherSelectionAllowed() {
      const gridConfig = this.get('config');
      const maxLimitByRouteSelection = gridConfig.maxLimitByRouteSelection;
      const maxLimitWithoutRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;
      const selectedStopRecords = this.get('widgetService').getSelectedWidgetRecordsByModel('stopWidget');
      let stopAssociatedRouteIds = selectedStopRecords.filter(data => !!data.routeId).map(data => data.routeId);
      stopAssociatedRouteIds = _lodash.default.uniq(stopAssociatedRouteIds);

      if (stopAssociatedRouteIds.length >= maxLimitByRouteSelection) {
        return false;
      }

      if (selectedStopRecords.length >= maxLimitWithoutRouteAssociation) {
        return false;
      }

      return true;
    },

    applySlackTimeTransformation(widgetRecord) {
      if (widgetRecord.slack && typeof widgetRecord.slack === 'number') {
        widgetRecord.slack = Math.round(widgetRecord.slack / 60);
      } else {
        // set default to 0
        widgetRecord.slack = 0;
      }
    },

    applyOdometerTransformation(widgetRecord) {
      if (widgetRecord.odometerOnArrival) {
        widgetRecord.odometerOnArrival = Math.round(widgetRecord.odometerOnArrival * 0.000621371192);
      }

      if (widgetRecord.odometerOnArrival === 0) {
        widgetRecord.odometerOnArrival = null;
      }
    },

    applyStopIdTransformation(widgetRecord) {
      if (!widgetRecord.id) return;

      if (widgetRecord.id.startsWith('SP')) {
        widgetRecord.id = widgetRecord.id.replace('SP', 'P');
      }

      if (widgetRecord.id.startsWith('SD')) {
        widgetRecord.id = widgetRecord.id.replace('SD', 'D');
      } // TODO: remove workaround for break point id not matched.


      if (widgetRecord.type === 'break' && !widgetRecord.id.startsWith('B')) {
        widgetRecord.id = 'B' + widgetRecord.id;
      }
    },

    applyStopPointPkTransformation(widgetRecord) {
      widgetRecord.stopPointId = widgetRecord.id;

      if (widgetRecord.stopPointPk) {
        widgetRecord.id = widgetRecord.stopPointPk;
      }
    },

    applyScheduleStopIdTransformation(widgetRecord) {
      if (widgetRecord.type === 'break' || widgetRecord.type === 'pick' || widgetRecord.type === 'drop') {
        widgetRecord.stopPk = widgetRecord.id;
        widgetRecord.id = widgetRecord.id.substr(1);
      }
    },

    applyStopTypeTransformation(widgetRecord) {
      try {
        if (!widgetRecord.type && widgetRecord.id) {
          if (widgetRecord.id.includes('GP')) {
            widgetRecord.type = 'pulloutGarage';
          } else if (widgetRecord.id.includes('GD')) {
            widgetRecord.type = 'pullinGarage';
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },

    /**
     * Re-numbering ordinal for Stop Widget Record only for schedule
     * @param widgetRecords
     */
    applyOrdinalTransformation(widgetRecords) {
      const groupedData = _lodash.default.groupBy(widgetRecords, 'routeName');

      _lodash.default.each(groupedData, value => {
        const filteredValues = _lodash.default.filter(value, val => {
          return val.type === 'pick' || val.type === 'drop' || val.type === 'break';
        }); // sort by eta property


        const sortedValues = filteredValues.sort((a, b) => {
          const aVal = (0, _moment.default)(a.eta);
          const bVal = (0, _moment.default)(b.eta);
          return aVal.diff(bVal);
        });

        _lodash.default.each(sortedValues, (data, index) => {
          data.scheduleOrdinal = index + 1;
        });
      });
    },

    /**
     * Stop record with status 'R' should not be displayed
     * @param header
     * @param body
     * @returns {string|*}
     */
    determineWidgetRecordAction(widgetRecords, header, data) {
      if (data.status === 'R') {
        return 'DELETE';
      }

      return this._super(...arguments);
    },

    removeDeletedItems(data) {
      if (data.type === 'break') {
        const routeBreak = this.get('store').peekRecord('route-break', data.routeBreakId);

        if (routeBreak && routeBreak.get('cluster')) {
          const cluster = this.get('store').peekRecord('cluster', routeBreak.get('cluster.id'));
          const dispatchRoute = cluster.get('dispatchRoute');
          cluster.set('dispatchRoute', null);
          dispatchRoute.notifyPropertyChange('clusters');
        }
      }

      if (data.type === 'pick' || data.type === 'drop') {
        const stopPoint = this.get('store').peekRecord('stop-point', data.stopPointPk);

        if (stopPoint && stopPoint.get('cluster')) {
          const cluster = this.get('store').peekRecord('cluster', stopPoint.get('cluster.id'));
          const dispatchRoute = cluster.get('dispatchRoute');
          cluster.set('dispatchRoute', null);
          dispatchRoute.notifyPropertyChange('clusters');
        }
      }
    }

  });

  _exports.default = _default;
});