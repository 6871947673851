define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/employment-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "STJjC/dP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"table-container\"],[9],[0,\"\\n  \"],[1,[27,\"iq-widgets/vehicle-driver-manager-widget/tabs/lookup/employment-status/table\",null,[[\"tableRef\"],[[23,[\"tableRef\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"form-panel-wrapper\"],[9],[0,\"\\n\"],[4,\"generic-components/panel\",null,[[\"class\",\"title\",\"isCollapsible\"],[\"route-information-panel\",\"Employment Status\",false]],{\"statements\":[[0,\"    \"],[7,\"form\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n            \"],[7,\"label\"],[11,\"class\",\"label\"],[9],[0,\"Description\"],[10],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"placeholder\",\"disabled\",\"type\",\"class\",\"input\",\"value\"],[\"Enter Name\",[23,[\"disableForm\"]],\"text\",\"description-box\",[27,\"action\",[[22,0,[]],\"onTextChange\",[23,[\"employeeStatusChangeSet\"]],\"displayName\"],null],[23,[\"employeeStatusChangeSet\",\"displayName\"]]]]],false],[0,\"\\n          \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/employment-status/template.hbs"
    }
  });

  _exports.default = _default;
});