define("adept-iq/pods/components/side-drawer/permissions-add-role/component", ["exports", "ember-concurrency", "lodash", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _lodash, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['compose-role-side-drawer'],
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    onCloseClick: null,
    record: null,
    callBack: Ember.computed.readOnly('workspace.topOptions.action'),
    roles: Ember.computed.readOnly('stackFrame.options.roles'),
    title: Ember.computed.readOnly('stackFrame.options.displayName'),
    roleData: Ember.computed.readOnly('stackFrame.options.roleData'),
    canSave: Ember.computed('isUndoEnable', function () {
      const isUndoEnable = this.get('isUndoEnable');
      return isUndoEnable;
    }),
    canUndo: Ember.computed('isUndoEnable', function () {
      const isUndoEnable = this.get('isUndoEnable');
      return isUndoEnable;
    }),

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.refresh();
    },

    initSections() {
      const sections = this.preparePermissionSections();
      this.set('sections', sections);
    },

    isValid() {
      let isValid = true;
      const roles = this.get('roles');
      const isNewRole = roles[0].get('isNew');
      Ember.set(this, 'errorMessages', []);
      this.get('roleData').map(data => {
        if (roles[0].displayName === data.displayName && isNewRole) {
          isValid = false;
          this.errorMessages.push('* Duplicated role name');
          return;
        } else if (roles[0].displayName === data.displayName && data.id !== roles[0].id) {
          isValid = false;
          this.errorMessages.push('* Duplicated role name');
          return;
        }
      });
      return isValid;
    },

    createPermissionSection(permissions) {
      if (permissions.length === 0) return;
      const permission = permissions[0];
      const noOfCheckedPermissions = [];
      permissions.map(value => {
        if (permission && permission.category.toLowerCase() === value.category.toLowerCase() && value.state) {
          if (value.state) {
            noOfCheckedPermissions.push(value);
          }
        }
      });
      return {
        title: Ember.String.capitalize(`${permission.category} Permissions (${noOfCheckedPermissions.length}/${permissions.length})`),
        fields: [{
          id: `${permission.category}-Permissions`,
          type: 'permission-section',
          valuePath: 'permissionHash',
          modelName: 'sso-permission',
          isLabel: false,
          extra: {
            category: `${permission.category}`,
            groupedPermissions: permissions
          }
        }]
      };
    },

    preparePermissionSections() {
      const groupedPermissions = this.get('groupedPermissions');
      const permKeys = (0, _lodash.keys)(groupedPermissions) || [];
      this.set('categories', permKeys);
      const sections = permKeys.map(key => {
        return this.createPermissionSection(groupedPermissions[key]);
      });
      return sections;
    },

    refresh() {
      this.get('refreshTask').perform();
    },

    reset() {},

    refreshTask: (0, _emberConcurrency.task)(function* () {
      yield this.get('refreshPermissionTask').perform();
    }),
    refreshPermissionTask: (0, _emberConcurrency.task)(function* () {
      const roles = this.get('roles') || [];
      const permissions = yield this.get('store').findAll('sso-permission');
      permissions.map(data => {
        if (data && data.state) {
          data.set('state', false);
        }
      });
      const groupedPermissions = (0, _lodash.groupBy)(permissions.toArray(), function (value) {
        return value.category.toLowerCase();
      });
      this.set('permissions', permissions);
      this.set('groupedPermissions', groupedPermissions);
      roles.forEach(role => {
        role.reconfigurePermissionHash(permissions);
        role.reconfigurePermissionCheck(permissions);
        const displayRoleName = role.get('displayName');
        this.set('displayRoleName', displayRoleName);
      });
      this.initSections();
    }),
    refreshGroupedPermTask: (0, _emberConcurrency.task)(function* () {
      const permissions = yield this.get('store').findAll('sso-permission');
      const groupedPermissions = (0, _lodash.groupBy)(permissions.toArray(), function (value) {
        return value.category.toLowerCase();
      });
      this.set('groupedPermissions', groupedPermissions);
      this.initSections();
    }),
    saveTask: (0, _emberConcurrency.task)(function* () {
      // using custom api call because sso api is non standard
      // and in-consistent.
      const ajax = this.get('ajax');
      const session = this.get('session');
      const roles = this.get('roles') || [];
      const notifications = this.get('notifications');
      const rolePermAssociations = yield ajax.request(`${_apiUrls.API.ssoService.host}/role-permission`, {
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      });
      yield Promise.all(roles.map(async record => {
        let currentPerms = record.get('permissionList');
        const displayName = record.get('displayName');
        const roleName = record.get('roleName') ? record.get('roleName') : record.get('displayName').replace(/\s+/, '');
        const rolePermAssocByRole = record.get('isNew') ? [] : rolePermAssociations.filter(rolePerm => rolePerm.roleId === roleName);
        currentPerms = currentPerms.sort((a, b) => a - b); // create a new role

        if (record.get('isNew')) {
          const json = {
            roleName: roleName,
            status: 'active',
            displayName
          };
          await ajax.post(`${_apiUrls.API.ssoService.host}/role`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).catch(e => {
            notifications.warning(`Unable to Create Role ${displayName}`);
            return Promise.reject(e); // failed to create role, won't be able to assign permissions

            console.error(e); // eslint-disable-line
          });
        } // otherwise patch the role
        else {
            const json = {
              displayName,
              status: 'active'
            };
            await ajax.patch(`${_apiUrls.API.ssoService.host}/role/${roleName}`, {
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${session.data.authenticated.token}`
              },
              data: json
            }).catch(e => {
              notifications.warning(`Unable to Update Role ${displayName}`);
              console.error(e); // eslint-disable-line
            });
          }

        const newPermList = Object.entries(record.get('permissionHash')).map(([key, value]) => {
          if (value) {
            return parseInt(key, 10);
          }

          return null;
        }).filter(val => Ember.isPresent(val)).sort((a, b) => a - b);
        const removedPermList = Object.entries(record.get('permissionHash')).map(([key, value]) => {
          if (!value) {
            return parseInt(key, 10);
          }

          return null;
        }).filter(val => Ember.isPresent(val)).sort((a, b) => a - b); // remove any permissions

        const removedPermissions = (0, _lodash.intersection)(currentPerms, removedPermList) || [];
        await removedPermissions.reduce((acu, permId) => {
          const removedPermAssocId = rolePermAssocByRole.find(rolePerm => rolePerm.permissionId === permId);
          return acu.then(() => ajax.del(`${_apiUrls.API.ssoService.host}/role-permission/${removedPermAssocId.id}`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          }).catch(e => {
            notifications.warning(`Unable to Remove Permission ${permId}`);
            console.error(e); // eslint-disable-line
          }));
        }, Promise.resolve()); // add any new permissions

        const newPermissions = (0, _lodash.difference)(newPermList, currentPerms) || [];
        return newPermissions.reduce((acu, permId) => {
          const json = {
            roleId: roleName,
            permissionId: permId
          };
          return acu.then(() => ajax.post(`${_apiUrls.API.ssoService.host}/role-permission`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).catch(e => {
            notifications.warning(`Unable to Save New Permission ${permId}`);
            console.error(e); // eslint-disable-line
          }));
        }, Promise.resolve());
      })).then(async () => {// await this.get('store').findAll('perm-sso-role');
        // await this.get('workspaceContext').get('reloadWorkspace').perform();
        // return this.get('activeContext.topActiveContext').refreshTableContent.perform(['perm-sso-role']);
      }).catch(e => {
        console.error(e); // eslint-disable-line
      });
    }),

    showToolTip() {
      Ember.set(this, 'errorMessages', []);
      const tooltip = this.get('tooltip');
      const role = this.get('roles')[0];
      const isNewRole = role.get('isNew');
      const tip = isNewRole ? 'Do you want to add a new role?' : 'Do you want to commit the changes?';
      const notification = isNewRole ? 'ADDED ROLE SUCCESSFULLY' : 'EDITED ROLE SUCCESSFULLY';
      tooltip.pushConfirmation({
        title: isNewRole ? 'Add Role' : 'Edit Role',
        tip,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.get('saveTask').perform().then(() => {
            this.send('cancel');
            this.get('notifications').success(`${notification}`);
            this.get('workspace').popState('permAddRole');
            return this.onCloseClick();
          }).catch(() => {
            this.get('notifications').warning(`${notification}`);
          }).finally(() => {
            return tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return tooltip.reset();
        }
      });
    },

    actions: {
      onValueChange(record, valuePath, options) {
        const value = options;
        record.set(valuePath, value);
        this.set('isUndoEnable', true);
      },

      onPermChange(record, valuePath, options) {
        const permHash = record.get(valuePath);
        const {
          id,
          state
        } = options;
        Ember.set(permHash, id, state);
        record.set(valuePath, permHash);
        this.get('refreshGroupedPermTask').perform();
        this.set('isUndoEnable', true); // this.initSections();
      },

      onSaveRoleButtonClick() {
        if (this.isValid()) {
          this.showToolTip();
        }
      },

      cancel() {
        if (this.callBack) {
          this.callBack(true);
        }
      },

      onUndoClick() {
        const roles = this.get('roles') || [];
        roles.forEach(role => {
          role.reconfigurePermissionHash(this.permissions);
          role.set('displayName', this.displayRoleName);
        });
        this.refresh();
        this.set('isUndoEnable', false);
        this.initSections();
      },

      onCloseClick() {
        const roles = this.get('roles') || []; // rollback any dirty attributes unless it's new, remove from store

        roles.forEach(role => {
          role.rollbackAttributes();
        });
        this.onCloseClick();
      }

    }
  });

  _exports.default = _default;
});