define("adept-iq/serializers/driver-availability", ["exports", "adept-iq/serializers/-driver", "moment"], function (_exports, _driver, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _driver.default.extend({
    attrs: {
      calcEndDate: {
        serialize: false
      },
      DOWSelected: {
        serialize: false
      }
    },

    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      if (json.data.attributes.routeLength) {
        delete json.data.attributes.routeLength;
      }

      if (json.data && json.data.attributes) {
        const {
          shiftStart,
          shiftEnd
        } = json.data.attributes;
        json.data.attributes.startTime = (0, _moment.default)(shiftStart).toISOString();
        json.data.attributes.endTime = (0, _moment.default)(shiftEnd).toISOString();
        const formattedShiftStart = (0, _moment.default)(shiftStart).format('h:mm a');
        const formattedShiftEnd = (0, _moment.default)(shiftEnd).format('h:mm a');
        json.data.attributes.shiftStart = formattedShiftStart;
        json.data.attributes.shiftEnd = formattedShiftEnd;
      }

      return json;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType === 'createRecord' && Ember.typeOf(payload.data) === 'array') {
        if (payload.data.length === 1) {
          payload.data = payload.data[0];
        }
      }

      return this._super(...arguments);
    },

    normalize(modelClass, resourceHash) {
      // handle topic payload
      const {
        days
      } = resourceHash.attributes;

      if (days) {
        delete resourceHash.attributes.days;
        days.forEach((value, i) => {
          const weekday = _moment.default.weekdays(i).toLowerCase();

          resourceHash.attributes[weekday] = value;
        });
      }

      resourceHash.attributes = resourceHash.attributes || {};
      resourceHash.relationships = resourceHash.relationships || {};
      const {
        startTime,
        endTime
      } = resourceHash.attributes;

      if (startTime && endTime) {
        const startMoment = (0, _moment.default)(`${startTime}`);
        const endMoment = (0, _moment.default)(`${endTime}`);
        const diff = Math.abs(startMoment.diff(endMoment));

        const duration = _moment.default.duration(diff);

        const hours = Math.floor(duration.asHours());
        const formattedHours = hours < 10 ? `0${hours}` : hours;

        const routeLength = formattedHours + _moment.default.utc(diff).format(':mm');

        resourceHash.attributes.routeLength = routeLength;
        resourceHash.attributes.shiftStart = startMoment.toISOString();
        resourceHash.attributes.calcEndDate = endMoment.toISOString();
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});