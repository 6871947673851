define("adept-iq/classes/data-joins/table", ["exports", "adept-iq/classes/data-joins/base-table", "adept-iq/config/environment"], function (_exports, _baseTable, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const QUEUE_ID = 'wgt-refresh';

  var _default = _baseTable.default.extend({
    // passed in from generic table component
    table: null,
    activeContext: null,
    work: null,
    Row: null,
    forceSync: _environment.default.APP.test,

    initialize(record) {
      const Row = this.get('Row'); // a row class passed into data join

      const row = new Row(record);
      return row;
    },

    clear() {
      this.join([], {
        sync: true
      });
    },

    join(collection, opts = {
      sync: false
    }) {
      if (opts.sync || this.get('forceSync')) {
        // syncs & destroys some rows
        this._super(...arguments); // at this point, table probably contains some destroyed rows


        const tableRows = this.get('table.rows');
        const rows = this.get('nodes');
        if (!tableRows || !rows) return false; // skip update if possible

        if (rows.length === tableRows.length) {
          let isDifferent = false;

          for (let i = 0; i < rows.length; i++) {
            if (rows[i] !== tableRows[i]) {
              isDifferent = true;
              break;
            }
          }

          if (!isDifferent) return false;
        }

        tableRows.setObjects(rows);
        const queue = this.get('work.queues').findBy('name', QUEUE_ID);

        if (queue) {
          queue.addJob({
            collection,
            dataJoin: this
          });
          this.get('work').start();
        } // FIXME: this causes an exception purporting that a tag cannot be
        // created for a destroyed element. This should not be the case.
        // Possibly an Ember or LightTable bug.
        // unloads memory from exited rows
        // this.purgeCache();


        return true;
      }

      const queue = this.get('work.queues').findBy('name', QUEUE_ID);

      if (!queue) {
        throw new Error(`queue '${QUEUE_ID}' not registered`);
      }

      queue.addJob({
        collection,
        dataJoin: this
      });
      this.get('work').start();
    },

    exit(row) {
      row.setProperties({
        isSelected: false,
        isLastChecked: false
      });
    },

    release(row) {
      row.destroy();
    }

  });

  _exports.default = _default;
});