define("adept-iq/pods/components/side-drawer/system-config/places/table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'place',
    title: 'Places',
    defaultSortId: 'address',
    selectFirstRow: true,
    fetchModelData: true,
    defaultSortAsc: true,
    hideWidgetControls: true,
    columns: [{
      id: 'id',
      index: 0,
      type: 'string',
      label: 'ID',
      valuePath: 'id',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'name',
      index: 1,
      type: 'string',
      label: 'Name',
      valuePath: 'address.alias',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'address',
      index: 2,
      type: 'text',
      label: 'Address',
      valuePath: 'address.readableAddress',
      defaultWidth: 100,
      searchable: true,
      editable: true
    }, {
      id: 'zoneId',
      index: 3,
      type: 'text',
      label: 'Zone ID',
      valuePath: 'address.zoneName.name',
      defaultWidth: 70,
      searchable: true
    }, {
      id: 'type',
      index: 4,
      type: 'string',
      label: 'Type',
      valuePath: 'placeCategoryType.id',
      defaultWidth: 70,
      searchable: true
    }]
  };
  _exports.default = _default;
});