define("adept-iq/pods/components/iq-widgets/booking-trips-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/previous-trips-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'trip',
    defaultSortId: 'id',
    defaultSortAsc: true,
    hideWidgetControls: false,
    hideHeader: true,
    checkBoxColumn: true,
    isFormControls: true,
    title: 'View All trips',
    columns: [{
      id: 'requestedTime',
      index: 1,
      type: 'text',
      label: 'Date / Request / Time',
      valuePath: 'requestTimeForDisplay',
      valuePreview: '08:00',
      editable: true,
      hidden: false,
      format: 'YYYY-MM-DD / / hh:mm A',
      defaultWidth: 200
    }, {
      id: 'tripId',
      index: 2,
      type: 'uuid',
      isKey: true,
      label: 'Trip ID',
      valuePath: 'tripId',
      editable: false,
      searchable: true,
      hidden: false,
      defaultWidth: 90
    }, {
      id: 'status',
      index: 3,
      type: 'text',
      label: 'Status',
      valuePath: 'computedStatus',
      hidden: false,
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 70
    }, {
      id: 'serviceMode',
      index: 4,
      type: 'text',
      label: 'Service Mode',
      valuePath: 'segment.travelMode',
      hidden: false,
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 120
    }, {
      id: 'logId',
      index: 5,
      type: 'uuid',
      label: 'Leg ID',
      valuePath: 'segment.leg.id',
      editable: true,
      hidden: false,
      defaultWidth: 85
    }, {
      id: 'passengerId',
      index: 6,
      type: 'text',
      label: 'Passenger ID',
      valuePath: 'rider.riderId',
      editable: true,
      hidden: false,
      defaultWidth: 100
    }, {
      id: 'firstName',
      index: 7,
      type: 'text',
      label: 'First Name',
      valuePath: 'rider.firstName',
      editable: false,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'lastName',
      index: 8,
      type: 'text',
      label: 'Last Name',
      valuePath: 'rider.lastName',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 100,
      validation: {
        type: 'text',
        minValue: 1,
        maxValue: 30
      }
    }, {
      id: 'pickupaddress',
      index: 9,
      type: 'text',
      label: 'Pickup Address',
      valuePath: 'pick.segmentStop.place.address.simpleAddress',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 130
    }, {
      id: 'dropaddress',
      index: 10,
      type: 'text',
      label: 'Drop Address',
      valuePath: 'drop.segmentStop.place.address.simpleAddress',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 130
    }, {
      id: 'city',
      index: 11,
      type: 'text',
      label: 'City',
      valuePath: 'pick.segmentStop.place.address.region',
      editable: true,
      hidden: false,
      searchable: true,
      defaultWidth: 200
    }]
  };
  _exports.default = _default;
});