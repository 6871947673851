define("adept-iq/pods/components/iq-widgets/subscription-form/subscription-form/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['subscription-form'],
    statusOptions: null,
    suspensionTypes: null,
    activeFromMinDate: null,
    activeToMinDate: null,
    statusFlag: true,
    booking: Ember.inject.service(),

    setMaxDate() {
      const minDate = this.get('subscription.startDate');
      const maxDate = this.get('subscription.endDate');
      this.set('maxDate', maxDate);
      this.set('minDate', minDate);
    },

    suspendedDateVisibility: Ember.computed('subscription.exclusions.firstObject.suspend', function () {
      return !this.get('subscription.exclusions.firstObject.suspend');
    }),

    init() {
      this._super(...arguments);

      this.setMaxDate();
      this.setProperties({
        statusOptions: [{
          id: 'active',
          name: 'Active'
        }, {
          id: 'inactive',
          name: 'Inactive'
        }, {
          id: 'denied',
          name: 'Denied'
        }],
        suspensionTypes: [{
          id: 'suspension',
          name: 'Suspension'
        }, {
          id: 'clientRequested',
          name: 'Client Requested'
        }]
      });
      const subscriptionStatus = this.get('subscription.status') ? this.get('subscription.status') : 'active';
      const supensionType = this.get('subscription.exclusions.firstObject.type') ? this.get('subscription.exclusions.firstObject.type') : 'suspension';
      this.set('subscription.status', subscriptionStatus);
      this.set('subscription.exclusions.firstObject.type', supensionType);
      this.set('activeFromMinDate', this.getActiveSuspendMinDate('startDate'));
      this.set('activeToMinDate', this.getActiveSuspendMinDate('endDate'));
    },

    // NYAAR-13554 Subscription - From date is not displayed upon editing the subscription
    getActiveSuspendMinDate(dateType) {
      const minDate = this.get('isEditMode') ? this.getMinDate(this.get(`subscription.${dateType}`)) : 'today';
      return minDate;
    },

    getMinDate(date) {
      const currentDate = (0, _moment.default)().startOf('day');
      return (0, _moment.default)(date).diff(currentDate) > 0 ? 'today' : date;
    },

    actions: {
      toggleCalendar(flatrRef) {
        flatrRef.toggle();
      },

      onCheckBoxClick(path, event) {
        this.set(path, event.target.checked);
      },

      onDateChange(path, type, value) {
        const selectedDate = (0, _moment.default)(value[0]);
        const rTime = this.get('leg.requestTime');
        const currentDateAndTime = (0, _moment.default)(rTime);
        selectedDate.hour(currentDateAndTime.hour());
        selectedDate.minutes(currentDateAndTime.minutes());
        selectedDate.seconds(currentDateAndTime.seconds());
        const selectedDateObjectWithTime = selectedDate.toDate();
        const currentDate = (0, _moment.default)().startOf('day');

        if (path.indexOf('startDate') > -1) {
          const prevStartDate = type === 'Subscription' ? this.get('booking.previousSubscriptionStartDate') : this.get('booking.previousSubscriptionSuspendStartDate');
          this.get('booking').validateDateNotToBePastDate(selectedDateObjectWithTime, currentDate, prevStartDate, type, 'From');
        } else {
          const prevEndDate = type === 'Subscription' ? this.get('booking.previousSubscriptionEndDate') : this.get('booking.previousSubscriptionSuspendEndDate');
          this.get('booking').validateDateNotToBePastDate(selectedDateObjectWithTime, currentDate, prevEndDate, type, 'To');
        }

        this.set(path, selectedDateObjectWithTime);
        this.setMaxDate();
      }

    }
  });

  _exports.default = _default;
});