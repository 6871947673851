define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/lookup/physical-type/table/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rowComponent: 'generic-components/table/rows/row',
    // default row component
    rowSelectionType: 'radio',
    modelName: 'driver-physical-type',
    title: 'Physical Type',
    defaultSortId: 'displayName',
    defaultSortAsc: true,
    fetchModelData: false,
    selectFirstRow: true,
    columns: [{
      id: 'displayName',
      index: 0,
      type: 'text',
      label: 'Physical',
      valuePath: 'displayName',
      defaultWidth: 100,
      searchable: true
    }]
  };
  _exports.default = _default;
});