define("adept-iq/models/vehicle-road-call", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    callDate: attr({
      defaultValue: null
    }),
    mileage: attr('number'),
    notes: attr('string'),
    vehicle: belongsTo('vehicle'),
    provider: belongsTo('provider'),
    roadCallCodeName: belongsTo('road-call-code-name'),
    driver: belongsTo('driver'),
    route: belongsTo('route')
  });

  _exports.default = _default;
});