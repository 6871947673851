define("adept-iq/config/comparison-functions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nullCompare = nullCompare;
  _exports.stringCompare = stringCompare;
  _exports.numberCompare = numberCompare;
  _exports.booleanCompare = booleanCompare;
  _exports.dateCompare = dateCompare;
  _exports.default = void 0;

  function nullCompare(a, b) {
    return Ember.guidFor(a).localeCompare(Ember.guidFor(b));
  }

  function stringCompare(a, b) {
    if (Ember.typeOf(a) !== 'string' && Ember.typeOf(b) !== 'string') return 0;
    if (Ember.typeOf(a) !== 'string') return 1;
    if (Ember.typeOf(b) !== 'string') return -1;
    return a.localeCompare(b);
  }

  function numberCompare(a, b) {
    const aIsNone = Ember.isNone(a);
    const bIsNone = Ember.isNone(b); // can't compare two null things

    if (aIsNone && bIsNone) return 0; // null things come after non-null

    if (aIsNone) return 1;
    if (bIsNone) return -1;
    const x = parseFloat(a);
    const y = parseFloat(b);
    const xIsNaN = isNaN(x);
    const yIsNaN = isNaN(y); // can't compare two non-numeric things

    if (xIsNaN && yIsNaN) return 0; // non-numeric things come after numeric things

    if (xIsNaN) return 1;
    if (yIsNaN) return -1;
    return x - y;
  }

  function booleanCompare(a, b) {
    if (Ember.typeOf(a) !== 'boolean' && Ember.typeOf(b) !== 'boolean') return 0;
    if (Ember.typeOf(a) !== 'boolean') return 1;
    if (Ember.typeOf(b) !== 'boolean') return -1;
    if (a && b || !a && !b) return 0;
    if (a) return -1;
    return 1;
  }

  function dateCompare(a, b) {
    const _a = new Date(a);

    const _b = new Date(b);

    if (Ember.typeOf(_a) !== 'date' && Ember.typeOf(_b) !== 'date') return 0;
    if (Ember.typeOf(_a) !== 'date') return 1;
    if (Ember.typeOf(_b) !== 'date') return -1;
    return _a.getTime() - _b.getTime();
  }

  var _default = [nullCompare, stringCompare, numberCompare, booleanCompare, dateCompare];
  _exports.default = _default;
});