define("adept-iq/pods/components/change-password-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oq4cD11k",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[7,\"form\"],[12,\"onsubmit\",[27,\"action\",[[22,0,[]],\"onSubmit\",[23,[\"password\"]],[23,[\"newPassword\"]],[23,[\"confirmPassword\"]]],null]],[9],[0,\"\\n\\n  \"],[7,\"label\"],[9],[0,\"\\n    Enter Current Password\\n    \"],[7,\"input\"],[12,\"value\",[27,\"readonly\",[[23,[\"password\"]]],null]],[12,\"oninput\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"password\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"type\",\"password\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"\\n    Enter New Password\\n    \"],[7,\"input\"],[12,\"value\",[27,\"readonly\",[[23,[\"newPassword\"]]],null]],[12,\"oninput\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"newPassword\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"type\",\"password\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"\\n    Confirm New Password\\n    \"],[7,\"input\"],[12,\"value\",[27,\"readonly\",[[23,[\"confirmPassword\"]]],null]],[12,\"oninput\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"confirmPassword\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"type\",\"password\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn mt-2\"],[9],[0,\"\\n     Cancel\\n   \"],[3,\"action\",[[22,0,[]],\"redirectToLogin\"]],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn mt-2\"],[12,\"disabled\",[27,\"not\",[[23,[\"isValid\"]]],null]],[11,\"data-test-login-button\",\"\"],[11,\"type\",\"submit\"],[9],[0,\"\\n    OK\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"errorArr\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[11,\"class\",\"errors\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"errorArr\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/change-password-form/template.hbs"
    }
  });

  _exports.default = _default;
});