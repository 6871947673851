define("adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/accident/component", ["exports", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "adept-iq/utils/unwrapProxy", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/accident/validation", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/tabs/driver/edit-views/accident/vehicle-search-config"], function (_exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _unwrapProxy, _validation, _vehicleSearchConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['driver-accident-view'],
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    tableRef: null,
    disableForm: true,
    // passed into view
    editAPI: null,
    selectedDriver: Ember.computed.readOnly('editAPI.selectedDriver'),
    selectedDriverAccident: Ember.computed.readOnly('tableRef.checkedRows.firstObject'),
    selectedRow: Ember.computed.alias('tableRef.table.selectedRows.firstObject'),

    async init() {
      this.get('refreshTask').perform();

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      const editAPI = this.get('editAPI'); // ember concurrency tasks

      const onSaveDriverAccidentRecord = this.get('onSaveDriverAccidentRecord');
      const onDeleteDriverAccidentRecord = this.get('onDeleteDriverAccidentRecord'); // normal functions

      const onUndoDriverAccidentRecord = this.get('onUndoDriverAccidentRecord').bind(this);
      const onNewDriverAccidentRecord = this.get('onNewDriverAccidentRecord').bind(this);
      const onEditDriverAccidentRecord = this.get('onEditDriverAccidentRecord').bind(this);
      Ember.run.next(() => {
        editAPI.setProperties({
          saveAction: onSaveDriverAccidentRecord,
          newAction: onNewDriverAccidentRecord,
          deleteAction: onDeleteDriverAccidentRecord,
          editAction: onEditDriverAccidentRecord,
          undoAction: onUndoDriverAccidentRecord,
          onValidateAction: null
        });
        editAPI.buttonSettings();
      });
    },

    driverAccidentChangeSet: Ember.computed('selectedDriverAccident', function () {
      const driverAccident = (0, _unwrapProxy.unwrapProxy)(this.get('selectedDriverAccident'));
      return this.setDefaultProperties(driverAccident);
    }),

    setDefaultProperties(record = null) {
      const store = this.get('store');
      let pendingDriverAccidentRecord = this.get('pendingDriverAccidentRecord');

      if (record) {
        pendingDriverAccidentRecord = record;
      } else if (!pendingDriverAccidentRecord) {
        pendingDriverAccidentRecord = store.createRecord('driver-accident');
        this.set('pendingDriverAccidentRecord', pendingDriverAccidentRecord);
      }

      if (pendingDriverAccidentRecord.get('isNew')) {
        pendingDriverAccidentRecord.setProperties({
          notification: false,
          notificationDate: new Date(),
          accidentDate: new Date(),
          transitVehicleInvolved: false,
          description: null,
          vehicle: null,
          driver: this.get('selectedDriver')
        });
      }

      return new _emberChangeset.default(pendingDriverAccidentRecord, (0, _emberChangesetValidations.default)(_validation.VALIDATION), _validation.VALIDATION);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const selectedDriverAccident = this.get('selectedDriverAccident');

      if (selectedDriverAccident) {
        if (!selectedDriverAccident.get('isNew') && !selectedDriverAccident.get('isDeleted')) {
          yield selectedDriverAccident.reload();
        }
      }

      const tableRef = this.get('tableRef');
      if (tableRef) Ember.run.scheduleOnce('afterRender', tableRef, 'refreshData');
    }),
    validateDriverAccident: (0, _emberConcurrency.task)(function* () {
      const tooltip = this.get('tooltip');
      const driverAccidentChangeSet = this.get('driverAccidentChangeSet');
      yield driverAccidentChangeSet.validate();
      const errors = driverAccidentChangeSet.errors;

      if (errors.length > 0) {
        tooltip.pushConfirmation({
          title: 'Manage Driver',
          tip: '',
          errors,
          primaryActionText: 'OK',

          primaryAction() {
            return tooltip.popConfirmation();
          }

        });
        return false;
      }

      return true;
    }),
    onSaveDriverAccidentRecord: (0, _emberConcurrency.task)(function* () {
      const notifications = this.get('notifications');
      const pendingDriverAccidentRecord = this.get('driverAccidentChangeSet');
      const vehicle = (0, _unwrapProxy.unwrapProxy)(pendingDriverAccidentRecord.get('vehicle'));
      const editAPI = this.get('editAPI'); // validation

      const isValidDriverAccident = yield this.validateDriverAccident.perform();

      if (isValidDriverAccident) {
        try {
          if (Ember.isEmpty(vehicle)) {
            pendingDriverAccidentRecord.set('transitVehicleInvolved', false);
          } else {
            pendingDriverAccidentRecord.set('transitVehicleInvolved', true);
          }

          yield pendingDriverAccidentRecord.save();
          notifications.success('Record successfully saved');
          this.set('pendingDriverAccidentRecord', null);
          editAPI.buttonSettings();
          this.set('disableForm', true);
        } catch (e) {
          notifications.warning('Record failed to save');
          console.warn(e); // eslint-disable-line
        }

        this.get('refreshTask').perform();
      }
    }),
    onDeleteDriverAccidentRecord: (0, _emberConcurrency.task)(function* () {
      const selectedDriverAccident = this.get('selectedDriverAccident');
      const notifications = this.get('notifications');
      const tooltip = this.get('tooltip');
      const refreshTask = this.get('refreshTask');
      if (!selectedDriverAccident) return;
      yield tooltip.pushConfirmation({
        title: 'Manage Driver',
        tip: 'Are you sure you want to delete this record?',
        secondaryActionText: 'Cancel',

        secondaryAction() {
          return tooltip.reset();
        },

        primaryActionText: 'Confirm',

        async primaryAction() {
          try {
            await selectedDriverAccident.destroyRecord();
            notifications.success('Record successfully deleted');
            refreshTask.perform();
          } catch (e) {
            notifications.warning('Record failed to delete');
          }

          return tooltip.reset();
        }

      });
    }),

    onUndoDriverAccidentRecord() {
      const driverAccidentChangeSet = this.get('driverAccidentChangeSet');

      if (driverAccidentChangeSet) {
        driverAccidentChangeSet.rollback();
      }
    },

    onNewDriverAccidentRecord() {
      const selectedRow = this.get('selectedRow');
      if (selectedRow) selectedRow.set('selected', false);
      this.set('disableForm', false);
      this.get('editAPI').buttonSettings(true, true, true, false, false); //False to enable the button, TRUE to disable
    },

    onEditDriverAccidentRecord() {
      const selectedRow = this.get('selectedRow');
      if (Ember.isEmpty(selectedRow)) return;
      this.set('disableForm', false);
      this.get('editAPI').buttonSettings(true, true, true, false, false);
    },

    onVehicleChange(vehicle, path = 'vehicle') {
      const driverAccidentChangeSet = this.get('driverAccidentChangeSet');
      driverAccidentChangeSet.set(path, vehicle);
    },

    actions: {
      openVehicleModal() {
        const onVehicleChange = this.get('onVehicleChange').bind(this);
        const searchVehicleObj = Ember.Object.create({
          title: 'Vehicles',
          hasOverlay: true,
          config: _vehicleSearchConfig.default,
          primaryActionText: 'Select',
          className: 'vehicle-search-modal',
          primaryAction: record => {
            onVehicleChange(record);
          }
        });
        this.set('modalAPI', searchVehicleObj);
      }

    }
  });

  _exports.default = _default;
});