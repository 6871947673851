define("adept-iq/models/rider-external", ["exports", "ember-data", "adept-iq/mixins/disable-record", "moment"], function (_exports, _emberData, _disableRecord, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const DATE_FORMAT = 'MM/DD/YYYY';

  var _default = Model.extend(_disableRecord.default, {
    firstName: attr('string'),
    lastName: attr('string'),
    loadTime: attr('number'),
    unloadTime: attr('number'),
    walkingSpeed: attr('number'),
    notes: attr('string'),
    passcode: attr('string'),
    middleName: attr('string'),
    dateOfBirth: attr('date'),
    notificationPhoneNumbers: attr(),
    status: attr('string'),
    travelNeeds: attr(),
    categories: attr(),
    places: attr(),
    serviceNeeds: attr(),
    eligibility: belongsTo('rider-external-eligibility'),
    eligibilityStart: Ember.computed('categories.0.begin', function () {
      const eligibilityStartDTM = this.get('categories.0.begin');
      return new Date(eligibilityStartDTM);
    }),
    eligibilityEnd: Ember.computed('categories.0.end', function () {
      const eligibilityEndDTM = this.get('categories.0.end');
      return new Date(eligibilityEndDTM);
    }),
    formattedEligibilityStart: Ember.computed('eligibilityStart', function () {
      return (0, _moment.default)(this.get('eligibilityStart')).format(DATE_FORMAT);
    }),
    formattedEligibilityEnd: Ember.computed('eligibilityEnd', function () {
      return (0, _moment.default)(this.get('eligibilityEnd')).format(DATE_FORMAT);
    }),
    eligibilityType: Ember.computed('categories.0.type', function () {
      const eligibilityType = this.get('categories.0.type');
      return eligibilityType;
    }),
    isEligibilityConditional: Ember.computed('eligibilityType', function () {
      if (this.get('eligibilityType').toLowerCase() === 'conditional') {
        return true;
      }

      return false;
    }),
    pcaRequired: Ember.computed('eligibility', function () {
      return this.get('eligibility.pcaRequired');
    }),
    fullName: Ember.computed('firstName', 'middleName', 'lastName', function () {
      return `${Ember.get(this, 'firstName')} ${Ember.get(this, 'middleName') || ''} ${Ember.get(this, 'lastName')}`.replace(/\s+/, ' ');
    }),
    mainPhoneNumber: Ember.computed('notificationPhoneNumbers', function () {
      const phoneNumObject = this.get('notificationPhoneNumbers').filter(notificationPhoneNumber => notificationPhoneNumber.type === 'MAIN').firstObject;
      return phoneNumObject ? phoneNumObject.phoneNumber : '';
    }),
    mainAreaCode: Ember.computed('notificationPhoneNumbers', function () {
      const areaCodeObject = this.get('notificationPhoneNumbers').filter(areaCode => areaCode.type === 'MAIN').firstObject;
      return areaCodeObject ? areaCodeObject.areaCode : '';
    }),
    formattedPhoneNumber: Ember.computed('mainPhoneNumber', 'mainAreaCode', function () {
      const mainAreaCode = this.get('mainAreaCode');
      const mainPhoneNumber = this.get('mainPhoneNumber');

      if (Ember.isEmpty(mainPhoneNumber) && Ember.isEmpty(mainAreaCode)) {
        return '';
      }

      return mainAreaCode + '-' + mainPhoneNumber;
    }),
    eligibilityConditions: Ember.computed('eligbility', function () {
      const conditions = this.get('eligibility.categories.0.conditions');

      if (!conditions || conditions.length === 0) {
        return [];
      }

      return conditions;
    })
  });

  _exports.default = _default;
});